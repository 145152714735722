.add-new-patient-modal,
.add-new-guardian-modal {
  min-height: 100vh;
  max-width: 98vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;

  &.only-patient-tab {
    .nav-tabs {
      display: none;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 50px 0;
  }

  .guardian-name {
    @include fontstyle(20px, 600, 1.4);

    color: $heading;
  }

  .relation {
    @include fontstyle(16px, normal, 20px);

    color: $level-9;
  }

  .loader-active {
    height: 433px;

    .loader-inner {
      margin-top: 0;
    }
  }

  .form-control {
    &::placeholder {
      letter-spacing: 0 !important;
      line-height: 24px;
      color: $textarea-placeholder;
    }

    &.error {
      border-color: $input-error;
    }
  }

  .modal-content {
    padding: 24px 24px 32px;
    margin-top: 0;
    border: 0;
    transition: padding-bottom 500ms ease;
  }

  .modal-body {
    padding: 0;
    min-height: 342px;
    max-height: calc(100vh - 260px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal-header {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    margin: 0;
  }

  .os__menu {
    right: 0;
  }

  .modal-footer {
    padding: 0;
    margin: 0;

    .add-guardian-btn {
      min-width: 200px;
      text-align: center;
      border: 1px solid $cl-link;
      color: $os-link;
      padding-left: 24px;

      .btn-icon-helper {
        position: relative;
        left: auto;
        transform: none;
        top: 2px;
        margin-right: 8px;
      }
    }

    > * {
      margin: 0;
    }

    .osbtn + .osbtn {
      margin-left: 0.5rem;
    }

    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
      justify-content: space-between;

      .osbtn {
        width: calc(50% - 0.5rem);
        min-width: unset;

        &.btn-save-info {
          padding-left: 16px;
          padding-right: 16px;
        }

        &:only-child {
          width: 100%;
        }
      }
    }
  }

  .os-header-btn {
    position: relative;
    top: 0;
    right: -10px;

    .btn-icon-helper {
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      top: 0;
    }
  }


  .osbtn-primary {
    min-width: 195px;
  }

  .form-group {
    position: relative;
    margin-bottom: 16px;

    &::placeholder {
      color: $textarea-placeholder;
    }
  }

  .date-calendar {
    .ifill-os-calendar {
      display: none;
      right: 16px;
    }

    .react-datepicker-wrapper {
      .form-control {
        &::placeholder {
          font-size: 14px;
          color: $textarea-placeholder;
        }
      }
    }

    .react-datepicker-popper[data-placement^="bottom"] {
      transform: none !important;
      inset: 100% 0 auto auto !important;
    }

    .react-datepicker-popper {
      width: 320px;

      @media(max-width: 767px) {
        width: 100%;
        max-width: 100% !important;

        .react-datepicker {
          width: 100%;
        }
      }
    }

  }

  .nav-tabs {
    border:  0;
    margin-top: 2px;
    padding-top: 1px;
    overflow: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
    position: sticky;
    top: 0;
    background: $level-1;
    z-index: 3;

    .nav-link {
      @extend %heading-3-semibold;

      color: $tab;
      border: 0;
      border-bottom: solid 2px transparent;
      padding: 0;
      line-height: 34px;
      white-space: nowrap;
      outline: none;
      -webkit-appearance: none;
      background: none;
      text-transform: capitalize;
      margin-bottom: 0;

      &.active {
        background: none;
        color: $tab-active;
        border-bottom-color: $tab-active ;
      }
    }

    .nav-link + .nav-link,
    .nav-item + .nav-item {
      margin-left: 12px;

      @media(min-width: 768px) {
        margin-left: 24px;
      }
    }

    .nav-item:last-child {
      button {
        margin-left: -16px;
      }
    }
  }

  .nav-item[id*="add_patient"],
  .nav-item.active[id*="add_patient"],
  .nav-link[id*="add_patient"],
  .nav-link.active[id*="add_patient"] {
    min-width: 156px;
    color: $level-8;
    display: flex;
    align-items: center;

    @include sticky;

    right: 0;
    padding: 0;
    background-color: transparent !important;
    justify-content: center;
    border: 0;

    &::before {
      content: "\e9da";

      @include osfont;

      margin-right: 8px;
    }
  }

  .cell-row {
    width: 100%;
  }

  .nav-search-container {
    padding-top: 2px;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 0;
    }

    .search-label-group {
      width: 100%;
      position: relative;
      margin: 0;
    }
  }

  .content-with-search {
    .loader-active {
      height: 120px;
      margin-top: 20px;
    }

    @media(min-width: 768px) {
      .cards {
        max-height: 368px;
        overflow: auto;
      }
    }

    .patient-cd-name {
      line-height: 1.25;
      padding-right: 0;

      @include multiline-truncate(1);
    }

    .patient-cd-address {
      margin: 0;
    }

    .person-card-tr {
      margin-top: 4px !important;
      padding-top: 0 !important;

      &:empty {
        display: none;
      }

      .os-btn-link {
        color: $level-9 !important;
      }

    }

    .os-custom-radio {
      font-weight: 600;
      text-transform: uppercase;
      color: $level-10;
      padding-left: 40px;
      padding-right: 16px;
      line-height: 40px;
      margin-left: 0;
      margin-right: auto;
      border: 1px solid $btn-icon-border;
      border-radius: 8px;
      margin-top: 8px;

      .icon-helper {
        left: 16px;
        top: calc(50% - 8px);
      }
    }

    .no-cases-block {
      max-width: 490px;
      margin: auto;
      display: flex;
      align-items: center;
      padding: 20px 0 0;

      @media(min-width: 767px) {
        flex-direction: column;

        &.contacts {
          flex-direction: row;
        }
      }

      .no-svg-img {
        width: 120px;
        height: 120px;

        svg {
          height: inherit;
          width: inherit;
        }
      }

      .no-cases-message-block {
        font-size: 1rem;
        color: $level-10;
        line-height: 1.25;

        @media(min-width: 768px) {
          text-align: left;
          padding: 0 0 0 12px;
        }
      }
    }
  }
}

.add-new-guardian-modal {
  .modal-body {
    min-height: 220px;
  }
}


.modal-header {
  .patient-label {
    border-radius: 8px !important;

    .cs-dropdown {
      min-width: 90px !important;
    }

    .page-heading-title {
      //background-color: lightblue;
      border-radius: 8px !important;

      .istoggleicon {
        right: 12px !important;
      }
    }

    .list-unstyled {
      //background-color: lightcoral;
      box-shadow: $block-shadow-m;
      right: -24px;

      & > li {
        text-align: left;

        & > div {
          padding: 8px 0 12px;
        }
      }

      .header-dropdown-list-item {
        & > div {
          padding-left: 0;
        }
      }
    }

    .header-dropdown-menu{
      position: relative;

      .header-dropdown-list{
        width: 170px;
        left: -40px;
        overflow: unset;
        max-height:none;

        .header-dropdown-list-item{
          overflow-y: auto;
          max-height: 600px;
          position: unset;
          .lb-li-footer {
            display: none;
          }

          .sub-labels{
            background: #fff;
            border-radius: 8px;
            box-shadow: $block-shadow;
            min-width: 150px;
            max-height: 200px;
            overflow-y: auto;

            .sub-label{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0 16px;
              &:hover{
                background: #edeff3;
              }
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.add_new_patient_form {
  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    p {
      color: #5d7893;
    }
  }

  .card-container-title {
    color: #354554;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    padding-top: 8px;
  }

  .choose-profile-wrap {
    padding: 0;
    display: flex;
    gap: 12px;
    margin-top: 32px;

    &.space-between {
      justify-content: space-between;
    }

    .action-btn {
      margin: 0;
    }
  }

  .os-custom-checkbox {
    font-size: 16px;

    .check-icon {
      top: 4px;
    }
  }

  .form-group { 
    .os__option,
    .os__single-value{
      > div {
        padding: 2px;

        .result-text-data {
          padding: 2px 0;
        }
      }
    }

    label {
      color: $level-11;

      &:not(.prm-guard) {
        &::after {
          content:'*';
          color: $os-link;
        }
      }

      &.not-mandatory {
        &::after {
          content: none;
        }
      }
    }
  }

  .guardian-text {
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 16px;
    display: block;
  }

  .form-error {
    position: absolute;
  }

  .single-select {
    .form-control {
      padding: 0;
      border: 0;

      &.error {
        border-color: transparent;
      }

      &#doctorId {
        border: 0;
      }
    }

    .os__menu-list {
      max-height: 180px;
      overflow-y: auto;
    }

    .os__menu {
      left: 0;
      right: auto;
    }
  }

  .os-card-v2 {
    height: 100%;
  }

  .selection-mode {
    .os-lg-person {
      height: auto;
    }
  }

  .os-sm-person {
    position: relative;
    min-height: 160px;
    height: 100%;
    max-height: unset;
  }

  .create-show-carespace {
    margin-top: auto;
    padding-left: 76px;
    position: relative;
    top: 10px;
  }
}

.cell-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  .form-group:first-child{
    position: absolute;
    z-index: 1;
    margin: 1px;
  }

  .form-control {
    padding-left: 55px;
    padding-right: 12px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .single-select {
    .form-control {
      min-width: 50px;
      padding: 0;
      border: 0;
      box-shadow: none;
      height: 38px;
    }

    .os__control {
      border: 0;
      box-shadow: none;
      height: 38px;
      min-height: 38px;
    }

    .os__value-container {
      padding: 0 0 0 16px;
    }

    .os__menu {
      min-width: 95px;
      z-index: 1;
      left: 0;
      right: auto;

      .os__option {
        padding: 8px 12px;
      }
    }
  }

  .os__indicators {
    display: none;
  }

  .form-group + .form-group {
    width: 100%;
  }
}

.switch-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 14px;

  label{
    white-space: nowrap;
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  &.notify-with-label {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0;
    margin-bottom: 16px;


    .form-group {
      background-color: $level-3;
      padding: 8px 16px;
      width: 100%;
      border-radius: 4px;
      margin-top: 9px;

      &.disabled {
        span, .check-icon {
          color: $level-8 !important;
        }
      }
    }
  }
}

.guardian_title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .osbtn {
    padding: 0 8px;
  }
}



@media(min-width: 768px) {
  .col-1-10 {
    width: 10%;
  }

  .col-2-20 {
    width: 20%;
  }

  .col-3-30 {
    width: 30%;
  }

  .col-4-40 {
    width: 40%;
  }

  .col-5-50 {
    width: 50%;
  }

  .col-6-60 {
    width: 60%;
  }

  .col-7-70 {
    width: 70%;
  }

  .col-8-80 {
    width: 80%;
  }

  .col-9-90 {
    width: 90%;
  }

  .col-10-10 {
    width: 100%;
  }
}


@media screen and (max-width: 767px) {
  .add-new-patient-modal, .add-new-guardian-modal {
    min-height: 100vh;
    height: auto;

    .modal-content {
      margin-top: 0;
      min-height: 100vh;
      height: 100vh;
      border-radius: 0;
      border: 0;
      padding: 0 24px;
    }

    .modal-header {
      margin-top: 24px !important;
    }

    .modal-body {
      height: calc(100vh - 180px);
      max-height: calc(100vh - 180px);
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .guardian-row-height {
    max-height: none;
  }
}

.overlay-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: $level-11;
  z-index: 2;
  border-radius: 12px;
  background-color: $modal-backdrop;
}


.os-menu-drop {
  &.only-option-text {
    .os__option {
      line-height: 32px;
      padding: 4px 12px;
    }
  }

  .os__value-container {
    padding: 0 16px;
  }

  .os__input-container {
    color: $dropdown-text;
  }

  .os__indicators {
    position: absolute;
    right: 40px;
    top: 4px;
    height: 32px;

    &::after {
      content: "\e922";
      font-family: fill-os-icon;
      position: absolute;
      right: -33px;
      cursor: pointer;
      color: #6c6c6f;
    }

    svg {
      margin-top: 4px;
    }
  }

  .os__control--menu-is-open {
    .os__indicators {
      &::after {
        content: "\e90e";
        font-family: fill-os-icon;
      }
    }
  }

  .os__clear-indicator {
    &::after {
      position: absolute;
      width: 1px;
      height: 12px;
      content: '';
      right: -2px;
      background-color: #6c6c6f;
      top: 12px;
    }
  }

  .os__control {
    max-height: 170px;
    overflow-x: hidden;
    overflow-y: auto;
    cursor: pointer;
    background-color: $level-1;
    border-color: $input-border;
    border-radius: 8px;
  }

  .os__placeholder {
    color: $level-9;
  }

  .os--is-disabled {
    pointer-events: auto;
    color: $level-11 !important;

    &.os__option--is-focused {
      background-color: $dropdown-list-hover !important;
      color: $dropdown-hover-text !important;
    }
  }

  .os__option--is-focused {
    background-color: $dropdown-list-hover !important;
    color: $dropdown-hover-text !important;
  }

  .os__menu {
    border-radius: 0 0 4px 4px;
    padding: 0;
    z-index: 2;
    box-shadow: $dropdown-shadow;
    background-color: $dropdown-bg;

    .os__menu-list {
      max-height: 140px;
      font-size: 14px;
      overflow-y: auto;
      list-style: none;
    }
  }

  .os__option,
  .os__single-value{
    cursor: pointer;
    padding: 0;
    color: $dropdown-text;

    > div {
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 13px 12px;

      .avatar {
        @include avatar(24px);

        border-width: 2px!important;
        border-radius: 4px;
        margin-right: 14px;

        img {
          border-radius: 4px;
        }

        .icon-span {
          @include icon-span(12px);
        }
      }

      .result-text-data {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &:hover, &.os__option--is-focused {
      background-color: $dropdown-list-hover !important;
      color: $dropdown-hover-text !important;
    }

  }

  .os__value-container {
    .os__single-value {
      &:hover {
        background-color: transparent !important;
        color: $dropdown-text !important;
      }
    }
  }

  .os--is-disabled {
    pointer-events: auto;
    color: $level-11 !important;

    &.os__option--is-focused {
      background-color: $dropdown-list-hover !important;
    }

    &:hover  {
      background-color: $dropdown-list-hover;
    }

    &::after {
      content:'';
      position: absolute;
      inset: 0;
      cursor: not-allowed !important;
      z-index: 1;
    }
  }

  .os__single-value{
    margin-left: 0;
    padding-right: 0;

    > div {
      padding: 8px 0;

      .avatar {
        @include avatar(20px);

        border-width: 1px!important;
        border-radius: 4px;
        margin-right: 8px;
      }

      .result-text-data {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &.round-avatar {
    .os__option,
    .os__single-value{
      > div {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 8px 12px;

        .avatar {
          border-radius: 50%;

          img {
            border-radius: 50%;
          }
        }
      }
    }

  }
}

.mobile-device {
  .add-new-patient-modal,
  .add-new-guardian-modal {
    .nav-tabs,
    .nav-search-container {
      background-color: $block;
      z-index: 10;

      @include sticky;
    }

    .nav-tabs {
      margin-top: 0;
      top: -1px;
      margin-bottom: 8px !important;
    }

    .nav-search-container {
      top: 37px;
    }
  }
}

@media(min-width: 768px) {
  .os-menu-drop {
    &.with-top-position {
      .os__menu {
        top: auto;
        bottom: 100%;
        border-radius: 4px 4px 0 0;
      }
    }
  }
}


.os-grid-modal.modal-carespace-related {
  .modal-content {
    max-width: 100%;
    width: 716px;
    padding: 30px 32px 28px;
    min-height: 272px;
  }

  .row {
    padding-top: 16px;
    margin: 0 -8px;

    [class*="col-"] {
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 12px;
    }
  }

  .modal-body {
    padding: 0;
  }

  .carspace_subheading {
    line-height: 1.25;
    padding-top: 22px;
  }
}
