%flex-style {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

%submission-selection-link {
  @include fontstyle(12px, normal, 40px);
  @extend %flex-style;
  height: 40px;
  padding: 0px 10px 0;
  border-radius: 4px;
  display: inline-block;
  color: $os-link;
  text-align: center;
  vertical-align: top;
  position: relative;
  z-index: 1;
}

.submit-right-container {
  height: 100%;
  position: relative;
  padding-top: 48px;
}

.quiz-right-section {
  overflow-y: auto;
  @extend %os__scroll;

  &.questions {
    .accordion-section {
      margin-bottom: 56px;
    }
  }

  .accordion-section {
    &.result-section {
      .accordion-block {
        &.selected {
          .accordion-header {
            color: #fff;
          }
        }
      }
    }
  }

  .accordion-slider-block {
    margin-top: 15px;

    .accordion-slider-row {
      width: 100%;
      height: 10px;
      position: relative;
      background: #858585;
      border-radius: 5px;
      cursor: pointer;
      transition: all .3s ease-in-out;
      position: relative;
      z-index: 2;

      &.mild {
        background-color: #65c7ff;
      }

      &.moderate {
        background-color: #00a2ff;
      }

      &.severe {
        background-color: #00598d;
      }

      input[type=range] {
        width: 100%;
        position: absolute;
        top: -3px;
        left: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;

        &:focus {
          outline: none;
        }
      }

      input[type=range]::-webkit-slider-thumb {
        width: 24px;
        height: 24px;
        margin-top: -5px;
        border: 3px solid #fff;
        border-radius: 17px;
        background: #1F1F1F;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-shadow: 0 2px 4px 0 #000;
      }

      input[type=range]::-moz-range-thumb {
        width: 18px;
        height: 18px;
        margin-top: -3px;
        border: 3px solid #fff;
        border-radius: 17px;
        background: #1F1F1F;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-shadow: 0 2px 4px 0 #000;
      }

      /*.accordion-slider-input {
        width: 100%;
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
      }*/

      .accordion-slider-points {
        width: calc(100% / 3);
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        background: transparent;

        &.moderate {
          left: 34%;
        }

        &.severe {
          left: 67%
        }
      }

      &.mild {
        .accordion-slider-handle {
          left: 0;
        }
      }

      &.moderate {
        .accordion-slider-handle {
          left: 47%;
          transition: all .3s ease-in-out;
        }
      }

      &.severe {
        .accordion-slider-handle {
          left: 97%;
        }
      }

      .accordion-slider-handle {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        border: solid 2px #fff;
        position: absolute;
        top: 50%;
        background: #1e1e1e;
        border-radius: 100%;
        transition: all .3s ease-in-out;
      }
    }

    .accordion-slider-options {
      margin: 10px 0 0 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      z-index: 2;

      .accordion-slider-option {
        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
  }

  .case-right-landing-submission {
    position: relative;
    top: 0;
    margin-bottom: 20px;
    right: 0;

    @media screen and (max-width: 767px) {
      padding-left: 24px;
    }
  }

  .quiz-error-footer {
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 14px;
    font-weight: $font-weight-bold;
    padding: 45px 12px 20px 12px;
  }
}

.questions {
  .card {
    position: relative;
    background-color: #222226;
    border: 0;
    border-radius: 8px !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 8px;
    padding: 12px 16px 16px;
    @include fontstyle(16px, normal, 1.5);
    color: $level-11;

    &:last-child {
      margin-bottom: 48px;
    }
  }

  .ifill-os-tick-single {
    display: none;
    color: $os-link;
    position: absolute;
    top: 18px;
    font-size: 16px;
    right: 18px;
  }


  .card-header {
    padding: 0 0;
    border: 0;
    background-color: transparent;

    &.selected {
      .ifill-os-tick-single {
        display: block;
      }
    }
  }

  .card-body {
    padding: 16px 0px 8px;
  }

  .quiz-answer {
    padding-top: 12px;
    @include fontstyle(12px, normal, 1.5);

    &:empty {
      display: none
    }
  }
}

.accordion-block {
  @include fontstyle(16px, normal, 1.5);
  background-color: #222226;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 12px 16px;
  position: relative;

  .quiz-answer {
    @include fontstyle(12px, normal, 1.5);

    &:empty {
      display: none
    }
  }

  .ifill-os-tick-single {
    display: none;
    color: $os-link;
    position: absolute;
    left: 3px;
    top: 19px;
    font-size: 11px;
  }

  .ifill-os-chevron-up,
  .ifill-os-chevron-right {
    float: right;
    display: block;
    margin-top: 2px;
    position: relative;
    right: 0;
    top: 0;
  }

  .ifill-os-chevron-up {
    display: none;
  }

  &.selected {
    padding-bottom: 16px;

    .ifill-os-tick-single {
      display: block;
    }

    .accordion-header {
      padding-bottom: 12px;
    }
  }

  &.expanded {
    padding: 12px 16px 24px;

    .accordion-header {
      padding-bottom: 12px;
    }

    .ifill-os-chevron-up {
      display: block;
    }

    .ifill-os-chevron-right {
      display: none;
    }

    .accordion-content-block {
      .educate-graph-block {
        max-height: 156px;
        overflow: hidden;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    padding-left: 24px;

    &.selected {
      padding-left: 24px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: 24px;

    &.selected {
      padding-left: 24px;
    }
  }
}

.accordion-header {
  color: $level-9;
  display: inline-block;
  line-height: 1.5;
  padding-bottom: 0;
  display: block;
  font-size: 16px;

  .accordion-button {
    .ac-heading {
      flex: 1 1;
    }

    .lt-count {
      margin-left: 0;
    }
  }
}

.accordion-link-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  cursor: pointer;
}

.submission-selection-holder {
  width: 100%;
  padding: 0;
  display: flex;

  .submission-selection-link {
    @extend %submission-selection-link;
    text-transform: uppercase;
    margin-right: 8px;
    border: 1px solid $cl-link;

    &.selected,
    &:hover {
      background: $cl-link;
      color: $level-11;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.four {
    flex-wrap: wrap;
    justify-content: space-between;

    .submission-selection-link {
      flex: 0 0 50%;
      max-width: calc(50% - 4px);
      margin-right: 0px;

      &:nth-child(n+1) {
        margin-bottom: 16px;
      }
    }
  }

}

.submission-selection-small-holder {
  width: 100%;
  height: 32px;
  padding: 0;
  display: flex;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;

  .submission-selection-link {
    @extend %submission-selection-link;
    height: 30px;
    line-height: 30px;
    color: $level-11;

    &.selected,
    &:hover {
      color: $cl-link;
      background-color: #fff;
      border-radius: 4px 0px 0px 4px;

      @media(max-width: 767px) {
        border-radius: 3px 0px 0px 3px;
      }
    }

    &:nth-child(n+2) {
      border-radius: 0;

      &.selected,
      &:hover {
        border-radius: 0;
      }
    }

    &:last-child {

      &.selected,
      &:hover {
        border-radius: 0px 4px 4px 0;

        @media(max-width: 767px) {
          border-radius: 0px 3px 3px 0;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 16px;
      width: 1px;
      background-color: #fff;
      top: 7px;
      right: 0;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  &.blue {
    border-color: $os-link;

    .submission-selection-link {
      color: $os-link;

      &.selected,
      &:hover {
        color: $level-11;
        background-color: $os-link;
      }
    }
  }

  &.four-submission-link {
    .submission-selection-link {
      padding: 0px 0px 0;
      font-size: 10px;
    }
  }
}

.submission-checkbox-holder {
  width: 100%;
  margin-top: 0;
  display: block;
  flex-direction: column;
  position: relative;
  z-index: 2;

  .submission-checkbox-link {
    @include fontstyle(12px, normal, 1.5);
    width: auto;
    margin-bottom: 8px;
    padding-left: 28px;
    display: inline-block;
    position: relative;
    color: #d4d4d9;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.single {
    .submission-checkbox-link {
      &.selected {
        &::before {
          font-family: 'fill-os-icon';
          content: "\e920";
          border-color: transparent;
          border-radius: 0;
          font-size: 17px;
          top: -4px;
          color: $os-link;
          left: -1px;
        }
      }

      &::before {
        content: "";
        top: 1px;
        width: 16px;
        left: 1px;
        position: absolute;
        height: 16px;
        border: solid 1px #8c8c8c;
        border-radius: 100%;
      }
    }
  }

  &.multiple {
    .submission-checkbox-link {
      &:last-child {
        margin-bottom: 0;
      }

      &.selected {
        &::before {
          font-family: 'fill-os-icon';
          content: "\e91d";
          color: #48acfd;
        }
      }

      &::before {
        font-family: 'fill-os-icon';
        content: "\e91e";
        border: 0;
        color: #8c8c8c;
        font-size: 16px;
        line-height: 16px;
        top: 1px;
        left: 1px;
        position: absolute;
      }
    }
  }
}

.accordion-section {
  margin-bottom: 0;

  .graph-value {
    @include fontstyle(12px, normal, 1.33);
  }

  .accordion-block {
    &.all-selected {
      .ifill-os-tick-single {
        display: inline-block;
      }
    }

    .accordion-content-block {
      .row {
        position: relative;
        z-index: 2;
      }

      .educate-graph-block {
        padding-right: 0px;
        padding-left: 0px;
      }

      .blue-content-response {
        color: #48acfd;
        font-size: 12px;
        padding: 8px 20px 5px 15px;
      }

      .graph-value-text {
        span {
          display: block;
        }
      }

      .rc-slider {
        position: relative;
        z-index: 2;

        &.rc-slider-with-marks {
          height: 8px;
          margin-top: 15px;
          margin-bottom: 8px;
          padding: 0;
        }

        .rc-slider-rail {
          height: 8px;
        }

        .rc-slider-track {
          height: 8px;
        }

        .rc-slider-handle {
          width: 16px;
          height: 16px;
          margin-top: 0;
          top: calc(50% - 8px);
        }

        .rc-slider-mark {
          left: 0px;
          width: calc(100% - 20px);

          .rc-slider-mark-text {
            width: auto !important;
            margin-left: 0px !important;
            top: -38px;
          }
        }

        .rc-slider-step {
          display: none;
        }

        .range-marks {
          color: #fff;
        }
      }

      .range,
      .picked-category-text {
        color: #fff;
        font-size: 12px;
      }

      .picked-category-text {
        span {
          margin-right: 5px;
          font-weight: 400;
        }

        color: #48acfd;
        text-align: left;
      }

      .chart-section-list {
        .col-6 {
          padding-left: 0;
        }
      }

      .bar-legend-list {
        padding-top: 6px;
        display: inline-block;
        color: $level-9;

        &.answers-count-1 {
          li {
            padding-top: 18px;
          }
        }

        .my-answer {
          color: $level-11;
        }

        &:hover {
          /*color: $os-link;
          text-decoration: underline;
          .my-answer {
            color: $os-link;
          } temperory removed */
        }

        @media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
          &.answers-count-2 {
            li {
              margin-top: 28px;
            }
          }

          &.answers-count-3 {
            li {
              margin-top: 13px;
            }
          }

          &.answers-count-1 {
            li {
              padding-top: 55px;
            }
          }
        }

        li {
          width: auto;
          float: left;
          clear: both;
          display: inline-block;
          color: #fff;
          padding-left: 0px;
          vertical-align: top;
          position: relative;
          padding-bottom: 12px;
          font-size: 12px;

          &:hover {
            color: #48acfd;
            text-decoration: underline;
          }

          &.my-answer {
            color: #48acfd;
          }
        }
      }
    }
  }
}

.submit-insight-header {
  @include fontstyle(16px, 600, 1);
  color: #e7e7ea;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.quiz-submit-btn {
  position: absolute;
  padding-top: 16px;
  bottom: 46px;
  left: 12px;
  right: 16px;
  background: #1c1c1f;
  z-index: 2;
}

.user-list-toggle {
  @include fontstyle(16px, normal, 1.5);
  background-color: #222226;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 8px;
  padding: 12px 0;
  position: relative;

  .your-insight-text {
    color: $level-9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }
}

.share-insight-text {
  display: none;
  color: #d4d4d9;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0;
  padding-right: 16px;
}

.ipad {
  &.portrait {

    .case-right-landing-header,
    .submit-insight-header {
      .cs-right-dropdown {
        display: none;
      }
    }

    .submit-insight-header {
      position: relative;
      left: 0;
      right: 0;
    }

    .quiz-submit-btn {
      position: relative;
      bottom: 0;
    }

    .quiz-right-section {
      &.questions {
        .accordion-section {
          margin-bottom: 0;
        }

        .card {
          max-width: 100%;
        }
      }
    }

    .new-cs-card {
      .submit-right-container {
        padding-top: 0;
      }

      .submit-insight-header {
        top: 0;
      }

      .share-insight-text {
        display: inline-block;

      }

      .accordion-section > .accordion {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
      }
    }
  }
}

.mobile-device {
  .submit-insight-header {
    position: relative;
    left: 0;
    margin-bottom: 24px;

    .cs-right-dropdown {
      display: none;
    }
  }

  .new-cs-card {
    .submit-insight-header {
      margin-bottom: 0;
    }
  }
}