#toast-container {
  position: fixed;
  top: 80vh;
  left: 50vw;
  z-index: 2;

  >.toast-error,
  >.toast-success {
    background-image: none !important;
    background-color: $level-11 !important;
    position: relative;
  }

  .toast-message {
    color: $level-1;
  }

  button {
    background: none;
    color: $level-1;
    -webkit-appearance: none;
    border: none;
    outline: none;
  }
}

.toast {
  font-size: 1rem;
  border: 0;
  border-radius: 20px;
}

.toast-top-full-width {
  top: 90px;

  .toast {
    width: 100% !important;
    max-width: unset;
    text-align: center;
    border-radius: 20 !important;
    box-shadow: 0 0 0 transparent !important;
    opacity: 1 !important;
    height: 40px;
    padding: 12px 16px;

    .toast-close-button {
      margin-top: -5px;
      position: absolute;
      top: 50%;
      right: 22px;
      font-family: fill-os-icon;
      font-size: 0;
      display: none;

      &:before {
        content: "\e99d";
        font-size: 8px;
      }
    }

    .toast-message {
      // width: 100%;
      // position: relative;
      // top: 50%;
      // transform: translateY(-50%);
      height: 16px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
    }
  }
}

.mobile-device {
  #toast-container {
    position: absolute;
    top: 80vh;
    left: 50vw;
    z-index: 2;

    .toast {
      padding-left: 15px !important;
      padding-right: 15px !important;

      .toast-close-button {
        top: 50%;
        right: 15px;
      }
    }

    .toast-message {
      font-size: 14px;
    }
  }

}