.os-dropdown {
  position: absolute;
  margin-bottom: 0;
  padding: 0px;
  box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
}

.header-dropdown-menu {
  .user-profile__dropdown {
    z-index: auto;
  }

  .osbtn {
    text-transform: none;
  }

  &:not(.show) {
    .ifill-os-chevron-caret-up::before {
      content: "\e906";
    }
  }
}

.user-profile__dropdown {
  top: calc(100% + 12px);
  right: 0;
  width: 220px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  color: $level-11;
  line-height: normal;

  .header-dropdown-list-item:first-child {

    &::before {
      position: absolute;
      content: 'none';
      bottom: 99%;
      right: 24px;
      transform: translateX(-50%);
      border: 12px solid transparent;
      border-bottom-color: $dropdown-bg;
      z-index: 1;
      pointer-events: none;
    }
  }

  .nav-profile__user {
    display: flex;
    align-items: center;
    padding: 12px 20px 24px !important;
    border-radius: 8px 8px 0 0 !important;

    &:hover {
      text-decoration: none;
    }

    .nav-profile__user-photo {
      margin-right: 16px;
      height: 50px;
      width: 50px;
      min-width: 50px;
      line-height: 46px;
      font-size: 22px;
      position: relative;

    }

    .nav-profile__user-info {
      font-size: 16px;
      line-height: 1.4;
      white-space: normal;
      font-weight: $font-weight-semibold;
      word-wrap: break-word;

      .user-name {
        color: $level-11;
      }
      .user-title {
        font-size: 12px;
        color: $level-9;
      }
    }
  }

  .header-dropdown-list-item:last-child {
    .nav-profile__link {
      border-radius: 0 0 8px 8px;
    }
  }

  &.only-logout {
    width: 100px;
    min-width: 100px;

    .nav-profile__link {
      border-radius: 8px;
    }
  }
}


// Bootstrap drowndown scss
.bootstrap-dropdown {
  .dropdown-menu {
    z-index: 100 !important;
    max-height: 420px;
    min-height: 200px;
    overflow-y: scroll;

    .loader {
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .dropdown-item {
      &:active {
        background-color: #F4F7F9;
        border-radius: 8px;
      }
    }

    .empty-records-message {
      color: var(--neutrals-level-9, #5D7893);
      margin: 0 8px 8px 8px;
    }
  }
}

// tiptap editor css with bootstrap drowndown
.tiptap-editor-container,
.partner-space-editor,
.task-modal-form,
.label-selector,
.tiptap-editor-patient-space-wrapper {
  .bootstrap-dropdown {
    .dropdown {
      width: fit-content;
      margin: 0 8px 8px 0;

    }
    .dropdown-menu {
      min-width: 230px !important;
      border-radius: 8px;
      padding-top: 0;
      background: var(--surface-borders-level-1, #FFF);
      box-shadow: 0px 2px 4px 0px rgba(53, 69, 84, 0.12), 0px 3px 6px 0px rgba(53, 69, 84, 0.15);

      input {
        margin: 8px;
        width: calc(100% - 16px) !important;
        border: 1px solid var(--border-input-active, #4A749A);
        border-radius: 8px;
        position: sticky;
        top: 8px;
        z-index: 2;
        height: 32px;
      }
    }

    .dropdown-item {
      margin: 8px;
      position: sticky;
      top: 8px;
      width: inherit;
      z-index: 1;
      height: 32px;
      color: var(--neutrals-level-9, #5D7893);
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        border-radius: 8px;
      }
    }
    .dropdown-toggle {
      background-color: #F4F7F9;
      border: none;
      height: 24px;
      color: var(--neutrals-level-9, #5D7893);
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
  }
}

.partner-space-editor + div {
  z-index: 1;
  position: relative;
  & > div:first-child {
    z-index: -1;
    position: relative;
  }
  .activity-listing {
    &:has(.show) {
      .day-dropdown-with-date {
        z-index: 0;
      }
    }
  }
  .group-by-space-name {
    z-index: 2;
  }
}