$spin-664: 664px;
$spin-height: 391px;
$transform-50: $transform-50 ;

.cs-fullscreen {
  position: absolute;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
  z-index: 2;
  background-color: #fff;
  color: #000;
  height: 24px;
  width: 24px;
  text-align: center;
  font-size: 20px;
  border-radius: 3px;
  opacity: .9;

  .os-fullscreen-btn {
    background-color: transparent;
    padding: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    color: inherit;
    display: block;
    line-height: 24px;
    font-size: 20px;

    i {
      line-height: 24px;
    }
  }
}

.no-touch-support {
  .cs-fullscreen {
    &:hover {
      background-color: rgba(72, 172, 253, 0.2) !important;
      color: $os-link;

      .os-fullscreen-btn {
        background-color:transparent;
        color: $os-link;
      }
    }
  }
}

.cs-right-dropdown {
  position: absolute;
  right: -20px;
  top: 0;

  .card-btn-more {
    padding: 0px 8px;
  }
}

.cs-close-fullscreen {
  position: absolute;
  right: -8px;
  top: 8px;
  background-color: $level-11;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  font-size: 20px;
  color: black;
  line-height: 24px;
  padding: 0;

  &:hover {
    background-color: $os-link-hover
$os-link-hover;
    color: $os-link;
  }
}

.case-timeline-right-section {
  .activity-footer-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    width: 100% !important;
    margin-left: 0 !important;

    .comment-like-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 8px;
    }

    .osbtn {
      &:first-child {
        margin-left: -12px;
      }
    }
  }
}

.ipad {
  &.portrait {
    .case-timeline-right-section {
      .activity-footer-block {
        width: auto !important;
      }
    }
  }
}

.mobile-device {
  .case-timeline-right-section {
    .activity-footer-block {
      .right-col {
        margin-right: 24px !important;
      }
    }
  }
}

.sbs-wrapper {
  height: calc(100% - 14.70%);
  width: 100%;
  position: relative;
  background-color: $level-1;

  .slider-threesixty {
    &.horizontal {
      margin-top: 16px;
    }
  }

  .cs-fullscreen {
    right: 24px;
    bottom: 96px;

    &.rt-16 {
      right: 16px;
    }
  }

  .slider-threesixty {
    &.vertical {
      height: calc(100% - 176px);
      right: 8px;
    }

    &.horizontal {
      margin-top: -116px;

      .rangeslider-horizontal {
        right: 52px;
        left: 16px;
      }
    }
  }
}

.sbs-spin-img-wrap {
  display: flex;
  height: 100%;
  border-radius: 8px;
  padding: 16px 0 0;
  margin-top: 16px;
  position: relative;

  &:before {
    content: '';
    background-color: $block;
    width: 100%;
    height: calc(100% - 80px);
    position: absolute;
    border-radius: 12px;
    left: 0;
    right: 0;
    top: 0;
  }
}

.no-touch-support {
  .sbs-spin-img-wrap {
    &.pl-16 {
      padding-right: 16px;

      + .slider-threesixty {
          &.vertical {
            right: -8px;
          }
        }
    }
  }

  &.desktop-hd {
    .sbs-spin-img-wrap {
      &.pl-16 {
        + .slider-threesixty {
            &.vertical {
              right: 0;
            }
          }
      }
    }

    .full-screen {
      .sbs-spin-img-wrap {
        &.pl-16 {
          + .slider-threesixty {
              &.vertical {
                right: -16px;
              }
            }
        }
      }
    }
  }

  &.desktop-small {
    .sbs-spin-img-wrap {
      &.pl-16 {
        padding-right: 16px;

        + .slider-threesixty {
            &.vertical {
              right: 0;
            }
          }
      }
    }

    .full-screen {
      .sbs-spin-img-wrap {
        &.pl-16 {
          + .slider-threesixty {
              &.vertical {
                right: -16px;
              }
            }
        }
      }
    }
  }
}

.sbs-col {
  flex: 0 0 50%;
  max-width: 50%;

  .sbs-inner-wrap {
    height: 100%;
  }

  .sbs-img {
    position: relative;
  }
}

.sbs-dropdown {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  .sbs-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cs-sort-btn-group {
    .cs-sort-btn {
      background-color: rgba(53, 69, 84, 0.2) !important;

      .btn-icon-helper {
        color: $level-1 !important;
      }
    }
  }

  .cs-dropdown {
    min-width: 120px;
    height: 40px;

    .page-heading-title {
      background-color: transparent;
      font-size: 12px;
      color: $level-9;
      padding: 0 40px 0 8px;
      line-height: 40px;

      .ifill-os-chevron-up {
        right: 12px;
      }
    }

    .header-dropdown-list {
      border-radius: 4px;
      max-height: 300px;
      overflow-y: auto;

      .header-dropdown-list-item {
        > div {
          padding: 8px 16px;
        }
      }
    }
  }
}

.sbs-left {
  padding-right: 8px;
  .sbs-img {
    &.new-os-slider {
      padding-left: 16px;
    }
  }
}

.sbs-right {
  padding-left: 8px;

  .sbs-dropdown {
    justify-content: flex-end;

  }

  .sbs-img {
    &.new-os-slider {
      padding-right: 16px;
    }
  }

  .sbs-inner-wrap {
    justify-content: flex-end;
  }

  .cs-dropdown {
    min-width: 120px;
    display: inline-block;
    order: 2;
    margin-right: 0;
  }

  .cs-file-dropdown {
    order: 1;
  }
}

.sbs-img {
  margin-top: 8px;
  order: 3;
  height: calc(100% - 32px);

  article, .spin-video {
    height: 100%;
    margin: 0;
  }

  .spin-video {
    .threesixty-spin {
      transform: $transform-50  scale(0.75);
      margin-left: 0;
    }

    &.section {
      .threesixty-spin {
        transform: $transform-50  scale(0.41);
      }
    }
  }
}

.new-os-slider {
  &.sbs-img {
    article, .spin-video {
      height: 100%;
    }
  }
}

.full-screen {
  .sbs-wrapper {
    margin-top: 9px;
    height: calc(100vh - 110px);
    padding-left: 0;
    padding-right: 0;

    .cs-fullscreen {
      right: 0;
    }

    .slider-threesixty {
      &.vertical {
        right: -16px;
      }

      &.horizontal {
        margin-top: -116px;

        .rangeslider-horizontal {
          right: 30px;
          left: 0;
        }
      }
    }
  }
  .sbs-left {
    .sbs-img {
      &.new-os-slider {
        padding-left: 0;
      }
    }
  }

  .sbs-img {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50  scale(1);
      }

      &.section {
        .threesixty-spin {
          transform: $transform-50  scale(0.52);
        }
      }
    }
  }


  .sbs-right {
    .sbs-img {
      &.new-os-slider {
        padding-right: 0;
      }
    }
    .sbs-dropdown {
      padding-right: 0;
    }
    .cs-dropdown:not(.cs-file-dropdown) {
      min-width: 110px;
    }
  }
}

.new-cs-card {
  .sbs-wrapper {
    height: calc(100% - 64px);
    background-color: $level-1;
    border-radius: 12px;

    .cs-fullscreen {
      display: none;
    }
  }

  .sbs-col {
    position: relative;
  }

  .sbs-dropdown {
    .cs-dropdown {
      min-width: 114px;
      z-index: 100;
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 12px 0 8px 0;

      .page-heading-title {
        background: none;
        color: $level-11;
        font-weight: normal;
        padding-left: 12px;
        padding-right: 28px;
        display: inline-flex;
        position: relative;
      }

      i {
        font-size: 12px;
        right: 12px;
      }
    }
  }

  .sbs-right,
  .sbs-left {
    padding-left: 0;
    padding-right: 0;
  }

  .sbs-right {
    .sbs-dropdown {
      .cs-dropdown {
        border-radius:0 12px 0 8px;
        .page-heading-title {
          display: flex;
          .pointer {
            margin-left: auto;
          }
        }
      }
    }
  }

  .both-movement-slider {
    margin-left: -15px;
    top: 10px;
    .link-icon {
      background-color: $level-1;
      border-radius: 8;
      width: 32px;
      height: 32px;
      color: $level-11;
    }
  }

  .cs-right-dropdown {
    right: -8px;

    @media screen and (max-width: 767px) {
      right: 0;

      .page-heading-title {
        .osbtn {
          width: 100%;

          &:not(.web-view-btn) {
            min-height: 40px;
            line-height: 40px;
          }
        }
      }

      .header-dropdown-menu {
        .header-dropdown-list {
          z-index: 999;
        }
      }
    }
  }
}

.desktop-small {
  .sbs-wrapper {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50  scale(0.5);
      }

      &.section {
        .threesixty-spin {
          transform: $transform-50  scale(0.34);
        }
      }
    }
  }

  .full-screen {
    .sbs-wrapper {
      .spin-video {
        .threesixty-spin {
          transform: $transform-50  scale(0.8);
        }

        &.section {
          .threesixty-spin {
            transform: $transform-50  scale(0.4);
          }
        }
      }

      .slider-threesixty {
        &.horizontal  {
          .rangeslider-horizontal {
            right: 38px;
          }
        }
      }
    }
  }
}



.ipad{
  .full-screen {
    .sbs-dropdown {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .sbs-spin-img-wrap {
      &.pl-16 {
        padding-right: 16px;
      }
    }

    .sbs-left {
      .sbs-dropdown {
        .cs-dropdown:not(cs-file-dropdown) {
          .page-heading-title {
            padding-left: 0;
          }
        }
      }
    }

    .sbs-right {
      .sbs-dropdown {
        .cs-dropdown {
          &:not(.cs-file-dropdown) {
            .page-heading-title {
              padding-right: 20px;

              i {
                right: 0;
              }
            }
          }
        }
      }
    }

    .sbs-wrapper {
      .cs-fullscreen {
        right: 0;
      }

      .slider-threesixty {
        &.horizontal  {
          .rangeslider-horizontal {
            right: 38px;
          }
        }
      }
    }
  }

  &.landscape {
    .sbs-wrapper {
      .slider-threesixty {
        &.vertical {
          right: 0;
        }

        &.horizontal  {
          .rangeslider-horizontal {
            right: 46px;
          }
        }
      }

      .spin-video {
        .threesixty-spin {
          transform: $transform-50 scale(0.6);

          @media screen and (min-width: 1300px) {
            transform: $transform-50 scale(.65);
          }
        }

        &.section {
          .threesixty-spin {
            transform: $transform-50  scale(0.38);

            @media screen and (min-width: 1300px) {
              transform: $transform-50 scale(.41);
            }
          }
        }
      }
    }

    .full-screen {
      .sbs-left {
        .sbs-dropdown {
          padding-left: 8px;
        }
      }

      .sbs-right {
        .sbs-dropdown {
          padding-right: 8px;
        }
      }

      .sbs-wrapper {
        .slider-threesixty {
          &.vertical {
            right: -16px;
          }

          &.horizontal  {
            .rangeslider-horizontal {
              right: 38px;
            }
          }
        }

        .spin-video {
          .threesixty-spin {
            transform: $transform-50 scale(0.75);

            @media screen and (min-width: 1300px) {
              transform: $transform-50 scale(1);
            }
          }

          &.section {
            .threesixty-spin {
              transform: $transform-50  scale(0.5);

              @media screen and (min-width: 1024px) {
                transform: $transform-50  scale(.38);
              }
            }
          }
        }
      }
    }

    .sbs-spin-img-wrap {
      &.pl-16 {
        padding-right: 16px;
      }
    }


  }

  &.portrait {
    .sbs-wrapper {
      height: 392px;

      .slider-threesixty {
        &.vertical {
          height: calc(100% - 176px);
          right: 0;
        }

        &.horizontal {
          margin-top: -132px;
        }
      }

      .sbs-spin-img-wrap {
        &.pl-16 {
          padding-right: 16px;
        }
      }

      .cs-fullscreen {
        right: 16px;
      }

      .spin-video {
        .threesixty-spin {
          transform: $transform-50  scale(0.5);
        }

        &.section {
          .threesixty-spin {
            transform: $transform-50  scale(0.39);
          }
        }
      }

      .new-os-slider {
        .slick-slide {
          .slide-img-holder {
            height: calc(100% - 36px);
          }
        }
      }
    }

    .sbs-img {
      text-align: center;

      .case-image-full {
        margin: auto;
        object-fit: scale-down;
      }
    }

    .full-screen {
      .sbs-wrapper {
        height: calc(100vh - 90px);

        .slider-threesixty {
          &.vertical {
            height: calc(100% - 176px);
            right: -28px;
          }

          &.horizontal  {
            .rangeslider-horizontal {
              right: 38px;
            }
          }
        }

        .spin-video {
          .threesixty-spin {
            transform: $transform-50  scale(0.9);

            @media screen and (min-width: 1024px) {
              transform: $transform-50  scale(1.15);
            }
          }

          &.section {
            .threesixty-spin {
              transform: $transform-50  scale(0.41);

              @media screen and (min-width: 1024px) {
                transform: $transform-50  scale(.56);
              }
            }
          }
        }

      }

      .cs-fullscreen {
        right: 0;

        &.rt-16 {
          right: -8px;
        }
      }
    }

    .new-cs-card {
      .sbs-wrapper {
        height: calc(100% - 64px);
      }
    }
  }
}

.mobile-device {
  .sbs-wrapper {
    height: 328px;

    .cs-dropdown  {
      margin-left: 0px;

      .header-dropdown-list {
        max-height: 180px;
      }
    }

    .new-os-slider {
      margin-top: 16px;
      max-height: calc(100% - 104px);

      .slick-slide > div {
        height: calc(100% - 36px);
      }
    }

    .slider-threesixty {
      &.vertical {
        height: 186px;
        right: -4px;
        top: 50px;
      }

      &.horizontal {
        margin-top: -92px;

        .rangeslider-horizontal {
          right: 30px;
          left: 16px;
        }
      }
    }
  }

  .sbs-spin-img-wrap {
    padding-top: 8px;

    &:before {
      height: 272px;
    }
  }



  .sbs-img {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50  scale(0.4);
      }
      &.section {
        .threesixty-spin {
          transform: $transform-50  scale(0.2);
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .sbs-img {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50 scale(0.78);
      }

      &.section {
        .threesixty-spin {
          transform: $transform-50  scale(0.57);
        }
      }
    }
  }
  .full-screen {
    .sbs-img {
      .spin-video {
        .threesixty-spin {
          transform: $transform-50 scale(1.3);
        }

        &.section {
          .threesixty-spin {
            transform: $transform-50  scale(0.7);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2520px) {
  .full-screen {
    .sbs-img {
      .spin-video {
        .threesixty-spin {
          transform: $transform-50 scale(2.2);
        }

        &.section {
          .threesixty-spin {
            transform: $transform-50  scale(1.5);
          }
        }
      }
    }
  }
}

.both-movement-slider {
  position: absolute;
  left: 50%;
  top: 4px;
  margin-left: -20px;
  z-index: 9;

  .movement-link-tooltip {
    display: none;
    position: absolute;
    top: 42px;
    left: -7px;
    background-color: $block;
    border-radius: 6px;
    white-space: nowrap;
    color: $level-11;
    font-size: 12px;
    line-height: 1.5;
    padding: 16px 24px;
    box-shadow: $block-xs;

    .ifill-os-info {
      color: $lt-red;
      display: inline-block;
      margin-right: 8px;
    }

    &:before {
      content: none;
      border: 10px solid transparent;
      border-bottom-color: $block;
      position: absolute;
      top: -20px;
      left: 16px;
    }
  }

  .link-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;

    &:hover {
      color: $level-9;
      background-color: $level-4;
      box-shadow: $block-xs;

      + .movement-link-tooltip {
        display: block;
      }
    }
  }
}

.new-cs-card {
  .both-movement-slider {
    .movement-link-tooltip {
      left: 50% !important;
      transform: translateX(-50%) !important;

      &:before {
        left: 50% !important;
        transform: translateX(-50%) !important;
      }
    }
  }
}

.dropdown-group-header {
  pointer-events: none;
  opacity: 0.5;
  padding: 8px 12px;
}

.mobile-device {
  .both-movement-slider {
    .movement-link-tooltip {
      left: -94px;
      font-size: 8px;

      &:before {
        left: 103px;
        top: -18px;
      }
    }
  }
}
