.user__badge {
  font-size: 8px;
  font-weight: 300;
  color: #48acfd;
  border-radius: 1px;
  border: solid 1px #48acfd;
  width: 46px;
  text-align: center;
  display: none;
  align-items: center;
  justify-content: center;
  margin: 10px auto 0;
  line-height: 10px;
  position: absolute;
  left: 50%;
  margin-left: -23px;
  bottom: 32px;
  height: 13px;
  span {
    position: absolute;
    top: 0;
    line-height: 11px;
  }
  &.trial-expired-border {
    border-color: #646464;
    color: #646464;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .user__badge {
    bottom: 25px;
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) {
  .user__badge {
    bottom: 36px;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .user__badge {
    bottom: 25px;
  }
}

.bg-teal {
  background-color: $lt-teal;
}

.bg-yellow {
  background-color: $lt-yellow;
}

.bg-purple {
  background-color: $lt-purple;
}

.bg-red {
  background-color: $lt-red-bg;
}

.bg-orange {
  background-color: $lt-orange;
}

.bg-blue {
  background-color: $lt-blue;
}

.bg-green {
  background-color: $lt-green-bg;
}
