.drag-drop-modal {
  .modal-content {
    width: 768px;
    padding: 40px 40px 24px;
  }

  .modal-header {
    margin-bottom: 24px;
  }

  .modal-title {
    line-height: 40px;
  }

  .modal-footer {
    margin-top: 0;
    padding: 0;
  }

  .loader-active {
    height: 512px;
  }

  .create-space-slider {
    margin-bottom: 24px;
  }

  .banner-selected-image {
    max-height: 100%;
    height: 100%;
  }

  .uploaded-image-block-holder {
    margin-bottom: 8px;
  }
}

.desktop-small,
.ipad.landscape {
  .drag-drop-modal {
    .modal-content {
      padding: 24px;
    }
  }

  .spaces.modal-open {
    .drag-drop-modal {
      .modal-content {
        height: 272px;
      }
    }
  }
}


.ipad {
  &.portrait {
    .drag-drop-modal {
      .modal-content {
        margin-top: 64px;
        padding: 24px 24px 32px;
        max-width: 720px;
      }

      .loader-active {
        height: 436px;
      }

      .create-space-slider {
        margin-bottom: 40px;
      }
    }
  }
}

.image-upload-banner {
  padding: 5px 36px;
  @media(min-width: 768px) {
    display: flex;
    align-items: center;
    height: 100%;

    .browse-image-col {
      width: 160px;
      flex: 0 0 160px;
      height: 160px;
      margin-right: 8px;
    }
  }
  .upload-banner-text {
    color: $level-11;
    text-align: left;
  }

  p {
    font-size: 12px;
    color: $level-8;
    margin: 8px 0 0 0;
  }
}

.mobile-device {
  &.portrait {
    .drag-drop-modal {
      .modal-content {
        padding: 44px 16px;
      }
      .modal-header {
        margin-bottom: 40px !important;
      }

      .uploaded-image-block-holder {
        margin: 0 0 40px 0;
      }

      .os-header-btn {
        right: 0;
      }

      .modal-footer {
        &.edit-space-modal-footer {
          justify-content: center;

          .mr-auto {
            margin-right: 0px !important;
          }

          > .save-cancel-btn {
            display: flex;
            align-items: center;
            margin-top: 16px;
            flex-direction: column;
            width: 100%;

            .osbtn {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
