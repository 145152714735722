.appointment-modal {
  @media (min-width: 1025px) {
    max-width: 1008px;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    max-width: 92%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 90%;
  }

  .modal-content {
    padding: 40px;
    box-shadow: none;
  }

  .modal-body {
    padding: 24px 0 0 0;
  }

  @media (max-width: 767px) {
    margin: 0;
  }
}
.appointment_nav {
  border: 0;
  margin-bottom: 32px;

  .nav-link  {
    @include fontstyle(16px, 600, 36px)
    color: $tab;
    margin-right: 24px !important;
    padding: 0px 8px;
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid transparent;
    outline: none;

    &:hover, &:focus, &.active {
      color: $tab-active;
      border-color: transparent;
      background-color: transparent;
      border: 2px solid transparent;
      border-bottom: 2px solid $tab-active;
      outline: none;
    }
  }
}

.new-appoint-footer {
  padding: 0;
  margin: 32px 0 0 0;
  width: 100%;

  .row {
    width: 100%;
    justify-content: space-between;
    margin: 0;
  }
}

.tab-content {
  margin-top: 24px;
}

.add-notes {
  background: $modal-bg;
  color: $level-11;
  font-size: 1rem;
  min-height: 40px;
  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
  height: calc(100% - 32px);
  border-color: transparent;
  resize: none;
  min-height: 120px;
  border: 1px solid $border-input-typed-button-2;
}

.drag-drop-text {
  @include fontstyle(12px, normal, 16px);
  color: $level-11;
  display: block;
}

.appointment-info {
  .form-group  {
    margin-bottom: 24px;
    position: relative;
    .with-error {
      position: absolute;
      bottom: -18px;
    }
  }

  label {
    &:not(.label-referral) {
      &:after {
        content:'*';
        color: $os-link;
      }
    }

    &.not-mandatory {
      &:after {
        content: none;
      }
    }

     &.label-treatment {
      &:after {
        content: none;
      }

      .started-text {
        margin-right: 4px;
      }
    }

  }

  input.error {
    border-color: $lt-red;
  }

  .textarea-note {
    .form-group {
      height: 100%;

      .with-error {
        bottom: 5px;
      }

      .add-notes {
        height: calc(100% - 56px);

        @media (min-width: 768px) and (max-width: 992px) {
          height: 150px;
        }
      }
    }
  }



}

.date-calendar {
  .ifill-os-calendar {
    position: absolute;
    right: 16px;
    top: 43px;
    z-index: 1;
  }

  .react-datepicker-wrapper {
    .form-control {
      padding-left: 15px;
      padding-right: 15px;
      color: $level-11;
    }
  }

  .react-datepicker {
    width: 320px;
  }
}

.appointment-type-dropdown {
  border: 1px solid $border-button-1;
  border-radius: 8px;
  .page-heading-title  {
    background-color: $block;
    line-height: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    color: $level-11;

    .first-elem-icon {
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  .header-dropdown-list {
    padding: 0;
    width: 100%;
    border-radius: 0 0 4px 4px;
  }

  .type-dropdown-item {
    padding: 12px 16px;

    &:hover {
      background-color: $dropdown-list-hover;
      color: $dropdown-hover-text;
    }
  }

  .ifill-os-chevron-caret-down,
  .ifill-os-chevron-caret-up {
    right: 16px;
    color: $level-11;
  }
}


@media (min-width: 768px) and (max-width: 1023px) {
  max-width: 80%;
}

@media (max-width: 767px) {
  .appointment-modal {
    .modal-content {
      border: 0;
      border-radius: 0;
      padding: 24px 12px;
    }

    .modal-dialog {
      margin: 0;
    }

    .os-header-btn {
      top: 6px;
      right: 10px;
    }

    .add-notes {
      min-height: 200px;
    }

    .modal-footer {
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 16px;

      .col {
        padding: 16px 0 0 0;
        text-align: center !important;
      }
    }

    .nav-search-container {
      flex-direction: column;
    }

    .tab-content {
      height: calc(100% - 60px);

      .tab-pane {
        height: 100%;
      }
    }

  }

  .appointment_nav {
    .nav-link {
      font-size: 12px;
      padding: 0px 4px;
      margin-right: 8px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}
