.assigneeContainer {
  position: relative;
  &:hover {
    .assigneeList {
      visibility: visible;
      transition: 0.5s all ease;
      transition-delay: 0s;
    }
  }

      .assigneeList {
        position: absolute;
        z-index: 10;
        visibility: hidden;
        width: 180px;
        max-height: 208px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        margin: 40px 0 0;
        padding: 0 0 8px;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
        background-color: #fff;
        overflow: scroll;

        .assigneeWrapper {
          min-height: 40px;
          align-self: stretch;
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 16px;
          padding: 4px 8px 4px 16px;
          cursor: pointer;

          &:hover {
            background-color: #edeff3;
          }

          .assigneeAvatar {
            width: 24px;
            height: 24px;
          }

          .assigneeName {
            width: 116px;
            min-height: 16px;
            flex-grow: 0;
            font-family: Inter;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #354554;
          }
        }
      }
    }

    .scheduledTaskContainer {
      position: relative;

  &:hover {
    .customPredefinedDates {
      visibility: visible;
      transition: 0.5s all ease;
      transition-delay: 0s;
    }
  }

  .customPredefinedDates {
    visibility: hidden;
    position: absolute;
    top: 0px;
    left: 170px;
    z-index: 10;
    width: 180px;
    max-height: 160px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 0 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
    background-color: #fff;

        .predefinedDates {
          min-height: 40px !important;
          align-self: stretch;
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 16px;
          padding: 4px 8px 4px 16px;
          cursor: pointer;

          &:hover {
            background-color: #edeff3;
          }

          .date {
            min-height: 16px;
            flex-grow: 0;
            font-family: Inter;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #354554;
          }

          .datePlaceholder {
            width: 112px;
            height: 32px;
            align-self: stretch;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px;
            border-radius: 8px;
            background-color: #f4f7f9;

            .dateplaceholderText {
              height: 16px;
              flex-grow: 1;
              font-family: Inter;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              text-align: left;
              color: #7e91a5;
            }
          }
        }

        .calendar {
          position: absolute;
          visibility: visible;
          z-index: 10;
          left: 240px;
          width: 300px;
          overflow: visible !important;
          box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);

          input {
            height: 40px;
            width: 310px;
          }
        }
      }
    }