$popular-col-width: 288px;

%react-select-ortho-2 {
  max-width: 100%;

  .Select-menu-outer {
    max-height: 200px;
  }

  .os__placeholder {
    color: $level-8;
  }

  .os__indicators {
    position: absolute;
    right: 20px;
    top: 0;
    height: 38px;
    color: $level-11;

    &:after {
      content: none;
      font-family: 'fill-os-icon';
      position: absolute;
      right: -32px;
      cursor: pointer;
    }

    svg {
      margin-top: 4px;
    }
  }

  .os__single-value {
    max-width: calc(100% - 26px);
    color: $level-11;
    text-align: left;
  }

  .os__control--menu-is-open {
    .os__indicators {
      &:after {
        content: none;
        font-family: 'fill-os-icon';
      }
    }
  }

  .os__input-container {
    color: $dropdown-text;
  }

  .os__clear-indicator {
    &:after {
      position: absolute;
      width: 1px;
      height: 12px;
      content: '';
      right: -2px;
      background-color: $level-8;
      top: 12px;
    }
  }

  .os__control {
    max-height: 170px;
    overflow-x: hidden;
    overflow-y: auto;
    cursor: pointer;
    background-color: $input-bg;
    border-color: $input-border;
    border-radius: 8px;
    color: $input-text-color;
  }

  .Select-menu,
  .os__menu-list {
    max-height: 140px;
    font-size: 14px;
    overflow-y: auto;
    list-style: none;
  }

  .os__menu {
    border-radius: 8px;
    box-shadow: $block-shadow;
    background-color: $dropdown-bg;
    margin-top: 2px;
    z-index: 11;
  }

  .os__menu-list {
    z-index: 2;

    .os__option {
      cursor: pointer;
      padding: 0;
      color: $dropdown-text;

      &:last-child {
        border-radius: 0 0 8px 8px;
      }

      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:only-child {
        border-radius: 8px;
      }

      >div,
      >span {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 8px 16px;
      }

      &:hover,
      &.os__control--is-focused,
      &.css-1fiepyl-option,
      &.css-sfecft-option {
        background-color: $dropdown-list-hover !important;
        color: $dropdown-hover-text;
      }
    }

    .result-text-data {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .avatar-combo {
    position: relative;

    .avatar {
      vertical-align: middle;
      top: -4px;
      position: relative;

      &:only-child {
        top: 0;
      }
    }

    .avatar+.avatar {
      position: absolute;
      top: 4px;
      left: 10px;
    }
  }

  .avatar {
    @include avatar(24px);
    margin-right: 14px;
  }

  .Select-control {
    min-height: 40px;
    border: 1px solid transparent;
  }

  .Select-value {
    position: relative;
    padding-right: 20px;
  }

  .Select-input {
    height: 40px;

    >input {
      padding-top: 0px;
      padding-bottom: 0px;
      height: 40px;
    }
  }

  .Select-value-icon {
    right: 6px;
    position: absolute;
    z-index: 9999;
    background: transparent !important;
    border-radius: 0;
    width: 18px;
    height: 18px;
    color: $level-1;
    line-height: 15px;
    text-transform: uppercase;
    font-size: 20px;
    top: 6px;
    text-align: center;
    padding: 0;
    font-family: os-icon;
    font-size: 0;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    box-shadow: none;
    border: 0;

    &:before {
      content: "\E947";
      font-size: 8px;
    }
  }

  .Select-clear-zone {
    width: 0;
  }

  .Select-arrow-zone {
    display: none;
    width: 0;
    padding-right: 0;
  }

  .Select-placeholder {
    color: $level-9;
    line-height: 40px;
  }

  .Select-value {
    position: relative;
    padding: 0 34px 0 5px;
    background: $level-4;
    margin-top: 3px;
    color: #fff;
    line-height: 25px;
  }

  .Select-value-label {
    background-color: $level-4;
  }

  .Select-multi-value-wrapper {
    overflow-y: auto;
    display: block;
    min-height: 40px;
    max-width: 472px !important;
    overflow-x: auto;
  }
}

%pulse-right-block {
  background-color: $block;
  padding: 12px 20px;
  margin-bottom: 4px;

  @media screen and (max-width: 1366px) {
    border-radius: 12px;
    box-shadow: $block-shadow;
    padding: 24px;
  }
}

.react-select-ortho-2 {
  @extend %react-select-ortho-2;
}

.os__value-container {
  .os__multi-value {
    background-color: $level-7;
  }
}

.no-touch-support,
.ipad {
  .explore-container {
    padding-top: 24px;
  }

  .pulse-detail-container {
    padding-top: 24px;
  }
}

.ipad.portrait {
  .collection-page {
    &.pulses {
      padding-top: 16px;
    }
  }
}

@media screen and (max-width: 992px) {
  .explore {
    .explore-container {

      .col-md-8,
      .col-md-4 {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .container {
        max-width: 100%;
      }
    }
  }
}

.px-52 {
  padding-left: 48px;
  padding-right: 48px;
}


.posts,
.explore {
  .sub-nav-background {
    padding-top: 30px;

    .container {
      padding: 0px;
    }
  }

  .case-hash-tag-listing {
    display: none;

    .fieldset {
      display: flex;
      align-items: flex-start;
    }

    .legend {
      @include fontstyle(1rem, 400, 32px);
      white-space: nowrap;
      margin: .5rem 0;
    }

    .checkbox-comment-list {
      @include fontstyle(1rem, 400, 32px);
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex: 1 1;
      padding-left: .5rem;
    }

    .custom-checkbox {
      position: relative;
      padding: 0 5px;
      border-radius: 4px;
      color: $os-link;
      cursor: pointer;
      margin: .5rem 0 0 0;
      transition: all 500ms ease;
      margin-right: 4px;
      white-space: nowrap;

      &.active {
        background: #os-blue;
        color: rgba(255, 255, 255, 0.9);
      }

      &:hover {
        background-color: rgba(72, 172, 253, 0.2);
      }
    }

    .custom-control-input {
      left: 0;
      top: 0;
      z-index: 1;
      cursor: inherit;
      height: 100%;
    }
  }
}

.post-detail,
.feeds-view {
  margin-bottom: 16px;

  .create-slider {
    margin-top: 1rem;
  }
}

.sidebar-container {
  @extend %pulse-right-block;

  @media screen and (min-width: 1920px) {
    padding-right: 52px;
  }

  &.px-16 {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  @media screen and (min-width: 768px) {

    &.tip-sidebar-container,
    &.EtiqutteBlock {
      padding: 24px 40px 32px 24px;
    }
  }
}


.invite-user-section {
  @include fontstyle(12px, 400, 16px);

  .widget-title {
    margin-bottom: 16px;

    .invite-user-count {
      font-size: 12px;
      color: $lt-orange;
      display: block;
      margin-top: 8px;
      font-weight: normal;
    }
  }

  .invite-user-blocks {
    display: flex;
    justify-content: space-between;
  }

  img {
    max-width: 140px;
  }

  .invite-user-link {
    color: $level-11;
    font-weight: $font-weight-bold;
  }

  .invite-explore-tab {
    margin-top: 10px;
  }
}

.no-touch-support {
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .invite-user-section {
      .invite-user-blocks {
        flex-direction: column;
        text-align: center;

        .invite-user-info {
          margin-top: 10px;
        }
      }
    }
  }
}

.code-etiquette-section {
  @include fontstyle(12px, 400, 16px);

  .widget-title {
    margin-bottom: 24px;
  }

  .code-etiquette-blocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mr-12 {
    margin-right: 16px;
  }

  svg {
    max-width: 120px;
    max-height: 120px;
    display: block;
  }

  .code-etiquette-info {
    font-size: 16px;
    line-height: 1.5;
  }

  .learn-more-link {
    cursor: pointer;
    margin-top: 20px;
    text-transform: uppercase;
    @include fontstyle(12px, normal, 16px);
    text-decoration: none;
    display: block;
    color: $os-link;
  }


  .code-etiquette-tab {
    margin-top: 10px;
  }
}

.ask-question-blocks {
  padding-top: 16px;

  p {
    margin-top: 8px;
    margin-bottom: 16px;
    text-align: left;
  }

  .code-etiquette-info {
    color: $level-11;
    text-align: center;
  }

  .osbtn {
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 auto;
  }

  @media(min-width: 1200px) {
    padding-bottom: 8px;

    .code-etiquette-blocks {
      align-items: unset;
    }

    .code-etiquette-info {
      padding-left: 9px;
      text-align: left;
    }

    p {
      padding-left: 12px;
      margin-bottom: 32px;
    }
  }
}


.guided-tour-section {
  @include fontstyle(16px, 400, 1.5);

  .widget-title {
    margin-bottom: 12px;
  }

  .guided-tour-blocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .guided-tour-info {
    color: $level-11;
    margin-top: 16px;
  }

  .guided-tour-link {
    padding-left: 24px !important;
  }

  .guided-tour-tab {
    margin-top: 10px;
  }
}

.popular-topics {
  .widget-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $heading;

    .tag-view-more {
      margin-right: 0;
    }

    .plus-btn {
      max-height: 40px;
    }
  }

  .hashtag-blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .hashtag-blocks {
    padding-top: 16px;
  }

  .hashtags {
    @include fontstyle(12px, 600, 32px);
    color: $level-11;
    padding: 0 12px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 16px;
    background: $tag-bg;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &.active,
    &[data-hover="true"]:hover {
      color: $tag-hover-text;
      box-shadow: $tag-shadow;
    }
  }
}

.pulse-get-started {
  max-width: $popular-col-width;

  .get-started-img-block {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 14px;
  }

  i {
    display: none;
  }

  .get-started-links-holder {
    list-style: none;
    font-size: 12px;
    padding-left: 0;

    li {
      text-align: center;
    }

    a {
      .only-text {
        color: $level-11;
      }
    }

    .get-started-links {
      cursor: default;
      text-decoration: none !important;
    }
  }

  img {
    max-width: 100%;
  }
}

.three-block-sticky,
.two-block-sticky {
  display: block;
}

.spaces{
  .right-wrap{
    position: sticky;
    left: 0;
    top: 0;
    height: 100vh;
    padding-top: 0;
    max-height: 100vh;

    .careSpaceDetail{
      height: calc(100% - 60px);
      overflow-y: auto;

      .three-block-sticky{
        top: 0 !important;
      }
    }
  }
}
.three-block-sticky {
  position: relative;
}

.posts {
  .activity-listing-item-content {
    padding: 24px 32px !important;

    .user-info-row {
      margin-top: 0;
    }

    .avatar-listing {
      position: relative !important;
      left: 0 !important;
    }
  }
}

@media screen and (max-height: 2000px) {
  %sidebar_fixed {
    position: sticky;
    //   top: 90px;
  }

  html:not(.mobile-device) {
    .sidebar_fixed {
      @extend %sidebar_fixed;

      //   &.pulse-detail {
      //     top: 16px;
      //   }
      //   &.user-detail {
      //     top: 60px;
      //   }

      &.explore {
        margin-bottom: 54px;
      }
    }
  }
}

.add-post-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  word-break: break-word;
  border-radius: 12px;
  position: relative;

  &.mb-24 {
    margin-bottom: 24px;
  }

  .filedrop-overlay {
    background: rgba(34, 34, 38, 0.9);
    border: 1px dashed $cl-link;

    .ifill-os-uploading {
      font-size: 62px;
      margin-bottom: 16px;
    }
  }

  .media-body {
    .comment-error {
      margin-left: 0 !important;
    }
  }

  .detail-comments-top-block {
    flex-direction: column;

    .comments-primary-avatar {
      position: relative;
      left: 0;
      top: 0;
    }

    .feed-author-info {
      @extend %heading-3-semibold;
      display: inline-block;
      padding-left: 0;
    }

    .media-comment-head {
      margin-bottom: 6px;
    }

    .media-body {
      width: 100%;
    }

    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      min-height: 132px;
      border-color: $level-3;
    }
  }

  .reply-buttons {
    display: none;
  }

  .react-select-ortho-2 {
    .os__menu {
      z-index: 11;
    }
  }

  .btn-group-right {
    white-space: nowrap;
  }
}

.add-post-user-name {
  display: none;
}

.post-avatar {
  position: absolute;
  top: 12px;
  left: 0px;
  z-index: 1;
  min-width: 48px;
  padding-left: 12px;
}

.post-footer-section {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) and (max-width: 1300px) {
    align-items: flex-start;
  }

  .react-select-ortho-2 {
    @extend %react-select-ortho-2;
  }

  .post-photo-field {
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    z-index: 2;
    font-size: 12px;
  }
}

.select-box-wrap {
  width: 245px;
  display: inline-block;
  position: relative;
  margin-right: 8px;

  .comment-error {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .single-select {
    z-index: 9;

    .os__placeholder {
      white-space: nowrap;
      @include text-truncate();
      max-width: 186px;
      display: block;
    }
  }

  .close-single-select {
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 9;
    width: 30px;
    min-width: 30px;
    padding: 0px 12px;
    background: none !important;
    box-shadow: none !important;

    .btn-icon-helper {
      font-size: 16px;
      color: inherit !important;
    }

    &:hover {
      background-color: transparent;
      box-shadow: none;
      color: $level-10;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    margin-bottom: 16px;
  }
}

.share-post-dropdown {
  margin-right: 8px;
  display: inline-block;

  .post-can-view-dropdown {
    margin-left: 0;
  }

  .osbtn {
    &.error {
      border: 1px solid $lt-red;
      border-radius: 4px;
      color: $lt-red;
    }
  }

  .dropdown-info-title {
    border: 1px solid transparent;

    &.error {
      border: 1px solid $lt-red;
      border-radius: 4px;

      .page-heading-title {
        color: $lt-red;

        .ifill-os-chevron-caret-down,
        .ifill-os-chevron-caret-up {
          color: $lt-red;
        }
      }
    }
  }

  .page-heading-title {
    padding-left: 16px;
    padding-right: 16px;
    text-transform: uppercase;
    height: 38px !important;
    font-weight: 600;
    color: $level-11;

    .ifill-os-chevron-caret-down,
    .ifill-os-chevron-caret-up {
      right: 16px;
    }

    @media screen and (max-width: 767px) {
      padding-left: 8px;
      padding-right: 8px;

      .ifill-os-chevron-caret-down,
      .ifill-os-chevron-caret-up {
        right: 8px;
      }

    }
  }

  .header-dropdown-list {
    min-width: 175px;
  }

  .comment-error {
    margin-left: 0 !important;
    padding-left: 0;
  }

  &.only-select {
    .osbtn-icon {
      padding-left: 16px;
      padding-right: 40px;

      .btn-icon-helper {
        color: #c6c6cf;
      }

      &:hover {
        .btn-icon-helper {
          color: $os-link;
        }
      }
    }
  }
}

html {
  &:not(.workspace) {
    .explore-container {
      .comment-edit-container {
        margin-top: 16px;
      }
    }
  }

  .post-comment-footer-action {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .react-select-ortho-2 {
      @extend %react-select-ortho-2;

      .os__control {
        border-color: $btn-icon-border;
      }
    }

    .osbtn {
      max-height: 40px;
    }

    .post-comment-footer {
      display: inline-block;
      max-width: 140px;
      margin-left: 16px !important;

      @media screen and (max-width: 767px) {
        max-width: 165px;
      }

      .osbtn {
        margin-left: 0 !important;
      }
    }
  }
}

.mention-textarea-wrap__highlighter,
.mention-textarea-wrap__input {
  word-break: break-word;
}

.post-textarea-wrap {
  position: relative;
  margin-top: 8px;

  .mention-textarea-wrap__highlighter,
  .mention-textarea-wrap__input {
    min-height: 96px;
    padding-left: 44px;
    padding-right: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mention-textarea-wrap__input {
    caret-color: $os-link;
  }

  .mention-textarea-wrap__suggestions {
    top: auto !important;
    margin-top: 1px !important;
  }
}

.ifill-os-uploading {
  &.icon-orange {
    color: $lt-orange;

    &:before {
      color: inherit;
    }
  }
}

.os-alert {
  background-color: $block;
  border-radius: 4px;
  @include fontsl(12px, 1.5);
  padding: 8px 24px;
  display: flex;

  &.os-danger {
    color: $lt-red;
  }

  &.os-orange {
    color: $lt-orange;
  }
}

.create-pulse {
  background-color: $block;
  border-radius: 4px;
  padding: 8px 24px 8px 50px;
  display: flex;
  width: 100%;
  position: relative;
  height: 40px;
  display: none;
  box-shadow: $block-shadow;

  .post-avatar {
    top: 8px !important;
  }

  .placeholder {
    color: $level-9;
  }
}

.pulse-post-area {
  .media-body {
    margin: 0;
  }

  .detail-comments-top-block {
    .comment-error {
      top: 100% !important;
      margin-top: 5px;

      .ifill-os-error {
        margin-right: 4px;
      }
    }
  }
}

.hashtag-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 44px;
  margin-bottom: 20px;
  margin-bottom: 16px;
  padding-right: 16px;

  .tag-item {
    margin: 0;
    height: auto;
    @include fontstyle(12px, 600, 32px);
    padding: 0 12px;
    border-radius: 8px;
    background-color: $tag-bg;
    margin-left: 24px;
    transition: color 0.2s ease-in-out, box-shadow 0.2s ease;
    color: $level-11;
    will-change: color, box-shadow;
    display: inline-flex;
    align-items: center;

    .ifill-os-close {
      text-shadow: 1px 0px $level-11;
      margin-left: 4px;
    }
  }
}

.comment-title-head {
  .hashtag-filter {
    .tag-item {
      display: flex;
      align-items: center;

      .tag-item-text {
        @include text-truncate();
        max-width: 350px;
        display: inline-block;

        @media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
          max-width: 220px;
        }

        @media screen and (max-width: 767px) {
          max-width: 153px;
        }
      }

      i {
        top: 0;
      }
    }
  }
}

.no-touch-support {
  .hashtag-filter {
    .tag-item {
      &:hover {
        color: $tag-hover-text;
        box-shadow: $tag-shadow;
      }
    }
  }

  .back-link {
    margin-top: 32px;
    display: block;

    &.mt-20 {
      margin-top: 20px;
    }
  }

  .get-started-links {
    .hover-underline {
      position: relative;
      z-index: 1;
    }
  }

  @media(max-width: 991px) {
    .sidebar_fixed {

      .three-block-sticky,
      .two-block-sticky {
        top: 0 !important;
      }
    }
  }
}

.back-link {
  margin-top: 32px;
}

.day-dropdown-with-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  position: relative;
  z-index: 10;
  background-color: $body-bg;
  transform: translate3d(0, 0px, 0);
  transition: transform 300ms;
  width: 100%;

  @media screen and (min-width: 1024px) {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
    padding-left: 12px;
  }

  .gr_with-date {
    min-height: 40px;
    font-size: 12px;
    line-height: 40px;
    text-transform: uppercase;
  }

  .day_with_caret {
    @extend %heading-2-semibold;
    color: $level-11;

    .pateint-access-tooltip {
      font-size: 16px;
      margin-left: 8px;
    }

    .tab-link-tooltip {
      bottom: auto;
      top: 100%;
      width: 272px;

      i {
        display: none;
      }

      .tooltip-container {
        padding: 16px;
        box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
        font-weight: normal;
      }

      @media screen and (min-width: 768px) and (max-width: 1366px) {
        left: 34px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding-left: 0;
    padding-top: 16px;
    top: 0px;
    margin-left: -16px;
    margin-right: -16px;
    width: calc(100% + 24px);
    padding-left: 16px;
    z-index: 0;

    &.sticky {
      z-index: 9;
    }
  }
}

.scroll-up {
  @media screen and (min-width: 768px) {
    .day-dropdown-with-date.sticky {
      transform: translate3d(0, 64px, 0);
    }
  }
}

.scroll-down {
  .users {
    .day-dropdown-with-date.sticky {
      transform: translate3d(0, 42px, 0);
    }
  }
}

.mr-2 {
  margin-right: 8px;
}



.group-by-space-name {
  background-color: $level-3;
  padding: 12px 32px;
  color: $level-9;
  font-size: 12px;
  border-radius: 12px 12px 0 0;
  display: flex;
  justify-content: space-between;
  line-height: 16px;

  &.processed {
    background-color: $level-4;
  }

  +.activity-listing-item {
    border-radius: 0 0 12px 12px;
  }

  .username-space {
    color: $level-8;
    font-weight: 300;
  }

  .timestamp {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: $level-8;
    font-size: 12px;
    flex: none;
    order: 2;
    flex-grow: 0;

    .dot-icon {
      padding: 0 8px;
    }
  }




  .white-link {
    color: $level-8;

    +.white-link {
      color: $level-10;
      font-weight: $font-weight-semibold;

      &:hover {
        color: $os-link;
      }
    }

    &:only-child {
      color: $level-10;
      font-weight: $font-weight-semibold;
    }
  }

  .timeline {
    .group-by-space-name {
      .white-link {
        color: $level-10;
        font-weight: $font-weight-semibold;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    font-size: 16px;
    line-height: 24px;
    padding: 12px 48px;
  }

  @media screen and (max-width: 767px) {
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
    min-height: 32px;
    height: auto;

    i {
      margin-top: 2px;
    }
  }
}

.remove-background{
  background-color: #f8f8f8 !important;
}

.internal-note{
  background-color: #3c759e !important;
  color: #fff !important;

  .username-space,.me-2{
    color: #fff !important;

    a{
      color: #fff !important;
      &:hover{
        color: #fff !important;
      }
    }
  }
}
 .only-internal-note{
  display: flex;
  justify-content: end;
 }

.activity-listing-item {
  .group-by-space-name {
    position: relative;
    height: auto;
    width: 100%;
  }
}

.activity-log-view {
  box-shadow: none;

  .group-by-space-name {
    display: flex;
    justify-content: center;
  }
}

.mobile-device {
  .users {
    .day-dropdown-with-date {
      top: 110px;
    }
  }

  .explore {
    .explore-container {
      padding-top: 24px;
    }
  }
}

html:not(.mobile-device) {
  .group-by-space-name {
    align-items: center;
  }
}

.mobile-device {
  .pulse-card-overlay {
    +.group-by-space-name {
      margin: 0 0;
      width: 100%;
    }
  }

  .posts {
    .activity-listing-item-content {
      padding: 16px 12px !important;

      .user-info-row {
        margin-top: 0;
      }

      .avatar-listing {
        position: relative !important;
        left: 0 !important;
      }
    }
  }

  .activity-listing-item {
    &.pinned-post {
      .activity-listing-item-content {
        padding-top: 32px;
      }

      .pinned-post-block {
        margin-left: 0;
      }
    }

    &.trending-case-block {
      padding: 16px 12px;
    }
  }

  .px-52 {
    padding-left: 0;
    padding-right: 0;
  }

  .only-general-user {
    margin-top: 24px;
  }

  .feeds-view {
    max-width: 100%;
  }

  .post-detail {
    max-width: 100%;
  }

  .explore {
    .dropzone-instruction {
      .file-finder-btn {
        width: auto;
      }
    }

    .page-header-dropdown-menu {
      .page-heading-title {
        padding-left: 0px;
        position: relative;
      }
    }
  }

  .back-link {
    display: block;
    margin-top: 32px;
    margin-bottom: 16px;
    margin-left: 20px;

    .ifill-os-chevron-left {
      vertical-align: middle;
    }

    .d-none-m,
    &.d-none-m {
      display: none;
    }
  }

  .detail-comments {
    .mobile-hashtag {
      flex-basis: calc(100% + 24px);
      max-width: calc(100% + 24px);
      width: calc(100% + 24px);
      margin: 0 -12px;
    }
  }

  .popular-topics {
    .hashtag-blocks {
      flex-wrap: nowrap;
      overflow-x: auto;
    }


    .hashtags {
      margin-bottom: 8px;
      text-transform: capitalize;
      margin-right: 8px;
    }
  }

  .pulse-detail-data-col {
    .activity-listing-item {
      .comment-edit-cancel {
        position: absolute;
        right: 4px;
      }
    }
  }

  .pulse-post-area {
    .add-post-area {
      background: none;
      border-radius: 0;
      box-shadow: none;
    }

    .comment-title-head {
      padding-top: 0px;
    }
  }

  &.portrait {
    .post-avatar {
      top: 15px;
    }

    .create-pulse {
      display: flex;
      margin-top: 24px;

      .post-avatar {
        top: 7px !important;
      }
    }

    .post-textarea-wrap,
    .detail-comments-top-block {
      .mention-textarea-wrap__highlighter {
        min-height: 155px;
      }

      .mention-textarea-wrap__input {
        min-height: 155px;
      }
    }

    .hashtag-filter {
      .tag-item {
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 10px;
      }
    }

    .post-mobile-top {
      background-color: $block;
      padding-top: 24px;
      padding-bottom: 4px;
      margin-bottom: 20px !important;
    }

    .add-post-btn {
      background-color: $btn-primary;
      width: 72px;
      height: 72px;
      position: fixed;
      bottom: 30px;
      right: 20px;
      border-radius: 50%;
      z-index: 9;
      color: $btn-primary-color;

      .btn-icon-helper {
        font-size: 20px;
        color: inherit;
      }
    }

    .head-sticky-mobile {
      position: sticky;
      top: 70px;
      background-color: $block;
      padding-top: 10px;
      padding-bottom: 10px;
      z-index: 9;
    }

    .activity-container {
      padding-top: 0;
    }

    .mobile-no-style {
      display: flex !important;
      flex-direction: column;
    }

    .create-slider {
      width: 100%;
    }
  }
}

.small-widget {
  padding: 16px 16px 16px 24px;

  .code-etiquette-section {
    padding-left: 48px;

    .widget-title {
      @extend %heading-4-1;
      margin-bottom: 8px;
    }

    .code-etiquette-info {
      @extend %caption-default;
      color: $level-9;
    }

    .svg-image {
      position: absolute;
      left: 0;
      top: 0;
      margin-right: 0;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .osbtn {
      display: none;
    }
  }
}

.ipad {
  .pulse-post-area {
    margin-bottom: 65px;
  }

  .post-textarea-wrap {
    .mention-textarea-wrap__highlighter {
      max-height: 30vh !important;
    }
  }

  .user-invitation-container:not(.no-invited-user-container) {
    .invite-user-section {
      .invite-user-info {
        max-width: 160px;
      }
    }
  }

  .no-invited-user-container .invite-user-section {
    .invite-user-blocks {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .invite-user-info {
      margin: 16px auto 7px;
    }
  }

  .code-etiquette-section {
    .code-etiquette-blocks {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .code-etiquette-info {
      margin-top: 16px;
      margin-bottom: 7px;
    }
  }

  &.portrait {
    .invite-user-section {
      @include fontsl(16px, 1.5);
    }

    .sidebar-container {
      width: 100%;
      min-height: 268px;

      @media screen and (max-width: 768px) {
        min-height: 276px;
      }
    }

    .explore-etiqutte {
      .col-6 {
        padding: 0px !important;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .sidebar-container {
        padding-bottom: 10px;
      }

      .code-etiquette-section {

        .code-etiquette-info {
          margin-top: 20px;
          margin-bottom: 2px;
          font-size: 12px;
          line-height: 1.2;
        }

        .mr-12 {
          margin-right: 0px;
        }

        .widget-title {
          margin-bottom: 15px;
        }
      }
    }

    .popular-user-with-tags {
      .col-6 {
        margin-bottom: 24px;

        &:first-child {
          padding-left: 0;
        }

        &:nth-child(2) {
          padding-right: 0;
        }

        .sidebar-container {
          margin-bottom: 0;
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .explore-etiqutte {
        .sidebar-container {
          padding-bottom: 10px;
        }

        .code-etiquette-section {

          .code-etiquette-info {
            margin-top: 8px;
          }

          .widget-title {
            margin-bottom: 8px;
          }

          svg {
            max-height: 95px;
            margin-top: 10px;
            margin-bottom: 10px;
          }

          .code-etiquette-tab {
            margin-top: 0;
          }

        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .posts {
      .comment-attachment-block {
        .col-lg-4 {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      .comment-attachment-block,
      .card-row-pulse,
      .write-post-row {
        .os-card-v2 {
          >[class*='-240'] {
            max-width: 240px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1025px) {
    &.landscape {
      .posts {
        .comment-attachment-block {
          .col-12.pulse-entity-card {
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      }
    }
  }


  .pulse-get-started {
    max-width: 100%;

    .get-started-img-block {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .get-started-links-block {
      margin-bottom: 8px;
    }
  }

  &.portrait {

    .explore-etiqutte,
    .etiqutte-next-block {
      .sidebar-container {
        height: calc(100% - 24px);
      }

      .loader-active {
        height: 240px;

        .loader-inner {
          margin-top: 0;
        }
      }
    }

    .etiqutte-next-block {
      .code-etiquette-section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .explore {
      .activity-listing-item+.container {
        padding: 0;
      }
    }
  }

  .hashtag-filter {
    .tag-item {
      font-size: 16px;
    }
  }
}

.pulse-card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;

  &.z-index-none {
    z-index: auto;
  }
}


.feed-card-content {
  pre {
    @extend %body-small-1-5;

    @media screen and (min-width: 1920px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  a:not(.link-text) {
    &:hover {
      text-decoration: underline !important;
    }
  }

  .a-link {
    .ifill-os-linked {
      color: $os-link;
    }
  }
}


.no-touch-support,
.ipad {
  .mx-6 {
    margin-left: -6px;
    margin-right: -6px;
  }

  .w-1006 {
    width: calc(100% + 6px);
  }
}


.no-touch-support {
  .pulse-right-sidebar {
    &.pulse-detail {
      margin-bottom: 62px;
    }
  }

  @media (max-width: 1199px) {
    .code-etiquette-section {
      .code-etiquette-blocks {
        flex-direction: column;

        .code-etiquette-info {
          margin-top: 16px;
        }
      }
    }
  }
}

@media (min-width: 1900px) {
  .code-etiquette-section {
    svg {
      max-width: 100px;
    }
  }

  @media (max-width: 992px) {
    .sidebar_fixed {
      position: relative !important;
      top: 0px !important;

      .three-block-sticky {
        top: 0px !important;
      }
    }
  }
}

@media (max-width: 992px) {

  .explore,
  .team {
    .sidebar_fixed {
      position: relative !important;
      top: 0px !important;

      .three-block-sticky {
        top: 0px !important;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.ipad,
.mobile-device {
  .posts {
    .activity-footer-block {
      .like-count:hover {
        .like-count-tooltip-wrap {
          left: auto;
          right: 0px;
        }

        .like-count-tooltip {
          &:before {
            left: auto;
            right: 16px;
          }
        }
      }
    }
  }
}

.no-touch-support,
.ipad,
.mobile-device {

  .explore,
  .posts {
    .activity-data-col {
      .object-header-bar {
        border-bottom-width: 2px;

        .object-filter {
          .list-inline-item {
            @include fontstyle(16px !important, normal, 1);
            margin-left: 0;
            margin-right: 24px !important;

            &.object-filter-active {
              &::after {
                margin-left: 0;
                border-bottom-width: 4px;
                bottom: -11px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-device {

  .explore,
  .posts {
    .activity-data-col {
      .object-header-bar {
        border-bottom-width: 1px;

        .object-filter {
          .list-inline-item {
            @include fontstyle(12px !important, normal, 1);
          }
        }
      }

      .head-sticky-mobile {
        padding: 0 !important;
      }
    }
  }
}


.os-card-listing {
  .os-card-v2 {
    margin-top: 16px;
  }
}

.sep-text {
  padding: 0px 4px;
}

.no-touch-support,
.touch-support {
  &:not(.mobile-device) {
    .add-post-head {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    .post-avatar {
      position: relative;
      top: 0;
      left: 0;
      min-width: 40px;
      padding-left: 0;
      max-height: 32px;
      margin-left: 0;

      .avatar-24 {
        @include avatar(32px);

        .icon-span {
          @include icon-span(20px);
          right: -2px;
          bottom: -2px;

          @media screen and (min-width: 768px) and (max-width: 1366px) {
            @include icon-span(12px);
          }
        }
      }
    }

    .add-post-user-name {
      @include fontstyle(20px, 500, 28px);
      color: $level-11;
      display: inline-block;
      padding-left: 8px;
    }

    .post-textarea-wrap {
      margin-top: 0;

      .mention-textarea-wrap__control {
        box-shadow: 0 2px 4px 0 rgba(23, 23, 23, 0.2);
      }

      .mention-textarea-wrap__input,
      .mention-textarea-wrap__highlighter {
        padding-left: 16px;
      }
    }

    .posts {
      .back-link {
        font-size: 12px;
        padding: 12px 16px 12px 0;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        i {
          font-size: 16px;
        }

        +section {
          .pulse-detail-container {
            padding-top: 8px;
            margin-bottom: 80px;
          }
        }
      }

    }

    .add-post-area {
      margin-bottom: 8px;
    }

    .explore-container {
      .comment-more {
        margin-right: -12px;
      }
    }

    @media only screen and (min-width: 1440px) {
      .add-post-head {
        position: absolute;
      }

      .add-post-user-name {
        display: none;
      }

      .feeds-view {
        .create-slider {
          padding-left: 8px;
          padding-right: 8px;
        }

        .fullscreen-button {
          right: 8px;
        }
      }

      .os-card-listing {
        &.row-col-space-16 {
          margin-left: 0;
        }
      }

      .add-post-area {

        .comment-error {
          margin-left: 64px;
        }

        .row-col-space-16 {
          margin-right: 0;
        }

        .detail-comments-top-block {
          margin-bottom: 0;
        }
      }

      .post-textarea {
        padding-left: 64px;
        padding-right: 0;
      }

      .post-footer-section {
        margin-top: 24px;
        padding-right: 0;
        padding-left: 64px;
      }

      .post-avatar {
        min-width: 48px;
        max-height: 48px;
        margin-left: 0;

        .avatar-24 {
          @include avatar(48px);
          border-width: 3px !important;

          .icon-span {
            @include icon-span(20px);
            bottom: -2px;
            right: -2px;
          }
        }
      }

    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .explore {
        .container {
          padding-left: 8px;
          padding-right: 8px;
        }
      }

      .explore-container {
        .col-pr-8 {
          padding-left: 0px;
          padding-right: 8px;
        }

        .col-pl-8 {
          padding-left: 8px;
          padding-right: 0;
        }
      }

      .card-row-pulse,
      .write-post-row,
      .os-card-listing {
        &.row-col-space-16 {
          margin-left: -4px;
          margin-right: -4px;

          >[class^="col-"] {
            padding-right: 4px;
            padding-left: 4px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {

  .write-post-row,
  {
  &.row-col-space-16 {
    margin-left: 52px;
  }
}

.os-card-listing {
  &.row-col-space-16 {
    margin-left: 0;
  }
}
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    &.container-tab {
      max-width: 768px;

      .rightsidebar-none {
        display: none;
      }

      .back-link {
        margin-left: -12px;
      }

      .pulse-detail-data-col {
        margin-left: -12px;
        margin-right: -12px;
      }

      .card-row-pulse,
      .write-post-row {
        &.row-col-space-16 {
          margin-left: -8px;
          margin-right: -8px;

          >[class^="col-"] {
            padding-right: 8px;
            padding-left: 8px;
          }
        }
      }
    }
  }
}


.ipad {
  &.portrait {
    .container {
      &.container-tab {
        .back-link {
          margin-left: -12px;
        }

        .pulse-detail-data-col {
          margin-left: -12px;
          margin-right: -12px;
        }
      }
    }

    .explore {
      .container {
        padding-left: 12px;
        padding-right: 12px;
      }

      .pulse-right-sidebar {
        display: none;
      }
    }

    .explore-container {
      margin-left: -12px;
      margin-right: -12px;

      .sidebar-container {
        min-height: 240px;
      }
    }

    .card-row-pulse,
    .write-post-row {
      &.row-col-space-16 {
        margin-left: -8px;
        margin-right: -8px;

        >[class^="col-"] {
          padding-right: 8px;
          padding-left: 8px;
        }
      }
    }
  }

  &.landscape {
    .explore-container {
      .col-px-8 {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .container {
      &.container-tab {
        max-width: 768px;

        .back-link {
          margin-left: -12px;
        }

        .pulse-detail-data-col {
          margin-left: -12px;
          margin-right: -12px;
        }
      }

      .card-row-pulse,
      .write-post-row {
        &.row-col-space-16 {
          margin-left: -8px;
          margin-right: -8px;

          >[class^="col-"] {
            padding-right: 8px;
            padding-left: 8px;
          }
        }
      }
    }

    .explore {

      .card-row-pulse,
      .write-post-row {
        &.row-col-space-16 {
          margin-left: -4px;
          margin-right: -4px;
          margin-bottom: 16px;

          &:empty {
            margin-bottom: 0;
          }

          >[class^="col-"] {
            padding-right: 4px;
            padding-left: 4px;
          }
        }
      }
    }
  }
}

.desktop-small {
  .px-52 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .explore-container {
    .sidebar-container {
      .recent-joiners-list {
        .heading-title {
          margin-left: -4px;
        }
      }
    }
  }

  .explore {
    .container.container-tab {
      max-width: 1000px;
    }

    .activity-listing-item {
      .row {
        margin-right: -4px;
        margin-left: -4px;

        >[class^="col-"] {
          padding-right: 4px;
          padding-left: 4px;
        }
      }
    }
  }
}


.post-can-view-dropdown {
  display: inline-block;

  .page-heading-title {
    height: 32px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;

    span {
      padding: 0 20px 0 4px;
    }
  }

  @media(min-width: 768px) {
    .page-heading-title {
      height: 40px;

      span {
        padding-right: 24px;
      }
    }

    .first-elem-icon,
    .istoggleicon {
      font-size: 16px;
    }
  }

  .header-dropdown-list {
    z-index: 100;

    .osbtn {
      margin: 0 !important;
      display: block;
      text-align: left;
    }
  }

  .list-selected {
    .osbtn {
      color: inherit;
      min-height: unset;
    }
  }
}

@media screen and (max-width: 767px) {
  .share-post-dropdown {
    .post-can-view-dropdown {
      .header-dropdown-list {
        right: 12px;
      }
    }
  }
}


.desktop-small,
.ipad.portrait {
  .posts {
    .container.container-tab {
      max-width: 744px;
    }
  }

  .posts {
    .container.container-tab {
      .col-xl-8 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .pulse-right-sidebar {
      display: none;
    }
  }
}

.ipad {
  &.portrait {
    .px-52 {
      padding-left: 32px;
      padding-right: 32px;
    }

    .explore {
      .container.container-tab {
        max-width: 744px;
      }
    }
  }
}


.btn-group-left {
  width: 20%;
  flex-basis: 20%;
  display: inline-flex;
  align-items: center;

  @media screen and (min-width: 1440px) {
    .osbtn+.osbtn {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 1439px) {
    .osbtn+.osbtn {
      margin-left: 0.5rem;
    }
  }
}

.btn-group-right {
  width: 80%;
  flex-basis: 80%;
  text-align: right;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  .submit-btn{
    display: flex;
  }


  .hide-border-radius{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}


.post-can-view-dropdown {
  +.btn-group-right {
    flex-basis: auto;
    width: auto;

    @media screen and (min-width: 768px) and (max-width: 1300px) {
      flex-basis: 25%;
      width: 25%;
      flex-wrap: wrap;

      .share-post-dropdown {
        margin-bottom: 16px;
        margin-right: -16px;
      }
    }
  }
}

.patient-detail-sidebar {
  .sponsored-widget-heading {
    margin-bottom: 24px;
    @include fontstyle(16px, 600, 16px);
    color: $heading;
  }

  .os-card-v2 {
    max-width: 100% !important;
  }

  .add-patient-btn {
    padding: 12px;
    color: $level-8;
    cursor: pointer;

    &:hover {
      color: $os-link;
      text-decoration: none;
    }
  }

  .mobile-device{
    .os-sm-person{
      background: transparent;
      box-shadow: none;
    }
  }

  .os-sm-person {
    padding: 24px 16px 24px 24px;

    .header-dropdown-list {
      padding: 0;
    }

    .avatar-64 {
      left: 24px;
    }

    .patient-cd-address {
      margin-top: 2px;
    }
  }

  @media(max-width: 767px) {
    .patient-detail-widget {
      .cards.row {
        padding: 0;
      }
    }
  }
}

.ipad {
  &.landscape {
    .record-video-btn {
      margin-left: 8px;
    }

    .modal-body-container {
      .record-video-btn {
        margin-left: -70px;
      }
    }
  }
}

@media screen and (min-width: 993px) and (max-width: 1366px) and (orientation: landscape) {

  .btn-group-left,
  .btn-group-right {
    width: auto;
    flex-basis: auto;
    display: inline-flex;
  }

  .btn-group-right {
    text-align: right;
    align-items: flex-end;
  }

  .select-box-wrap {
    margin-bottom: 0;

    .os__placeholder {
      white-space: nowrap;
      @include text-truncate();
      max-width: 190px;
      display: block;
    }
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

  .btn-group-left,
  .btn-group-right {
    width: auto;
    flex-basis: auto;
    display: inline-flex;
  }

  .select-box-wrap {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1366px) {
  .reply-buttons {
    .btn-group-left {
      max-width: 48px !important;

      .osbtn {
        border: 0 !important;

        +.osbtn {
          display: none;
        }
      }
    }
  }
}

.mobile-device {
  .posts {
    .back-link {
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .write-post-row {
    .col-12 {
      padding-right: 10px !important;
    }
  }

  .post-textarea-wrap,
  .detail-comments-top-block {
    .mention-textarea-wrap__input {
      &::-webkit-input-placeholder {
        line-height: 30px !important;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        line-height: 30px !important;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        line-height: 30px !important;
      }

      &:-ms-input-placeholder {
        line-height: 30px !important;
      }

      &::placeholder {
        line-height: 30px !important;
      }
    }
  }

  .btn-group-left
   {
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: auto;
    flex-basis: auto;
  }

  .btn-group-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;

    .time-pill{
      margin-right: 0 !important;
    }
  }

  .comment-edit-container {

    .btn-group-left,
    .btn-group-right {
      align-items: center;
    }
  }

  .detail-comments-header:not(.space-comments),
  .pulse-report-modal {
    .btn-group-left {
      min-width: 90px;

      .osbtn:not(.list-button) {
        font-size: 0;
        padding: 0px 8px !important;

        .btn-icon-helper {
          font-size: 20px;
          left: 18px !important;
        }

        +.osbtn {
          margin-left: 10px;
        }
      }
    }

    .btn-group-right {
      justify-content: flex-end;
      align-items: flex-end;
      position: relative;
      width: calc(100% - 100px);
      flex-direction: column;

      .form-group {
        position: absolute;
        top: 0;
        left: 20px;
      }
    }
  }

  .post-footer-section {
    .share-post-dropdown {
      margin-right: -6px !important;
    }
  }

  .select-box-wrap {
    margin-right: 0 !important;
  }

  .form-group+.share-post-dropdown {
    .post-can-view-dropdown {
      .header-dropdown-list {
        right: 6px;
        left: unset;
      }
    }
  }

  .explore-container {

    .extra-small-min-card .os-sm-comment,
    .os-sm-conversation-message {
      min-height: 96px;
      max-height: 126px;
      height: unset;
    }
  }

  .modal-share-entity {
    .btn-group-right {
      /*flex-basis: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 0 !important;
      align-items: center;*/
      order: 1;

      .osbtn:only-child {
        margin-right: 0 !important;
        margin-left: 1rem;
      }
    }
  }
}

.ipad {
  &.landscape {
    @media screen and (min-width: 1024px) {
      .post-footer-section {
        .osbtn {
          &:first-child {
            margin-right: 0;
          }
        }

        .btn-group-left {
          +.post-can-view-dropdown {
            position: absolute;
            right: 25%;
          }
        }
      }
    }
  }
}

.reply-edit-group {
  margin-bottom: 16px;
}

.mobile-device {
  .reply-edit-group {
    .btn-group-left {
      .osbtn+.osbtn {
        margin-left: 0;
      }
    }
  }
}

.copy-link-b {
  font-weight: $font-weight-semibold;
}
