$talk-bubble-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24),
  0 1px 3px 0 rgba(53, 69, 84, 0.12);

$level-5: #c9d1da;
$level-8: #7e91a5;
$level-10: #4a749a;

.chat-message-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4px 12px;

  .message-row {
    display: flex;
    flex-direction: column;
    float: left;
    position: relative;
    min-height: 56px;
    .row-content {
      max-width: 640px;
      width: auto;
      display: flex;
      gap: 24px;
      align-items: center;
      .avatar-container {
        width: 40px;
        .msg-avatar {
          min-width: 40px;
          position: absolute;
          top: 28px;
        }
      }
      .block-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .creator-info {
        font-size: 0.5rem;
        color: #7e91a5;
        line-height: 1.5rem;
      }
    }
    .content-block,
    .attachment-wrapper {
      display: flex;
      flex-direction: column;
      .action-wrapper {
        display: inline-flex;
        gap: 8px;
      }
      .description {
        display: inline-block;
        max-width: 580px;
        padding: 16px 16px;
        position: relative;
        background: #fff;
        border-radius: 12px;
        box-shadow: $talk-bubble-shadow;
        word-break: normal;
        display: inline-block;
        word-break: break-word;
        margin-bottom: 0;
        color: #354554;
        .parent-msg-box {
          display: flex;
          flex-direction: column;
          padding-left: 8px;
          border-left: 2px solid #4a749a;
          color: #7e91a5;
          justify-content: flex-start;
          align-items: flex-start;

          .c-author,
          .c-text {
            font-size: 12px;
            line-height: 1.33;
          }

          .c-author {
            font-weight: 600;
          }
        }

        .parent-msg-box.own {
          border-left: 2px solid #fff;
          color: #fff;

          .cl-l10 {
            color: #fff;
          }
        }
        .parse-comment-text {
          p {
            font-size: 16px;
          }
        }
      }

      .reply-info {
        color: #4a749a;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5rem;
        cursor: pointer;
      }

      .m-actions {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        display: flex;
        align-items: center;
        gap: 2px;
        margin-left: 0px;
        position: relative;
        flex-direction: row-reverse;
        .emoji-btns-group {
          padding: 0;
          margin: 0;
        }
      }

      &:hover {
        .m-actions {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }

      .user-emoji-list {
        position: absolute;
        margin: 0 !important;
        top: -10px;
        right: -15px;
        left: auto;
        min-width: 24px;
        height: 24px;

        .emoji-counter {
          position: absolute;
          color: $level-8;
          left: calc(100% + 6px);
          font-size: 12px;
          line-height: 24px;
        }

        .user-emoji-holder:only-child {
          .osbtn {
            left: 0;
          }
        }

        .osbtn {
          position: absolute;
          left: 4px;
          top: 0;
          padding: 0 !important;
          height: 24px;
          width: 24px;
          min-height: 24px;
          min-width: 24px;
          border-radius: 8px;
          line-height: 24px !important;
          min-height: 24px !important;
          font-size: 16px;

          &:not(.add-emoji-btn) {
            background: $level-5;
          }

          &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $level-5;
            position: absolute;
            top: 0;
            right: -3px;
          }

          &.active {
            z-index: 1;
            left: 0;
            background: $level-10 !important;
            &:after {
              background: $level-10 !important;
            }
          }
        }

        .count {
          display: none;
        }

        .user-emoji-holder {
          display: unset;
        }
        .user-emoji-holder:nth-child(2) ~ .user-emoji-holder {
          display: none;
        }
      }
      &.have-emoji {
        gap: 8px;
      }
    }

    .m-files,
    .content-block {
      .have-author {
        position: relative;
        &::after {
          content: '\e923';
          width: 8px;
          height: 8px;
          top: 35px;
          left: 3px;
          position: absolute;
          font-family: 'fill-os-icon' !important;
          transform: rotate(180deg);
          font-size: 25px;
          color: #fff;
          text-shadow: 1px 0px 3px rgba(53, 69, 84, 0.12);
        }
      }
    }

    &.own-message {
      float: right;
      direction: rtl;
      .content-block,
      .attachment-wrapper {
        .description {
          background: #4a749a;
          color: #fff;
          direction: ltr;
          .parse-comment-text {
            a {
              color: #ffffff;
              text-decoration: underline;
              font-weight: 600;
            }
            .cl-l10 {
              color: #ffffff;
            }
          }
        }

        .user-emoji-list {
          right: auto;

          left: -12px;
          .osbtn {
            left: -4px;
            &:after {
              left: -3px;
            }
            &.active {
              left: 0;
            }
          }
          .emoji-counter {
            left: auto;
            right: calc(100% + 6px);
          }
        }
      }
      .m-files,
      .content-block {
        .have-author {
          &::after {
            top: 8px;
            left: auto;
            right: 5px;
            transform: rotate(0);
            color: #4a749a;
          }
        }
      }
      .m-files {
        .os-card-v2 {
          direction: ltr;
        }
      }
    }

    .m-files {
      max-width: 344px;
      min-width: 344px;
      position: relative;
    }
  }
  &:nth-last-child(2) {
    .message-row {
      .m-files {
        .os-sm-attachment {
          .header-dropdown-list {
            bottom: 32px;
          }
        }
      }
    }
  }
}

.mobile-device {
  .message-row {
    .row-content {
      gap: 16px;
    }
    .avatar-container {
      width: 27px !important;
      .msg-avatar {
        min-width: 27px !important;
        top: 32px !important;
        .avatar.avatar-40 {
          width: 27px;
          height: 27px;
          line-height: 27px;
          min-width: 27px;
          i {
            font-size: 20.30075px;
          }
        }
      }
    }
    .m-files {
      max-width: 284px;
      min-width: 284px;
    }
    .block-container {
      &:hover {
        .row-content {
          .msg-avatar {
            margin-top: -48px;
          }
        }
      }
    }
  }
  .content-block,
  .attachment-wrapper {
    .action-wrapper {
      flex-direction: column;
      .description {
        width: fit-content;
      }
      .m-actions {
        display: none;
      }
      &:hover {
        .m-actions {
          display: flex;
          max-width: fit-content;
        }
      }
    }
  }
}
