%leftAlignMenu {
  .header-dropdown-list {
    .header-dropdown-list-item:first-child {
      &::before {
        left: 19px;
        right: auto;
      }
    }
  }
}

.header-dropdown-menu-helper {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  cursor: pointer;
  z-index: 1;
}

.list-inline-item {
  &:empty {
    display: none;
  }
}

.attach-icon {
  border-radius: 8px !important;
}

.header-dropdown-menu {
  position: relative;

  .ifill-os-more {
    cursor: pointer;
    font-size: 24px;
    display: inline-block;
  }

  .osbtn-icon {
    .ifill-os-more {
      font-size: 1rem;
    }
  }

  &.left-align-menu {
    @extend %leftAlignMenu;
  }

  .header-dropdown-list {
    background-color: $dropdown-bg;
    z-index: 11;
    border-radius: 8px;
    position: absolute;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    min-width: 170px;
    margin-bottom: 0;
    margin-top: 0;
    box-shadow: $block-shadow;

    &.left-align {
      left: 0;
    }

    .osbtn-icon {
      background-color: transparent;
      border-radius: 0;
    }

    .header-dropdown-list-item {
      color: $level-9;
      background: none;

      .separator{
        border-bottom: 0.5px solid $level-9;
      }

      .osbtn {
        margin-bottom: 0 !important;
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
      }

      &:last-child {
        .osbtn,
        .list-icon-text,
        .os-btn-link {
          border-radius: 0 0 8px 8px !important;
        }

        &.list-selected,
        .active,
        .accepted-connection-button {
          border-radius: 0 0 8px 8px;
        }
      }

      &:first-child {
        &:empty {
          display: block;
          padding: 0;
          pointer-events: none;
          height: 0;
        }

        .osbtn,
        .list-icon-text,
        .os-btn-link {
          border-radius: 8px 8px 0 0;
        }

        &::before {
          position: absolute;
          content: none;
          bottom: 100%;
          right: 7px;
          transform: translateX(-50%);
          border: 6px solid transparent;
          border-bottom-color: $dropdown-bg;
          z-index: 1;
          pointer-events: none;
        }

        &.list-selected,
        .active {
          border-radius: 8px 8px 0 0;

          &::before {
            border-bottom-color: $dropdown-list-hover;
          }
        }
      }

      &:only-child {
        border-radius: 8px !important;

        .osbtn,
        .list-icon-text,
        .active,
        .os-btn-link {
          border-radius: 8px;
        }
      }
    }
  }

  .header-dropdown-list-item {
    padding: 0;
    line-height: 1.33;
    font-size: 12px;
    cursor: pointer;
    vertical-align: middle;
    position: relative;

    .osbtn-icon,
    .os-btn-link,
    .list-icon-text {
      margin-right: 0 !important;
      color: $dropdown-text;
      padding: 0 18px 0 40px;
      cursor: pointer;
      vertical-align: middle;
      line-height: 40px;
      white-space: nowrap;
      display: block;
      min-height: 40px;
      text-align: left;
      font-weight: $font-weight-normal;
      border: 0 !important;

      i:not(.sorting-check-icon) {
        color: $dropdown-icon;
        font-size: 16px;
        margin-right: 8px;
        vertical-align: middle;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover,
      &.active {
        color: $dropdown-hover-text !important;
        background-color: $dropdown-list-hover !important;
        text-decoration: none;
        border-color: transparent !important;
        box-shadow: none !important;

        i:not(.sorting-check-icon) {
          color: $dropdown-icon-hover !important;
        }
      }
    }

    &:empty {
      display: none;
    }

    > span:not(.osbtn) {
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
      }
    }


    &:last-child {
      .btn {
        border-bottom: 0;
      }
    }

    &.list-selected {
      background-color: $dropdown-list-hover;
      color: $dropdown-hover-text;

      .btn-icon-helper,
      i:not(.sorting-check-icon) {
        color: $dropdown-icon-hover;
      }
    }

    .pl-40 {
      padding-left: 40px;
    }
  }

  .header-list-icon {
    margin-right: 8px;
    font-size: 16px;
    vertical-align: middle;
  }

  .btn {
    background-color: transparent;
    width: 100%;
    text-align: left;
    display: block;
    margin: 0;
    padding: 8px 0 8px 30px !important;
    border-radius: 0;
    position: relative;
    font-size: 14px;
    text-transform: unset;
    font-weight: normal;
    height: auto;

    i {
      position: absolute;
      left: 0;
      margin-left: 0;
      font-size: 16px;
      top: 8px;

      &.ifill-os-edit {
        font-size: 14px;
      }
    }

    &.btn-br-top {
      border-top: 1px solid $border-button-1;
      margin-left: 0 !important;
    }

    &.delete-comparison {
      line-height: 1.5;
    }
  }

  &.page-header-dropdown-menu {
    .page-heading-title {
      padding: 4px 24px 0 0;
      font-size: 24px;

      span {
        font-weight: 300;
        padding-left: 0;
      }

      .sort-rel-icon {
        position: static;
        transform: none;

        &.ifill-os-fill-case-2-d {
          margin-top: -5px;
        }
      }

      .ifill-os-chevron-down,
      .ifill-os-chevron-up {
        margin-top: 3px;
        font-size: 16px;
      }
    }
  }

  .ifill-os-chevron-down,
  .ifill-os-chevron-up,
  .ifill-os-chevron-caret-up,
  .ifill-os-chevron-caret-down {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    &:not(.space-section-dropdown) {
      .ifill-os-chevron-down,
      .ifill-os-chevron-up {
        margin-left: 10px;
      }
    }
  }
}

.left-align-menu {
  .header-dropdown-menu {
    @extend %leftAlignMenu;
  }
}

.comment-more {
  .header-dropdown-list-item {
    cursor: default;

    span {
      display: block;
      margin-right: 0 !important;
    }
  }
}

.header-dropdown-buttons {
  display: flex;
  align-items: center;

  .engagement__share {
    margin-right: 16px;

    i {
      font-size: 18px;
    }
  }
}

.header-dropdown-list {
  .comparison-delete-button {
    .recover-comparison {
      border: none;
    }
  }
}

.page-header-dropdown-menu {
  display: inline-block;

  .ifill-os-chevron-down {
    cursor: pointer;
  }

  .page-heading-title {
    font-size: 24px;
    font-weight: $font-weight-light;
    cursor: pointer;
    color: $level-11;

    i {
      display: inline-block !important;
    }

    span {
      padding-left: 8px;

      i {
        padding-left: 4px;
        font-size: 80%;
        margin-top: 4px;
      }
    }

    &.line-16 {
      line-height: 40px;
    }
  }

  .header-dropdown-list {
    position: absolute;
    left: 15px;
    right: auto;
    min-width: 160px;
  }

  .header-dropdown-list-item {
    padding: 8px 16px;

    span {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
}


.object-header-bar {
  .header-dropdown-menu {
    position: unset;
  }

  .object-view-switcher {
    .header-dropdown-menu {
      position: relative;

      .header-dropdown-list {
        right: 0;
      }
    }
  }

  .header-dropdown-list {
    right: auto;
    left: auto;
    min-width: 130px;
    top: 100%;
  }

  .header-dropdown-list-item {
    margin: 0 !important;
    cursor: pointer;
    padding: 0 16px;
    white-space: nowrap;
    line-height: 40px;
    text-transform: uppercase;

    &:hover,
    &.active {
      color: $dropdown-hover-text !important;
      background-color: $dropdown-list-hover !important;
      text-decoration: none;

      i:not(.sorting-check-icon) {
        color: $dropdown-icon-hover !important;
      }
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:only-child {
      border-radius: 8px;
    }
  }
}


.head-sticky {
  @include backface-visibility;

  .page-heading-title {
    padding: 0 28px 0 24px;
    display: inline-flex;
    align-items: center;
    position: relative;
    color: $level-11;
    font-size: 16px;

    i.sort-rel-icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.fixed {
    &.big-sticky {
      .header-dropdown-list {
        margin-top: 15px;
      }
    }

    .page-header-dropdown-menu {
      .header-dropdown-list {
        top: 55px;
        margin-top: 0;
      }
    }

    .sorting-dropdown {
      top: 55px;
    }
  }
}

.spaces .detail {
  .head-sticky {
    @media(min-width: 768px) {
      .container {
        max-width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    @media (min-width: 2100px) {
      .container {
        max-width: 2000px;
      }
    }

    + .container {
      max-width: 100%;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.right-15 {
  .header-dropdown-list {
    right: 15px;
  }
}

.right-menu {
  .header-dropdown-list {
    right: 0;
    left: auto;
    top: 28px;
  }
}

.with-border-list-item {
  .header-dropdown-list-item {
    line-height: 32px;
    position: relative;

    + .header-dropdown-list-item {
      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 15px;
        right: 10px;
        border-top: 1px solid rgba(204, 204, 204, 20%);
      }

      &.active {
        color: $dropdown-hover-text !important;
        background-color: $dropdown-list-hover !important;
      }
    }
  }
}

.modal-dropdown-menu {
  position: relative;
  display: block;
  margin-bottom: 15px;

  .header-dropdown-list {
    border-radius: 8px;
    top: 100%;
    left: 0;
  }

  .header-dropdown-list-item {
    margin-bottom: 0;
    padding: 0;
    line-height: 1.6;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }

    .modal-dropdown-span {
      padding: 0 16px;
      display: block;
      line-height: 40px;
    }
  }
}

.analytics-modal {
  .modal-dropdown-menu {
    .header-dropdown-list-item {
      > div:hover,
      .active {
        color: $dropdown-hover-text !important;
        background-color: $dropdown-list-hover !important;
      }
    }
  }
}

.comment-more {
  .header-dropdown-list-item {
    span {
      display: block;
      margin-right: 0 !important;
    }
  }
}

@media only screen and (min-device-pixel-ratio: 1) and (max-device-width: 1024px) and (min-device-width: 768px) {
  .header-dropdown-menu {
    .btn--pill {
      i {
        &.ifill-os-edit {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 896px) and (orientation: landscape) {
  .page-header-dropdown-menu {
    .page-heading-title {
      font-size: 16px;
      padding: 8px 10px;

      span i {
        font-size: 12px;
        padding-left: 8px;
      }
    }
  }
}

.mobile-device {
  .page-header-dropdown-menu {
    .header-dropdown-list {
      left: 9px;
    }

    .page-heading-title {
      font-size: 16px;
      padding: 8px 20px 8px 15px;

      .ifill-os-chevron-down,
      .ifill-os-chevron-up {
        margin-top: 0 !important;
      }
    }

  }

  .head-sticky.fixed {
    .page-header-dropdown-menu .header-dropdown-list {
      top: 100%;
    }
  }

  .object-view-switcher {
    .header-dropdown-menu {
      margin: 0;
      order: 2;
      height: 38px;
      min-width: 18px;

      .page-heading-title {
        font-size: 15px;
        padding: 0;

        .sort-rel-icon {
          margin-top: 4px;
        }
      }
    }

    .header-dropdown-list {
      right: -18px;
    }
  }
}


.post-card-menu {
  position: absolute;
  right: 16px;

  .header-dropdown-list {
    padding: 8px 0 !important;
  }

  .ifill-os-more {
    font-size: 16px;
  }

  .osbtn {
    min-width: 32px;
    min-height: 32px;
    padding: 8px;
  }

  .osbtn-del {
    line-height: 32px !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.sticky-tab-with-dropdown {
  margin-top: 16px;

  @media(min-width: 1440px) {
    margin-top: 32px;
  }

  margin-bottom: 20px;

  .object-filter-active {
    .header-dropdown-list {
      top: 34px;
    }
  }

  .head-sticky {
    margin-bottom: 24px;

    &.fixed {
      background-color: $level-1;

      .page-header-dropdown-menu {
        .header-dropdown-list {
          top: 40px;
        }

        .object-filter-active {
          .header-dropdown-list {
            top: 34px;
            left: auto;
          }
        }
      }
    }
  }

  .page-header-dropdown-menu {
    .page-heading-title {
      color: inherit;

      @include fontsw(16px, 500);

      padding-left: 0;
      padding-right: 20px;
    }
  }

  .page-heading-title span {
    i {
      margin-top: 0;
    }
  }
}


.os-tab-style {
  .head-sticky.fixed,
  &.fixed {
    .object-filter::after {
      bottom: 2px;
    }
  }

  .object-filter {
    margin: 0;
    padding-bottom: 10px !important;
    overflow-x: auto;;
    display: flex;
    padding-left: 0;

    &:empty {
      &::after {
        content: none;
      }
    }

    .list-inline-item {
      margin: 0 !important;
      font-size: 16px;
      font-weight: $font-weight-semibold;


      + .list-inline-item {
        margin-left: 32px !important;
      }

      &::after {
        max-width: 100%;
        margin: 0;
        width: unset;
        left: 0;
        right: 0;
        border-width: 3px;
        bottom: -7px !important;
        border-radius: 5px;
      }
    }
  }

  .object-header-bar {
    border: 0;
    overflow: unset;
  }
}

.ipad {
  .os-tab-style:not(.os-acctoun-tab-style) {
    &.fixed,
    &.head-sticky.fixed {
      background: transparent;

      .object-filter {
        height: 45px;
      }
    }
  }
}

.no-touch-support {
  .tab-desktop-justify {
    .object-filter {
      display: flex;
      justify-content: space-between;

      .list-inline-item + .list-inline-item {
        margin-left: 16px;
      }
    }

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.no-touch-support,
.ipad,
.mobile-device.landscape {
  .sticky-tab-with-dropdown {
    .object-filter {
      a,
      li:not(.header-dropdown-list-item) {
        margin-left: 0;

        &.object-filter-active {
          &::after {
            left: 0;
            width: 100%;
            margin-left: 0;
          }
        }

        .header-dropdown-list-item {
          margin-right: 0 !important;
        }
      }
    }

    .page-header-dropdown-menu {
      .page-heading-title {
        span {
          padding-left: 0;
        }
      }
    }
  }
}

.no-touch-support,
.ipad {
  .sticky-tab-with-dropdown {
    .px-tab-0 {
      padding: 0;
    }
  }
}


.ipad,
.touch-support,
.mobile-device.landscape {
  .sticky-tab-with-dropdown {
    .object-filter-active {
      height: 100%;

      &::after {
        bottom: -5px;
      }

      &.user-list-item {
        position: static;

        &::after {
          content: none;
        }
      }

      .page-heading-title {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          background: rgba(255, 255, 255, 90%);
          height: 3px;
          width: 100%;
          top: 27px;
          left: 0;
        }
      }
    }

    .page-header-dropdown-menu {
      .page-heading-title {
        padding: 0;
        padding-right: 24px;

        span {
          padding-left: 0;
        }
      }
    }
  }
}

.mobile-device {
  .object-view-switcher {
    .page-heading-title {
      > span {
        display: none;
      }
    }
  }

  .os-tab-style {
    .object-filter {
      padding-left: 0;
    }

    .list-inline-item {
      height: auto !important;
    }
  }

  .sticky-tab-with-dropdown {
    min-width: 100%;
    margin-top: 0;

    .object-filter-active {
      .header-dropdown-list {
        top: 39px;
      }
    }

    .head-sticky {
      &.fixed {
        .page-header-dropdown-menu {
          .header-dropdown-list {
            top: 39px;
          }

          .object-filter-active {
            .header-dropdown-list {
              top: 39px;
            }
          }
        }
      }
    }

    .page-header-dropdown-menu {
      .list-inline-item {
        margin-right: 24px !important;
      }

      .dropdown-info-title {
        padding-top: 5px;
      }

      .page-heading-title {
        color: inherit;
        position: relative;
        display: block;
      }

      .header-dropdown-list {
        left: unset;
      }
    }

    .object-header-bar {
      border-bottom: 1px solid hsla(0deg, 0%, 100%, 15%) !important;
    }

    .object-filter-active {
      height: 100%;

      &::after {
        bottom: 6px;
        margin-left: 0 !important;
        width: 100% !important;

      }

      &.user-list-item {
        position: static;

        &::after {
          content: none;
        }
      }

      .page-heading-title {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          background: rgba(255, 255, 255, 90%);
          height: 3px;
          width: 100%;
          top: 30px;
          left: 0;
        }
      }
    }
  }

  .sub-nav-background {
    .object-filter {
      .list-inline-item:last-child {
        .header-dropdown-list {
          left: auto !important;
          right: 0;
        }
      }
    }
  }
}

.ipad {
  .sticky-tab-with-dropdown {
    .object-filter {
      .object-filter-active {
        .header-dropdown-list {
          top: 40px;
        }
      }
    }
  }

  &.portrait {
    .sticky-tab-with-dropdown {
      .object-filter {
        &.page-header-dropdown-menu {
          .page-heading-title {
            color: inherit;
            position: relative;

            i {
              font-size: 12px !important;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }

    .header-dropdown-menu {
      .ifill-os-chevron-down,
      .ifill-os-chevron-up,
      .ifill-os-chevron-caret-up,
      .ifill-os-chevron-caret-down {
        top: 50%;
        width: auto;
      }
    }
  }
}

.touch-support {
  .os-tab-style {
    .list-inline-item {
      margin: 0 !important;

      + .list-inline-item {
        margin-left: 24px !important;
      }
    }
  }
}

.spaces {
  .right-15 {
    .header-dropdown-list {
      right: 11px;
    }
  }
}

html:not(.mobile-device) {
  .sticky-tab-with-dropdown {
    .tab-nav-container {
      overflow-x: auto;
      overflow-y: hidden;
      overflow: visible;

      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        max-width: 100%;
        z-index: 1;
        display: none;

        @media(min-width: 1441px) {
          left: 12px;
          right: 12px;
        }
      }
    }

    .object-header-bar {
      border: 0;

      .dropdown-info-title {
        position: relative;
        line-height: 22px;
        padding-bottom: 0;
        font-size: 12px;
      }

      .header-dropdown-list {
        top: 100% !important;
      }
    }

    .page-header-dropdown-menu {
      white-space: nowrap;
      margin-bottom: 0;
    }


    .page-heading-title,
    .object-filter-active {
      &::after {
        display: none !important;
      }
    }

    .object-filter-active {
      position: unset !important;

      .dropdown-info-title {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 2px solid $tab-active;
          z-index: 2;
        }
      }
    }
  }
}

.detail-page {
  height: auto;
  min-height: 100vh;

  body {
    min-height: inherit;
  }
}

.mobile-device {
  .spaces {
    .sticky-tab-with-dropdown {
      .head-sticky {
        &.fixed {
          .object-filter {
            padding-top: 0;
            height: 40px;
          }

          .tab-nav-container {
            &::after {
              border-bottom: 0;
            }
          }
        }
      }

      .space-sub-header {
        padding-top: 0;

        .space-header-name {
          line-height: 32px;
        }
      }
    }
  }

  &.detail-page {
    .posts {
      .activity-listing-item-content {
        position: relative;
      }
    }
  }
}

.comments-sort-dropdown {
  position: relative;
  z-index: 0;

  .ifill-os-chevron-caret-down,
  .ifill-os-chevron-caret-up {
    color: #5c5c66;
    font-size: 16px;
  }

  .sort-dropdown-toggle {
    @include fontsw(16px, 600);

    position: relative;
    cursor: pointer;
    padding-right: 24px;

    .ifill-os-chevron-down,
    .ifill-os-chevron-caret-down {
      right: 0;
      top: 2px;
      position: absolute;
      color: inherit;
    }
  }

  .page-heading-title {
    font-size: 12px;
    color: $level-11;
    text-transform: uppercase;
  }

  &.header-dropdown-menu {
    .page-heading-title {
      padding-left: 0;
      padding-right: 24px;
      font-weight: 600;
    }
  }
}

.ipad {
  .comments-sort-dropdown {
    margin-right: 2px;
  }
}

.mobile-device {
  .comments-sort-dropdown {
    &.header-dropdown-menu {
      .page-heading-title {
        line-height: 1;
      }

      .header-dropdown-list {
        margin-top: 5px;
      }
    }

    .sort-dropdown-toggle {
      .ifill-os-chevron-down {
        right: 8px;
      }
    }
  }

}

@media screen and (min-width: 1441px) {
  .tab-spacing {
    padding-left: 0;
    padding-right: 0;

    .tab-nav-container {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .user-details-tabs-block {
    .tab-spacing {
      .tab-nav-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .folders {
    .tab-spacing {
      .tab-nav-container {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

}

.ipad {
  .tab-spacing {
    padding-left: 0;
    padding-right: 0;
  }

  .search-sticky {
    .sub-nav-background {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .at-bt-dpdowm {
    .header-dropdown-list {
      right: auto;
      left: 0;
    }
  }
}

.user-details-tabs-block {
  .tab-spacing {
    padding-left: 0;
    padding-right: 0;
  }
}

.mobile-device {
  .user-details-tabs-block {
    .tab-spacing {
      padding-left: 12px;
      padding-right: 12px;

    }
  }

  .media-actions {
    .at-bt-dpdowm {
      .header-dropdown-list {
        right: auto;
        left: 0;
      }
    }
  }
}