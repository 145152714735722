.modal-share-entity {
  .modal-body {
    transition: padding .5s ease;
    will-change: padding;
    max-height: 430px;
  }

  .card-row {
    transition: all .7s cubic-bezier(0.15, 0.58, 0.31, 0.95);
    -webkit-transition: all .7s cubic-bezier(0.15, 0.58, 0.31, 0.95);
    transform: translateY(-160px) !important;
    -webkit-transform: translateY(-160px) !important;
  }
  &:not(.share-animation-active) {
    .slide-animation {
      z-index: -10;
      max-height: 160px;
    }
  }

  .slide-animation {
    &:empty {
      min-height: 160px;
    }
  }

  .comment-error {
    margin-left: 0 !important;
    padding-left: 20px !important;
    display: inline-flex;
    color: $lt-error-text;

    .error-icon {
      color: $lt-error-bg;
    }
  }
}

.modal-share-small-card {
  .modal-body-share {
    max-height: 160px;
  }
}

.share-animation-active {
  .modal-body-share {
    padding-bottom: 64px !important;
    transition-delay: 0;
    -webkit-transition-delay: 0;
    max-height: 2000px !important;
  }

  .slide-animation {
    transition-duration: 1s;
    -webkit-transition-duration: 1s;
    z-index: 1;
  }
  .card-row {
    margin-bottom: 40px !important;
    will-change: transform, margin;
    transform: translateY(0px) !important;
    -webkit-transform: translateY(0px) !important;
  }
  .share-select-list {
    visibility: hidden;
    z-index: -10;
    opacity: 0;
    transition-delay: 0;
    -webkit-transition-delay: 0;
    transition: none;
    -webkit-transition: none;
  }
  .share-modal-footer {
    transition: opacity .2s linear;
    transition-delay: 1.2s;
    -webkit-transition: opacity .2s linear;
    -webkit-transition-delay: 1.2s;
  }

  &.share-feed, &.share-discussion {
    .modal-body-share {
      padding-bottom: 8px !important;
    }
  }
}

.error-animation-active {
  .modal-body-share{
    padding-bottom: 24px !important;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    max-height: 2000px !important;
  }

  .share-select-list {
    bottom: 116px;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
  }

  .share-error{
    visibility: visible;
    opacity: 1;
    transition-delay: .2s;
    -webkit-transition-delay: .2s;
    margin-bottom: 8px;
  }
}

.slide-animation {
  opacity: 0;
  transition: opacity .6s ease;
  -webkit-transition: opacity .6s ease;
  will-change: opacity;
  position: relative;
  &.form-animate {
    opacity: 1;
  }
}
.share-select-list {
  position: absolute;
  bottom: 90px;
  left: 0;
  right: 0;
  visibility: visible;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  will-change: opacity, visibility;
}

.share-error {
  padding-left: 16px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 32px;
  border-radius: 4px;
  text-align: left;
  color: $lt-error-text;
  background: $lt-error-bg;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease-in;
  -webkit-transition: all .5s ease-in;
  will-change: opacity, visibility;

  i {
    color: $lt-red;
  }
}


.share-modal-footer {
  position: absolute;
  left: 24px;
  right: 16px;
  bottom: 24px;
}

.share-error + .share-modal-footer {
  bottom: 24px;
}
