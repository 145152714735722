.doctor-suggest-modal {
  .modal-content {
    width: 682px;
  }

  .modal-header {
    font-size: 24px;
  }

  .loader-active {
    max-height: 288px;

    .loader-inner {
      margin: 0
    }
  }

  .cards.row {
    padding: 0;
    margin-right: -12px;
    margin-left: -12px;

    .os-card-v2 {
      margin-top: 24px;
    }

    > [class^="col-"] {
        padding-right: 12px;
        padding-left: 12px;
        min-width: 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 0 !important;
      }

    .os-card {
      width: 100%;
      margin-top: 24px;
    }
  }

  .connect-suggest-heading {
    padding-top: 12px;
  }

  .modal-footer {
    padding:  32px 0 8px;
  }

  .skip-btn {
    padding-left: 16px;
    padding-right: 16px;
  }

  .osbtn + .osbtn {
    margin-left: 32px;
  }
}


@media(min-width: 1400px) {
  .doctor-suggest-modal {
    .modal-content {
      width: 792px;
    }
  }
}

.ipad {
  &.portrait {
    .doctor-suggest-modal {
      .modal-content {
        width: 736px;
      }
    }
  }
}

.mobile-device {
  &.portrait {
    .doctor-suggest-modal {
      .modal-content {
        padding-left: 16px;
        padding-right: 16px;
      }

      .cards.row {
        margin-right: -12px;
        margin-left: -12px;

        > [class^="col-"] {
            min-width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
      }


      .remind-me-btn {
        width: 192px;
        letter-spacing: 0.5px;
      }
    }
  }
}
