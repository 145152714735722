.form-container {

  min-width: 968px;
  width: 100%;
  padding: 40px;
  background-color: #FFFFFF;

  .form-group {
    margin-bottom: 0px;
  }

  .form-secondary-heading {
    font-weight: 700;
    line-height: 24px;
    font-size: 20px;
  }

  .form-secondary-explanation {
    font-weight: 400;
    line-height: 28px;
    font-size: 20px;
  }

  .workspace-logo-field {
    margin-bottom: 16px;

    .banner-field {
      display: flex;
      gap: 40px;
    }

    .banner-preview-box {
      height: 120px;
      width: 120px;
      background-color: #B9C4CF;
      border-radius: 8px;

      .banner-preview {
        height: inherit;
        width: inherit;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
      }
    }

    .banner-dropzone-box {
      width: 100%;
      height: 120px;
      border: 1px dashed #4A749A;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      .description {
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin: 0 0 2px 120px;

        span {
          color: #4a749a;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .description-limit {
        margin: 0 0 0 120px;
        font-size: 12px;
        font-weight: 400;
        color: #7E91A5;
      }
    }
  }

  .clinic-address-field {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    margin-bottom: 96px;

    .label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      font-family: Inter, serif;
    }

    .form-field {
      display: flex;
      flex-direction: column;

      input {
        background-color: #F4F7F9;
        border-radius: 8px;
        color: black;
        border-style: none;
        height: 40px;
        padding: 8px 16px;
        box-shadow: none;
      }
    }

    .field-1 {
      grid-column-start: 1;
      grid-column-end: 5;
    }

    .field-2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .field-7 {
      .phone-number-field {
        .form-control {
          border: none;
          box-shadow: none;
          padding: 0;
        }

        display: flex;

        #country_code {

          .os__control {
            border: none;
            box-shadow: none;
            background-color: #F4F7F9;
            border-radius: 8px 0 0 8px;
          }

          .os__value-container {
            width: max-content;
            margin: 0;
            padding: 0 8px 0 16px;
          }

          .os__single-value {
            color: black;
          }

          .os__input-container {
            padding: 0;
            margin: 0;
          }

          .os__menu {
            width: fit-content;
          }
        }
      }

      #phone_number {
        border-radius: 0 8px 8px 0;
      }
    }

    .field-5, .field-8 {

      .os__control {
        background-color: #F4F7F9;
        border-style: none;
        box-shadow: none;
      }

      .os__single-value {
        color: black;
      }

      .os__value-container {
        height: 40px !important;
      }

      .os__input-container {
        height: inherit !important;
        padding: 0;
        margin: 0;
      }

      .os__menu {
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(53, 69, 84, 0.15), 0 2px 4px rgba(53, 69, 84, 0.12);

        .os__option {
          height: inherit;
          padding: 4px 4px;
        }
      }
    }
  }

  .patient-emails-field {
    margin-bottom: 96px;

    .banner-field {
      display: flex;
      gap: 40px;
    }

    .banner-preview-box {
      height: 120px;
      width: 120px;
      background-color: #B9C4CF;
      border-radius: 8px;

      .banner-preview {
        height: inherit;
        width: inherit;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
      }
    }

    .banner-dropzone-box {
      width: 100%;
      height: 120px;
      border: 1px dashed #4A749A;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      .description {
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin: 0 0 2px 120px;

        span {
          color: #4a749a;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .description-limit {
        margin: 0 0 0 120px;
        font-size: 12px;
        font-weight: 400;
        color: #7E91A5;
      }
    }
  }

  .timezone-workday-settings {
    margin-bottom: 96px;

    .timezone-workday-settings-field {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;

      .label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        font-family: Inter, serif;
      }

      .form-field {
        display: flex;
        flex-direction: column;

        input {
          background-color: #F4F7F9;
          border-radius: 8px;
          border-style: none;
          height: 40px;
          padding: 8px 16px;
          box-shadow: none;
          color: black;
        }
      }

      .field-1 {
        grid-column-start: 1;
        grid-column-end: 4;

        // Styling of OsField components
        .os-menu-drop {
          border: none;
          padding: 0;

          &:hover {
            border: none;
          }
        }

        .os__control {
          background-color: #F4F7F9;
          border-style: none;
          height: 40px !important;
          box-shadow: none !important;
          overflow: hidden;

          .os__single-value {
            color: black;
          }

          .os__input {
            color: black
          }

          .os__indicators:after {
            top: 10px !important;
          }

          .os__input-container {
            margin: 0;
            padding: 0 !important;
          }
        }

        .os__menu {
          border-radius: 8px;
          width: 80%;
          box-shadow: 0 3px 6px rgba(53, 69, 84, 0.15), 0 2px 4px rgba(53, 69, 84, 0.12);

          //.os__option {
          //  height: inherit;
          //  padding: 4px 4px;
          //}

          .os__option {
            .timezone-option {
              display: flex;
              justify-content: flex-start;
              padding: 0 !important;
              color: #354554;
              font-size: 12px;
              line-height: 16px;

              .timezone-constant {
                color: #5D7893;
                min-width: 80px;
                padding-right: 8px;
              }
            }
          }
        }
      }

      .field-2, .field-3{
        position: relative;
      }

      .field-4 {
        .os-menu-drop {
          border: none;
          padding: 0;

          &:hover {
            border: none;
          }
        }

        .os__control {
          background-color: #F4F7F9;
          border-style: none;
          height: 40px !important;
          box-shadow: none !important;
          overflow: hidden;

          .os__single-value {
            color: black;

            .timezone-constant {
              padding-right: 4px;
            }
          }

          .os__input {
            color: black
          }

          .os__indicators:after {
            top: 10px !important;
          }

          .os__input-container {
            margin: 0;
            padding: 0 !important;
          }
        }

        .os__menu {
          border-radius: 8px;
          box-shadow: 0 3px 6px rgba(53, 69, 84, 0.15), 0 2px 4px rgba(53, 69, 84, 0.12);

          .os__option {
            .timezone-option {
              display: flex;
              justify-content: flex-start;
              padding: 0 !important;
              color: #354554;
              font-size: 12px;
              line-height: 16px;

              .timezone-constant {
                color: #5D7893;
                min-width: 80px;
                padding-right: 8px;
              }
            }
          }

          //.os__option {
          //  height: inherit;
          //  padding: 4px 4px;
          //}
        }
      }
    }
  }

  .profile-switcher-field {
    .toggle-field {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;

      .slider {
        top: -14px;
      }
    }
  }
}


// Care-space CSS
.form-care-space-container {
  margin-top: 24px;
  background-color: white;
  padding: 40px;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  .toggle-field {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;

    .slider {
      top: -14px;
    } 
  }

  .label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: Inter, serif;
  }

  .form-field {
    display: flex;
    flex-direction: column;

    input {
      background-color: #F4F7F9;
      border-radius: 8px;
      border-style: none;
      height: 40px;
      padding: 8px 16px;
      width: 400px;
    }
  }

  .field-2 {
    .os-menu-drop {
      padding: 0;
      border: none;
      box-shadow: none;
      width: 400px;

      &:hover {
        border: none;
      }
    }

    .os__control {
      background-color: #F4F7F9;
      border-style: none;
      box-shadow: none;
    }

    .os__value-container {
      height: 40px !important;
    }

    .os__input-container {
      height: inherit !important;
      padding: 0;
      margin: 0;
    }
  }

  .patient-notification {
    margin-top: 96px;
    margin-bottom: 24px;
  }

  .action-button{
    display: flex;
  }

}
// css for tiptap css.
.options {
  //display: flex;
  //justify-content: space-between;
  margin-top: 16px;
  .at-bt-dpdowm {
    width: fit-content;

    .header-dropdown-list {
      box-shadow: $block-shadow-m;
    }
  }
  .page-heading-title {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .editor-attachments {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    & > div {
      padding: 4px;
    }

  }
}
