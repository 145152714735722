// mixins
@import "mixins";

@import "_style_guide";
@import "heading";
@import "os_scroll";
@import "iconfont/os_icon";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~loaders.css/src/loaders.scss";

// global elements

@import "elements/avatar";
@import "elements/nav";
@import "elements/form";
@import "elements/notification";
@import "elements/list";
@import "elements/detail";
@import "elements/button";
@import "elements/discussion_tab";
@import "elements/video";
@import "elements/user";
@import "elements/cropper";
@import "elements/alert";
@import "elements/footer";
@import "elements/search";
@import "elements/switch_toggle";
@import "elements/os-form";
@import "elements/os-typo";
@import "elements/mention_tag";
@import "elements/os-button";
@import "elements/emoji-button";
@import "elements/badge";
@import "elements/datepicker";

// icons
@import "iconfont/_ortho";

// components
@import "icons";
@import "layout";
@import "override";
@import "desktop-only";
@import "cases";
@import "signup";
@import "timeline";
@import "assign";
@import "team";
@import "conversations";
@import "tool";
@import "board";
@import "collection_pages";
@import "comments";
@import "modal";
@import "login";
@import "communications";
@import "scrollbar/custom-1";
@import "kanban";
@import "workspace_settings";
@import "new-conversation";
@import "flash-message";
@import "template";
@import "company";
@import "web_recorder";
@import "command_menu";
@import "tiptapEditor";
@import "tiptap-field";
@import "notification-preference";
@import "educational_contents";
@import "custom_dropdown";
@import "slide-animation";
@import "upgrade_plan";
@import "plan_review";
@import "resource_unavailable";
@import "activity";
@import "engagement-new";
@import "notification";
@import "guest-layout";
@import "floater-message";
@import "os-pulse";
@import "feed-slider";
@import "onboard";
@import "empty";
@import "user-account";
@import "user-detail-table";
// @import "landing-page";
@import "new_landing-page";
@import "os_grid";
@import "system_upgrade";
@import "os_dropdown";
@import "member-pending-table";
@import "page_banner";
@import "testimonial_sidebar";
@import "myedit_profile";
@import "icon-svg-table";
@import "care_space_pages";

// OS Cards
@import "cards/card_variable";
@import "cards/appliance_log_card";
@import "cards/attachments";
@import "cards/comment";
@import "cards/message";
@import "cards/notes";
@import "cards/post";
@import "cards/doctor";
@import "cards/company";
@import "cards/space";
@import "cards/case";
@import "cards/comparison";
@import "cards/tool";
@import "cards/private";
@import "cards/loading";
@import "cards/og_cards";
@import "cards/testimonial";
@import "cards/learning-hub";
@import "cards/spaceLinkCard";
@import "cards/patient";
@import "cards/clinic_space_card";
@import 'cards/thumbnail-card';
@import 'cards/card_with_border';
@import 'cards/card_with_shadow';

// SpaceListing
@import "SpaceDropdownListing";
@import "patient_directory_listing";

//modals
@import "add_message_modal";
@import "modal_file_upload";
@import "add-to-board-modal";
@import "modal/analytic_modal";
@import "modal/appointment_modal";
@import "modal/policies_modal";
@import "modal/display_content_portal";
@import "modal/share_entity";
@import "modal/partner_spaces";
/*@import 'modal/edit_profile';*/
@import "modal/plan_payment";
@import "modal/general_modal";
@import "modal/connect";
@import "modal/entity-preview-modal";
@import "modal/update_password";
@import "modal/invitation";
@import "modal/pulse_report";
@import "modal/update_plan";
/*@import "modal/sidebar_category";*/
@import "modal/space";
@import "modal/update_limit";
@import "modal/guide_tour";
@import "modal/doctor_suggest";
@import "modal/attachment_preview";
@import "modal/skip_quiz";
@import "modal/invite_member";
/* @import "modal/upcoming_event";*/
@import "modal/drag_drop_modal";
@import "modal/lecture_preview_modal";
@import "modal/secure_account";
@import "modal/share-link-modal";
@import "modal/video_record";
@import "modal/add-new-patient-modal";
@import "modal/popular-topic";
@import "modal/confirm-profile-update";
@import "modal/task-modal";

//case
@import "case/submit";
@import "case/quiz";
@import "case/case_detail";
@import "case/header";
@import "case/insight_header";
@import "case/case_footer";
@import "case/gallery-grid";
@import "case/case_timeline";
@import "case/cs-btn-group";
@import "component/space-list";
@import "component/space-slider";
@import "component/tool-slider";
@import "case/patient_case";
@import "component/workspace_card";
@import "component/emoji_dropdown";

//compoents
@import "component/space_header";
@import "component/disable_btn";
@import "component/sidebar_widget";
@import "component/information_tab";
@import "component/event-speaker";
@import "component/sidebar_with_cards";
@import "component/lecture";
@import "component/video_scrubber";
@import "component/case-spin";
@import "component/case-sbs";
@import "component/case-slider";
@import "component/case-thumbnail-slider";
@import "component/upcominng-event-widget";
@import "component/ce-survey";
@import "component/lecture_preview";
@import "component/dropdown/options";
@import "component/clv_spin";
@import "component/course-quiz";
@import "component/course-cart";
@import "component/coursekey-widget";
@import "component/course_skill";
@import "component/free_course";
@import "component/course_faq";
@import "component/skill_tag";
@import "component/instructor_sidebar";
@import "component/information_widget";
@import "component/universal-banner";
@import "component/input_range";
@import "component/clv";
@import "component/care-team-widget";
@import "component/appointment_modal_image";
@import "component/patient_file_card";
@import "component/course_complete_bar";
@import "component/care_not_present";
@import "component/care-clv";
@import "component/dob-password";
@import 'component/folder/folder-modal';
@import 'component/folder/folder-page';
@import 'component/dob-password';
@import 'component/inputgroup';
@import 'component/reset_password';
@import 'component/leftsidebar';
@import 'component/rightsidebar';
@import 'component/middlebar';
@import 'component/coming-soon-section';
@import 'component/visitors';
@import 'component/task-list-view.scss';
@import 'component/task-manager.scss';
@import 'component/related-task-widget.scss';
@import 'component/task-action-button.scss';
@import 'component/small-screen-widget.scss';
@import 'component/empty-state-for-task.scss';
@import 'component/pill-button.scss';
@import 'component/workspace_configuration.scss';
@import 'component/treatment-progress.scss';
@import 'component/profile-switcher.scss';
@import 'component/labels-view.scss';
@import 'component/status-widget.scss';
@import 'component/schedule-post.scss';
@import 'component/task-labels-widget.scss';

//pages
@import "page/detail";
@import "page/tool_detail";
@import "page/space_detail";
/*@import "page/trending";
@import "page/feature_course";*/
@import "page/myfolder";
@import "page/care_space_detail";

/*@import 'layout-grid';
*/
//workspace
@import "workspace";

//case
@import "new_cases";

@import "errors";
