.template-description-overlay {
  padding: 24px;
  position: absolute;
  top: 0;
  right: 200px;
  width: 650px;
  overflow-y: auto;
  z-index: 100000;
  background-color: #222B35;
  color: #F4F7F9;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(28, 50, 79, 0.15), 0 3px 6px rgba(53, 69, 84, 0.1);

  .template-description-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    span {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      font-style: normal;
      color: #C9D1DA;
      &:last-child {
        color: #B9C4CF;
      }
    }
  }
  .template-description-content {
    font-weight: 400;
    font-size: 16px;
  }
}