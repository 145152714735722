.case-timeline-right-section {
  .case-right-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .cs-right-dropdown {
      right: 4px;
    }
  }


  .case-treatment-right-section {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    margin-top: 20px;

    .case-active-treatment-block {
      position: relative;
      padding-left: 24px;
      padding-right: 0;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
      width: 100%;

      .timeline {
        margin: 0;
        padding: 0;
      }

      .case-treatment-text-block {
        width: 100%;
        margin-bottom: 0;
        text-align: left;
      }

      .case-treatment-text-small {
        display: inline-block;
        @extend %caption-default;
        color: $level-9;
        margin-bottom: 0;
        text-transform: none;
      }

      .case-treatment-time-block {
        display: inline-block;
        font-size: 14px;
        font-weight: $font-weight-bold;
        float: right;
        line-height: 22px;
      }

      .case-treatment-progress-block {
        width: 100%;
      }

      .case-treatment-progress-grey-bar {
        width: 100%;
        height: 12px;
        position: relative;
        background: $cl-link;
        border-radius: 6px;

        .base-line {
          top: 10px !important;
          height: 0;
        }

        .case-treatment-tooltip {
          font-size: 9px;
          &.left {
            left: 36px;

            &::before {
              left: 15%;
              background: #343434;
              box-shadow: 1px 2px 3px #343434;
            }
          }
          &.right {
            right: 45px;

            &::before {
              left: auto;
              right: 9%;
            }
          }
          .case-treatment-tooltip-content{
            padding: 0 3px;
          }
        }
      }

      .case-treatment-progress-blue-bar {
        width: auto;
        height: 12px !important;
        position: absolute;
        background: $cl-link;
        border-radius: 6px;

        .occurance-line {
          display: none;
        }
      }

      .case-treatment-progress-lightblue-bar {
        width: auto;
        height: 12px !important;
        position: absolute;
        background: $os-link-hover;
        border-radius: 6px;

        .occurance-line {
          display: none;
        }
      }
    }

    .timeline-right-section {
      display: inline-block;
      width: 100%;
      overflow-y: auto;
      padding-right: 10px;
      @extend %os__scroll;
    }
    .detail-text {
      h5 {
        font-size: 12px;
        font-weight: $font-weight-light;
        text-transform: uppercase;
      }
    }
  }

  .case-timeline-right-section {
    &.prioritized-listing {
      height: 100%;
      margin-top: 0;
      text-align: center;

      .case-treatment-right-section {
        margin-top: 0;
      }
    }
  }

  .detail-text {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
    line-height: 24px;
  }
}


.case-timeline-right-section {
  height: calc(100% - 16px);
  margin-top: 24px;

  .case-treatment-right-section {
    margin:-12px 0 0 0 ;
    padding-top: 0;
    height: auto;
    padding-right: 12px;

    @media screen and (max-width: 767px) {
      padding-right: 0;
    }
  }

  .quiz-right-section {
    height: 100%;
    overflow-y: auto;
  }

  .case-active-treatment-block  + .timeline-right-section {
    height: auto ;
    min-height: auto;
    padding-right: 0;
    max-height: none ;
    margin-top: 12px ;
    padding-left: 24px;
  }
}

.cd-detail-conatiner:not(.new-cs-card) {
  .submit-right-container {
    height: 99%;
  }

  @media(max-width: 767px) {

    .case-treatment-right-section {
      padding-top: 0;
    }

    .timeline-right-section {
      max-height: 450px;
      margin-top: 10px !important;
    }

    .quiz-right-section {
      max-height: 550px;
      margin-top: 0 !important;
    }
  }
}

.ipad {
  &.portrait {
    .cd-detail-conatiner:not(.new-cs-card) {
      .quiz-right-section {
        max-height: 457px;
        margin-top: 36px;
      }

      .timeline-right-section {
        max-height: 360px;
      }
    }
  }
}
