.badge {
  border-radius: 12px;
  padding: 2px 8px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: $level-11;

  &.badge-orange {
    background-color: $badge-orange;
  }

  &.badge-yellow {
    background-color: $badge-yellow;
  }

  &.badge-red {
    background-color: $badge-red;
  }

  &.badge-section {
    background-color: $level-4;
    border: 1px solid $level-4;
  }

}

.space-section-dropdown {
  margin-left: 8px;
}

.space-header {
  height: 226px;

  &.no-hero-image {
    height: 32px;
    margin-bottom: 0;
    min-height: unset;
    display: none;
  }

  @media(min-width: 992px) {
    height: 304px;
  }

  @media(min-width: 1200px) {
    height: 360px;
  }


  @media(min-width: 1440px) {
    height: 360px;
  }

  @media(min-width: 1920px) {
    height: 500px;
  }

  position: relative;
  min-height: 72px;

  .header-mask {
    opacity: .2;
    background-image: linear-gradient(to bottom, rgba(18, 18, 20, 50%), #121214 100%);
    border-radius: 8px;
    position: absolute;
    inset: 0;
  }

  .m-header-action {
    position: absolute;
    top: 16px;
    left: 16px;
    right: 8px;
  }
}

.space-slider-image-heading  {
  line-height: 1;
}

.detail-board {
  .sub-nav-background {
    padding-top: 0;

    @media(min-width: 768px){
      .container {
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }

}

.space-heading-title {
  line-height: normal;

  i {
    vertical-align: middle;
  }
}

.web-heading-title {
  position: absolute;
  top: 24px;
  left: 3.61%;
}

.no-hero-image {
  .web-heading-title {
    top: 0;
  }
}

.header-title-row {
  padding: 32px 0 0;
  position: relative;

  + .btn-with-title-bar {
    margin-top: 12px;
    flex-wrap: wrap;
  }

  @media(min-width: 768px) {
    padding-top: 24px;
  }

  .task-btn{
    min-width: 48px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C9D1DA;
    border-radius: 8px;
    margin-left: 8px;
    cursor: pointer;
    color: #7e91a5;

    &:hover {
      background: #354554;
      color: #fff;
    }
  }
}

.space-header-hero-parent {
  overflow: hidden;
  height: 100%;
  width: 100%;

  /* border-radius: 8px; */

  .edit-btn {
    position: absolute;
    right: 3.61%;
    top: 8px;
  }

  .img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    /* border-radius: 8px; */
  }

  .card-btn-more,
  .unsubscribed-space-actions {
    position: absolute !important;
    right: 12px;
    top: 12px;
  }

  .unsubscribed-space-actions {
    .header-dropdown-list {
      top: 52px;
      right: 12px;
    }
  }

  @media screen and (min-width: 768px) {
    .unsubscribed-space-actions {
      display: none !important;
    }
  }
}

.space-header-name {
  @extend %heading-2-bold;

  color: $heading;

  .span {
    margin-right: 6px;

    @media(min-width: 1024px) {
      margin-right: 12px;
    }

    &:only-child {
      margin-right: 0;
    }
  }

  .badge {
    font-size: 12px;
    color: rgba(0, 0, 0, 90%);
    padding-top: 0;
    padding-bottom: 0;
    text-transform: none;
    font-weight: normal;
    vertical-align: middle;
    margin-top: -5px;


    &.badge-light {
      background: $level-4;
    }

    &.badge-section {
      background: $level-4;
    }

    + .badge {
      margin-left: 12px;

      @media(min-width: 1024px) {
        margin-left: 16px;
      }
    }
  }
}



.space-header-right {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-start;
  min-width: calc(33.33% - 12px);
  min-height: 40px;
  position: relative;

  .osbtn {
    &.request-join-btn {
      /* margin-right: 48px; */
    }
  }

  .header-dropdown-menu {
    /* position: absolute !important; */
    right: 0;
    top: 0;
  }
}

.desktop-small {
  .space-header-right {
    .osbtn:not(.osbtn-icon) {
      padding: 0 16px;
      max-width: 190px;
    }
  }
}

.discounted-price {
  text-align: right;
  padding-right: 40px;

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    padding-right: 16px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
    padding-right: 24px;
  }
}

.course-price-sm {
  color: $level-11;
  font-size: 12px;
  line-height: 16px;
}

.course-price-lg {
  color: $os-link;
  font-size: 24px;
  line-height: 32px;
  font-weight: 900;
}

.space-sub-header {
  margin-bottom: 4px;

  .header-dropdown-list {
    z-index: 100;
  }

  .mr-32 {
    margin-right: 32px;
  }

  .dropdown-info-title {
    height: 40px;
  }

  .page-heading-title {
    padding-right: 0;
    padding-left: 0;
  }
}

.sub-space-btn {
  margin-left: -12px;
}

.btn-with-title-bar {
  &:empty {
   display:none !important;
  }

  .space-like-btn:first-child {
    left: -8px;
  }

  .osbtn {
    &.mgl-12 {
      margin-left: -16px;
    }
  }
}

.desktop-small {
  .space-header-hero-parent {
    .edit-btn {
      right: 2px;
    }
  }
}

.ipad {
  &.portrait {
    .space-header-name {
      font-size: 22px;
    }

    .space-header:not(.no-hero-image) {
      height: 304px;
    }
  }

  //&:landscape {
  //  .space-header-hero-parent {
  //    .edit-btn {
  //      right: 2px;
  //    }
  //  }
  //}
}

@media(max-width: 767px) {
  .space-header-hero-parent {
    border-radius: 0;

    .img {
      border-radius: 0;
    }

    .edit-btn {
      right: 2px;
    }
  }

  .space-header {
    height: 120px;
    margin-bottom: 0;

    .header-dropdown-list {
      z-index: 99;
    }

    &.no-hero-image {
      .m-header-action {
        top: 12px;
        height: 32px;
      }
    }

    .sticky-tab-with-dropdown {
      margin-top: 30px;
    }

    .m-header-action {
      top: 8px;

      .space-heading-title {
        margin-top: 0;
        line-height: 16px;
      }
    }
  }

  .space-header-name {
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
  }

  .btn-with-title-bar {
    .space-like-btn {
      left: -8px;
    }

    &.only-mobile-bar {
      display: block !important;
      padding-right: 15px;
      margin-top: 10px;
      font-size: 12px;

      .a-link {
        font-size: inherit;
      }

      > div {
        display: inline-block;

        + .a-link{
          display: inline-block;
        }
      }

      > div,
      .osbtn,
       {
        margin-right: 0 !important;
        font-weight: normal;
      }

      .osbtn {
        padding-left: 0;
        padding-right: 0 !important;
        text-align: left;

        i {
          position: static;
        }
      }

      > div,
      .osbtn, {
        &:nth-child( odd ) {
          padding-right: 10px !important;
          width: 50%;
        }

        &:only-child {
          width: 100%;
        }
      }

      > div,
      span.badge {
        margin-top: 5px;
      }

      > div,
      .osbtn,
      .badge {
        &:nth-child( odd ) {
          margin-right: auto !important;
          margin-left: 0;
        }

        &:nth-child( even ) {
          margin-left: auto;
          text-align: right;

          &.course-durationn {
            width: 50%;
            justify-content: flex-end;
          }
        }
      }

      .badge {
        line-height: 16px;
      }
    }
  }
}

.spaces,
.companies {
  .btn-with-title-bar {
    .user-name-block {
      .ml-1 {
        &:first-child {
          margin-left: 0 !important;
        }
      }
    }

    .a-link {
      + .degree-text {
          font-weight: 400;
          font-size: 12px;
      }

      &:hover {
        + .degree-text {
          /* text-decoration: underline; */
        }
      }
    }
  }
}

.tab-nav-container {
  &.space-tab-fixed  {
    position: relative;
  }
}


.spaces,
.companies {
  .btn-with-title-bar {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
    min-height: 40px;
  }

  .space-sub-header {
    .header-title-row{
      justify-content: space-between;

      .space-info{
        display: flex;

        .ms-3{
          .space-header-name{
            span{
              overflow-wrap: anywhere;
            }
          }
        }
      }
    }

    .detail-comments-header{
      z-index: 999;

      @media (max-width: 1440px) {
        padding: 16px;
      }

    //   div{
    //     .tiptap-editor-container{
    //       margin-top:16px;

    //       .btn-container{
    //         max-width: 168px;
    //         position: absolute;
    //         top: -81px;
    //         right: 0;
    //         padding: 16px 0 0 8px;
    //         box-shadow: none;
    //         background-color: transparent;
    //         border-radius: none;
    //         display: flex;
    //         justify-content: flex-end;
    //         gap: 8px;
    //         align-items: center;

    //       .tiptap-editor{
    //         .btn-group-left{
    //           position: unset !important;
    //         }

    //         .btn-group-right{
    //           .time-pill{
    //             display: flex;
    //             flex-direction: row;
    //             justify-content: center;
    //             align-items: center;
    //             padding: 8px 12px;
    //             gap: 4px;
    //             height: 32px;
    //             background: #EDEFF3;
    //             border-radius: 16px;
    //             cursor: pointer;
    //             margin-right: 16px;

    //             span{
    //               height: 16px;
    //               font-family: Inter, sans-serif;
    //               font-style: normal;
    //               font-weight: 600;
    //               font-size: 12px;
    //               line-height: 16px;
    //               color: #354554;
    //               mix-blend-mode: normal;
    //             }
    //           }

    //           .scheduler-btn{
    //             height: 40px;
    //             width: 40px;
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             border-top-right-radius: 8px;
    //             border-bottom-right-radius: 8px;
    //             color:#fff;
    //             background: #354554;
    //             cursor: pointer;

    //             &:hover{
    //               background:#4A749A;
    //             }
    //           }

    //           .dropdown-active{
    //             background:#4A749A;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .hide-extra-action {
    .fixed {
      pointer-events: none;
      background-color: $level-1;

      .container, .container-fluid {
        pointer-events: auto;
      }
    }

    .button-tooltip {
      bottom: -54px;
      top: auto;
      z-index: 1;

      &::before {
        bottom: auto;
        top: -6px;
        border-top: 0;
        border-bottom: solid 6px $block;
      }
    }

    .sticky-before {
      content: '';
      height: 100%;
      top: 0;
      position: absolute;
      left: 0;
      right: 0;

      @media(min-width: 768px) {
        /* margin-top: -33px; */
      }
    }

    .fixed {
      .sticky-before {
        background-color: $level-1;
      }
    }

    .btn-with-title-bar,
    .only-mobile-bar {
      pointer-events: none;
      opacity: 0;
      overflow: hidden;
    }

    .only-mobile-bar {
      left: 16px;
      right: 0;
      position: absolute !important;
    }

    .tab-nav-container {
      @media(min-width: 768px) {
        /* margin-top: -32px; */
      }
    }
  }
}

.companies {
  .header-with-clv {
    .space-header-right {
      .osbtn {
        &[text='Send Message'] {
          min-width: 150px;
          margin-right: 48px;
        }
      }
    }
  }
}

.ipad{
  .spaces,
  .companies{
    .head-sticky {
      .sticky-before {
        position: absolute;
        top: 0;
        opacity: 1;
        display: block;
        left: 0;
        right: 0;
        height: 100%;

        @include backface-visibility;
      }

      &:not(.fixed) {
        .sticky-before {
          left: 0;
          right: 0;
          width: 100%;
        }
      }

      &.board-header-mobile {
        .sticky-before {
          background-color: transparent;
        }
      }

    }
  }

  &.portrait {
    .spaces,
    .companies{
      .hide-extra-action {
        .tab-nav-container {
          /* margin-top: -34px; */
        }
      }

      .r-p-action {
        margin-top: 40px;
      }

      .header-title-row {
        padding-top: 8px;
      }
    }
  }

  &.landscape {
    .spaces,
    .companies{
      .hide-extra-action {
        .tab-nav-container {
          transition: none;

          /* margin-top: -34px; */
        }
      }
    }
  }
}

.mobile-device {
  .spaces,
  .companies {
    .board-tab-style.tab-sticky {
      &::before {
        opacity: 0;
      }
    }

    .header-title-row {
      padding-top: 16px;

      .avatar-edit{
        .back-btn{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10px;
          padding-top: 20px;
          gap: 8px;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          flex: none;
          order: 1;
          flex-grow: 0;

          i{
            color: #7E91A5;
            width: 17px;
            height: 10px;
          }
        }
      }

    }

    .sub-nav-background {
      height: unset !important;
    }

    .hide-extra-action {
      .sticky-before {
        opacity: 0;
        display: none;
      }
    }

    .detail-board {
      .header-dropdown-list {
        top: unset !important;
        z-index: 99;
      }
    }
  }
}

.guest-layout{
  .spaces,
  .companies,
  .course-unsubscribed {
    .sticky-tab-with-dropdown {
      .head-sticky {
        &::before {
          display: none;
        }
      }

      .header-title-row {
        align-items: flex-start;

        + .btn-with-title-bar {
          .mr-40,
          .ce-credit-btn,
          .reload-complete {
            @media screen and (min-width: 1025px) {
              margin-right: 40px !important;
            }

            @media screen and (min-width: 768px) and (max-width: 1024px) {
              margin-right: 24px !important;
            }
          }
        }
      }
    }
  }
}

.course-unsubscribed {
  .sticky-tab-with-dropdown {
    .header-title-row {
      align-items: flex-start;

      .space-header-name {
        + .text-nowrap {
            margin-top: 8px;
        }
      }

      + .btn-with-title-bar {
        min-height: auto;

        .mr-40,
        .ce-credit-btn,
        .reload-complete {
          @media screen and (min-width: 1025px) {
            margin-right: 40px !important;
          }

          @media screen and (min-width: 768px) and (max-width: 1024px) {
            margin-right: 24px !important;
          }
        }
      }
    }
  }
}

.guest-layout .spaces,
.guest-layout .companies,
.course-unsubscribed {
  .sticky-tab-with-dropdown {
    .header-title-row {
      @media(min-width: 993px) {
        padding-top: 16px;
      }
    }

    .with-extra-info {
      @media(max-width: 767px) {
        .header-title-row {
          padding-top: 8px;
        }
      }

      .header-title-row + .btn-with-title-bar {
        margin-top: 0;
        min-height: unset;

        @media(min-width: 768px) {
          margin-bottom: 32px;
        }

        @media(min-width: 1400px) {
          margin-bottom: 42px;
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .r-p-action {
    .header-dropdown-menu {
      position: absolute !important;
      top: 0;
      right: 12px;
      z-index: 99;
    }

    .osbtn {
      &.d-block + .d-block {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .osbtn-icon.d-block {
      i {
        position: relative;
        transform: none;
        vertical-align: middle;
        left: 0;
        top: -1px;
        margin-right: 3px;
      }
    }
  }

  .header-with-clv {
    .r-p-action {
      .header-dropdown-menu {
        top: 24px;
      }
    }
  }
}

.secure_notification_visibility {
  .modal-content {
    width: 800px;
  }

  .modal-footer {
    margin: 0 !important;

    &>div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 4px;
    }
  }
}
}