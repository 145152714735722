body {
  &.private_access_denied,
  &.not_found {
    .navbar .nav-search-block,
    .mobile-nav-bar .nav-item.search {
      display: none;
    }
  }
}

.heading-404 {
  font-size: 1.5rem;
  padding: 1.95rem 0 1rem;

  .big {
    font-weight: $font-weight-semibold;
    line-height: 1.33;
    padding-bottom: 1rem;
  }
  .small-text {
    font-size: 66.66%;
  }
}

.resource-unavailable {
  padding-top: 64px;
}

.label-404-search {
  margin: 0 0 48px;
  position: relative;
  width: 425px;

  .ifill-os-search {
    position: absolute;
    font-size: 1rem;
    left: 16px;
    top: calc(50% - 8px);
    line-height: 1;
    color: $level-8;
    cursor: pointer;
  }

  button.form-control {
    height: 32px;
    border-radius: 4px;
    font-size: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 45px;
    text-align: left;
    color: $level-8;

    &:hover, &:active, &:focus {
      color: $level-11;
    }
  }
}

.mobile-device {
  .svg-icon-block {
    text-align: center;
  }
  &.portrait {
    .label-404-search {
      width: calc(100% - 2rem);
    }
    .privacy-svg {
      max-width: calc(100% - 2rem);
      margin-top: 15px;
    }
  }
}
