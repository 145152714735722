div.collection-page {
  .title-row{
    padding-top: 40px;
    padding-bottom: 40px;
    h3 {
      font-size: 24px;
      margin-bottom: 0;
    }
  }

  &.notifications-page {
    .page-header {
      justify-content: flex-start;
    }
  }

  &.conversations-page {
    height: calc(100vh - 64px);
    min-height: auto;
    overflow: hidden;
    margin-bottom: 0;
  }

  &.tools{
    min-height: 0;
  }

  &.search {
    &.detail {
      h1 {
        width: 100%;
      }
    }
  }

  &.home {

    .sub-nav-background {
      .object-filter .list-inline-item {
        height: auto;
        padding: 0;
        margin-top: 0 !important;
      }
    }
    .object-header-bar {
      overflow: hidden;
      border-bottom: 0;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        background: #fff-30;
        height: 1px;
        left: 0;
        bottom: 1px;
        z-index: -1;
      }
    }
    .object-filter {
      white-space: nowrap;
      overflow: hidden;
      overflow-x: auto;
      margin-bottom: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;

      .list-inline-item {
        margin-right: 0 !important;
        margin-left: 0;
        line-height: 40px;

        &.object-filter-active {
          &::after {
            margin-left: 0;
            width: calc(100% + 5px);
            box-shadow: none;
            border: 0;
            bottom: 0;
            height: 3px;
            background: #fff-90;
          }
        }

        &:last-child {
          &::after {
            max-width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .title-row{
      display: none;
    }

    &.home {
      min-height: auto;
    }

    &> .container {
      margin-top: 0 !important;

      .page-header {
        display: none;
      }
    }

    &.tools {
      .sub-nav-background {
        .object-header-bar {
          .object-filter {
            width: calc(100% - 50px);
          }

          .object-view-switcher {
            width: 50px;
          }
        }
      }
    }

    &.spaces,
    &.users {
      .object-header-bar {
        .object-filter {
          width: 100%;
          padding-left: 0;
        }
      }
    }

    &.conversations-page {
      height: calc(100vh - 95px);

      margin-bottom: 0;
      overflow: auto;

      &.chat-listing-page {
        height: calc(100vh - 134px);
        margin-top: 0;
      }

      .container {
        &.communication__container {
          padding: 0;
        }
      }
    }

    .tags-container {
      padding-top: 0px;

      &.tags-cases {
        padding-top: 0;
      }
    }
  }

  @media only screen and (min-width : 850px){
    &.home {
      .object-filter {
        .list-inline-item {
          &.object-filter-active {
            &::after {
              margin-left: 0;
              width: calc(100% + 5px);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width : 767px) {
    &.home {
      .sub-nav-background .object-filter .list-inline-item + .list-inline-item,
      .object-filter .list-inline-item + .list-inline-item {
        margin-left: 15px !important;
      }
      .object-filter {
        .list-inline-item {
          &.object-filter-active {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width : 1023px) {
    &.home {
      .object-filter {
        .list-inline-item {
          font-size: 10px;
        }
      }
    }

    &.conversations-page {
      height: calc(100vh - 64px);
    }
  }

  @media only screen and (min-width: 1080px) {
    &.home {
      .object-filter {
        white-space: normal;
      }
    }
  }


  @media only screen and (min-width: 1441px) {
    .sticky-before {
      + .px-0 {
        padding-left: 12px !important;
        padding-right: 12px !important;
      }
    }
  }
}

span.collection-page {
  &.tools {
    display: block;

    .tags-container {
      padding-top: 10px;

      &.tags-cases {
        padding-top: 0;
      }
    }
  }

  &.cases {
    .tags-container {
      padding-top: 10px;

      &.tags-cases {
        padding-top: 0;
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    &.cases {
      .tags-container {
        padding-top: 0;
      }
    }
  }
}
.loader-active {
  height: calc(100vh - 36px - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  .loader-inner{
    margin-top: -150px;
  }

  &.popular-hash-tags {
    height: 150px;
    .loader-inner {
      margin: 0;
    }
  }

  &.full-wh-loader {
    width: 100%;
    height: 100%;
    min-height: 100%;

    .loader-inner {
      margin: 0;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    min-height: 100vh;
  }

  &.xy-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.collection-page {
  margin-bottom: 24px;

  .page-header {
    display: flex;
    justify-content: flex-end;
    height: 60px;
    position: relative;
    z-index: 1021;
    h1 {
      color: $heading;
      font-size: 24px;
      font-weight: $font-weight-semibold;
      line-height: 41px;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: $font-weight-light;
      margin: 0;
      > a {
        color: $os-link;
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        .icon-right-arrow {
          color: #fff;
          height: 14px;
          font-size: 12px;
          margin-left: 7px;
          &:hover {
            color: #fff;
          }
        }
      }
    }

    .btn {
      height: 34px;
      margin-left: 10px;
      border-radius: 17px;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1;
      font-weight: $font-weight-bold;
      letter-spacing: .5px;
      padding: 0 25px;
      display: inline-flex;
      align-items: center;
      background: #8C8C8C;

      &.btn-transparent {
        border: 1px solid $border-button-1;
        background: transparent;
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: #fff;
      }
      i {
        margin-left: 10px;
        position: relative;
        top: 2px;
        font-size: 18px;
        height: 19px;
        line-height: 1;
        font-style: normal;
      }
    }
  }

  &.communication {
    &.conversations-page {
      .page-header {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }
    }
  }
  .trending {
    display: flex;
    align-items: center;
    margin-top: 27px;
    p {
      font-size: 12px;
      line-height: 18px;
      font-weight: $font-weight-bold;
      margin-bottom: 0;
      margin-right: 20px;
    }
    .topics .topic {
      padding: 0 18px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      margin-right: 6px;
      border-radius: 4px;
      background-color: #5a5a5a;
      font-size: 15px;
      line-height: 20px;
      color: #fff;
    }
  }

  // scoped collection pages
  &.home {
    // .page-header {
    //   justify-content: flex-end;
    // }

  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    &.search {
      .saerch-container {
        padding: 0;
      }

      .detail-header {
        padding: 10px 15px;

        hr {
          display: none;
        }

        h1 {
          width: 100%;
          font-size: 18px;
          line-height: 24px;
        }

        p {
          margin-bottom: 0;
          font-size: 14px;
        }
      }

      .object-header-bar {
        .object-filter {
          width: calc(100% - 50px);
        }

        .object-query {
          display: none;
        }

        .object-view-switcher {
          width: 50px;
          padding-left: 10px;

          &.user-search-sort-block {
            right: 0;

            .sorting-block {
              &::before {
                content: '';
                width: 1px;
                height: 20px;
                position: absolute;
                top: 10px;
                left: -10px;
                background: rgba(151, 151, 151, 0.2);
              }
            }
          }
        }
      }

      .cards {
        &.row {
          margin: 0;
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .page-header {
      .cases-search-block,
      .cases-search-holder {
        width: 70%;

        .nav-search {
          width: 100%;
          min-width: 500px;
        }
      }

      .cases-search-holder {
        .cases-search-block {
          width: 100%;
        }
      }
    }
  }
}

.conversations {
  #intercom-container {
    .intercom-launcher-frame,
    .intercom-launcher-mobile,
    .intercom-launcher-discovery-frame {
      bottom: 80px !important;
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (-webkit-min-device-pixel-ratio: 1) {
  .collection-page {
    .page-header {
      .btn {
        height: 28px;
        padding: 5px 12px;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  body {
    &.new-chat {
      div {
        &.collection-page {
          &.conversations-page {
            margin-top: 0;
          }
        }
      }
    }

    #intercom-container {
      .intercom-launcher-frame {
        right: 5px !important;
        bottom: 50px !important;
      }
    }
  }
}

.ipad {
  .conversations {
    #root {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .layout {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.mobile-device {
  .collection-page {
    min-height: auto;
  }

  .collection-page.home {
    .sub-nav-background {
      .object-filter{
        padding-left: 15px;

        .list-inline-item  {
          &:last-child {
            padding-right: 15px ;
            &.object-filter-active {
              &:after {
                width: calc(100% - 15px);
              }
            }
          }
        }
      }
    }
    .object-filter .list-inline-item {
      font-size: 11px !important;
    }

    .sub-nav-background .object-filter .list-inline-item + .list-inline-item,
    .object-filter .list-inline-item + .list-inline-item {
      margin-left: 15px !important;
    }
  }

  .conversations {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    .layout {
      min-height: unset;
      padding-bottom: 0;
    }

    &:not(.chat-detail) {
      .collection-page {
        position: fixed;
        top: 70px;
        bottom: 0;
        height: unset !important;
        overflow: hidden;
        left: 0;
        right: 0;
        width: 100%;
        padding-top: 0;
      }
    }
  }

  .users {
    .notifications-page {
      padding-left: 0;
      padding-right: 0;

      .nf-card-details-row {
        margin: 0;
      }

      .nf-container {
        padding-left: 0;
        padding-right: 0;
      }

      .nf-day-heading {
        margin-left: -10px;
      }
    }
  }
}

.saerch-container {
  margin-top: 24px;
  .sub-nav-background {
    .container {
      padding: 0px;
    }

    @media screen and (min-width: 1250px) and (max-width: 1440px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .object-view-switcher {
    right: -15px;

    .header-dropdown-list {
      right: 12px;
    }
  }
}

.ipad {
  body {
    &.workspace-body {
      padding-bottom: 0px;

      .loader-active {
        .loader-inner{
          margin-top: 0px;
        }
      }
    }
  }
}

.card-z-index {
  z-index: 1;
}

.conversation-card-container {
  &.card-z-index {
    z-index: 1;

    .emoji-bar {
      z-index: -1;
    }
  }
}

.guest-layout {
  .collection-page {
    padding-bottom: 48px ;
    margin-bottom:0;
  }
}
