.mobile-device {
  .dob-password-container {
    .os-menu-drop {
       &.month {
         width: 37vw;
       }
       &.day {
         width: 28vw;
       }
       &.year {
         width: 32vw;
       }
     }
  }
}
.dob-password-container {
  margin: auto;
  max-width: 87%;
  width: 675px;
  height: 700px;
  padding: 40px;
  margin-top: 40px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(53, 69, 84, 0.15), 0px 2px 4px rgba(53, 69, 84, 0.12);
  border-radius: 12px;

  .os-menu-drop {
    border: none;
    padding: 0 16px 0 0 !important;
    background: none !important;
    width: 150px;
  }

  .os__menu-list {
    .os__option {
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 13px 12px;
    }
  }

  .image-col{
    width: -webkit-fill-available;
    padding: 0 20px;
    margin-bottom: 70px;
    img{
      max-width: 432px;
      max-height: 149px;
    }
    .clinic-info{
      margin-top: 16px;
      height: 48px;
      max-height: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #354554;

      mix-blend-mode: normal;
      opacity: 0.9;

      flex: none;
      order: 1;
      flex-grow: 0;

      .phone-email{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span{
          line-height: 1.6;
          color: $level-10;
          text-align: start;
          i{
            margin-right: 10px;
            height: 14px;
            width: 14px;
          }
        }
      }

      .address{
        height: inherit;
        width: 200px;
        display: flex;
        align-items: flex-start;
        i{
          margin-top: 3px;
          margin-right: 10px;
          height: 14px;
          width: 14px;
        }

        span{
          line-height: 1.6;
          color: $level-10;
          text-align: start;

        }
      }
    }
  }

  .form-heading {
    font-size: 24px;
    font-weight: 800;
    color: $level-11;
    line-height: 1.33;
    margin-bottom: 24px;
  }

  .form-text-hint {
    padding: 0 40px;
    text-align: start;
    // max-width: 497px;
    line-height: 1.6;
    color: $level-10;
    margin: 24px 0 16px 0;
  }

  .lock-unlock-btn {
    margin-left: 0;
  }

  .decline-care-row {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;

    .osbtn {
      max-height: 40px;
      padding: 0 16px;
      line-height: 40px;
    }
  }

  svg-icon {
    width: 175px;
    height: 175px;
  }

  .appointment-info{
    width: -webkit-fill-available;
  }

  @media(min-width: 768px) {
    // max-width: 500px;
    svg {
      height: 240px;
      width: 240px;
    }

    .form-text-hint {
      text-align: start;
    }

    .appointment-info-row {
      padding: 0 40px;
      width: 100%;
      display: flex;
      align-items: end;
      .form-group  {
        margin-bottom: 0;
        width: inherit;
        display: flex;
        flex-direction: column;

        .react-date-picker{
          width: 100%;
          .react-date-picker__wrapper{
            height: 40px;
            background: #F4F7F9;
            padding: 0px 16px;
            border-radius: 8px;
            border: none;
            &:hover{
              border: 1px solid #4A749A;
            }
          }
        }

        .form-control{
          background: #F4F7F9;
          padding: 8px 16px;
        }
      }

      .lock-unlock-btn {
        margin-left: auto;
      }
    }
  }
}

html.mobile-device {
  .dob-password-container {
    margin: 0;
    max-width: 100%;
    height: auto;
    box-shadow: none;
    padding: 40px 16px;

    .image-col{
      width: 100%;
      padding: 0;

      img{
        width: 100%;
      }

      .clinic-info{
        height: auto;
        align-items: flex-start;

        .phone-email{
          gap: 16px;
        }
      }
    }

    .form-text-hint{
      padding: 0;
    }

    .appointment-info-row {
      .form-group  {
        .react-date-picker{
          width: 100%;
          .react-date-picker__wrapper{
            height: 40px;
            background: #F4F7F9;
            padding: 0px 16px;
            border-radius: 8px;
            border: none;
            &:hover{
              border: 1px solid #4A749A;
            }
          }
        }


      }

      .lock-unlock-btn {
        margin-left: auto;
      }
    }
  }
}

html:not(.mobile-device) {
  .dob-password-container {
    @media(min-height: 800px) {
      // padding-top: 80px;
    }
  }
}

.modal-decline-access {
  .modal-content {
    padding: 24px 32px;
  }

  .modal-dialog {
    max-width: 488px;
  }

  .modal-body,
  .modal-header,
  .modal-footer {
    padding: 0;
  }

  .modal-footer {
    padding: 24px 0 16px;

    > *{
      margin: unset;
    }

    .osbtn {
      padding: 0 16px;

      + .osbtn {
        margin-left: 24px;
      }
    }
  }

  .can-edit-image {
    margin: 22px 0 16px;
    text-align: center;
  }

  .form-group,
  label {
    padding-left: 40px;
  }

  label {
    .checkmark {
      width: 22px;
      height: 22px;
      border-width: 2px;

      &:after {
        width: 14px;
        height: 14px;
        left: 2px;
        top: 2px;
      }
    }
  }

  input.error {
    border: 1px solid $lt-red;
  }

  @media(max-width: 400px) {
    .modal-footer {
      .osbtn + .osbtn  {
        margin-left: 8px;
      }
      flex-wrap: nowrap;
    }

    .modal-content {
      padding: 24px 16px;
    }
  }
}
