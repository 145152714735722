.landing-page {
  .navbar-unauth-text {
    .mr-32 {
      margin-right: -12px;
    }

    .osbtn {
      padding: 0px 12px;
      margin-left: 12px;
      font-weight: bold;
    }
  }

  .instructors-avatar {
    + div >.osbtn-secondary {
      margin-top: 56px;
    }
  }
}

.landing-page-section {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .free-course-form  {
    margin: 64px 0 24px;
  }

}

#skills-to-learn-section {
  + .top-doctors {
    padding-top: 72px;
  }
}

.landing-article {
  &.top-doctors {
    padding-top: 40px;

    .article-heading {
      .banner-font-16 {
        text-align : center;
      }
    }
  }

  &.case-list {
    text-align: center;
    padding-top: 96px;

    .cs-title-wrap {
      text-align: left;
    }
  }

  .article-heading {
    @include fontstyle(16px, 300, 1.5);

    .banner-font-28 {
      @include fontstyle(28px, 900, 1.14);
      text-align: center;
    }

    .banner-font-16 {
      max-width: 66%;
      margin: 32px auto 48px;
      text-align: center;
    }
  }

  &.learning-hub-list {
    padding-top: 40px;

    .banner-font-16 {
      margin-bottom: 72px;
    }
  }

  &.landing-footer {
    padding-top: 40px;
    padding-bottom: 160px;

    .osbtn-primary {
      min-width: 160px;
    }
  }

  .small-avatar-row {
    .ins-avtar-col {
      .avatar {
        + .pt-2 {
          padding-top: 0px !important;
        }
      }
    }
  }
}

.ipad {
  &.portrait {
    .landing-article {
      &.case-list {
        .article-heading {
          .banner-font-16 {
            max-width: 100%;
            padding-left: 18px;
            padding-right:18px;
          }
        }
      }
    }
  }
}

.touch-support,
.no-touch-support {
  &:not(.mobile-device) {
    .new-landing-page-container {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.mobile-device {
  .landing-page {
    .navbar-unauth-text {
      .mr-m-16 {
        margin-right: -12px;
      }
    }

    .footer {
      display: block !important;

      .row {
        + .row{
          display: flex;
          justify-content: space-between;
        }
      }

      .copyright {
        font-size: 8px;
        display: inline-flex;

        + div {
          display: inline-flex !important;
        }
      }

      ul {
        margin-top: 0;
      }

    }
  }

  .landing-article {
    padding-top: 86px;

    &.top-doctors {
      text-align: left;
    }

    &.course-list {
      padding-top: 46px;
    }

    &.learning-hub-list {
      padding-top: 46px;

      .banner-font-16 {
        margin: 24px 0 40px;
      }
    }

    .article-heading {
      .banner-font-28 {
        @include fontstyle(20px, 600, 1.4);
        text-align: left;
      }

      .banner-font-16 {
        margin: 24px 0 40px;
        max-width: 100%;
        text-align: left;
      }
    }

    &.landing-footer {
      padding-bottom: 80px;

      .article-heading {
        text-align: center;

        .banner-font-28,
        .banner-font-16 {
          text-align: center;
        }

        .banner-font-16 {
          margin: 16px auto 40px;
          max-width: 80%;
        }
      }
    }
  }
}
