.account_settings {
  .layout {
    min-height: unset;
    padding-bottom: 0;
  }
}

.account-setting-container {
  padding-top: 48px;

  .detail-header {
    font-size: 24px;
    font-weight: 900;
    line-height: 28px;
    color: $heading;
  }

  .notifications-preferences {
    padding-left: 0;
    padding-right: 0;
  }

  .sub-nav-background {
    .xl-container {
      padding-left: 12px !important;
      padding-right: 12px !important;

      @media screen and (min-width: 768px) {
        max-width: 744px;
      }

      @media screen and (min-width: 992px) {
        max-width: 984px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 1152px;
      }

      @media screen and (min-width: 1441px) {
        max-width: 1344px;
      }
    }
  }
}

.user-invitation-container {
  margin-top: 48px;

  .sidebar-container {
    padding-bottom: 16px;

    .invite-user-info {
      font-size: 16px;
      line-height: 1.5;
    }

    .invite-img-block {
      margin-right: 0 !important;
    }

    &:not(.sidebar-full) {
      .invite-img-block {
        img {
          width: 136px;
        }
      }
    }
  }
}

.ipad {
  .account-invitee-data {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
}

.user-account-setting-container {
  margin-top: 32px;

  .signup-account-details-card {
    padding: 0 !important;
  }
}

.account-tabs-data {
  padding-bottom: 24px;

  .account-plan-card {
    width: 100%;
    min-height: 216px;
    background: #1c1c1f;
    border-radius: 6px;
  }


  .os-default-card {
    padding: 24px 24px 44px;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .address-card-btn-row {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .loader-active {
    height: 216px;
    min-height: unset;

    .loader-inner {
      margin-top: 0;
    }
  }

  .user-empty-icon {
    margin: 32px 0 10px;
    text-align: center;
  }
}

.ac-setting-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  .ac-footer {
    margin-top: auto;
    padding-bottom: 8px;
    text-align: center;
  }
}

.user-subscription-list {
  margin-top: 40px;

  li {
    line-height: 16px;
  }
}

.expired-text {
  font-size: 12px;
  font-weight: normal;
  text-transform: capitalize;
  margin-left: 8px;
  padding-top: 6px;
  display: inline-block;
}

.touch-support {
  .account-tabs-data {
    .os-default-card {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}


.user--details-list {
  .user-address-details-row {
    align-items: center;
  }

  .user-address-details-value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}


.no-invited-user-container {
  .sidebar-full {
    width: 441px;
    max-width: 100%;
    margin: auto;
    background: none;
    padding: 0 0 16px;

    .invite-explore-tab {
      margin-top: 40px;
    }

    .invite-user-section {
      display: flex;
      flex-direction: column;
    }

    .invite-user-title,
    .invite-user-info {
      text-align: center;
    }

    .invite-user-title {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 8px;
    }

    .invite-title-heading {
      font-size: 20px;
      padding-right: 8px;
      line-height: 1.4;
    }

    .invite-user-info {
      color: $level-11;
      font-size: 16px;
    }
  }

  .invite-user-blocks {
    order: -1;
    display: block !important;
    margin-bottom: 32px;
  }

  .invite-img-block {
    img {
      max-width: 420px;
    }
  }
}

@media(max-width: 1199px) {
  .user-invitation-container:not(.no-invited-user-container) {
    .sidebar-container {
      padding-left: 16px;
      padding-right: 16px;
    }

    .invite-user-section {
      .invite-user-blocks {
        display: block;
        max-width: 120px;
        margin-left: auto;
        margin-right: auto;
      }

      .invite-img-block {
        img {
          width: 120px;
        }
      }

      .invite-user-info {
        margin-top: 1rem;
      }

      .invite-user-info {
        line-height: normal;
        font-size: 12px;
      }
    }
  }
}

.mobile-device {
  .user-subscription-list {
    margin-top: 36px;
  }

  .account-tabs-data {
    padding-bottom: 0;
  }

  .os-acctoun-tab-style.os-tab-style {
    .sub-nav-background {
      background: none;
    }

    .object-header-bar:after {
      content: '';
      position: absolute;
      left: -12px;
      right: -12px;
      z-index: -1;
      height: 32px;
      top: 0;
    }
  }

  &.portrait {

    .user-invitation-container {
      margin-top: 24px;

      .invite-user-section:not(.full-v-section) {
        .invite-user-blocks {
          align-items: center;
          display: flex;
          max-width: 100%;
        }

        .invite-user-info {
          margin-top: 0;
          padding-right: 16px;
        }

        .invite-img-block {
          margin-right: 8px !important;
        }

        .invite-user-title {
          margin-bottom: 24px;
        }

        .invite-user-title {
          display: flex;
          align-items: center;

          .invite-user-count {
            margin-left: 8px;
            margin-top: 0;
          }
        }

        .invite-explore-tab {
          margin-top: 13px;
        }
      }
    }

    .account-setting {
      .os-tab-style {

        .sub-nav-background {
          margin: 0;
        }

        .object-header-bar {
          padding-left: 0;
          margin: 0 0;

          &:after {
            left: 0;
            right: 0;
          }
        }

        .object-filter {
          padding-left: 12px;
          padding-right: 14px;

          &:after {
            left: 12px;
            right: 0;
            background: rgba(52, 52, 54, 1);
          }
        }

        .list-inline-item {
          position: relative;

          + .list-inline-item {
            margin-left: 16px !important;
          }

          &:first-child:before {
            content: none;
            position: absolute;
            left: 0;
            right: 0;
            background: rgba(52, 52, 54, 1);
            height: 1px;
            bottom: -4px;
            left: 0;
          }

          &:last-child {
            padding-right: 15px;

            &:after {
              right: 15px;
            }
          }

          &.object-filter-active {
            &:before {
              display: none !important;
            }
          }
        }
      }
    }
  }

  &.landscape {
    .user-account-setting-container {
      margin-top: 24px;
    }

    .user-invitation-container {
      margin-top: 16px;
    }

    .invitee-sticky-require {
      @include sticky;
      top: 124px;
      align-self: flex-start;

      .sidebar-container {
        padding-bottom: 0
      }
    }
  }

  .account-setting-container {
    padding-top: 24px;

    .detail-header {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 20px;
    }

    .dropdown-info-title {
      padding-top: 5px;
    }
  }

  .account-setting {
    .os-tab-style .list-inline-item {
      margin-left: 32px;
    }
  }

  .detail-header {
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
  }

  .no-invited-user-container {
    .sidebar-full {
      .invite-user-blocks {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      .invite-title-heading {
        font-size: 16px;
        padding-right: 8px;
        line-height: 1.4;
      }

      .invite-user-info {
        color: $level-11;
        font-size: 12px;
      }
    }
  }
}

.ipad {
  .account-tabs-data {
    padding-bottom: 0;
  }

  .account-setting-container {
    padding-top: 40px;
  }

  .user-invitation-container {
    margin-top: 32px;
  }

  &.portrait {
    .user-invitation-container {
      .pulse-sidebar {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media(min-width: 1440px) {
  .account-setting-container {
    padding-top: 80px;
  }

  .account-tabs-data {
    padding-bottom: 0;
  }
}
