%cs-os-btn {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  padding: 0  ;
  color:$appt-text !important;
  background-color: $appt-btn !important;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  @include fontstyle(8px, 600, 32px);
  transition: transform .1s ease-out;
  margin: 0 8px 0 0;

  .btn-icon-helper {
    color: $appt-icon !important;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    background-color: $cs-btn-hover !important;
    color: $cs-btn-hover !important;
    text-decoration: none;

    .btn-icon-helper {
      color: $level-1 !important;
    }
  }

  &.osbtn-disabled,
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: auto;
    color: $level-6 !important;

    .btn-icon-helper {
      color: $level-6 !important;
    }

    &:focus, &:active, &.active {
      cursor: not-allowed;
    }

    &:hover {
      background-color: $level-1 !important;
      .btn-icon-helper {
        color: $level-6 !important;
      }
    }
  }
}

.no-touch-support {
  %cs-os-btn {
      &:not(.cs-record-btn) {
      &:hover {
        background-color: $cs-btn-hover !important;
        color: $cs-btn-hover-text !important;
        text-decoration: none;

        .btn-icon-helper {
          color: $cs-btn-hover-text !important;
        }
      }
      &.active {
        color: $appt-btn-active-icon !important;
      }

      &.osbtn-disabled,
      &:disabled,
      &[disabled] {
        cursor: not-allowed;
        pointer-events: auto;
        color: $level-6 !important;

        .btn-icon-helper {
          color: $level-6 !important;
        }

        &:hover {
          background-color: $level-1 !important;
          box-shadow: none;
          .btn-icon-helper {
            color: $level-6 !important;
          }
        }
      }
    }
  }
}

.cs-header-btn-group {
  text-align: right;
  padding-right: 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 0;

  .cs-header-btn {
    margin: 0;

    + .cs-header-btn {
      margin-left: 8px;
    }
  }
}

.cs-header-btn {
  @extend %cs-os-btn;
  font-weight: 600;

  &.reset-btn {
    min-width: 72px;
    width: auto;
    padding: 0 16px;
    background-color: transparent !important;
    color: $level-9 !important;
  }

  &.osbtn-disabled:hover {
    background-color: #222226 !important;
    color: #303036 !important;

    .button-tooltip {
      display: none;
    }
  }

  &.osbtn-disabled {
    background-color: #222226;
    color: #303036 !important;
  }
}

.cs-record-btn {
  @extend %cs-os-btn;
  cursor: pointer;
  font-size: 16px;
  padding-top: 8px;
  line-height: 1;
  margin-right: 4px;

  .button-tooltip {
    top: auto;
    bottom: calc(100% + 7px);
    width: auto;
    margin: 0;
    white-space: nowrap;
    left: 50%;
    text-align: left;
    transform: translateX(-50%);
    padding: 4px 16px;

    .ifill-os-info {
      color: $lt-red;
      display: inline-block;
      margin-right: 8px;
    }

    &::before {
      content: none;
      left: 50%;
      top: 100%;
      border-left: solid 6px transparent;
      border-top: solid 6px transparent;
      border-right: solid 6px transparent;
      border-top: solid 6px $level-1;
      margin-left: -3px;
    }
  }

  &:hover {
    z-index: 1;
    .button-tooltip {
      display: block;
    }
  }

  &.cs-index-count {
    font-size: 12px;
    background-color: transparent !important;
    line-height: 16px;
    height: 16px;
    min-height: 16px;
    padding-top: 0;

    .button-tooltip {
      bottom: calc(100% + 46px);
    }

    &:after {
      content: none !important;
    }

    &:hover {
      background-color: transparent !important;
    }

    .dot {
      background-color: $os-link;
      width: 4px;
      height: 4px;
      display: none;
      border-radius: 50%;
      margin-left: 2px;
      vertical-align: middle;
      margin-top: -2px;
    }

    &.timeline-active {
      background-color: transparent !important;
      color: $os-link !important;

      .dot {
        display: inline-block;
      }
    }
  }
}


.cs-sort-btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 12px;
  position: absolute;
  width: 32px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  z-index: 99;
  opacity: 0;
  transition: all  500ms ease;

  .cs-sort-btn {
    @extend %cs-os-btn;
    margin: 0 0 8px ;

    &:last-child {
      margin-bottom: 0;
    }

    .button-tooltip {
      top: 50%;
      width: auto;
      margin: 0;
      transform: translateY(-50%);
      white-space: nowrap;
      left: 100%;
      margin-left: 7px;
      text-align: left;

      &::before {
        transform: rotate(90deg);
        left: -6px;
        bottom: 10px;
      }
    }

    &:hover {
      z-index: 1;
      .button-tooltip {
        display: block;
      }
    }
  }

  &.show-btns {
    opacity: 1;
    left: 8px;
  }
}


.sbs-right {
  .cs-sort-btn-group {
    .cs-sort-btn {
      @media(min-width: 768px) {
        .button-tooltip {
          transform: translateY(-50%);
          left: auto;
          right: 100%;
          margin-left: 0;
          margin-right: 7px;
          text-align: right;

          &::before {
            transform: rotate(-90deg);
            right: -4px;
            left: auto;
            margin-left: 0;
            margin-right: -3px;
            bottom: 10px;
          }
        }
      }

      &:hover {
        .button-tooltip {
          display: block;
        }
      }
    }
  }
}

.no-touch-support {
  .case-left-wrapper,
  .sbs-wrapper {
    .cs-sort-btn-group {
      pointer-events: none;
    }

    .sbs-right {
      .cs-sort-btn-group {
        right: 0;
        left: auto;
      }
    }

    &:hover {
      .cs-sort-btn-group {
        opacity: 1;
        pointer-events: auto;
      }

      .sbs-left {
        .cs-sort-btn-group {
          left: 8px;
        }
      }

      .sbs-right {
        .cs-sort-btn-group {
          right: 8px;
        }
      }
    }
  }

  .case-left-wrapper {
    &:hover .spin-slide-wrapper {
      .cs-sort-btn-group {
        left: 8px;
        opacity: 1;
        pointer-events: auto;
        margin-top: -8px;
      }
    }
  }
}

.ipad {
  &.landscape {
    .cs-header-btn-group {
      padding-top: 0;
      align-items: center;
    }
  }
}

.mobile-device {
  .cs-sort-btn-group {
    left: 0;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    top: auto;
    bottom: -100px;
    width: 100%;
    flex-direction: row;
    padding-top: 4px;
    background-color: $level-2;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 16px;

    .cs-sort-btn {
      min-width: 48px;
      width: 48px;
      height: 48px;
      margin-bottom: 0;
      line-height: 48px;
      margin-left: 8px;
      background-color: $level-1 !important;
      color: $level-11 !important;

      .btn-icon-helper {
        color: $level-11 !important;
      }
      &.active {
        background-color: $level-11 !important;
        color: $level-1 !important;

        .btn-icon-helper {
          color: $level-1 !important;
        }
      }
    }

    &:hover {
      z-index: 1;
    }
  }
}


html:not(.mobile-device) {
  .case-card-detail-header {
    justify-content: space-between;
  }
  .cs-header-btn-group {
    max-width: 302px;
  }
}
