$card-gutter: 24px;
$card-gutter-16: 16px;
$card-height: 320px;
$card-height-sm: 160px;
$card-360: 360px;
$card-240: 240px;
$card-304: 304px;
$card-bg-color: $level-1;
$card-border-radius: 8px;
$extra-card-height-sm: 96px;
$card-heading: $level-11;
$card-text: $level-9;
$card-sm-text: $card-text;
$card-icon: $level-8;
$card-thumbnail: 120px;
$card-border: $level-5;
$card-shadow: $block-shadow;
$bg-yellow: #fec452;
$bg-orange: #e1683b;
$bg-red: #e05257;
$bg-green: #4a9e6f;
$bg-blue: #4c89e5;
$bg-pink: #ff9aca;
$bg-purple: #9b85cb;

%card-heading {
  color: $card-heading;
  font-weight: $font-weight-semibold;
  line-height: 1.33;
}

%os-card-base {
  background: $card-bg-color;
  color: $card-text;
  max-width: 100%;
  border-radius: $card-border-radius;
  position: relative;
  cursor: pointer;
  transition: 500ms;
  will-change: transform, opacity;
}

%os-lg-card {
  height: $card-height;
  max-height: $card-height;
  width: 100%;
  max-width: 100%;
  box-shadow: $block-shadow-l;
}

%os-sm-card {
  height: $card-height-sm;
  max-height: $card-240;
  box-shadow: $block-shadow;
}

%os-extra-sm-card {
  height: $extra-card-height-sm;
  max-height: $extra-card-height-sm;
}

%os-card-thumbnail {
  height: $card-thumbnail;
  max-height: $card-thumbnail;
  max-width: 120px;
  padding: 8px;
  @extend %os-card-base;
  color: $level-11;
}

%os-304 {
  max-width: 100%;
}

%os-240 {
  max-width: 100%;
}

%card-x-gutter-16 {
  padding-left: $card-gutter-16;
  padding-right: $card-gutter-16;
}

%user {
  display: flex;
  align-items: center;

  .user-name {
    @include fontstyle(16px, 600, 1.5);
    @include text-truncate;
    padding-right: 8px;
    max-width: 175px;
  }

  .user__info {
    display: flex;
    align-items: center;

    * {
      margin: 0;
    }
  }

  .user-title {
    @include fontstyle(12px, normal, 1);
    color: $level-9;
    @include text-truncate();
    max-width: 99px;
  }
}

%os-card-head {
  @include fontstyle(12px, normal, 1.33);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: $level-11;
  margin-bottom: 16px;
  position: relative;
  padding-left: $card-gutter;
  padding-right: $card-gutter;

  i{
    font-size: 16px;
  }
}

%col-head {
  display: flex;
  align-items: center;
  color: $level-11;
}

%img-parent {
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  height: 100%;
  width: 100%;
}

%overlay-span {
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  bottom: -1px;
  border-radius: 8px 8px 0 0;
  background: rgba(34, 43, 53, 0.2);
  opacity: 0.6;
  transition: 500ms;
  will-change: opacity;
}

.br-radius-4 {
  border-radius: 4px;
}

.card-dropdown-menu  {
  position: absolute;
  right: 16px;
  z-index: unset;

  &.top-18 {
    top: 18px;
  }

  .ifill-os-more {
    font-size: 16px;
  }

  .dropdown-info-title {
    padding-bottom: 0 !important;
  }

  .header-dropdown-list-item   {
    &:last-child {
      .osbtn-icon {
        &[text='Remove from space'],
        &[text = 'Remove from carespace']{
          border-top: 1px solid $border-button-1 !important;
        }
      }
    }
  }
}

.select-custom-checkbox{
  position: relative;
  padding-left: 20px;
  color: $level-11;
  @include fontstyle(12px, normal, 1.33);
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 0;

  .ifill-os-checkmark-round-default,
  .ifill-os-checkmark-round {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0%, -50%);
    text-align: center;
    line-height: 16px;
    font-size: 16px;
    color: #787878;
    cursor:pointer;
    margin-top: -1px;


    &:hover {
      &:before {
        /*content: "\e961";*/
        content: "\e920";
        color: #48acfd;
        transition: color 500ms ease;
        will-change: color;
      }
    }
  }

  input:checked ~ .ifill-os-checkmark-round-default,
  input:checked ~ .ifill-os-checkmark-round {
    &:before {
      /*content: "\e961";*/
      content: "\e920";
      color: #48acfd;
    }
  }
}

.os-close-link {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  right: 16px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  background: $level-1;
  border-radius: 8px;
  z-index: 4;
  color: $level-8;
  transform: translateY(-50%);
  transition: background-color 500ms ease;
  will-change: background-color;

  &[data-hover="true"]:hover {
    color: $level-8;
    background: $level-4;
  }

  &:active, &:focus {
    background: $level-5;
  }
}

.osbtn.no-text {
  &.mr-right-4 {
    margin-right: -4px;
  }
}

%img-cover {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.avoid-modal-actions {
  .add-to-space-button,
  .report-button,
  .os-share-button {
    pointer-events: none !important;
    opacity: 0.3;
  }
}

.os-card-v2 {
  display: block;
  .ifill-os-comments {
    margin-top: 2px;
  }
}

.extra-small-card-space-name {
  @extend %card-heading;
}

.no-touch-support {
  .os-card-v2 {
    &:hover {
      %os-card-base {
        transform: translateY(-2px);
      }

      .hero-image-overlay,
      .overlay-span {
        opacity: 0.4;
      }
    }
  }
}

.link-card {
  .img-parent {
    background-color: $bg-orange;
  }
}

.os-card-v2 {
  .image-icon {
    opacity: 1;
  }
}

.os-card-v2:not(.extra-small-min-card) {
  .os-sm-user {
    .card-dropdown-menu {
      right: 8px;
      z-index: 2;
      top: 8px;
    }
  }

  .os-sm-care, .os-sm-person {
    .card-dropdown-menu {
      right: 12px;
      z-index: 2;
      top: 12px;
    }
  }

  .os-sm-attachment {
    .card-dropdown-menu {
      right: -8px;
    }
  }

  .os-sm-video{
    .card-dropdown-menu {
      right: 8px;
    }
  }

  .os-sm-attachment {
    .attach-card-head {
      right: 16px;
      left: 16px;
    }
  }

  .os-sm-video {
    .attach-card-head {
      left: 16px;
      right: 0;
      top: 16px;
    }
  }

  .os-sm-comment,.os-sm-conversation-message {
    .card-dropdown-menu {
      right: 8px;
      z-index: 3;
      top: -8px;
    }
  }

  .os-sm-case {
    .card-dropdown-menu {
      right: 8px;
      top: -4px;
    }
  }
}

.extra-small-min-card {
  .card-dropdown-menu {
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }

  &.selection-mode {
    .card-dropdown-menu {
      display: none;
    }
  }


}

.image-card {
  .img-parent,
  .thumbnail-image,
  .cd-list-img {
    background-color: $bg-yellow;
    color: $level-1;
    border-radius: 8px;
  }
}

.doc-card {
  .attachment-card-image,
  .thumbnail-image,
  .cd-list-img {
    background-color: $bg-green;
    color: $level-1;
    border-radius: 8px;
  }
}

.case-card {
  .single-case-img,
  .thumbnail-image,
  .cd-list-img {
    background-color: $bg-red;
    color: $level-1;
    border-radius: 8px;
  }
}


.doc-card,
.case-card {
  .thumbnail-image {
    display: flex;

    .attach-custom-file-icons {
      margin: auto;
      height: 100%;
    }
  }
}

.tool-card {
  .img-parent,
  .thumbnail-image,
  .cd-list-img {
    background-color: $bg-blue;
    color: $level-1;
    border-radius: 8px;
  }
}
.post-card {
  .img-parent,
  .thumbnail-image,
  .cd-list-img {
    background-color: $bg-pink;
    color: $level-1;
    border-radius: 8px;
  }
}

.video-card {
  .img-parent,
  .thumbnail-image,
  .cd-list-img {
    background-color: $bg-purple;
    color: $level-1;
    border-radius: 8px;
  }
}
