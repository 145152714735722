.appointment-modal-image {
  height: 584px;
  overflow: hidden;
  overflow-y: auto;
  transform: translateY(-8px);

  &.appointment-xray-image {
    min-height: 150px;
    height: auto;
  }

  @media (max-width: 767px) {
    height: 540px;
    padding-right: 8px;
  }

  .modal-dropzone-area {
    border-color: transparent;
    background-image: repeating-linear-gradient(-60deg, $border-input-typed-button-2, $border-input-typed-button-2 3px, transparent 3px, transparent 5px, $border-input-typed-button-2 5px), repeating-linear-gradient(30deg, $border-input-typed-button-2, $border-input-typed-button-2 3px, transparent 3px, transparent 5px, $border-input-typed-button-2 5px), repeating-linear-gradient(120deg, $border-input-typed-button-2, $border-input-typed-button-2 3px, transparent 3px, transparent 5px, $border-input-typed-button-2 5px), repeating-linear-gradient(210deg, $border-input-typed-button-2, $border-input-typed-button-2 3px, transparent 3px, transparent 5px, $border-input-typed-button-2 5px);
    background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
  }
}

.appoint-image-row {
  margin: 0 -2px;
}

.appoint-image-col {
  padding: 0 2px;
  margin-top: 8px;
  position: relative;

  .loader-active {
    min-height: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    background: linear-gradient(to bottom, #121214, rgba(18, 18, 20, 0));
    opacity: 1;
  }

  &.landscape-col {
    .appoint-image-block {
      height: 144px;
    }
  }

  &.add-new-image-col {
    display: flex;
    align-items: center;
  }

  .add-new-image-btn {
    i {
      font-size: 1.25rem;
      color: $level-11;
    }
  }

  .img-label {
    font-size: 8px;
    line-height: 20px;
    color: #e7e7ea;
    position: absolute;
    left: 50%;
    padding: 0 8px;
    background: #5c5c66;
    transform: translateX(-50%);
    border-radius: 4px;
    top: 10px;
    min-width: 33px;
  }

  .modal-dropzone-area{
    border-color: transparent;
    cursor: pointer;
  }
}

.appoint-image-block {
  height: 264px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  .span-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0);
  }

  .loader-active {
    border: dashed 1px $cl-link;

    .span-overlay {
      background: rgba(34, 43, 53, 0.2);
    }
  }

  .appoint-img {
    height: calc(100% - 2px);
    max-width: none;
    opacity: 1;
    margin-top: 1px;

    &.opacity-1 {
      opacity: 1;
      height: auto;
      width: 100%;
    }
  }

  .appoint-img-cross {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #5c5c66;

    &.appoint-img-cross {
      z-index: 2;
    }
  }

  &.active {
    border: dashed 1px $cl-link;

    .span-overlay {
      background: rgba(34, 43, 53, 0.2);
    }
  }
}

.appointment-xray-image {
  .appoint-image-block {
    .appoint-img {
      opacity: 1;
    }
  }
}


.dropzone-drag-uploader-container {
  cursor: pointer;

  &.dropzone-drag-active {
    border: dashed 1px $cl-link;
  }
}
