.folders {
  .not-using-right-now-extra-small-card {
    > div {
      padding: 12px;
    }

    .os-sm-attachment {
      padding: 12px 56px 12px 12px;
    }

    .attachment-card-image {
      @include minmaxwidth(128px);
      @include equalAllHeight(72px);
    }

    .card-attachment-body {
      padding: 0 0 0 16px;
      font-size: 12px;
      line-height: 1.33;
      color: #e4e49e;

      .attachment-card-name {
        font-size: inherit;
        line-height: inherit;
        font-weight: 600;
      }

      .file-size {
        color: inherit;
      }
    }

    .attach-card-head {
      top: 50%;
      transform: translateY(-50%);
    }

    .card-dropdown-menu {
      right: 0;
    }
  }
}

.mobile-device {
  .folders {
    .mb-cards {
      .os-card-v2 {
        [class^="os-sm-"] {
          max-height: 56px;
          height: 56px;
          padding: 8px 12px;

          .sm-case-card-images,
          .case-card-images,
          .attachment-card-image {
            height: 40px;
            max-height: 40px;
            min-width: 72px;
            max-width: 72px;
          }

          .card-attachment-body {
            width: calc(100% - 72px);
            max-width: calc(100% - 72px);
          }
        }
      }
    }
  }
}


.big {
  .os-card-thumbnail {
    max-width: 100%;
    height: 196px;
    max-height: 196px;
    padding: 0;

    .thumbnail-image {
      height: 144px;
      max-height: 144px;
    }

    .thumbnail-title {
      text-align: left;
      padding: 8px 16px 0;
    }

    .card-attach-image {
      border-radius: 8px 8px 0 0;
    }
  }

  .doc-card,
  .case-card {
    .thumbnail-image {
      border-radius: 8px 8px 0 0;
    }
  }
}

.table-list {
  &:before {
    counter-increment: list-number;
    content: counter(list-number);
    font-size:16px;
    color:$level-11;
    position: absolute;
    margin-left: 48px;
    margin-top: 8px;

    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: 24px;
    }

    @media screen and (min-width: 768px) and (max-width: 1360px) and (orientation: landscape) {
      margin-left: 44px;
    }

    @media (max-width: 767px) {
      content: none;
    }
  }
}

.table-card-row {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 72px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: $level-1;
  margin-bottom: 0;
  border-radius: 4px;
  box-shadow: $block-shadow;
  color: $level-11;

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1360px) and (orientation: landscape) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 60px;
  }

  &.sorting-options-bar {
    background-color:transparent;
    margin-bottom: 0 !important;
    display: none;
  }

  &.selected {
    background-color: $block;
  }

  .folder-table-block,
  .sorting-option {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    padding: 0;
    @include fontstyle(16px, normal, 24px);

    &.name-block {
      min-width: 35%;
      max-width: 35%;
    }

    &.selected {
      color: #dddde0;

      .carespace-name {
        color: $level-11;
      }
    }
  }

  .sorting-option {
    @include fontstyle(12px, normal, 18px);

     &.name-block {
      padding-left: 26px;
    }

    &.cd-date-added {
      padding-left: 4px;
    }
  }

  .cd-list-img {
    width: 72px;
    height: 40px;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    margin-right: 16px;
    margin-left: 12px;

    @media screen and (min-width: 768px) and (max-width: 992px) {
      margin-right: 8px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .attach-custom-file-icons {
      .attachments-content-holder {
        width: 16px;
      }

      .fs-40 {
        font-size: 16px;
      }

      .icon {
        font-size: 24px;
        margin-left: 5px;
        margin-top: 4px;
        display: block;
      }
      .conversation-attachment-ext-name {
        top: 11px;
        left: 10px;
        @include fontstyle(8px, 600, 10px);
        height: 14px;
      }
    }
  }

  .cd-list-info {
    width: 66%;

    @media screen and (min-width: 768px) and (max-width: 992px) {
      padding-right: 8px;
    }
  }

  .cd-last-used {
    width: 24%;
    flex-direction: column;
    align-items: flex-start !important;

    .cd-file-name {
      @include text-truncate();
      display: block;
    }
  }

  .cd-extra-info {
    display: block;
  }

  .cd-extra-info,
  .carespace-name {
    color: $level-9;
    @include fontstyle(12px, normal, 16px);
  }

  .cd-date-added {
    width: 11.6%;
    margin-right: 20px;
  }

  .file-type {
    width: 11.6%;
    margin-right: 20px;
    padding-left: 0;
  }

  .cd-list-select {
    min-width: 100px;
    justify-content: center;

    .os-btn-link {
      color: #6c6c6f;
      font-size: 24px;
      cursor: pointer;
      text-decoration: none;
      margin-top: 8px;

      &:hover, &.active {
        color: $os-link;
      }
    }
  }

  .selectable-item {
    &.osbtn-icon {
      border: 0 !important;
      .btn-icon-helper {
        left: 10px;
        font-size: 20px;
      }
    }
  }
}

.ipad {
  .table-card-row {
    .file-type {
      padding-left: 8px;
    }
  }
}

.mobile-device {
  .table-card-row {
    &.sorting-options-bar {
      display: none;
    }

    padding-left: 0;
    margin-bottom: 4px;

    .cd-date-added,
    .file-type,
    .cd-last-used {
      display: none;
    }

    .name-block:not(.sorting-option){
      min-width: calc(100% - 54px);
    }

    .cd-file-name {
      font-weight: 600;
      @include text-truncate();
      display: block;
    }
  }
}

.desktop-small:not(.desktop-md) {
  .files {
    .cards {
      .col-md-4 {
        &.mw-400 {
          flex: 0 0 auto;
          width: 50%;
          max-width: 400px;
        }
      }
    }
  }
}
