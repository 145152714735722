.configuration-view {
  .os-menu-drop  {
    padding: 0;
    border: 0;
  }
  .os__menu {
    left: 0;
  }

  .os__menu-list {
    .os__option {
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 13px 12px;
    }
  }

  .carespace-section {
    background: $block;
    box-shadow: $block-shadow;
    border-radius: 10px;
    padding: 15px 32px 11px;
    margin-bottom: 30px;
    max-width: 624px;
  }

  .basic-info-section {
    background: $block;
    box-shadow: $block-shadow;
    border-radius: 10px;
    padding: 15px 32px 11px;
    margin-bottom: 30px;
  }
  .notify-with-label {
    .form-group {
      background: none;
    }
  }
}
