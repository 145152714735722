.containerClass {
  margin: 0;
}

.leftbar-mobile-container {
  height: 100%;
}

.left-ac-mobile {
  height: 100vh;
}

.leftbar {
  background-color: $level-1;
  height: calc(100% - 60px);
  overflow-y: hidden;
  &:hover {
    overflow-y: auto;
  }
  .ac-seperator {
    display: flex;
    padding: 16px 16px 0px 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background-color: #eff1f4;
    margin-top: 4px;
    .accordion-button {
      color: var(--neutrals-level-7, #818D99);
      font-family: Inter;
      font-weight: 600;
      line-height: 24px;
      flex: 1 0 0;
      cursor: default;
      padding: 16px;
      padding-bottom: 8px;
      .ac-heading {
        font-size: 16px;
      }
    }
  }
  .accordion-button {
    &:hover {
      background-color: #EDEFF3;
      .btnContainer {
        display: flex !important;
      }
    }
  }
}

.left-search-wrap {
  padding: 16px;
}

.ac-heading {
  @include fontstyle(0.75rem, $font-weight-semibold, 0.75rem);
  // width: 175px;

  @media screen and (min-width: 1920px) {
    @include fontstyle(0.75rem, $font-weight-semibold, 1rem);
  }
}

.partners {
  .ac-heading {
    @include fontstyle(1rem, $font-weight-semibold, 1rem);

    @media screen and (min-width: 1920px) {
      @include fontstyle(1.2rem, $font-weight-semibold, 1.75rem);
    }
  }
}

.sidebar-arrow-icon {
  color: $level-8;
  font-size: 12px;
}

.lt-count {
  margin-left: auto;
  background-color: $level-4;
  padding: 0 4px;
  line-height: 16px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $level-10;
  margin-right: 8px;
  text-align: center;
  width: 25px;
}

.ac-header {
  .accordion-button {
    padding: 12px 24px 12px 32px;

    @media screen and (min-width: 1920px) {
      padding: 12px 24px 12px 54px;
    }
  }
}

.left-ac {
  // height: calc(100% - 16px);
  display: flex;
  flex-direction: column;

  .accordion-button {
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent;
    color: $level-8;
    display: flex;
    align-items: center;

    i {
      color: $level-6;
    }
  }

  .active {
    .accordion-button {
      color: $level-9;
      background-color: $level-4;
      box-shadow: none !important;

      i {
        color: $level-9;
      }

      .lt-count {
        background-color: $level-3;
      }
      .btnContainer {
        display: flex !important;
      }
    }
  }

  .accordion-item {
    background-color: transparent;
    border: 0;
    border-top: 4px solid $level-2;
    min-height: 40px;
    /*max-height: 100%;*/
    /*overflow: hidden;*/
    &:has(.ac-first){
      border-top: 0;
    }

    &.ac-scroll {
      height: calc(100% - 40px);
    }

    .lt-bar-icon {
      display: none;
    }

    .actionable-item {
      &:hover {
        .lt-bar-icon {
          display: block;
        }
        .item-icon {
          display: none;
        }
      }
    }

    .accordion-collapse {
      max-height: calc(100% - 40px);
      overflow-y: auto;
      scrollbar-gutter: stable;
    }

    &:first-child {
      border-top: 0;
    }

    &:first-of-type,
    &:last-of-type {
      border-radius: 0 !important;

      .accordion-button {
        &.collapsed {
          border-radius: 0 !important;
        }
      }
    }
    .accordion-header {
      &.active {
        position: sticky;
        top: 0px;
        z-index: 1;
      }
      .ac-right-items {
        display: flex;
        min-width: 40px;
        align-items: center;
        .btnContainer {
          display: none;
          justify-content: flex-end;
          flex: 1;
          height: 16px;
          width: 16px;
          font-size: 16px;
        }
      }
    }
  }

  .accordion-body {
    padding: 0;

    .tasks-list{
      display: flex;
      flex-direction: column;


      .tasks-list-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 32px;
        width: 100%;
        padding-left: 76px;
        padding-right: 40px;
        cursor: pointer;
        transition: text-align 500ms ease-in;
        text-align: center;

        @media(max-width: 1919px){
          padding-left: 52px;
        }

        @media(max-width: 1440px){
          padding-right: 0;
          padding-left: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media(min-width: 1440px){
          text-align: left;
        }

        &.u-inbox {
          .lt-count {
            color: var(--surface-borders-level-1, #FFF);
            background: #F5555A;
          }
        }

        &:hover{
          background: #EDEFF3;
        }

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        .item-text{
          font-size: 12px;
          line-height: 16px;
          display: block;
          align-items: center;
          color: #7E91A5;
          flex: none;
          order: 0;
          flex-grow: 0;
          word-break: break-all;
          width: calc(100% - 18px);
        }

        .new{
          font-size: 10px;
          line-height: 16px;
          display: block;
          color: #7E91A5;
          flex: none;
          order: 0;
          flex-grow: 0;
          word-break: break-all;
          min-width: 24px;
        }

        .item-text:only-child {
          width: 100%;
        }

        .item-count{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 6px;
          gap: 10px;
          height: 12px;
          background: #EDEFF3;
          border-radius: 16px;
          font-family: Inter;
          font-size: 8px;
          font-weight: 600;
          color: #4A749A;
          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
    }
  }
}

.ac-nst-header {
  .accordion-button {
    padding: 8px 16px 8px 56px;

    .ac-heading {
      @include fontstyle(12px, $font-weight-semibold, 16px);
    }

    &:not(.collapsed) {
      background-color: transparent !important;

      .ifill-os-chevron-down {
        transform: rotate(-180deg);
      }
    }
  }
}

.left-ac-nested {
  .accordion-item {
    border-top: 0;
  }

  .accordion-body {
    padding-left: 56px;
    padding-right: 16px;
  }
}

.left-wrap {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
  padding-top: 0;
  max-height: 100vh;
  max-width: 368px;
}

.desktop-md,
.desktop-small,
.ipad {
  .pl-120 {
    padding-left: 120px !important;
    padding-top: 16px;
    padding-right: 20px;

    .discussions,
    .files {
      .xl-container.px-sm-32 {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .head-sticky {
        .sub-nav-background {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .left-wrap {
    z-index: 100;
    width: 116px;
    max-width: 116px;
    transition: all 500ms ease-in;
    transition-delay: 0.4s;
    position: fixed;
    top: 64px;

    &:before {
      background-color: rgba(53, 69, 84, 0.1);
      content: none;
      position: fixed;
      left: 0;
      right: 0;
      width: 304px;
      box-shadow: 304px 0 0 1200px rgba(53, 69, 84, 0.1);
      top: 64px;
      bottom: 0;
      opacity: 0;
      z-index: 0;
      transition: opacity 500ms ease-in;
      will-change: opacity;
      transition-delay: 0.4s;
    }

    .lt-count {
      opacity: 0;
      transition: opacity 500ms ease-in;
      transition-delay: 0.2s;
    }

    .new {
      opacity: 0;
      transition: opacity 500ms ease-in;
      transition-delay: 0.2s;
    }

    .ac-header {
      .accordion-button {
        padding-left: 44px;
        padding-right: 40px;
      }

      .ac-heading {
        opacity: 0;
        transition: opacity 500ms ease-in;
        transition-delay: 0.2s;
        white-space: nowrap;
      }
    }

    .ac-nst-header {
      .accordion-button {
        padding: 8px 16px 8px 16px;
        justify-content: center;
      }

      i {
        opacity: 0;
        display: none;
      }
    }

    .left-ac-nested {
      .accordion-body {
        padding-left: 44px;
      }
    }

    &:hover {
      width: 304px;
      max-width: 304px;


      .accordion-body {
        .tasks-list-item {
          text-align: left;
          @media(max-width: 1428px){
            padding-left: 52px;
            padding-right: 40px;
          }
        }
      }

      &:before {
        opacity: 1;
        content: '';
      }

      .lt-count {
        opacity: 1;
      }

      .new {
        opacity: 1;
      }

      .ac-header {
        .accordion-button {
          padding-left: 44px;
          padding-right: 40px;
        }

        .ac-heading {
          opacity: 1;
        }
      }

      .ac-nst-header {
        .accordion-button {
          padding: 8px 16px 8px 16px;
          justify-content: center;
        }

        i {
          opacity: 0;
          display: none;
        }
      }

      .left-ac-nested {
        .accordion-body {
          padding-left: 44px;
        }
      }

    }
  }
}

.direct-mention-notification {
	height: 8px;
	min-width: 8px;
	border: 1px solid red;
	background-color: red;
	margin-right: 16px;
	display: inline-block;
	border-radius: 50%;
	text-align: left;
}

@media screen and (min-width: 1440px) {
  .feed-head {
    padding-top: 16px;
  }
}

.ac-user-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
  /* max-height: 100%;
  height: 100%;
  overflow-y: auto;*/

  .conversation-content-block {
    @include text-truncate();
  }

  li:hover {
    background-color: $level-4;
  }

  li {
    &.unread {
      a {
        .ac-user-name {
          font-weight: $font-weight-semibold ;
        }
      }
    }

    a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: $level-9;
      position: relative;
      padding: 8px 42px 8px;
      padding-left: 80px;

      .user-avatar,
      .user-avatar-2 {
        position: absolute;
        left: 56px;
        width: 24px;
        height: 16px;
        top: 8px;
      }

      .user-avatar {
        div.avatar {
          @include avatar(16px);
        }

        img {
          width: 16px;
          height: 16px;
          font-size: 1px;
          text-indent: -999px;
        }
      }

      .user-avatar-2 {
        width: 32px;

        div.avatar,
        img {
          position: absolute;

          &:first-child {
            left: 0;
            top: 0;
          }
        }

        .avatar+.avatar {
          left: 6px
        }

        img {
          width: 16px;
          height: 16px;
          font-size: 1px;
          text-indent: -999px;
        }

        div.avatar {
          @include avatar(16px);
        }
      }

      .ac-user-name {
        flex: 1;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media screen and (min-width: 1920px) {
        padding-left: 104px;

        .user-avatar,
        .user-avatar-2 {
          left: 80px;
        }
      }
    }

    &.active {
      a {
        background: $m-user-list-active;

        .ac-user-name {
          font-weight: normal;
          //color: $level-11;
        }
      }
    }

    &.unread {
      a {
        .ac-user-name {
          font-weight: $font-weight-semibold;
          color: $level-11;
        }
      }
    }

    &.new-conversation {
      a {
        i {
          text-align: center;
          font-size: 24px;
          width: 50px;
          color: white;
          opacity: 0.2;
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    li {
      a {
        .user-avatar-2 {
          div.avatar:last-child {
            top: 10px;
            left: 15px;
          }
        }
      }
    }
  }
}

body {
  &:not(.patient){
    &:has(.task-modal){
      .tippy-box{
        background: #fff !important;
        color: #354554 !important;
        .tippy-content{
          padding: 0 !important;
          .sub-label-dropdown{
            background: #fff;
            max-height: 180px;
            height: max-content;
            width: 200px;
            overflow: scroll;
            border-radius: 8px;
            box-shadow:  0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);

            .sublabelContainer{
              padding: 0 16px;
              display: flex;
              align-items: center;
              height: 40px;
              padding: 0 16px;
              cursor: pointer;
              position: relative;

              &:hover{
                background-color: #eff1f4;
              }
            }
          }
        }
      }
    }
    &>div:last-child {
      .tippy-box[data-animation=fade][data-state=hidden] {
        opacity: 0
      }

      [data-tippy-root] {
        max-width: calc(100vw - 10px)
      }

      .tippy-box {
        position: relative;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        font-size: 12px;
        line-height: 1.2;
        white-space: normal;
        outline: 0;
        transition-property: transform, visibility, opacity
      }

      .tippy-box[data-placement^=top]>.tippy-arrow {
        bottom: 0
      }

      .tippy-box[data-placement^=top]>.tippy-arrow:before {
        bottom: -7px;
        left: 0;
        border-width: 8px 8px 0;
        border-top-color: initial;
        transform-origin: center top
      }

      .tippy-box[data-placement^=bottom]>.tippy-arrow {
        top: 0
      }

      .tippy-box[data-placement^=bottom]>.tippy-arrow:before {
        top: -7px;
        left: 0;
        border-width: 0 8px 8px;
        border-bottom-color: initial;
        transform-origin: center bottom
      }

      .tippy-box[data-placement^=left]>.tippy-arrow {
        right: 0
      }

      .tippy-box[data-placement^=left]>.tippy-arrow:before {
        border-width: 8px 0 8px 8px;
        border-left-color: initial;
        right: -7px;
        transform-origin: center left
      }

      .tippy-box[data-placement^=right]>.tippy-arrow {
        left: 0
      }

      .tippy-box[data-placement^=right]>.tippy-arrow:before {
        left: -7px;
        border-width: 8px 8px 8px 0;
        border-right-color: initial;
        transform-origin: center right
      }

      .tippy-box[data-inertia][data-state=visible] {
        transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
      }

      .tippy-arrow {
        width: 16px;
        height: 16px;
        color: #333
      }

      .tippy-arrow:before {
        content: "";
        position: absolute;
        border-color: transparent;
        border-style: solid
      }

      .tippy-content {
        position: relative;
        padding: 5px 9px;
        z-index: 1
      }
    }
  }
}
