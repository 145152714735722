.edit-page,
.detail-page {
  .spaces,
  .companies {
    .card-top-marging-none {
      .mb-4:first-child {
        margin-bottom: 0 !important;
      }
    }

    .sub-nav-background {
      .list-inline-item {
        font-size: 12px !important;
        padding-top: 0;
        text-transform: uppercase;

        .page-heading-title {
          &:after {
            top: 30px;
          }
        }
      }

      .user-list-item {
        .page-heading-title,
        .ifill-os-chevron-down {
          font-size: 16px !important;
        }
      }
    }
  }
}

.course-btn-index {
  position: relative;

  .osbtn {
    position: absolute;
    right: 0;
    top: 12px;
  }

  + .f-course-block {
    padding-top: 24px;
  }

}

.editor-custom-checkbox {
  &.space-dis {
    margin-top: 12px;
    cursor: pointer;
    display: inline-block;
  }
}

.space-detail-information-course,
.space-detail-information-guest-course {
  margin-bottom: 32px;
}

.desktop-hd,
.desktop-small,
.ipad.landscape {
  .editor-custom-checkbox {
    &.space-dis {
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
    }
  }
}


.ipad{
  .information-tab {
    min-height: calc(100% - 40px);
  }

  .board-form-content {
    .px-tab-0 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.portrait {
    .information-tab {
      padding-left: 24px;
      padding-right: 24px;
    }

    .space-detail-information-course,
    .space-detail-information-guest-course {
      margin-bottom: 56px;
    }

    .edit-board-type {
      padding-left: 27px;
      i {
        position: absolute;
        left: 0;
      }

      span {
        display: inline-block;
        margin-top: -4px;
      }
    }

    .pulse-sidebar {
      &:not(.case-detail) {
        margin-left: -24px;
        margin-right: -24px;
      }

      > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .col-6 {
          margin-bottom: 24px;

          .sidebar-container {
            margin-bottom: 0;
            height: 100%;
          }
        }
      }

      .no-live-session-message {
        svg {
          max-width: 190px;
          max-height: none;
        }
      }

      .sponsored_space {
        .col-sm-6 {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  .spaces {
    .header-title-row {
      padding-top: 20px;
    }
    .sub-nav-background {
      .list-inline-item {
        .page-heading-title {
          &:after {
            top: 27px;
          }
        }
      }
    }
  }
}

.spaces {
  .header-title-row {
    .avatar-edit {
      .user-image-edit-btn {
        top: 16px;
        left: 0;
      }
    }
  }
}

.mobile-device {
  &.edit-page {
    .spaces {
      .os-main-nav {
        display: none;
      }

      .head-sticky {
        position: sticky;
        top: 48px;
      }

      .tab-sticky {
        position: sticky;
        top: 48px;

        &:before {
          opacity: 0;
        }
      }
    }
  }

  &.edit-page,
  &.detail-page {
    .spaces {
      .sub-nav-background {
        .list-inline-item {
          margin-right: 24px !important;
        }

        .list-inline-item,
        .page-header-dropdown-menu .page-heading-title,
        .page-header-dropdown-menu .ifill-os-chevron-down {
          font-size: 16px !important;
        }

        .page-header-dropdown-menu .page-heading-title {
          padding-right: 24px !important
        }
      }
    }
  }
}

.space-members-group-container {
  margin-top: 24px;
  .on-demand-content-container {
    margin-top: 8px;
  }
}

.detail-board {
  .manageable-sections {
    @media(min-width: 992px) {
      .col-lg-3,
      .col-xxl-3 {
        max-width: calc(25% - 24px);
      }
    }
    @media(min-width: 1441px) {
      .col-xxl-3 {
        max-width: calc(25% - 24px);
      }
    }
  }
}

.see-more-btn {
  position: absolute;
  top: -48px;
  right: 0;
}

.spc-tab-heading {
  @include fontstyle(20px, 600, 1.4);
  color: $heading;
  margin-bottom: 8px;
  display: inline-block;
}

.year-group {
  .spc-tab-heading {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .see-more-btn {
    top: -40px;
    right: 12px;
  }

  .spc-tab-heading {
    &.pl-m-0 {
      padding-left: 0;
    }
  }

  .year-group {
    .spc-tab-heading {
      padding-left: 0;
    }

    .see-more-btn {
      right: 0;
    }
  }

  .detail-board  {
    .sticky-tab-with-dropdown {
      .cards > .col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

}


.ce-credit-btn,
.reload-complete {
  color: $level-11;
  font-size: 12px;
  margin-right: 32px;

  i {
    font-size: 12px;
  }

  + .osbtn {
      &.mgl-12,
      &.sub-space-btn {
        margin-left: 0 !important;
      }
  }
}

.reload-complete {
  margin-right: 32px;
}

.btn-with-title-bar {
  .care-space-label {
    display: inline-block !important;
    margin-left: 8px;
    border-radius: 16px;
    .cs-dropdown {
      min-width: 50px;
      .dropdown-info-title {
        height: 20px !important;
      }
      .list-unstyled {
        right: -40%;
        margin-top: 10px;
      }
      .header-dropdown-list-item {
        & > div:first-child {
          padding: 0 !important;
        }

        .lb-li-body{
          .label-list-item {
            white-space: pre-wrap;
          }
        }
      }
      .page-heading-title {
        padding: 4px 31px 2px 11px;;
        border-radius: 16px;
        .pointer {
          text-transform: capitalize;
          z-index: 1;
          color: var(--bs-gray-800) !important;
        }
      }
    }
    .badge-color {
      border-radius: 16px;
      background-color: blue;
    }
    .istoggleicon {
      right: 14px;
    }
  }
}

.mobile-device {
  .space-sub-header {
    .btn-with-title-bar {
      &.only-mobile-bar {
        display: flex !important;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;

        div:first-child {
          .span-helper {
            display: inline-block !important;
          }
        }
      }

      .osbtn-icon {
        padding-right: 14px;

        + .osbtn-icon {
          margin-right: -12px;
        }
      }
    }
  }

  .reload-complete,
  .ce-credit-btn  {
    display: inline-flex !important;
    margin-right: 0px;

    + .osbtn {
      &.mgl-12 {
        margin-right: -12px !important;
      }

      .ifill-os-like {
        font-size: 14px;
      }
    }
  }
}

.ipad {
  &.portrait {
    .pulse-sidebar {
      .loader-active {
        &.dc-loader {
          min-height: 256px;
          width: 50%;
        }
      }
    }
  }
}

.manageable-sections:not(.cards-selection-active) {
  .vertical-more {
    cursor: grab;
  }
}

.manageable-sections {
  .section-droppable {
    transform: translateY(-12px);
    height: 1px;

    &.drop-active {
      border: 1px solid $cl-link;
    }
  }
}

.mobile-device {
  .osbtn[text="Unarchive Space"] {
    width: 100%;
  }
}

.detail-page {
  .info-read-only {
    .draftPluginsEditorWrapper.draftPluginsEditorWrapper.editorFocused {
      border-color: transparent;
    }
  }
}


.preview-content {
  #stlviewer {
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .podcast-frame {
    margin-top: 100px;

    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }
  }

  .spotify-podcast {
    padding-top: 0;

    @media screen and (max-width: 767px) {
      iframe {
        max-height: 200px;
      }
    }
  }
}

.clv-row {
  #stlviewer {
    div:first-child {
      margin: 0px auto;
    }
  }
}

@media screen and (max-width: 767px) {
  .header-with-clv {
    .stlviewer, #stlviewer {
      width: 100% !important;
      height: 100% !important;

      > div {
        width: 100% !important;
        height: 100% !important;
      }

      canvas {
        width: 100% !important;
        height: auto !important;
        margin: 0px auto;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.modal-body {
  .stlviewer, #stlviewer {
    width: 100% !important;
    height: 100% !important;

    > div {
      width: 100% !important;
      height: 100% !important;
    }

    canvas {
      width: 100% !important;
      height: auto !important;
      margin: 0px auto;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: $level-3;
    }
  }

  .spotify-podcast {
    width: 300px;
    padding-top: 0;
    margin:0px auto;
  }

}
.pic-download-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  img {
    max-width: 100%;
  }

  .download-btn {
    position: absolute;
    right: 50px;
    top: 0;
    background-color: $os-link;
  }

  .close-btn-stl {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $level-11;
  }
}

.photo-btn {
  position: absolute;
  top: 50%;
  z-index: 1;
  background: rgba(18,18,18,0.3);
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    background-color: rgba(18,18,18,0.9) !important;
    color: $level-11 !important;

  }
}


.workspace {
  .board-form-content {
    .mt-4 {
      margin-top: 1.5rem;
    }
  }
}


@media screen and (min-width: 768px) {
  .space-typ-col {
    height: calc(100vh - 194px);
    position: sticky;
    top: 90px;
  }
}
