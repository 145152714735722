.mobile-device {
  .group-space-list {
    li {
      .actions {
        &.active {
          visibility: hidden;
        }
      }
      &:hover {
        .actions {
          visibility: hidden;
        }
      }
      &.active {
        .actions {
          visibility: hidden;
        }
      }
      .actions {
        display: none;
        visibility: hidden;
      }
    }
  }
}
