.confirm-profile-modal {
  .modal-content {
    width: 488px;
    @media(min-width: 768px) {
      padding: 24px 32px 40px 32px;
    }

    svg {
      max-width: 160px;
      max-height: 160px;
      margin: 1rem auto;
    }
  }

  .modal-update--profile-listing {
    color: $level-11;
    + .modal-update--profile-listing {
      padding-top: 1rem;
    }
  }

  .modal-footer {
    justify-content: center;
    padding: 0;

    > * {
      margin: 0;
    }

    .osbtn + .osbtn {
      margin-left: 24px;
    }
  }
}

