.profileSwitcherForm {
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  section {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center;
    width: 100%;
  }

  .profile-switcher-btn {
    width: 200px;
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  .imageContainer {
    display: flex;
    align-items: center;

    img {
      max-height: 520px;
      max-width: 520px;
    }
  }

  .profile-switcher-card-view {
    min-height: 600px;
    width: 440px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12),
      0 3px 6px 0 rgba(53, 69, 84, 0.15);
  }

  .profile-switcher-form {
    padding: 80px;
    padding-top: 60px;
    min-height: 600px;

    .profile-switcher-form-heading {
      margin: 0;
      font-size: 24px;
      text-align: center;
      font-weight: 900;
      line-height: 1.17;
    }

    .profile-switcher-form-user-field {
      min-height: 240px;
      width: 280px;
      margin-top: 64px;
      background: #fff;
      font-family: "Inter";

      .label {
        margin-top: 24px;
      }

      .select-user-profile {
        width: 100%;
        background-color: #f4f7f9;
        height: 48px;
        border-radius: 8px;

        .userField {
          margin-bottom: "24px";
          height: 100%;
          width: 100%;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          span {
            height: 16px;
            flex-grow: 0;
            font-family: Inter;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #5d7893;
          }

          .avatar {
            height: 32px;
            width: 32px;

            img {
              height: 32px;
              width: 32px;
              border-radius: 50%;
            }
          }
        }

        select {
          width: 95%;
          padding-left: 4px;
          background-color: #f4f7f9;
          height: 48px;
          border-radius: 8px;
          border: none;
          outline: none;
        }

        .users {
          .avatar {
            border-radius: 50% !important;
            height: 32px;
            width: 32px;

            img {
              border-radius: 50% !important;
              height: 32px;
              width: 32px;
            }
          }

          input {
            height: 38px;
            min-width: max-content !important;
          }

          .os__indicators::after {
            top: 7px;
          }
        }
      }

      .password-block {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 16px;

        .form-error {
          position: absolute;
          width: 200px;
          top: 55px;

          i {
            padding-right: 0px !important;
            margin-left: 0 !important;
          }
        }
      }

      .password-verification {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 16px;

        div {
          width: 100%;

          input {
            padding-left: 20px;
          }
        }
      }

      .form-error {
        position: absolute;
        width: 200px;
        top: 50px;

        i {
          padding-right: 0px !important;
          margin-left: 0 !important;
        }
      }
    }

    .profileSwitcherPrimaryBtn {
      margin-top: 50px;
      text-align: center;

      input {
        background-color: #4A749A !important;
      }
    }

    label {
      display: block;
    }


    input {
      width: 100%;
      background-color: #f4f7f9;
      border: none;
      outline: none;
      padding-left: 4px;
      height: 48px;
      border-radius: 8px;
    }

    input:focus {
      border: 1px solid #4a749a;
    }

  }
}

@media screen and (max-width: 1024px) {

  .profileSwitcherForm {
    .section {
      justify-content: center;
    }
  }

  .imageContainer {
    display: none;
  }
}