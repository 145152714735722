$block-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12),
  0 3px 6px 0 rgba(53, 69, 84, 0.15);

.custom-time-block-wrapper {
  border-radius: 8px;
  background: #fff;
  box-shadow: $block-shadow;
  position: absolute;
  top: 75px;
  width: 100%;
  margin-top: 8px;

  .time-block {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .time-block-container {
      min-width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;

      i {
        cursor: pointer;
      }

      .hour-block,
      .minute-block,
      .shift-block {
        min-width: 20px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #354554;
      }
    }
  }
  .time-error {
    text-align: center;
    color: red;
    width: 350px;
  }
}
