.information-tab {
  #course-skill-wrapper {
    ul {
      list-style: none !important;
      padding: 0 !important;
      margin: 0 -3px !important;
    }

    li {
      font-weight: 300;
      padding: 0 16px;
      line-height: 40px;
      display: inline-block;
      border-radius: 4px;
      color: #fff;
      margin: 0 4px 10px;
      vertical-align: top;
    }
  }
}
