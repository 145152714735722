@import 'component/activity-user-connect';
@import 'component/activity-listing';
@import 'component/os-card';

.activity-container {
  padding-top: 1.5rem;
  align-items: flex-start;
}

.sticky-tab-with-dropdown,
.activity-container {
  .loader-active {
    height: 158px;
    min-height: unset;

    .loader-inner {
      margin-top: 0;
    }
  }
}

.heading-title {
  color: $heading;
  @include fontstyle(16px, $font-weight-bold, 1);
}


.sticky-tab-with-dropdown {
  .activity-container {
    padding-top: 0;
  }

  .collection-page {
    padding-top: 0;
  }
}
