.communication {
  &.conversations-page {
    padding-top: 32px;
    .communication__container {
      width: 100%;
      max-width: none;
    }

    .page-header {
      height: 40px;
      width: 25%;
      padding-left: 36px;
      position: absolute;
      top:0;

      .message-page-header {
        @extend %heading-4-1;
        color: $level-8;
      }

      .ifill-os-info {
        font-size: 15px;
      }
    }

    .communication__new-message {
      position: relative;
      right: 30px;
      bottom: auto;
      i {
        margin-left: 0px !important;
        margin-right: 10px;
      }
    }

    .conversation-content-container {
      width: 100%;
      max-width: none;
      height: 100%;
      padding: 0;
    }

    .communication__height-100 {
      height: 100% !important;
      min-height: auto;
      margin: 0;
      padding-top: 0;
      display: flex !important;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .message-user-list-section {
      .conversations-list {
        padding-bottom: 0;
        position: relative;
      }
    }

    .conversation-left-panel {
      width: 25%;
      padding: 100px 0 0 0;
      position: relative;

      @media screen and (max-width: 767px) {
        padding-top: 56px;
      }
    }

    .message-user-list-section {
      height: 100%;
      padding-left: 0;
      background-color: $block;

      @media screen and (min-width: 768px) {
        margin-left: 48px;
        width: calc(100% - 48px);
      }
    }

    .search {
      height: 42px;
      padding-left: 48px;
      border-top: 0;
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 1;
      border-bottom: 0;
      top: 56px;

      @media screen and  (max-width: 767px) {
        top: 12px;
      }

      .ifill-os-search {
        top: 15px;
        left: 62px;
        font-size: 14px;
      }

      .ifill-os-clear {
        top: 15px;
        right: 12px;
        left: auto;
        font-size: 14px;
        color: $level-8;
      }

      .form-group {
        background-color: $search-bg;
        border: 1px solid $input-border;
        border-radius: 4px 4px 0 0;
      }

      input {
        height: 42px;
        padding-left: 35px;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;

        &:focus, &:active {
          border-color: $input-border-active;
        }
      }
    }

    .no-result-left-msg{
      position: absolute;
      left: 0;
      padding: 50px 0 0 40px;
      z-index: 1;
      width: 25%;
    }

    .conversations-list {
      li {
        a {
          height: 104px;
          padding: 0 10px 0 60px;
          display: block;
          cursor: pointer;


          .conversation-avatar,
          .conversation-avatar-2 {
            width: 38px;
            top: 16px;

            .oval {
              display: none;
            }

            div.avatar {
              @include avatar(40px);
            }

            div.avatar {
              &+ div.avatar {
                top: 10px;
                left: 10px;
              }
            }
          }

          .conversation-avatar-2 {
            div.avatar {
              @include avatar(30px);
            }

            img {
              &+ div.avatar {
                top: 10px;
                left: 10px;
                z-index: 1;
              }
            }
          }

          .conversation-content-top-row {
            line-height: 1.2;
            margin-bottom: 12px;
          }

          .conversation-content-block {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }

          .conversation-name {
            @extend %heading-4-1;
            max-width: calc(100% - 66px);
            margin-right: 66px;
            display: inline-block;

            &.group-name {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
              margin-bottom: 7px;
            }

          }

          .lateset-message-date {
            max-width: 60px;
            position: absolute;
            top: 0;
            right: 8px;
            word-wrap: nowrap;
            @extend %caption-default;
            color: $level-9;
          }

          .lateset-message {
            height: auto;
            max-height: 45px;
            margin-top: 0;
            @extend %body-small-1-2;

            svg {
              height: 20px;
              width: 20px;
              vertical-align: top;

              @media screen and (max-width: 767px) {
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }
    }

    .conversation-right-panel {
      width: 75%;
      position: relative;
      display: flex;

      .new-conversation {
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    .conversation-center-panel {
      width: 100%;
      position: relative;
      display: flex;

      .new-conversation {
        overflow-x: hidden;
        overflow-y: auto;
      }

    }

    .conversation {
      &.conversation-chat-section {
        max-width: 100%;
        position: relative;
        display: flex;
        flex: 1 1 0%;
        flex-direction: row-reverse;
        border-top: 0;
      }
    }

    .conversation-messages {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .assign-overlay {
        transition: initial;
      }

      .card-user-info-row.start-with-avatar {
        .card__status {
          display: unset;
        }
      }
    }

    .user-details-block {
      border-bottom: solid 1px $border-input-typed-button-2;
      padding: 0 0 4px 0;
      margin-left: 24px;
      margin-right: 24px;

      .user-avatar-block {
        margin: 0px;
        height: 40px;
      }

      .user-details-multi-user {
        .user-avatar-block {
          min-height: 40px;
        }
      }

      .user-details-single-user,
      .user-details-multi-user {
        display: flex;
        flex-direction: row;
        align-items: center;

        .user-avatar-block {
          div.avatar {
            @include avatar(40px);
            text-align: center;
            padding-right: 0;
          }
        }

        .conversation-avatar-2 {
          width: auto;
          position: relative;
          top: 4px;

          img {
            &:last-child {
              position: absolute;
              width: 32px;
              height: 32px;
            }

            &+ div.avatar {
              position: absolute;
              @include avatar(32px);
              top: 0px;
            }
          }

          div.avatar {
            top: 0px;
            &+ div.avatar {
              position: absolute;
              @include avatar(32px);
              left: 24px;
              top: 0px;
              z-index: 1;
            }
          }
        }
      }

      .user-details {
        padding-left: 10px;
      }

      .user-name {
        font-size: 16px;
        font-weight: $font-weight-normal;

        .os-btn-link, .a-link{
          color: $level-11;

          &:hover {
            color: $level-10 !important;
          }
        }

        .a-link {
          font-size: 16px;
        }
      }

      .user-details-multi-user {
        .user-details {
          padding-left: 8px;
          .user-name {
            cursor: default;
          }
        }
      }

      .user-degree {
        font-size: 12px;
        display: none;
      }

      .user-clinic {
        font-size: 12px;
        display: none;
      }
    }

    .user-selection-row {
      width: 75%;

      @media screen and (min-width: 1024px) and (orientation: landscape) {
        width: 100%;
      }

      .new-conversation-row {
        width: 100%;
        display: flex;

        .new-conversation-label {
          width: 41px;
          padding-top: 7px;
        }

        .new-conversation-input {
          width: calc(100% - 80px);

          @media screen and (min-width: 1024px) and (orientation: landscape) {
            width: 100%;
          }

          .os__menu {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
                        rgba(0, 0, 0, 0.1) 0px 4px 11px;
            z-index: 3;

          }

          .Select-clear-zone {
            display: none;
          }

          .Select-arrow-zone {
            width: 35px;
          }

          .Select-arrow::before {
            left: -15px !important;
          }
        }

        .Select-input > input {
          margin: 4px 0 0;
          padding: 4px 0;
          color: $level-11;
          &:focus {
            caret-color: $os-link;
          }
        }
      }

      .is-open {
        &> .Select-control {
          .Select-arrow {
            top: 0;
          }
        }
        /*.Select-placeholder {
          border:1px solid  $cl-link;
          border-radius: 4px;
        }*/
      }

      .is-open,
      .is-focused {
        .Select-control {
          border-color: $os-link;
        }
      }

      .Select-control {
        border: solid 1px transparent;
        padding-bottom: 5px;

        .Select-placeholder {
          line-height: 40px;
        }

        .Select-arrow {
          border: none;

          &::before {
            content: '+';
            width: 30px;
            height: 30px;
            display: inline-block;
            position: relative;
            top: 1px;
            left: -20px;
            font-size: 18px;
            font-weight: $font-weight-bold;
            text-align: center;
            line-height: 28px;
            border-radius: 30px;
          }
        }
      }
    }

    .messenger-input-container {
      position: relative;
    }

    .conversation-input-section {
      position: relative;
      margin-top: 4px;
      background-color: $block;
      padding:24px 88px 24px 88px;


      &.new-conversation-block {
        width: 100%;
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        padding:24px 48px 24px 88px;

        .media-actions,
        .conversation-options-block {
          height: 100%;
        }
      }
    }


    .conversation-options-row {
      bottom: 0px;
      position: absolute;
      right: 30px;
      .chat-content-options-holder {
        width: 100%;
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      .chat-content-options {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
    }

    .conversation-input-holder {
      position: relative;

      .form-group,
      .media-body {
        margin: 0;
      }

      .media-body {
        padding: 0;
        border: 0 !important;
        border-radius: 8px;
        max-width: 100%;

        .conversation-input-row {
          position: relative;

          ::-webkit-input-placeholder {
            color: $level-9;
          }
          ::-moz-placeholder {
            color: $level-9;
          }
          :-ms-input-placeholder {
            color: $level-9;
          }
          :-moz-placeholder {
            color: $level-9;
          }
        }

        .conversation-input-button{
          border: none;
          width: 48px;
          height: calc(100% - 2px);
          display: inline-block;
          cursor: pointer;
          font-size: 24px;
          padding: 0;
          font-weight: normal;
          margin-bottom: 1px;


          &:disabled,
          &.disabled {
            cursor: not-allowed;
          }
        }

        textarea {
          padding: 7px 160px 7px 16px;
          overflow-y: auto;

          @media(min-width: 767px) {
            padding: 9px 130px 9px 16px;
          }
        }
        .mention-textarea-wrap__highlighter {
          padding: 7px 160px 7px 16px;

          @media(min-width: 767px) {
            padding: 9px 130px 9px 16px;
          }
        }
      }
    }

    .conversation-attachment-row {
      width: 100%;
      margin: 0;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    .conversation-attachment-file-block {
      width: auto;
      min-width: 100%;
      padding: 10px;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;

      .conversation-attachment-files-count {
        width: 100%;
        margin-bottom: 10px;
        padding-left: 10px;
        font-size: 13px;
        font-weight: $font-weight-bold;
      }

      .conversation-attachment-file-holder {
        margin-right: 10px;
        padding: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        background: $level-1;
        border-radius: 5px;

        &.attached-card {
          width: 300px;
          padding: 0;
        }

        &.attached-file {
          width: 200px;
          padding: 15px 15px 5px;

          .conversation-attachment-file-content {
            height: 100%;
            display: flex;

            .icon {
              font-size: 38px;
            }
          }

          .conversation-attachment-icon-holder {
            width: 100%;
            height: 55%;
            position: relative;
          }

          .conversation-attachement-icon {
            background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
            width: 38px;
            height: 48px;
            display: inline-block;
            background-size: 38px auto;
          }

          .conversation-attachment-ext-name {
            width: auto;
            max-width: 100px;
            height: 19px;
            border: solid 2px #DDD;
            padding: 0 7px 0 3px;
            overflow: hidden;
            position: absolute;
            top: 22px;
            left: 7px;
            color: #DDD;
            font-size: 13px;
            font-weight: $font-weight-bold;
            line-height: 16px;
            text-align: left;
            border-radius: 4px;
          }

          .uploaded-file {
            width: 100%;
            height: 45%;
            top: 0;
            color: #DDD !important;
            font-size: 11px;
            font-weight: $font-weight-bold;
            line-height: 13px;
            text-align: left;
            white-space: normal;
            overflow-wrap: break-word;
            word-break: break-all;
            word-wrap: break-word;
            word-break: break-word;
            transform: translateY(0);
          }
        }

        .uploaded-file {
          width: 100%;
          margin: 0;
          padding: 0;
          position: relative;
          top: 50%;
          text-align: center;
          background: transparent;
          transform: translateY(-50%);
        }

        .ifill-os-clear {
          position: absolute;
          z-index: 11;
          text-align: center;
          cursor: pointer;
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          position: absolute;
          z-index:4;
        }
      }
    }

    .conversation-options-block {
      height: 100%;
      display: flex;
      align-content: center;
    }

    .chat-content-block {
      max-width: 400px;
      min-width: 300px;
      flex: 0 2 33.33%;
    }

    .header-dropdown-list {
      margin-top: 0;
    }

    .chat-content-options {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    .chat-content-options-holder {
      width: 20%;
    }

    .chat-content-options-link {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        line-height: 12px;

        &.icon-comparison {
          margin-top: 3px;
        }
      }

      img {
        max-width: 16px;
        max-height: 16px;
        opacity: .6;
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      .conversation-header-mobile {
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;

        .ifill-os-messages {
          margin-right: 12px;
          position: relative;
          top: 6px;
          font-size: 20px;
          margin-left: 12px;
        }

        .communication__new-message {
          position: relative;
          top: 2px;
          z-index: 1;
        }
      }

      .conversation-content-container {
        height: calc(100% - 64px);
      }

      .conversation-left-panel {
        width: 100%;
      }

      .message-user-list-section {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        padding-left: 0;

        .conversations-list {
          height: calc(100% - 42px) !important;
          overflow: auto;

          // Note: Element no longer exists
          .blank-div {
            width: 100%;
            height: 110px;
            display: block;
          }
        }
      }

      .left-panel-hidden {
       display: none;
      }

      .search {
        width: 100%;
        padding-left: 0;

        input {
          font-size: 13px;
          padding-left: 45px;
        }

        i {
          top: 12px;
          &.ifill-os-search {
            left: 18px;
            top: 21px !important;
          }

          &.ifill-os-clear {
            top: 21px !important;
          }
        }
      }

      .conversations-list {
        li {
          &.active {
            a {
              background: transparent;
            }
          }

          a {
            height: 70px;
            padding-right: 60px;

            .conversation-avatar {
              width: 40px;
              top: 15px;

              img {
                width: 40px;
                height: 40px;
              }
            }

            .conversation-name {
              font-size: 14px;
              font-weight: $font-weight-bold;
            }

            .lateset-message {
              max-height: 24px;
              margin-top: 5px;
              font-size: 10px;
              line-height: 12px;
            }

            .conversation-arrow-icon {
              margin-top: -5px;
              position: absolute;
              top: 50%;
              right: 20px;
              font-size: 10px;
            }
          }
        }
      }

      .conversation-right-panel {
        width: 100%;
        border: none;
        background: $level-3;

        .new-conversation {
          padding: 20px 0 0;
        }
      }

      .user-details-block {
        width: 100%;
        padding: 12px 20px 8px 55px;
        background: $level-1;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        margin-left: 0;
        margin-right: 0;

        .user-details-back-link {
          width: 55px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;

          i {
            margin-top: -5px;
            position: absolute;
            top: 50%;
          }
        }

        .user-details-single-user {
          .user-avatar-block {
            height: 40px;

            img {
              width: 40px;
              height: 40px;
              text-indent: -9999px;
            }

            div {
              &.avatar {
                @include avatar(40px);
              }
            }
          }
          .user-details {
            padding-left: 20px;
          }
        }

        .user-details-multi-user {
          .user-avatar-block {
            .conversation-avatar {
              img {
                width: 40px;
                height: 40px;
                text-indent: -9999px;
              }

              div {
                &.avatar {
                  @include avatar(40px);
                }
              }
            }
          }

          .conversation-avatar-2 {
            img {
              &.avatar {
                @include avatar(40px);

                &:first-child {
                  position: relative;
                  top: -5px;
                }

                &:last-child {
                  top: 5px;
                  left: 20px;
                }
              }

              &+ div.avatar {
                @include avatar(40px);
                top: 5px;
                left: 25px;
              }
            }

            div {
              &.avatar {
                @include avatar(40px);

                &:first-child {
                  position: relative;
                  top: -5px;
                }

                &+ div {
                  &.avatar {
                    @include avatar(40px);
                    top: -5px;
                    left: 24px;
                    z-index: 1;
                  }
                }

                &+ img {
                  &.avatar {
                    @include avatar(40px);
                  }
                }
              }
            }
          }
          .user-details {
            padding-left: 10px;
          }
        }


        .user-name {
          font-size: 16px;
          font-weight: $font-weight-bold;
          line-height: 18px;
          margin-left: 8px;
        }

        .user-clinic {
          display: none;
        }
      }

      .list-messages {
        .blank-div {
          width: 100%;
          height: 70px;
          display: block;
        }
      }

      .user-selection-row {
        width: 100%;
        padding: 0 15px;

        .Select-control {
          padding-bottom: 0;

          .Select-multi-value-wrapper {
            max-height: 146px;
            overflow-y: auto;
          }

          .Select-placeholder {
            line-height: 46px;
          }

          /* .Select-multi-value-wrapper {
            max-height: 148px;
            overflow-y: auto;
          } */

          .Select-arrow {
            &::before {
              top: -1px;
            }
          }
        }
      }

      .hide {
        display: none;
      }

      .conversation-options-block {
        position: relative;

        .chat-content-options-holder {
          width: auto;
        }
        .conversation-input-button{
          width: 70px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 9px;
          line-height: 21px;
          border-radius: 4px;

          &.disabled {
            opacity: .5;
          }
        }
      }

      .communication__new-message {
        right: 10px;
      }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      .user-selection-row {
        .new-conversation-row {
          .new-conversation-label {
            width: 30px;
            padding-left:0;
          }

          .new-conversation-input {
            width: calc(100% - 30px);
          }
        }
      }

      .conversation-input-section {
        .conversation-options-row {
          padding-left: 0;
        }
      }
      .communication__new-message {
        position: relative;
        right: 10px;
        bottom: auto;
      }
    }

  }
}

.chat-action-group {
  position: absolute;
  right: 0;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  align-items: flex-end;
  height: 100%;

  .osbtn {
    min-height: 40px;
    min-width: 40px;
    margin-bottom: 4px;
    padding: 0;

    + .osbtn {
      margin-left: 4px;
    }
  }

  .conversation-input-button {
    height: 100%;
    min-height: 40px;
    min-width: 48px;
    margin-bottom: 1px;
  }
}

.new-conversation-input {
  .icon-plus-search {
    width: 30px;
    height: 30px;
    border: solid 1px $border-active;
    display: inline-block;
    position: relative;
    text-align: center;
    border-radius: 30px;
    line-height: normal;

    &::before {
      color: $level-11;
      font-size: 18px;
      font-weight: $font-weight-bold;
      line-height: 22px;
    }
  }

  .css-na2q2k-menu {
    max-height: 330px;
    border: 0!important;
    margin-top: 0;

    .css-4ljt47-MenuList {
      max-height: 328px;

      > div {
        &:hover {
          color: $os-link;
        }
      }
    }
  }

  .os__control {
    .avatar {
      display: none !important;
    }
  }

  .os-menu-drop .os__option > div .avatar {
    border-radius: 50% !important;

    img {
      border-radius: 50% !important;
    }

    .icon-span {
      display: block;
      line-height: 12px;
      font-weight: 600;

      i {
        padding-top: 0;
      }
    }
  }
}


.communication-unread-count{
  color: #4eaefa;
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
}

.conversations-list {
  list-style: none;

  li {

    &:first-child{
      a{
        padding-top: 3px;
        .conversation-avatar, .conversation-avatar-2{
          top: 4 !important;
        }
      }
    }

    &.unread{
      a{
        .lateset-message{
          font-weight: $font-weight-semibold !important;
        }
      }

      .lateset-message-date {
        font-weight: $font-weight-semibold !important;
      }
    }

    a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: $level-11;
      position: relative;
      padding: 13px 10px 21px;
      padding-left: 65px;
      border-bottom: 1px solid $message-divider;

      .conversation-avatar, .conversation-avatar-2{
        position: absolute;
        left: 16px;
        width: 45px;
        top: 15px;
      }
      .conversation-avatar{
        div.avatar {
          @include avatar(40px);
        }

        img{
          width: 40px;
          height: 40px;
          font-size: 1px;
          text-indent: -999px;
        }
      }

      .conversation-avatar-2 {
        div.avatar,
        img{
          position: absolute;

          &:first-child{
            left: 0;
            top: 0;
          }
        }

        img {
          width: 32px;
          height: 32px;
          font-size: 1px;
          text-indent: -999px;
        }

        div.avatar {
          @include avatar(32px);
        }
      }

      .conversation-name {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .lateset-message-date{
        font-size: 12px;
        line-height: 16px
      }

      .lateset-message{
        width: 100%;
        height: 38px;
        overflow: hidden;
        margin-top: 4px;
        text-overflow: ellipsis;
      }
    }

    &.active {
      a {
        background: $m-user-list-active;
      }
    }

    &.new-conversation {
      a {
        i {
          text-align: center;
          font-size: 24px;
          width: 50px;
          color: white;
          opacity: 0.2;
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    li {
      a {
        .conversation-avatar-2 {
          div.avatar:last-child {
            top: 10px;
            left: 15px;
          }
        }
      }
    }
  }
}

.conversation {
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  .media-body{
    @extend %media-body;

    .conversation-input-row {

      textarea{
        padding: 13px 16px;
        font-size: 16px;
        border: 1px solid transparent;
        color: $level-11;
        min-height: 48px;

        &::placeholder{
          color: $textarea-placeholder;
        }

        &:focus {
          border-color: $os-link;
        }
      }
    }

    .uploaded-file {
      margin: 0 20px 20px 0;
      padding: 6px 25px 6px 15px;
      display: inline-block;
      position: relative;
      vertical-align: top;
      border-radius: 5px;
      word-break: break-all;

      span {
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
      }
    }
  }
}

.conversation-messages {
  height: calc(100% - 60px);
  overflow: hidden;

  .tooltip-copy {
    &.without-arrow {
      bottom: 60px;
      z-index: 0;
    }
  }

  h2{
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
    padding: 7px 0;
  }

  .list-messages {
    @extend %os__scroll;

    height: 100%;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;

    .loader {
      &.loader-active{
        position: absolute;
        top: 108px;
        left: 0;
        width: 100%;
        height: calc(100% - 110px - 66px);
        z-index: 1;
        .loader-inner{
          margin-top: 0;
        }
      }
    }
  }
}

.conversation-message {
  width: 100%;
  position: relative;
  padding-top: 16px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;

  .conversation-message-content-row {
    // width: 680px;
    float: left;
    position: relative;
    display: flex;
    min-height: 56px;

    .conversation-message-text {
      margin-bottom: 0px;
      overflow: hidden;
      white-space: normal;
      @extend %body-small-1-5;
    }
  }

  .user-emoji-list {
    position: absolute;
    margin: 0 !important;
    top: -10px;
    left: -12px;
    min-width: 24px;
    height: 24px;

    .emoji-counter {
      position: absolute;
      color: $level-8;
      left: calc(100% + 6px);
      font-size: 12px;
      line-height: 24px;
    }

    .user-emoji-holder:only-child {
      .osbtn {
        left: 0;
      }
    }

    .osbtn {
      position: absolute;
      left: 4px;
      top: 0;
      padding: 0 !important;
      height: 24px;
      width: 24px;
      min-height: 24px;
      min-width: 24px;
      border-radius: 8px;
      line-height: 24px !important;
      min-height: 24px !important;
      font-size: 16px;


      &:not(.add-emoji-btn) {
        background: $level-5;
      }

      &:after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $level-5;
        position: absolute;
        top: 0;
        right: -3px;
      }

      &.active {
        z-index: 1;
        left: 0;
        background: $level-10 !important;
        &:after {
          background: $level-10 !important;
        }
      }
    }

    .count {
      display: none;
    }

    .user-emoji-holder {
      display: unset;
    }
    .user-emoji-holder:nth-child(2) ~ .user-emoji-holder {
      display: none;
    }
  }

  .emoji-btn-icons {
    margin-left: 4px !important;
  }

  .emoji-add-list {
    top: -10px;
    left: 100%;
    padding-top: 0;
    padding-left: 10px;

    &:before {
      left: 0;
      top: 8px;
      border-bottom-color: transparent;
      border-right-color: $level-1;
      border-width: 6px;
      margin-left: -2px;
    }
  }

  .white-link {
    color: inherit;
    text-decoration: underline;
    font-weight: $link-weight;
    &:hover {
      opacity: 0.7;
      text-decoration: underline;
      color: $level-11 !important;
    }
  }

  &.ownMessage {
    margin-left: 0;

    &:first-child {
      padding-top: 32px;
    }

    .conversation-message-box {
      &::before {
        display: none;
      }
    }

    .conversation-message-content-row {
      float: right;
      direction: rtl
    }

    .conversation-content {
      align-items: flex-end;
    }

    .emoji-bar {
      order: -1;

      .emoji-btn-icons {
        margin: 0 4px 0 0 !important;
      }
    }

    .user-emoji-list {
      right: -15px;
      left: auto;

      .osbtn {
        left: -4px;
        &:after {
          left: -3px;
        }

        &.active {
          left: 0;
        }
      }

      .emoji-counter {
        left: auto;
        right: calc(100% + 6px);
      }
    }

    .emoji-add-list {
      left: auto;
      right: 100%;
      padding-top: 0;
      padding-right: 10px;

      &:before {
        left: auto;
        right: -2px;
        top: 8px;
        border-right-color: transparent;
        border-left-color: $level-1;
        margin-left: 0;
      }
    }

    .conversation-message-attachment-row {
      .conversation-card-container {
        margin: 16px 0 20px 10px;
        display: inline-flex;

        .emoji-bar {
          direction: rtl;
          text-align: right;
        }
      }
    }

    .emoji-btns-group {
      direction: rtl !important;
      text-align: right;

      * {
        direction: ltr !important;
      }
    }


    .row.w-100 {
      justify-content: flex-end;
    }

    .conversation-date{
      right: 0px;
      direction: ltr;
      bottom: 0px;
      text-align: right;
      white-space: nowrap;
    }

    .conversation-message-content{
      padding-left: 0;
      padding-right: 59px;
      direction: initial;
      align-items: flex-end;

      * {
        direction: inherit;
      }

      .conversation-message-author{
        left: auto;
        right: -8px;
        text-align: right;
      }

      .conversation-message-box{
        background: $talk-bubble-own;
        direction: ltr;
        word-break: break-word;

        &::after{
          content: none;
          width: 8px;
          height: 8px;
          top: 8px;
          left: auto;
          right: 5px;
          position: absolute;
          font-family: 'fill-os-icon' !important;
          transform: rotate(0);
          font-size: 25px;
          color: $talk-bubble-own;
          text-shadow: 1px 0px 3px rgba(53, 69, 84, 0.12);
        }

        .conversation-message-text {
          color: $level-1 !important;
        }
        .parse-comment-text{
          a {
            color: #ffffff;
          }
        }
      }
    }
  }

  .conversation-message-content {
    width: auto;
    max-width: 680px;
    padding-left: 72px;
    position: relative;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }

  .conversation-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .slider-container{
      width: 400px;
      height: 288px;
    }

    .chat-emoji-holder {
      @media(max-width: 767px) {
        min-height: 44px;
      }

      .blank-emoji-holder {
        width: 44px;
        display: inline-block !important;
        pointer-events: none;
      }
    }
  }

  .conversation-message-author {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;

    div.avatar {
      font-size: 14px;
      line-height: 40px;
      text-indent: 0;
    }

    a {
      display: inline-block;
      max-height: 40px;
    }

    + .conversation-content {
      .conversation-message-box {
        &:after {
          content: '\e923';
        }
      }
    }
  }

  .conversation-message-box {
    max-width: 580px;
    display: inline-block;
    padding: 16px 16px;
    position: relative;
    background: $talk-bubble;
    border-radius: 12px;
    box-shadow: $talk-bubble-shadow;
    word-break: normal;
    display: inline-block;
    word-break: break-word;
    margin-bottom: 0;
    color: $level-11;

    &::after{
      content: none;
      width: 8px;
      height: 8px;
      top: 35px;
      left: 3px;
      position: absolute;
      font-family: 'fill-os-icon' !important;
      transform: rotate(180deg);
      font-size: 25px;
      color: #fff;
      text-shadow: 1px 0px 3px rgba(53, 69, 84, 0.12);
    }

    &::before {
      border-right-color: #d3d6d7;
      right: calc(100% + 1px);
    }

    ~ .conversation-message-attachment-row {
      margin-top : 10px;
    }
  }

  .conversation-message-attachment-row {
    .row {
      &.w-100 {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
      }
    }

    .conversation-card-container {
      margin: 10px 10px 10px 0;
      width: 240px;
      min-height: 96px;

      &.w-344 {
        min-width: 344px;
        width: auto;
        display: inline-flex;
        max-width: 344px;

        .card--private {
          max-width:100%;
        }

        .os-card-v2 {
          width: calc(100% - 44px);

          @media screen and (max-width: 767px) {
            width: calc(100% - 32px);
          }
        }
      }
    }

    .company-card-content {
      .company-name {
        margin-bottom: 10px;
      }

      .company-views {
        position: absolute;
        bottom: 10px;
        left: 17px;
      }
    }

  }

  .conversation-message-attachment{
    width: 150px;
    height: 150px;
    margin: 5px 10px 5px 0;
    padding: 5px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
    border-radius: 4px;
    position: relative;

    &.attachment-link {
      max-width: 100%;
      height: 40px;
      max-height: none;
      margin: 5px 0;
      padding: 7px 10px 10px;
      display: block;
      overflow: hidden;
      position: relative;
      color: #DDD;
      font-size: 11px;
      font-weight: $link-weight;
      white-space: nowrap;
      border-radius: 4px;
      text-overflow: ellipsis;

      .icon-document {
        width: 16px;
        height: 20px;
        margin-right: 8px;
        display: inline-block;
        position: relative;
        top: 5px;
        background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
        background-size: 16px auto;
      }
    }

    .conversation-message-attachment-holder {
      width: 100%;
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .ifill-os-fill-download {
      position: absolute;
      bottom: 4px;
      width: 25px;
      height: 25px;
      right: 5px;
      padding: 3px;
      border-radius: 3px;

      .ifill-os-fill-download {
        margin-left: 0;
        top: 0;
      }
    }

    img {
      max-width: 140px;
      max-height: 140px;
    }
  }

  .conversation-date{
    position: absolute;
    left: 0;
    top: 48px;
    width: 100%;
    text-align: left;
    @extend %caption-tiny;
    white-space: nowrap;

    @media screen and (max-width: 767px) {
      top: 30px;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    margin: 8px 0;
    padding-top: 0;

    .conversation-date {
      white-space: normal;
    }

    &.ownMessage {
      .conversation-message-content-row {
        padding-left: 5px;
        padding-right: 0;
      }

      .conversation-message-content {
        padding-right: 50px;

        &:after {
          top: 0;
        }

        .conversation-message-author {
          right: 15px;
        }

        .conversation-date {
          white-space: normal;
        }
      }
    }

    .conversation-message-content-row {
      width: 100%;
      padding-right: 5px;
    }

    .conversation-message-content {
      max-width: 100%;
      padding-left: 50px;

      .conversation-message-box {
        max-width: fit-content;
        padding: 8px 16px;
        font-size: 12px;

        &::after {
          top: 32px;
          margin-left: 0;
        }
      }

      .conversation-message-author {
        width: 27px !important;
        height: 27px !important;
        left: 15px;

        div {
          &.avatar {
            line-height: 24px;
          }
        }

        .avatar {
          &.avatar-40 {
            @include avatar(27px);
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .conversation-message-content-row {
      width: calc(100% - 20px);
    }
  }
}

.group-user-name {
  display: none;
}

.conversation-message-author-name {
  display: none;
}

.ownMessage {
  .conversation-message-attachment-row {
    .conversation-card-container {
      &.w-344 {
        justify-content: flex-end;
      }
    }
  }
}

.group-message {
  .group-member-message {
    .conversation-message-author {
      top: 0px;
      width: 60px;
      height: auto;
      position: absolute;
      min-width: 40px;

      a {
        display: inline-block;
        height: 40px;
        max-height: 40px;
      }

      + .conversation-content {
        .conversation-message-box {
          &:after {
            content: '\e923';
          }
        }
      }
    }
    .conversation-date {
      text-align: left;
      right: auto;
    }

    .conversation-message-content {
      flex-direction: row;
    }

    .conversation-message-author-name {
      @include fontsl(12px, 1.5);
      color: $level-9;
      margin-bottom: 10px;

      display: block;

      .os-btn-link  {
        text-decoration: none;
        color: $level-8;
        font-weight: normal;

        &:hover {
          text-decoration: underline;
          opacity: 1;
          color: $level-10;
        }
      }
    }

    .group-user-name{
      font-size: 12px;
      line-height: 18px;
      color: $level-9;
      padding-bottom: 4px;
      display: block;
    }

    .ml-32 {
      padding-left: 20px;
    }
  }

  .ownMessage {
    .conversation-message-content {
      flex-direction: column;
    }

    .conversation-message-author {
      top: 0;
    }
  }
}

.new-conversation {
  width: 100%;
  padding:0 30px 30px;

  &.communication {
    padding-top: 0;
  }

  .media-body {
    background-color: transparent;
  }

  .active {
    &.media-body {
      border: 1px solid transparent !important;
      .conversation-input-row {

        .mention-textarea-wrap__control {
          font-size: 16px;
          line-height: 24px;

          .mention-textarea-wrap__highlighter,
          .mention-textarea-wrap__input {
            padding-top: 7px;
            padding-bottom: 7px;
            border: 1px solid transparent !important;
            border-radius: 8px !important;
            background-color: $level-1 !important;

            &:focus {
              border: 1px solid $input-border-active !important;
            }
          }
        }

        textarea {
          font-size: 16px;
          min-height: 48px;
          height: 48px;
        }
      }
    }
  }

  &.new-conversation-block {
    position: relative;

    .new-conversation-row {
      width: calc(100% - 60px);
      position: absolute;
      bottom: 0;
      left: 30px;

      .form-group {
        margin-bottom: 0;
      }
    }

    .conversation-input-holder {
      padding: 0;
      background-color: #8d8d8d;
      border-radius: 4px;
      overflow: hidden;
    }

    .conversation-attachment-row {
      width: 100%;
      margin: 0;
      overflow: hidden;
    }

    .conversation-attachment-file-block {
      width: auto;
      min-width: 100%;
      padding: 10px;
      border-bottom: solid 1px $border-button-1;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;

      .conversation-attachment-files-count {
        width: 100%;
        margin-bottom: 10px;
        padding-left: 10px;
        font-size: 13px;
        font-weight: $font-weight-bold;
      }

      .conversation-attachment-file-holder {
        width: 120px;
        height: 120px;
        margin-right: 10px;
        padding: 5px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        border-radius: 5px;

        &.attached-card {
          width: 260px;
          padding: 0;
        }

        &.attached-file {
          width: 200px;
          padding: 15px 15px 5px;


          .conversation-attachment-icon-holder {
            width: 100%;
            height: 55%;
            position: relative;
          }

          .conversation-attachement-icon {
            background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
            width: 38px;
            height: 48px;
            display: inline-block;
            background-size: 38px auto;
          }

          .conversation-attachment-ext-name {
            width: auto;
            max-width: 100px;
            height: 19px;
            border: solid 2px #DDD;
            padding: 0 7px 0 3px;
            overflow: hidden;
            position: absolute;
            top: 22px;
            left: 7px;
            color: #DDD;
            font-size: 13px;
            font-weight: $font-weight-bold;
            line-height: 16px;
            text-align: left;
            border-radius: 4px;
          }

          .uploaded-file {
            width: 100%;
            height: 45%;
            top: 0;
            color: #DDD !important;
            font-size: 11px;
            font-weight: $font-weight-bold;
            line-height: 13px;
            text-align: left;
            white-space: normal;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-all;
            transform: translateY(0);
          }
        }

        .uploaded-file {
          width: 100%;
          margin: 0;
          padding: 0;
          position: relative;
          top: 50%;
          text-align: center;
          background: transparent;
          transform: translateY(-50%);
        }

        .ifill-os-clear {
          position: absolute;
          z-index: 11;
          text-align: center;
          background: rgba(0, 0, 0, .4);
          cursor: pointer;
          top: 5px;
          right: 5px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 24px;
          font-size: 12px;
        }

        img {
          max-width: 104px;
          max-height: 104px;

          &.w-100 {
            width: 100%;
            max-width: none;
            height: auto;
            max-height: 100%;
          }
        }
      }
    }

    .conversation-input-row {
      position: relative;
      margin-bottom: 1px;
    }

    textarea {
      &.new-conversation-textarea {
        width: 100%;
        height: 80px !important;
        border: none !important;
        padding: 13px 45px 13px 16px !important;
        color: $level-11;
        border-radius: 4px !important;
      }
    }

    .ifill-os-send {
      &.conversation-input-button {
        border: none;
        background: transparent ;
        width: 24px;
        height: 24px;
        display: inline-block;
        position: absolute;
        top: 28px;
        right: 18px;
        cursor: pointer;
        font-size: 24px;

        &:disabled,
        &.disabled {
          opacity: .5;
          pointer-events: none;
        }
      }
    }

    .media-actions {
      height: 100%;
    }

    .conversation-options-block {
      width: 100%;
      height: 100%;
    }

    .chat-content-options {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    padding: 0;
    .chat-content-options {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }
}

.new-message {
  display: flex;

  .form-group {
    flex: 1;
  }

  textarea {
    border: 1px solid transparent;

    &:focus{
      color: $level-11 !important;
      border-color: $os-link;
    }
  }
}

.btn {
  &.disabled, &:disabled {
    cursor: not-allowed;
  }
}

.communication__height-100 {
  width: 100%;

  .message-user-list-section {
    width: 100%;
    height: 450px;
    overflow: auto;
    position: relative;
    @extend %os__scroll;

    .conversation-search {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 1;

      + div {
        height: inherit;
        padding-top: 0px;
      }
    }
    .conversations-list {
      padding-bottom: 20px;
      overflow-x: hidden;
      background-color: $m-user-list-bg;
    }
  }
}

.ril-toolbar {
  background: transparent !important;
}

.conversation-drop-file-row {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  body {
    &.chat-detail {
      overflow: hidden;

      .layout {
        padding-bottom: 0;
        min-height: auto;
        background: $level-1;
      }

      div {
        &.collection-page {
          &.conversations-page {
            margin-top: 0;
            padding-top: 0;
            max-height: 100%;
            padding-bottom: 0;
            overflow: hidden;
          }
        }
      }

      nav {
        &.navbar {
          display: none;
        }
      }

      .communication {
        &.conversations-page {
          .conversation {
            &.conversation-chat-section {
              border-top: none;
            }
          }

          .conversation-messages {
            min-width: 100%;
            margin: 0 !important;
          }


          .conversation-content-container,
          .conversation-messages {
            /*height: 100%;*/
            max-height: 100%;

            .list-messages {
              height: 100%;
              padding-top: 70px;
              padding-bottom: 20px;
              overflow-y: auto;
              padding-left: 0;
              padding-right: 0;

              // Note: Element no longer exists
              .list-messages-holder {
                height: auto;
                min-height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                overflow: hidden;
                overflow-y: auto;
              }

              .loader {
                &.loader-active{
                  top: 108px;
                  height: 100%;
                  z-index: 1;
                  .loader-inner{
                    margin-top: 0;
                  }
                }
              }
            }
          }

          .conversation-content-container {
            height: 100%;
            margin-top: 60px !important;
          }
        }
      }
    }

    &.conversations {
      &.new-chat {
        overflow-y: hidden;
        height: 100%;
        position: fixed;
        width: 100%;
        .navbar {
          .navbar-nav {
            &.site-nav {
              display: none;
            }
          }
        }
        .communication{
          .conversations-page {
            .user-selection-row{
              position: fixed;
              top: 90px;
              .Select-control {
                .Select-placeholder {
                  line-height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.chat-attach-mobile-link {
  display: none;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
  body {
    &.conversations {
      .object-filter {
        li {
          margin-right: 20px !important;
        }
      }

      .communication {
        .search input {
          font-size: 14px;
        }
      }

      .message-user-list-section {
        .conversation-name,
        .lateset-message-date {
          font-size: 12px !important;
        }
        .conversation-name {
          padding-right: 0 !important;
        }

        .lateset-message-date {
          display: block;
          position: static !important;
          min-width: 100%;
          padding-top: 3px;
        }

        .lateset-message {
          font-size: 12px !important;
          line-height: normal  !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .conversation-avatar,
        .conversation-avatar-2 {
          margin-left: -3px;
        }
      }
    }
  }
}


body {
  &.conversations {
    #footer {
      display: none !important; /* hide on conversations - chat section only*/
    }
  }
}

.loading-font {
  height: calc(100% - 90px);
  padding-top: 50px;
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    height: auto;
  }
  @media screen and (min-device-width: 375px) and (max-device-width: 812) and (orientation: landscape) {
    height: auto;
  }

  @media screen and (min-width: 320px) and (max-width: 896px) and (orientation: landscape) {
    height: auto;
  }
}

.nomessage-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    font-size: 57px;
    margin-bottom: 16px;
  }
  p{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    &.quite-text {
      font-size: 20px;
      margin-bottom: .5rem;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    height: 100%;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    justify-content: flex-start;
    padding-bottom: 20px;
  }
  @media screen and (min-device-width: 375px) and (max-device-width: 812) and (orientation: landscape) {
    justify-content: flex-start;
    padding-bottom: 20px;
  }

  @media screen and (min-width: 320px) and (max-width: 896px) and (orientation: landscape) {
    justify-content: flex-start;
    padding-bottom: 20px;
  }
}


.ipad, .iphone{
  &.touch-support {
    ::-webkit-scrollbar {
      width: 0 !important;
      display: none !important;
    }
  }
}

.mobile-device {
  .conversations-page {
    .conversation-message {
      .conversation-message-content {
        .conversation-message-box {
          &:after {
            content: none;
          }
        }
      }
    }

    .tooltip-copy {
      &.without-arrow {
        bottom: 90px;
      }
    }

    .conversation-card-container {
      &.w-344 {
        width: 304px;
      }
    }

    .conversation-input-section {
      width: 100%;
      min-height: 72px;
      padding: 16px 15px;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;

      &.new-conversation-block {
        width: 100%;
        padding: 16px 15px;
        position: fixed;
        bottom: 0;
        left: 0;

        .media-body {
          textarea {
            border: 1px solid transparent;
            border-radius: 4px;

            &:focus {
              border-color: $os-link;
            }
          }
        }
      }
    }

    .chat-attach-mobile-link {
      width: 40px;
      height: 100%;
      color: $level-11;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      line-height: 44px;
      position: absolute;
      top: 0;
      z-index: 2;
    }

    .conversation-input-holder {
      width: 100%;
      display: inline-block;

      .media-body {
        textarea {
          width: 100%;
          min-height: 40px;
          font-size: 16px;
          color: $level-11;
        }
      }

      .conversation-attachment-file-block{
        padding-left: 10px;
      }
    }
  }

  .chat-action-group {
    .osbtn {
      min-height: 52px;
      min-width: 40px;
      margin-bottom: 0;

      &.osbtn-disabled {
        color: $level-5 !important;
      }
    }
  }
}

.mobile-device {
  .communication.conversations-page .search input {
    padding-left: 12px;
    padding-right: 12px;
  }

  .chat-detail {
    .communication__container {
      display: none;
    }
    #root {
      position: absolute;
      height: 100%;
      width: 100%;

      > div {
        height: inherit;
        max-height: 100%;
      }

      .layout {
        min-height: unset;
        height: inherit;
        max-height: inherit;
      }
    }

    .conversation-message-text {
      line-height: 20px;
    }
  }

  .communication.conversations-page {
    .search input {
      padding-left: 45px !important;
      padding-right: 12px;
    }

    .no-result-left-msg{
      padding: 50px 40px 0;
      width: 100%;
    }
  }

  &.landscape {
    .blank-div{
      height: 0 !important;
    }
  }

  @media screen and (max-width: 320px) {
    .conversations-page  {
      .conversation-card-container.w-344 {
        width: 240px;
      }
    }
  }
}

.user-conversation-avatar {
  display: inline-block;
  .avatar {
    vertical-align: middle;
    display: inline-flex;

    i {
      margin: auto;
    }
  }
}

.chat-detail {
  .os-footer {
    display: none;
  }

  .conversation-avatar-2 {
    .user-conversation-avatar {
      &:nth-child(n+2) {
        margin-left: -8px;
      }
    }
  }

  &.modal-open {
    .modal-chatgroup {
      .user-image .icon-span {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}


.conversation-card-container {
  .os-small-video {
    .edu-play-span.edu-custom-file-span {
      top: 15px !important;
      overflow: unset;
    }
  }
}

.conversation-avatar,
.user-conversation-avatar {
  .avatar-user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.ipad {
  &.portrait {
    .communication {
      &.conversations-page {
        padding-top: 32px;

        .conversation-content-container {
          height: 100%;
        }

        &.new-conversation {
          padding-top: 0;
        }

        .page-header {
          position:absolute;
          top: 0;
          right: 24px;
          min-width: 125px;
          padding-top: 0;
          height: auto;
          z-index: 1;
          justify-content: flex-end;
          padding-left: 0;

          h1 {
            display: none;
          }
        }

        .conversation-content-container {
          .search {
            width: 100%;
            z-index: 1;
            border-bottom: 0;
            transition: width 500ms ease;
            will-change: width;
            height: 40px;
            left: 0;
            padding-right: 0;
            padding-left: 24px;
            top: 0;

            .form-group {
              margin-bottom: 0;
              height: 100%;
              border: 0;
              border-radius: 4px;
            }

            i {
              left: 52px;
              pointer-events: none;
              font-size: 16px;
              top:12px;
            }

            .ifill-os-clear {
              right: 16px;
              left: auto;
            }

            .form-control {
              width: 40px;
              height: 40px;
              border-radius: 4px;
              padding-left: 16px;

              &:focus {
                width: 100%;
                padding-left: 32px;
                cursor: auto;
              }
            }

            &.input-focus-active {
              .ifill-os-search {
                left: 32px;
              }
            }

            input::-webkit-input-placeholder {
              display: none;
              opacity: 0;
              color: red;

            }
            input::-moz-placeholder {
              display: none;
              opacity: 0;
              color: red;

            }


            input:-ms-input-placeholder {
              display: none;
              opacity: 0;
              color: red;
            }

            input:-moz-placeholder {
              display: none;
              opacity: 0;
              color: red;
            }
          }
        }

        .conversation-left-panel {
          position: relative;
          width: 95px;
          transition: width 500ms ease;
          will-change: width;
          padding-top: 50px;
        }

        .message-user-list-section {
          padding-left: 0;
          margin-left: 24px;
          width: calc(100% - 24px);
        }

        .conversation-right-panel {
          width: calc(100% - 95px);
          margin-right: 24px;
        }

        .conversation-messages {
          margin-right: 0;
          width: 100%;
        }

        .conversation-content-block {
          display: none;
          max-width: calc(100% - 56px);
          margin-left: 8px;

          .lateset-message-date,
          .lateset-message,
          .oval{
            display: none;
          }
        }

        .conversation-messages {
          .list-messages {
            padding-right: 24px;
          }
        }

        .conversations-list {
          width: 100%;
          border-right: 0;
          padding-right: 0px;
          transition: width 500ms ease;
          will-change: width;

          li {
            a {
              padding: 6px  0 10px 18px;
              height: 56px;
              display: flex;
              justify-content: flex-start;

              .conversation-content-top-row {
                line-height: 1;
              }

              .conversation-avatar {
                width: 40px;
                position: relative;
                top: 0;
                left: auto;
                margin-left: 0;
                display: inline-block;

                .oval {
                  display: inline-block;
                  position: absolute;
                  left: -14px;
                  top: 16px;
                }
              }

              .conversation-avatar-2 {
                position: relative;
                margin-left: 0;
                left: 0;
                width: 48px;
                top: 0;
                display: inline-block;
                height: 100%;

                .oval {
                  display: inline-block;
                  position: absolute;
                  left: -14px;
                  top: 15px;
                }

                div.avatar {
                  @include avatar(32px);

                  + div.avatar {
                    top: 4px;
                    left: 14px;
                  }
                }

                +  .conversation-content-block {
                    max-width: calc(100% - 70px);
                }
              }
            }
          }
        }

        .conversation-input-section {
          padding: 8px 0 8px 20px ;
        }

        &.search-focussed {
          .conversation-left-panel  {
            width: 26.5%;
          }

          .conversation-right-panel {
            width: calc(73.5% - 24px);
          }

          .conversation-content-block {
            display: inline-block;
            top: 0;
            transform: none;
          }

          .search {
            width: 100% !important;
            z-index: 999;

            .form-control {
              width: 100%;
              padding-left: 40px;
            }
          }
        }
      }
    }


    .conversation-input-section:not(.new-conversation-block) {
      .conversation-input-holder {
        max-width: calc(100% - 27px);
      }
      .conversation-options-row {
        right: 20px;
      }
    }

    .conversation-message.ownMessage {
      padding-right: 0;
    }
  }

  &.landscape {
    .message-user-list-section {
      .conversations-list {
        padding-bottom: 0;
        position: relative;
      }
    }
  }

  .conversation-message.ownMessage {
    .conversation-message-content {
      padding-right: 53px;

      .conversation-message-author {
        right: 0;
      }
    }

    .emoji-wrapper {
      margin-left: -40px !important;

      &::before {
        margin-left: 37px !important;
      }
    }
  }
}

.no-touch-support,
.ipad.landscape {
  .communication.conversations-page {
    .page-header {
      padding-right: 12px;
    }
  }
}

.no-touch-support {
  .conversation-message {
    .chat-emoji-holder,
    .conversation-card-container {
      .add-emoji-btn {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      &:hover {
        .add-emoji-btn {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }
    }
  }
}

.mobile-device {
  .chat-detail, .new-chat {
    .search {
      display: none;
    }
  }
}

.ipad.landscape, .desktop-small {
  .communication.conversations-page {
    .page-header {
      padding-right: 12px;
      padding-left: 14px;
    }

    .search {
      padding-left: 24px;

      .ifill-os-search {
        left: 38px;
      }
    }
    .message-user-list-section {
      margin-left : 24px;
      width: calc(100% - 24px);
    }
  }
}
