.spaces,
.boards {
  &.modal-open {
    padding-bottom: 0;
    #root {
      min-height: calc(100vh + 140px);
      > div {
        min-height: inherit;
      }
    }

    .modal-edit-board {
      padding-bottom: 140px;
    }
  }
}

.modal-open {
  #footer {
    opacity: 0;
  }
}


.space-warning-modal {
  .modal-content {
    padding: 24px 32px 40px;

    @media screen and (min-width: 576px) {
      width: 480px;

      .modal-title {
        line-height: 28px;
      }
    }

    font-weight: 300;
  }


  svg {
    height: 160px;
  }

  .modal-info-helper-text {
    padding-top: 8px;
    padding-bottom: 24px;
  }

  .os-header-btn {
    margin-right: -16px;
  }

  .os-checkbox-block {
    padding-top: 16px;
  }

  .os-checkbox-label {
    font-size: 16px;
    line-height: 24px;
    padding-left: 32px;

    .ifill-os-checkmark-default-white {
      color: #5c5c66;
    }

    .ifill-os-checkmark-default-white,
    .ifill-os-checkmark-checked {
      font-size: 24px;
      top: calc(50% - 12px);
    }
  }

  .footer-btn-center {
    width: 100%;
    text-align: center;
  }

  .footer-btn-center-course {
    width: 100%;
    text-align: center;

    .osbtn + .osbtn {
      margin-left: 0px !important;
      margin-top: 16px;
    }
  }

  .modal-space-type-list {
    list-style-type: none;
    margin: 0 0 20px;
    padding: 0;
    text-align: center;
    line-height: 1;

    li {
      margin: 0 6px;
      position: relative;
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      cursor: pointer;
      border: solid 2px transparent;
      transition: border 400ms ease;
      vertical-align: middle;

      &::after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        top: calc(50% - 4px);
        left: calc(50% - 4px);
        background: $level-1;
        transition: background 350ms ease;
      }

      &.active {
        border-color: $os-link;
        cursor: default;

        &::after {
          background: $cl-link;
        }
      }
    }
  }

  &.reshare_with_patient_confirmation,  &.copy_patient_link {
    .os-header-btn {
      position: relative;
      top: 0;
    }
    .modal-info-helper-text {
      padding-bottom: 8px;
    }

    .link-url-copy {
      margin-top: 0;
    }

    .loader-active {
      height: 380px;

      .loader-inner {
        margin-top: 0;
      }
    }

    .tooltip-copy {
      top: auto;
    }

    .link-url-copy {
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        i {
          top: 8px;
        }
      }
    }
  }
}

.shared_with_patient_confirmation_with_list {
  .modal-header {
    .os-header-btn {
      top: -8px;
    }
  }
  .modal-info-helper-text {
    padding-top: 0;
    padding-bottom: 16px;
  }

  .os-custom-checkbox {
    font-size: 16px;
    line-height: 1.5;
    color: $level-11;
    padding-left: 37px;

    .check-icon {
      font-size: 22px;
    }
  }

  .modal-footer {
    padding: 8px 0 0 0 !important;
  }
}

.mobile-device {
  .space-warning-modal {

    .os-header-btn {
      margin-right: 0;
    }

    .modal-content {
      padding: 40px 24px 20px !important;

      .os-checkbox-block {
        padding-top: 24px;
      }

      .os-checkbox-label {
        left: -8px;
      }

      .svg-icon {
        max-height: 172px;
      }
    }

    .footer-btn-center {
      .osbtn {
        line-height:48px;
        border-radius: 8px;
      }
    }

  }
}
