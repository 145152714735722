@import '../component/folder/header';
@import '../component/folder/card';
@import '../component/folder/columnspace';

.folders {
  .spaces-list {
    @media(min-width: 768px) {
      padding-top: 40px;
    }

    .space-v2-card {
      .space-user {
        margin-top: 4px;
      }
    }
  }

  .space-input-field {
    .input-close-btn {
      height: 36px;
      min-height: 36px;
      top: 1px;
      right: 1px;

      @media screen and (max-width: 767px) {
        right: 16px;
      }
    }
  }

  .space-links-container {
    min-height: unset;

    .cards{
      .col-xxl-4{
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-lg-3{
        max-width: 20%;
      }
    }
  }

  .folder-cards-listing {
    padding-top: 24px;
  }

  .filter-text-help {
    color: $level-12;
    opacity: 0.9;
    margin-right: 8px;
  }

  .space-header-right {
    min-width: 80px;

    .cs-right-dropdown {
      right: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      position: absolute;
      right: 0;
      top: 16px;

      .request-join-btn {
        margin-right: 16px !important;
      }
    }
  }

  .image-with-info {
    display: inline-flex;
    align-items: center;

    .space-header-name {
      color: $level-11;
      font-weight: 900;
      font-size: 20px;
      line-height: 1.2;
      padding-right: 10px;

      i:first-child {
        position: relative;
        top: 2px;
      }

      @media(min-width: 768px) {
        padding-right: 0;
      }
    }

    .btn-with-title-bar {
      font-size: 16px;
      margin-top: 8px;

      .a-link {
        font-size: inherit;
      }
    }
  }

  .image-figure {
    position: relative;
    margin-right: 8px;
    border-radius: 4px;
    overflow: hidden;

    @media(min-width: 768px) {
      margin-right: 16px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(18,18,20,0.7);
      left: 0;
      top: 0;
      opacity: 0;
    }

    &:hover {
      .edit-image-btn {
        opacity: 1;
      }

      &:after {
        opacity: 1;
      }
    }

    @media screen and (max-width: 767px) {
      max-width: 72px;
      max-height: 48px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .edit-image-btn {
    @include x-y-center;
    font-size: 0;
    padding: 0;
    opacity: 0;
    z-index: 1;

    .btn-icon-helper {
      font-size: 16px;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  .detail-board {
    padding-top: 1.5rem;
  }

  .os-card-thumbnail {
    transition: transform 500ms ease;
  }
}

.os-card-thumbnail {
  .osbtn-icon.no-text {
    .btn-icon-helper {
      color: $level-1 !important;
    }

    &:hover, &:active, &.active, &:focus {
      .btn-icon-helper {
        color: $level-8 !important;
      }
    }
  }
}
