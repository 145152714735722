.add-pulse-backdrop {
  background-color: transparent;
  position: absolute;
  z-index: 1000;
}


.osbtn-publish {
  padding-left: 16px;
  padding-right: 16px;
}

label {
  &.radio-label {
    padding-left: 24px;
    @include fontstyle(16px, 400, 20px);
    position: relative;
    margin-bottom: 20px;
  }

  .checkmark {
    width: 16px;
    height: 16px;
    border: 1px solid $border-input-typed-button-2;
    position: absolute;
    left: 0;
    background-color: transparent;
    border-radius: 50%;
    -webkit-transition: all .25s ease;
    transition: all .25s ease;

    &.top0 {
      top: 0;
    }
  }

  input:checked~.checkmark {
    border-color: $level-10;
  }

  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $level-10;
    content: '';
    position: relative;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}

.modal,
.modal-global  {
  .modal-title,
  .card-title {
    @extend %heading-2-bold;
    color: $heading;
    padding-right: 32px;
  }

  .cards-row-title {
    color: $secondary-heading;
  }
}

.pulse-report-modal {
  .modal-body-text {
    .title-primary {
      display: block;
    }
  }
}

.pulse-report-modal,
.os-grid-modal {
  min-width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  min-height: 100%;

  .modal-content {
    padding: 24px;
    margin: auto;
    max-width: 95%;
    border: 0;
  }

  .modal-body {
    padding: 0 16px;
  }

  .saved-to-entity-modal {
    @extend %heading-2-bold;
  }

  .modal-title {

    &.lh-normal {
      line-height: normal;
    }

    &.fw-300 {
      font-weight: 300;
    }
  }

  @media screen and (min-width: 576px) {
    .modal-content {
      width: 552px;
      border-radius: 10px;
    }
  }

  .svg-image {
    .svg-icon {
      max-width: 160px;
      max-height: 160px;
    }
  }

  .modal-header {
    position: relative;
    padding-bottom: 24px;
    color: #d4d4d9;

    .os-header-btn {
      top: -8px;
      right: -12px;
    }

    .close-edit-error-pulse {
      right: 16px;
      top: 16px;
    }

    .r-0 {
      right: 0;
    }

    .ifill-os-close {
      font-size: 16px;
    }

    .r--8 {
      right: -8px;
    }

    .y-center {
      top: 50%;
      transform: translateY(-50%);
    }
  }


  .with-abs-close {
    .ifill-os-close {
      right: -10px;
      top: -12px;
      position: absolute;
    }
  }

  label {
    &.radio-label {
      padding-left: 24px;
      @include fontstyle(16px, 400, 20px);
      position: relative;
      margin-bottom: 20px;
    }

    .checkmark {
      width: 16px;
      height: 16px;
      border: 1px solid $border-input-typed-button-2;
      position: absolute;
      top: calc(50% - 8px);
      left: 0;
      background-color: transparent;
      border-radius: 50%;
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
    }

    input:checked~.checkmark {
      border-color: $os-link;
    }

    .checkmark:after {
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $cl-link;
      content: '';
      position: relative;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
  }

  .modal-body-textarea {
    padding-left: 24px;
    position: relative;

    .form-control{
      padding-top: 7px;
      padding-bottom: 7px;
      resize: none;
      height: 40px;
      line-height: 24px;
      max-height: 80px;
    }

    .form-error {
      position: absolute;
      top: 100%;
    }
  }

  .modal-footer {
    margin-top: 40px;
  }

  &.report-modal {
    .modal-body-text {
      margin-bottom: 0px;
    }
    .report-text {
      margin-bottom: 28px;
    }

    .modal-footer {
      margin-top: 32px;
    }
  }

  &.pulse-add-new {
    background-color: $block;
    height: calc(100% - 70px);

    .ifill-os-close {
      position: absolute;
      z-index: 1;
      margin-top: 0px;
    }

    .modal-content {
      background-color: transparent;
      height: 100%;
      padding-top: 24px !important;
      overflow-y: auto;
    }

    .new-post-heading {
      margin-bottom: 10px;
      margin-top: 16px;
    }

    .post-footer-section {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;

      .osbtn {
        &:first-child {
          margin-left: -6px;
        }

        &.photo-btn-footer {
          min-width: 40px !important;
          padding: 0px 8px;
          font-size: 0;
          padding: 0px 8px !important;

          .btn-icon-helper {
            font-size: 20px;
            left: 12px !important;
          }
        }
      }
    }

    .osbtn {
      line-height: 40px;
      min-height: 40px;
      max-height: 40px;
      &:not(.no-text) {
        padding-left: 30px;
        padding-right: 8px;
      }

      &.osbtn-icon{
        &:not(.no-text) {
          .btn-icon-helper {
            left: 8px !important;
            color: #6c6c6f;
          }
        }

        &.photo-btn-footer {
          min-width: 80px;
          padding-left: 20px;

          .btn-icon-helper {
            left: 0px !important;
          }
        }
      }

      &.osbtn-publish {
        margin-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
        display: block;
        order: 4;
        flex-basis: 100%;
      }
    }

    .select-box-wrap {
      position: absolute;
      right: 0;
      width: calc(100% - 75px);
      top: 40px;
      z-index: 3;

      .os-header-btn {
        right: 0;
        top: 0;
      }
    }

    .share-post-dropdown {
      margin-right: -15px;

      &.only-select {
        .osbtn-icon {
          padding-left: 16px !important;
          padding-right: 40px !important;

          .btn-icon-helper {
            color: $level-8;
            left: auto !important;
          }

          &:hover {
            .btn-icon-helper {
              color: $os-link;
            }
          }
        }
      }

      + .osbtn {
        margin-top: 16px;

        &.osbtn-publish {
          flex-basis: auto;
        }
      }
    }
  }
}


.disable-access-modal {
  .modal-content {
    padding: 24px 32px 40px;

    @media screen and (min-width: 576px) {
      max-width: 480px;
    }
  }

  .modal-header {
    padding: 0;
    min-height: 40px;
  }

  .report-text {
    color: $level-11;
    margin-bottom: 16px !important;

  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;
  }

  .os-header-btn {
    top: -4px;
  }

  label {
    &.radio-label {
      color: $level-11;
      padding-left: 40px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .checkmark-icon {
      position: absolute;
      top: 4px;
      left: 0;
      font-size: 24px;
      color:$level-8;
      cursor: pointer;

      &.checked {
        display: none;
      }
    }

    input:checked~.checkmark-icon {
      &.checked {
        color: $os-link;
        display: block;
      }
    }
  }

  .modal-footer {
    .osbtn + .osbtn {
      margin-left: 24px;
    }
  }
}

.mobile-card {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.mobile-device {
  .pulse-report-modal,
  .os-grid-modal {

    .os-header-btn {
      right: -13px;
    }

    .close-assign-modal-btn {
      right: 12px;
    }

    .close-add-new-post {
      right: 8px;
      top: 8px;
    }

    .close-report-btn {
      right: 5px;
      top: -8px;
    }

    .close-edit-error-pulse {
      right: 4px;
      top: 4px;
    }

    .with-abs-close {
      .ifill-os-close {
        right: 0px;
        top: 10px;
        margin: 0;
        position: absolute;
      }
    }

    .post-can-view-dropdown {

      .header-dropdown-list-item {
        .osbtn {
          padding-left: 40px !important;

          .btn-icon-helper {
            left: 16px !important;
          }
        }

      }
    }
  }

  .disable-access-modal {
    .modal-content {
      padding-top: 24px;
    }
    .close-report-btn {
      top: -5px;
    }
  }

  &.portrait {
    .pulse-report-modal,
    .os-grid-modal {

      .modal-content {
        min-height: 100%;
        margin: 0;
        max-width: 100%;
        width: 100%;
        padding: 48px 20px 20px;
        border-radius: 0px;
      }

      .modal-body {
        flex: inherit;
      }

      .px-24{
        padding-left: 24px;
        padding-right: 24px;
      }

      .svg-image {
        margin-bottom: 47px !important;
      }

      .modal-footer {
        margin-top: 50px;
      }

      &.report-modal {
        .modal-header {
          margin-bottom: 46px;
        }

        .svg-image {
          margin-bottom: 30px !important;
        }

        .report-text {
          font-size: 16px;
          margin-bottom: 16px;
        }

        .modal-body-textarea {
          textarea {
            font-size: 14px;
          }
        }

        .modal-footer {
          margin-top: 50px;
        }
      }
    }

    .pulse-report-modal {
      &.pulse-add-new {
        .ifill-os-close {
          right: -8px;
        }
      }
    }
  }
  .pulse-add-new {
    background-color: $block;

    .os-card-v2 {
      margin-top: 16px;
    }

    .ifill-os-close.card-close-link {
      right: -15px;
      margin-top: 0px !important;
    }

    .m-row-hidden {
      max-height: 100%;
      overflow-y : auto;
      flex: unset;
    }

    .mobile-card {
      display: block;
      overflow-y: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .user-account-modal {
    .os__menu-list {
      max-height: 170px;
    }
  }

  .pulse-report-modal {
    .detail-comments-top-block {
      flex-direction: column;
      margin-top: 24px;

      .feed-author-info {
        display: none;
      }

      .media-body {
        width: 100%;
      }

      .mention-textarea-wrap__highlighter,
      .mention-textarea-wrap__input {
        padding: 10px 16px  10px 40px;
      }

      .comments-primary-avatar {
        top: 20px;
        left: 8px;
      }

      .avatar {
        &.avatar-32 {
          @include avatar(26px);
        }
      }

      .reply-buttons {
        display: none;
      }

      .comment-error {
        position: absolute;
        bottom: -20px;
      }
    }

    .post-comment-footer-action {
      width: auto;
      flex-basis: auto;
      margin-top: 24px !important;

      .post-comment-footer  {
        display: inline-block ;
        margin-top: 20px;
        float: right;

        .osbtn {
          padding: 0px 16px !important;
        }
      }

      .select-box-wrap {
        max-width: 100%;
        width: 100%;
        top: 0;
        position: relative;
        margin-bottom: 16px;

        + .post-comment-footer {
          margin-top: 60px;
        }

        .os-header-btn {
          top: 0;
          right: 2px;
        }
      }

      .share-post-dropdown {
        margin-right: 0;
        min-height: 40px;

        .page-heading-title {
          span {
            position: relative;
          }
        }

        .comment-error {
          position: absolute;
          margin-top: 0 !important;
        }
      }
    }
  }
}

.user-account-modal {
  .os__menu-list {
    max-height: 200px;
  }

  .form-group {
    position: relative;
  }

  .form-error {
    position: absolute;
    left: 0;
    top: 100%;
  }
}


.assign-message-mdaoal {
  .saved-to-entity-modal {
    padding-right: 50px;
  }

  .assign-link {
    margin-top: 24px;
    margin-right: -50px;
    float: none !important;
    text-align: right;
  }

  .close-assign-modal-btn {
    right: 12px;
    z-index: 9;
  }
}

.mobile-device {
  &.portrait {
    .os-grid-modal {
      &.add-space-modal {
        display: flex;
        align-items: center;
        height: inherit;
        padding: 0 0.5rem;


        .modal-content {
          min-height: auto;
        }
      }
    }
  }

  .pulse-add-new {
    .post-can-view-dropdown {
      margin-left: auto;
    }
  }
}


@-moz-document url-prefix() {
  .pulse-report-modal {
    label {
      .checkmark {
        top: 2px;
      }
    }
  }
}


.os-grid-modal {
  &.timeline-legend-modal {
    @media screen and (min-width: 576px) {
      .modal-content {
        width: 480px;
        padding: 24px 32px;
      }

      &.cr-space-modal {
        .modal-content {
          width: 376px;
        }
      }
    }

    .modal-header {
      padding: 6px 0 24px 0;

      .os-header-btn {
        right: -8px;
        top: 2px;

        @media screen and (max-width: 767px) {
          top: 0;
          right: 0;
        }
      }
    }

    &.cr-space-modal {
      .modal-header {
        .os-header-btn {
          top: 0;
          right: -8px;
        }
      }
    }
  }
}

.mute_confirmation {
  .modal-header {
    .os-header-btn {
      top: -2px;
    }
  }
}
