.show-in-connect-modal {
  display: none;
}

.modal-connect {
  .modal-header {
    margin-bottom: 9px;
    padding: 16px 24px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 1px;
      background: #fff-20;
    }

    i.ifill-os-close {
      position: relative;
      top: 0;
      right: 0
    }
  }

  .hide-in-connect-modal {
    display: none;
  }

  .show-in-connect-modal {
    display: block;
    width: 100%;
  }

  .modal-content  .modal-body {
    padding: 24px;
  }

  .modal-card-connect {
    width: 304px;
    margin: 0 auto 32px;

    .card__content {
      padding: 16px 0;
      flex-wrap: wrap;
      align-content: flex-start;
      position: relative;
      min-height: 120px;
    }

    label {
      line-height: 1;
    }

  }

  .textarea-field {
    max-height: 80px;
    resize: none;
  }

  .user-location {
    text-align: left;
    padding: 0 16px 0 32px;
    position: relative;
    line-height: normal;
    margin-top: 16px;
  }

  .user-location-text {
    line-height: 1.33;
    font-size: 12px;
  }

  .ifill-os-location {
    position: absolute;
    left: 12px;
    top: 0px;
    font-size: 16px;
  }

  .request-send-msg {
    position: relative;
    top: -10px;
    text-align: left;
  }
}

@media (min-width: 600px) {
  .modal-connect {
    max-width: 580px;

    .modal-content {
      width: 100%;
    }

    .modal-header .card-title {
      font-size: 24px;
    }
  }
}


@media (max-width: 599px) {
  .modal-connect {
    .modal-content {
      width: 100%;
    }
  }
}

.mobile-device {
  .modal-connect {
    .ifill-os-close {
      position: static !important;
    }
  }
}
