.lecture-screen{
  margin-top: 24px;
  background-color: #1c1c1f;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(18, 18, 20, 0.2);
  padding: 32px 20px 32px 0 ;
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);

  .lecture-side-btns {
    top: -28px;
    right: -16px;
    position: absolute;

    .osbtn + .header-dropdown-menu,
    .clv-close-btn {
      margin-left: 8px;
    }
  }

  .detail-subheader  {
    display: inline-block;
  }

  .lecture-loader {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    background-color: $os-link;
    border-radius: 8px;
    box-shadow: 0 2px 7px 0 rgba(18, 18, 20, 0.1);
    padding: 33px 60px;
    z-index: 1;
    width: 152px;
    height: 96px;

    i {
      font-size: 28px;
      line-height: 1;
      color: $level-11 !important;
    }
  }
}

.preview-dropdown-menu {
  display: inline-block;

  .osbtn {
    padding: 0px 12px;
  }

}

.preview-content {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.figure-caption {
  @include fontstyle(16px, 300, 1.5);
  color: $level-11;
  margin-bottom: 32px;
  display: flex;

  .fig-caption-heading {
    max-width: calc(100% - 88px);
  }

  .osbtn {
    padding: 0px 12px;
  }
}

.lecture-preview {
  .back-nav-bar {
    align-items: center;
    margin-bottom: 0;
    margin-top: 24px;
  }

  .clv-close-btn {
    .btn-icon-helper.ifill-os-close {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media(max-width: 767px) {
    .back-nav-btn {
      max-width: calc(100% - 25px);
    }
  }
}

.preview-left-section,
.preview-content-wrap {
  max-height: 100%;
}

.preview-left-section,
.preview-content-wrap {
  max-height: 100%;
}

.preview-left-section {
  flex: 0 0 31%;
  max-width: 31%;
  padding-left: 0;
}

.preview-content-wrap {
  flex: 0 0 69%;
  max-width: 69%;
  padding-left: 0;
  padding-top: 10px;
}

.preview-left-listing {
  max-height: 100%;
  overflow-y: auto;
  padding-right: 8px;

  .preview-left-listing  {
    max-height: 100%;
  }

  .toggle-heading {
    @include fontstyle(20px, 600, 1.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-top: 11px;
    padding-left: 32px;
    padding-right: 8px;
    cursor: pointer;


    i {
      font-size: 16px;
    }
  }

  .toggle-summary {
    @include fontstyle(16px, normal, 1.5);
    color: $level-11;
    padding: 8px 24px 8px 56px;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;

    &.active {
      background-color: #222226;
      box-shadow: 0 2px 4px 0 rgba(18, 18, 20, 0.2);
    }

    .pw-icon {
      position: absolute;
      left: 32px;
      top: 12px;
    }
  }

  .toggle-summary-text {
    color: $level-11;
    max-width: 100%;

    span {
      overflow-wrap: break-word;
      text-overflow: ellipsis;
    }
  }

  .time-type {
    color: $level-9;
    font-size: 12px;
  }
}

.no-touch-support {
  .toggle-summary {
    &:hover {
      background-color: #222226;
      box-shadow: 0 2px 4px 0 rgba(18, 18, 20, 0.2);
    }
  }

  &.clv-page {
    @include clvSpin(0.98, 0.95, 0.6)
  }
}

.img-video-content {
  height: calc(100% - 72px);
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    border: 0px !important;
  }

  .case-video {
    padding-bottom: 0;
    height: 100%;
  }

  .no-preview-text {
    @include fontstyle(16px, 300, 1.5);
    color: $level-9;
  }

  .create-slider {
    width: 100%;
    height: 100%;

    .slick-slider {
      height: 100%;
      max-height: 100%;

      .slick-list {
        border-radius: 6px;
      }

      .slick-list,
      .slick-track,
      .slick-slide {
        height: inherit;
      }

      .slide-img-holder {
        &:before {
          content: none;
          background: linear-gradient(to top, rgba(0, 0, 0, 0), #121214),
                      linear-gradient(to top, rgba(73, 103, 119, 0) 29%, #496777),
                      linear-gradient(to bottom, rgba(117, 116, 116, 0) 51%, rgba(30, 30, 30, 0.7)) !important;
        }
      }

      .case-video,
      .slide-video-holder {
        height: 100%;
      }
    }

    .fullscreen-button {
      display: none !important;
    }

    .pdf-canvas {
      width: 100%;

      > canvas {
        width: 100%;
      }
    }
  }
}

.desktop-small,
.ipad.landscape {
  .lecture-screen {
    padding-top: 28px;
    padding-right: 0;

    .lecture-side-btns {
      top: -24px;
      right: -4px;
    }
  }

  .preview-left-section {
    flex: 0 0 30.2%;
    max-width: 30.2%;

    .toggle-heading {
      padding-left: 16px;
    }

    .toggle-summary {
      padding-left: 40px;

      .pw-icon {
        left: 16px;
      }
    }
  }

  .preview-content-wrap {
    padding-top: 15px;
  }
}

.ipad {
  .lecture-screen {
    height: calc(100vh - 220px);
    max-height: calc(100vh - 220px);
  }

  &.portrait {
    .lecture-screen {
      flex-direction: column-reverse;
      padding:28px 0 16px 0;

      .lecture-side-btns {
        top: -24px;
        right: 4px;
      }
    }

    .preview-content-wrap {
      padding-left: 24px;
      padding-right: 24px;
      max-width: 100%;
      max-height: 69% !important;
    }

    .preview-left-listing {
      padding-right: 0;
    }

    .preview-left-section {
      max-width: 100%;
      max-height: 31% !important;
      padding-right: 0;

      .toggle-heading {
        @include fontstyle(20px, 600, 1.4);
        padding-left: 24px;
      }

      .toggle-summary {
        padding-left: 48px;

        .pw-icon {
          left: 24px;
        }
      }
    }

    .img-video-content {
      margin-bottom: 16px;
    }

    .lecture-screen {
      &.document-screen {
        .img-video-content {
          background-color: #171717;
          border-radius: 8px;
        }
      }
    }
  }
}

.mobile-device {
  .lecture-preview {
    padding: 0;

    .mx-m-0 {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .lecture-screen {
    height: calc(100vh - 280px);
    max-height: calc(100vh - 280px);
    flex-direction: column-reverse;
    padding:24px 0 16px 0;
    margin-top: 16px;
    justify-content: flex-end;

    .lecture-side-btns {
      top: -17px;
      right: 4px;
    }

    @media(max-height: 580px) {
      height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);
    }

    .lecture-loader {
      padding: 13px 31px;
      width: 76px;
      height: 48px;
      z-index: 1;

      i {
        font-size: 14px;
        line-height: 1;
      }
    }

    .detail-subheader {
      display: inline;
    }
  }

  .preview-content-wrap {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 5px;
    max-width: 100%;
    height: 65%;
    max-height: 65%;
  }

  .preview-left-section {
    max-width: 100%;
    height: 35%;
    max-height: 35%;
    padding-right: 0;

    .toggle-heading {
      @include fontstyle(20px, 600, 1.4);
      margin-bottom: 24px;
    }
  }

  .preview-left-listing {
    padding-right: 0;

    .preview-left-listing  {
      max-height: 100%;
    }
  }

  .figure-caption {
    margin-bottom: 16px;
  }

  .img-video-content {
    .create-slider {
      margin: 0;
      width: 100%;

      .slick-slider {
        height: 100% !important;
      }
    }
  }

  .img-video-content {
    height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    margin-bottom: 0;
  }

  .lecture-screen {
    &.document-screen {
      .img-video-content {

        .create-slider{
          .main-slider{
            &.slick-slider {
              height: 100% !important;
            }

            .slick-slide {
              height: auto;
            }
          }
        }

        .pg-viewer-wrapper {
          .document-container {
            border-radius: 6px;
            width: 100% !important;
          }
        }
      }

      .preview-left-listing {
        max-height: 100%;
      }
    }

    .img-video-content {
      .create-slider {
        .slick-slide,
        .case-video {
          height: 100% !important;
        }
      }
    }
  }

  .zoomable-content {
    .create-slider{
      .main-slider{
        &.slick-slider {
          height: 100% !important;

          > div {
            height: inherit;
            overflow: unset !important;
          }
        }

        .slick-slide {
          height: inherit;
        }
      }

      .pdf-canvas {
        width: 100%;

        >canvas {
          width: 100%;
        }
      }
    }

    .pg-viewer-wrapper {
      .document-container {
        border-radius: 6px;
        width: 100% !important;
      }
    }
  }
}

.fullscreen-view {
  .preview-left-section {
    max-height: 100%;
  }

  .preview-content-wrap {
    max-height: 100%;
  }
}

.clv-page {
  body {
    padding-bottom: 0;
    #root {
      min-height: unset !important;
    }
  }

  .cs-treatment-sec {
    overflow-y: auto;
    @extend %os__scroll;
  }

  &:not(.mobile-device) {
    .modal-comparison {
      margin-top: 172px;

      .modal-content {
        .os-header-btn {
          top: 4px !important;
          right: 0px;
        }
      }

      .new-cs-card {
        padding-top: 32px;
        box-shadow: none;

        .case-right-landing-header{

          .case-right-landing-message {
            padding-top: 0;
          }

          .case-right-landing-btn-row {
            margin-top: 32px;
            margin-bottom: 48px;
          }
        }

        .sbs-dropdown {
          .cs-dropdown i {
            top: 50%;
          }
        }

        .active-tool-btn {
          .ifill-os-chevron-down {
            position: relative;
            right: auto;
            top: auto;
            color: $os-link;
          }
        }
      }
    }
  }

  &.ipad.landscape {
    .modal-comparison {
      margin-top: 172px;

      .modal-content {
        top: unset;
        transform: none
      }
    }

    .new-cs-card {
      height: 645px;
      min-height: 645px;
      max-height: 645px;

      .spin-video {
        .threesixty-spin {
          transform: translate(-50%,-50%) scale(.94);
        }
      }

      .case-left-wrapper {
        &:hover {
          .spin-video {
            .threesixty-spin {
              transform: translate(-50%,-50%) scale(.87);
            }
          }
        }
      }

      .sbs-wrapper {
        .spin-video {
          .threesixty-spin {
            transform: translate(-50%,-50%) scale(0.8);
          }
        }
      }

      .case-timeline-right-section {
        height: 100%;
      }
    }

    @media(min-width: 1300px) {
      .modal-comparison {
        max-width: 1128px;
      }

      .new-cs-card {
        .spin-video {
          .threesixty-spin {
            transform: translate(-50%,-50%) scale(.92);
          }
        }

        .case-left-wrapper {
          &:hover {
            .spin-video {
              .threesixty-spin {
                transform: translate(-50%,-50%) scale(.89);
              }
            }
          }
        }

        .sbs-wrapper {
          .spin-video {
            .threesixty-spin {
              transform: translate(-50%,-50%) scale(0.8);
            }
          }
        }


      }
    }
  }

  @media screen and (min-width: 1900px) {
    .lecture-screen {
      height: 65vh;
      max-height: 65vh;
    }

    .preview-left-section {
      flex: 0 0 25.7%;
      max-width: 25.7%;
    }

    .preview-content-wrap {
      flex: 0 0 74%;
      max-width: 74%;
    }
  }

  .new-cs-card {
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;

    .submission-selection-holder
    .submission-selection-link,
    .submission-selection-small-holder
    .submission-selection-link {
      font-size: 10px;
    }

    .submission-selection-small-holder{
      &.four-submission-link {
        .submission-selection-link {
          font-size: 8px;
        }
      }
    }
    .questions {
      .card {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  &.ipad {
    .new-cs-card {
      .case-details-video-section {
        height: 600px;
        min-height: 600px;
        padding-bottom: 0;
      }

      .spin-video {
        .threesixty-spin {
          margin-top: 39px;
        }
      }

      .case-left-wrapper,
      .sbs-wrapper {
        &:hover {
          .case-thumbnail-slider {
            margin-bottom: 8px;
            margin-top: 0;
          }
        }
      }
    }

    &.portrait {
      .new-cs-card {
        margin-bottom: 0;

        .case-timeline-right-section {
          margin-top: 0;
          background-color: transparent;
        }
      }

      .case-left-wrapper,
      .sbs-wrapper{
        &:hover {
          .case-thumbnail-slider {
            margin-bottom: 5px;
          }
        }
      }

      @include clvSpin(0.9, 0.94, 0.8)

      .new-cs-card {
        .case-left-wrapper,
        .sbs-wrapper {
          &:hover {
            .cs-sort-btn-group {
              margin-top: -16px;
            }

            .case-thumbnail-slider {
              bottom: 0;
              margin-top: 0;
            }
          }
        }

        .sbs-wrapper {
          &:hover {
            .cs-sort-btn-group {
              margin-top: -16px;
            }
          }
        }


        @media(max-width: 1023px) {
          .slider-threesixty.horizontal {
            bottom: 16px;
          }
        }
      }

      @media(min-width: 1024px) {
        .modal-comparison {
          max-width: 1000px;
        }

        @include clvSpin(1.17, 1.15, 0.8);
      }

      &.portrait {
        .cs-treatment-sec {
          max-height: 450px;
        }
      }
    }
  }
}


.universalBannerWrapper {
  ~ .lecture-preview .lecture-screen {
      height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);
  }
}

@media screen and (min-width: 1900px) {
  .universalBannerWrapper {
  ~ .lecture-preview .lecture-screen {
        height: calc(65vh - 48px);
        max-height: calc(65vh - 48px);
    }
  }
}
