$pulse-left-sidebar-width: 168px;
$pulse-right-sidebar-width: 288px;

.doctor-list {
  .degree-text {
    color: $level-9;
  }

  .recent-joiners-list {
    margin-top: 16px;
  }
}

.dr-connect-list-item{
  padding: 12px 0 0px;
  line-height: normal;

  .avatar-link {
    margin-right: 0.5rem;
    min-width: 40px;
    max-height: 40px;
  }

  .connect-avatar {
    .ifill-os-fill-user {
      margin-top: 1.5px;
    }
  }

  .connection-info {
    flex: 1;
    line-height: 1;
    /*max-width: calc(100% - 48px);*/
    overflow: hidden;
  }

  .user-name-info {
    line-height: 1.25;
    position: relative;
    padding-right: 40px;

    .white-link {
      + .degree-text {
        display: none
      }
    }
  }

  .span-helper {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .small {
    font-size: 12px;
    display: block;
    padding-right: 48px;
    margin-top: 3px;
  }

  .pr-60 {
    padding-right: 60px;
  }

  .aside-action {
    position: absolute;
    width: 40px;
    white-space: nowrap;
    right: 0;
    top: 0;
  }

  .connect-link {
    padding-left: 8px !important;
    padding-right: 8px !important;
    right: -8px;
    color: #e4e49e;

    .btn-icon-helper {
      font-size: 24px !important;
    }
  }

  .connection-info + .osbtn {
    padding-left: 0 !important;
    border: 0;

    &:hover {
      background: $level-4 !important;
      .btn-icon-helper {
        color: $level-12 !important;
      }
    }

    &:focus, &:active {
      background: $level-5 !important;
      color: $level-12 !important;
    }

    .btn-icon-helper {
      left:  12px;
    }
  }
}

.recent-joiners-list {
  padding-left: 0;

  .dr-connect-list-item {
    .user-name-info {
      padding-right: 0;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .dr-connect-list-item {
    padding: 24px 0 4px;

    .avatar-link {
      max-height: 40px;
    }
  }

  .recent-joiners-list {
    .heading-title {
      margin-left: -4px;
    }
  }
}

.mobile-device {
  .dr-connect-list-item {
    .span-helper {
      display: block !important;
    }
  }
}
