.mycare_space,
.care_team,
.patient_listing,
.clinic_space {
  padding-top: 26px;
}

.no-profile-info {
  text-align: center;
  padding: 3.61%;
}


.patient_detail_page {
  .px-52 {
    padding-right: 0;
    padding-left: 0;
  }
}

.post-visibility {
  margin: 0 6px;
}

.care,
.workspace {
  .discussion-thread-section {
    &>div:last-child {
      .os__menu {
        width: fit-content !important;
        left: 0;
      }
      &:has(.os__control--menu-is-open) {
        position: relative !important;
        z-index: 0;
      }
      .taskWrapper {
        .header-dropdown-list {
          z-index: 11;
        }
      }
    }
    .comment {
      .feed-author-info {
        display: block;
      }
      .media-comment-time {
        display: inline-block;

      }
    }
    .time-with-seenlist {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 4px;

      .post-visibility{
        height: 16px;
        width: 16px;
        margin: 0;
        padding: 0;
        .ifill-os-locked, .ifill-os-views {
          color: #5d7893;
        }
      }
      .copy-tooltip{
        margin: 0;
        padding: 0;
        max-height: 16px;
      }
      .not-seen-text{
        margin: 0;
        padding: 4px 0 0 0;
      }
    }

    .user-name-block {
      .tooltip-copy {
        right: auto;
        left: 0;
        top: -40px !important;
        bottom: auto;

        @media screen and (max-width: 767px) {
          top: -40px !important;
          left: -15px;
        }
      }

      .user-with-relation {
        display: flex;
        align-items: center;
      }
    }
    .comment-reply-list {
      .comment {
        .feed-author-info {
          display: inline-block;
        }
        .user-name-block {
          .tooltip-copy {
            top: -36px;
            left: auto;

            @media screen and (max-width: 767px) {
              top: -20px;
            }
          }
          .user-with-relatiion {
            display: inline-flex;
          }
        }
        .time-with-seenlist {
          display: inline-flex;
        }
      }
    }
  }
}

.seen-icon-tag {
  color: $level-9;
  padding: 0 4px;
  cursor: pointer;
  @include fontstyle(12px, $font-weight-normal, 16px);

  @media screen and (min-width: 1920px) {
    font-size: 16px;
    line-height: 24px;
  }

  .tick-icon {
    margin-right: 4px;
    vertical-align: middle;
    display: inline-block;
  }

  .see-all-heading {
    font-size: 12px;
    color: $level-9;
    font-weight: 400;
    text-align: center;
    margin: 0 0;
    border-bottom: solid 1px $level-4;
    line-height: 38px;
    @include sticky;
    top: 0;
    background: $level-1;
    z-index: 1;
  }

  .see-all {
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    font-weight: 400;
    color: $os-link;

    &:hover {
      text-decoration: underline;
    }
  }

  .emoji-add-list {
    .span-helper {
      @include text-truncate();
      color: $level-11;
      padding-left: 8px;
    }

    ul {
      padding: 0 0 8px;
    }
  }


  &:hover {
    color: $level-11;
    text-decoration: none !important;

    .tick-icon {
      color: $level-11;
    }

    .emoji-add-list {
      display: block;
      width: 152px;
      left: -12px;
      top: 20px;
      visibility: visible;
      pointer-events: auto;

      &:before {
        left: 24px;
      }

      &:hover {
        .user-name {
          color: $level-11;
        }
      }
    }
  }
}

.not-seen-text {
  display: unset;
  margin: 6px 4px auto 4px;
  height: unset;
  color: $level-9;
  padding: 0;
  line-height: 16px;
  font-weight: $font-weight-normal ;
  font-size: 12px;

  @media screen and (min-width: 1920px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.care {
  .detail-comments-header {

    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      min-height: 96px;
      padding-right: 48px;
    }
  }

  .f-course-cmn-block.d-none+.f-course-cmn-block {
    margin-top: 24px;
  }
}

.care {
  .f-course-cmn-block {
    margin-bottom: 24px;
  }
}

.workspace {
  .detail-comments-header {

    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      min-height: 128px;
      padding-right: 48px;
    }
  }
}

.care,
.workspace {
  &.mobile-device {
    .activity-listing-item {
      .user-info-row {
        .avatar-listing {
          margin-top: 5px;
        }
      }
    }

    .not-seen-text,
    .seen-icon-tag {
      font-weight: $font-weight-normal !important;
      font-size: 12px;
      line-height: 16px;
    }
  }
}