.labelsContainer {
    width: 100%;
    height: max-content;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 12px;
    background-color: #fff;
    margin-bottom: 4px;

    .labelsContainerHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .headerTitle {
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            line-height: 16px;
            letter-spacing: normal;
            color: #7e91a5;
        }
    }

    .labels-container-body{
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 8px;
        align-self: stretch;
        flex-wrap: wrap;

        .label {
            display: flex;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 16px;
            cursor: pointer;

            .labelText {
                font-family: Inter;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                line-height: 16px;
                letter-spacing: normal;
                color: #354554;
            }
        }

        .member {
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 4px;
            padding: 4px 8px;
            border-radius: 16px;
            background-color: #edeff3;
            cursor: pointer;

            .avatar {
                img {
                    position: relative;
                }
            }

            img {
                width: 24px !important;
                height: 24px !important;
                border-radius: 50%;
            }

            .memberName {
                flex-grow: 0;
                font-family: Inter;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #7e91a5;
            }
        }
    }
}