body {
  &.login:not(.landing-page) {
    .toast-top-full-width {
      top: 0px !important;
    }
  }

  &.forgot_password {
    overflow: hidden;

    #footer,
    .footer {
      display: none;
    }
  }

  &.reset_password {
    padding-bottom: 0px;

    .login-container {
      padding-top: 5px;

      .btn {
        margin-top: 14px;
      }

      .form-group {
        margin-top: 1.5rem;
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    &.login {
      &.modal-open {
        position: fixed;
        width: 100%;
      }
    }

    &.forgot_password {
      height: 100vh;
      overflow: hidden;
      position: relative;

      .layout {
        padding-bottom: 0;

        .container {
          padding: 0;
        }

        .footer {
          display: none;
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (orientation : landscape) {
    &.login {
      position: relative;

      .container {
        width: 100%;
        max-width: none;
        padding: 0;
      }
    }
  }

  @media(max-width: 767px) {
    &.login,
    &.forgot_password,
    &.reset_password,
    &.image-layout-open,
    &.locked_space {
      background-color: $level-1;
      .form-control {
        background: $level-3;
      }
    }
  }
}

.login-container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-position: center top;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $level-1;
    content: '';
  }

  .login-logo-block {
    padding: 0 0 20px;
    text-align: center;

    .login-logo {
      width: 100px;
      height: 100px;
      margin-bottom: 15px;
    }

    .login-logo-text {
      font-size: 24px;
      font-weight: $font-weight-normal;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
  .login-modal{
    width: 480px;
    max-height: 444px;
    background-color: #1c1c1f;
    margin: 0 auto;
    color: #fff;
    box-shadow: 0 2px 2px 0 rgba(18, 18, 20, 0.2);
    border-radius: 10px;
    padding: 24px 24px 0;
    z-index: 1;

    &.login-reset {
      max-height: none;
      min-height: 300px;
      position: relative;
    }

    .alert {
      margin-bottom: 5px;
      line-height: 18px;

      &.alert-danger {
        background-color: transparent;
        color: $lt-red;
        border-color: $lt-red;
      }
    }
  }
  h1{
    color: #fff;
    font-size: 24px;
    font-weight: $font-weight-light;
    margin-bottom: 1rem;
  }
  .btn{
    height: 34px;
    border-radius: 17px;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 24px;
  }

  &.change-password-container {
    .login-logo-block {
      display: none;
    }
  }
  .change-pass-h1 {
    font-size: 24px;
    font-weight: $font-weight-light;
    margin-bottom: 24px;
  }
  .change-password-text {
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    margin-bottom: 20px;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    padding-top: 30px;
    overflow-y: auto;
    padding-bottom: 0px;

    &.change-password-container {
      padding-top: 80px;
    }

    .login-logo-block {
      .login-logo {
        width: 60px;
        height: 60px;
      }

      .login-logo-text {
        font-size: 18px;
      }
    }
    .login-account-create {
      margin-top: 5px;
    }
    .contact-links {
      margin-bottom: 5px !important;
    }

    .login-modal {
      width: 96%;
      margin: 0 auto;
      padding-bottom: 12px;
      margin-bottom: 16px;

      .alert-danger {
        padding: 10px;
        font-size: 13px;
      }
    }

    .text-nowrap {
      &.visible-mobile {
        width: 100%;
        margin-top: 10px;
        display: block;
        text-align: center;
        z-index: 1;
      }
    }

    .login-change-password {
      .form-group {
        .form-error {
          font-size: 9px;
        }
      }
    }
  }
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .login-change-password {
      .btn {
        margin-top: 10px;
      }
    }
  }
  @media only screen and (min-device-width : 320px) and (max-device-width : 568px) {
    .login-change-password {
      .form-group {
        .form-error {
          font-size: 8px;
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (orientation : landscape) {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-y: auto;
    position: relative;
  }
}

html:not(.mobile-device) {
  .update_password {
    .login-container {
      height: calc(100vh -80px);
    }
  }
}


.login-form {
  label{
    font-size: 16px;
    font-weight: $font-weight-normal;
    line-height: 16px;
    color: $label;
  }

  .form-label-hint {
    width: 15px;
    margin-left: 10px;
    display: inline-block;
    position: relative;
    top: 2px;

    &:hover {
      .form-label-info-box {
        display: block;
      }
    }

    i {
      width: 13px;
      height: 13px;
      cursor: pointer;
      background-size: 13px auto;
    }

    .form-label-info-box {
      width: 200px;
      margin-left: -100px;
      padding: 10px;
      display: none;
      position: absolute;
      bottom: 17px;
      left: 50%;
      background: #1e1e1e;
      border-radius: 5px;

      &::after {
        content: '';
        width: 0;
        height: 0;
        margin-left: -10px;
        border-right: solid 10px transparent;
        border-left: solid 10px transparent;
        border-top: solid 10px #1e1e1e;
        position: absolute;
        bottom: -10px;
        left: 50%;
      }

      .form-label-info-row {
        margin: 3px 0 0;
        padding: 0;
        list-style: none;
        color: #fff;
        font-size: 12px;
        font-weight: $font-weight-normal;
        line-height: 15px;
        text-transform: none;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .form-control {
    color: $input-text-color

    &:focus{
      border: 1px solid #c8c8c8;
    }
  }
  .ifill-os-views,
  .ifill-os-hide-password {
    position: absolute;
    top: 39px;
    right: 16px;
    cursor: pointer;
    color: $level-8;

    &:hover {
      color: #4d4df4;
    }
  }
  .custom-checkbox{
    position: relative;
    padding-left: 30px;
    color: #d2d2d2;
    font-size: 12px;

    .ifill-os-checkmark-default-white{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      text-align: center;
      line-height: 24px;
      font-size: 24px;
      color: #787878;
      cursor:pointer;
    }
    input:checked ~ .ifill-os-checkmark-default-white {
      &:before {
        content: "\e94f";
        color: #48acfd;
      }
    }
  }
  .login-remember{
    line-height: 24px;
  }
  a.login-forgot{
    color: #fff;
    text-decoration: underline;
    font-size: 14px;
  }
  .form-group {
    position: relative;
    .form-control {
      &.error {
        border: 1px solid $lt-red;
      }
    }
    .form-error {
      position: absolute;
      top: 73px;
      right: auto;
      left: 0;
      color: $lt-red;
      font-size: 10px;
      font-weight: 600;
      text-align: left;
      line-height: 12px;
    }
  }
}

.login-logo{
  margin-bottom: 34px;
}

.forgot-password{
  font-size: 14px;
  text-decoration: underline;
}

.login-account-create{
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 15px;
  .btn{
    margin-left: 27px;
  }
}

.btn-login{
  width: 128px;
}

.btn-create-account{
  width: 168px;
}

.login-forgot, .login-reset{
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-links {
    display: block;
    clear: both;
    margin-top: 40px !important;

    span {
      color: $os-link;
    }
  }

  .mt-40 {
    margin-top: 40px;
  }

  .a-link {
    font-size: 12px;
  }
}

.login-reset {
  .contact-links {
    position: absolute;
    bottom: 8px;
    width: 100%;
    left: 0;
  }

  .reset-password-form {
    + .contact-links {
        position: relative;
        bottom: 0;
      }
  }
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill {
  color: $input-text-color;
  border: 1px solid $input-border;
  -webkit-text-fill-color: $input-text-color;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.text-underline {
  text-decoration: underline;
}


@media screen and (min-device-width: 1025px) and (max-device-width: 1366px) {
  .login-container {
    overflow-y: auto;
  }
}
@media screen and (min-width: 1441px) and (max-width: 2880px) {
  .contact-links {
    margin-top: 60px !important;
  }
}

.mobile-device {
  &:not(.iphone) {
    body {
      &.login {
        padding-bottom: 0;
      }
    }
  }

  .osbtn {
    &.btn-Ok,
    &.m-btn-80 {
      min-width: 80px;
      width: 80px;
    }
  }
}

.reset-password-form,
.modal-global.modal-update-password .reset-password-form {
  .form-group {
    .password-hint,
    .form-error {
      margin-top: 4px;
      position: absolute;
      top: unset !important;
      line-height: 1.2;
    }

    label {
      &:after {
        content: '*';
        color: $level-10;
      }
    }
  }
}

.modal-global.modal-update-password .reset-password-form {
  .form-group {
    margin-bottom: 42px;
  }
}

.modal + .login-update-modal {
  z-index: 1049;
}

.login-update-modal {
  .modal-dialog {
    max-width: 480px;
    margin: 0px auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-content {
    padding: 40px 40px 24px;

    .os-header-btn {
      right: 24px;
      top: 28px;
    }
  }

  &.verify-account-modal {
    .modal-dialog {
      max-width: 440px;
    }

    .modal-content {
      padding: 24px 20px 32px 24px;
    }

    .modal-header {
      text-align: left;
      justify-content: flex-start;
    }

    .code-hint-text {
      + .os-btn-link {
        margin-left: 0 !important;
        background: none;
        box-shadow: none;
        border: 0;
        display: inline;
        padding: 0 0 0 4px;

        &:focus, &:active {
          border: 0px;
          box-shadow: none !important;
          outline: 0 !important;
        }
      }
    }

    .modal-title {
      @include fontstyle(24px, 600, 1.4);
    }

    .security-row-action {
      margin-top: 16px;
    }

    .password-hint {
      margin-top: 5px;
      display: none;
    }

    .security-row {
      .p-number-col {
        flex:0 0 292px;
        max-width: 292px;
      }

      .cc-code-col {
        flex:0 0 116px;
        max-width: 116px;
      }

      .form-error {
        margin-top: 3px;
      }

      .code-expire-note {
        .form-error {
          margin-top: 0px;
        }
      }

      .code-send-col {
        margin-top: 8px;
      }
    }

    .send-btn-col {
      .onboard-label {
        display: none;
      }

      .col {
        padding-left: 0;
        padding-right: 0;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .code-active {
      margin-bottom: 8px;
    }

  }

  &.only-login {
    .modal-content {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      border: 0;
    }
  }

  .modal-body-content {
    padding: 32px 40px 40px;
    border-radius: 8px;
    box-shadow: $modal-shadow;
    background-color: $modal-bg;
  }

  .cus-label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .custom-control-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .login-modal-logo {
    max-width: 32px;
  }

  .modal-title {
    @include fontstyle(20px, 600, 1.4);
  }

  .modal-header {
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 32px;
  }

  .login-form {
    label {
      font-size: 16px;
    }

    .form-group {
      margin-bottom: 24px;
    }

    .custom-checkbox {
      color: $level-10;
    }
  }

  .contact-links {
    width: 100%;
    margin: 0;
    font-size: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    .a-link {
      font-size: 12px;
      line-height: 1.33;
    }
  }
}

.login-2fa {
  .modal-content {
    padding: 32px 28px 40px 32px ;

    @media screen and (max-width: 767px) {
      padding: 24px 22px;
    }
  }

  .modal-header {
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .form-group {
    .form-control {
      width: 96px !important;
      margin-right: 12px;

    }
  }

  .check-listing {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    color: $level-11;

    .form-group {
      margin-bottom: 0;
      width: 50%;
    }

    label {
      margin-bottom: 0;
      display: block;
      position: relative;
      padding-left: 25px !important;
      font-size: 12px;
      cursor: pointer;

      .check-icon-input {
        display: none;
      }

      input:checked ~ i {
        &.ifill-os-checkmark-checked {
          opacity: 1;
          transform: scale(1);
        }

        &.ifill-os-checkmark-default-white {
          opacity: 0;
          transform: scale(0);
        }
      }
    }

    i {
      position: absolute;
      left: 0;
      top: calc(50% - 8px);
      transition: all 200ms ease;
      font-size: 16px;
    }

    .ifill-os-checkmark-checked {
      opacity: 0;
      transform: scale(0);
    }

    @media(min-width: 768px) {
      .form-group {
        label {
          font-size: 16px;
          padding-left: 24px;

          i {
            top: calc(50% - 8px);
          }
        }
      }
    }
  }

  h3{
    &.login-2fa-h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 36px;
      line-height: 24px;
      color: $heading;
    }
  }
  span{
    &.login-2fa-span {
      font-size: 16px;
      font-weight: 500;
      color: $level-11;
      white-space: nowrap;
    }
  }
  .resend-text {
    font-size: 12px;
    font-weight: 500;
    color: $level-11;
    display: inline-block;
    padding-top: 3px;
    margin-left: auto;

    .resend-text-link,
    .a-link {
      cursor: pointer;
      color: $os-link;
      font-size: 12px;
      font-weight: 500;

      &.cursor-not-allowed {
        cursor: not-allowed;
      }

      &:hover {
        text-decoration : underline;
        color: $os-link-hover;
      }
    }

    .a-link-13 {
      font-size: 13px;
    }
  }

  .btn{
    margin-top: 30px;
    background-color: #fff;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1366px) {
  .login-2fa {
    .form-group {
      .form-control {
        width: 90px !important;
        margin-right: 13px;
        background-color: $level-1;
        border-color: $border-button-1;
      }
    }

    .btn{
      margin-top: 30px;
      background-color: #fff;
    }
  }
}

@media screen and (min-width: 768px) {
  .login-update-modal{
    &.verify-account-modal  {
      .security-row  {
        .code-send-col {
          white-space: nowrap;
          .form-control {
            max-width: 193px;
          }

          .form-group {
            display: flex;
            align-items: center;
          }

          .code-expire-note {
            padding-left: 16px;
          }

          .form-error{
            position: absolute;
            top: 63px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .login-update-modal {
    .modal-dialog {
      max-width: 95%;
    }

    .modal-header {
      justify-content: flex-start;
    }

    .modal-content {
      padding: 24px;

      .os-header-btn {
        right: 16px;
        top: 22px;
      }
    }
    &.only-login {
      display: flex;
      align-items: center;

      .modal-dialog {
        top: 24px;
        transform: translateY(0);
      }

      .modal-content {
        padding: 0;
      }

      .modal-body-content {
        padding: 24px;
      }
    }

    .remember-block {
      flex-direction: column;
    }

    .login-form {
      .os-btn-link {
        margin-top: 24px;
      }
    }
  }

  .login-2fa {
    .form-group {
      .form-group {
        flex: 1;

        + .form-group {
          margin-left: 13px;
        }
      }

      .form-control {
        width: 100% !important;
        margin-right: 13px;
      }
    }
    .btn{
      margin-right: 0;
    }
    .verify-text-link {
      align-items: flex-start;

      .resend-text-link {
        line-height: 16px;
        min-height: 24px;
      }
    }
  }
}

@media screen and (max-height: 667px) {
  .login-update-modal {
    .modal-dialog {
      transform: translateY(0);
      top: 24px;
    }
  }
}

.pre_verified_text {
  margin-bottom: 16px;
  display: block;
}

.modal-nav-visible {
  #root {
    filter: blur(0) !important;
  }

  .modal-backdrop-custom {
    top: 72px;
  }

  .verify-account-modal {
    top: 72px;

    .modal-dialog {
      height: calc(100% - 24px);
      display: flex;
    }

    .modal-content {
      margin: auto;
    }
  }

  #root {
    z-index: 1051;
  }
}

.no-touch-support {
  .reset_password {
    .unauth-container {
      max-width: 100% !important;
      padding: 0;
    }

    .login-container {
      position: relative;
      height: unset;
      min-height: calc(100vh - 72px);
      justify-content: unset;
      padding-top: 5px;

      .login-reset {
        margin: auto;
      }
    }

    .universalBannerWrapper ~ {
      .unauth-container .login-container {
        min-height: calc(100vh - 120px);
      }
    }
  }
}

.ipad {
  &.portrait {
    .login-update-modal {
      &.verify-account-modal {
        .modal-dialog {
          margin-top: 0;
        }
      }
    }
  }
}

.mobile-device {
  .login-update-modal {

    .modal-dialog {
      margin-top: 20px;
    }

    .modal-body-content {
      height: auto;
    }

    &.verify-account-modal {
      .modal-dialog {
        margin-top: 36px;
        transform: translateY(-50%);
        top: 50%;
      }

      .password-hint {
        display: block;
      }
      .show-login-2fa {
        display: none;
      }

      .security-row {
        .code-send-col {
          margin-top: 16px;
        }

        .p-number-col {
          flex:0 0 225px;
          max-width: 225px;
        }

        .form-error {
          margin-top: 3px;
        }
      }

      .security-row-action {
        justify-content: center;
      }
    }
  }

  .reset_password {
    .universalBannerWrapper ~ {
      .unauth-container .login-container {
        min-height: calc(100vh - 124px);
        padding-top: 10px;

        @media(max-width: 350px) {
          min-height: calc(100vh - 145px);
        }
      }
    }
  }
}

.ws_auth_row {
  align-items: center;
}

.ws-auth-info-area {
  @media(min-width: 1024px) {
    padding-right: 12.7%;
  }
  font-size: 1.25rem;
  line-height: 1.4;
  color: $level-9;

  .auth-heading {
    color: $os-257;
    @include font-text($font-weight-black, 3rem ,1.17);
    padding-bottom: 2rem;
  }
}

.mobile-device {
  .clinic_invite-rightBar {
    &.cmn-form-box {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.ws-user-form {
  .cmn-form-box {
    @media(min-width: 768px) {
      box-shadow: $form-shadow;
      border-radius: 12px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @media(max-width: 768px) {
      padding: 3.75rem 0.25rem 0;
      background: none;

      .osbtn-primary {
        line-height: 40px;
      }

      .form-heading {
        padding-bottom: 3rem;
      }
    }
  }

  .clinic_invite-rightBar,
  .login_form-rightBar,
  .care_signup-rightBar {
    width: 100%;
    display: flex;
    padding-bottom: 40px;
    @media(min-width: 768px) {
      min-height: 620px;
      padding-bottom: 20px;
    }
  }

  .care_signup-rightBar {
    @media(min-width: 768px) {
      min-height: 676px;
    }

    .reset_login_password {
      margin-bottom: 30px;
    }
  }

  .device-trust-checkare {
    padding-left: 25px;
  }

  .update_number-rightBar {
    padding-top: 40px;
    padding-bottom: 40px;

    @media(min-width: 768px) {
      min-height: 564px;
      padding-top: 80px;
      padding-bottom: 20px;
    }

    .code-send-col {
      margin-top: 24px;
    }

    .onboard-label {
      margin: 0;

      &[for="phone_number"] {
        margin-top: 24px;
        margin-bottom: 8px;
      }
    }

    .verify-action-footer {
      position: unset;
    }

    .verify-mobile-group  {
      background: $level-3;
      border: 0;

      input {
        background: $level-3;
      }
    }

    .btn__login {
      margin-top: 64px;
    }
  }

  .device-trust-checkarea {
    padding-left: 25px;
  }

  .shared_space-rightBar {
    min-height: 544px;
    padding-top: 40px;
    padding-bottom: 40px;

    @media(min-width: 768px) {
      min-height: 544px;
      padding-top: 80px;
      padding-bottom: 20px;
    }

    .login-form-hint {
      padding-bottom: 32px;
      padding-top: 0;
    }
  }

  .login_password + .form-error {
    background: $level-1;
    z-index: 1;
    position: relative;
    margin-bottom: 5px;
    margin-top: -5px;
  }

  form {
    margin: auto;
    width: 100%;
  }

  .os-btn-link {
    cursor: pointer;
    font-weight: normal;
  }

  .login_password {
    position: relative;
    .ifill-os-hide-password,
    .ifill-os-views  {
      position: absolute;
      right: 16px;
      top: 44px;
      cursor: pointer;
      color: $level-8;
    }
  }

  .with-error {
    position: absolute;
    padding-top: 2px;

    ~ .ws-password-hint {
      transform: translateY(1rem);
    }
  }

  .subs-term {
    margin: 20px 0;

    .os-btn-link {
      font-weight: $font-weight-semibold;
    }
  }

  .ws-password-hint {
    font-size: 12px;
    color: $level-9;
    margin-top: 4px;
  }

  .code-send-col {
    .device-trust-checkarea {
      padding-left: 25px;
    }
  }

  .otp-form-group {
    margin-top: 8px;
    gap: 8px;

    .form-group {
      width: 25%;

      .form-control {
        width: 100% !important;
        padding: 0;
      }
    }
  }

  .verify-mobile-group {
    .single-select {
      .os__control {
        &.os__control--is-focused {
          box-shadow: none !important;
        }
      }
    }
  }

  .os-btn-link.resend-code-link {
    font-size: 12px;
    background: none;
    border: 0;
    box-shadow: none;
    color: $level-10;
    font-weight: $font-weight-semibold;
  }
}

.show--password {
  position: relative;

  .ifill-os-hide-password,
  .ifill-os-views  {
    position: absolute;
    right: 16px;
    top: 44px;
    cursor: pointer;
    color: $level-8;
  }
}

.form-error {
  line-height: normal;

  .os-btn-link {
    font-size: inherit;
  }
}

.ifill-os-fill-error  {
  color: $lt-red;
}

.otp-error-group {
  position: relative;

  .code-hint-text {
    position: absolute;
    bottom: 6px;
    left: 0;
    display: flex;
    width: 100%;
  }

  i {
    order: -1;
  }

  span:empty + i {
    display: none;
  }
}

input::-webkit-credentials-auto-fill-button {
  margin-right: 5px;
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-caps-lock-indicator {
  background-color: $level-8 !important;
}
