%note-card-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include fontstyle(12px, normal, 1.33);
  text-transform: uppercase;
  color: $level-11;
  margin-bottom: 8px;

  i{
    font-size: 16px;
  }

  .left-note-col, .right-note-col {
    display: flex;
    align-items: center;
    color: $level-11;
  }

  .note-type-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
  }
}

%note-card-images {
  border-radius: $card-border-radius;
  display: flex;
  align-items: center;
  margin: 16px -12px;

  .note-img-col {
    padding: 0px 12px;
    border-radius: 4px;
  }

  .note-img {
    min-width: 88px;
    max-width: 88px;
    height: 72px;
    @include flex-center();
    position: relative;


    .overlay-span {
      @extend %overlay-span;
      border-radius: 4px;
    }

    img{
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}


/*big card and min-width card */
%os-notes-card {
  @extend %os-card-base;
  display: block;

  .note-card-head{
    @extend %note-card-head;
  }

  .note-card-detail {
    @include fontstyle(16px, normal, 1.5);
    max-height: 195px;
    @include multiline-truncate(8);
    overflow: hidden;
    word-break: break-word;
  }

  .note-card-images {
    @extend %note-card-images;
  }

  .note-edit-time{
    @include fontstyle(12px, normal, 1.4);
    color: $level-11;
    text-transform: lowercase;
  }

  .note-card-head {
    align-items: flex-start;

    .left-note-col {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &.with-photos {
    .note-card-detail {
      max-height: 96px;
      @include multiline-truncate(4);
    }
  }
}

.extra-small-note-title {
  font-size : 16px;
  text-transform: none;
  color: #e7e7ea;
}

.os-lg-note {
  @extend %os-lg-card;
  @extend %os-notes-card;
  padding: $card-gutter;

  .a-link {
    font-size: 16px;
  }

  &.note-304 {
    padding: 24px 16px;
    @extend %os-304;

    .note-card-images {
      margin: 16px -8px;

      .note-img-col {
        padding: 0px 8px;
      }

      .note-img {
        min-width: 80px;
        max-width: 80px;
        height: 72px;
      }
    }
  }
}

@media screen and (min-width: 1441px) {
  .os-lg-note {
    .note-card-head {
      .note-type-text {
        max-width: 70px;
      }
    }

    pre .a-link {
      font-size: 16px;
    }

    .note-card-images {
      .note-img {
        min-width: 72px;
        max-width: 72px;
      }
    }
  }
}


/*---small doc */
.os-sm-note {
  @extend %os-sm-card;
  @extend %os-notes-card;
  padding: 16px;

  .note-card-head{
    margin-bottom: 8px;
    .left-note-col, .right-note-col {
      color: rgba(255,255,255,0.54);
    }

    .right-note-col {
      text-transform: capitalize;
    }

    .note-type-text {
      max-width: 175px;
    }
  }

  .sm-note-title {
    @include text-truncate;
    max-width: 180px;
  }

  .note-card-detail {
    @include fontstyle(12px, normal, 1.33);
    max-height: 96px;
    @include multiline-truncate(5);
  }

  .note-card-images {
    .note-img {
      height: 88px;
    }
  }


  &.note-240 {
    @extend %os-240 ;

    .right-note-col {
      display: none;
    }

  }
}

.extra-small-min-card {
  .os-sm-note {
    @extend %os-extra-sm-card;

    .note-card-head {
      margin-bottom: 8px;
      text-transform: none;
    }

    .note-card-detail {
      font-size: 12px;
      line-height: 1.33;
      max-height: 32px;
      @include multiline-truncate(2);
      color: #d4d4d9;

      .a-link {
        font-size: 12px;
        line-height: 1.33;
      }
    }

    pre {
      margin: 0;
    }

    .note-card-images {
      margin: 0 -12px;
    }

    .note-img {
      height: 32px;
      min-width: 32px;
      max-width: 32px;
    }

    .left-note-col {
      flex-direction: row;
      align-items: center;
    }

    .note-edit-time {
      padding-left: 10px;
      color: $level-11;
    }
  }
}
