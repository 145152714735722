.modal-dropzone-area {
  position: relative;
  height: inherit;
  border: dashed 1px $level-10;
  border-radius: 8px;
  text-align: center;
  width: 100%;

  .drag-text {
    @include fontstyle(16px, normal, 24px);
  }

  .filedrop-overlay {
    top: 0;
    bottom: 0;
    background: $modal-backdrop;
    padding: 0;
    overflow-y: auto;


    * {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently*/
    }
  }

  .filedrop-overlay-content {
    width: 100%;
    text-align: center;
  }

  input[type="file"] {
    width: 100%;
  }
}

.dropzone-instruction {
  position: relative;
  height: 100%;
  flex-direction: column;
  padding-bottom: 52px;
  overflow-y: auto;

  .file-finder-btn {
    margin-top: 50px;
    width: 150px;
    z-index: 1;
  }

  .small {
    color: #8c8c8c;
    padding-top: 5px;
  }
}

.file-status {
  font-size: 12px;
  color: #fff;
  white-space: normal;
  word-wrap: break-word;
  margin-top: 6px;
  line-height: normal;
  font-weight: 600;
  position: relative;

  .file-uploading {
    color: $file-uploading;
    display: flex;
    align-items: center;
  }

  .file-upload-error {
    color: $file-uploading-error;
  }

  .file-upload-successfull {
    color: $file-uploading-successfull;
  }

  [class^="icon-"] {
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 2px;
  }
}

.elem-rotate {
  -webkit-animation: rotation 2s linear infinite;
  display: inline-block;
}

@-moz-keyframes rotation {
  100% { -moz-transform: rotate(360deg);}
}
@-webkit-keyframes rotation {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes rotation {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.dropzone-files-present {
  .dropzone-instruction {
    padding-bottom: 20px;

    .active-blue  {
      margin-top: 25px;
    }
  }

  .file-ext-icon {
    font-size: 20px;
  }

  .card-close-link {
    &.ifill-os-close {
      font-size: 16px;
      line-height: 22px;
      right: -1px;
      top: 0px;
      width: 23px;
      height: 23px;
    }
  }
}

@media only screen and (max-width : 1000px) and (orientation: landscape) {
  .dropzone-instruction {
    font-size: 13px;

    .active-blue {
      margin-top: 0;
    }

    .small {
      top: 80%;
    }
  }

  .dropzone-files-present {
    .dropzone-instruction {
      padding-top: 5px;

      .active-blue {
        margin-top: 0;
      }
    }

    p {
      margin-bottom: 10px;
      line-height: normal;
    }

    .file-ext-icon {
      font-size: 12px;
    }
  }
}

.dropzone-drag-uploader-container {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1e-05;
  position: absolute;
  outline: 0;

  &.opacity-1 {
    opacity: 1;
  }

  &.dropzone-drag-active {
    opacity: 1;
    z-index: 2 !important;

     .filedrop-overlay {
        z-index: 1;
      }
  }
}

.conversation-input-section {
  .dropzone-drag-uploader-container {
    z-index: 1;
  }
}

.back-nav-bar {
  height: 48px;
  margin: 0 0 16px;
  position: sticky;
  display: flex;
  top: 0;
  background: $block;
  box-shadow: $box-shadow;
  z-index: 9;

  .back-nav-btn {
    position: absolute;
    left: 0;
    top: calc(50% - 20px) ;
    max-width: 100%;
  }

  .back-page-title {
    margin: auto;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
  }
}

.no-touch-srupport {
  .back-nav-bar {
    margin-left: 0;
    margin-right: 0;
  }
}

.dropzone-active {

  .space-create-dropzone {
    border-color: $os-link;
  }

  .svg-icon {
    transform: scale(1.1);
  }
}

.space-create-dropzone {
  height: 170px;
  overflow: hidden;
  border-radius: 8px;

  .header-mask {
    opacity: .7;
    background: $level-2;
    border-radius: 8px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .space-dropzone-hero {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .svg-icon  {
    margin: 0;
    transition: transform 500ms ease;
    width: 160px;
    height: 160px;
  }

  .dropzone-instruction {
    height: unset;
    padding-bottom: 0;
    margin-bottom: 28px;
    display: flex;

    span {
      + span {
        margin-left: 4px;
      }
    }

    .file-finder-btn {
      position: relative;
    }
  }

  .case-instruction-ul {
    margin: 0 auto;
    max-width: 411px;
  }

  .dropzone-change-image-btn {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  .dropzone-remove-image-btn {
    position: absolute;
    top: 0;
    right: -10px;
  }
}

@media(min-width: 1024px) {
  .space-create-dropzone {
    .dropzone-instruction {
      margin-bottom: 24px;
      span {
        display: block;
        + span {
          margin-left: 0;
        }
      }
    }

    .dropzone-change-image-btn {
      bottom: 12px;
    }
  }
}

.desktop-small,
.ipad.landscape {
  .space-create-dropzone {
    .dropzone-instruction {
      margin-bottom: 16px;
    }

    .dropzone-change-image-btn {
      bottom: 24px;
    }
  }
}

/* for ipad*/
@media(max-width: 992px) {
  .space-create-dropzone {

    .dropzone-instruction {
      margin-bottom: 8px;

      .tb-portrait {
        display: none;
      }
    }

    .dropzone-change-image-btn {
      bottom: 24px;
    }
  }
}

.ipad {
  &.portrait {
    .space-create-dropzone {

      .dropzone-instruction {
        margin-bottom: 8px;
      }

      .dropzone-change-image-btn {
        bottom: 24px;
      }

      .tb-portrait {
        display: none;
      }
    }
  }
}
/* ipad ends*/

@media(max-width: 767px) {
  .uploaded-image-block-holder {
    margin-left: -12px;
    margin-right: -12px;
    margin-top: 12px;
  }

  .space-create-dropzone {
    padding-bottom: 12px;
    border: 0;

    .dropzone-instruction {
      margin-bottom: 8px;
    }

    .case-instruction-ul {
      text-align: center;
      max-width: calc(100% - 16px);
    }

    .dropzone-change-image-btn {
      bottom: 8px;
    }
  }
}
