.header-with-clv {
  .space-sub-header {
    margin-bottom: 0;
  }

  .lecture-loader {
    display: flex;

    .ifill-os-play {
      margin: auto;
    }
  }

  .no-preview-text {
    width: 100%;
    height: 100%;
    padding-top: 150px;

    @media(max-width: 767px) {
      padding: 20px 0 10px;
    }
  }

  .space-header-name {
    line-height: 1.14;
    color: $heading;
  }

  > .container-fluid,
  .space-header-info-group {
    background: #1c1c1f;
  }

  .space-header-info-group {
    padding: 22px 0  0;

    .fullscreen-button {
      display: none;
    }

    .new-cs-card {
      padding-top: 0;
    }
  }

  .new-cs-card {
    box-shadow: none;
  }

  > .container-fluid,
  .clv-row {
    padding-left: 3.33%;
    padding-right: 3.33%;
  }
  .clv-row {
    .new-cs-card {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .col-md-8 {
    .col-md-8 {
      min-width: 100%;
      flex: 100%;
      padding: 0;
    }
  }

  &.non-case {
    > .container-fluid {
        padding-left: 3.61%;
        padding-right: 3.61%;
      }

    .create-slider {
      height: 50vh !important;
      min-height: 520px;

      .main-slider {
        min-height: 100%;
        height: 100%;

        .case-video,
        .slick-slide,
        .slick-list,
        .slick-track,
        .slide-video-holder {
          height: 100%;
        }
      }
    }

    .case-video {
      padding-bottom: 0;
      height: 100%;
    }

    .space-header-info-group {
      .accordion-listing {
        max-height: 50vh;
        min-height: 520px;
      }
    }
  }

  .header-title-row + .btn-with-title-bar {
    margin-top: 0;
  }

  .course-price-lg {
    line-height: 28px;
    font-size: 24px;
  }

  .space-header-right {
    .osbtn:not(.card-btn-more) {
      min-width: 160px;
      @media(min-width: 1440px) {
        min-width: 234px;
      }
    }
  }

  .ce-credit-btn,
  .course-duration,
  .reload-complete {
    font-size: 16px;
    line-height: 1;

    i {
      font-size: inherit;
    }
  }

  .new-cs-card {
    .case-timeline-right-section {
      margin-top: 12px;
      padding-bottom: 45px;

      .submit-right-container:first-child {
        margin-top: 3px;
      }
    }
  }

  .back-to-curriculum {
    margin-bottom: 12px;
    color: #6c6c6f;
    margin-left: -12px;
    padding: 0 16px 0 28px;

    .btn-icon-helper {
      left: 8px;
    }
  }

  .case-timeline-right-section,
  #clv-right-section-container {
    .loader-active {
      height: 80px !important;
      transform: scale(0.5);
      min-height: 80px !important;

      .loader-inner {
        margin-top: -10px;
      }
    }
  }
  ~ .tab-min-height {
    min-height: unset;
  }
}

.non-clv-header {
  .cs-user-info {
    @include text-truncate();
    display: inline-block !important;
    width: 100%;
  }
}

.space-header-info-group {
  .cs-header-btn-group {
    position: absolute;
    right: 0;
  }
  .accordion-listing {
    overflow-y: auto;
    max-height: 100%;
    min-height: 200px;

    h2 {
      margin: 0 0 8px;
      padding: 0 0 0 0;
      font-size: 20px;
      line-height: 1.4;
      color: $heading;
    }

    .course-trailer-btn {
      padding: 12px 20px 12px 16px;
      min-height: 48px;
      font-size: 12px;
      cursor: pointer;
      border: none;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      background: #222226;
      border-radius: 4px;
      color: $level-11;
      text-transform: uppercase;

      &.active {
        background: $block;
      }

      .trailer-icon {
        width: 24px;
        height: 24px;
        background: $cl-link;
        color: #fff;
        line-height: 24px;
        max-height: 24px;
        border-radius: 24px;
        margin-right: 8px;
        position: relative;

        i {
          @include centerItem;
          margin-left: 1px;
        }
      }
    }

    > .accordion .new-clv-card:only-child .card {
      /*background: #222226;*/
    }

    > .accordion > .card > .card-header {
      background: #222226;
      border-radius: 4px;

      + .collapse {
          border-radius: 4px;
          .accordion {
            padding-top: 8px;
            background: #1c1c1f;
          }
      }
    }

    .card {
      background: none;
      border-radius: 4px;
      border: none;
    }

    .content-not-accessible {
      background: #222226;
      padding: 12px 20px 12px 16px;
      min-height: 48px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      letter-spacing: 0.5px;
      margin-bottom: 0;
      color: #e7e7ea;
      margin: 0;
      border-radius: 4px;
    }

    .records-container {
      .card {
        background: #222226;
        position: relative;
        overflow: unset;

        &:before {
          position: absolute;
          height: 8px;
          width: 100%;
          left: 0;
          top: -8px;
          background: #1c1c1f;
        }

        + .card {
          margin-top: 8px;
        }
      }

      .card-z-index {
        z-index: 4;
      }
    }

    .card-header {
      padding: 12px 20px 12px 16px;
      min-height: 48px;
      font-size: 12px;
      cursor: pointer;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      letter-spacing: 0.5px;
      margin-bottom: 0;
      color: #e7e7ea;
      text-transform: uppercase;
      background: transparent;
      margin: 0;

      &:after {
        @include osIcon;
        content: "\e903";
        font-size: 16px;
        opacity: 0.5;
        transform: rotate(-90deg);
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }

      &.open {
        &:after {
          opacity: 1;
          transform: rotate(0);
        }
      }
    }

    .card-body {
      padding: 0;
    }

    .card-toc {
      display: flex;
      font-size: 12px;
      line-height: 24px;

      &.without-toc-content {
        padding-bottom: 20px;
      }
    }

    .card-info {
      justify-content: center;

      .toc-heading {
        margin-bottom: 0;
      }
    }

    .toc-list-item {
      padding: 20px 16px 0;
      cursor: pointer;
      color: $level-11;

      &:hover {
        color: #fff;
      }

      + .toc-list-item {
        padding-top: 0;

        .card-toc {
          padding-top: 24px;
          border-top:  solid 1px #303036;
        }
      }
    }

    .toc-number {
      height: 24px;
      min-width: 24px;
      background: #29292e;
      color: #fff;
      text-align: center;
      border-radius: 20px;
    }

    .toc-heading {
      padding: 0 8px;
      word-break: break-word !important;
    }

    .toc-time {
      white-space: nowrap;
      color: $level-11;
      @include fontstyle(12px, normal, 16px);
      display: inline-block;
      float: left;
    }

    .toc-content {
      padding: 8px 0 24px 32px;
    }

    .card-info {
      &.without-desc-duration {
        .toc-heading {
          margin: auto 0;
        }
      }
    }
  }
}

.new-clv-card:not(.d-none) {
  + .new-clv-card {
      padding-top: 27px;
  }

  .header-dropdown-menu {
    .header-dropdown-list {
      background: #303036;
    }
  }
}

.ipad {
  &.portrait {
    .header-with-clv {
      .new-cs-card .case-timeline-right-section {
        padding-bottom: 0;
      }
    }

    .new-cs-card {
      position: relative;
    }

    .btn-with-title-bar {
      padding-top: 16px;
      padding-bottom: 24px;
    }
  }
}


.clvtransgif {
  display: none;
}

.case-ratio,
.clv-case-ratio {
  position: relative;
  min-height: 600px;

  @media(max-width: 767px) {
    min-height: unset;

    .clv-row {
      position: relative;
    }

    .clvtransgif {
      display: none;
    }
  }

  .transgif,
  .clvtransgif {
    max-width: 100%;
    width: 100%;
    display: block;

    @media(max-width: 767px) {
      display: none;
    }
  }
}

.clv-case-ratio {
  .clv-row {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    max-height: 100%;
    border-radius: 0;

    iframe {
      border: 0;
      border-radius: 4px;
      height: 98%;

      &[title='Spotify'] {
        margin: 0px auto;
        display: block;
        margin-top: 150px;
      }
    }

    > .col-md-8,
    > .col-md-4 {
      height: 100%;
    }

    > .col-md-8 {
      position: relative;

        > .btn-view-only {
          width: 100%;
          height: 100%;
          text-align: center;
          padding-top: 150px;
        }

      .create-slider {
        height: calc(100% - 90px);

        .main-slider {
          min-height: 100%;
          height: 100%;

          .case-video,
          .slick-slide,
          .slick-list,
          .slick-track,
          .slide-video-holder {
            height: 100%;
          }
        }
      }

      .case-video {
        padding-bottom: 0;
        height: calc(100% - 28px);
        border-radius: 4px;
      }
    }
  }

  .case-ratio {
    height: 100%;
    min-height: unset;

    .case-details-video-section {
      padding-right: 0;
    }

    .transgif {
      display: none;
    }

    .new-cs-card {
      position: relative;
    }
  }
}

.ipad {
  &.portrait {
    .new-cs-card {
      position: static;
      height: auto;
    }

    .clvtransgif {
      display: none;
    }

    .clv-row {
      position: relative;
      padding-left: 0;
      padding-right: 0;

      .col-md-8,
      .col-md-4 {
        max-width: 100%;
        flex: 100%;
        height: auto;
        padding: 0;
      }

      .col-md-8 {
        min-height: 522px;
        height: 850px;
        /*padding-bottom: 120px;*/
        margin-bottom: 24px;
      }

      > .col-md-8 {
        .create-slider {
          height: 100%;
          padding: 0 1.5rem;
        }

        .clv-attachement-title + .create-slider {
          height: calc(100% - 36px);
        }

        > .btn-view-only {
          padding-top: 330px;
        }

        iframe {
          &[title='Spotify'] {
            margin-top: 250px;
          }
        }
      }
    }

    .space-header-info-group {
      background: none;
      padding-top: 0;

      .accordion-listing {
        max-height: 500px;
        padding: 0 1.5rem;
        margin-top: 0;
        margin-bottom: 0;

        > .accordion .card:only-child {
          background-color: transparent;
        }

        > .accordion > .card > .card-header {
          + .collapse {
              .accordion {
                background: transparent;
              }
          }
        }
      }
    }

    .course-key-container {
      background: none;
      padding-bottom: 30px;
      margin-bottom: 16px;
      padding-top: 38px;
    }

    .course-key-listing {
      padding-top: 0;
    }

    .clv-case-ratio {
      .cs-treatment-sec,
      .new-cs-card {
        margin-bottom: 0;

      }

      .cd-detail-conatiner {
        padding-top: 0;
      }

      .case-card-detail-header {
        padding-right: 24px;
      }
    }
  }

  &.landscape {
    .case-ratio,
    .clv-case-ratio {
      min-height: 700px;
    }

    .clv-right-section {
      .insight-tab-text {
        margin-right: 12px;
      }

      .case-right-header {
        justify-content: space-between;
        display: flex;
      }

      .header-dropdown-menu {
        margin-left: 0px !important;
      }
    }
  }
}


.mobile-device {
  .header-with-clv {
    margin-bottom: 20px;

    > .container-fluid {
      padding-left: 16px;
      padding-right: 16px;

      .space-sub-header {
        padding-left: 0;
      }

      .space-header-name {
        font-size: 20px;
        line-height: 1.4;
      }
    }
  }

  .spaces {
    .header-title-row {
      padding-top: 24px;
    }
  }
  .space-header-info-group {
    .new-cs-card {
      .case-card-detail-header {
        .cs-user-info {
          @include  text-truncate();
        }

        .back-btn {
          margin-left: 20px;
          margin-right: 8px;
        }

        .cate + .detail-subheader {
          margin-left: 0;
        }

        .cs-id-user-name {
          .os-btn-link {
            line-height: 16px;
          }
        }
      }
    }
  }

  .header-with-clv,
  .clv-case-ratio {
    .space-header-info-group {
      padding-top: 20px;
    }

    .r-p-action:empty {
      display: none;

      + .course-complete-bar {
          margin-top: 10px;
        }
    }

    .back-to-curriculum {
      /*display: none;*/
    }

    .new-cs-card {
      .case-card-detail-header {
        display: flex;
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
        align-items: center;

        .indicator-badge {
          font-size: 8px;
        }

        .back-btn {
          margin-right: 0;
        }

        .cs-header-btn-group {
          right: 48px;
          top: 20px;
          max-width: 48px;
          padding-right:  88px!important;

          &.full-width-header {
            max-width: calc(100% - 104px);
          }
        }
      }

      .sbs-spin-img-wrap {
        margin-top: 0;
      }

      .case-timeline-right-section {
        padding-bottom: 0;
      }
    }
  }

  .space-header-info-group {
    .cs-header-btn-group {
      right: 8px;
    }
  }

  .case-ratio,
  .clv-case-ratio {
    position: relative;
    min-height: unset;
  }

  .clv-case-ratio {
    position: relative;
    min-height: unset;

    .clv-row {
      position: relative;
      display: block;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 5px;

      > .col-md-4,
      > .col-md-8 {
        padding: 0;
      }

      > .col-md-4 {
        height: auto;
      }

      > .col-md-8 {
        min-height: 422px;
        @include sticky;
        top: 0;
        z-index: 9;
        background: #1c1c1f;

        > .create-slider {
          height: 422px;
          margin: 0;
          width: 100%;
        }

        > .case-video {
          height: 394px;
        }
      }
    }

    .accordion-listing {
      margin-bottom: 12px;
    }

    .clvtransgif {
      display: none;
    }
  }

  .space-header-info-group {
    .card-header {
      line-height: 24px;
    }
  }

  .space-header-info-group {
    .case-card-detail-header {
      line-height: 1.33;

      &.back-btn-available {
        margin-left: -15px;
      }
    }

    .cate {
      margin-right: 5px;
    }

    .detail-subheader {
      margin-left: 0;
    }
  }
}

.non-clv-header {
  .header-title-row {
    padding-top: 16px;
  }

  .btn-with-title-bar {
    min-height: unset;
    margin-top: 16px;
    font-size: 12px;

    .span-helper {
      line-height: 1.33;
    }

    .a-link {
      font-size: 12px;
    }

    .user-name {
      font-weight: normal !important;
    }
  }

  ~ .sticky-tab-with-dropdown {
    margin-top: 16px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  opacity: 0.5;
}
.lds-ellipsis div {
  position: absolute;
  top: 10px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 1s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 1s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 1s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 40px;
  animation: lds-ellipsis3 1s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(16px, 0);
  }
}

.clv-right-section {
  &:not(.col-md-4) {
    width: 100%;
  }

  &.withtab-slide {
    overflow: hidden;
  }

  .col-md-4 {
    padding: 0;
    width: 100%;
    max-width: 100%;
    height: calc(100% - 68px);
    margin-top: 12px;
  }


  .clv-right-slide {
    height: 100%;
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-wrap: nowrap;

    > .clv-right-tabs-section,
    > .col-md-4 {
      height: 100%;
      display: block !important;
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      transition: all 200ms ease;
    }

    .clv-right-tabs-section {
    }

    @media(min-width: 768px) {
      h2 {
        position: sticky;
        background: #1c1c1f;
        z-index: 2;
        top: 0;
      }
    }
  }

  .case-right-header {
    + .col-md-4 {
      height: calc(100% - 40px);
      .accordion-listing {
        max-height: calc(100% - 10px);
      }
    }
  }


  &.clv-slide-1 {
    padding-left: 0;

    .clv-right-slide {
      > .col-md-4 {
        transform: translateX(-102%);
      }

      > .clv-right-tabs-section  {
        transform: translateX(-100%);
      }
    }

    .case-timeline-right-section {
      padding-left: 12px;
    }
  }

  .case-right-landing-header {
    > .cs-right-dropdown {
      right: 0;
    }
  }

  &.with-credit-btn {
    .clv-right-slide {
     height: calc(100% - 28px);

      .accordion-listing {
        max-height: 100%;
        height: 100%;
      }
    }
  }
}


.no-touch-support,
.ipad.landscape {
  .clv-row {
    &.lecture {
      .clv-right-section {
        &.with-credit-btn {
          padding-bottom: 46px;
        }
      }

      .clv-attachement-title + .create-slider {
        height: calc(100% - 90px);
      }
    }
  }
}

.clv-attachement-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 8px;
  color: $heading;
}

.credit-btn-col {
  margin-top: 6px;
  margin-bottom: 10px;

  @media(min-width: 768px) {
    margin-top: 14px;
    margin-bottom: 0;
  }

  .osbtn {
    display: block;
  }
}


.ipad {
  &.portrait {
    .clv-right-section {
      .clv-right-slide {
        width: calc(100% - 3rem);
        margin: auto;

        .accordion-listing {
          padding: 0;
        }

        .case-timeline-right-section {
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-top: 0;
        }
      }

      .back-to-curriculum {
        margin-left: 0;
      }

      .case-right-header {
        padding: 0 24px;
      }
    }

    .credit-btn-col {
      padding: 0 1.5rem;
    }

    .clv-row {
      .case-card-detail-header {
        .cs-right-dropdown {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  .clv-row {
    .case-card-detail-header {
      .cs-right-dropdown {
        display: none;
      }
    }
  }
}

.desktop-small {
  &.workspace {
    .case-right-header {
      justify-content: space-between;
      display: flex;

      .header-dropdown-menu {
        margin-left: 0 !important;
      }
    }
    .insight-tab-text {
      margin-right: 24px;

      @media screen and (min-width: 1200px) {
        margin-right: 34px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.permission-denied-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .permission-denied-image {
    max-width: 550px;
    margin-bottom: 10px;

    + p {
      font-size: 16px;
      color: $level-11;
      max-width: 200px;
    }

    @media screen and (max-width: 767px) {
      max-width: 300px;
    }
  }
}

.comingsoon-image {
  max-width: 304px;
  max-height: 90%;

  @media screen and (max-width: 767px) {
    max-height: 65%;
  } 

  + p {
    @extend %body-small-1-5;
    color: $level-9;
    max-width: 200px;
    margin-bottom: 8px;
  }
}

.permission-denied-view {
  ~ .clv-right-section {
    display: none;
  }

  .permisson-denied-img {
    width: 628px;
    max-width: 100%;
  }

  .permisson-denied-text {
    max-width: 206px;
    margin: 0 auto 20px;

    p {
      margin: 10px 0 16px;
      color: $level-11;
    }
  }
}

.ipad.portrait {
  .clv-permission-denied {
    background: #1c1c1f;
  }
}

.ipad.portrait,
.mobile-device {
  .clv-insight-tab-header {
    .insight-tab-text:first-child {
      .tab-link-tooltip {
        left: 0;
        transform: translate(0);

        .tooltip-arrow-top {
          left: 30px;
        }
      }
    }
  }
}

.clv-right-section + .footer-not-available {
  display: block;
  height: 80px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #222226;
}
