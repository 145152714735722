
.new-cs-card {
  &.with-phase-btn-show {
    margin-bottom: 120px;

    &.animation-hovered {
      margin-bottom: 150px;
    }
  }
}

.cs-footer {
  min-height: 48px;
  height: 80px;
  padding: 8px 3.61% 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  left:0;
  transition: height 200ms ease, bottom 200ms ease;
  will-change: height, bottom;

  &:before {
    content: '';
    position: absolute;
    height: 80px;
    background: transparent;
    left: 0;
    right: 0;
    bottom: 0;
    transition: height 200ms ease;
    will-change: height;
    z-index: 1;
  }

  label {
    color: $level-9;
    @include fontstyle(12px, normal, 16px);
    margin-right: 16px;
    margin-bottom: 0;
  }

  .osbtn[icon="chevron-right"]:only-child {
    margin-left: auto;
  }

 /* &:empty {
    display: none;
  }*/

  .phase-btn {
    margin-left: -12px;
    z-index: 2;
    position: absolute;
    left: 3.61%;
    bottom: 50%;
    bottom: auto;
    transform: translateY(-50%);
    padding-right: 12px;
    font-size: 20px;
    border-color: transparent !important;

    &.righticon {
      padding-left: 12px !important;
      padding-right: 32px !important;
      margin-left: 0;
      margin-right: -12px;
      left:auto;
      right: 3.61%;

      .righticon {
        right: 8px;
      }

      .button-tooltip {
        margin-left: 0;
        left: auto;
        right: 0;
        transform: none;

        &:before {
          left: auto;
          margin-left: 0;
          right: 22px;
        }
      }
    }

    .button-tooltip {
      top: auto;
      bottom: calc(100% + 7px);
      width: auto;
      margin: 0;
      white-space: nowrap;
      left: 0;
      text-align: left;
      transform: none;
      margin-left: 0;

      &::before {
        content: none;
        left: 22px;
        top: 100%;
        border-left: solid 6px transparent;
        border-top: solid 6px transparent;
        border-right: solid 6px transparent;
        border-top: solid 6px $level-1;
      }
    }

    &:hover {
      .button-tooltip {
        display: block;
      }
    }
  }
}

/*----height--------*/
.desktop-hd,
.desktop {
  .cs-footer {
    &.appointment-hovered {
      height: 105px;

      .cs-records-btn-groups {
        height: 100px;
      }
    }

    &.with-phase-btn {
      height: 120px;
      bottom: -120px;

      &:before {
        height: 120px;
      }

      &.appointment-hovered {
        height: 150px;
        bottom: -150px;

        &:before {
          height: 150px;
        }
      }
    }
  }
}

.desktop-small {
  .new-cs-card {
    &.with-phase-btn-show {
      margin-bottom: 150px;

      &.animation-hovered {
        margin-bottom: 150px;
      }
    }
  }

  .cs-footer {
    &.appointment-hovered {
      height: 105px;

      .cs-records-btn-groups {
        height: 100px;
      }
    }

    &.with-phase-btn {
      height: 120px;
      bottom: -120px;

      &:before {
        height: 120px;
      }

      &.appointment-hovered {
        height: 150px;
        bottom: -150px;

        &:before {
          height: 150px;
        }

      }
    }
  }
}

.ipad {
  &.landscape {
    .new-cs-card {
      &.with-phase-btn-show {
        margin-bottom: 150px;

        &.animation-hovered {
          margin-bottom: 150px;
        }
      }
    }

    .cs-footer {
      &.appointment-hovered {
        height: 110px;

        .cs-records-btn-groups {
          height: 100px;
        }

      }

      &.with-phase-btn {
        height: 120px;
        bottom: -120px;

        &:before {
          height: 120px;
        }

        &.appointment-hovered {
          height: 150px;
          bottom: -150px;

          &:before {
            height: 150px;
          }

        }
      }
    }
  }

  &.portrait {
    .cs-footer {
      padding: 8px 16px;
      height: 96px;
      bottom: 0;

      &:before {
        height: 96px;
      }

      &.appointment-hovered {
        height: 130px;

        .cs-records-btn-groups {
          height: 130px;
        }
      }
    }
  }
}

.mobile-device {
  .cs-footer {
    &.appointment-hovered {
      height: 150px;

      .cs-records-btn-groups {
        height: 150px;
      }
    }
  }
}

.phase-btns-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
}


.cs-records-btn-group {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
  padding: 0;
  background: transparent;
}

.cs-records-btn-groups {
  display: flex;
  align-items: flex-start;;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  transition: height 200ms ease;
  will-change: height;

  .ifill-os-info {
    cursor: pointer;
    margin-left: 8px;
    z-index: 1;
  }

  &.overflow-x {
    overflow-x: auto;
  }
}

.full-width {
  .cs-records-btn-groups {
    max-width: calc(100% - 190px);
  }

  .phase-btn {
    font-size: 20px;
  }
}

.no-phase-btn {
  &.cs-footer {
    padding-left: 16px;
    padding-right: 16px;
  }

  .cs-records-btn-groups {
    max-width: 100% !important;
  }
}

.cs-record-btn {
  color: $level-7;
  &:not(.no-content) {
    z-index: 1;
  }

  &:hover {
    z-index: 2;
    background-color:  $appt-btn-active !important;
    color:  $appt-btn-active-icon !important ;
    text-decoration: none !important;
  }

  .button-tooltip {
    z-index: 3;
  }

  &.timeline-active {
    z-index: 1;
    background-color: $appt-btn-active !important;
    color: $appt-btn-active-icon !important;
  }

  &:not(.cs-index-count) {
    &.active {
      z-index: 1;
      background-color: $appt-btn-active !important;
      color: $appt-btn-active-icon !important;
    }
  }

  + .cs-record-btn {
    &:after {
      content: '';
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      height: 1px;
      width: 4px;
      right: 100%;
      background-color: $level-1;
    }

    &.no-content {
      &:after {
        right: 100%;
        width: 8px;
      }
    }

    &.no-content + .cs-record-btn:not(.no-content) {
      &:after {
        width: 17px;
      }
    }
  }
}

.cs-records-btn-groups {
  &.overflow-x {
    .cs-record-btn {
      &:first-child {
        .button-tooltip {
          left: 0;
          transform: none;

          &:before {
            left: 16px;
          }
        }
      }

      &:nth-last-child(2),
      &:last-child {
        .button-tooltip {
          left: auto;
          right: 0;
          transform: none;

          &:before {
            left: auto;
            right: 16px;
          }
        }
      }

      &:nth-last-child(3) {
        .button-tooltip {
          left: auto;
          right: 0;
          transform: none;

          &:before {
            left: auto;
            right: 16px;
          }
        }
      }

      &:nth-child(2) {
        .button-tooltip {
          margin-left: 0;
          left: 0;
          transform: none;

          &:before {
            left: 16px;
          }
        }
      }
    }
  }
}

.sbs-footer {
  .cs-record-btn-group {
    .timeline-active {
      background-color: $os-link;
      color: white;

      ~ .cs-record-btn {
          &:after {
            background: $level-1;
          }
      }

      ~ .timeline-active {
        ~ .cs-record-btn {
            color: $level-11;

            &:after {
              background: $level-1;
            }
          }
      }
    }
  }
}


.appt-blue-hover {
  font-size: 10px;
  line-height: 16px;
  color: $level-7;
  position: absolute;
  bottom: 8px;
  left: 0;
  background-color:rgba(235, 235, 235, 0.4);
  border-radius: 2px;
  text-align: center;
  z-index: 2;
}

.desktop-small,
.ipad.landscape {
  .cs-footer:not(.full-width) {
    .cs-records-btn-groups {
      max-width: calc(100% - 410px);
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  .cs-footer {
    .osbtn-icon {
      display: none;
    }
  }
}

.ipad {
  &.portrait {
    .full-width {
      .cs-records-btn-groups {
        max-width: 100%;
      }
    }

    .new-cs-card {
      .cs-records-btn-groups {
        margin-bottom: 8px;
      }

      .cs-records-btn-group {
        &:not(.count-btn) {
          height: 40px;
        }

        .cs-record-btn {
          &:not(.cs-index-count ) {
            width: 40px;
            height: 40px;
            line-height: 40px;
            padding-top: 2px;
          }

          &.cs-index-count {
            width: 40px;
            .button-tooltip {
              bottom: calc(100% + 55px);
            }
          }
        }
      }
    }
  }
}

.mobile-device  {
  .full-width {
    .cs-records-btn-groups {
      max-width: 100%;
    }
  }

  .cs-footer {
    padding: 0;
    background-color: transparent;
    height: 112px;
    bottom: 0;
    position: sticky;
    z-index: 99;
    padding-top: 8px;

    &:before {
      height: 112px;
      bottom: -1px;
      background-color: $level-2;
    }
  }

  .cs-record-btn {
    width: 48px;
    height: 48px;
    line-height: 48px;
    margin: 0 4px 0 0;
    padding-top: 2px;

    &.cs-index-count {
      line-height: 32px;
      height: 32px;

      .button-tooltip {
        bottom: calc(100% + 63px);
      }
    }
  }

  .cs-records-btn-groups {
    max-width: 100%;
    padding-bottom: 8px;
  }

  .cs-records-btn-group {
    &:not(.count-btn) {
      height: 48px;
    }

    &.count-btn {
      margin-top: 8px;
    }
  }

  .appt-blue-hover {
    line-height: 32px;
  }
}

.clv-page {
  .cs-footer {
    bottom: -70px;
    &:before {
      border-radius: 0 0 10px 10px;
    }
  }
}
