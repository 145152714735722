@media only screen and (min-width: 1024px) {
  .faq-container {
    max-width: 800px;
    margin: 0px auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.faq-container {
  .faq-heading {
    text-align: center;
    @include fontstyle(20px, 600, 28px);
    margin-bottom: 24px;
    margin-top: 24px;
    color: $heading;

    .faq-btn-m {
      display: none;
    }
  }

  .accordion {
    .card {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #5c5c66;
      padding: 0 40px;

      &-header {
        padding: 36px 0 32px 0px;
        @include fontstyle(16px, 600, 1);
        background-color: transparent;
        border:0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $secondary-heading;

        p {
          margin-bottom: 0;
        }

        .osbtn {
          margin-right: -12px;
        }
      }

      &-body {
        padding: 0 15px 32px 0px;
        font-weight: normal;
      }
    }
  }
}

.ipad {
  &.portrait {
    .faq-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.landscape {
    .faq-container {
      max-width: 800px;
    }
  }
}

.ipad.portrait,
.mobile-device {
  .spaces {
    .f-best-selling-course {
      .container {
        padding-right: 0;
      }
      .cards {
        overflow-x: auto;
        flex-wrap: nowrap;
        overflow-y: hidden;
        padding-left:0;
        padding-right: 0;

        .col-12 {
          margin-right: 24px;
          max-width: 328px;
          padding-right: 0;
        }
      }
    }
  }
}

.mobile-device {
  .faq-container {
    padding:24px 0 0  0;
    background-color: #1c1c1f;

    .accordion {
      .card {
        padding: 0 20px;
      }

      .card-header {
        align-items: flex-start;
        font-weight: normal;
        line-height: 24px;
        padding: 32px 0;
      }
    }
  }

  .faq-heading {
    margin: 0;
    padding: 24px 0;
    @include fontstyle(16px, 500, 16px);

    &:empty {
      padding: 0;
    }
  }


  .faq-btn-m {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    background-color: #1c1c1f;
    padding: 32px 0;
    text-align: center;
    vertical-align: middle;

    i {
      position: relative;
      left: 8px;
      right: 0;
      top: 3px;
      font-size: 16px;
    }
  }
}

.enroll-container {
  max-width: 800px;
  margin: 0px auto 120px;

  .enroll-heading {
    @include fontstyle(40px, bold, 48px);
    margin-bottom: 16px;
    color: $heading;
  }
}

.ipad {
  &.portrait {
    .enroll-container {
      margin-bottom: 80px;
    }
  }
}

.mobile-device {
  .enroll-container {
    margin-bottom: 80px;

    .enroll-heading {
      @include fontstyle(28px, bold, 32px);
      margin-bottom: 24px;
    }

    .osbtn {
      min-width: 275px;
      min-height: 40px;
      line-height: 40px;
    }
  }
}
