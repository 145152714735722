/*light theme*/
// font
$font-family-base: 'Inter', sans-serif;
$font-secondary: $font-family-base;

$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$font-weight-black: 900;
$link-weight : $font-weight-semibold;
$font-weight-light-roboto: 100;


$level-1: #fff;
$level-2: #f8f8f8;
$level-3: #f4f7f9;
$level-4: #eff1f4;
$level-5: #C9D1DA;
$level-6: #B9C4CF;
$level-7: #818d99;
$level-8: #7e91a5;
$level-9: #5d7893;
$level-10: #4A749A;
$level-11: #354554;
$level-12: #222B35;

$stripe-placeholder-color : #757575;
$primary-text: $level-11;
$lt-red-bg: #f5b3b5;
$lt-red: #df383e;
$lt-green-bg: #c7e9d6;
$lt-green: #2b9e5d;
$lt-notification-bg: #f5555a;
$lt-notification-text: $level-1;
$lt-orange: #f5555f;
$lt-yellow: #ffe4ae;
$lt-teal: #c2e5e8;
$lt-purple: #ddd4ef;
$lt-blue: #c1d7f7;
$lt-orange-bg: #ffc3ad;


$lt-error-bg: $lt-red-bg;
$lt-error-text: $level-11;
$lt-error-icon: $lt-red;
$lt-success-bg: $lt-green-bg;
$lt-success-text: $level-11;
$lt-success-icon: $lt-green;

$purple: #9b85cb;

//border
$border-button-1: #eff1f4;
$border-input-typed-button-2: $level-5;
$border-active: $level-10;

// Base
$body-bg: $level-2;
$body-color: $level-2;

//block
$block: $level-1;
$block-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
$block-shadow-m: 0 3px 6px 0 rgba(53, 69, 84, 0.1), 0 10px 20px 0 rgba(28, 50, 79, 0.15);
$block-shadow-l: 0 5px 10px 0 rgba(53, 69, 84, 0.05), 0 20px 25px 0 rgba(53, 69, 84, 0.2);
$block-xs: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);
$form-shadow: $block-shadow;

//Nav
$nav-bg: $level-1;
$nav-link: $level-6;
$nav-link-hover: $level-11;
$nav-link-icon: $level-9;
$nav-link-icon-hover: $level-9;
$nav-link-active: $level-11;
$nav-link-light: $level-6;
$mobile-dropdown-bg: $level-2;

//dropdown
$dropdown-bg: $level-1;
$dropdown-list-hover: $level-4;
$dropdown-text: $level-9;
$dropdown-icon: $level-8;
$dropdown-hover-text: $level-11;
$dropdown-icon-hover: $level-11;
$dropdown-shadow: $block-shadow;


//toggle
$toggle-bg: $level-3;
$toggle-bg-active: $level-1;
$toggle-after: $level-5;
$toggle-before: $level-4;
$toggle-after: $level-6;


$icon-hover-color: $level-11;
$icon-color: $level-8;



//input field
$input-bg: $level-1;
$input-text-color:  $level-11;
$input-border: $border-button-1;
$input-placeholder: $level-8;
$input-error: $lt-red;
$input-icon: $level-8;
$input-border-active: $border-active;
$input-hover-border: $border-active;
$label: $level-11;
$input-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);
$input-disabled: $level-3;
$input-disabled-text: $level-8;
$textarea-bg: $level-3;
$textarea-placeholder: $level-8;
$caret: $level-10;



//overlay
$overlay: rgba(235, 235, 235, 0.4);

//Modal
$modal-bg: $level-1;
$modal-backdrop: rgba(235, 235, 235, 0.4);
$modal-shadow: $block-shadow-m;

//footer
$footer-bg: $level-1;
$footer-text: $level-8;

//login
$login-bg: $level-1;

//block
$block: $level-1;
$block-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
$block-shadow-m: 0 3px 6px 0 rgba(53, 69, 84, 0.1), 0 10px 20px 0 rgba(28, 50, 79, 0.15);
$block-shadow-l: 0 5px 10px 0 rgba(53, 69, 84, 0.05), 0 20px 25px 0 rgba(53, 69, 84, 0.2);
$block-shadow-xs: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);

//message
$search-bg: $level-1;
$m-user-list-bg: $level-1;
$message-divider: $level-4;
$m-user-list-active: $level-4;
$talk-bubble: $level-1;
$talk-bubble-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);
$talk-bubble-own: $level-10;
$talk-bubble-own-border: $level-10;


$btn-primary : $level-11;
$btn-primary-hover: $level-10;
$btn-primary-color: $level-1;
$btn-hover-shadow: $block-shadow-xs;
$btn-primary-active: $level-12;
$btn-primary-disabled: $level-7;
$btn-primary-disabled-text: $level-5;

$btn-secondary : $level-11;
$btn-secondary-border: $border-input-typed-button-2;
$btn-secondary-hover: $level-10;
$btn-secondary-active: $level-12;
$btn-secondary-disabled: $level-6;
$btn-secondary-bg-active: $level-11;
$btn-secondary-bg-active-text: $level-1;


$btn-icon: $level-1;
$btn-icon-border: $border-input-typed-button-2;
$btn-icon-helper: $level-8;
$btn-icon-text: $level-11;
$btn-icon-hover: $level-10;
$btn-icon-active: $level-12;
$btn-icon-disabled: $level-6;

//xs active icon
$btn-i-xs-shadow: $block-shadow-xs;
$btn-i-xs-color: $level-12;
$btn-i-xs-bg: $level-5;

//xs hover icon
$btn-i-xs-bg-hover: $level-4;


$btn-tertiary: $level-9;
$btn-tertiary-icon: $level-8;
$btn-tertiary-hover: $level-10;
$btn-tertiary-active: $level-12;
$btn-tertiary-disabled: $level-6;


$os-link: $level-10;
$os-link-hover: $level-10;
$cl-link: $os-link;

$btn-link: $os-link;
$btn-link-hover: $os-link-hover;

$tab-active: $level-11;
$tab: $level-8;

$tag-bg: #ffc3ad;
$tag-hover-text: #974c31;
$tag-shadow: $block-shadow-xs;

$os-257: #e05257;

//scroll bar colors
$scrollBarThumb : $level-5;
$scrollBarBlueThumb: rgba(235, 235, 235, 0);


$video-tag: #3e3e45;

$cl-color:  (
    "l1" $level-1,
    "l2" $level-2,
    "l3" $level-3,
    "l4" $level-4,
    "l5" $level-5,
    "l6" $level-6,
    "l7" $level-7,
    "l8" $level-8,
    "l9" $level-9,
    "l10" $level-10,
    "l11" $level-11,
    "l12" $level-12,
    "red" $lt-red,
    "teal" $lt-teal,
    "yellow" $lt-yellow,
    "purple" $lt-purple,
    "blue" $lt-blue
    "orange" $lt-orange-bg,
    "green" $lt-green-bg,

);

.cl {
  @each $name, $color in $cl-color {
    &-#{$name} {
      color: $color;
    }
  }
}


$os-gold: #fcb947;
$os-platinum: #ffffff;
$os-trial: $os-link;


$joyride-color: $lt-orange;
$joyride-tooltip-color: $level-11;
$joyride-tooltip-bg-color: $block;
$joyride-skip-button-color: $level-11;

$os-gutter-space: 15px;

//case-mdium
$case-side-gutter : 1.25rem;

//modal
$modal-body-spacing : 24px;

$tissue-bg: rgba(48, 48, 54, 0.2);

//discussion-style
$discuss-textarea-color: rgba(255,255,255,.9);;
$discuss-textarea-placeholder: rgba(255,255,255,.6);
$discuss-textarea-attachment-icon : #cccccc;
$comment-discuss-heading-color: $discuss-textarea-color;
$comment-author-name: $discuss-textarea-color;
$filedset-error: rgba(255, 100, 124, .1);
$filedset-error-text: #cf667a;
$comment-footer-border: rgba(140, 140, 140, .4);
$comment-box-shadow: rgba(10, 10, 10, 0.26);
$comment-hide-link : $discuss-textarea-color;
$comments-dropdown-toggle: rgba(255,255,255,.9);
$commment-dropdown-hover: rgba(72, 172, 253, 0.2);
$comment-dropdown-bg: #3e3e45;
$comment-dropdown-shadow: rgba(140, 140, 140, 0.25);
$comment-dropdown-border: rgba(204, 204, 204, .2);
$policy-tab-border: $comment-dropdown-border;
$policy-btn-disable-text: #cccccc;


//file-upload
$file-uploading: $level-7;
$file-uploading-error: $lt-red;
$file-uploading-successfull : $lt-green;


$os-btn-disabled: #4c4c54;
$os-btn-border: #5c5c66;
$os-btn-disabled: #4c4c54;

$btn-ghost-color: $level-11;
$btn-ghost-border: $border-input-typed-button-2;
$os-secondry: $level-11;
$btn-ghost-hover-br: $level-10;
$btn-ghost-hover-color: $level-10;
$btn-ghost-active: $level-12;


//appointment button
$appt-btn: $level-1;
$appt-btn-2: rgba(235, 235, 235, 0.2);
$appt-text:  $level-11;
$appt-icon:  $level-11;
$appt-btn-hover: $level-1;
$appt-btn-hover-icon: $level-11;
$appt-btn-active:  $level-11;
$appt-btn-active-icon: $level-1;

$cs-btn: $level-1;
$cs-btn-hover: $level-11;
$cs-btn-hover-text: $level-1;


$badge-red: $lt-red-bg;
$badge-orange: #ffc3ad;
$badge-yellow: #ffe4ae;

$caret-arrow-border: #e3e4e6;
$notification-carret: #f0f0f5;
