.error__wrapper {
  position: relative;
  padding-top: 0;

  @media (min-width: 1440px) {
    max-height: 100vh;
    overflow: hidden;
  }

  .error-img-wrap {
    width: 100%;
    pointer-events: none;

    .svg-icon,
    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .error__heading_info {
    padding-top: 92px;
    color: $heading;

    h1 {
      @include fontstyle(40px, 900, 48px);
      margin-bottom: 32px;
    }

    p {
      @include fontstyle(20px, normal, 32px);
      color: $heading;
      margin-bottom: 32px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding-top: 60px;
    }

    @media (max-width: 767px) {
      padding-top: 16px;
    }

  }

  .navbar-brand {
    max-width: 225px;
    display: block;

    .logo {
      img {
        max-width: 100%;
      }
    }
  }
}

.error-page {
  body {
    padding-top: 0px !important;
  }
}

.mobile-device {
  .m-column-reverse {
    flex-direction: column-reverse;

  }

  .error-img-wrap {
    margin-top: 24px;
  }
}
