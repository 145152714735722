.modal {
  &:has(.task-preview-modal) {
    overflow-y: hidden;
  }
}

.task-peview-container {
  display: flex;
  flex-direction: column;
}

.not-modal {
  margin: 0 auto;
  margin-top: 48px;
  box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12),
    0 3px 6px 0 rgba(53, 69, 84, 0.15);
  border-radius: 16px;
  max-width: 1080px !important;
  background-color: #fff;
  position: relative;

  &:has(.task-preview-activity) {
    .task-preview-entity {
      box-shadow: 0 3px 6px rgba(53, 69, 84, 0.15),
        0 2px 4px rgba(53, 69, 84, 0.12);
    }
  }

  &.is-completed {
    background: #f4f7f9 !important;
  }
}

.task-wrapper {
  margin-bottom: 8px;
  position: relative;

  .not-modal {
    margin: 16px 0;
    width: 100%;
    max-width: 100% !important;
    box-shadow: 0 1px 3px rgba(53, 69, 84, 0.12),
      0 1px 2px rgba(53, 69, 84, 0.24);

    .task-preview-entity {
      &.no-activity {
        margin-bottom: 0;
        box-shadow: none !important;
      }
    }
    .task-preview-activity {
      min-height: 160px;
      max-height: 160px;
      &.no-activity {
        height: 0px;
        min-height: 0px;
      }
    }
  }
}

.task-preview-modal {
  max-width: 904px !important;
  margin: 2rem auto !important;
  max-height: calc(100vh - 60px);
  height: 100%;

  .modal-content {
    padding: 0;
    overflow: auto;
    height: 100%;
  }
  .modal-content > div {
    height: 100%;
  }

  .show-more-activities {
    display: none;
  }

  .task-preview-activity {
    min-height: 256px;
    height: 100%;
    .activity-container {
      min-height: 482px;
      max-height: 100%;
    }
  }
}

.task-preview-entity {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 8px;
  margin-bottom: 4px;
  cursor: pointer;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 3px 6px rgba(53, 69, 84, 0.15), 0 2px 4px rgba(53, 69, 84, 0.12);
  flex-grow: 0;

  &.is-modal {
    cursor: default;
  }

  &.is-collapsed {
    box-shadow: none;
  }

  .entity-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .taskInformation {
      align-items: center;
      max-width: calc(100% - 90px);

      .taskTitleAndDueDate {
        align-items: center;
        max-width: calc(100% - 32px);
        width: 100%;
        .title-conatiner {
          display: flex;
          gap: 8px;
        }
      }
    }

    .task-preview-action {
      display: flex;
      gap: 8px;
      flex: 1;
      position: absolute;
      right: 32px;
      top: 16px;

      .d-inline-block {
        .comment-more {
          margin-top: 0px;
        }
      }

      .header-dropdown-list {
        box-shadow: 0 3px 6px rgba(53, 69, 84, 0.1),
          0 10px 20px rgba(28, 50, 79, 0.15) !important;
      }
      .task-chevron {
        min-width: 40px;
        min-height: 40px !important;
        padding: 8px;
        cursor: pointer;
      }
    }
  }

  .entity-body {
    display: flex;
    flex-direction: column;
    margin-left: 35px;
    gap: 8px;
    width: calc(100% - 35px);

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #354554;
      mix-blend-mode: normal;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    .line-through {
      text-decoration: line-through;
    }

    .additional-links {
      margin-left: -12px;
    }

    .card-entity-post {
      margin-left: 0;
      max-width: 100%;
      & > a {
        max-width: 100%;
      }
    }

    .task-description-footer {
      display: flex;
      margin-top: 12px;
      align-items: flex-start;
      gap: 10px;

      .task-label-group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 4px;

        .teamMembersDropdown {
          .os__menu {
            min-width: 250px !important;
          }

          .react-select-ortho-2,
          .css-b62m3t-container {
            height: 24px;

            .os__control,
            .css-j9wwj5-control {
              border-color: #edeff3;
              background-color: #edeff3;
              height: 24px;
              max-height: 24px;
              min-height: 24px;
              overflow: hidden;
              border-radius: 12px;

              .os__value-container {
                padding: 0;
                border-radius: 12px;

                .os__input-container {
                  margin: 0;
                  padding: 0;
                }

                .os__single-value {
                  max-width: 100%;
                  min-width: 140px;

                  div {
                    display: flex;

                    .avatar {
                      margin-right: 4px;
                      width: 16px;
                      min-width: 16px;
                      height: 16px;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      img {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                      }
                    }

                    .result-text-data {
                      flex-grow: 0;
                      font-family: Inter, sans-serif;
                      font-size: 12px;
                      font-weight: 600;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.33;
                      letter-spacing: normal;
                      text-align: left;
                      color: #354554;
                      display: flex;
                      align-items: center;
                    }
                  }
                }
              }
            }
          }
        }

        .taskLabelContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 8px;
          gap: 4px;
          border-radius: 12px;
          min-height: 24px;

          .singleTaskLabel {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #354554;
          }
        }
      }

      .task-date {
        display: flex;
        white-space: nowrap;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 8px;
        line-height: 12px;
        color: #7e91a5;
        mix-blend-mode: normal;
        order: 0;
        align-items: center;
        flex: 1;
      }

      .task-comment-count {
        font-size: 12px;
        line-height: 16px;
        padding: 4px 8px;
        color: #5d7893;
        mix-blend-mode: normal;
        flex: none;
        display: flex;
        align-items: center;
        gap: 4px;
        padding-left: 0;
        cursor: pointer;
        .red {
          color: #f5555a;
        }
      }
    }
  }
}

.show-more-activities {
  position: relative;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  height: 30px;

  .modal-btn-wrapper {
    display: flex;
    position: absolute;
    top: 4px;
    align-items: center;
    justify-content: center;
    width: 100%;

    .more_btn_container {
      padding: 0 12px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #c9d1da;
      box-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24),
        0 1px 3px 0 rgba(53, 69, 84, 0.12);
      background-color: #fff;
      border-radius: 30px;
      cursor: pointer;
      z-index: 1;

      .btn_text {
        color: #4a749a;
      }
    }
  }
}

.task-preview-activity {
  background: #f8f8f8;
  min-height: 420px;
  height: 420px;
  max-height: calc(100% - 100px);
  overflow: auto;
  display: flex;
  flex-direction: column;

  .loader-active {
    height: 62px;
    margin: 8px 0;
    display: flex;

    .loader-inner {
      margin-top: -45px;
      transform: translate(-29.994px, 0);
    }
  }

  .activity-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #7e91a5;
    padding: 16px 0;
  }
}

.task-preview-input {
  background: #ffffff;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  .attachment-file-block {
    width: auto;
    min-width: 100%;
    padding: 8px 24px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    .files-count {
      width: 100%;
      margin-bottom: 8px;
      font-size: 13px;
      font-weight: 700;
    }

    .file-holder {
      margin-right: 8px;
      width: 300px;
      padding: 0;
      position: relative;
      display: inline-block;
      vertical-align: top;
      background: #fff;
      border-radius: 5px;
    }
  }

  .tip-tap-editor-field {
    min-height: 44px;
    margin: 24px;
    padding: 10px 0;
    padding-right: 92px;
    span[data-type='mention'] {
      background: #e3e3e3;
      border-radius: 4px;
      padding: 4px;
    }
  }

  .tiptap-wrapper {
    position: relative;

    .action-wrapper {
      position: absolute;
      top: 2px;
      right: 28px;
      display: flex;
      gap: 8px;

      .post-commment-btn {
        height: 40px;

        &.disabled {
          cursor: not-allowed;
        }
      }

      .at-bt-dpdowm {
        margin-left: 0;
        margin-bottom: 4px;
        margin-right: 0;

        .page-heading-title {
          padding-left: 0;
          padding-right: 0;
          background-color: transparent;
          border: 0;
        }
      }

      .header-dropdown-list {
        bottom: 100%;
      }

      .action-btn {
        cursor: pointer;
        color: #7e91a5;
        min-height: 40px;
        min-width: 40px;
        padding: 12px;

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}

.mobile-device {
  .task-preview-modal {
    margin: 0 !important;
    max-height: 100vh;
  }
  .task-preview-entity {
    .taskInformation {
      .taskTitleAndDueDate {
        align-items: normal;
        .title-conatiner {
          gap: 4px;
          flex-direction: column;
        }
      }
    }
    .entity-body {
      margin-left: 0px;
    }
  }
}
