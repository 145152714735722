%edu-video-thumbnail,
%edu-file-thumbnail {
  position: relative;
  overflow: hidden;
  background: linear-gradient(0deg, #7b7b7b 0%, #adadad 100%);
  box-shadow: 0px 1px 3px #fff-15;
  max-height: 179px;
  min-height: 179px;

  .pdf-canvas canvas {
    width: 100%;
  }

  .pg-viewer-wrapper {
    pointer-events: none;
    overflow: hidden;
  }

  .edu-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
  }

  img {
    max-width: 100%;
    width: 100%;
    position: relative;
  }

  .edu-play-span {
    position: absolute;
    top: 22px;
    z-index: 1;
    right: 15px;
    cursor: pointer;
    &.edu-file-span {
      top: 40px;
    }
    &.edu-custom-file-span{
      right: 161px;

      i {
        font-size: 38px;
        display: block;
        margin-top: 12px;
      }
    }
  }

  .edu-video-title {
    width: 100%;
    position: absolute;
    top: 130px;
    z-index: 1;
    left: 0;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #7b7b7b;
    height: 36px;
    font-weight: $font-weight-bold;
    line-height: 35px;
    text-transform: uppercase;
    text-align: right;
    padding-right: 15px;
  }

  .edu-time {
    font-size: 18px;
    position: absolute;
    bottom: 20px;
    left: 40px;
    z-index: 1;
    color: #e6e6e64;
  }
  .fa {
    font-size: 18px;
  }

   .conversation-attachment-file-content {
    width: 100%;
    height: 120px;
  }

   .conversation-attachment-icon-holder {
    width: 100%;
    height: 55%;
    position: relative;
  }

   .conversation-attachement-icon {
    background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
    width: 38px;
    height: 48px;
    display: inline-block;
    background-size: 38px auto;
  }

   .conversation-attachment-ext-name {
    width: auto;
    max-width: 100px;
    height: 19px;
    border: solid 2px #DDD;
    padding: 0 7px 0 3px;
    overflow: hidden;
    position: absolute;
    top: 22px;
    left: 7px;
    color: #DDD;
    font-size: 13px;
    font-weight: $font-weight-bold;
    line-height: 16px;
    text-align: left;
    background: #block;
    border-radius: 4px;
  }

}
%notification-heading {
  margin-top: 15px;
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
  height: 36px;
  max-width: 150px;
  font-size: 14px;
  font-weight: $font-weight-bold;
  line-height: 35px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-left: 15px;
  z-index:1;
}

.mobile-device {
  .educational-videos {
    padding: 0 15px;
    .os-card {
      margin: 0 auto;
    }
  }
}

.educational-videos, .space-links-container, .modal-edit-board {

  &.card.row {
    margin-left: 0;
    margin-right: 0;
  }

  .edu-video-thumbnail{
    @extend %edu-video-thumbnail;
  }

  .edu-file-thumbnail {
    @extend %edu-file-thumbnail;

    .image-viewer-wrapper {
      &.image-no-name {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #222226;
      }
    }
  }

  .notification-heading {
    @extend %notification-heading;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    margin:0;
    .edu-video-thumbnail {
      height: auto;
    }
  }
}

.mobile-device {
  .educational-videos {
    .edu-video-thumbnail{
      display: flex;
      align-items: center;
      justify-content: center;
      background: #222226;
    }
  }

}
