$img-h-120: 120px;
$img-h-136: 136px;
$img-h-144: 144px;

%icon-small-attachment {
  position: relative;
  padding-right: 16px;

  .conversation-attachement-icon {
    background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
    width: 14px;
    height: 16px;
    display: inline-block;
    background-size: 12px auto;
  }

  .conversation-attachment-ext-name {
    @include fontstyle(8px, 600, 7px);
    width: auto;
    max-width: 100px;
    height: 10px;
    border: 1px solid #ddd;
    padding: 0 2px;
    overflow: hidden;
    position: absolute;
    top: 5px;
    left: 5px;
    color: $level-9;
    text-align: center;
    background: #424242;
    border-radius: 2px;
    white-space: nowrap;
  }
}

.h1-page-heading {
  @include fontstyle(24px, 300, 1.4);
  margin: 72px 0 24px 0;
  color: $heading;
}

.submit-case-container {
  padding-bottom: 25px;

  .modal-dropzone-area {
    background-color: rgba(34, 34, 38, 0.8);
    border-color: #4c4c54;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .svg-icon {
    margin-bottom: 20px;
    transition: transform 500ms ease;
  }

  .file-finder-btn {
    cursor: pointer;
    text-decoration: underline;
    position: relative;
  }

  .dropzone-instruction {
    @include fontstyle(16px, normal, 1.5);
    padding-bottom: 0;
  }

  .dropzone-active {
    .modal-dropzone-area {
      border: dashed 2px rgba(72, 172, 253, 0.5);
      background-color: rgba(41, 41, 46, 0.5);
    }

    .svg-icon {
      transform: scale(1.2);
    }
  }

  .h1-page-heading {
    margin-top: 24px;
  }

  .submit-action {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #121214;
    margin: 0;
    padding-top: 8px;
    padding-bottom: 20px;
  }

  ~ .footer {
    visibility: hidden;
    pointer-events: none;
  }
}

.universalBannerWrapper ~ .submit-case-container{
  .h1-page-heading {
    margin-top: 5px;
  }
}

.case-instruction-ul {
  @include fontstyle(12px, normal, 1.4);
  max-width: 400px;
  text-align: left;
  margin: 20px auto 0px auto;

  .li-hint {
    &.with-number {
      list-style-type: number;
    }
  }
}

.submit-action {
  margin-top: 8px;

  .mr-32{
    margin-right: 32px;
  }
}

.upload-heading {
  @include fontstyle(16px, 600, 1.5);
}

.image-upload-card {
  position: relative;
  margin-bottom: 24px;

  .file-status {
    margin-top: 9px;
  }
}

.attachment-upload-card {
  position: relative;
  margin-bottom: 16px;

  .file-status {
    margin-top: 9px;
  }
}

.remove-card{
  position: absolute;
  right: 12px;
  top: 0;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.8  ;
  }
}

.image-card {
  &.h-144 {
    max-width: 100%;
    position: relative;
    border-radius: 4px;
    background-color: #e6e6e63;
    min-height: $img-h-144;
    max-height: $img-h-144;
    display: flex;

    .upload-img {
      max-width: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    .overlay-gr {
      position: absolute;
      left:0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 4px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0), #000000 13%);
    }
  }
}


.upload-file-card {
  max-width: 100%;
  position: relative;
  border-radius: 4px;
  background-color: #222226;
  display: flex;
  flex-direction: column;
  padding: 16px 18px 20px;

  .icon {
    margin-right: 5px;
    font-size: 16px;
  }

  .remove-card {
    top: 12px;
    right: 13px;
  }

  .upload-file-size {
    color: $level-9;
    font-size: 12px;
    padding-top: 4px;
    line-height: 1.4;
  }
}

.upload-file-group {
  display: flex;
  align-items: center;

  .upload-file-name {
    @include text-truncate();
    overflow: hidden;
    max-width: calc(100% - 57px);
    display: inline-block;
  }

  .icon-small-attachment {
    @extend %icon-small-attachment;
  }
}

html:not(.ipad) {
  @media (max-width: 1199px) {
    .image-card {
      min-height: $img-h-120;
      max-height: $img-h-120;
    }
  }
}

.ipad{
  &.portrait {
    .image-card {
      min-height: $img-h-136;
      max-height: $img-h-136;
    }
  }

  &.landscape {
    .image-card {
      min-height: $img-h-120;
      max-height: $img-h-120;
    }
  }
}

.saved-time-text {
  @include fontstyle(12px, normal, 16px)
  color: $level-9;
  padding-left: 16px;
}

@media(min-width: 768px) {
  .submit-case-container {
    .modal-dropzone-area {
      padding: 24px 0;
    }
  }
}
