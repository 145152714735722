.invitation-modal {
  max-width: 100% !important;
  height: 100%;
  margin: 0;
  display: flex;

  .modal-header {
    padding: 16px 24px;

    .ifill-os-close {
      right: 23px;
      top: 15px;
    }
  }

  .modal-content {
    width: 580px;
    margin: auto;
    display: block;
    text-align: center;
    padding: 0;

    .modal-body {
      padding: 0 77px;
    }
  }

  .svg-icon {
    height: 160px;
  }

  .modal-footer {
    padding: 48px 40px 32px;
    position: relative;

    .osbtn + .osbtn {
      margin-left: .5rem;
    }
  }

  .copy-code {
    margin-top: 32px;
    color: $lt-orange;
    font-size: 24px;
    line-height: normal;
  }
}

@media (max-width: 600px) {
  .invitation-modal {
    max-width: 90%;

    .modal-content {
      .modal-body {
        padding-right: 24px;
        padding-left: 24px;
      }
    }
  }
}
