/*body {
  * {
    -webkit-tap-highlight-color: transparent !important;
    user-select: none !important;
  }
}*/

%emoji-btn {
  color: $level-11;
  padding: 0 12px;
  box-shadow: none;
  min-height: 38px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 500ms ease, border-color 500ms ease;
  will-change: background-color, border-color;
  box-shadow: none !important;
  font-size: 16px;

  @media screen and (min-width: 1900px) {
    font-size: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    display: inline-block;

    &.ok_svg {
      width: 22px;
      height: 24px;
    }
  }

  .btn-icon-helper {
    transition: color 500ms ease;
    will-change: color;
  }

  .count {
    margin-left: 4px;

    @media screen and (max-width: 767px) {
      line-height: 30px;
    }
  }
}


.emoji-btns-group {
  display: inline-flex;
  max-width: 100%;
  align-items: center;

  li {
    display: inline-block;
    margin-right: 8px;
    position: relative;
    vertical-align: top;
    margin-bottom: 6px;

    &.emoji-btn-icons {
      margin-right: 3px;
    }

    .less-btn {
      margin-right: 36px !important;

      @media screen and (min-width: 768px) and (max-width: 1366px) {
        margin-right: 70px !important;
      }
    }
  }
}

.osbtn {
  &.emoji-btn {
    @extend %emoji-btn;

    &:not(.add-emoji-btn) {
      .btn-icon-helper {
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        margin-right: 4px;
      }

      &.active,
      &.emoji-btn {
        background-color: $level-3;
        color: $level-9;
      }
    }

    &.less-btn {
      &:not(.no-text) {
        padding-left: 12px !important;
      }

      &[text='less'] {
        padding-left: 12px !important;
      }
    }

    &.add-emoji-btn {
      padding: 0 !important;
      width: 40px;
      height: 40px;
      text-align: center;
      text-decoration: none !important;

      .btn-icon-helper {
        font-size: 16px;
        position: unset !important;
        transform: none !important;

        @media screen and (min-width: 1900px) {
          font-size: 20px;
        }
      }

      @media screen and (max-width: 767px) {
        &:focus {
          .emoji-wrapper {
            display: block;
          }
        }
      }
    }

    &:not(.add-emoji-btn) {
      &:focus {
        .emoji-add-list {
          display: block;
        }
      }
    }
  }
}

.emoji-wrapper {
  z-index: 11;

  &:before {
    border: 8px solid transparent;
    border-bottom: 8px solid $dropdown-bg;
    position: absolute;
    left: 50%;
    top: -6px;
    content: '';
    display: none;
    margin-left: -7px;
  }

  ul {
    margin: 0;
    padding: 0;
    /*padding: 16px 16px 8px !important;
    background-color: $dropdown-bg;
    border-radius: 8px;
    box-shadow: $dropdown-shadow;
    white-space: normal;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;*/
  }

  .add-reaction-mob {
    display: none;
  }

  .emoji-icon-list {
    width: 24px;
    height: 24px;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    cursor: pointer;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      margin-top: 0;
      display: inline-block;
      margin-right: 0;

      &.ok_svg {
        width: 24px;
        height: 24px;
      }
    }

    &:nth-child(7n+7) {
      margin-right: 0 !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $dropdown-list-hover;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 767px) {
    /*height: auto;
    max-height: 100%;
    position: fixed;
    max-width: 100%;
    width: 100%;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    opacity: 1;
    padding-top: 0;
    transition: opacity 500ms ease, bottom 500ms ease;
    will-change: opacity, bottom;
    border-radius: 8px 8px 0 0;
    box-shadow: $block-shadow;*/
    background-color: $level-1;
    max-height: 100%;
    height: 100%;

    &:before {
      content: none;
    }

    .add-reaction-mob {
      display: block;
      margin-bottom: 8px;
      color: $level-11;
      text-align: left;
      text-transform: none;
      font-size: 12px;
      padding-left: 16px;
      line-height: 16px;
      display: none;
    }

    ul {
      box-shadow: none;
      padding-right: 0 !important;
      padding-top: 0px !important;
      height: 100%;

      .emoji-icon-list {
        &:nth-child(7n+7) {
          margin-right: 8px !important;
        }

        @media screen and (max-width: 400px) {
          &:nth-child(11) {
            margin-right: 0px !important;
          }
        }
      }

      >div {
        height: 100%;
      }
    }

    &.active {
      opacity: 1;
      bottom: 0;
    }
  }
}

.emoji-add-list {
  width: 200px;
  padding-top: 10px;
  position: absolute;
  z-index: 9;
  top: 100%;
  display: none;

  .emoji-list-header {
    color: $level-9;
    font-size: 12px;
    line-height: 38px;
    position: relative;
    text-transform: none;
    font-weight: normal;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 13px;
      right: 13px;
      height: 1px;
      background: $level-4;
    }
  }

  &:before {
    border: 8px solid transparent;
    border-bottom: 8px solid $dropdown-bg;
    position: absolute;
    left: 50%;
    top: -5px;
    margin-left: -7px;
  }

  ul {
    margin: 0;
    padding: 8px 10px 8px 0;
    background-color: $dropdown-bg;
    border-radius: 8px;
    box-shadow: $block-shadow;
    width: max-content;


    .listing-items-box {
      max-height: 480px;
      overflow-y: auto;

      .see-all-heading {
        border-radius: 8px;
      }

      @media screen and (max-width: 767px) {
        max-height: 135px;
      }
    }
  }

  .emoji-list-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px !important;
    padding-right: 8px !important;
    margin: 0 !important;
    cursor: pointer;
    height: 44px;
    @include text-truncate();

    &:hover {
      background-color: $dropdown-list-hover;
      color: $dropdown-hover-text;
    }
  }

  .user-name {
    color: $dropdown-text;
    font-size: 12px;
    text-transform: none;
    font-weight: normal;
  }
}

.emoji-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  @media(min-width: 768px) {
    margin-top: 8px;
  }

  *,
  .workspace-dropdown-overlay {
    user-select: none;
  }

  .workspace-dropdown-overlay {
    user-select: none;
    position: fixed;
  }
}

.no-touch-support {
  .emoji-add-list {
    visibility: hidden;
    z-index: 13;
    pointer-events: none;
    transition: all 10ms ease;
    transition-delay: 50ms;
  }

  .add-emoji-btn {
    &.active {
      .emoji-wrapper {
        display: block;
      }
    }
  }

  .user-emoji-list {
    &:hover {
      .emoji-add-list {
        visibility: visible;
        pointer-events: auto;
        transition-delay: 250ms;
      }
    }
  }
}

.mobile-device,
.ipad {
  .add-emoji-btn {
    &.active {
      .emoji-wrapper {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .conversation-message {

    .emoji-bar+.conversation-message-attachment-row {
      margin-top: 16px;
    }

    .emoji-wrapper {
      margin-left: 40px;
      left: 50%;
      transform: translateX(-50%);

      &:before {
        margin-left: -48px;
      }
    }

    .less-btn {
      margin-right: 0 !important;

      @media screen and (min-width: 768px) and (max-width: 1366px) {
        margin-right: 0 !important;
      }
    }

    .emoji-bar {
      margin-top: 0;

      .add-emoji-btn {
        font-size: 0;

        .btn-icon-helper {
          margin-right: 0;
        }
      }

      .emoji-btn {
        margin-top: 0;
      }
    }

    .emoji-btns-group {
      li {
        .less-btn {
          margin-right: 8px !important;

          @media screen and (min-width: 768px) and (max-width: 1366px) {
            margin-right: 8px !important;
          }
        }
      }
    }

    &.ownMessage {
      .emoji-wrapper {
        margin-left: -22px;
        left: 40%;

        &:before {
          margin-left: 20px;
        }
      }

      .emoji-btns-group {
        li {
          margin: 0 !important;
        }
      }
    }

    &:nth-last-child(3)+&:nth-last-child(2) {

      /* need to remove */
      .emoji-wrapper {
        top: auto;
        bottom: 40px;
        padding-bottom: 10px;
        padding-top: 0;

        &:before {
          border-bottom: 8px solid transparent;
          border-top: 8px solid $dropdown-bg;
          top: auto;
          bottom: -5px;
        }
      }

      /* need to remove */

      :not(.emoji-m-user-list)> {
        .emoji-add-list {
          margin-left: 0;
          top: auto;
          bottom: 8px;
          padding-top: 0;
          padding-bottom: 10px;

          &:before {
            border-bottom: 8px solid transparent;
            border-top: 8px solid $dropdown-bg;
            top: auto;
            bottom: -5px;
          }
        }
      }
    }

    &:not(.ownMessage) {
      &:nth-last-child(3)+&:nth-last-child(2) {
        .emoji-wrapper {
          margin-left: 38px;

          &:before {
            margin-left: -47px;
          }
        }
      }
    }
  }

  .floater-message-holder {
    .conversation-message {
      .emoji-wrapper {
        margin-left: -30px;
        left: 0;
        transform: none;

        &:before {
          margin-left: 0;
          left: 50px !important;
        }
      }


      .emoji-btns-group {
        display: flex;
        flex-wrap: wrap;
        padding-right: 0;
        margin-bottom: 0;

        li:nth-child(3n+1) {
          .emoji-add-list {
            left: -30px;

            &:before {
              margin-left: 0;
              left: 50px;
            }
          }
        }

        li:not(.emoji-icon-list) {
          margin-right: 8px !important;

          &:last-child {
            margin-right: 8px !important;
          }
        }
      }

      &.ownMessage {
        .emoji-wrapper {
          left: auto;
          right: -34px;
          transform: none;

          &:before {
            left: auto !important;
            right: 52px;
          }
        }

        .emoji-btns-group {
          li:nth-child(3n+3) {
            .emoji-add-list {
              left: -30px !important;
              margin-left: 0;

              &:before {
                margin-left: 0;
                left: 50px;
              }
            }
          }

          li:nth-child(3n+1) {
            .emoji-add-list {
              left: auto;
              right: -40px;

              &:before {
                margin-left: 0;
                left: auto;
                right: 60px;
              }
            }
          }

          li:not(.emoji-icon-list) {
            margin-left: 8px !important;
            margin-right: 0 !important;

            &:last-child {
              margin-right: 0 !important;
            }
          }

        }
      }

      &:nth-last-child(2) {
        .emoji-wrapper {
          margin-left: -30px !important;

          &:before {
            left: 82px;
          }
        }

        &.ownMessage {
          .emoji-wrapper {
            margin-left: -30px !important;

            &:before {
              left: auto;
              right: 50px;
            }
          }

          .emoji-btns-group {
            li:nth-child(3n+3) {
              .emoji-add-list {
                left: -30px !important;
                margin-left: 0;

                &:before {
                  margin-left: 0;
                  left: 50px;
                }
              }
            }

            li:nth-child(3n+1) {
              .emoji-add-list {
                left: auto;
                right: -40px;

                &:before {
                  margin-left: 0;
                  left: auto;
                  right: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.emoji-m-user-list {
  background-color: $block;
  height: 200px;
  max-height: 264px;
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  z-index: 99999;
  padding: 0 0;
  opacity: 0;
  transition: opacity 500ms ease, bottom 500ms ease;
  will-change: opacity, bottom;

  @media screen and (max-width: 767px) {
    &.active {
      opacity: 1;
      bottom: 0;
    }
  }

  .emoji-add-list {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    top: 0;
    width: 100%;
    display: block;

    &:before {
      content: none;
    }
  }
}

.emoji-bar {
  .emoji-btn-list-group {
    list-style: none;
    padding: 0 16px 4px !important;
    margin: 0;
    white-space: nowrap;
    overflow-x: auto;

    li {
      display: inline-block;
      width: 50%;

      .osbtn {
        &.emoji-btn {
          width: 100%;
          border-radius: 0;
          margin-bottom: 0;
          background-color: transparent !important;
          height: 38px;
          border: 0;
          border-bottom: 1px solid #5c5c66;
          font-size: 16px;
          font-weight: 500;
          box-shadow: none;
          border: 0;

          svg {
            width: 24px;
            height: 24px;
          }

          &.active {
            background-color: transparent !important;
            border-bottom: 2px solid white;
            border-radius: 0;
          }
        }
      }
    }
  }
}

.user-emoji-list {
  .osbtn {
    padding: 0 !important;
    height: 20px;
    width: 20px;
    min-width: 20px;
    line-height: 20px !important;
    min-height: 20px !important;
    border-radius: 4px;

    .emoji-mart-emoji {
      padding: 0 !important;
    }
  }

  .count {
    display: none;
  }

  .user-emoji-holder {
    display: inline-flex;
  }
}


.mobile-device {
  .emoji-btns-group {
    .emoji-add-list {
      display: none;
    }
  }

  .emoji-bar {
    .emoji-btn-list-group {
      .osbtn {
        &.emoji-btn {
          border-radius: 0;

          &.active {
            background-color: transparent !important;
            border-bottom: 2px solid white;
            box-shadow: none;
          }
        }
      }
    }

    .emoji-add-list {
      .avatar {
        top: 0px !important;
      }

      .span-helper {
        display: inline-block !important;
        font-weight: normal;
      }
    }
  }

  .comment-section,
  .explore,
  .pulse-detail {
    .emoji-bar {
      margin-top: 0;
      min-height: 40px;

      .blank-emoji-holder+.user-emoji-list .user-emoji-holder {
        &:first-child {
          margin-left: 10px;
        }
      }
    }

    .row {
      min-width: 100%;
    }

    .emoji-btns-group {
      width: 100%;

      li {
        &.emoji-icon-list {
          margin-right: 7px !important;
        }
      }

      .emoji-btn-icons {
        margin-right: 12px;

        &:first-child {
          .add-emoji-btn {
            margin-bottom: 0;
            font-size: 12px;
            padding: 0px 16px !important;
            color: $level-11;

            .btn-icon-helper {
              margin-right: 4px;
              transition: color 500ms ease;
              will-change: color;
            }

            &.active {
              .btn-icon-helper {
                color: $os-link;
              }
            }
          }
        }
      }
    }

    .reply-ul {
      .emoji-bar {
        margin-top: 0;
      }
    }
  }

  .emoji-btn-list-group {
    li:only-child {
      width: 100%;
    }
  }

  .conversation-message-content {
    .emoji-list-name {
      direction: ltr !important;
    }

    .emoji-btn-list-group {
      direction: ltr !important;
      text-align: left;

      li:only-child {
        width: 100%;
      }
    }

    .emoji-add-list {
      direction: ltr;
    }
  }
}

.desktop-small {

  .explore,
  .spaces,
  .posts {

    .comment-section,
    .explore,
    .pulse-detail {
      .emoji-wrapper {
        left: -35px;

        &:before {
          margin-left: -56px;
        }
      }
    }
  }
}

.ipad {
  .emoji-add-list {
    display: none;
  }

  .emoji-btn:not(.add-emoji-btn) {
    &:hover {
      .emoji-add-list {
        display: block;
      }
    }
  }

  &.landscape {
    .explore {

      .comment-section,
      .explore {
        .emoji-wrapper {
          left: -35px;

          &:before {
            margin-left: 28px;
          }
        }
      }
    }

    .spaces {

      .comment-section,
      .explore {
        .emoji-wrapper {
          left: -35px;

          &:before {
            margin-left: -56px;
          }
        }
      }
    }
  }

  &.portrait {

    .posts,
    .spaces {

      .pulse-detail,
      .comment-section {
        .emoji-wrapper {
          left: -35px;

          &:before {
            margin-left: -56px;
          }
        }
      }
    }

    .comment-section,
    .explore {
      .emoji-bar {
        margin-top: 16px;
      }
    }

    .explore {
      .floater-message-holder {
        .conversation-message {
          .emoji-bar {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .activity-footer-block {
    &.explore {
      .emoji-wrapper {
        left: 0;

        &:before {
          left: 42px;
        }
      }
    }
  }
}

.emoji-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.0);
  z-index: 9;

  @media(max-width: 767px) {
    background-color: rgba(255, 255, 255, .8);

    &::after {
      content: '\e99d';
      position: absolute;
      @include osfont;
      width: 40px;
      height: 40px;
      right: 0;
      top: 12px;
      text-align: center;
      line-height: 40px;
      color: $level-10;
      font-size: 16px;
    }
  }
}

.emoji-wrapper-popover {
  background: none !important;
  box-shadow: none !important;
  padding-bottom: 0 !important;

  @media(max-width: 767px) {
    position: fixed !important;
    top: 50% !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
    max-width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.conversations,
.chat-detail {
  .emoji-wrapper-popover {
    @media(max-width: 767px) {
      top: 64px !important;
    }
  }
}