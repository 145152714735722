.new-cs-card {
  .case-thumbnail-slider {
    width: 100%;
    position: absolute;
    bottom: 0;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;
    transition: bottom 500ms ease, opacity  500ms ease;
    will-change: bottom, opacity;

    &.show-thumbs {
      bottom: 0;
      opacity: 1;
    }
  }
}
.case-thumbnail-slider {
  width: 100%;
  position: relative;
  margin-top: 16px;

  .image-selector-unselected {
    opacity: 1;
  }

  .slick-slide {
    position: relative;
    cursor: pointer;

    &.slick-current ~ .slick-cloned {
      display: inline-block;
    }

    &:before {
      content :'';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 0;
      visibility: visible;
      background-color: #121214;
      opacity: 0.2;
      z-index: 1;
      transition: opacity 500ms ease;
    }

    &:after {
      content :'';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: solid 1px $level-1;
      visibility: hidden;
    }

    &.slick-current {
      border-radius: 2px;
      overflow: hidden;

      &:before {
        visibility: hidden;
      }

      &:after {
        visibility: visible;
        border-width: 2px;
      }
    }

    > div {
      position: relative;
      height: 100%;
      width: 100%;

      .case-image-small {
        object-fit: cover;
        height: 100%;
        width: auto !important;
      }
    }
  }

  .slick-track {
    overflow: hidden;
  }

  .slick-track,
  .image-selector-image {
    height: 40px;
  }

  .osbtn {
    transform: translate(0, -50%);
    top: 50%;
    height: unset;
    min-width: 32px;
    min-height: 32px;
    line-height: 32px;
    font-size: 16px;
    padding: 0;
    position: absolute;
    border: 0 !important;
    background: none !important;

    i {
      text-shadow: 0.5px 0 rgba(18, 18, 20, 0.5);
      position: static;
      transform: unset !important;
      line-height: inherit;
    }

    &:hover {
      background: $level-4 important;
      color: $level-12 !important;
    }
  }

  .image-selector-prev {
    left: 0;
    text-align: right;
  }

  .image-selector-next {
    right: 0;
    text-align: left;
  }
}

.no-touch-support {
  .case-thumbnail-slider {
    .slick-slide:hover {
      &:before {
        opacity: 0;
      }
    }
  }

  .new-cs-card {
    .sbs-wrapper {
      .case-thumbnail-slider {
        height: 0;
        transition: all 500ms;
      }

      &:hover {
        .case-thumbnail-slider {
          bottom: 0;
          opacity: 1;
          height: 64px;
        }
      }
    }
  }
}

.full-screen {
  .case-thumbnail-slider {
    .slick-track {
      height: 112px;
    }

    .slick-slider {
      padding-left: 32px;
      padding-right: 32px;
    }

    .image-selector-prev {
      left: 0;
    }

    .image-selector-next {
      right: 0;
    }
  }
}

.cases {
  .image-selector-prev {
    left: 8px;

    .btn-icon-helper {
      margin-right: 8px;
    }
  }

  .image-selector-next {
    right: 8px;

    .btn-icon-helper {
      margin-left: 8px;
    }
  }
}

.desktop-small,
.ipad.landscape {
  .case-thumbnail-slider {
    margin-top: 8px;
  }
}


.ipad {
  &.portrait {
    .new-os-slider {
      .slider-controll-buttons {
        margin-bottom: 0;
        height: 48px;
      }
    }

    .full-screen {
      .case-thumbnail-slider {
        .image-selector-prev {
          left: -8px;
        }

        .image-selector-next {
          right: -8px;
        }
      }
    }
  }
}

.sbs-wrapper {
  .case-thumbnail-slider {
    margin-top: 16px;

    .slick-track,
    .image-selector-image {
      height: 40px;
    }
  }

  .new-os-slider {
    max-height: calc(100% - 80px);
  }
}

.no-touch-support {
  .case-thumbnail-slider {
    .image-selector-prev,
    .image-selector-next {
      &:hover {
        background-color: rgba(72, 172, 253, 0.2) !important;
        color: $os-link;

        .btn-icon-helper {
          color: $os-link;
        }
      }
    }
  }
}

.mobile-device {
  .case-thumbnail-slider {
    margin-top: 0;

    .osbtn {
      display: none !important;
    }
  }

  .sbs-wrapper {
    .case-thumbnail-slider {
      margin-top: 8px;

      .slick-track,
      .image-selector-image {
        height: 40px;
      }
    }
  }
}


.new-cs-card {
  .sbs-wrapper {
    .new-os-slider {
      margin-top: 0;
      max-height: calc(100% - 40px);
    }
  }

  .sbs-spin-img-wrap {
    padding-top: 0;
  }
}

.thumb-center {
  .slick-track {
    display: flex;
    justify-content: center;
  }
}
