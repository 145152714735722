.care-clv {
  .case-right-header {
    justify-content: space-between;
    display: flex;

    .header-dropdown-menu {
      margin-left: 0 !important;
    }
  }
  .insight-tab-text {
    margin-right: 24px;
    z-index: 2;

    @media screen and (min-width: 1200px) {
      margin-right: 34px;
    }

    &:last-child {
      margin-right: 0;
    }

    &.tab-disabled {
      .tab-link-text {
        color: #level-11 !important;
      }

      &:hover {
        .tab-link-tooltip {
          visibility: visible;
          opacity: 1;
          top: 80%;
          @media(min-width: 767px) {
            top: 100%;
          }
          pointer-events: auto;
        }
      }
    }
  }

  .clv-row {
    padding-top: 6px !important;
    &.lecture {
      .clv-attachement-title {
        + .create-slider {
          height: calc(100% - 150px);
        }
      }
    }
  }

  .clv-attachement-title {
    margin-top: 16px;
    padding-left: 0;
  }

  .cs-id-user-name +
  .header-dropdown-menu {
    position: absolute !important;
    top: 34px;
    right: 12px;
    display: none !important;
  }
}

.ipad.portrait,
.mobile-device {
  .care-clv {
    .cs-id-user-name {
      + .header-dropdown-menu {
        display: block !important;
      }

      .os-btn-link {
        line-height: 16px;
      }
    }

    .case-right-header {
      .header-dropdown-menu {
        display: none !important;
      }
    }

    .case-right-header {
      margin-bottom: 16px;
    }
  }
}

.tab-link-tooltip {
  @include x-center;
  top: 50%;
  padding-top: 10px;
  visibility: hiedden;
  opacity: 0;
  transition: all 300ms ease;
  pointer-events: none;
  width: 264px;
  border-radius: 8px;
  box-shadow: $block-xs;

  .tooltip-container {
    background: $block;
    padding:  16px 24px 16px 48px;
    border-radius: 8px;
    box-shadow: 0 1.3px 4px 0 rgba(0, 0, 0, 0.25);
    color: $level-11;
    font-size: 12px;
    text-transform: none;
    position: relative;



    .header-list-icon {
      font-size: 16px;
      color: $level-8;
      left: 20px;
      top: 20px;
      position: absolute;

      &.ifill-os-info {
        color: $lt-red;
        top: 16px
      }
    }
  }

  .tooltip-arrow-top {
    @include x-center;
    top: -6px;
    font-size: 24px;
    color: $block !important;
  }
}

.mobile-device {
  .care-clv {
    .clv-row {
      padding-top: 0 !important;
    }

    .detail-subheader {
      font-size: 16px;
    }

    .cs-id-user-name +
    .header-dropdown-menu {
      right: -6px;
      top: 20px;
    }

    .case-card-detail-header {
      .cs-header-btn-group {
        top: -48px !important;
        right: 48px;
        padding-right: 0 !important;
      }
    }

    .new-cs-card {
      .case-card-detail-header {
        padding: 0 !important;
      }
    }

    .cs-user-info {
      flex-direction: column;
      align-items: flex-start;
    }

    .detail-subheader {
      padding-left: 0;
    }

    .cs-id {
      padding-right: 0;

      + .or {
        color: $level-11;
        line-height: 12px;
        font-size: 10px;
        padding: 0px 4px !important;
      }
    }
  }
}

.no-touch-support {
  &.workspace, &.care {
    .clv-left-sec {
      .clv-attachement-title {
        background: rgba(34, 34, 38, 0.4);
        position: absolute;
        padding: 8px 12px;
        @include fontstyle(16px, normal, 24px);
        z-index: 1;
        width: 100%;
        margin: 0;
        border-radius: 4px 4px 0 0;
        opacity: 0;
        transition: opacity, 500ms;
        will-change: opacity;
      }

      &:hover {
        .clv-attachement-title {
          opacity: 1;
        }
      }
    }
  }
}
