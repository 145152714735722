.tags-container{
  padding-top: 16px;
  position: relative;

  .filter-results-block {
    width: 100%;
    margin-bottom: 16px;
  }

  .tags-selected{
    width: 100%;
    margin-bottom: 5px;
    padding-right: 100px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    i {
      margin-left: 8px;
      margin-right: 10px;
      font-size: 12px;
    }

    .cases-result-count {
      width: 105px;
      position: absolute;
      top: 10px;
      right: 0;
      text-align: right;
      text-transform: uppercase;
      cursor: auto;
    }
  }

  .tags-view-all{
    position: relative;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: .5px;
    vertical-align: top;
    top: 6px;
    margin-bottom: 10px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .tag-all-list{
    display: flex;
    flex-flow: row wrap;

    .tag-item{
      display: block;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    padding-top: 10px;

    .tags-view-all {
      height: 28px;
      padding: 0px 12px;
      font-size: 11px;

      &:hover {
        background: transparent;
      }
    }
  }
}

.tag-item {
  cursor: pointer;
  font-size: 16px;
  padding: 7px 16px;
  margin: 8px 10px 0 0;
  position: relative;
  color: $level-11;
  font-weight: $font-weight-normal;
  background-color: $tag-bg;
  height: 32px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  &:hover,
  &.selected {
    background-color: $os-link-hover
$os-link-hover;
    color: $btn-link-hover;
  }

  &.btn {
    transition: none !important;
  }

  &:focus {
    outline: none;
  }

  .tag-count {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    position: absolute;
    top: -8px;
    right: -7px;
    color: #121214;
    font-size: 13px;
    font-weight: $font-weight-bold;
    text-align: center;
    background: $lt-orange;
    border-radius: 100%;
    cursor: pointer;

    .ifill-os-close {
      width: 24px;
      height: 24px;
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      color: #121214;
      font-size: 12px;
      font-weight: $font-weight-bold;
      text-align: center;
      background: $lt-orange;
      border-radius: 100%;
      line-height: 24px;
    }
  }

  ~ .tag-sap {
    height: unset;
    padding: 0;
    display: inline-block;
    transform: translateY(5px);
  }
}

.tag-sap {
  height: 34px;
  padding: 0 5px;
}

.tag-buttons {
  height: 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0px 16px;
  display: inline-block;
  position: relative;
  top: 8px;
  color: $level-11;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  background: $tag-bg;
  border-radius: 4px;
  cursor: pointer;

  &.selected,
  &:hover {
    color: $tag-hover-text;
  }
}

.no-result-block {
  margin-top: 10px;
  padding: 30px 20px;
  position: relative;
  text-align: center;
  width: 100%;

  .no-results-text {
    font-size: 18px;
    font-weight: $font-weight-light;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.case-details-content-block {
  .related {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 24px;
      line-height: 1.5;
      color: $sidebar-widget-title;
    }
    .tag-view-more,
    .related-boards-view-link {
      font-size: 12px;
      margin-bottom: 24px;
      cursor: pointer;
    }

    .related-boards-view-link {
      margin-top: -8px;

      &.case-see-all {
        margin-bottom: 18px;
        text-transform: uppercase;
      }
    }
  }
  .cases-tags-block,
  .related-cards-block {
    margin-bottom: 40px;

    .tag-item {
      margin-left: 6px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 1;
    }
  }

  .cases-tags-block {
    padding: 0;

    .tag-item {
      &:first-child{
       margin-left:0;
      }
    }
  }
}

.related-cards-block {
  &.related-case,
  &.related-course,
  &.case-documents {
    padding: 12px 20px;
    background-color: $block;

    .tag-view-more {
      margin-right:0;
    }
    
    @media screen  and (max-width: 1366px) {
      border-radius: 12px;
      box-shadow: $block-shadow;
    }

    @media screen and  (min-width: 1920px) {
      padding-right: 52px;

      .tag-view-more {
        margin-right:0;
      }
    }
  }
}

.case-detail-height {
  min-height: 704px;
  max-height: 704px;
  height: 704px;

  @media only screen and (min-width : 1024px) and (max-width : 1366px)  {
    max-height: 592px;
    min-height: 592px;
    height: 592px;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    min-height: auto;
    max-height: none;
    height: auto;
  }
}

.case-right-landing-header {
  padding: 12px 18px 0;
  font-size: 15px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width:100%;

  &.hide{
    display: none;
  }
}

.case-right-landing-submission {
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
  vertical-align: text-bottom;
  i {
    margin-right: 10px;
    vertical-align: text-top;
  }
}
.case-right-landing-message {
  font-size: 16px;
  padding: 0px 25px;
}
.case-right-landing-btn-row {
  text-align: center;
  margin-top: 70px;

  .skip-link {
    color: #48acfd;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    margin-top: 35px;
    font-weight: bold;
    cursor: pointer;
  }
}

.btn-blue {
  font-size: 12px;
  font-weight: $font-weight-bold;
  color: #fff;
  letter-spacing: 0.5px;
  background-color: $os-link;
  line-height: 40px;
  padding: 0 16px;
  border: 0;
  text-transform: uppercase;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: color 500ms ease, background 200ms ease;
  will-change: color, background;
  min-height: 40px;
  min-width: 40px;
  position: relative;
  min-width: 206px;


  &:focus, &:active, &.active {
    color: #fff;
    background-color: $os-link-hover;
    outline: 0;
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    color: $level-9 !important;
    border-color: transparent;
    background-color: $os-btn-disabled !important;
  }

  &.btn-width-auto {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 15px;
  }

  &.x-27 {
    padding-left: 27px;
    padding-right: 27px;
  }

  &.btn-width-response {
    width: auto;
    margin-bottom: 15px;
    padding: 0px 30px;
    height: 34px;
    line-height: 34px;
  }

  &.h-36 {
    height: auto;
    line-height: 36px;
    border-radius: 18px;
  }

  .mw-128 {
    min-width: 128px;
  }

  &.btn-upgrade {
    min-width: 144px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    margin-top: 14px;
  }
}

.chrat-data-render {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  + .chrat-data-render {
      margin-top: 10px;
  }

  .chart-col {
    flex: 0 0 50%;

    &.legend-text {
      flex-basis: auto;
      max-width: 50%;
    }

    .bar-legend-list {
      padding-top: 0 !important;
    }
  }

  .chart-render {
    padding-left: 15px;
    height: 1.078125rem;
    overflow: hidden;
  }

  .chartjs-render-monitor {
    max-width: 100%;
    height: 50px !important;
    margin-top: -15px;
  }
}


@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .mobile-sidebar {
    width: 100%;
    padding: 20px 15px;
    background: #646464;

    .cases-tags-block {
      margin-bottom: 50px;
    }

    .related {
      h5 {
        font-size: 12px;
        font-weight: $font-weight-bold;
      }

      .tag-view-more {
        display: none;
      }
    }

    .btn {
      &.tag-item {
        height: 24px;
        margin: 0 4px 4px 0;
        padding: 0 13px;
        font-size: 10px;
        line-height: 24px;
      }
    }

    .related-cards-block {
      .related {
        margin-bottom: 8px;

        a {
          font-size: 12px;
          font-weight: $font-weight-bold;
        }
      }
    }

    .related-boards-view-link {
      font-size: 12px;
      cursor: pointer;
    }
  }
  .cases-tags-block {
    .cases-tags-wrap {
      white-space: nowrap;
      overflow-x: auto;
      .col-12:first-child {
        padding-left: 0;
      }
    }
  }
  .related-cards-nowrap {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    .col-12:first-child {
      padding-left: 0;
    }
  }
  .case-timeline-right-section {
    .case-right-header {
      position: relative;
      left:0;
      right: 0;
    }

    .case-treatment-right-section {
      margin-top: 20px;
      .timeline-right-section {
        margin-top: 10px;

        .detail-text {
          padding: 15px 0;
        }
      }
    }


    .case-right-landing-header {
      padding: 8px 10px 40px;

      .case-right-landing-message {
        font-size: 16px;
      }
    }

    .case-right-landing-header {
      .case-right-landing-submission {
        position: relative;
        top: 0;
        right: 0;
        margin-bottom: 20px;
      }
      .case-right-landing-btn-row {
        margin-top: 30px;
      }
    }

    &.prioritized-listing {
      .case-treatment-right-section {
        margin-top: 0;
        .timeline-right-section {
          max-height: none;
          min-height: 160px;
        }
      }
    }
  }
  .detail {
    .case-details-content-block {
      .case-details-video-section {
        padding-bottom: 20px;
        .detail-text {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 20px 0 0 0;
          .detail-subheader {
            font-size: 14px;
            padding-left: 15px;
            padding-bottom: 15px;
          }
          .engagement__views {
            .ifill-os-views {
              margin-right: 0.5rem;
            }
          }
          .detail-type-mobile {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            border-bottom: 2px solid rgba(255, 255, 255, 0.1);
          }
        }

        .detail-comments {
          padding-top: 0 !important;

          .media-actions-buttons {
            margin-bottom: 30px;

            .btn-small-text{
              padding-left: 14px;
              padding-right: 14px;
              margin-right: 0px !important;
              font-size: 10px;
              width: 45%;
              &.btn-info {
                color: #fff;
              }
            }

            .text-link {
              cursor: pointer;
              top: 0px;
              position: relative;
            }

          }
        }
      }
      .case-timeline-right-section {
        .case-treatment-right-section {
          .case-active-treatment-block {
            width: 100%;
            position: relative;

            .case-treatment-text-block {
              text-align: center;
              position: relative;
              margin-bottom: 10px;
              .case-treatment-text-small {
                font-size: 10px;
              }

            }
            .case-treatment-time-block {
              float: none;
              font-size: 13px;
              padding-left: 10px;
            }
          }
          .case-treatment-tooltip {
            &:before {
              box-shadow: none;
            }
            &.left {
              left: 0px;
            }
            &.right {
              right: 0px;
            }
          }
        }

        .quiz-right-section {
          margin-top: 40px;
          &.questions {
            margin-top: 0px !important;
          }
        }
      }
    }
  }


}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .tag-item {
    padding: 5px 15px;
    font-size: 12px;
  }

  .tag-buttons {
    font-size: 12px;
  }

  .cases-tags-block {
    .cases-tags-wrap {
      white-space: nowrap;
      overflow-x: auto;

      .col-12:first-child {
        padding-left: 0;
      }
    }
  }
  .related-cards-nowrap {
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
  }

  .detail {
    .case-details-content-block {
      .case-details-video-section {
        .detail-text {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 20px;
        }
        .case-data-not-present {
          height: 310px;
        }
      }
    }
    .case-timeline-right-section {
      .quiz-right-section {
        .submission-selection-holder {
          .submission-selection-link {
            padding: 0px 5px 0;
          }
        }
        .submission-selection-small-holder {
          &.four-submission-link {
            .submission-selection-link {
              padding: 0px 0px 0;
              font-size: 7px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and  (orientation: landscape) {
  .related-cards-nowrap {
    white-space: normal;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  .detail {
    .case-timeline-right-section {
      .case-right-header {
        display: block;
        .quiz-section-header {
          position: relative;
          z-index:2;
          min-height: 58px;
        }
      }
      .quiz-right-section {
        .landscape-block {
          width: 100%;
        }
        .submission-selection-holder {
          .submission-selection-link {
            padding: 0px 5px 0;
          }
        }
      }

      .quiz-right-section, .timeline-right-section {
        padding-right: 0;
      }
    }
  }
}


@media only screen and (min-device-width : 1024px) and (max-device-width : 1365px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .tag-item {
    padding: 5px 15px;
    font-size: 12px;
  }

  .tag-buttons {
    font-size: 12px;
  }

  .cases-tags-block {
    .cases-tags-wrap {
      white-space: nowrap;
      overflow-x: auto;

      .col-12:first-child {
        padding-left: 0;
      }
    }
  }

  .related-cards-nowrap {
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
  }

  .detail {
    .case-details-content-block {
      .case-details-video-section {
        .detail-text {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  .case-details-video-section {
    &.col-tablet-12 {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .case-timeline-right-section {
    .case-treatment-right-section {
      margin-top: 36px;
      .timeline-right-section {
        max-height: 360px;
      }
    }

    &.prioritized-listing {
      .case-treatment-right-section {
        .timeline-right-section {
          max-height: 450px;
        }
      }
    }
    .quiz-right-section {
      margin-top: 36px;

      &.questions {
        margin-top: 24px !important;
      }
    }
  }

}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .case-timeline-right-section {
    .case-treatment-right-section {
      .timeline-right-section {
        max-height: 460px;
      }
    }
    &.prioritized-listing {
      .case-treatment-right-section {
        .timeline-right-section {
          max-height: 540px;
        }
      }
    }
    .quiz-right-section {
      margin-top: 36px;
      &.questions {
        margin-top: 24px !important;
      }
    }
  }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape )and (-webkit-min-device-pixel-ratio: 1) {
  .case-details-video-section {
    &.col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }
  }
  .detail {
    .case-timeline-right-section {
      &.col-lg-4 {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;

        .case-treatment-right-section {
          .case-active-treatment-block {
            width: calc(100% - 24px);
            display: flex;
            justify-content: space-between;
            .case-treatment-text-small {
              display: inline-block;
              font-size: 16px;
              line-height: 18px;
            }
            .case-treatment-time-block {
              display: inline-block;
              font-size: 14px;
              font-weight: 600;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}


.mobile-device {
  &.landscape {
    .case-details-video-section {
      &.col-tablet-12 {
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
        .related-cards-nowrap {
          white-space: nowrap;
          overflow-x: auto;
          display: flex;
          flex-wrap: nowrap;
        }

      }
    }
  }
}

.share-select-list {
  input[type='text'].form-control {
    background-color: #000 !important;
    border-color: #787878;
    font-size: 14px;
  }
  .share-list-result {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    flex-wrap: wrap;
    max-height: 115px;
    overflow-y: auto;
    margin-bottom:15px;
    span {
      flex-basis: 49%;
      text-align: left;
      padding: 10px 8px;
      border-radius: 15px;
      background: #191919;
      font-size: 12px;
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.8px;
      color: #e6e6e6;
      position: relative;
      p {
        padding-right: 18px;
      }
      i {
        position: absolute;
        top: 13px;
        right: 10px;
        font-size: 8px;
      }
    }
  }
  ul {
    max-height: 202px;
    overflow-y: auto;
    li {
      .media {
        width: 100%;
        min-height: 58px;
        border-radius: 4px;
        background-color: #191919;
        margin-bottom: 5px;
        text-align: left;
        padding: 10px;
        border: 1px solid transparent;
        cursor: pointer;

        .avatar-31 {
          @include avatar(31px);
          box-shadow: none;
        }

        p {
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.8px;
          color: #e6e6e6;
          padding-left: 10px;
          font-weight: bold;

          small {
            display: block;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.67px;
            font-weight: normal;
          }
        }

        i.fa {
          font-size: 21px;
          background: #fff;
          border-radius: 63%;
          width: 17px;
          height: 16px;
          position: relative;
          line-height: 17px;
        }
      }

      &:hover {
        .media {
          border-color:#4294e5;
        }
      }
    }
  }
}

.create-comparison-title-row {
  .create-comparison-title-block {
    height: 36px;
    padding-bottom: 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1px;
  }

  .create-comparison-title {
    font-size: 24px;
  }

  .create-comparison-btns-block {
    .osbtn {
      position: relative;

      &.disabled-only {
        &:hover {
          .compare-selection-message {
            display: block;
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }

      i {
        margin-left: 12px;
      }

      .compare-selection-message {
        width: 200px;
        margin-left: -100px;
        padding: 10px;
        display: none;
        position: absolute;
        top: 40px;
        left: 50%;
        color: #fff;
        font-size: 12px;
        text-transform: none;
        text-align: center;
        white-space: normal;
        background: #000;
        border-radius: 4px;
        line-height: 1.5;

        &::before {
          content: '';
          width: 0;
          height: 0;
          margin-left: -3px;
          border-right: solid 6px transparent;
          border-left: solid 6px transparent;
          border-bottom: solid 6px #000;
          position: absolute;
          top: -6px;
          left: 50%;
        }
      }
    }

    .create-comparison-close-btn {
      cursor: pointer;
      width: 34px;
      height: 34px;
      margin-left: 6px;
      display: inline-block;
      position: relative;
      top: 4px;
      float: none;
      color: #fff;
      font-size: 24px;
      font-weight: $font-weight-light;
      line-height: 34px;
      background: #a4a4a4;
      border-radius: 100%;
      opacity: 1;
      text-shadow: 0 0 0 transparent;

      &:focus {
        outline: none;
      }
      .ifill-os-close {
        font-size: 14px;
        line-height: 34px;
        text-align: center;
        display: block;
      }
    }
  }
}

.comparison-loader-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, .75);

  &.comparison-save-overlay {
    .loader-active {
      .loader-inner {
        margin-top: -250px;
        margin-left: -250px;
        transform: translate(0);
      }
    }
  }

  .loader-active {
    .loader-inner {
      margin-left: -250px
    }
  }
}

.case-details-content-block {
  .case-details-video-section {
    .inprogress-height {
      height: 100%;
    }
    .case-data-not-present {
      height: 432px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }
}

.video-inprogress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 40px !important;
  height: calc(100% - 62px);
  background: $block;
  border-radius: 12px;
  margin-top: 16px;

  h5{
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-bottom: 16px;
  }
  p {
    text-align: center;
  }

  + .detail-text {
    position: absolute;
    bottom: 0;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1366px) and (orientation: portrait) {
    height: 400px;

    .comingsoon-image {
      max-height: 70%;
      margin-bottom: 24px;
    }

    + .detail-text {
      bottom: 15px;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (-webkit-min-device-pixel-ratio: 1) {
    height: 300px;
  }
  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    height: 300px;
  }
}

.related-case-inprogress, .related-tag-inprogress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px !important;
  background-color: #000;
  margin-bottom: 30px;
  h5{
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-bottom: 16px;
  }
  p {
    text-align: center;
    font-size: 14px;
    font-weight: $font-weight-bold;
  }
}


@media only screen and (min-device-width : 320px) and (max-device-width : 1366px)  and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.cases {
      .video-holder{
        overflow: hidden;
      }
      .slick-slider {
        position: relative;
      }
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 767px)  and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.cases {

      .video-scrubber {
        .slick-list {
          height: auto;
        }
        .slick-track {
          display: flex;
        }
      }
    }
  }
}


@media only screen and (min-device-width : 320px) and (max-device-width : 1366px)  and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.cases {
      .assign-overlay.add-to-message-modal.assign-overlay--show {
        z-index: 1052;
      }
    }
  }
}

.slick-big-slider {
  .slick-slider {
    .slick-slide {
      padding: 0 1px;

      &.slick-current {
        padding: 0;
      }
    }
  }
}


.case-treatment-right-section {
  &.treatment-data-unavailable {
    .timeline-right-section {
      margin-top: 0;
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media(min-width: 768px) {
        min-height: 160px;

        .treatment-data-msg {
          margin:0 auto;
          padding-left: 24px;
        }
      }
    }
  }
}

.no-touch-support {
  .case-details-content-block .case-details-video-section {
    .tag-view-more:hover,
    .related-boards-view-link:hover {
      color: $os-link-hover;
      text-decoration: underline;
    }
  }

  .btn-blue {
    &:hover {
      color: #fff;
      background-color: $os-link-hover;
    }
  }
  .tag-count {
    &:hover {
      .ifill-os-close{
        display: inline-block;
        display: inline-block;
        color: #121214;
        background: $lt-orange;
        border-radius: 50%;
        padding: 0;
        line-height: 24px;
        font-size: 10px;
        margin: 0;
      }
    }
  }
}

.touch-support {
  &.portrait {
    .cases-tags-block  .tag-item:first-child {
      margin-left: 0 !important;
    }
  }
}

.ipad, .no-touch-support, .mobile-device{
  .case-video-holder, .comparison-video-holder {
    .case-video ~ .slick-slider {
      display: none;
    }
  }
}


.mobile-device {
  &.portrait {
    .comment-content-section {
      .case-details-video-section {
        padding: 0px;
      }
    }

    .container {
      &.case-details-container {
        padding: 0;
      }
    }
  }
}

.ipad{
  &.portrait {
    .related-cards-nowrap {
      margin-left: -12px;
      margin-right: -12px;
    }

    .related-cards-block {
      overflow-x : hidden;
      width: 100%;
    }
  }
}

.mobile-device{
  .related-cards-nowrap {
    margin: 0;
  }

  .case-right-landing-message {
    margin-top : 24px;
  }
}

.desktop-small {
  .case-right-landing-submission {
    p {
      display: inline;
      padding-left: 5px;
    }
  }

  .quiz-right-section {
    padding-right: 8px;
  }

  @media screen and (max-width: 1024px) {
    .full-screen {
      .user__info {
        display: flex;
        align-items: center;

        .user-case-id,
        .detail-subheader  {
          display: inline-block !important;

        }

        .detail-subheader  {
          @include text-truncate();
          max-width: 86px;
        }
      }
    }
  }
}

.ipad{
  &.landscape {
    @media screen and (max-width: 1024px) {
      .full-screen {
        .user__info {
          .detail-subheader  {
            @include text-truncate();
            max-width: 76px !important;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
  .switch-back {
    max-width: 165px;
    text-align: center;

    span {
      display: block;
    }
  }
}

html:not(.mobile-device) {
  .related-cards-block {
    .os-card-v2.small-card {
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) and (min-width: 1366px) {
  .case-detail {
    .related-cards-block,
    .cases-tags-block {
      padding-left: 0;
      padding-right: 0;

      &.related-case,
      &.related-course,
      &.case-documents {
        padding: 24px 20px 20px;
      }
    }
  }
}


.ipad.portrait,
.mobile-device {
  .submit-right-container {
    padding-top: 0;

    .cases-tags-block {
      .cases-tags-wrap {
        white-space: normal;
        overflow-x: hidden;

        .col-12:first-child {
          padding-left: 0;
        }

        .tag-item:first-child {
          margin-left : 6px !important;
        }
      }
    }
  }
}


.mobile-device {
  .submit-right-container {
    padding-top: 0;
  }
}


.case-documents {
  margin-bottom: 24px;
  .related {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .document-list {
    max-height: 510px;
    overflow-y: auto;
  }
  .card-z-index {
    z-index: 1;
  }
}

.case-doc-title {
  color: $heading;

  .tag-view-more {
    margin-right: -14px;
  }
}

.related-cards-nowrap {
  .card-z-index {
    z-index: 1;
  }
}
