.clv-page {

  &.no-touch-support {
    .case-detail-height {
      @include equalAllHeight(704px)
    }
  }

  .spin-video .threesixty-spin {
    background-size: unset !important;
  }

  .sbs-wrapper {
    .slick-current {
      z-index: 1;
    }

    @media(min-width: 768px) {
      .spin-video {
        max-height: calc(100% - 25px);
      }
    }
    
    .spin-video {
      .threesixty-spin {
        background-size: unset !important;
        @media(min-width: 768px) {
          @include spinScaleHeight(411);
        }
      }

      &.section {
        .threesixty-spin {
          @media(min-width: 768px) {
            @include spinScaleWidth(240);
          }

          @media(min-width: 900px) {
            @include spinScaleWidth(292);
          }

          @media(min-width: 1024px) {
            @include spinScaleWidth(306);
          }

          @media(min-width: 1440px) {
            @include spinScaleWidth(342);
          }
        }
      }
    }
  }

  &.ipad {
    &.landscape {
      .sbs-wrapper {
        .spin-video {
          .threesixty-spin {
            @include spinScaleHeight(316);
          }

          &.section {
            .threesixty-spin {
              @media (max-width: 1024px) {
                @include spinScaleWidth(306);
              }
            }
          }
        }
      }
    }

    &.portrait {
      .sbs-wrapper {
        .spin-video {          
          .threesixty-spin {
            @include spinScaleHeight(247);
          }

          &.section {
            .threesixty-spin {
              @include spinScaleWidth(307);

              @media(min-width: 1024px) {
                @include spinScaleWidth(447);
              }

              @media (max-width: 769px) and (min-width: 900px) {
                @include spinScaleWidth(363);
              }
            }
          }
        }
      }
    }
  }
}
