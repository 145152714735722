$os-gr : linear-gradient(164deg, rgba(12,82,223,1) 0%, rgba(238,129,248,1) 68%, rgba(253,230,255,1) 99%);


.signup,
.reset_password {
  padding-bottom: 0;

  .layout {
    min-height: unset;
    padding-bottom: 0;
  }
}

.login-email-group {
  + .email-error {
      transform: translateY(-15px);
      line-height: normal;
    }
}

%subs-term {
  margin: 0 0 2rem;
  position: relative;
  padding-top: 32px;
  padding-left: 28px;
  @include font-text(600, 16px, 1.5);

  .subs-term-label {
    padding-left: 0;

    .check-icon {
      left: -28px;
      top: 4px;
    }
  }

  .subs-term-label,
  .a-link {
    font-size: inherit;
    font-weight: inherit;
  }

  .form-error {
    margin-top: 0;
  }
}
.onboard-container,
.onboard-success-container {
  max-width: 576px;
  padding: 0 12px 52px;
  margin: auto;

  .subs-term {
    margin: 0 0 2rem;
    position: relative;
    padding-top: 32px;
    padding-left: 28px;
    @include font-text(600, 16px, 1.5);

    .subs-term-label {
      padding-left: 0;

      .check-icon {
        left: -28px;
        top: 4px;
      }
    }

    .subs-term-label,
    .a-link {
      font-size: inherit;
      font-weight: inherit;
    }

    .form-error {
      margin-top: 0;
    }
  }

  @include hideInputNumerArrow;

  .form-control {
    padding-left: 12px;
    padding-right: 12px;
  }

  .osbtn {
    padding-left: 16px;
    padding-right: 16px;
  }

  .password-hint,
  .form-error {
    position: absolute;
    display: block;
    font-size: 12px;
    line-height: 16;
    margin-top: 5px !important;
  }

  .password-hint {
    color: $level-9;
  }

  .code-hint-text {
    white-space: nowrap;
    margin-right: 10px;

    + .os-btn-link {
      margin-left: 0 !important;
      background: none;
      box-shadow: none;
      border: 0;
      display: inline;
      padding: 0;

      &:focus, &:active {
        border: 0px;
        box-shadow: none !important;
        outline: 0 !important;
      }
    }
  }

  .form-error {
    &.code-hint-text {
      margin-top: 5px !important;
    }

    &:empty + .code-expire-note {
      margin-top: 5px;
    }
  }

  .code-expire-note {
    margin-top: 17px;
  }
}

.board-current-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -12px;

  .current-label-holder {
    padding: 0 12px;
  }

  .holder-1 {
    width: 184px;
  }

  .holder-2 {
    width: 212px;
  }

  .holder-3 {
    width: 180px;
  }
}

.onboard-role-dropdown.header-dropdown-menu {
  font-size: 1rem;

  .page-heading-title {
    > span {
      display: block;
      line-height: 40px;
      padding-left: 16px;
    }
  }

  .ifill-os-chevron-down,
  .ifill-os-chevron-up {
    right: 12px;
    margin-top: 0;
  }

  .header-dropdown-list {
    width: 100%;
    border-radius: 0 0 4px 4px;
    margin-top: -2px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .header-dropdown-list-item {
    font-size: 16px;
  }

  .header-dropdown-item {
    line-height: 32px;
    padding: 0 16px;
  }
}

.onboard-container {
  .signup-account-details-card {
    padding: 0;
  }

  .form-group  {
    margin-bottom: 2rem;
    position: relative;
  }

  .next-btn {
    min-width: 168px;
    font-weight: bold;
  }

  .os-radio-label {
    height: calc(100% - 2rem);
    margin-bottom: 2rem;
  }

  .card-action {
    text-align: right !important;

    .osbtn + .osbtn {
      margin-left: 1.5rem;
    }

    &.invite-code-active {
      .osbtn {
        width: 100%;
      }
    }
  }

  .field-icon-group {
    position: relative;
  }

  .custom-checkbox {
    position: relative;
    padding-left: 28px;
    color: $level-11;

    .ifill-os-checkmark-default-white {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      text-align: center;
      line-height: 1.5px;
      font-size: 16px;
    }

    input:checked ~ .ifill-os-checkmark-default-white{
      &:before {
        content: "\e91d";
      }
    }
  }
}

.onboard-login-image {
  width: 100px;
  margin: 65px auto auto;
}

.onboard-welcome {
  margin: 32px 0 62px;
}

.onboard-tabs {
  justify-content: space-between;
  border-color: $border-button-1;
  margin-bottom: 33px;

  .nav-item {
    background: transparent !important;
    border: 0 !important;
    padding: 0 0 8px !important;
    color: $level-9;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1;
    position: relative;
    outline: 0;
    font-size: 12px;

    &::after {
      content: '';
      top: calc(100% - 1px);
      left: 0;
      right: 0;
      position: absolute;
      height: 2px;
      background: transparent;
    }

    &.active {
      color: $level-11;
      &:after {
        background: $level-11;
      }
    }
  }
}

.onboard-label {
  font-weight: normal;
  line-height: 1;
  margin-bottom: 8px;
  display: block;
}

.os-radio-label {
  display: flex;
  min-height: 40px;
  padding: 12px 15px;
  width: 100%;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  position: relative;
  -webkit-appearance: none !important;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;

  .os-radio-text {
    margin: auto;
    position: relative;
    z-index: 1;
    color: $os-link;
  }

  .radio-helper {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px;
    background: transparent;
    -webkit-appearance: none !important;
    border: solid 1px $cl-link;
    transition: all .3s;
  }

  .radio-input {
    display: none;
  }

  .radio-input:checked ~ {
    .radio-helper {
      background: $cl-link;
      border: 0;
    }

    .os-radio-text {
      color: $level-11;
    }
  }
}

.onboard-success-container {
  margin-top: 66px;

  .schedule-call-btn {
    min-width: 168px;
  }

  .congratulations-block {
    .img-block {
      text-align: center;
    }
  }
}


.onboard-success-image {
  width: 128px;
  max-width: 100%;
}

.onboard-success-message {
  margin-bottom: 6rem;
}

.no-touch-support {
  .os-radio-label:hover {
    .radio-input:not(:checked) + .radio-helper {
      color: $level-11;
      border-color: $level-9;
      background-color: transparent;
    }

    .radio-input:checked + .radio-helper {
      color: $level-11;
      background-color: $level-4;
    }
  }
}

.security-row {
  .cc-code-col {
    flex: 0 0 120px;
    max-width: 120px;

    .os__menu {
      width: 200px;

      .os__option--is-focused:not(.os__option--is-selected) {
        background: none;
      }
    }

    .os__menu-list {
      max-height: 180px;
    }

    .os__control {
      cursor: pointer;
      color: $level-9;
      position: relative;
      padding-right: 40px;
      padding-left: 5px;

      &:after {
        position: absolute;
        top: calc(50% - 8px);
        right: 16px;
        content: "\e99d";
        font-size: 16px;
        font-family: 'fill-os-icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
      }

      &.os__control--menu-is-open {
        &:after {
          transform: rotate(180deg);
          color: $level-11;
        }
      }
    }

    .os__single-value {
      color: $level-9;
    }

    .os__value-container {
      padding-left: 5px;
    }
  }

  .p-number-col {
    flex: 0 0 251.31px;
    max-width: 251.31px;
  }

  .code-send-col {
    flex: 1;
    max-width: unset;

    .form-control:focus {
      @include placeholder {
        font-size: 0;
        color: transparent;
        padding-bottom: 5px;
      }
    }
  }
}

.onboard-tabs-content {
  .security-row {
    position: relative;

    .cc-code-col {
      flex: 0 0 117px;
      max-width: 117px;
    }

    .p-number-col {
      flex: 0 0 43.633333%;
      max-width: 43.633333%;
    }

    .code-send-col {
      padding: 0 0px 0px 16px;
    }

    .os-btn-link:not(.osbtn-disabled) {
      cursor: pointer;
    }
  }

  .show-login-2fa {
    display: none;
  }

  .security-row-action {
    margin-top: 24px;
  }
}

@media(min-width: 1400px) {
  .onboard-tabs {
    .nav-item {
      font-size: 16px;
    }
  }
}

@media(min-width: 1024px) and (max-width: 1300px) {
  .onboard-container,
  .onboard-success-container {
    max-width: 576px;
  }

  .security-row {
    .p-number-col {
      flex: 0 0 40.6976%;
      max-width: 40.6976%;
    }
  }
}


.mobile-device,
.ipad {
  .onboard-container {
    .form-control {
      padding-top: 0;
      padding-bottom: 0;
      line-height: normal;
    }
  }
}


.ipad {
  .security-row {
    .cc-code-col {
      flex: 0 0 124px;
      max-width: 124px;
    }

    .code-send-col {
      flex: 1;
      max-width: unset;

      .form-control:focus {
        @include placeholder {
          font-size: 0;
          color: transparent;
          padding-bottom: 5px;
        }
      }
    }
  }

  &.portrait {
    .onboard-container {
      .next-btn {
        min-width: 162px;
      }
    }
    .navbar-unauth {
      .login-btn {
        margin-left: 8px;
      }
    }
  }

  &.landscape {
    .onboard-container {
      .next-btn {
        min-width: 222px;
      }

      .verify-btn {
        min-width: 168px;
      }
    }
  }
}


.mobile-device{

  .card-action {
    &.invite-code-active {
      padding-top: 0px !important;

      .osbtn {
        width: auto;
      }
    }
  }

  .signup {
    @media(max-width: 340px) {
      .board-current-status {
        margin: 0 -6px;

        .current-label-holder {
          padding: 0 6px;
        }
      }

      .navbar-unauth-text {
        .osbtn {
          padding: 0 15px;
        }
      }
    }
  }

  .navbar-unauth {
    height: auto;
    padding: 0 16px;

    .login-btn {
      padding: 0 15px;
    }

    .navbar-brand {
      margin-top: 11px;
      margin-bottom: 11px;
    }
  }

  .navbar-unauth-signin-text {
    font-size: 12px;
  }

  .onboard-container {
    max-width: 100%;
    padding-bottom: 2rem;
    padding-right: 0;
    padding-left: 0;

    .os-radio-label {
      min-height: 72px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .next-btn {
      min-width: 160px;
    }

    .form-group {
      margin-bottom: 24px;
    }

    .subs-term {
      padding-top: 4px;

      .form-error,
      .password-hint {
        position: absolute;
      }
    }
  }

  .onboard-welcome {
    font-size: 16px;
    font-weight: 600;
    margin: 28px 0 48px;
  }

  .onboard-tabs {
    overflow: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: 12px;
    line-height: 1;
    justify-content: initial;

    .nav-item + .nav-item {
      margin-left: 32px;
    }
  }

  .onboard-success-container {
    margin-top: 72px;
    width: 456px;
    max-width: 100%;
    padding: 0 15px;
    margin-top: 31px;

    .schedule-call-btn {
      width: 216px;
    }

    .congratulations-block {
      display: flex;
      justify-content: space-between;

      .img-block {
        max-width: 82px;
        flex: 0 0 82px;
        margin: 0 15px 0 32px;
      }

      &.verification-block-pg {
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .img-block {
          max-width: 100%;
          flex: 0 0 100%;
          margin: 0 15px 24px 32px;
        }

        .text-block {
          text-align: center;

          .head-4 {
            line-height: 1.25rem;
          }
        }

      }
    }

    .head-4 {
      line-height: normal;
    }
  }

  .board-current-status {
    .current-label-holder {
      width: auto;
      flex: 1;
    }
  }

  .security-row-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .resend-text-span {
      line-height: 1.2;
      font-size: 11px;
    }
  }

  .security-row {
    .p-number-col {
      flex: 0 0 calc(100% - 120px);
      max-width: unset;
      padding-right: 8px !important;

      .password-hint,
      .form-error {
        line-height: 1.1;
      }
    }

    .code-send-col {
      flex: 1;
      min-width: 100%;
      margin-top: 24px;

      &.code-not-active {
        .onboard-label {
          display: none;
        }
      }

      .osbtn {
        max-width: calc(100% - 120px);
        margin-left: auto;
      }
    }
  }
  .send-btn-col {
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0 !important;

    .onboard-label {
      display: none;
    }

    .col {
      padding: 0;
    }
  }

  .security-row-action {
    margin-top: 16px;

    .osbtn {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/** new csss starts here **/
.onboard__wrapper {
  position: relative;
  min-height: calc(100vh - 250px);

  .ip-port-only {
    display: none;
  }

  .icon__block {
    width: 66.36%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;

    .svg-icon,
    img {
      max-width: 100%;
      width: 100%;
    }

    &.referral_signup_icon_block {
      width: 58%;
    }

    @media(max-width: 1024px) {
      width: 67.55%;
      top: 23px;
    }

    @media(max-width: 768px) {
      width: 58.1%;
      .ip-port-only-hide {
        display: none;
      }

      .ip-port-only {
        display: block;
      }
    }

    &.login__password_block {
      z-index: -1;
    }
  }

  .with-error {
    position: absolute;
    line-height: 1.4;
    padding-top: 5px;
  }

  .login_password {
    position: relative;

    .ifill-os-views,
    .ifill-os-hide-password  {
      position: absolute;
      right: 10px;
      top: 44px;
      cursor: pointer;
      color: $level-8;

      @media(max-width:767px) {
        top: 49px;
      }
    }
  }
}

.guest-layout {
  body:not(.purchase_course) {
    padding-bottom: 0;
  }
}

.signup,
.login,
.reset_password,
.image-layout-open,
.locked_space {
  .os-main-nav-inner {
    max-width: unset !important;
    width: 100% !important;
    padding: 0 8.33333% !important;
    @media(max-width: 768px) {
      padding: 0 12px !important;
    }
  }

  #root {
    min-height: calc(100vh - 142px);
    height: unset;
    @media(max-width: 768px) {
      min-height: calc(100vh - 82px);
    }
  }

  #footer {
    position: relative;
    margin-top: 42px;
  }
}

.locked_space {
  padding-bottom: 0;
}

.onboard__hero__container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 1.5rem 0 2rem;

  @media (min-width:1025px) {
    padding: 0 8.33333%;
  }

  @media(max-width: 1024px) {
    padding: 0 2rem 0 3rem;
  }
}


.onboard__form {
  @include minmaxwidth(480px);
  padding-top: 20px;

  @media(max-width: 1024px) {
    @include minmaxwidth(386px);
  }

  @media(max-width: 768px) {
    @include minmaxwidth(348px);
  }

  .subs-term {
    width: 100%;
    padding: 0 12px;
    margin-top: 15px;
    .form-group  {
      margin-bottom: 0;
    }

    .form-error {
      position: absolute;
    }
  }

  &.signup_onboard__form {
    .cmn-form-box {
      min-height: 630px;
    }
  }

  &.onboard__password__form {
    .reset_login_password {
      min-height: 116px;
    }
  }
}

.qlark-payment-method {
  display: none;
}

.cmn-form-box {
  background: $login-bg;
  border-radius: 8px;
  padding: 0 2.5rem;
  width: 100%;

  @media(max-width: 1024px) {
    padding: 0 1.25rem 1rem;
  }

  @media(max-width: 768px) {
    padding: 2.5rem 1.25rem 3rem;

    .btn__signup,
    .btn__login,
    .btn__reset_password {
      margin-top: 40px;
    }
  }

  .form-heading,
  .modal-title {
    font-size: 1.5rem;
    font-weight: 900;
    padding-bottom: 2rem;
    color: $heading;

    @media(min-width: 768px) {
      padding-bottom: 4rem;
    }
  }

  .modal-title {
    text-align: center;
  }

  .form-heading-small {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 24px;
    color: $heading;
    text-transform: uppercase;
  }

  .form-control {
    &#password,
    &#password_validate,
    &#confirm_password {
      padding-right: 30px;

      &[type="password"]{
        font-size: 18px;
        letter-spacing: 3px;
      }
    }
  }

  .mt--12 {
    margin-top: -12px;
    margin-right: -16px;

    @media screen and (max-width: 767px) {
      margin-top: -7px;
      margin-right: 0;
      padding: 0;
    }
  }

  .btn__login,
  .btn__signup {
    min-width: 200px;
  }

  .btn__reset_password {
    min-width: 240px;
    margin-top: 2.25rem;
  }

  .btn__signup {
    margin-top: 4rem;
  }

  @media(min-width: 1351px) {
    .btn__reset_password,
    .btn__login {
      margin-top: 4rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .hide-modal-only {
    display: none;

    &.visible-mobile {
      display: flex;
    }
  }

  .login-form-hint {
    padding-top: 15px;
  }

  .os-btn-link:not(.forgot-pass-btn) {
    font-size: 0;

    .btn-link-helper {
      position: relative;
      left: unset;
      right: unset;
      top: unset;
      bottom: unset;
      font-size: 12px;

      &::after {
        content: attr(data-text);
      }
    }

    &.fs-12 {
      .btn-link-helper {
        font-size: 12px;
      }
    }
  }

  .login-form-hint {
    .os-btn-link:not(.forgot-pass-btn) {
      .btn-link-helper {
        font-size: 16px;
      }
    }
  }

  .subs-term {
    .os-btn-link {
      .btn-link-helper {
        left: -3px;
      }
    }
  }


  &.pay-detail-box {
    padding: 1.5rem 2.5rem;
    margin-bottom: 1.5rem;


    @media(min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
      flex-direction: column;
    }

    @media(max-width: 768px) {
      padding: 1.5rem 1.5rem .5rem;
    }

    @media(max-width: 768px) {
      padding: 1.56rem 1.25rem 28px;
    }

    @media(max-width: 767px) {
      padding-bottom: 8px;

      + div >  .btn__login {
        width: 224px !important;
        max-width: 224px !important;
        line-height: 48px;
        min-height: 48px;
        margin-bottom: 80px;
        margin-top: 16px;
        border-radius: 8px;
      }

      .card-holder-col {
        &.order-2 {
          order: 4 !important;
        }
      }

      .form-label-text {
        margin-bottom: 0.5rem;
      }
    }

    .form-label-text {
      white-space: nowrap;
      margin-bottom: 0.5rem;
    }

    .form-label-block {
      padding-bottom: 24px;

      iframe {
        opacity: 0.9;
      }
    }

    .qlark-payment-method {
      display: flex;
      align-items: center;

      ul {
        list-style: none;
        padding: 0;
        margin: 0 0 0 8px;
        display: flex;
        align-items: center;

        li {
          width: 24px;
          height: 16px;
          border-radius: 2px;
          display: inline-flex;
          align-items: center;
          background: $block;
          overflow: hidden;

          img {
            width: 100%;
          }

          &.master_card {
            background: #252525;
          }

          &.amex_card {
            background: #006fcf;
          }

          + li {
            margin-left: 8px;
          }
        }

      }
    }
  }

  &.course-buy-info-box {
    padding: 1.25rem 1.25rem 36px;

    @media(min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
      flex-direction: column;
    }

    @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      padding: 16px 16px 28px;

      .user-ins {
        display: none !important;
      }
    }

    @media(max-width: 767px) {
      padding-bottom: 28px;
    }

    .course-info-flex {
      justify-content: flex-start;

      .mr-md-4 {
        margin-right: 24px !important;
        align-items: flex-start !important;

        i {
          margin-top: 3px;
        }

        &:nth-child(2) {
          white-space: nowrap;
        }

        &:last-child {
          margin-right: 0 !important;
        }
      }

      @media(max-width: 1024px) {
        justify-content: space-between;

        .mr-md-4 {
          margin-right: 0 !important;
        }
      }
    }

    .course-name-head {
      font-size: 16px;
      padding-top: 4px;
      @include multiline-truncate(2);
      overflow: hidden;
      margin-bottom: 32px;
    }

    .total-pay {
      @include fontstyle(20px, 600, 28px);
    }

    .total-pay-amount {
      @include fontstyle(24px, bold, 32px);
    }

    .card-v2-hero {
      width: 100%;
      height: 80px;
      border-radius: 8px;
      margin-top: 40px;

      .img-parent {
        overflow: hidden;
        height: 100%;
        width: 100%;
        border-radius: 8px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }
  }

  .reset-password-hint {
    font-size: 12px;
    color: $level-9;
    line-height:1.33;
    padding-top: 14px;
    display: block;
  }
}

.modal {
  .form-control[id*="password"] {
    padding-right: 30px;

    &[type="password"]{
      font-size: 18px;
      letter-spacing: 3px;
    }
  }
}

.onboard__modal {
  max-width: 480px;

  .modal-content {
    padding: 1rem 1.5rem 1.5rem;
    border-radius: 8px;
  }

  .hide-in-modal,
  .mobile-signup-footer,
  .mobile-login-footer {
    display: none;
  }

  .os-btn-link {
    cursor: pointer;

    &.reset-button {
      @media(max-width: 767px) {
        display: inline-block;
      }
    }
  }

  .form-heading {
    margin-bottom: 40px;
    @include font-text(600, 20px, 1.4);
    color: $heading;
  }

  @media(min-width: 768px){
    .login-form-heading {
      ~ .form-error {
        white-space: nowrap;
        letter-spacing: -0.002rem;
      }
    }
  }

  label {
    color: $level-11;
  }

  .form-group {
    margin-bottom: 1.5rem;
    position: relative;
  }

  .modal-footer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 24px 0 0;
    font-size: 12px;
    text-align: center;

    .os-btn-link {
      color: inherit;
      margin: 0 5px;
    }
  }

  .btn__signup {
    display: block;
    margin-top: 40px;
    width: 100%;
  }

  .btn__login {
    min-width: 240px;
    margin-top: 32px;
  }

  .subs-term {
    padding: 0 12px;
    width: 100%;
    margin: 4px 0 0;

    .form-error {
      position: absolute;
    }
  }

  .with-error {
    position: absolute;
    padding-top: 2px;
    line-height: 16px;
  }

  .form-control#password,
  .form-control#password_validate {
    padding-right: 30px;

    &[type="password"]{
      font-size: 18px;
      letter-spacing: 3px;
    }
  }

  .ifill-os-views,
  .ifill-os-hide-password  {
    position: absolute;
    right: 10px;
    top: 44px;
    cursor: pointer;
  }

  .login-form-hint {
    color: $level-8;
    padding-top: 8px;
    .os-btn-link {
      display: inline-block;
      font-weight: $font-weight-normal;
    }
  }
}

.login,
.signup {
  .with-error {
    line-height: 16px;
    .a-link {
      font-size: 12px;
    }
  }
}

.reset_password {
  @media (min-width: 768px) {
    .navbar {
      margin-bottom: 50px;
    }
  }
}

.ipad {
  .cmn-form-box {
    .btn__signup {
      margin-top: 40px;
    }

    .btn__login {
      margin-top: 50px;
    }
  }

  &.portrait {
    .form-heading {
      padding-bottom: 2rem;
    }

    .login-form-heading {
      padding-bottom: 3rem;
    }

    .ip-port-only {
      display: block;
    }

    .onboard__form {
      padding-top: 0;

      &.signup_onboard__form {
        .cmn-form-box {
          min-height: unset;
          height: 536px;
          display: flex;

          form {
            margin: auto;
          }

          .row {
            [class^="col-"] {
              max-width: 100%;
              flex: 100%;
            }
          }
        }
      }
    }

    .login,
    .signup {
      .navbar {
        height: 64px;
      }

      #root {
        min-height: calc(100vh - 150px);
      }
    }

    .login,
    .signup,
    .reset_password {
      .os-main-nav-inner {
        padding: 0 2rem !important;
      }
    }

    .onboard__hero__container {
      padding-right: 1.5rem;
      padding-left: 2rem;
    }

    .onboard__wrapper {
      min-height: unset;

      .icon__block {
        bottom: unset;
        top: 170px;

        @media (min-width: 1024px) {
          width: 68%;
        }

        &.login__password_block {
          width: 76%;
          top: 0;
          padding: 0;
        }
      }

      .onboard__password__form {
        .cmn-form-box {
          padding-top: 40px;
          padding-bottom: 48px;
        }

        .login-form-heading {
          padding-bottom: 4.375rem;
        }
      }

      .ip-port-only-hide {
        display: none;
      }

      .login-email-group {
        margin-bottom: 2rem;
      }

      @media(width: 1024px) {
        &.login__password_block {
          width: 73%;
        }

        .onboard__password__form {
          .cmn-form-box {
            min-height: 680px;
          }
        }

        .login__password_block {
          top: -40px;
        }
      }
    }
  }

  &.landscape {
    @media(width: 1024px) {
      .image-form-container {
        padding-top: 50px;
      }

      .signup,
      .login,
      .reset_password {
        .os-main-nav-inner {
          padding: 0 2rem 0 3rem !important;
        }
      }
    }

    .onboard__login__wrapper {
      z-index: 11;
    }

    .cmn-form-box {
      display: flex;

      form {
        margin: auto;
      }
    }

    .onboard__wrapper {
      .onboard__password__form {
        .cmn-form-box {
          padding-top: 40px;
          padding-bottom: 56px;
          min-height: 480px;

          .form-group {
            margin-bottom: 1rem;
          }
        }


        .login-form-heading {
          padding-bottom: 40px;
        }
      }

      @media(min-width: 1300px) {
        .icon__block {
          width: 64.6%;
          top: 35px;
        }

        .login__password_block {
          width: 65%;
          padding-top: 0;
          top: -32px;
        }
      }



      @media(max-width: 1180px) {
        .login__password_block {
          width: 68.1%;
          padding-top: 0;
          top: -32px;
        }

        .signup_onboard__form {
          .cmn-form-box {
            min-height: 480px;
          }
        }
      }

      .ip-port-only-hide {
        display: block;
      }
    }

    .onboard__form {
      width: 480px;
      padding-top: 0;
    }

    .cmn-form-box {
      .btn__signup,
      .btn__login {
        margin-top: 48px;
      }

      .btn__login {
        margin-top: 24px;
      }
    }
  }

  .login,
  .signup,
  .reset_password {
    .navbar {
      positon: reltaive !important;
      transform: translateY(0) !important;
    }
  }
}

.no-touch-support:not(.ipad) {
  .onboard__wrapper {
    .icon__block {
      position: relative;
    }

    .onboard__form {
      max-width: 33.33%;
      min-width: 33.33%;
      position: absolute;
      top: 0;
      left: 58.1%;
      bottom: 14.5%;

      .cmn-form-box {
        height: 100%;
        display: flex;

        form {
          width: 100%;
          margin: auto;
        }
      }

      &.signup_onboard__form:not(.referral_signup_form) {
        @media(max-width: 1350px) and (min-width: 768px) {
          padding-top : 0;

          .cmn-form-box {
            padding: 0 1.25rem 1rem;
            min-height: 480px;
          }

          .btn__signup {
            margin-top: 48px;
          }

          .login-form-heading {
            padding-bottom: 2.5rem;
          }
        }
      }
    }

    .os-btn-link {
      cursor: pointer;
    }

    &.onboard__wrapper_signup {
      @media(max-width: 1350px) and (min-width: 1000px) {
        height: 528px;

        .icon__block {
          top: 28px;
        }

        .signup_onboard__form {
          width: 37.6953%;
          max-width: 386px;
        }

        .row {
          margin: 0 -4px;

          [class^="col-"] {
            padding: 0 4px;
          }
        }

        .subs-term {
          padding: 0 4px;
          margin-top: 7px;
        }
      }
    }

    .login__password_block {
      width: 73.5%;
      padding-top: 0;
      top: -32px;
      padding-left: 8.3%;
    }

    &.onboard__login__password_wrapper {
      z-index: 11;

      .onboard__password__form {
        bottom: unset;
        top: 0;
        padding-top: 0;

        .cmn-form-box {
          height: unset;
          min-height: 480px;

        }
        @media (min-width: 1350px) {
          padding-top: 3%;

          .cmn-form-box {
            min-height: 630px;
          }
        }
      }
    }
  }

  @media(max-width: 1350px) and (min-width: 1200px) {
    .signup,
    .login,
    .reset_password {
      .os-main-nav-inner {
        padding-left: 4.6875% !important;
        padding-right: 4.6875% !important;
      }

      .login__password_block {
        padding-left: 4.6875%;
      }
    }
  }

  @media(max-width: 1350px) and (min-width: 1100px) {
    .reset_password {
      .login__password_block {
        width: 68.3%;
      }
    }
  }

  @media(max-width: 1099px) and (min-width: 768px) {
    .reset_password {
      .login__password_block {
        width: 70.3%;
      }
    }
  }
}

.image-form-container {
  .has-disabled-input {
    margin-bottom: 14px !important;
    
    label {
      color: $level-6 !important;
      pointer-events: none;
    }

    .form-control:disabled {
      pointer-events: none;
      background: $level-3;
      color: $level-6;
      border-color: $level-4 !important;
    }
  }
  .care_signup-row {

    @media(min-width: 768px) {
      .form-group {
        margin-bottom: 30px;
      }
      .prefilled-email {
        padding-bottom: 30px;
      }
    }

    .prefilled-email {
      font-size: 12px;
      margin-top: -8px;
      padding-left: 16px;

      .ifill-os-fill-error  {
        color: $lt-red;
      }
    }

    .btn__referral_signup {
      margin-top: 17px;
      min-width: 240px;
    }

    .reset-password-hint {
      padding-top: 6px;
    }

    .with-error {
      + .reset-password-hint {
        transform: translateY(14px);
      }
    }
  }
}

.care_signup-rightBar {
  .subs-term {
    .d-flex,
    .d-flex div {
      display: inline !important;
    }
  }

  .mobile-signup-footer {
    margin-top: 10px;
  }
}

.update_number-leftBar {
  text-align: center;
}

.referral_signup_icon_block  {
  max-width: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  max-height: 792px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

@media(max-width: 767px) {
  .onboard__wrapper {
    min-height: unset;

    .icon__block {
      width: 100%;
      position: unset;
      text-align: center;

      img {
        width: 34.93%;
      }

      .login-illustration {
        width: auto;
        margin-left: -30px;
      }
    }

    .onboard__hero__container {
      padding: 0 1rem;

      .onboard__form {
        min-width: unset;
        max-width: 100%;
      }
    }

    .cmn-form-box {
      background: none;
      padding: 0;

      .form-heading {
        padding-bottom: 32px;
        font-size: 28px;
        padding-top: 52px;
      }

      .reset_login_password {
        min-height: 132px;

        .reset-password-hint {
          padding-top: 8px;
        }
      }

      .btn__signup,
      .btn__login,
      .btn__reset_password {
        min-width: 240px;
        height: 40px;
        line-height: 40px;
        margin-top: 24px;
      }

      .form-control {
        height: 48px;
      }

      .mobile-login-footer,
      .mobile-signup-footer {
        padding-top: 74px;
        p {
          display: inline;
          color: $level-11;
          padding-right: 8px;
        }
      }

      .mobile-signup-footer {
        padding-top: 32px;

        p{
          padding-right: 4px;
        }
      }

      .form-group {
        margin-bottom: 2rem;

        &.login_password {
          margin-bottom: 8px;
        }

        &.last-name-signup {
          margin-bottom: 1rem;
        }
      }

      .subs-term {
        margin-top: 7px;

        > .d-flex {
          white-space: nowrap;
        }

        .form-error {
          position: absolute;
        }
      }
    }

    .login-form-hint {
      padding-bottom: 8px;
    }
  }

  .signup,
  .login,
  .reset_password {
    .navbar-unauth-text {
      display: none;
    }

    .os-main-nav-inner {
      padding: 0 0.75rem !important;
    }

    #footer {
      height: 40px;
      padding: 0;
      display: flex !important;
      align-items: center;
      font-size: 8px;
      width: 100%;
      > div {
        width: 100%;
      }

      .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .support {
        align-items: center;
        li {
          margin-left: 16px;
        }

        .os-btn-link {
          font-size: inherit;
          line-height: 2;
        }
      }

      .social {
        font-size: 16px;
      }
    }
  }

  .onboard__password__form {
    padding-top: 32px;
  }

  .mobile-device {
    .signup,
    .login,
    .reset_password,
    .image-layout-open {
      .navbar-unauth {
        height: 64px;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: solid 1px rgba(202, 194, 194, 0.10);
      }
    }

    &.guest-layout {
      .onboard__modal {
        .mobile-login-footer,
        .mobile-signup-footer {
          display: block;
          padding-top: 66px;

          p {
            color: $level-11;
          }
        }

        .mobile-signup-footer {
          padding-top: 40px;
        }
      }
    }
  }

  .guest-user-view {
    .os-main-nav-inner {
      .osbtn {
        width: 80px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0 !important;

        &[text*="Sign up"],
        &[text*="request to join"] {
          width: auto;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}

.mobile-device {
  .onboard__login__wrapper {
    .onboard__login__form {
      .os-btn-link {
        display: inline-block;
      }
    }
  }
}

.btn__referral_signup {
  margin-top: 70px;
  min-width: 200px;
}

.ipad {
  .onboard__form {
    &.referral_signup_form {
      position: absolute;
      right: 2rem;
      height: auto;

      .cmn-form-box {
        min-height: 630px !important;
        height: auto !important;

        @media screen and (min-width: 1024px) and (max-width: 1365px) {
          min-height: 770px !important;
        }
      }
    }
  }

  .onboard__wrapper_referral {
    min-height: calc(100vh - 250px) !important;
    overflow-x: hidden;
  }


  &.portrait {
    .referral_signup_icon_block {
      img {
        position: absolute;
        max-width: none;
        height: 100%;
        right: -43%;

        @media screen and (min-width: 1024px) {
          right: -57%;
        }
      }
    }

    .login-illustration {
      margin-left: -15%;
      transform: scale(1.3);
      margin-top: 50px;
    }
  }

  &.landscape {
    .onboard__form {
      &.referral_signup_form {
        right: 8.33333%;
      }
    }

    .login-illustration {
      margin-left: -12%;
      transform: scale(1.2);
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .onboard__hero__container {
    flex-wrap: wrap;
  }
}


.clinic-team-logo-text {
  color: $level-11;
  @extend %heading-2-bold;
  @include fontstyle(20px, 600, 1.33);
  vertical-align: middle;
}

.no-touch-support:not(.ipad) {
  &.workspace,
  &.care  {
    .clinic-team-logo-text {
      font-size: 20px;
      line-height: 22px;
    }

    .login,
    .signup  {
      .navbar {
        .logo img {
          max-width: 258px !important;
        }

        .clinic-team-logo-text {
          font-size: 20px;
          line-height: 22px;
        }

        .header-list-icon {
          margin: 0px 12px;
          font-size: 16px;
        }
      }
    }
  }
}

.workspace,
.care {
  &.guest-layout {
    .navbar .logo img {
      margin-right: 8px;
      max-width: 24px;
    }
  }
}

.mobile-device {
  &.workspace, &.care {
    .login,
    .signup  {
      .navbar {
        .logo img {
          width: 174px;
        }

        .clinic-team-logo-text {
          @include fontstyle(20px, 300, 1.33);
        }
      }
    }
  }

  &.workspace {
    .login,
    .signup  {
      .navbar {
        .logo img {
          width: 60px;
        }
      }
    }
  }
}

.no-touch-support:not(.ipad)  {
  &.patient-layout {
    body {
      #root {
        > div {
          min-height: 100%;
        }
      }

      .onboard__wrapper {
        height: 100%;
        overflow-y: auto;
      }

      .navbar.os-main-nav {
        background: $level-2;
        box-shadow: none;

        .os-main-nav-inner {
          align-items: center;
          padding: 0 96px;
        }

        .navbar-brand {
          .logo-workspace {
            max-height: 24px;
          }

          .clinic-team-logo-text {
            font-size: 20px;
            line-height: 22px;
          }

          i {
            font-size: 16px;
            margin: 0px 12px;
          }
        }
      }
    }
  }
}


.ipad {
  &.patient-layout {
    .navbar.os-main-nav {
      .os-main-nav-inner {
        padding: 0 2rem 0 3rem;
      }

      .navbar-brand {
        .logo-workspace {
          max-height: 22px;
        }

        .clinic-team-logo-text {
          font-size: 20px;
          line-height: 22px;
        }

        i {
          font-size: 16px;
          margin: 0px 12px;
        }
      }
    }
  }
}

.mobile-device {
  &.patient-layout {
    .navbar.os-main-nav {
      .navbar-brand {
        display: inline-flex;
        .logo {
          padding-right: 0;
        }

        .logo-workspace {
          max-height: 22px;
        }

        .clinic-team-logo-text {
          font-size: 20px;
          line-height: 22px;
        }

        i {
          font-size: 16px;
          margin: 0px 12px;
        }
      }
    }
  }
}

@media(max-width: 992px) and (min-width: 768px) {
  .container.image-form-container {
    max-width: 95%;
    padding-top: 50px;

    .ws-user-form .cmn-form-box {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.guest-layout {
  .os-main-nav {
    transform: none !important;
  }  
}