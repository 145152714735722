.no-touch-support,
.ipad {
  .tools,
  .cases,
  .companies,
  .courses,
  .spaces,
  .users,
  .comparisons,
  .records {
    .case-details-container {
      padding-top: 24px;
    }

    .head-sticky.big-sticky {
      padding-top: 16px;
      padding-bottom: 16px;
      min-height: 72px;

      &.fixed {
        &::before {
          height: 72px;
        }

        .page-heading-title {
          padding-top: 0 !important;
        }
      }

      .object-view-switcher {
        margin: 0;
        align-items: center;
      }
    }
  }

  .object-view-switcher {
    .sorting-holder {
      min-width: unset;
      padding: 0 28px 0 0;
      font-size: 16px;
    }

    .btn {
      height: 34px;
    }

    .sorting-icon-block {
      margin-right: 8px;
    }

    .sorting-block-text {
      font-weight: $font-weight-bold;
    }

    .ifill-os-recent {
      position: relative;
      top: 1.5px;
    }
  }
}


.ipad.portrait {
  .users,
  .tools {
    .card--short.card--loading {
      padding-bottom: 50%;
    }
  }
}

.mobile-device {
  .records {
    .back-link {
      margin-left: 0;
    }
  }
}
