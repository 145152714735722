.os-gray-btn {
  color: #5c5c66;

  &[data-hover="true"]:hover,
  &:hover {
    background-color: transparent !important;
    color: $os-link !important;
  }
}


.icon-span {
  position: absolute;
  right: -4px;
  bottom: -4px;
  background-color: #5c5c66;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* .os-card-v2 {
  .icon-span {
    display: flex;
  }
} */

.patient-name-field {
  .form-control {
    text-transform: capitalize;
  }

  .form-control::placeholder {
    text-transform: none !important;
  }
}

.patient-card-body {
  flex-grow: 1;

  .care-space-card-dropdown{
    right: 36px !important;
  }
}

.patient-card-body-wrapper {
  display: flex;
  flex-direction: row;

  .patient-card-body {
    width: calc(100% - 16px);
  }

  .widget-chevron-icon {
    display: flex;
    position: absolute;
    top: 20px;
    right: 16px;
  }
}

.extra-small-card {
  .widget-chevron-icon {
    display: flex;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.care-space-card-dropdown {
  .header-dropdown-list {
    .osbtn {
      line-height: 40px !important;
      min-height: 40px !important;
      height: 40px !important;
      padding-right: 16px;
      color: $level-10;

      .btn-icon-helper {
        color: $level-8;
      }

      &:hover {
        .btn-icon-helper {
          color: $os-link;
        }
      }
    }
  }
}

.patient-info-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #5c5c66;
  padding-bottom: 4px;

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }
}

.patient-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.patient-cd-clinic-name {
  @include fontstyle(12px, normal, 16px);

  color: $level-9;
}

.patient-cd-address {
  @include fontstyle(12px, normal, 16px);

  color: $level-9;
  margin-top: 4px;
  margin-bottom: 4px;

  @include multiline-truncate(3);

  overflow: hidden;

  .relation {
    font-weight: $font-weight-semibold;
  }

  .mailinfo,
  .phone-text {
    a {
      color: inherit !important;
      font-weight: normal;

      &:hover {
        color: $os-link-hover;
      }
    }
  }

  .patient-extra-info{
    display: flex;
    flex-direction: column;
    gap: 4px;

    .patient-email,.patient-phone{
      max-width: 122px;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}

.patient-card-info {
  display: flex;
  flex-direction: column;
  padding-left: 80px;

  .icon-span {
    @include icon-span(24px);

    background-color: $os-link;

    i {
      font-size: 12px !important;
    }
  }

  .avatar {
    margin-right: 16px;
    position: absolute !important;
    left: 16px;
    top: 16px !important;

  }
}

.pateint-access-tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.5;

  .tab-link-tooltip {
    margin-left: 4px;
    padding-top: 0;
    padding-bottom: 0;
    top: auto;
    bottom: 30px;
    width: 226px;
    background-color: $level-1;
    box-shadow: $block-shadow-xs;

    .tooltip-container {
      box-shadow: none;

      .ifill-os-info {
        top: 20px;
      }
    }

    .tooltip-arrow-top {
      top: auto;
      bottom: -6px;
      display: none;
    }
  }

  &:hover {
    .tab-link-tooltip {
      visibility: visible;
      opacity: 1;
      bottom: 100%;
      z-index: 10;
    }
  }
}

.patient-cd-name {
  .pt-name {
    display: inline;
    padding-right: 8px;
  }

  .etc-icon {
    margin-left: 0;
    margin-right: 8px;
  }

  &:not(.no-access) {
    @include multiline-truncate(2);

    overflow: hidden;
  }

  @extend %heading-4-1-2;

  max-width: 100%;
  padding-right: 40px;
  color: $level-11;

  i {
    font-size: 16px;
    line-height: 16px;
    color: $level-8;

    &.green {
      color: $lt-green;
    }

    &.ifill-os-fill-error,
    &.ifill-os-mute {
      color: $lt-red;
    }
  }
}

.misccase_tags {
  width: 28px;
  height: 16px;
  margin-left: 4px;
  padding: 2px 4px;
  border-radius: 12px;
  background-color: $os-link;
  display: inline-block;
  text-align: center;

  i {
    font-size: 12px;
    line-height: 12px;
    display: block;
  }

  &.only-icon {
    background-color: transparent;
    padding: 0;
    width: 16px;
    border-radius: 0;
    vertical-align: middle;
    margin-left: 8px;

    i {
      font-size: 16px;
      line-height: 16px;
      color: $level-8;

      &.ifill-os-checkmark-round {
        color: $lt-green;
      }

      &.ifill-os-fill-error,
      &.ifill-os-mute {
        color: $lt-red;
      }
    }
  }
}

.patient-message {
  @include fontstyle(12px, normal, 20px);

  color: $level-11;
  font-style: italic;
}

/* big card and min-width card */

.os-lg-care, .os-lg-person {
  @extend %os-sm-card;
  @extend %os-card-base;

  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  .card-dropdown-menu {
    z-index: 1;
    right: 16px;
  }

  .patient-card-footer {
    padding-top: 0;
  }

  .patient-info-actions {
    min-height: 45px;

    .osbtn {
      + .osbtn {
        margin-left: 8px;
      }
    }
  }

  &.patient-304 {
    @extend %os-304;

    padding: 24px 8px;
  }

  .general-patient-detail {
    text-align: center;
  }

  .patient-card-info {
    margin-bottom: 16px;
  }
}

.mailinfo {
  @include text-truncate;
}

.os-8bf-link {
  color: $level-10;
}

/* ---small patient */
.os-sm-care, .os-sm-person {
  @extend %os-sm-card;
  @extend %os-card-base;

  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  .selectable-item {
    min-height: 32px;
    line-height: 32px;
  }

  .patient-info-actions {
    border-bottom: 0;
    padding-left: 63px;
    color: $level-9;
    line-height: 10px;
    font-size: 12px;
    justify-content: flex-start;
    flex-wrap: wrap;

    .btn-normal {
      padding-right: 4px;
      white-space: nowrap;

      i {
        padding-right: 8px;

        &.ifill-os-connection {
          padding-right: 6px;
        }
      }

      + .btn-normal {
        padding-left: 4px;
      }
    }
  }

  .patient-cd-address {
    // display: none;
  }

  .patient-card-footer {
    padding-top: 0;
    margin-top: auto;
  }


  &.patient-240 {
    @extend %os-240;

    .patient-card-body {
      min-height: 50px;
      margin-bottom: 10px;
      display: block;
    }

    .patient-info-actions {
      padding-left: 0;
      flex-wrap: wrap;
      font-size: 0.71rem;

      .osbtn {
        padding-right: 10px;
      }

      .btn-normal {
        padding-right: 4px;
        font-size: 0.71rem;

        + .btn-normal {
          padding-left: 4px;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .pr-6 {
        padding-right: 4px;
      }
    }

    .patient-card-footer {
      padding-top: 0;
    }
  }
}

.patienttor-suggest-modal {
  .os-sm-care, .os-sm-person {
    .osbtn {
      margin-right: 4px;

      + .osbtn {
        margin-left: 4px;
      }
    }
  }
}

.os-sm-person {
  .patient-card-info {
    .icon-span {
      @include icon-span(20px);

      background-color: $os-link;

      i {
        font-size: 12px !important;
      }
    }
  }
}

.etc-icon {
  margin-left: 4px;
  display: inline-block;

  &:not(.os-red) {
    color: $level-8;
  }
}


.locked-care-card {
  .patient-cd-name {
    @include multiline-truncate(0);

    display: flex;
    align-items: flex-start;

    .name {
      @include multiline-truncate(2);
    }

    i {
      margin-left: 8px;
      color: $level-10;
      margin-top: 3px;
    }
  }

  .locked-care-text {
    font-weight: normal;
    color: $level-9;
    margin-top: 4px;

    @include ellipsis-lines(12px, 1.5, 2);
  }

  .action-buttons {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 24px;
    width: 100%;
    left: 0;

    .accept-btn {
      background-color: $btn-primary;
      color: $btn-primary-color;

      .btn-icon-helper {
        color: inherit;
      }
    }
  }
}


.no-touch-support {
  .action-buttons {
    .accept-btn {
      &:hover {
        background-color: $btn-primary-hover;
        color: $level-1 !important;
      }
    }
  }
}

.multi-selection-mode {
  .patient-cd-name {
    @include multiline-truncate(1);
  }
}

/* extra-small-min-card */
.extra-small-min-card {
  .os-sm-care, .os-sm-person {
    @extend %os-extra-sm-card;

    padding: 16px;

    .card-dropdown-menu {
      .os-gray-btn {
        width: 100%;
        color: $level-11;
        padding-left: 40px;
        display: block;
        text-align: left;

        .btn-icon-helper {
          left: 16px;
          transform: translateY(-50%);
        }
      }
    }

    .mailinfo, .phone-number {
      display: none;
    }

    .patient-card-info {
      .patient-cd-name {
        @include multiline-truncate(1);

        font-size: 14px;
        line-height: 16px;
      }
    }

    .patient-cd-address {
      line-height: 14px;
      font-size: 10px;
      padding-right: 34px;
    }

    .patient-card-footer {
      @include extra-small-card-footer;

      .osbtn-icon {
        padding: 0 8px;
        color: #5c5c66;

        .btn-icon-helper {
          font-size: 24px;
        }

        &:hover {
          color: $os-link;
        }
      }
    }
  }

  .locked-care-card {
    height: 160px;
    max-height: 160px;
  }
}




.patient-detail-widget {
  .ac-btn {
    i {
      font-size: 20px;
    }
  }

  .extra-small-min-card {
    .os-sm-person {
      height: fit-content !important;
      max-height: none !important;
      padding: 12px;
    }

    .user-image-edit-btn {
      left: 12px;
      top: 12px;
      background-color: rgba(255, 255, 255, 70%);

      @media screen and (max-width: 1919px) {
        min-width: 32px;
        min-height: 32px;
        width: 32px;
        height: 32px;
      }
    }

    .patient-card-info {
      padding-left: 48px;

      .patient-cd-name {
        font-size: 12px;

        .badge {
          display: table-cell;
          vertical-align: middle;
        }
      }

      .pt-name {
        display: inline-block;
        max-width: 60%;

        @media screen and (max-width: 1440px) {
          max-width: 50%;
        }
      }

      .avatar {
        top: 12px !important;
        left: 12px;
      }
    }

    .card-dropdown-menu{
      top: 20px;
    }

    .phone-text, .mailinfo {
      display: none;
    }

    .patient-cd-address {
      font-size: 12px;

      .patient-extra-info{
        display: flex;
        flex-direction: column;
        gap: 4px;

        .patient-email,.patient-phone{
          max-width: 122px;
          overflow-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }

    .person-card-tr:not(:empty) {
      + .relation {
        display: none;
      }
    }


    @media screen and (min-width: 1920px) {
      .avatar-32 {
        @include avatar(48px);
      }

      .os-sm-person {
        height: 80px;
        max-height: 80px;
        padding: 16px 12px;
      }

      .user-image-edit-btn {
        min-width: 48px;
        min-height: 48px;
        width: 48px;
        height: 48px;
      }

      .patient-card-info {
        padding-left: 64px;

        .patient-cd-name {
          font-size: 16px;
          line-height: 20px;
        }

        .avatar {
          top: 12px !important;
          left: 12px;
        }
      }

      .patient-cd-address {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.add-patient-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;

  & + div {
    padding: 4px;
  }

  .patient-firstname-field {
    grid-column: 1 / 4;
  }

  .patient-lastname-field {
    grid-column: 4 / 7;
  }

  .patient-preferredname-field {
    grid-column: 7 / 9;
  }

  .patient-dob-field {
    grid-column: 9 / 11;
  }

  .patient-gender-field {
    grid-column: 11 / 13;
  }

  .patient-id-field {
    grid-column: 1 / 4;
  }

  .patient-doctor-field {
    grid-column: 4 / 7;
  }

  .patient-cell-field {
    grid-column: 7 / 10;
  }

  .patient-email-field {
    grid-column: 10 / 13;
  }

  .patient-street-field {
    grid-column: 1 / 5;
  }

  .patient-apt-field {
    grid-column: 5 / 7;
  }

  .patient-city-field {
    grid-column: 7 / 9;
  }

  .patient-state-field {
    grid-column: 9 / 11;
  }

  .patient-zip-field {
    grid-column: 11 / 13;
  }

  .patient-country-field {
    grid-column: 1 / 4;
  }

  .patient-dob-field {
    &:has(.with-error) {
      .react-date-picker {
        .react-date-picker__wrapper {
          border-color: #df383e !important;
          border-width: 1px !important;
        }
      }
    }
  }

  .date-calendar{
    .form-group{
      display: flex;
      flex-direction: column;

      label{
        font-family: Inter;
        font-size: 16px;
      }

      .cool-date-picker{
		color: #354554;

        .react-date-picker__wrapper{
          height: 40px;
          border-color: #eff1f4;
          border-radius: 8px;
          padding: 0 10px 0 16px;

		  input {
			color: #354554;
		  }
        }

        .react-date-picker__wrapper:hover{
          border-color: #4A749A;
        }
      }
    }
  }
}

.guardian-form {
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  grid-column-gap: 24px;

  .guardian-firstname-field {
    grid-column: 1 / 4;
  }

  .guardian-lastname-field {
    grid-column: 4 / 7;
  }

  //.guardian-preferredname-field {
  //  grid-column-start: 7;
  //  grid-column-end: 10;
  //}

  .guardian-email-field {
    grid-column: 7 / 10;
  }

  .guardian-phone-field {
    grid-column: 10 / 13;
  }

  .guardian-relationship-field {
    grid-column: 1 / 3;
  }

  .guardian-address {
    grid-column: 3 / 5;
  }

  .patient-street-field {
    grid-column: 5 / 7;
  }

  .patient-apt-field {
    grid-column: 7 / 9;
  }

  .patient-city-field {
    grid-column: 9 / 11;
  }

  .patient-state-field {
    grid-column: 1 / 3;
  }

  .patient-zip-field {
    grid-column: 3 / 5;
  }

  .patient-country-field {
    grid-column: 5 / 7;
  }
}
