$plan-gutter : 24px;

.plan-subscription-change {
  max-width: 1110px;
  margin: 0 auto 30px;
  padding: 0 15px;

  .ifill-os-checkmark-round {
    color: #48acfd;
    font-size: 24px;
  }
}

.plan-heading {
  font-size: 1.5rem;
  font-weight: $font-weight-light;
  margin-bottom: 1.9375rem;
}

.subscription-change-holder {
  padding: $plan-gutter;
  background: #e6e6e68;
  border-radius: 10px;
}

.subscription-col-left,
.subscription-col-right {
  word-break: break-word;
}

.subscription-col-left {
  flex: 0 0 50.387596%;
}

.plan-heading,
.small-plan-heading,
.plan-name-heading {
  font-weight: $font-weight-light;
}

.small-plan-heading {
  font-size: .875rem;
  line-height: 1.43;
}

.plan-detail-table {
  margin: 0;
  width: 100%;

  tr:first-child {
    td {
      border-top: 0;
    }
  }

  td {
    line-height: 1;
    padding: 5px 0;
    vertical-align: middle;
    border-top: solid .5px #646464;
    height: 47px;
  }

  .small {
    font-weight: normal;
    font-size: 12px;
    line-height: 1.33;
  }

  .big {
    font-size: inherit;
    width: 90px;
    text-align: center;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.plan-name-heading {
  font-size: 24px;
  line-height: 1;
  padding: 20px 0 10px;
}

.subscription-change-footer {
  margin-top: 4rem;

  .btn-subs-change {
    min-width: 144px;
    margin: 0 0 0 2rem;
    &:disabled {
      background: #fff;
      color: #ccc;
    }
  }
}

.new-subs-info {
  letter-spacing: 0.39px;
  font-size: .75rem;
  line-height: 1.35;
  font-weight: $font-weight-light;
}

.subs-pp-list {
  margin: 14px 0 10px;

  .subs-pp-list-item {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    font-weight: $font-weight-light;
    align-items: center;
  }

  .subs-price-text {
    letter-spacing: 0.24px;
  }

  .subs-pp-final-item {
    font-weight: normal;
    padding-bottom: 12px;
    padding-top: 20px;

    .subs-price-text {
      letter-spacing: 0.3px;
      font-weight: $font-weight-bold;
      line-height: 1.6;
      font-size: 1.25rem;
    }
  }
}

.subs-term {
  font-size: 12px;
  line-height: 16px;
  margin-top: 22px;

  a.link-text {
    text-decoration: underline;
    transition: all .5s ease;
    color: #fff;
  }

  .os-btn-link {
    margin-left: 3px;
  }
}

.subs-term-label,
.editor-custom-checkbox,
.agree-custom-checkbox,
.device-trust-checkarea  {
  font-size: 12px;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
  margin: 0;

  .check-icon {
    position: absolute;
    font-size: 16px;
    transition: all .5s ease;
    left: 0;
    top: 0;
    color: $icon-color;
  }

  .ifill-os-checkmark-checked {
    transform: scale(0);
  }

  .check-icon-input {
    display: none;

    &:checked ~ {
      .ifill-os-checkmark-default-white {
        transform: scale(0);
      }

      .ifill-os-checkmark-checked {
        transform: scale(1);
        color: $level-10;
      }
    }
  }
}


.agree-custom-checkbox {
  line-height: 12px;
  padding-left: 16px;

  span {
    color: $level-11;
  }
  .check-icon {
    font-size: 12px;
  }
}


@media (min-width: 768px) {
  .subscription-col-right {
    flex: 0 0 353px;
  }
}


@media (min-width: 768px) and (orientation: portrait) {
  .subscription-col-right {
    flex: 0 0 353px;
  }

  .subscription-col-left {
    flex: 0 0 42%;
  }
}

.no-touch-support {
  .subs-term-label,
  .editor-custom-checkbox,
  .agree-custom-checkbox {
    .check-icon:hover {
      color: $os-link;
    }
  }
}
