.new-cs-card {
  /*min-height: 720px;
  max-height: 720px;
  height: 720px;*/
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  min-height: unset;
  padding: 28px 3.61% 100px;
  border-radius: 0;
  box-shadow: inset 0px 8px 8px rgba(18, 18, 20, 0.35);

  @media only screen and (min-width : 1025px) and (max-width : 1366px) and (orientation:landscape) {
    /*max-height: 535px;
    min-height: 535px;
    height: 535px;*/
    padding-left: 2.34%;
    padding-right: 2.34%;
  }

  @media only screen and (min-width : 1024px) and (max-width : 1366px) and (orientation:landscape) {
    /*max-height: 535px;
    min-height: 535px;
    height: 535px;*/
    padding-left: 3.125%;
    padding-right: 3.125%;

    .submit-insight-text {
      padding-right: 40px;
      margin-top: 0;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    min-height: auto;
    max-height: none;
    height: auto;
  }

  .cs-summary_tags {
    padding-bottom: 24px;
  }

  .case-quiz-content-section {
    .subs-term {
      margin-bottom: 24px;
    }
  }

  .cs-treatment-sec {
    height: calc(100% - 5px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      padding-bottom: 28px;
      padding-top: 0;
      padding-right: 0;
    }
  }

  #appointment-scroll-container-web {
    height: calc(100% - 56px);
    padding-bottom: 10px;
    margin-bottom: 8px;

  @media screen and (min-width: 1024px) and (orientation:landscape) {
    background-color: $block;
    border-radius: 12px;
  }

    &::only-child {
      height: calc(100% - 56px);
    }

    .cs-summary_tags {
      padding-bottom: 0;

      &.hide-summary,
      .phase-list:empty {
        display: none;
      }
    }

    .cs-right-cards-block {
      width: 100%;

      &.active {
        overflow: initial;
      }
    }

    ~ .add-appointment-btn {
        margin-left: 0;
        display: block;
        width: 100%;
        background-color: $btn-primary;
        color: $btn-primary-color;
        border-radius: 8px;

        .btn-icon-helper {
        color: $btn-primary-color;
        }

        &:hover {
          background-color: $btn-primary-hover;
          color: $btn-primary-color;
          box-shadow: $btn-hover-shadow;
        }
      }
  }

  .spin-slide-body,
  .case-thumbnail-slider {
     -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
  }

  .case-right-header {
    .cs-right-dropdown {
      top: -10px;
    }
  }
  .case-timeline-right-section {
    height: calc(100% - 16px);
    margin-top: 24px;

    @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation:portrait) {
      height: auto;
    }

    .case-treatment-right-section {
      height: auto;
      margin-top: -12px;
      padding-top: 0;
      padding-right: 12px;

      &.treatment-data-unavailable {
        height: auto;
      }

      .timeline-right-section {
        height: 100% !important;
        max-height: 100% !important;
        margin-top: 0 !important;
        min-height: auto;
        padding-right: 0;
      }

      .case-active-treatment-block  {
        .case-treatment-text-block {
          @media screen and (max-width: 767px) {
            top: 0px;
            margin-bottom: 0 !important;
          }
        }

        .case-treatment-text-small {
          font-size: 12px !important;
          line-height: 16px;
          color: $level-11;
          margin-bottom: 0;
          text-transform: none;
        }

        .case-treatment-progress-grey-bar {
          height: 12px !important;
          background-color: $os-link;
        }

        .case-treatment-progress-blue-bar {
          height: 12px !important;
          background-color: $os-link;

          .base-line {
            top: 8px !important;
          }
        }

        .case-treatment-progress-lightblue-bar {
          height: 12px !important;
          background-color: $os-link-hover;
        }

        + .timeline-right-section {
          height: auto !important;
          max-height: none !important;
          margin-top: 12px !important;
          padding-left: 24px;

          @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
            margin-top: 16px !important;
          }
        }
      }

      .case-active-treatment-block {
        .case-treatment-progress-grey-bar {
          .case-treatment-tooltip.left {
            left: 46px;
          }
        }
      }
    }

    .cases-tags-block  {
      padding: 0 !important;
      margin-bottom: 0;

      .tag-item {
        height: 40px;
        padding: 0 16px;
        margin-left: 0;
        margin-right: 12px;
        line-height: 40px;
        color: #e7e7ea;
        margin-top: 16px;
      }

       @media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation:portrait) {
        .cases-tags-wrap {
          overflow-x: hidden;
          white-space: normal;
        }
      }


      @media screen and (max-width: 767px) {
        .cases-tags-wrap {
          overflow-x: hidden;
          white-space: normal;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding: 0;
      margin-top:0;
    }
  }

  .timeline {
    .appliance-log {
      .duration {
        @media screen and (max-width: 767px) {
          .show-icon {
            top: -30px !important;
          }
        }
      }

      .appliance-list {
        margin-top: 20px !important;

        &:empty {
          margin-top: 0 !important;
        }

        .row {
          margin-left: 0;
          margin-right: 0;

          .col-12 {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }

  .quiz-right-section {
    max-height: 100% !important;

    &.questions {
      .accordion-section {
        margin-bottom: 0;
      }

      .card-header {
        &.selected {
          padding-right: 25px;
        }
      }
    }

    &.patient {
      height: 100%;
      margin-top: 12px;
    }
  }

  .case-right-landing-message {
    max-width: 220px;
    padding: 0;
    text-align: left !important;
    font-weight: normal;
  }

  .case-right-landing-btn-row {
    margin-top: 32px;
  }

  .slider-threesixty {
    &.vertical {
      right: -10px;
      top: 56px;
      height: calc(100% - 72px);
    }

    &.horizontal {
      bottom: -8px;
      margin-top: -36px;
      .rangeslider {
        left: 12px;
        right: 16px;
      }
    }
  }

  .sbs-wrapper {
    .slider-threesixty {

      &.vertical {
        right: -8px !important;
      }

      &.horizontal {
        margin-top: -36px;
      }
    }

    .sbs-spin-img-wrap{
      &:before {
        height: 100%;
      }
    }

    .case-thumbnail-slider {
      margin-top: 0;
    }
  }

  .timeline-right-section {
    .treatment-data-msg {
      &:only-child {
        padding-top: 24px;
        padding-left:24px;
      }
    }
  }

  .sbs-dropdown {
    .cs-dropdown {
      .header-dropdown-list-item {
        &:hover {
          background: $block;
          color: $level-11;
        }

        &.dropdown-group-header {
          color: $heading !important;
        }
      }

      @media screen and (max-width: 767px) {
        &.sbs-right {
          .sbs-dropdown {
            .cs-dropdown i {
              right: 10px;
            }
          }
        }
      }
    }
  }

  .new-os-slider {
    .slick-list,
    .slick-track,
    .case-photo,
    .slick-slide {
      height: 100%;
    }

    .slick-slide {
      > div{
        .slide-img-holder {
          &:before {
            background-color: transparent;
            border-radius: 0;
          }

          &:after {
            content: none;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      &.sbs-left {
        padding-left: 14px;
      }

      &.sbs-right {
        padding-right: 14px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .sbs-right {
      .sbs-dropdown {
        .cs-dropdown i {
          right: 0;
        }
      }
    }
  }

  .case-left-wrapper {
    .slide-with-scrubber {
      .new-os-slider {
        .slick-slider,
        article {
          height: 100%;
        }
      }
    }

    .new-os-slider {
      .slick-list,
      .slick-track,
      .case-photo,
      .slick-slide {
        height: 100%;
      }

      .slick-slide {
        > div{
          .slide-img-holder {
            img {
              max-width: 100% !important;
              height: 100%;
              object-fit: cover;
            }

            &:before {
              content: none;
            }
          }
        }
      }
    }
  }

  .slider-controll-buttons,
  .switch-classic-mode {
    background-color: rgba(235, 235, 235, 0.6);
    height: 40px;
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    justify-content: flex-start;
    padding-left: 0;
    z-index: 100;

    @media(max-width: 767px) {
      height: 40px;
    }

    .dropdown-info-title {
      background-color: rgba(235, 235, 235, 0.6);
      border-radius: 4px;
      padding: 4px 12px;
    }
    .cs-dropdown {
      min-width: 134px;

      .page-heading-title {
        background: none;
        color: $level-11;
        font-weight: normal;
        padding-left: 0;
        padding-right: 27px;
        display: inline-flex;
        position: relative;
      }

      i {
        font-size: 12px;
        right: 6px;
      }

      .header-dropdown-list {
        max-height: 300px;
        overflow-y: auto;
      }

      @media(max-width: 767px) {
        min-width: unset;
        font-size: 12px;
        margin-left: 4px;

        .page-heading-title {
          padding-right: 14px;

          .istoggleicon {
            right: 0;

            &::before {
              display: none;
            }

            border: transparent 4px solid;
            border-top-color: #5c5c66;
            margin-top: 3px;
            border-radius: 2px 2px 2px 0;

            &.ifill-os-chevron-up {
              transform: rotate(-180deg);
              margin-top: -6px;
            }
          }
        }

        .header-dropdown-list {
          white-space: nowrap;
          right: auto;
          left: 0;
        }
      }
    }
  }

  .case-details-video-section:not(.px-0) {
    .switch-classic-mode {
      width: 156px;
      border-radius: 0 12px 0 8px;
      right: 0;
    }
  }

  .switch-classic-mode {
    justify-content: flex-end;
    padding-right: 15px;
    opacity: 1;
    transition: opacity 200ms;

    &:before {
      content: '';
      top: 0;
      margin-top: -18px;
      bottom: 0;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: -1;
    }

    .form-group {
      margin-bottom: 4px;
    }

    .label {
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      left: -80px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .cs-detail-header {
    &:hover ~ .cs-gallery-grid {
      .switch-classic-mode {
        opacity: 1;
      }
    }
  }

  .togglebtnlink {
    font-size: 12px;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    color: $level-11;
    cursor: pointer;
    margin: 0;

    .check-icon {
      display: none !important;
    }

    &:hover {
      color: $level-11;
    }

    .toogle-control {
      position: relative;
      width: 20px;
      border-radius: 20px;
      height: 14px;
      border: solid 1px $level-11;
      margin-left: 5px;
      transition:  all 300ms ease;
      background: transparent;

      &::after {
        content: '';
        border-radius: 50%;
        background: $level-11;
        display: block;
        height: 10px;
        left: 0;
        width: 10px;
        z-index: 1;
        position: absolute;
        transition:  all 300ms ease;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input {
      display: none;
    }

    input:checked ~ .toogle-control {
      background: $cl-link;
      border-color: $os-link;

      &::after {
        left: calc(100% - 11px);
      }
    }
  }

  .submit-insight-header {
    z-index: 3;
  }
  .submit-right-container {
    padding-top: 40px;
  }
}

.cs-right-cards-block {
  position: relative;
  border: 0;
  margin-bottom: 8px;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: $level-11;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  border-bottom : 1px solid $level-3;

  &:last-child {
    border-bottom: 0;
  }

  @media screen and (min-width: 1024px) and (orientation: landscape) {
    border-radius: 12px 12px 0 0  !important;
  }

  &.cs-insight {
    display: none;
  }

  .card-body {
    padding: 4px 8px 0 0;
  }

  .cs-rt-icon {
    min-width: 48px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    background-color: $level-2;
    border-radius: 50%;
    display: inline-block;
    margin-right: 16px;
    text-align: center;
    color: $level-8;
    font-weight: bold;
    position: relative;
    z-index: 1;
  }

  .cs-rt-icon-right {
    width: 48px;
    height: 48px;
    line-height: 48px;
    display: inline-block;
    text-align: center;
    color: $level-8;
    cursor: pointer;
    right: 0 !important;
    position: relative !important;
    top: auto !important;
  }

  &.active {
    .cs-rt-icon {
      color: $level-8;
    }
  }

  .cs-rt-head {
    color: $level-11;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    line-height: 20px;

    div {
      line-height: 28px;
    }

    small {
      font-size: 16px;
      font-weight: $font-weight-normal;
      color: $level-9;
    }
  }

  .card-header {
    width: 100%;
    box-shadow: none;
    border:0;
    display: flex;
    align-items: center;
    padding: 28px 8px 28px 24px;
    cursor: pointer;
    background-color: transparent;

    &.active {
      .cs-rt-icon,
      .cs-rt-icon-right,
      .cs-rt-head {
        color: white;
      }
    }
  }


  @media screen and (max-width: 767px) {
    margin-bottom: 4px;

    .card-header {
      padding: 20px 24px 20px 24px;
    }

    .cs-rt-icon-right {
      right: -16px !important;
    }

    &.active {

      .card-header {
        padding-right: 0;
      }

      .cs-rt-icon-right {
        right: 10px !important;
      }
    }

    &.cs-insight {
      display: flex;
    }

    .quiz-right-section {
      margin-top: 0 !important;
    }
  }
}

.ipad {
  .new-cs-card {
    .case-timeline-right-section {
      .case-treatment-right-section {
        .timeline-right-section {
          padding-top: 0;
        }
      }
    }

    .new-os-slider {
      .slick-slide > div .slide-img-holder img {
        width: auto !important;
      }
    }
  }

  .case-details-video-section {
    position: relative;
  }

  &.portrait {
    .transgif {
      display: none;
    }

    .cs-right-cards-block {
      &.active {
        .cs-rt-icon-right {
          right: 24px !important;
        }
      }
    }

    .cd-detail-conatiner {
      &:not(.new-cs-card) {
        .case-timeline-right-section {
          margin: 0;
          padding-left: 0px !important;
          padding-right: 0 !important;
        }
      }
    }

    .new-cs-card {
      position: relative;
    }

    .cs-treatment-sec {
      width: 100%;
      margin-bottom: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 0;

      .cs-accordion-treatment {
        max-height: 584px;
        overflow-y: auto;
        width: 59%;
        padding-right: 12px;
        transition: width 500ms ease ;
        will-change: width;

        &:first-child {
          max-height: 584px;
        }

        &.active-accordion {
          width: 100%;
          padding-right: 0;
        }
      }

      .cs-summary_tags {
        width: 41%;
        padding-left: 12px;
        display: inline-block;
        opacity: 1;
        transition: opacity 500ms ease, width 500ms ease ;
        will-change: opacity, width;

        &.hide-summary {
          opacity: 0;
          width: 0;
          padding-left: 0;
        }
      }
    }

    .cs-summary_tags {
      height: auto;
      margin-bottom: 0;
      overflow-y: auto;
    }

    .cs-right-cards-block {
      margin-bottom: 24px;

      .card-header {
        padding: 20px 8px 20px 24px;
      }


      &.cs-insight {
        display: flex;
      }
    }

    .cs-treatment-d-flex {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .case-timeline-right-section {
      .case-right-header {
        display: none;
      }

      .detail-text {
        padding-top: 0;
      }

    }

    .cd-detail-conatiner {
      padding-top: 24px;
      .accordion-section {
        .accordion > div {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }

    .new-cs-card {
      position: relative;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 32px;

      .getin-touch-btn {
        position: absolute;
        right: 0;
        top: -63vh;

        @media screen and (min-width: 1024px) {
          top: -60vh;
        }
      }

      .case-details-video-section {
        min-height: 522px;
        padding-bottom: 120px;
        height: 850px;

        &.no-ap-cs-card {
          min-height: auto;
          height: auto;
          padding-bottom: 0;
        }
      }

      .submit-right-container {
        min-height: unset;
        height: auto;
      }

      ~ .comment-content-section {
          .activity-container {
            padding-top: 0;
          }
      }

      .case-right-landing-header {
        justify-content: flex-start;
        padding-top: 45px;
      }

      .case-right-landing-btn-row {
        margin-top: 72px;
      }

      .case-timeline-right-section {
        margin-top: 0;
        padding-top: 20px;

        .case-treatment-right-section {
          max-height: calc(100% - 20px);
        }

        .quiz-right-section {
          max-height: unset !important;
          padding-right: 0;
          overflow: unset;
          width: 100%;
        }
      }

      .case-right-landing-header {
        padding: 0;
      }

      .case-right-landing-message {
        max-width: 340px;
        padding-top: 46px;
      }

      .submit-insight-header {
        padding-bottom: 6px;
      }

      .accordion-section {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0 -8px;

        .share-insight-text {
          min-width: 100%;
        }

        .accordion {
          padding: 0 8px;
          width: 50%;
        }

        .question-preview-area {
          width: 100%;
        }

        ~ .case-right-btn-row {
            display: none;
          }
      }

      .accordion {
        .card {
          min-width: 100%;
          min-height: 80px;
          border-radius: 8px;
        }

        &:first-child {
          max-height: 305px;
          overflow-y: auto;
        }
      }

      .case-right-landing-submission {
        + .accordion-section {
            flex-wrap: wrap;
            margin:  0;
            max-height: 305px;
            overflow-y: auto;

            .accordion-block {
              width: 100%;
              margin-bottom: 8px;
              padding-left: 24px;

              .ifill-os-chevron-up,
              .ifill-os-chevron-right {
                right: 8px;
              }
            }

            .accordion {
              padding-left: 0;
              padding-right: 0;
            }
        }
      }

      .question-preview-area {
        .card {
          background: $block;
          padding: 12px 14px 8px;
          border-radius: 8px;
          margin-bottom: 39px;
          height: auto;
          min-width: 100%;

          .card-header {
            margin-bottom: 16px;
          }

          .card-body {
            padding-top: 0;
            padding-bottom: 16px;
          }

          .submission-selection-small-holder {
            margin-top: 0;

            .submission-selection-link {
              min-width: unset;
              max-width: unset;
              flex: 1;
              margin: 0;
            }
          }
        }

        .submission-selection-holder {
          flex-wrap: wrap;
        }

        .submission-selection-link {
          flex: 0;
          min-width: calc(50% - 7px);
          max-width: calc(50% - 7px);
          margin: 0 0 16px 0;

          &:nth-child(odd) {
            margin-right: 7px ;
          }

          &:nth-child(even) {
            margin-left: 7px ;
          }
        }

        ~ div {
          width: 100%;
        }
      }

      .case-thumbnail-slider {
        margin-top: 0;
        bottom: -20px;
      }

      .slider-threesixty {
        &.horizontal {
          bottom: 8px;
          margin-top: -36px;
        }

        &.vertical {
          right: 0 !important;
          height: calc(100% - 72px);
          overflow: hidden;

          .rangeslider-vertical {
            margin-left: 8px;
          }
        }
      }

      .case-left-wrapper,
      .sbs-wrapper {
        &:hover {
          .case-thumbnail-slider {
            opacity: 1;
            bottom: -8px;
          }

          .slide-with-scrubber {
            .new-os-slider {
              height: calc(100% - 100px);
            }

            .slider-threesixty {
              &.vertical {
                height: calc(100% - 166px);
              }
            }
          }
        }
      }

      .sbs-wrapper {
        &:hover {
          .case-thumbnail-slider {
            opacity: 1;
            bottom: 0 !important;
          }

          .slide-with-scrubber {
            .new-os-slider {
              height: calc(100% - 140px);
              max-height: calc(100% - 140px);
            }

            ~ .slider-threesixty {
                &.vertical {
                  height: calc(100% - 130px);
                }

                &.horizontal {
                  bottom: 72px;
                }
            }
          }
        }
      }

      .sbs-right {
        .cs-sort-btn-group {
          left: auto;
          right: 0;
        }
      }

      .sbs-wrapper,
      .case-left-wrapper {
        &:hover {
          .cs-sort-btn-group {
            opacity: 1;
            margin: 0 5px;
          }
        }
      }

      .case-right-btn-row {
        display: flex;
        justify-content: flex-end;
      }
    }

    .cases {
      .back-link {
        display: none;
      }
    }
  }

  &.landscape {
    .case-left-wrapper,
    .sbs-wrapper {
      .slide-with-scrubber {
        .slider-threesixty {
          &.horizontal {
            .rangeslider {
              right: 16px !important;
            }
          }
        }
      }

      &:hover {
        .case-thumbnail-slider {
          opacity: 1;
          bottom: 0;
        }
      }
    }

    .case-left-wrapper {
      &:hover {
        .slide-with-scrubber {
          .new-os-slider {
            height: calc(100% - 78px);
          }

          .slider-threesixty {
            &.vertical {
              right: -8px;
              height: calc(100% - 146px);
            }
          }
        }
      }
    }

    .sbs-wrapper {
      .slide-with-scrubber {
        ~ .slider-threesixty {
            &.horizontal {
              .rangeslider {
                right: 16px !important;
              }
            }
        }
      }

      &:hover {
        .case-thumbnail-slider {
          opacity: 1;
          bottom: 0 !important;
        }

        .slide-with-scrubber {
          .new-os-slider {
            height: calc(100% - 146px);
            max-height: calc(100% - 146px);
          }

          ~ .slider-threesixty {
              &.vertical {
                right: -8px;
                height: calc(100% - 142px);
              }

              &.horizontal {
                bottom: 62px;
              }
          }
        }
      }
    }
    .sbs-right {
      .cs-sort-btn-group {
        left: auto;
        right: 0;
      }
    }

    .sbs-wrapper,
    .case-left-wrapper {
      &:hover {
        .cs-sort-btn-group {
          opacity: 1;
          margin: 0 5px;
        }
      }
    }
  }
}

.mobile-device {
  &.detail-page .cases {
    .mobile-nav-bar {
      display: none;
    }
  }

  .case-timeline-right-section {
    .case-right-header {
      display: none;
    }
  }

  .appointment-sbs-hint {
    width: 250px;
  }

  .switch-classic-mode {
    .osbtn {
      padding: 0px 12px !important;
    }
  }

  .new-cs-card {
    background: transparent;
    position: relative;
    height: auto;

    .transgif {
      display: none;
    }

    .case-card-detail-header {
      height: 96px;
      box-shadow: 0 5px 5px 0 rgba(18, 18, 20, 0.5);
      background: $level-3;
      margin-bottom: 0;
      padding-top: 40px;

      .cs-header-btn-group {
        top: 48px;
      }

      .visible-mobile {
        width: unset;
        padding: 0;
      }
    }

    .case-left-wrapper {
      margin: 0;
      border-radius: 0;
    }

    .case-right-landing-message {
      max-width: 100%;
      padding: 0;
    }

    .submit-right-container {
      padding-top: 0;

      .case-right-header {
        display: none;
      }

      padding-bottom: 0;
    }

    .quiz-right-section {
      &.questions {
        padding-top: 24px;
      }
    }

    .share-insight-btn {
      @include minmaxwidth(132px);
      line-height: 48px;
      padding: 0;
      border-radius: 8px;
    }

    .case-left-wrapper {
      .new-os-slider {
        .slick-slide {
          > div {
            .slide-img-holder {
              img {
                width: auto !important;
              }
            }
          }
        }
      }
    }

    .case-timeline-right-section {
      .case-right-header {
        position: relative;
      }
    }

    .sbs-wrapper {
      height: 422px;
      margin-bottom: 40px;

      .slider-threesixty {
        &.vertical {
          height: calc(100% - 145px);
        }

        &.horizontal {
          bottom: 70px;

          .rangeslider-horizontal {
            right: 20px;
          }
        }
      }

      .case-thumbnail-slider {
        bottom: -40px;
      }

      &:hover {
        .case-thumbnail-slider {
          bottom: 59px;
          opacity: 1;
          margin: 0;
        }

        .slide-with-scrubber ~ {
          .slider-threesixty {
            &.vertical {
              height: calc(100% - 175px);
            }

            &.horizontal {
              bottom: 104px;
            }
          }
        }
      }
    }

    .case-left-wrapper {
      .case-thumbnail-slider {
        bottom: -40px;
      }

      &:hover {
        .case-thumbnail-slider {
          bottom: 74px;
          opacity: 1;
          margin: 0;
        }

        .slide-with-scrubber ~ {
          .slider-threesixty {
            &.vertical {
              height: calc(100% - 134px);
            }

            &.horizontal {
              bottom: 60px;
            }
          }

        }
      }
    }

    .cs-treatment-d-flex {
      padding: 0;
    }

    .case-details-video-section {
      padding-bottom: 8px;
    }

    .cs-summary_tags {
      padding: 5px 24px;
    }
  }

  @include clvSpin(0.45, 0.42, 0.28);
}

.case-hover-hint,
.appointment-sbs-hint {
  position: absolute;
  background-color: rgba(34, 34, 38, 0.4);
  border-radius: 8px;
  padding: 16px;
  max-width: 316px;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: white;
}


/* slider with using VH to support full height and spin scale to manage resolution like full mode view*/
html:not(.mobile-device) {
  @media(min-width: 1300px) and (max-width: 1599px) {
    @include clvSpin(1.08, 0.95, 0.68);
  }

  @media(min-width: 1600px) and (max-width: 2000px) {
    @include clvSpin(1.25, 1.02, 0.68);
  }

  @media(min-width: 2001px) and (max-width: 2499px) {
    @include clvSpin(1.63, 1.43, 0.84);
  }

  @media(min-width: 2500px) and (max-width :2999px) {
    @include clvSpin(2.15, 1.97, 1.02);
  }

  @media(min-width: 3000px) {
    @include clvSpin(2.95, 2.5, 1.4);
  }
}


.mobile-device {
  .new-cs-card {
    .sbs-wrapper {
      margin-bottom: 20px;

      .sbs-col {
        .new-os-slider {
          max-height: calc(100% - 112px);
        }

        .cs-sort-btn-group {
          bottom: -52px;
        }

        &.sbs-left {
          .cs-sort-btn-group {
            border-right: 2px solid $level-2;
          }
        }

        &.sbs-right {
          .cs-sort-btn-group {
            border-left: 2px solid $level-2;
          }
        }
      }
    }
  }
}

.cs-legend-list {
  .cs-legend-list-item {
    display: flex;
    align-items:center;
    justify-content: flex-start;
    padding: 8px 0 8px 4px;
    font-size: 12px;
  }

  .header-list-icon {
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: $level-4;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    margin-right: 26px;
    color: $level-9;
    display: inline-block;

    &.ifill-os-image {
      font-size: 14px;
    }

    &.ifill-os-error {
      &:before {
        color: $level-11;
      }
    }
  }
}


.no-data-appt-info {
  width: 206px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .appt-type-btn {
    line-height: 20px;
    color: $os-link;
    cursor: pointer;
  }

  p {
    padding-top: 16px;
    margin-bottom: 20px;
  }

  +.appointment-sbs-hint {
    margin-top: -130px;
  }

  + .cs-footer + .appointment-sbs-hint {
    margin-top: -140px;
  }
}

.active-tool-btn {
  border-radius: 4px;
  border : 0;
  text-align: center;
  padding: 16px 0;
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: $level-9;
  font-weight: 600;
  margin-top: 12px;
  transition: color 200ms ease;
  will-change: color;

  i {
    color: $level-8;
    text-decoration: none;
  }

  &:hover {
    color: $level-10;
    span {
      text-decoration: underline;
    }
    i {
      color: $btn-icon-hover;
    }
  }

  + .cards {
    margin-top: 16px;
  }
}


.mobile-device {
  .new-cs-card {
    .timeline {
      .appliance-log {
        .appliance-list {
          .cards {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.summary-text-block {
  p {
    font-size: 16px;
    line-height: 24px;
    color: $level-11;
  }
}
.summary-text-inline {
  position: absolute;
  font-weight: 600;
}


.duration {
  + .cards {
    margin: 16px 0 0 0px;
    width: 100%;

    .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}


@media screen and (max-width: 767px) {
  .no-data-appt-info {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    padding:0 12px 32px 12px;
    min-height: 408px;
    padding-top: 130px;

    &.single-col {
      width: 100%;
      text-align: center;
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      padding:0 12px 32px 12px;
      min-height: 408px;
      padding-top: 130px;

      .appt-type-btn {
        line-height: 20px;
        color: $os-link;
        cursor: pointer;
      }

      i {
        margin-top: 10px;
        display: block;
      }

      +.appointment-sbs-hint {
        margin-top: -85px;
      }
    }
  }

  .sbs-col {
    .no-data-appt-info {
      &.single-col {
        min-height: 100px;
        padding-top: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.slick-current {
  z-index: 1;
}

.ipad.portrait {
  .new-cs-card {
    .submit-right-container {
      .cases-tags-block {
        .cases-tags-wrap {
          .tag-item:first-child {
            margin-left: 0 !important;
          }
        }
      }
    }

    .case-card-detail-header {
      .cs-header-btn-group {
        padding-right: 56px !important;
        min-height: 40px;
        position: relative;
        margin-right: 12px;
      }

      .cs-right-dropdown {
        right: 0;
        top: 0px;

        .header-dropdown-menu {
          .header-dropdown-list {
            z-index: 999;
          }
        }
      }
    }
  }
}


.accordion-button::after {
  background-image: none !important;
  content: none;
  display: none !important;
}
