.folder-views-area,
.sorting-options-bar {
  display: none;
}

.folders, .add-folder-modal {
  .workspace-page-head {
    margin-bottom: 16px;

    .page-heading-right {
      flex-basis: 50%;
    }
  }

  .nav-relative {
    position: relative;
  }
  .non-clv-header {
    .folder-author-info .btn-with-title-bar {
      font-size: 12px;

      @media (max-width: 767px) {
        .space-header-name {
          .ifill-os-fill-folder {
            display: none;
          }
        }
      }
    }
  }

  .object-header-bar {
    display: flex;
  }

  .sorting-options-bar {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: $level-11;
    text-transform: uppercase;
    margin-bottom: 16px;

    .sorting-option {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      &:hover,
      &.active {
        color: $os-link;
      }

      &.active {
        i {
          transform: rotate(-180deg);
        }
      }

      i {
        margin-left: 4px;
        font-size: 16px;
        transition: transform 200ms ease;
      }
    }
  }
}

.folder-views-area {
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  .nav-search-input {
    padding-right: 12px !important;
    padding-left: 40px !important;
    &.form-control {
      font-size: 12px;

      &:placeholder {
        color: $textarea-placeholder
      }
    }
  }

  .dropdown-info-title {
    padding-bottom: 0 !important;

    .page-heading-title  {
      line-height: 40px;
      padding-bottom: 0;
      padding-top: 0;
      height: 40px;
      padding-right: 16px;
      display: inline-flex;
      align-items: center;
      font-weight: 600;
    }
  }

  .cs-dropdown.dropdown-open {
    .page-heading-title {
      border-radius: 8px;
    }
  }

  .header-dropdown-list {
    min-width: 207px !important;
  }

  .small-filter-btn {
    display: none;
  }

  @media(max-width: 1000px) {
    .small-filter-btn {
      display: inline-block;
    }

    .normal-filter-btn {
      display: none !important;

      + .osbtn {
        margin-left: 0;
      }
    }
  }

  @media(max-width: 1150px) and (min-width: 1001px) {
    .normal-filter-btn {
      font-size: 0;
      &:before {
        content: 'Filter';
        font-size: 16px;
      }
      i {
        font-size: 16px;
      }
    }
  }

  .space-search-container {
    width: 200px;
    margin-bottom: 0;
    margin-right: 4px;
    height: 40px;

    .clear-input {
      position: absolute;
      right: 12px;
      top: 12px;
      color: $level-8;
    }

    @media (min-width: 950px){
      width: 210px;
    }

    @media (min-width: 1150px) {
      width: 300px;
    }

    @media(max-width: 800px) and (min-width: 768px) {
      width: 180px;

      .ifill-os-search {
        left: 10px;
      }

      .nav-search-input.form-control {
        padding-right: 5px !important;
        padding-left: 32px !important;
        font-size: 11px;
      }
    }
  }

  .os-btn-link {
    width: 40px;
    height: 40px;
    background-color: #303036;
    color: #6c6c6f;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;


    &:hover, &.active {
      background-color: #5c5c66;
      color: #e7e7ea;
      text-decoration: none;
    }
  }

  .osbtn-icon,
  .osbtn-icon.no-text,
  .page-heading-title {
    background-color: $level-1;
    border: solid 1px $border-button-1;
    color: $level-11;
    vertical-align: middle;
    border-radius: 8px;

    &.active {
      background: $level-11 !important;
      border-color: $level-11;
      color: $level-1;

      .btn-icon-helper {
        color: inherit;
      }
    }

    .btn-icon-helper {
      color: inherit;
    }
  }

  .dropdown-open {
    .page-heading-title {
      background: $level-11 !important;
      border-color: $level-11;
      color: $level-1
    }
  }

  .normal-filter-btn {
    padding-right: 12px;
    padding-left: 36px;

    .btn-icon-helper {
      left: 12px;
    }
  }
}

.desktop-small{
  .folders {
    .detail {
      .head-sticky {
        .sub-nav-background {
          padding-left: 32px;
          padding-right: 32px;
        }
      }
    }

    .non-clv-header {
      .px-52 {
        padding-left: 32px;
        padding-right: 32px;
      }
    }

    .xl-container.px-hd-40 {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

.ipad.portrait {
  .folders {
    .detail {
      .head-sticky {
        .sub-nav-background {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
  }
}

.ipad.portrait {
  .folders {
    .space-header-right {
      min-width: 100px;
      width: auto;
      right: 0;
    }
  }
}

.nav-type-icon,
.chevron-icon {
  display: none;
}

.grid-btn-group {
  .osbtn + .osbtn {
    margin-left: 0.25rem;
  }
  &.disabled {
    pointer-events: none;

    .osbtn, .osbtn.active{
      border-color: #eff1f4;
      color: $level-6;
      background-color: $level-1 !important;
      box-shadow: none !important;

      .btn-icon-helper {
        color: $level-6;
      }
    }
  }
}

.mobile-device {
  .grid-btn-group {
    display: none;
  }

  .folder-views-area {
    display: flex;
    margin-left: 0;
    flex-wrap: wrap;

    .space-search-container {
      width: 100%;
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  .filter-dropdown {
    width: 50%;
    margin: 0 !important;
    position: relative;

    .page-heading-title {
      width: 100%;
      height: 44px;
      line-height: 44px;
      // background-color: $os-036;
      font-weight: 600;
    }

    .istoggleicon {
      display: initial;
    }

    &.with-sorting {
      .page-heading-title {
        border-radius: 8px 0 0 8px !important;
        // color: $os-blue;
        padding: 0 10px;
        text-align: center;

        span {
          margin: auto;
        }
      }

      .istoggleicon {
        transform: none;
        position: static;
        vertical-align: middle;
        margin-left: 4px;
        color: inherit;
      }

      .sort-rel-icon {
        display: none;
      }
    }

    &.with-list-sorting  {
      .page-heading-title {
        border-radius: 0 8px 8px 0 !important;
      }
    }

    .os-custom-checkbox {
      font-size: 11px;
      padding-left: 32px;

      .check-icon {
        left: 10px;
      }
    }

    + .filter-dropdown {
      &:before {
        content: '';
        position: absolute;
        left: -1px;
        width: 2px;
        // background-color: $os-036;
        top: 2px;
        bottom: 2px;
      }
    }


  }

  .folders {
    .non-clv-header {
      .cs-right-dropdown {
        right: 0;
      }
    }

    .sticky-tab-with-dropdown {
      .object-header-bar {
        flex-direction: column-reverse;
        border-bottom: 0 !important;
      }

      .head-sticky {
        .sub-nav-background {
          padding-left: 12px;
          padding-right: 12px;

          .tab-spacing {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .object-filter {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        flex-wrap: wrap;
        overflow-x: hidden;
        justify-content: center;
        height: auto;
        padding: 0;

        .list-inline-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $block;
          border-radius: 8px;
          padding: 24px;
          box-shadow: $block-shadow;
          margin: 8px 0 !important;
          width: 100%;
          height: 88px;

          + .list-inline-item {
            margin-top: 0 !important;
          }

          &.object-filter-active::after {
            content: none;
          }

          .dropdown-info-title {
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            color: $level-11;
            padding-top: 0;
          }
        }
      }

      .nav-type-icon {
        background: $level-3;
        padding: 12px;
        border-radius: 50%;
        color: $level-8;
        margin-right: 16px;
        display: inline-block;
      }

      .chevron-icon {
        color: $level-8;
        display: inline-block;
      }
    }
  }
}
.mobile-device {
  .arch-btn {
    width: 100%;
  }
}
