%multiline-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.click-link-overlay {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.clinic-title {
  display: none;
}


.avatar-hide {
  .avatar {
    display: none;
  }
}


.os-multiline-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.avatar-hide {
  .avatar {
    display: none;
  }
}

.touch-support {
  .insight-lock-overlay, .list-card-actions {
    display: none;
  }
}

.feed-card-hover-overlay {
  .click-link-overlay {
    display: none;
  }
}

.no-touch-support {
  .insight-lock-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    display: flex;
    will-change: opacity, visibility;
    transition: opacity 500ms ease, visibility 500ms ease;
    background: linear-gradient(to bottom, #2c2b30 0%, #343539 50%, #1e1d22 100%);
  }

  .feed-card-hover-overlay:hover {
    .insight-lock-overlay {
      visibility: visible;
      opacity: 1;
      z-index: 2;
    }
  }
}

.list-card-actions {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  display: flex;
  will-change: opacity, visibility;
  transition: opacity 500ms ease, visibility 500ms ease;
  background: linear-gradient(to bottom, #2c2b30 0%, #343539 50%, #1e1d22 100%);
  z-index: -1;
  border-radius: 4px;
}


.mobile-device {
  .notification-heading {
    margin-top: 10px;
    line-height: 20px;
  }
}

.add-new-section {
  display: none;
}

.detail-page {
  .sort-item-drag-active {
    &:focus {
      border: 1px solid $cl-link;
      opacity: 0.8;
      position: relative;
      z-index: 1;
      outline: none;
    }
  }

  .manageable-sections {
    div {
      outline: none;
    }
  }

  .add-new-card {
    color: #5c5c66;
  }

  .manageable-sections {
    .space-full-card {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .space-card-col {
    /*padding-left: 0;
    padding-right: 0;*/

    div {
      &:focus {
        outline: none;
      }
    }

    .multi-selection-mode {
      .selectable-item {
        margin: 0px auto;
      }

      .space-user {
        text-align: left;
      }
    }

    .os-lg-company {
      justify-content: space-between;
    }

    .drag-count {
      position: absolute;
      right: -12px;
      top: -12px;
      background-color: $os-link;
      border-radius: 8px;
      min-width: 32px;
      text-align: center;
      opacity: 1 !important;
      z-index: 1;
      white-space: nowrap;
      font-size: 12px;
      padding: 3px 8px;
    }
    .os-lg-attachment,
    .os-lg-space,
    .os-lg-video {
      .selectable-item {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
      }

      .card-attachment-body {
        padding-bottom: 0px;
      }

      .selectable-item {
        z-index: 1;
      }
    }

    .drag-active {
      //position: absolute;
      z-index: 99999;
      //left: 0;
      //right: 0;

      &:focus {
        outline: none;
      }

      .os-card-v2  {
        opacity: 0.8;
        z-index: 1;
        border: 1px solid $cl-link;
        border-radius: 8px;

        [class^="os-lg-"]:hover {
          transform: none;
        }

        &:hover {
          .space-v2-card {
            transform: none !important;
          }
        }
      }

      .drag-count {
        opacity: 1;
      }
    }
  }

  @media (min-width: 800px) {
    .space-card-col {
      /*flex: 0 0 calc(33.33% - 24px);
      max-width: calc(33.33% - 24px);*/

      .os-card-v2.small-card {
        max-width: 100%;
      }
    }

    .space-new-col {
      /*flex: 0 0 calc(33.33% - 12px);
      max-width: calc(33.33% - 12px);*/

      .os-card-v2.small-card {
        max-width: 100%;
      }
    }

    .cards-custom-margin {
      .next-elem-after + .add-new-section,
      .next-elem-after + .add-new-section-division {
      /*  margin-left: -12px;
        right: -12px;*/

        + .add-new-section,
        + .add-new-section-division {
          /*margin-left: -12px;*/
        }
      }
    }

    .manageable-sections {
      .without-add-new-card {
        .add-new-section:first-child{
          &.drop-active {
            left: -12px;
          }

          + div {
            /*margin-left: -12px;*/
          }
        }

        .add-new-section-division {
          + .add-new-section-division {
            /*margin-left: -12px;*/
          }
        }
      }
    }
  }

.patient-directory-accordion, .prescription-list {
  .accordion-item {
    border-style: none;
    .accordion-header {
      .collapsed[type='button'] {
        .ifill-os-chevron-caret-down {
          display: unset;
        }
        .ifill-os-chevron-caret-up {
          display: none;
        }
      }

      button {
        border: none;
        color: unset;
        background-color: unset;
        box-shadow: none;

        .ifill-os-chevron-caret-down {
          display: none;
        }
      }
      .section-title {
        color: var(--neutrals-level-9, #5D7893);
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
      }
    }
    .accordion-body {
      padding: 0;
    }
  }
}

  @media (min-width: 1440px) {
    .manageable-sections {
      .without-add-new-card {
        .add-new-section:first-child,
        .add-new-section-division:first-child {
          /*left: -12px;*/

          + div {
            /*margin-left: -12px;*/
          }
        }

        .add-new-section-division {
          + .add-new-section-division {
            /*margin-left: -12px;*/
          }
        }
      }
    }
  }

  .add-new-section, .add-new-section-division {
    content: '';
    height: 96px;
    width: 24px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 0;

    i {
      display: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $os-link;
      opacity: 0;
      transition: opacity 500ms ease;
      font-size: 80px;
    }
  }

  .add-new-section {
    &.drop-active {
      i {
        opacity: 1;
        display: block;
      }
    }
  }

  .add-new-section-division {
    &:hover {
      i {
        opacity: 1;
        display: block;
      }
    }
  }
}

.subspaces {
  .add-new-section {
    cursor: unset !important;
  }
}

.mobile-device {
  .conversation-card-container {
    .os-card-v2, .space-v2-card {
      max-width: 100%;

      [class^="os-sm-"] {
        max-width: 100%;
      }
    }
  }
}
.extra-small-min-card {
  .header-dropdown-list-item {
    .osbtn-icon {
      &.active:not(.list-button), &:not(.list-button):hover {
        background-color: rgba(72, 172, 253, 0.2) !important;
        color: $os-link !important;
        .btn-icon-helper {
          color: $os-link !important;
        }
      }

      &.active, &:hover {
        width: 100%;
        text-align: left;
        border-radius: 0;

      }
    }
  }
}

.card-viewed {
  .overlay-span {
    opacity: 0.7 !important;
    background-color: rgba(18,18,20,0.7) !important;
    backdrop-filter: blur(2px);
    top: 0 !important;
    bottom: 0 !important;
  }

  .ifill-os-checkmark-round ~ [class*='icon-'] {
    opacity: 0;
  }
}

.comment-share {
  .selection-mode,
  .top-row-cards {
    .card-dropdown-menu {
      display: none;
    }
  }
}

.os-card-container {
  padding: 12px;
  border-radius: 12px;
  background: var(--surface-borders-level-1, #FFF);
  box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
  .partner-space-card {
    min-height: 72px;
    display: flex;
    gap: 16px;
    align-items: center;
    .card-image {
      img {
        width: 72px;
        object-fit: fill;
        border-radius: 8px !important;
      }
    }
    .partner-card-description {
      flex-grow: 2;
      .card-type {
        color: var(--neutrals-level-7, #818D99);
        font-family: Inter, 'sans-serif';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
      }
      .info {
        .info-title {
          color: var(--neutrals-level-11, #354554);
          font-family: Inter, 'sans-serif';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-transform: capitalize;
        }
      }
    }
  }
}
