.media-device-container {
  position: absolute;
  top: 10%;
  right: 0;
}

.kebab-menu {
  position: absolute;
  bottom: 100%;
  right: 100%;
  width: 200px;
  background-color: #fff;
  border-radius: 4px;
  :hover {
    background-color: #eee;
  }

}

.kebab-menu-icon-modified {
  transform: rotate(90deg);
  cursor: pointer;
}

.kebab-menu-heading {
  padding: 8px;
  text-align: center;
  font-weight: bold;

}
.kebab-menu-heading:hover {
  background: none;
  cursor: default;
}

.kebab-menu-item {
  cursor: pointer;
  padding: 8px;
}

.duration-timer {
  position: absolute;
  bottom: 10%;
  left: 3%;
  color: white;
}

.countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 150px;
}

.video-duration {
  position: absolute;
  color: red;
}

.start-recording-btn {
  margin: 8px auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}

.recorder-preview {
  width: 100%;
  margin-right: 0;
}

.preview-recorder-height {
  height: 500px !important;
  object-fit: cover;
}

.video-player-preview {
  object-fit: cover;
  border: 2px solid blue;
}

.recorder-play-button {
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}


@media screen and (max-width: 650px) {
  .video-player-preview {
    width: 100%;
  }
}

//------------------------------------
.recorded-player-container {
  display: flex;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  .recorded-video {
    object-fit: cover;
    height: 500px !important;
    display: block;
  }
}


