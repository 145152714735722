.quiz-accordion-section {
  margin-bottom: 20px;

  + .quiz-error-footer {
    margin-bottom: 40px;
  }

  .accordion-block {
    background-color: transparent;
    box-shadow: none;
    padding: 12px 0 0 0;
  }

  .accordion-header {
    color: $level-11;
  }

  .submission-checkbox-holder {
    .submission-checkbox-link {
      @include fontstyle(16px, 300, 1.5);
      color: $secondary-heading;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &.single {
      .submission-checkbox-link {
        &::before {
          top: 4px;
          border-color: $level-11;
          line-height: 24px;
        }

        &.selected {
          &::before {
            top: -1px;
            border-color: transparent;
          }
        }
      }
    }

    &.multiple {
      .submission-checkbox-link {
        &::before {
          color: $level-11;
          line-height: 24px;
          top: 0;
        }

        &.selected {
          &::before {
            color: $os-link;
          }
        }
      }
    }
  }
}
