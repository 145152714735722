.conversation-inbox-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  .conversation-list-row {
    display: flex;
    width: 100%;
    height: fit-content;
    padding: 12px;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    border: 1px solid var(--border-input-typed-button-2, #c9d1da);
    background: var(--surface-borders-level-1, #fff);
    box-shadow: 0px 1px 2px 0px rgba(53, 69, 84, 0.24),
      0px 1px 3px 0px rgba(53, 69, 84, 0.12);
    a {
      display: flex;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;
      .avatar-wrapper {
        .avatar {
          width: 64px;
          height: 64px;
          font-size: 64px;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        font-family: Inter;
        .c-name {
          display: flex;
          height: 16px;
          flex-direction: column;
          justify-content: center;
          align-self: stretch;
          color: var(--neutrals-level-7, #818d99);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }
        .c-message {
          color: var(--neutrals-level-11, #354554);
          font-feature-settings: 'clig' off, 'liga' off;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          // max-height: 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          p {
            font-size: 12px;
          }
        }
        .c-timestamp {
          color: var(--neutrals-level-9, #5d7893);
          font-size: 8px;
          font-weight: 400;
          line-height: 12px;
        }
      }
    }
    &.unread {
      font-weight: bold;
    }
  }
}
