.tool-card-head{
  @extend %os-card-head;
  position:absolute;
  left: 0;
  top: 24px;
  right: 0;

  .left-tool-col, .right-tool-col {
    @extend %col-head;
  }

}

.tl-card-footer {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.tl-card-body {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  padding-left: $card-gutter-16;
  padding-right: $card-gutter-16;
}

.tool-company-name {
  flex-wrap: wrap;
  @include multiline-truncate(2);
  *{
    display: inline;
  }
}

.tl-name-info {
  max-width: calc(100% - 56px);
}

.tl-image {
  height: 120px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  border-radius: 8px 8px 0 0;

  .img-parent {
    @include flex_center();
    @extend %img-parent;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.tl-company-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  @include flex_center();
  /*box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: black;*/

  img {
    max-width: 100%;
  }

  &:hover {
    text-decoration: none !important;
  }
}

.osbtn-tl-case {
  color: $level-3;

  .ifill-os-fill-case-2-d {
    color: $level-9;
  }
}


.os-lg-tool {
  @extend %os-lg-card;
  @extend %os-card-base;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $card-gutter-16;

  .card-dropdown-menu {
    z-index: 1;
  }

  .tl-card-body {
    padding-left: $card-gutter;
    padding-right: $card-gutter;
  }

  .overlay-span {
    @extend %overlay-span;
  }

  .tl-card-footer {
    padding-left: $card-gutter-16;
    padding-right: $card-gutter-16;
  }

  &.without-image {
    .tl-image {
      background: #29292e;
      img {
        max-height: 80px;
      }
    }
  }

  &.tool-304 {
    @extend %os-304;

    .tool-card-head {
      padding-left: $card-gutter-16;
      padding-right: $card-gutter-16;
    }

    .card-dropdown-menu {
      right: 8px;
    }

    .tl-card-body {
      padding-left: $card-gutter-16;
      padding-right: $card-gutter-16;
    }

    .tl-card-footer {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}



.os-sm-tool {
  @extend %os-sm-card;
  @extend %os-card-base;
  padding-left: 40%;

  .tl-image {
    max-width: 40%;
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    min-width: 40%;
    position: absolute;
    left: 0;
    top: 0;

    .overlay-span {
      border-radius: 8px 0 0 8px;
      @extend %overlay-span;
    }


    img {
      max-width: none;
      max-height: 100%;
      height: 100%;
    }
  }

  .img-parent {
    border-radius: 8px 0 0 8px;
  }

  .card-dropdown-menu {
    right: 8px;
    top: 8px;
  }

  &.without-image {
    .tl-image {
      background: #29292e;
    }

    img {
      max-height: 80px;
    }

    .overlay-span {
      @extend %overlay-span;
    }
  }

  .tl-card-body {
    padding-top: 12px;
    padding-bottom: 8px;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  .tl-name-info {
    max-width: 100%;
    padding-right: 32px;
  }

  .lg-text-9 {
    @include fontstyle(16px, normal, 1.5);
    color: $card-text;;
    margin-bottom: 8px;
  }

  .tool-card-sm-head {
    display: flex;
    justify-content: space-between;
  }

  .tl-card-footer {
    margin-left: -8px;
    margin-right: -8px;
    align-items: center;
    justify-content: space-between;

    .osbtn + .osbtn {
      margin-left: 0px;
    }

    .osbtn:last-child {
      padding-left: 32px !important;

      .btn-icon-helper {
        left: 8px;
      }
    }
  }
}

.tool-240 {
  @extend %os-240;
  flex-direction: column;
  padding: 16px 16px 8px;

  .tl-image {
    max-width: 48px;
    height: 56px;
    border-radius: 4px;
    min-width: 48px;
    left: 16px;
    top: 16px;
  }

  .overlay-span {
    border-radius: 4px !important;
  }

  .tl-card-body {
    padding: 0;
  }

  .tl-name-info {
    padding-left: 56px;
  }

  .lg-text-9 {
    margin-bottom: 0px;
  }
}

/*extra-small-min-card*/
.extra-small-min-card {
  .os-sm-tool {
    @extend %os-extra-sm-card;
    padding: 8px 8px 8px 8px;
    display: flex;

    .attachment-link,
    > div,
    .os-video-card  {
      display: flex;
    }

    .card-dropdown-menu {
      top: 50%;
      right: 8px;
    }
    
    .tl-image {
      position: absolute;
      top: 12px;
      left: 12px;
      height: 72px;
      max-height: 72px;
      min-width: 128px;
      max-width: 128px;
      border-radius: 8px;

      .img-parent,
      .overlay-span {
        padding: 0;
        border-radius: 8px;
      }
    }

    .tl-card-body {
      padding: 0 0px 0 140px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      min-width: 100%;
      flex-basis: 100%;
    }

    .tl-name-info {
      padding: 0;
      margin: auto 0;
      width: 100%;
    }

    .cm_name {
      color: $level-11;
      font-size: 12px;
      white-space: nowrap;

      .os-btn-link {
        color: $level-11;
        padding-right: 4px;
        white-space: nowrap;
        @include text-truncate();
        display: inline;

        &:hover, &:active {
          color: $os-link;
        }
      }
    }

    .lg-text-9 {
      margin: 0;
      padding-right: 40px;
    }

    .tl-card-footer {
      @include extra-small-card-footer;
      right: 16px;

      .osbtn {
        color: #5c5c66;
        padding: 0px 8px;

        &:hover {
          color: $os-link;
        }
      }

      .osbtn + .osbtn {
        display: none;
      }
    }
  }
}

.no-touch-support {
  .os-sm-tool {
    .tl-card-footer .osbtn:last-child {
      margin-right: 8px;
    }
  }
}
