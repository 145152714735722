@mixin ellipsis-lines($font-size, $line-height, $lines-to-show) {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-height: calc(#{$font-size*$line-height*$lines-to-show} - 1px); /* Fallback for non-webkit */
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin grayscale($value) {
  filter: #{ "grayscale(" + $value + ")" };
}

@mixin box-shadow($x...) {
  -webkit-box-shadow: $x;
  -moz-box-shadow: $x;
  box-shadow: $x;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin hide-text {
  font-size: 0;
  line-height: 0;
  text-shadow: none;
  color: transparent;
}

@mixin text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin text-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin filter($value) {
  -webkit-filter: blur($value);
  -moz-filter: blur($value);
  -o-filter: blur($value);
  -ms-filter: blur($value);
  filter: blur($value);
}

@mixin x-y-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

@mixin y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

@mixin x-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

@mixin fontws($w, $s) {
  font-weight: $w;
  font-size: $s;
}

@mixin font-text($w, $s, $l) {
  font-weight: $w;
  font-size: $s;
  line-height: $l;
}

@mixin avatar($x) {
  width: $x;
  height: $x;
  line-height: $x;
  min-width: $x;
  font-size: $x / 1.33;

  @if $x > 160px {
    border-width: 6px;
  } @else if $x > 40px {
    border-width: 3px;
  } @else {
    border-width: 2px !important;
  }

  i {
    font-size: $x/1.33;
    display: inline-block;
  }
}

@mixin icon-span($x) {
  width: $x;
  height: $x;
  line-height: $x - 4px;
  min-width: $x;
  font-size: $x / 1.9;

  i {
    font-size: $x / 1.9 !important;
  }
}

@mixin avatarimp($x) {
  width: $x !important;
  min-width:($x) !important;
  height: $x !important;
  line-height: $x - 4px !important;
  font-size: $x / 3 !important;
}

@mixin flex($properties) {
  @if $properties == 'flex' {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
  } @else if $properties == 'inline-flex' {
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      display: inline-flex;
  }
}

@mixin flexwidth($val){
  flex: $val;
  max-width: $val;
}

@mixin flexallwidth($val){
  flex: $val;
  max-width: $val;
  min-width:  $val;
}


@mixin flex_direction($properties) {
  -webkit-flex-direction: $properties;
  -ms-flex-direction: $properties;
  flex-direction: $properties;
}

@mixin flex_wrap($properties) {
  @if $properties == 'nowrap' {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  } @else if $properties == 'wrap' {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  } @else if $properties == 'wrap-reverse' {
    -webkit-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
}

@mixin flex_justify($properties) {
  @if $properties == 'flex-start' {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  } @else if $properties == 'flex-end' {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  } @else if $properties == 'center' {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  } @else if $properties == 'space-between' {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  } @else if $properties == 'space-around' {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

@mixin flex_align_content($properties) {
  @if $properties == 'flex-start' {
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  } @else if $properties == 'flex-end' {
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  } @else if $properties == 'center' {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  } @else if $properties == 'space-between' {
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  } @else if $properties == 'space-around' {
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  } @else if $properties == 'stretch' {
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
}

@mixin flex_align_items($properties) {
  @if $properties == 'flex-start' {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  } @else if $properties == 'flex-end' {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  } @else if $properties == 'center' {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  } @else if $properties == 'baseline' {
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  } @else if $properties == 'stretch' {
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

@mixin flex_align_self($properties) {
  @if $properties == 'auto' {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  } @else if $properties == 'flex-start' {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  } @else if $properties == 'flex-end' {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  } @else if $properties == 'center' {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  } @else if $properties == 'baseline' {
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
  } @else if $properties == 'stretch' {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@mixin flex_order($properties) {
  -webkit-order: $properties;
  -ms-flex-order: $properties;
  order: $properties;
}

@mixin flex_grow($properties) {
  -webkit-flex-grow: $properties;
  -ms-flex-positive: $properties;
  flex-grow: $properties;
}

@mixin flex_shrink($properties) {
  -webkit-flex-shrink: $properties;
  -ms-flex-negative: $properties;
  flex-shrink: $properties;
}

@mixin flex_basis($properties) {
  -webkit-flex-basis: $properties;
  flex-basis: $properties;
}

@mixin flex_center() {
  @include flex(flex);
  @include flex_align_items(center);
  @include flex_justify(center);
}

@mixin flex_space_between() {
  @include flex(flex);
  @include flex_align_items(center);
  @include flex_justify(space-between);
}

@mixin flex-max-width($x) {
  flex: 0 0 $x;
  max-width: $x;
}

@mixin fontstyle($size, $weight, $lineHeight) {
  font-size: $size;
  font-weight: $weight;
  line-height: $lineHeight;
}

@mixin fontsw($size, $weight) {
  font-size: $size;
  font-weight: $weight;
}

@mixin fontwl($weight, $lineHeight) {
  font-weight: $weight;
  line-height: $lineHeight;
}

@mixin fontsl($size, $lineHeight) {
  font-size: $size;
  line-height: $lineHeight;
}

@mixin sticky {
  position: -webkit-sticky;
  position: sticky;
}

@mixin backface-visibility {
  -webkit-backface-visibility: hidden;  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}

@mixin flex_space_between() {
  @include flex(flex);
  @include flex_align_items(center);
  @include flex_justify(space-between);
}

@mixin flexstyle($flex, $flex_ai, $flex_jc, $column) {
  display: $flex;
  align-items: $flex_ai;
  justify-content: $flex_jc;
  flex-direction: $column;
}

@mixin multiline-truncate ($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  white-space: normal;
}

@mixin hideInputNumerArrow {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type=number]::-webkit-inner-spin-button {
    background: red;
  }
}

@mixin keep-aspect-ratio($x, $y, $width: 100%) {
  position: relative;
  padding-bottom: ($y / $x) * $width;
  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@mixin stickyHorizontal($x) {
  left: -$x - 12px;
  right:-$x - 12px;
}

@mixin minmaxwidth($x) {
  min-width: $x;
  max-width: $x;
}

@mixin equalAllHeight($x) {
  min-height: $x;
  max-height: $x;
  height:  $x;
}

@mixin equalWidth($x) {
  min-width: $x;
  width: $x;
  max-width:  $x;
}

@mixin osfont{
  font-family: fill-os-icon!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin col-os($n) {
  flex: 0 0 10% * $n;
  max-width: 10% * $n;
}

@mixin clvSpin($x, $x1, $x2) {
  .new-cs-card {
    .spin-video {
      .threesixty-spin {
        transform: translate(-50%,-50%) scale($x);
      }
    }

    .case-left-wrapper {
      &:hover {
        .spin-video {
          .threesixty-spin {
            transform: translate(-50%,-50%) scale($x1);
          }
        }
      }
    }

    .sbs-wrapper {
      .spin-video {
        .threesixty-spin {
          transform: translate(-50%,-50%) scale($x2);
        }
      }
    }
  }
}

@mixin spin($x) {
  .threesixty-spin {
    transform: translate(-50%,-50%) scale($x);
  }
}

@mixin spinScaleWidth($x) {
  transform: translate(-50%,-50%) scale($x / 800);
}

@mixin spinScaleHeight($x) {
  transform: translate(-50%,-50%) scale($x / 472);
}

@mixin extra-small-card-footer {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  .osbtn {
    width: 40px;
    height: 40px;
    overflow: hidden;
  }
}

@mixin gutterx($x){
  padding-left : $x;
  padding-right : $x;
}

@mixin osIcon {
  font-family: 'fill-os-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

@mixin centerItem {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@mixin verticalCenter {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
