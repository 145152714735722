.instructor_sidebar {
  margin-bottom: 24px;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  .testimonial_sidebar-body {
    padding: 12px 0 0 0;
  }

  .avatar-120 {
    border-width: 6px;
  }
  .user-name-instructor {
    display: block;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 12px;
    @include fontstyle(20px, 500, 28px);
    padding: 0px 16px;
  }

  .instructor_slider_text {
    color: $level-11;
    @include fontstyle(16px, normal, 24px);
    margin-bottom: 24px;

    .instructor-text-p {
      @include multiline-truncate(10);
      overflow: hidden;
    }
  }

  .chevronright {
    display: none;
  }
}


.ipad {
  &.portrait {
    .instructor_sidebar {
      display: flex;
      margin-bottom: 80px;

      .user-info-instructor {
        min-width: 33.33%;
        padding-right: 16px;

        @media screen and (min-width: 1024px) {
          min-width: 28%;
        }
      }

      .instructor_slider_text {
        margin-bottom: 0;
      }

      .user-name-instructor {
        padding: 0;
      }
    }
  }
}

.mobile-device {
  .instructor_sidebar {
    padding: 0 16px;
    margin-bottom: 0;
  }
  .user-info-instructor {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 24px;
    color: #d4d4d9;

    .avatar-120 {
      @include avatar(40px);
      margin: 0px 16px 0 0!important;

      .icon-span {
        @include icon-span(14px);
      }
    }

    .user-name-instructor {
      @include fontstyle(16px, normal, 24px);
      text-align: left;
      padding-right: 40px;
      padding-left:0;
    }

    .chevronright {
      display: block;
      position: absolute;
      right: 0;
      color: #6c6c6f;
    }
  }

  .instructor_slider_text {
    @include fontstyle(16px, normal, 24px);

    .osbtn{
      margin-top: 32px;
    }
  }
}
