.folder-modal {
  .folder-modal-header {
    display: flex;
    gap: 8px;
    align-items: center;
    .heading {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4;
      color: #354554;
      margin: 0px;
    }
  }
  .folder-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
  }
  .folder-footer {
    padding-top: 16px;
  }
}

.folder-modal-form {
  .form-container {
    padding: 0;
    min-width: auto;
    .form-group {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    .folder-name {
      display: flex;
      gap: 16px;
      .hero-image {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        object-fit: cover;
        aspect-ratio: 4/3;
        transition: all 0.3s linear;
        flex-grow: 0;
        border-radius: 4px;
      }
    }
    .radio-button-group {
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      gap: 16px;
      div {
        flex: 1;
      }
    }

    .radio-input-element {
      display: none;
    }

    .radio-block {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 16px;
      border-radius: 8px;
      background-color: #f4f7f9;

      font-size: 12px;
      font-weight: 600;
      line-height: 1.33;
      text-align: center;
      color: #4a749a;
    }
    .radio-input-element:checked + .radio-block {
      background-color: #4a749a;
      color: #fff;
    }

    .folder-banner-field {
      .banner-field {
        display: flex;
        gap: 8px;
      }

      .banner-preview-box {
        height: 120px;
        width: 120px;
        background-color: #b9c4cf;
        border-radius: 8px;

        .banner-preview {
          height: inherit;
          width: inherit;
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
        }
      }

      .banner-dropzone-box {
        width: 100%;
        height: 120px;
        padding: 16px;
        border: 1px dashed #4a749a;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        .description {
          font-size: 16px;
          font-weight: 400;
          padding: 0;
          margin-bottom: 2px;

          span {
            color: #4a749a;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .description-limit {
          font-size: 12px;
          font-weight: 400;
          color: #7e91a5;
        }
      }
    }
  }
}
