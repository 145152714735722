.relatedTaskWidget {

  .container {
    opacity: 1;
    min-width: 100%;
    background-color: #fff;
    padding: 12px 12px 12px 0;

    .header {
      height: 40px;
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 7px 0 20px;

      .headerTitle {
        height: 28px;
        flex-grow: 0;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: rgb(126, 145, 165);
        display: flex;
        align-items: center;
        gap:16px;

        @media (min-width: 1920px) {
          font-size: 20px;
          line-height: 28px;
        }

        .seeAllBtn {         
          .osbtn{
            font-size: 12px;
            display: flex;
            align-items: center;
            padding: 0 !important;
          }
        }
      }

      .btnContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .widget-chevron-icon {
          cursor: pointer;
        }


        .plusBtn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;

          i {
            color: #7e91a5;
          }
        }
      }
    }

    .relatedTaskList {
      max-height: 490px;
      overflow-y: scroll;
      scroll-behavior: smooth;

      ::-webkit-scrollbar {
        display: none;
      }

      margin-top: 12px;
      width: 100%;
      height: calc(100% - 40px);

      .singleTask {
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        padding: 0 4px 12px;
        background-color: rgb(255, 255, 255);
        cursor: pointer;

        .top {
          align-self: stretch;
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0;

          .completeCheckBox {
            width: 40px;
            height: 40px;
            flex-grow: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 8px;
            cursor: pointer;
          }

          .titleAndCreator {
            width: 100%;
            min-height: 40px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 8px;

            .title {
              flex-grow: 0;
              font-family: Inter;
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.25;
              letter-spacing: normal;
              text-align: left;
              color: rgb(53, 69, 84)
            }
          }

          .btnContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
          }
        }

        .dueDate {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #5D7893;
          mix-blend-mode: normal;

          span {
            margin-right: 4px;
          }

          img {
            margin-bottom: 3px;
            margin-left: 4px;
          }
        }

        .creator {
          margin-left: 40px;
          flex-grow: 0;
          font-family: Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: rgb(126, 145, 165);
        }

        .description {
          margin-left: 40px;
          align-self: stretch;
          flex-grow: 0;
          font-family: Inter;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: rgb(53, 69, 84);
        }

        .entityWrapper {
          margin-left: 40px;
        }

        .assigneeAndLabels {
          margin-left: 40px;
          height: 24px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;

          .teamMembersDropdown {

            .react-select-ortho-2 {
              height: 24px;

              .os__control {
                border-color: #edeff3;
                background-color: #edeff3;
                height: 24px;
                max-height: 24px;
                min-height: 24px;

                .os__value__container,
                .os__value-container--has-value {
                  padding: 0;

                  .os__input-container {
                    margin: 0;
                    padding: 0;
                  }

                  .os__single-value {
                    max-width: 100%;
                    min-width: 140px;

                    div {
                      display: flex !important;

                      .avatar {
                        margin-right: 4px;
                        width: 16px;
                        min-width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                          width: 16px;
                          height: 16px;
                          border-radius: 50%;
                        }
                      }

                      .result-text-data {
                        flex-grow: 0;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.33;
                        letter-spacing: normal;
                        text-align: left;
                        color: #354554;
                        display: flex;
                        align-items: center;
                      }
                    }
                  }
                }
              }
            }
          }

          .taskLabelContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 8px;
            gap: 4px;
            border-radius: 12px;
            min-height: 24px;


            .singleTaskLabel {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              color: #354554;
            }
          }
        }
      }

      .line {
        width: 100%;
        border: 0.5px solid #C1C1C1;
        opacity: 0.3;
      }
    }

    .relatedTaskList::-webkit-scrollbar {
      display: none;
    }

  }

  .assigneeContainer {
    .assigneeList {
      top: 0px;
      left: -50px;
    }
  }

  .scheduledTaskContainer {
    position: relative;

    .customPredefinedDates {
      top: 0px !important;
      left: -50px !important;
    }

    .calendar {
      // position: absolute;
      top: 0px !important;
      left: -65px !important;

      input {
        // z-index: 10;
        // height: 40px;
        // width: 308px;
      }
    }
  }

}