.with-cross,
.selection-mode,
.comparison-cards-holder {
  .header-dropdown-menu-helper {
    display: none;
  }

  &:not(.multi-selection-mode) {
    .osbtn,
    .os-btn-link {
      color: $level-6 !important;
      cursor: not-allowed;
      border-color: $os-btn-disabled !important;
      z-index: 1;

      .ifill-os-fill-case-2-d {
        color: inherit;
      }

      .btn-icon-helper {
        color: inherit !important;
      }
    }
  }

  .os-btn-link {
    pointer-events: none;
  }

  .header-dropdown-menu {
    pointer-events: none;

    .cursor-pointer {
      cursor: inherit;
      pointer-events: none;
    }
  }

  .btn-link-helper {
    visibility: hidden;
    display: none;
  }

  .selectable-item {
    .btn-link-helper {
      visibility: visible;
      display: initial;
    }
  }

  .osbtn:not(.selectable-item) {
    position: relative;

    &:hover {
      color: $level-6;
      background: none !important;
    }
  }

  .doc-info-actions {
    color: $level-6
  }
}

.os-link-disabled {
  color: $level-6 !important;
  cursor: not-allowed !important;

  .btn-link-helper {
    display: none;
    visibility: hidden;
  }
}

.selectable-item {
  display: none;
}

.multi-selection-mode {
  .selectable-item {
    display: inline-block;
    margin: auto;
    padding-right: 8px;
    padding-left: 32px;

    .btn-icon-helper {
      left: 10px;
      font-size: 20px;
    }

    &.selected {
      .btn-icon-helper {
        color: $os-link !important;
      }
    }
  }
  .os-sm-attachment,
  .os-sm-video {
    .selectable-item {
      position: absolute;
      z-index: 1;
    }

    .file-duration {
      visibility: hidden;
    }
  }

  .page-heading-title {
    pointer-events: auto;
  }

  .space-v2-card {
    .selectable-item {
      margin-top: 8px;
    }
  }

  .os-sm-company,
  .os-sm-tool,
  .os-sm-user {
    .selectable-item {
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.extra-small-min-card  {
    .selectable-item {
      padding: 0px 8px !important;
      position: absolute;
      left: auto !important;
      right: 8px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      max-width: 40px;
      max-height: 40px;
      margin: 0px !important;

      .btn-icon-helper {
        left: 50% !important;
      }
    }
  }
}

.no-touch-support {
  .with-cross,
  .selection-mode,
  .comparison-cards-holder {
    .a-link.os-btn-link:hover {
      color: $level-6 !important;
      text-decoration: none !important;
    }
  }

  .selectable-item:hover {
    color: $level-11 !important;

    .btn-icon-helper {
      color: $os-link !important;
    }
  }
}
