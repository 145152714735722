.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  
  .table {
    margin-bottom: 0;
    
    th {
      white-space: nowrap;
      background-color: #f8f9fa;
    }
    
    td {
      vertical-align: middle;
    }
  }
}

.table-loading {
  text-align: center;
  padding: 2rem;
}

@media (max-width: 768px) {
  .table-responsive {
    .table {
      td, th {
        min-width: 120px;
      }
    }
  }
} 