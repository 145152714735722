.input-with-btn {
  .mention-textarea-wrap__control,
  .mention-textarea-wrap__highlighter,
  .mention-textarea-wrap__input {
    border-radius: 8px;
  }

  .osbtn {
    color: $level-8;
    background: transparent;

    .btn-icon-helper {
      color: inherit !important;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &.osbtn-disabled {
      color: $level-5 !important;
    }

    &:hover {
      color: $level-8;
      background: $level-4;
      box-shadow: none !important;
    }

    &:active {
      box-shadow: none !important;
    }
  }

  .message-send-btn,
  .conversation-input-button {
    &.osbtn-disabled {
      color: $level-6;
    }

    &:hover {
      background: $input-hover-border !important;
      color: $level-1 !important;
    }

  }

  .input-parent-box {
    div{
      .referenceContainer{
        min-height: 0px !important;
      }
    }
    textarea {
      border: solid 1px $input-border-active;
    }

    &:hover {
      textarea,
      input {
        border-color: $level-11 !important;
      }
    }
  }
}

.detail-comments-top-block {
  .input-parent-box {
    textarea {
      border: solid 1px transparent;
    }
  }
}

.input-focus-active {
  .message-send-btn,
  .conversation-input-button {
    background: $level-11 !important;
    color: $level-1 !important;
  }

  .input-parent-box {
    textarea.mention-textarea-wrap__input {
      border-color: $level-11 !important;
    }
  }
}
