.workspace {
  .contents, .members, .comments {
    .container-workspace {
      padding-top: 24px;
    }
  }

  .container-workspace {
    > .xl-container {
      padding-top: 24px;
    }
  }

  .pt-24 {
    padding-top: 32px ;
  }

  .mt-4 {
    margin-top: 0;
  }

  .explore {
    .universalBannerWrapper {
      + .container-workspace {
        padding-top: 0;
      }
    }

    .explore-container {
      padding-top: 0 !important;
    }
  }

  .members, .spaces {
    .container-workspace {
      padding-right: 15px;
      padding-left: 15px;
    }

    .manageable-sections {
      margin-top: 24px;
    }
  }

  .conversations {
    @media (min-width: 576px){
      .container-workspace {
        max-width: 100% !important;
      }
    }
  }

  .submit-case-container {
    padding-left: 0;
    padding-right: 0;
  }

  .footer:empty {
    display: none;
  }

  .navbar-unauth-signin-text {
    display: none;
  }

  .osbtn {
    &[text="Sign up"],
    &[href*='signup'] {
      display: none;
    }
  }

  .os-main-nav {
    margin-right: auto;

    + .container {
      .h1-page-heading {
        margin-top: 32px;
      }
    }
  }

  .activity-container {
    padding-bottom: 40px;
  }

  &.edit-page {
    .floater-message-wrapper {
      display: none;
    }
  }

  &.conversations {
    .os-main-nav {
      + .container {
        max-width: 100%;
      }
    }
  }

  .universalBannerWrapper ~ .os-main-nav + .container-workspace .collection-page.conversations-page {
    height: calc(100vh - 138px);
  }

  .onboard__wrapper {
    .login__icon__block {
      img {
        @media(min-width: 1024px) {
          max-width: 90%;
        }

        @media(min-width: 768px) {
          position: relative;
          pointer-events: none;
          top: 50px;
        }

        @media(max-width: 1023px) {
          margin: auto;
        }
      }
    }
  }
}

.os-main-nav-inner {
  .cs-dropdown {
    margin-right: 24px;

    .navbar-brand {
      display: inline-flex;
      margin-right: 12px;
    }

    .dropdown-info-title {
      max-width: 192px;
      min-width: 192px;

      .page-heading-title {
        background-color:transparent;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .pointer {
          width: 100%;
          display: flex;
          align-items: center;
          line-height: 24px;

          .workspace-icon-logo {
            font-size: 20px;
            vertical-align: top;
            display: inline-block;
            margin-top: -2px;
            padding-right: 10px;
          }

          span.text-truncate{
            display: inline-block;
            max-width: calc(100% - 88px);
            margin-right: 8px;
            min-width: 104px;
          }
        }
      }
    }

  .ifill-os-chevron-caret-down,
  .ifill-os-chevron-caret-up {
    right: 0;
    position: relative;
    top: 8px !important;
    color: #5c5c66;
  }

  .header-dropdown-list {
    z-index: 1140;
    max-width: 192px;
    min-width: 192px;
    right: auto;
    left: 118px;

    .header-dropdown-list-item:first-child {
      &::before {
        right: auto;
        left: 19px;
      }
    }

    .header-dropdown-list-item > div {
      padding: 0;
    }

    .os-btn-link  {
      text-decoration: none;
      max-width: 100%;
      display: flex;
      align-items: center;
      @include text-truncate();
      padding: 10px 16px;

      &[data-hover='true']:not(.nohover):hover {
        color: $os-link-hover;
      }

      span {
        line-height: 16px;
        display: inline-block;
      }

      .workspace-icon-logo {
        font-size: 20px;
        padding-right: 10px;
        transform: none;
        position: static;
        margin: 0;
      }
    }


    span.oval {
      &:hover {
        background-color: $os-link !important;
        border-radius: 50%;
      }
    }

    .navbar-brand {
      + .oval {
        &.absolute {
          right: 16px;
          width: 4px;
          height: 4px;
          top: 50%;
          left: auto;
          background-color: $os-link !important;

          &:hover {
            background-color: $os-link !important;
          }
        }
      }
    }
  }

    &.community {
      .header-dropdown-list {
        left: 77px;
      }
    }
  }
}

.clinic {
  .os-main-nav-inner {
    .cs-dropdown {
      .header-dropdown-list {
        left: 77px;
      }
    }
  }
}

.ipad {
  .os-main-nav-inner {
    .cs-dropdown {
      .header-dropdown-list {
        right: auto;
        left: 0;
      }

      .dropdown-info-title {
        .page-heading-title {
          .pointer {
            span.text-truncate {
              max-width: calc(100% - 65px);
            }
          }
        }
      }

      @media(max-width:  1024px) {
        min-width: 110px;
        margin-right: 20px;
        .dropdown-info-title {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }

    @media(max-width: 1024px) {
      .navbar-nav {
        .nav-item:not(:last-of-type) {
          margin-right: 35px !important;
        }
      }
    }

    @media(max-width: 768px) {
      .navbar-nav {
        .nav-item:not(:last-of-type) {
          margin-right: 22px !important;
        }
      }
    }
  }
}

.mobile-device {
  &.workspace {
    .users {
      .container-workspace {
        padding: 0;
      }
    }

    .os-btn-link.navbar-brand  {
      display: none;
    }

    .add-post-area {
      padding: 0 0;
    }

    .no-invited-user-container {
      .invite-img-block {
        img {
          max-width: 300px;
        }
      }
    }

    .pulse-add-new {
      .detail-comments-top-block {
        flex-direction: column;

        .user-link, .reply-buttons {
          display: none;
        }

        .media-body {
          width: 100%;
        }

        .avatar-32 {
          @include avatar(24px);
        }

        .comments-primary-avatar {
          left : 10px;
          top: 20px;
        }

        .mention-textarea-wrap__input,
        .mention-textarea-wrap__highlighter {
          padding-left: 44px;
        }
      }

      .osbtn:not(.osbtn-icon) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .os-main-nav {
      margin-right: auto;

      + .container {
          margin-top: 24px;
      }
    }

    .post-mobile-top {
      padding: 0 12px;
    }

    .space-search-container {
      padding: 0;
    }

    .space-details-table-header {
      padding-right: 12px;

      .invite-block {
        max-width: 130px;
        white-space: nowrap;
      }
      .action-block {
        max-width: 155px;
        flex: 0 0 155px;
        margin-left: auto !important;
        .osbtn {
          margin-right: 0px !important;
        }
      }
    }

    .subspace-container {
      padding: 0;

      .h1-page-heading {
        margin-top: 0;
      }
    }

    .space-links-container {
      .cards {
        margin: 0;
      }
    }

    .head-sticky {
      top: 64px;
    }

    .comment-reply-container {
      .detail-comments-top-block {
        .mention-textarea-wrap__input {
          padding-left: 16px;
        }
      }
    }

    .mobile-header-search {
      min-height: 72px;
    }

    .conversations {
      &:not(.chat-detail) {
        .universalBannerWrapper ~ .container-workspace{
          .collection-page {
            top: 142px;
          }
        }
      }


      &.new-chat {
        .universalBannerWrapper ~ .container-workspace{
          .conversations-page {
            .user-selection-row {
              top: 142px;
            }
          }
        }
      }
    }

    .chat-detail {
      .list-messages {
        padding: 0 25px !important;
        margin-top: 36px;
      }
    }

    .user-name-block {
      .span-helper {
        display: inline-block !important;
      }
    }
  }

  .guest-user-view {
    .container-workspace {
      margin-top: 24px;

      .information-tab {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

.ipad {
  .conversations {
    .container-workspace {
      width: 100%;
      max-width: 100% !important;
    }
  }
}

.edit-info-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;

  .info-head {
    font-weight: 600;
    color: $level-11;
  }
}

.edit-info-link {
  font-size: 12px;
  z-index: 5;
}

@media screen and (max-width: 767px) {
  .edit-info-head {
    margin-bottom: 0;
  }

  .draftPluginsEditorWrapper.modeEdit {
    margin-top: 20px;
  }
}

html:not(.mobile-device) {
  &.workspace {
    .spaces {
      .manageable-sections {
        .space-links-container {
          .cards {
            &.row {
              margin-left: -12px !important;
              margin-right: -12px !important;
            }
          }
        }
      }
    }
  }
}

.ipad {
  &.portrait {
    &.workspace {
      .clv-row {
        .video-inprogress {
          height: 100%;
        }

        .case-right-header {
          padding-left: 24px;
          margin-top: 50px;
        }
      }
    }
  }
}

.container-workspace {
  .info-read-only {
    margin-top: 24px;
  }

  .pt-24 {
    padding-top: 32px ;
  }
}


.create-space-head {
  .h1-page-heading {
    + span {
      display: none;
    }
  }
}


.workspace-page-head {
  padding: 16px !important;

  &.page-head {
    .page-heading-right {
      text-align: right;
    }
    @media screen and (min-width: 768px) {
      .cs-right-dropdown {
        position: relative;
        right: 0;
        display: inline-flex;
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      margin-top: 32px;
      position: relative;

      .cs-right-dropdown {
        right: 8px;
      }

      .header-dropdown-menu {
        .header-dropdown-list {
          z-index: 13;
          margin-top: 0;
        }
      }
    }
  }

  .workspace-space-page-heading {
    @extend %heading-2-semibold;

    @media screen and (max-width: 767px) {
      @include fontstyle(20px, $font-weight-semibold, 28px);
    }

    + .page-heading-right > .action-block {
        margin-top: 0 !important;
        margin-left: 1rem;

        @media screen and (max-width: 767px) {
          .osbtn {
            top: 0 !important;
          }
        }
      }
  }

  &:not(.page-head) {
    .page-heading-right {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;

      .cs-right-dropdown {
        right: 16px;
      }

      .container-fluid {
        max-width: 240px;
        margin: 0;
      }

      .nav-search-input {
        min-height: 40px !important;
        height: 40px !important;
      }

      .form-group {
        margin-bottom: 0;
      }

      .space-search-container {
        margin-bottom: 0;
        height: 40px;
      }

      .nav-search-input {
        padding-left: 42px !important;
      }

      .icon-os-search {
        line-height: 40px !important;
        color: #72727e;
      }

      .osbtn {
        margin-left: 16px;

        &.osbtn-primary {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .row {
      flex-direction: column;
      position: relative;
    }

    &:not(.page-head)
    .page-heading-right {
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column-reverse;
      margin-top: 24px;

      .container-fluid {
        max-width: 100%;
      }

      .osbtn {
        max-width: 165px;
        position: absolute;
        top: 0px;
        right: 12px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .spaces {
    .workspace-page-head {
      .page-heading-right {
        .osbtn {
          top: -16px;
        }
      }
    }
  }
}

.space-members-group-container {
  .spc-tab-heading {
    + .on-demand-content-container {
        margin-top: 0;
    }
  }
}

@media screen and (min-width: 767px){
  .folders {
    .workspace-space-page-heading {
      line-height: 40px;
    }
  }
}
