.object-list-item {
  cursor: pointer;
  transition: 500ms;
  will-change: background-color, transform;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $border-button-1;

  &:first-of-type {
    border-top: none;
  }

  &:hover {
    z-index: 999 !important;
    background-color: rgba(255, 255, 255, 0.04);
    .list-img-side .scan-image img {
      transform: scale(1.1);
    }
  }

  &.assign-selected{
    background-color: rgba(255, 255, 255, 0.08);
    position: relative;
    z-index: 9;

    .object-list-add-button{
      background-color: rgba(0,0,0,0.3);
      width: 90px;

      &:not(.no-hover):hover {
        .add-button-dropdown {
          display: block;
        }
      }

      .add-button-dropdown {
        display: none;
      }

      .btn {
        &.btn-gray8 {
          padding: 0;
          background: transparent !important;

          i {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .object-list-add-button{
    background-color: rgba(255,255,255,0.3);
    height: 34px;
    width: 48px;
    border-radius: 24px;
    position: absolute;
    right: 20px;
  }

  .list-img-side {
    position: relative;

    .scan-image {
      overflow: hidden;

      img {
        transition: 500ms;
        object-fit: cover;
        height: 150px;
      }

      &.board-image img {
        height: 75px;
      }
    }

    .doctor {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 70px;
      padding-bottom: 20px;

      span {
        color: rgba(255, 255, 255, 0.8);
      }

      img {
        position: absolute;
        left: 10px;
        bottom: 10px;
        box-shadow: 0 0 3px black;
      }
    }
  }

  .list-type{
    color: $level-11;
    font-size: 11px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }


  .list-published{
    font-size: 14px;
    color: #8f8f8f;
  }

  $font-size: 12px;
  $line-height: 1.2;
  $lines-to-show: 2;

  .list-text {
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    max-width: 525px;
    height: $font-size*$line-height*$lines-to-show -1; /* Fallback for non-webkit */
    margin: 0 auto;
    font-size: $font-size;
    line-height: $line-height;
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .object-list-item-check{
    position: relative;

    .ifill-os-checkmark-default-white{
      position: absolute;
      color: #787878;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding-left: 2px;
    }

    input:checked ~ .ifill-os-checkmark-default-white {
      &:before {
        content: "\e94f";
        color: $level-11;
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    &.assign-selected {
      .object-list-add-button{
        &:hover {
          .add-button-dropdown {
            margin-left: 0;
            left: auto;
            right: -20px;

            &::before {
              margin-left: 0;
              left: auto;
              right: 20px;
            }
          }
        }
      }
    }

  }
}

.mobile-device{
  .head-sticky {
    position: sticky;
    top: 64px;
    z-index: 8;
  }

  &.unauthorized-session  {
    .head-sticky {
      top: 53px;
    }

    .mobile-signup-footer {
      .os-btn-link {
        .btn-link-helper {
          font-size: 16px;
          font-weight: $font-weight-semibold;
        }
      }
    }
  }
}


@media only screen and (min-device-width : 768px) {
  .head-sticky {
    position: sticky;
    top: 0px;
    z-index: 99;
  }
}

.head-sticky {
  .page-heading-title {
    padding-top: 4px;
  }

  .object-view-button-block .btn {
    top: 0 !important;
  }

  &.dropdown-open {
    z-index: 1024;

    .transparent-overlay {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1023;
      background: rgba(0, 0, 0, .3);
    }

    .object-view-switcher {
      .list-inline-item {
        &.sorting-block {
          position: relative;
          z-index: 1024;
        }
      }
    }
  }

  .object-view-switcher {
    margin: 0;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.object-header-bar {
  color: $heading;

  .heading-bar-icon {
    cursor: pointer;
    margin-left: 16px;
  }

  .list-inline-item {
    .icon-cards {
      font-size: 20px;
    }
    &.sorting-block {
      z-index: 1;
      position: relative;
    }

  }

  .object-query {
    right: 240px;

    &.tool-search-object {
      right: 80px;
    }
  }

  .object-view-switcher {
    &.user-search-sort-block {
      right: 100px;
      bottom: 1px;

      .sorting-holder {
        height: 36px;
      }
    }
  }

  .object-sort {
    position: absolute;
    bottom: 5px;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 14px;
      height: 14px;
      margin-left: 6px;
    }
  }
  .object-query{
    position: absolute;
    bottom: 1px;
    right: 100px;
    display: flex;
    align-items: center;
    &.object-query-pull-right{
      right: 0;
    }
  }
  a {
    text-decoration: none;
    color: $level-11;
    .btn {
      font-size: 13px;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    overflow-y: auto;
    &.assign-drawer-modal {
      overflow-y: initial;
    }
    .list-inline-item {
      &.list-view-options {
        position: relative;
        top: -4px;
      }

      .icon-cards {
        font-size: 16px;
      }
    }
  }
}

.object-filter {
  margin-bottom: 5px;
  /*padding-top: 10px;*/
  li,
  a {
    height: 100%;
    padding: 0;
    align-items: center;
    cursor: pointer;
    color: $tab;
    margin-right: 24px !important;
    @extend %heading-3-semibold;

    &:last-of-type{
      margin-right: 0px !important;
    }

    &.object-filter-active {
      color: $tab-active;
      position: relative;


      &::after {
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        content: "";
        border-bottom: 2px solid $tab-active;
      }

      .dropdown-info-title {
        color: $level-11;
      }
    }

    &:hover {
      color: $tab-active;
    }

    .list-item-count {
      margin-left: 4px;
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 930px) {
    li,
    a {
      margin-right: 30px !important;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    white-space: nowrap;

  }
}


.object-view-switcher {
  position: absolute;
  right: 15px;
  bottom: -14px;
  display: flex;
  align-items: flex-end;

  .list-inline-item{

    &:last-child {
      margin-right: 0 !important;
    }
  }
  li,
  a:not(.osbtn) {
    height: 100%;
    padding: 0;
    align-items: center;
    cursor: pointer;
    color: #c8c8c8;
    margin-right: 20px !important;
    font-size: 20px;
    &.object-view-active {
      color: $level-11;
      position: relative;
    }
    .icon-list{
      position: relative;
      top: 4px;
      font-size: 24px;
    }
  }

  .filters-block {
    display: none;
  }

  li {
    &.object-view-button-block {
      margin-right: 0 !important;

      .btn {
        position: relative;
        top: -1px;
        &.btn-submit-case {
          background-color: #c8c8c8;
          border-color: #c8c8c8;
          &:hover {
            background-color: #4294E5;
          }
        }

        i {
          &.ifill-os-invite {
            position: relative;
            top: -1px;
            font-size: 15px;
          }
        }
      }
    }
  }

  a {
    &.btn {
      margin-right: 0 !important;
    }
  }

  .header-dropdown-menu {
    background: transparent;
    border-radius: 4px;

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      padding: 6px 0px 0;
      font-size: 14px;
    }

    .page-heading-title {
      height: 34px;
      padding-left: 24px;
    }

    .ifill-os-chevron-down,
    .ifill-os-chevron-up {
      right: 5px;
    }
  }

  .sorting-icon-block {
    margin-right: 12px;
    i {
      &.ifill-os-views {
        display: block;
      }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      margin-right: 6px;
    }
  }

  .icon-ascending-primary {
    width: 27px;
    font-size: 20px;
    line-height: 30px;
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      width: 18px;
    }
  }

  .header-dropdown-menu {
    margin-right: 20px;
    position: relative;

    .header-dropdown-list {
      width: 280px;
      top: 40px;
    }
  }

  .header-dropdown-list-item {
    padding: 0 !important;
  }


  .page-heading-title {
    padding-top: 0;
  }

  .sorting-dropdown-content {
    width: 100%;
    padding: 0;
    line-height: 1.5;
    vertical-align: middle;
    display: inline-block;
  }

  .sorting-check-icon {
    width: 18px;
    height: auto;
    position: absolute;
    top: 13px;
    right: 12px;
    color: $dropdown-icon;

    &.ifill-os-tick-single {
      &:before {
        color: $dropdown-icon-hover;
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    width: 100px;
    margin-bottom: 0;
    align-items: normal;
    bottom: auto;
    top: 0;
    right: 0;

    .list-inline-item {
      display: none;

      &.filters-block,
      &.sorting-block {
        display: block;
        position: relative;
      }

      &.filters-block {
        height: 38px;
        padding-left: 20px;

        &::before {
          content: '';
          width: 1px;
          height: 20px;
          position: absolute;
          top: 10px;
          left: 0;
          background: #5c5c66;
        }
      }

      .filters-icon-holder {
        width: 22px;
        height: 38px;
        margin-right: 0 !important;
        padding-top: 9px;
        display: inline-block;
        font-size: 18px;

        .ifill-os-filter {
          color: $level-11;

          &.ifill-os-filter-mobile {
            &:after {
              content: '';
              position: absolute;
              right: 3px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: $lt-orange;
            }
          }
        }
      }

      &.sorting-block {
        margin-right: 0 !important;

        .sorting-dropdown {
          left: auto;
          top: 36px;
          right: -18px;
        }
      }

      .sorting-holder {
        width: 21px;
        min-width: auto;
        height: 38px;
        padding: 7px 0 0;
        vertical-align: top;
        background: transparent;
        border-radius: 0;

        .sorting-block-text,
        .ifill-os-chevron-down,
        .ifill-os-chevron-up {
          display: none;
        }
      }

      .sorting-icon-block {
        margin-right: 0;
        opacity: 1;

        .icon-ascending-primary{
          line-height: 1;
        }

        img {
          width: auto;
          max-width: 27px;
          height: auto;
          max-height: 15px;
        }
      }
    }

    li {
      &.object-view-button-block {
        &:not(.spaces-cta) {
          display: none;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 930px) {
    .sorting-holder {
      padding: 10px 8px 0;
      font-size: 12px;

      .sorting-icon-block {
        margin-right: 8px;
        position: relative;
        top: 0px;
      }

      .icon-ascending-primary {
        width: 20px;
      }

      .ifill-os-chevron-down,
      .ifill-os-chevron-up {
        margin-left: 10px;
        top: 15px;
      }
    }

    .btn {
      height: 28px;
      padding: 0 15px;
      position: relative;
      top: -3px;
      font-size: 10px;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    li,
    a {
      margin-right: 10px !important;

      .icon-list {
        font-size: 18px;
      }
    }

    .btn {
      &.btn-small-text {
        height: 28px;
        padding: 2px 10px 0 10px;
        font-size: 10px;
      }
    }
    li {
      &.object-view-button-block {
        .btn {
          top: -4px;
        }
      }
    }

    .header-dropdown-menu {
      margin-right: 12px;

      .header-dropdown-list {
        width: 210px;
      }
    }

    .sorting-holder {
      min-width: 190px;
    }

    .sorting-dropdown-row {
      &:last-child {
        .icon-sorting-secondary {
          max-width: 19px;
        }
      }
    }

    .sorting-dropdown-icon-block {
      width: 32px;
      padding-top: 2px;
      padding-bottom: 0;

      .icon-sorting-secondary {
        max-width: 24px;
        max-height: 18px;
      }
    }

    .sorting-dropdown-content {
      font-size: 13px;
    }

    .sorting-check-icon {
      width: 13px;
      top: 11px;
      right: 5px;
    }
  }
}



@media (max-width : 1250px) {
  .case-cart-listing {
    .add-button-dropdown {
      left: auto;
      right: -10px;

      &::before {
        left: auto;
        right: 32px;
      }
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.cases {
      .object-filter {
        li {
          margin-right: 20px !important;
        }
      }
    }
  }
}

.touch-support {
  .object-header-bar {
    overflow: unset;
  }
}


@media screen and (max-width: 1430px) {
  .object-header-bar .object-view-switcher {
    .header-dropdown-list {
      left: auto;
      right: 0;
    }
  }
}


.cases {
  .object-header-bar {
    .object-view-switcher {
      right: 12px;

      .header-dropdown-list {
        width: 245px;
      }
    }
  }
}


.mobile-device {
  &.landscape {
    .head-sticky {
      z-index: 9;
    }
  }

  &:not(.detail-page) {
    .px-52 {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .non-clv-header {
    .px-52 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
