.tool-header-slider-section {
  .tool-header-slider-block {
    position: relative;
    border-radius: 8px;
  }

  &.non-spin-image-header {
    .tool-header-slider-block {
      background: #ffffff;
    }
  }
}

.detail-tool {
  .video-holder {
    .threesixty-spin {
      &:after {
        content: none !important;
      }
    }
  }

  .photo-display {
    display: block;
    text-align: center;

    .photo-display__main {
      img {
        width: 100%;
        object-fit: cover;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.25);
      }
    }

    .threesixty-spin {
      display: inline-block;
    }
  }

  .photo-display__picker {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slick-list {
      width: 100%;
    }

    img {
      object-fit: cover;
      cursor: pointer;

      &.selected {
        border: 1px solid #a0a0a0;
      }
    }


    .slick-slide {
      position: relative;
      &:after {
        content: '';
        opacity: .2;
        background-image: linear-gradient(to bottom, rgba(18, 18, 20, 0.5), #121214 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
      }
    }
  }

  .slick-dots {
    bottom: 20px;
    line-height: 1;
    z-index: 1;

    @media(max-width: 767px) {
      bottom: 10px;
    }

    li {
      margin: 0 6px;
      display: inline-block;
      vertical-align: middle;
      height: unset;
      width: unset;

      button {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        cursor: pointer;
        border: solid 2px transparent;
        transition: border 350ms ease;
        display: block;
        margin: 0;
        padding: 0;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          top: calc(50% - 4px);
          left: calc(50% - 4px);
          background: #fff;
          transition: background 300ms ease;
          opacity: 1;
        }

        &:hover {
          &:before {
            background: $cl-link;
          }
        }
      }


      &.slick-active button {
        border-color: #fff;
        cursor: default;

        &::before {
          background: #fff;
        }
      }
    }
  }


  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .photo-display {
      max-height: 150px;
      display: block;

      .threesixty-spin {
        width: 526px !important;
        position: relative;
        top: -105px;
        left: -40%;
      }
    }
  }

  @media only screen and (min-device-width : 414px) and (max-device-width : 767px) {
    .photo-display {
      .threesixty-spin {
        left: -30%;
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .video-holder {
      &.photo-display {
        .threesixty-spin {
          position: relative;
          top: 0;
          left: 0;
          transform: scale(.95);
        }
      }
    }
  }
}

.mobile-device {
  .tool-details-section {
    .tool-details-header {
      margin: 0px -12px;
    }

    .tool-data-info-conianter,
    .tool-details-tab-content-section {
      padding-top: 24px;
    }
  }

  .tool-header-content-section {
    padding: 0 0;
  }

  .tool-details-action-row {
    .engagement-new  {
      margin-left: -12px;
      margin-right: -12px;
    }
  }
}

