.upgrade-plan-header {
  margin-bottom: 48px;
  h1{
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 0;
  }
}

.macintel {
  .upgrade-plan-container {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    * {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
}

.upgrade-plan-container {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  line-height: 1.7;
  padding-bottom: 50px;

  .upgrade-plan-section {
    padding: 22px 30px;
    border-radius: 10px;
    background-color: #1c1c1f;
    box-shadow: 0 2px 23px 0 rgba(50, 72, 101, 0.21);
  }

  .plan-card {
    background-color: #e6e6e68;
    border-radius: 8px;
    margin: 8px 0;
    padding: 16px;
  }

  .upgrade-plan-period-row {
    width: 100%;
    text-align: right;
    margin-bottom: 8px;
    line-height: 1.5;
  }
  .text-smallcase {
    text-transform: capitalize;
  }
  .upgrade-plan-period-text {
    color: #fff;
    font-size: 14px;
    font-weight: $font-weight-normal;
    text-transform: uppercase;
    letter-spacing: .47px;
    opacity: .55;
    vertical-align: middle;
    line-height: 1.5;
    &.selected {
      opacity: 1;
    }
  }
  .sm-switch {
    vertical-align: middle;
    pointer-events: auto;

    &:before {
      content: '';
      width: 40px;
      height: 40px;
      position: absolute;
      top: -11px;
      left: -5px;
      opacity: 0;
      background: rgba(72, 172, 253, 0.2);
      -webkit-transition: opacity 500ms ease;
      transition: opacity 500ms ease;
      border-radius: 4px;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  .upgrade-plan-columns {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: stretch;
    align-content: stretch;
  }

  .upgrade-plan-header-top {
    @include flex(flex);
    @include flex_align_items(center);
    @include flex_justify(flex-start);
    @include flex_direction(column);
    padding: 16px 35px 0;
    min-height: 176px;
  }

  .upgrade-block-title {
    margin: 0;
    font-size: 24px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 1.33;
    min-height: 47px;
  }

  .upgrade-block-price-row {
    color: #48acfd;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: .67px;
    padding-top: 9px;
    padding-bottom: 16px;
    line-height: 1.61;
  }

  .upgrade-block-feature-text {
    font-size: 12px;
    min-height: 36px;
  }

  .plan-month {
    position: relative;
    color: #fff;
    font-size: 12px;
    font-weight: $font-weight-bold;
    vertical-align: top;
    top: 10px;
    text-transform: capitalize;
    line-height: 1;
  }

  .prize-icon {
    font-size: 47px;
    position: relative;
    display: inline-block;
    vertical-align: top;
  }

  .free-trial {
    font-size: 16px;
    line-height: 1.25;
    color: #48acfd;
  }

  .col {
    min-width: 20%;
    max-width: 33.33%;
    &.plan-selected {
      background:  linear-gradient(to bottom, rgba(97,127,158,0.3) 0%, rgba(50,72,101,0.3) 100%);
      border-radius: 10px;
      .btn-link-gray {
        pointer-events: none;
      }
    }
  }
  .col-with-badge {
    .upgrade-block-title {
      position: relative;
    }
  }

  .col-row {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 1.3;
    min-height: 50px;
    border-bottom: 1px solid $border-button-1;
    text-align: center;
    @include flex(flex);
    @include flex_align_items(center);
    @include flex_justify(center);

    &.btn-row {
      border-bottom: 0;
      margin-top: 40px;
      margin-bottom: 16px;
    }
    &.bt-0{
      border-bottom: 0;
    }
  }

  .ifill-os-checkmark-round{
    color: #48acfd;
    font-size: 28px;
  }

  .btn {
    font-size: 12px;
    font-weight: $font-weight-bold;
    padding: 0 16px;
    text-transform: uppercase;
    background-color: $os-link;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    transition: color 500ms ease, background 200ms ease;
    will-change: color, background;
    position: relative;
    line-height: 40px;
    min-height: 40px;
    pointer-events: auto;
    border: 1px solid transparent;
    pointer-events: auto;

    &.btn-outline-gray {
      border-color: #787878;
    }
    &.btn-link-gray {
      color: #8c8c8c;
    }
  }

  .col-features{
    .upgrade-plan-header-top {
      @include flex_align_items(flex-start);
      padding-left: 0;
      padding-top: 116px;
    }
    .upgrade-block-feature-text {
      font-size: 20px;
      font-weight: $font-weight-light;
      letter-spacing: .3px;
    }
    .col-row {
      padding: 0;
      font-size: 12px;
      font-weight: $font-weight-normal;
      text-align: left;
      @include flex_justify(flex-start);
    }
  }

  .plan-include-col {
    @include flex(flex);
    @include flex_align_items(flex-start);
    @include flex_justify(flex-start);
    @include flex_direction(column);
    margin-top: 48px;
  }
  .plan-include-head {
    min-width: 20%;
    flex-basis: 20%;
    font-size: 20px;
    font-weight: $font-weight-light;
    margin-bottom: 0;
    letter-spacing: .3px;
    line-height: 1;
  }
  .plan-include-content {
    font-size: 12px;
    font-weight: $font-weight-light;
    margin-top: 24px;
    letter-spacing: .3px;
    [class*="col-"] {
      margin-bottom: 16px;
    }
    .plan-include-text {
      display: inline-block;
      max-width: calc(100% - 32px);
      padding-left: 8px;
      vertical-align: top;
      line-height: 1.33;
    }
    i{
      vertical-align: top;
    }
  }
}

.onboarding {
  padding:0;
  .upgrade-plan-section {
    background-color: transparent;
    box-shadow: none;
  }

  .upgrade-plan-header-top {
    padding: 16px 16px 0;
  }
  .upgrade-plan-period-text {
    color: #8c8c8c;
    line-height: inherit;
    &.selected {
      color: #fff;
    }
    .text-smallcase {
      margin-left: 8px;
    }
  }
}

.card-view {
  padding: 22px 12px;
  .upgrade-plan-period-row {
    margin-bottom: 15px;
    text-align: center;
  }
  .plan-card {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 300;
    padding-top: 8px;
  }
  .upgrade-block-title {
    min-height: auto;
  }
  .plan-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
  }

  .upgrade-block-price-row {
    padding: 0;
    letter-spacing: 0.8px;
    font-weight: normal;
    font-size: 24px;
  }
  .plan-month {
    top: 12px;
  }
  .col-row {
    &.btn-row  {
      justify-content: flex-end;
      padding: 0;
      margin-top: 16px;
      margin-bottom: 0;
      min-height:auto;
    }
  }

  .prize-icon {
    margin-left: 0;
    margin-right: 5px;
    font-size: 24px;
    position: relative;
    left: 0;
    top: 2px;
  }
  .btn-link {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #48acfd;
    text-decoration: none;
    &:active, &:focus,  {
      text-decoration: none;
      color: #48acfd;
    }
  }
  .plan-detail-table {
    margin: 32px 0 0 0;
    td {
      border-top: solid 1px #646464;
      height: 32px;
    }
    .big {
      white-space: normal;
    }
    + .col-row {
        &.btn-row  {
          margin-top: 32px;
        }
      }

  }

  .upgrade-plan-section {
    padding:0;
  }
  .plan-include-col {
    @include flex_direction(column);
    margin-top: 28px;
  }
  .ifill-os-checkmark-round {
    font-size: 16px;
  }
  .plan-include-content {
    margin-top: 8px;
  }
  .plan-include-head {
    line-height: 1.2;
  }
  .plan-include-text {
    padding-left: 16px;
    max-width: calc(100% - 32px);
  }
  .col-m-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }

}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .upgrade-plan-container {
    .upgrade-plan-header-top {
      min-height: 200px;
      padding: 16px 16px 0;
    }
    .plan-include-col {
      .col-md-4.col-lg-3 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
    .col-with-badge {
      .upgrade-block-title {
        padding-left: 0;
      }
    }
    .osbtn {
      min-width: 115px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .upgrade-plan-container {
    .upgrade-plan-header-top {
      min-height: 200px;
      padding: 16px 16px 0;
    }
  }
}

@media screen and (min-device-width: 1024px) {
  .ipad {
    .upgrade-plan-container .col {
      flex: 33.33%;
    }
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .upgrade-plan-container {
    .upgrade-plan-header-top {
      min-height: 200px;
      padding: 16px 16px 0;
    }
  }
}

.mobile-device, .ipad {
  .upgrade-plan-container {
    .btn-blue {
      min-width: 137px;
    }
    .upgrade-plan-period-row {
      margin-bottom: 15px;
    }
  }
}

.ipad {
  .upgrade-plan-container.onboarding {
    .upgrade-block-title {
      white-space: nowrap;
    }

    .col-1,
    .col-2 {
      .btn-blue {
        font-size: 0;
        &::before {
          content: 'Continue';
          font-size: 12px;
        }
      }
    }
  }
}
