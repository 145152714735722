%comment-card-head{
  @extend %os-card-head;

  .left-comment-col,
  .right-comment-col {
    @extend %col-head;
  }

  .comment-type-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
  }
}

%cm-card-user-info {
  margin-bottom: 8px;
  padding-left: $card-gutter;
  padding-right: $card-gutter;
}


%cm-card-footer {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

%cm-card-body {
  flex-grow: 1;
  padding-left: $card-gutter;
  padding-right: $card-gutter;
}


/*big card and min-width card */
%os-comment-card {
  @extend %os-card-base;
  display: flex;
  flex-direction: column;

  .pulse-card-overlay {
    left: 0;
    right: 0;
    top: 0;
  }

  .comment-card-head{
    @extend %comment-card-head;
  }

  .post-card-menu {
    z-index: 1;
    right: 16px;
  }

  .cm-card-user-info {
    @extend %cm-card-user-info;
  }

  .cm-card-body {
    @extend %cm-card-body;
  }

  .comment-text-helper {
    @include fontstyle(16px, normal, 1.5);
    @include multiline-truncate(6);
    max-height: 170px;
    overflow: hidden;
    word-break: break-word;
    margin-bottom: 0;
  }

  .cm-card-footer {
    @extend %cm-card-footer;
    padding-left: 16px;
    padding-right: 16px;
  }

  .user {
    @extend %user;
  }

  &.with-photos {
    .note-card-detail {
      max-height: 96px;
      @include multiline-truncate(4);
    }
  }

  .a-link {
    z-index: 2;
  }
}

.os-lg-comment {
  @extend %os-lg-card;
  @extend %os-comment-card;
  padding-top: $card-gutter;
  padding-bottom: $card-gutter-16;

  &.comment-304 {
    @extend %os-304;

    .comment-card-head,
    .cm-card-user-info,
    .cm-card-body {
      padding-left: $card-gutter-16;
      padding-right: $card-gutter-16;
    }

    .card-dropdown-menu {
      right: 8px;
    }

    .cm-card-footer {
      @extend %cm-card-footer;
      padding-left: 8px;
      padding-right: 8px;
    }

    .user {
      .user-name {
        max-width: 160px;
      }
    }
  }
}




/*---small doc */
.os-sm-comment,.os-sm-conversation-message {
  @extend %os-sm-card;
  @extend %os-comment-card ;
  padding-top: $card-gutter-16;
  padding-bottom: 8px;

  .comment-card-head {
    margin-bottom: 12px;
    text-transform: unset;
  }

  .comment-card-head,
  .cm-card-body {
    padding-left: $card-gutter-16;
    padding-right: $card-gutter-16;
  }

  .left-comment-col {
    color: $level-9 !important;
    max-width: 276px;
  }

  .time-format {
    max-width : 85px;
  }

  .card-dropdown-menu {
    z-index: 3;
  }

  .cm-card-footer {
    padding-left: 8px;
    padding-right: 8px;
  }

  .comment-text-helper {
    @include fontstyle(12px, normal, 1.33);
    @include multiline-truncate(3);
  }

  .a-link {
    font-size: 12px;
  }

  &.comment-240 {
    @extend %os-240;

    .cm-card-footer {
      display: none;
    }

    .comment-text-helper {
      @include multiline-truncate(6);
    }

    .comment-card-head {
      margin-bottom: 4px;
    }

    .left-comment-col {
      max-width: 175px;
    }

    .time-format {
      display: none;
    }

    .a-link {
      font-size: 12px;
    }
  }
}


.extra-small-min-card {
  .os-sm-comment,.os-sm-conversation-message {
    @extend %os-extra-sm-card;
    padding-bottom: 16px;

    .comment-card-head {
      position: absolute;
      left: 12px;
      padding: 0;
      right: 0;
    }
    .card-dropdown-menu {
      top: 30px;
    }

    .cm-user-name {
      display: none;
    }

    .cm-card-body {
      padding-left: 52px;
    }

    .comment-text-helper {
      @include multiline-truncate(4);
      padding-right: 44px;
    }
  }
}


