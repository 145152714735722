.widget-title,
%widget-title {
  font-weight: 600;
  line-height: 16px;
  color: $level-8;
  font-size: 16px;

  .custom-color {
    color: $level-8;
  }

  +.osbtn-icon {
    .btn-icon-helper {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 1920px) {
    .accordion-button {
      @include fontstyle(20px, $font-weight-semibold, 28px);
    }
  }
}

.tip-day-content {
  display: flex !important;
  align-items: center;
  margin: 24px 0 16px;
  padding: 0;

  .tip-svg {
    max-width: 100%;
  }

  .img-block {
    flex: 0 0 120px;
    max-width: 120px;
    padding: 0;
    display: inline-block;
    margin-right: 16px;
  }

  .svg-icon {
    max-width: 120px;
    max-height: 120px;
  }

  .tip-text {
    display: inline-block;
    @extend %body-small-1-5;
  }
}

.recent-discussion-bar {
  .collection {
    max-height: 404px;
    overflow-y: auto;
  }

  pre {
    @include fontstyle(16px, normal, 1.5);
  }

  .cards {
    margin: 0px !important;
  }

  .os-list-card-v2 {
    list-style: none;
  }

  .mw-130 {
    max-width: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .recent-ml-12 {
    margin-left: -12px;
  }

  .recent-mr-12 {
    margin-margin: -12px;
  }

  .tool-mr-12 {
    margin-right: -12px;
  }
}

.no-touch-support {
  .activity-container {
    .recent-discussion-bar {
      .cards {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
}

.ipad {
  &.portrait {
    .recent-discussion-bar {
      height: 325px;
      overflow: hidden;

      .sidebar-widget {
        height: 100%;
      }

      .widget-title+div {
        height: calc(100% - 32px);
      }

      .collection {
        max-height: 100%;
        height: 100%;
        overflow-x: hidden;
      }
    }
  }
}

.mobile-device {

  .recent-discussion-bar,
  .sponsored_space {
    .cards {
      margin: 0 !important;
      padding: 0 !important;

      >.col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .spaces-with-recommendation {

    .col-xl-4,
    .col-lg-4 {
      .pulse-sidebar {
        padding-top: 6px;

        .sidebar-container {
          padding: 12px 16px 16px;
        }
      }

      .recom-space-heading {
        padding-bottom: 24px;
        padding-top: 6px;
      }

      .cards {
        .col-12 {
          min-width: 100%;
        }
      }
    }
  }
}

.spaces-with-recommendation {
  padding-top: 16px;

  .head-sticky:not(.fixed) {
    background-color: transparent;
  }

  .container-mx-3 {
    .space-index-8 {
      margin-top: 72px;
    }
  }

  @media(min-width:1024px) {
    padding-top: 26px;
  }

  @media(width:1024px) and (orientation: portrait) {
    padding-top: 16px;
  }

  .recom-space-heading {
    padding-bottom: 30px;
    font-size: 18px;
  }

  .on-demand-content-container {
    .see-more-btn {
      top: 0;
    }
  }

  .head-sticky {
    .container.px-tab-0 {
      padding-left: 0;
      padding-right: 0;
    }

    .object-view-switcher {
      right: 0;
    }

    .space-tab-fixed {
      .object-view-switcher {
        right: 12px;

        @media(max-width:1024px) and (orientation: portrait) {
          right: 16px;
        }
      }

      @media(max-width:1024px) and (orientation: portrait) {
        .object-header-bar {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
  }
}

.desktop-small {
  .space-tab-fixed {
    @media(min-width:992px) {
      /*flex: 0 0 66.66667%;
      max-width: 66.66667%;*/
    }
  }
}

.no-touch-support,
.ipad.landscape {
  @media(max-width: 1200px) {
    .spaces-with-recommendation {
      .recom-space-heading {
        font-size: 16px;
      }
    }
  }

  .spaces-with-recommendation {
    .container-mx-3 {
      margin-top: -72px;
    }
  }
}

@media(min-width: 768px) {
  .fixed {
    .header-heading-text {
      line-height: 1.8;
      display: inline-block;
    }
  }
}

.spaces-recommended,
.spaces-list {
  padding-top: 16px;
}

.spaces-list {
  .recommended_spaces_listing {
    padding-top: 26px;
  }

  .see-all-parent {
    display: block;
    text-align: center;
  }

  @media(min-width:1900px) {
    .col-load-shadow {
      min-width: 33.33%;
    }
  }
}

.ipad {
  @media(max-width: 1024px) {

    .spaces-list,
    .spaces-with-recommendation,
    .spaces-recommended {
      .object-header-bar .header-dropdown-menu {
        position: relative;

        .header-dropdown-lis {
          left: 0;
        }
      }
    }
  }

  &.portrait {
    .recommended_spaces_widget {
      .sidebar-container {
        padding: 24px 16px 8px;
      }
    }

    .spaces-with-recommendation {
      .recom-space-heading {
        padding-bottom: 16px;
      }
    }

    .spaces-list,
    .spaces-with-recommendation,
    .spaces-recommended {
      .cards {
        .col-lg-4 {
          min-width: 50%
        }

        .os-card-v2 {
          &.small-card {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.mobile-device {
  .recommended_spaces_listing {
    margin-top: 1.5rem;

    .spc-tab-heading {
      padding-left: 0;
    }
  }

  .spaces-list,
  .spaces-with-recommendation,
  .spaces-recommended {
    .cards>[class^="col-"] {
      margin-bottom: 8px !important;
    }
  }

  .spaces-list,
  .spaces-recommended {
    .board-header-mobile {
      .object-view-switcher {
        margin-right: 0;
      }

      .tab-nav-container {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .spaces-with-recommendation {
    padding-left: 12px;
    padding-right: 12px;
  }

  .recommended_spaces_widget {
    .sidebar-container {
      background: none;
      padding: 40px 15px 0;
      margin-bottom: 16px;
    }
  }

  .osbtn.btn-cmn-all-spaces {
    display: block;
    line-height: 56px;
    border-radius: 8px;
  }
}


@media (min-width: 1441px) {
  .object-header-bar {
    &:after {
      left: 12px;
      right: 12px;
    }
  }
}


.sidebar-widget {
  .slick-dots {
    top: auto;
    bottom: -10px;
    position: absolute;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);

    li {
      margin: 0;
      display: inline-block;
      cursor: pointer;
      padding: 0;
      position: relative;

      button {
        width: 16px;
        height: 16px;
        border: transparent;
        padding: 3px;
        display: block;
        outline: none;
        color: transparent;
        font-size: 0;
        line-height: 0;
        background: transparent;
        cursor: pointer;
        border-radius: 50%;

        &:before {
          position: relative;
          top: 0;
          left: 0;
          content: ' ';
          width: 8px;
          height: 8px;
          background-color: $level-6;
          opacity: 1;
          display: block;
          border-radius: 50%;
        }
      }

      &.slick-active {
        button {
          border: 1px solid $level-10;

          &:before {
            background-color: $level-10;
          }
        }
      }
    }
  }
}

.patient-detail-widget {
  .os-card-v2 .os-sm-person {
    transform: none !important;

    .relation {
      font-weight: normal !important;
    }
  }

  .accordion-body {
    min-height: 80px;
  }
}