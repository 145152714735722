.live-session-container {
  min-height: 320px;
  
  @media(min-width: 768px) {
    margin: 0 -16px;
    min-height: 460px;
  }

  .live-session-data {
    flex-wrap: wrap;

    .live-session-title {
      flex: 1;
    }
  }

  .live-seesion-video {
    width: 100%;
    max-width: 100%;
  }
}

.sidebar-container {
  .upcoming-event-widget {
    padding: 4px 0 0 8px;
  }

  .upcoming-event-list {
    margin-top: 24px;
    padding-right:8px;
    max-height: 320px;
    overflow-y: auto;

    .event-list-item {
      &:first-child {
        margin-top: 0;
      }
    }

    .event-time-status {
      margin-left: auto;
      text-align: right;
    }
  }
}


.upcoming-event-list {
  list-style: none;
  margin: 0;
  padding: 0;

  .event-list-item {
    display: flex;
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  .event-avtar-link {
    flex: 0 0 48px;
    min-width: 48px;
  }

  .event-detail-title {
    padding: 0 8px;
  }

  .head-2 {
    font-weight: normal;
    @include ellipsis-lines(16px, 1.5, 3)
  }

  .event-time-status {
    flex: 0 0 58px;
    min-width: 58px;
    font-size: 12px;
    color: $level-9;
    padding-top: 3px;

    &.live {
      color: $os-link;
    }
  }

  .event-status-circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: $cl-link;
    margin-right: 4px;
  }
}


.no-live-session {
  padding-top: 8px;
  text-align: center;
  margin: auto;

  @media(min-width: 768px) {
    max-width: 436px;
  }
}
