%icon-no-touch-hover:hover {
  [class*="icon-os"] {
    color: #48acfd;
  }
}

.engagement-new {
  display: flex;
  align-items: center;

  &:not(.no-space-between) {
    justify-content: space-between;
  }

  &.full-width {
    width: 100%;
  }

  &.h-100 {
    height: 100%;
  }

  &.x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.fw-bold {
    .eng-col {
      font-weight: $font-weight-bold;
    }
  }

  &.lc-margin-none {
    .eng-col:last-child {
      margin-right: 0;
    }
  }

  .eng-col {
    font-size: 12px;
    line-height: 16px;
    position: relative;
    height: 16px;
    min-width: 18px;
    font-weight: $font-weight-normal;

    + .eng-col {
        margin-left: 1.25rem;
      }
  }

  .osbtn {
    + .osbtn {
        margin-left: 0.5rem;
      }
  }

  &.last-child-space {
    .eng-col:last-child,
    .osbtn:last-child {
      margin-right: 1rem;
    }
  }

  .x-l-23 {
    padding-left: 23px;
  }

  .x-l-27 {
    padding-left: 27px;
  }

  .x-l-23,
  .x-l-27 {
    white-space: nowrap;
  }

  .ifill-os-like,
  .ifill-os-share,
  .ifill-os-follow {
    cursor: pointer;
  }

  .case-select-box {
    position: relative;
    margin-left: auto;
    width: 30px;
    height: 30px;

    [class*="icon-os"] {
      font-size: 24px;
      transition: color .5s;
      color: $level-9;
    }

    .ifill-os-checkmark-round-default {
      color: #5c5c66;
    }
    .ifill-os-checkmark-round {
      color: $os-link;
    }
  }
}

.no-touch-support {
  .engagement-new {
    .like-col,
    .comments-col {
      @extend %icon-no-touch-hover;
      cursor: pointer;
    }
  }

  .case-select-box {
    .ifill-os-checkmark-round-default {
      &:hover {
        color: $os-link;
        &::before {
          content: "\E961";
        }
      }
    }
  }
}

.iphone,
.mobile-device {
  .engagement-new:not(.with-col-space) {
    .eng-col {
      margin: 0;
    }
  }
}

@media (max-device-width: 767px) {
  .engagement-new:not(.with-col-space) {
    .eng-col {
      margin: 0;
    }
  }

  .detail {
    .engagement-new {
      display: flex;
    }
  }
}
