.page-banner {
  height: 360px;
  max-width: 100%;
  overflow: hidden;
  position: relative;

  .page-banner-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    text-align: center;
    z-index: 1;

    b {
      font-weight: 900;
    }
  }

  .page-banner-image {
    background-color: rgba(18, 18, 20, 0.4);
    margin: 0px;
    max-width: 100%;
  }

  .banner-text {
    @include fontstyle(48px, 900, 1);
  }

  .banner-small-text {
    @include fontstyle(20px, 600, 1);
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translate(-50%);
  }

  .osbtn {
    min-width: 114px;
    position: absolute;
    bottom: -144px;
    left: 50%;
    transform: translate(-50%);
  }

  .slick-slider {
    height: 100%;

    .slick-list,
    .slick-slide {
      height: 100%;
    }

    .slick-slide > div,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      > div:first-child {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(18, 18, 20, 0);
          z-index: 0;
        }
      }
    }
  }

  .slick-dots {
    bottom: 10px;
    line-height: 1;
    z-index: 1;
    text-align: center !important;

    li {
      margin: 0 6px;
      display: inline-block;
      vertical-align: middle;
      height: unset;
      width: unset;
      padding: 0 !important;

      button {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        cursor: pointer;
        border: solid 2px transparent;
        transition: border 350ms ease;
        display: block;
        margin: 0;
        padding: 0;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          top: calc(50% - 4px);
          left: calc(50% - 4px);
          transition: background 300ms ease;
          opacity: 1;
        }

        &:hover {
          &:before {
            background: $cl-link;
          }
        }
      }


      &.slick-active button {
        border-color: $os-link;
        cursor: default;

        &::before {
          background: $cl-link;
        }
      }
    }
  }

  &.course-banner {
    height: 61.867vh;

    .page-banner-text {
      top: 32%;
      left : 10.83%;
      transform: translateX(0);
    }

    .banner-text {
      @include fontstyle(40px, 900, 56px);
      pointer-events: none;
    }

    .banner-small-text {
      @include fontstyle(20px, 600, 28px);
      left : 0;
      transform: translateX(0);
      bottom: -32px;
      text-align: left;
      pointer-events: none;
    }

    .osbtn {
      left: 0;
      transform: translateX(0);
      bottom: -110px;
    }

    .slick-slider {
      .slick-slide {
        > div:first-child {
          &:after {
            background: linear-gradient(to bottom, #121214, rgba(18, 18, 20, 0));
            opacity: 0.5;
          }
        }
      }
    }
  }

  .video-base-slide {
    height: 100%;
    background: #000;

    .case-video {
      padding: 0;
      height: 100%;
    }
  }

  .container {
    &.admin-banner-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 20px;
      bottom: 20px;
      z-index: 1;
    }
  }

  .admin_base_banner {
    position: absolute;
    z-index: 1;

    &.position_overlay {
      @extend %overlay-span;

      opacity: 0 !important;
      height: 100%;

      div, p, a {
        height: 100%;
        width: 100%;
        display: block;
      }
    }

    @media(min-width: 768px) {
      font-size: 20px;
    }

    @media(min-width: 1200px) {
      font-size: 28px;
    }

    @media(min-width: 1500px) {
      font-size: 40px;
    }

    .os-btn-link,
    a {
      font-size: 1rem;
      line-height: 40px;
    }

  }

  .position {
    &_top,
    &_top_start,
    &_top_end {
       top: 0;
    }

    &_top,
    &_top_start,
    &_left,
    &_bottom_start ,
    &_bottom,
    &_auto {
      left: 0;

      @media(max-width: 767px) {
        left: 12px;
      }
    }

    &_top,
    &_top_end,
    &_right,
    &_bottom,
    &_bottom_end,
    &_auto {
      right: 0;

      @media(max-width: 767px) {
        right: 12px;
      }
    }

    &_top,
    &_bottom ,
    &_auto{
      text-align: center;
    }

    &_bottom,
    &_bottom_start,
    &_bottom_end {
      bottom: 0;
    }

    &_left,
    &_right,
    &_auto {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ul {
    &.tick-list {
      li {
        padding-left: 35px;
        position: relative;

        &:before {
          content: "\e9c1";
          left: 0;
          position: absolute;
          top: 6px;
          font-family: 'fill-os-icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          color: #57b894;
        }

        @media(max-width: 1024px) {
          padding-left: 25px;
        }

        @media(max-width: 767px) {
          padding-left: 20px;

          &:before {
            top: 5px;
          }
        }
      }
    }
  }
}

.touch-support {
  .page-banner-text {
    /* this css is use for video and image slider */
    /*pointer-events: none;*/

    .banner-small-text {
      position: absolute;
      bottom: -64px;
    }
  }

  .course-banner {
    .page-banner-text {
      pointer-events: none;
    }
    .banner-small-text {
      bottom: -32px;
    }
  }
}

@media only screen and (min-width : 992px) and (max-width: 1349px) {
  .page-banner:not(.course-banner) {
    height: 560px;

    .banner-text {
      @include fontstyle(40px, 900, 1);
    }
  }
}

.desktop-small,
.ipad.landscape {
  .page-banner:not(.course-banner) {
    .page-banner-text {
      top: 42%;
    }
  }

  .page-banner {
    &.course-banner {
      height: 360px;
      .slick-slider {
        .slick-slide > div {
          display:flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            max-width: none;
            width: auto !important;
            height: 100%;
          }
        }
      }

      .banner-small-text {
        bottom: -32px;
        white-space: nowrap;
      }
    }
  }
}

.ipad {
  &.portrait {
    .page-banner {
      height: 440px;

      .banner-text {
        @include fontstyle(32px, 900, 1.25);
      }

      .banner-small-text {
        @include fontstyle(20px, 600, 1.6);
        white-space: normal;
        bottom: -70px;
        width: 100%;
      }

      .page-banner-text {
        max-width: 598px;
        top: 33%;
      }
    }
  }

  .page-banner {
    &.course-banner {
      height: 360px;
      .slick-slider {
        .slick-slide > div {
          display:flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            max-width: none;
            width: auto !important;
            height: 100%;
          }
        }
      }

      .banner-small-text {
        bottom: -32px;
        white-space: nowrap;
      }
    }
  }
}

.ipad {
  @media(min-width: 768px) and (max-width: 1023px) {
    .course-banner {
      .slick-slide {
        &[data-index="2"] {
          .banner-container {
            top: 40%;
          }
        }

        &[data-index="1"] {
          .banner-container {
            top: 55%;
          }
        }

        ul {
          font-size: 18px;
        }
      }
    }
  }

  @media(min-width: 1300px) {
    .course-banner {
      .slick-slide {
        &[data-index="1"] {
          .banner-container {
            padding-left: 50px;
          }
        }
      }
    }
  }
}

.mobile-device {
  .page-banner {
    height: 192px;

    .banner-text {
      @include fontstyle(28px, 900, 1.14);
      text-align: left;
    }

    .banner-small-text {
      @include fontstyle(16px, normal, 1.2);
      text-align: left;
      left: 0;
      transform: translate(0);
      bottom: -75px;
    }

    .page-banner-text {
      width: calc(100% - 48px);
      max-width: calc(100% - 48px);
      top: 30%;
      left: 24px;
      padding:0;
      white-space: normal;
      transform: translate(0, 0);

      .osbtn {
        position: absolute;
        bottom: -144px;
        left: 50%;
        transform: translate(-50%);
      }
    }

    .slick-slider {
      height: calc(100% - 40px);
      .slick-list,
      .slick-slide {
        height: 100%;
      }

      .slick-slide > div {
        position: relative;
        max-width: 100%;
      }
    }

    .case-video {
      width: 100vw;
      max-width: 100vw;
    }

    &.course-banner {
      height: 192px;
      margin-bottom: 48px;

      .slick-slider {
        height: 100%;
      }

      .page-banner-text {
        top: 35px;
      }

      .banner-small-text {
        bottom: -48px;
      }

      .osbtn {
        display: none;
      }
    }
  }
}

@media only screen and (min-width : 1920px) and (max-width: 2880px) {
  .page-banner {
    &.course-banner {
      top: 40%;
    }
  }
}

.landing-page {
  .page-banner {
    height: 800px;

    @media only screen and (min-width : 992px) and (max-width: 1349px) {
      height: 560px;
    }
  }
}

.ipad {
  &.portrait {
    .landing-page {
      .page-banner {
        height: 440px;
      }
    }
  }
}

.mobile-device {
  .landing-page {
    .page-banner {
      height: 560px;
    }
  }
}
