$block-shadow: 0 2px 4px 0 RGBA(53, 69, 84, 0.12),
  0 3px 6px 0 RGBA(53, 69, 84, 0.15);

.dropdown-container {
  background: #fff;
  border-radius: 8px;
  position: absolute;
  top: 25px;
  box-shadow: $block-shadow;

  .dropdown-option {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px;

    color: #5d7893;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &:hover {
      background: #edeff3;
    }
  }
}
