.icon-tool-appliance {
  width: 20px;
  height: 18px;
  display: inline-block;
  background: transparent url('/app/images/icon-tool-appliance.png') 0 0 no-repeat;
  background-size: 20px auto;
}

.icon-tool-placeholder-image {
  display: inline-block;
  height: 100%;
  width: 100%;
  min-height: 58px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0 4px 4px 0px;


  &:not(.cover-size) {
    background-size: 100% auto !important;
  }

  &.cover-size {
    background-size: cover;
  }

  &.l-b-radius {
    border-radius: 4px 0 0 4px;
  }

  &.icon-tool-appliance-gray {
    background-image: url('/app/images/icon-tool-appliance-gray-2.jpg');
  }
  &.icon-tool-aligners {
    background-image: url('/app/images/icon-tool-aligners.jpg');
  }
  &.icon-tool-archwires {
    background-image: url('/app/images/icon-tool-archwires.jpg');
  }
  &.icon-tool-auxillary {
    background-image: url('/app/images/icon-tool-auxillary.jpg');
  }
  &.icon-tool-brackets {
    background-image: url('/app/images/icon-tool-brackets.jpg');
  }
  &.icon-tool-elastics {
    background-image: url('/app/images/icon-tool-elastics.jpg');
  }
  &.icon-tool-equipment {
    background-image: url('/app/images/icon-tool-equipment.jpg');
  }
  &.icon-tool-services {
    background-image: url('/app/images/icon-tool-services.jpg');
  }
  &.icon-tool-software {
    background-image: url('/app/images/icon-tool-software.jpg');
  }
}

.tools {
  .video-holder {
    background: transparent;
  }
}

.tool-cards-row {
  margin-top: 36px;

  &.selected {
    margin-top: 0;
  }

  .tools-catds-title-row {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 24px;
  }

  .cards-row-title {
    width: 50%;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: $font-weight-normal;
    span {
      &:not(.style-default){
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .cards-row-btn-block {
    width: 50%;
    margin-bottom: 0;
    text-align: right;
    padding-right: 5px;

    button {
      border: none;
      margin: 0;
      padding: 0;
      color: #368BED;
      font-size: 18px;
      font-weight: $font-weight-bold;
      text-decoration: underline;
      cursor: pointer;
      background: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    margin-top: 20px;

    .tools-catds-title-row {
      .cards-row-btn-block {
        a {
          &.btn {
            height: auto;
            border: none;
            padding: 0;
            position: relative;
            top: -3px;
            font-size: 12px;
          }
        }
      }
    }

    .cards-row-title {
      font-size: 12px;
      font-weight: $font-weight-bold;
    }
  }
}

.tool-details-section {

  .tool-header-top-block {
    width: 100%;
    padding: 10px 0;

    &> .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .tool-details-header {
    padding-top: 8px;
  }

  .tool-header-icon {
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    top: 26px;
    left: 26px;
    z-index: 1;

    @media(max-width: 767px) {
      left: 10px;
      top: 10px;
    }

    i {
      width: 18px;
      height: 15px;
      margin-right: 5px;
      position: relative;
      top: 2px;
      background-size: 18px auto;

      &.icon-tool-placeholder-image {
        width: 250px ;
        height: 250px;
        background-position: center;
        margin-top: 30px;
        background-size: 150% auto !important;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .tool-header-content-block {
    padding: 0 130px;
    text-align: center;
    color: #000;
  }

  .tool-header-content-title {
    width: 100%;
    margin: 0;
    font-size: 32px;
    font-weight: $font-weight-bold;

    .ifill-os-info {
      font-size: 15px;
    }
  }

  .tool-header-content-subtitle {
    margin: 5px 0 0;
    font-size: 20px;
    font-weight: $font-weight-bold;
  }

  .tool-header-actions-row {
    .container-check {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .get-in-touch {
        display: flex;

        &:first-child {
          padding-right: 15px;
          justify-content: flex-end;
        }

        &:last-child {
          padding-left: 15px;
          justify-content: flex-start;
        }

        a {
          color: #368BED;
          font-weight: $font-weight-bold;

          &:hover {
            color: #4eaefa;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .tool-header-slider-block {

    &.tool-no-image {
      height: 360px;
      img{
        max-height: 100%;
        width: auto;
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
      }

      &:after {
        content: '';
        opacity: .2;
        background-image: linear-gradient(to bottom, rgba(18, 18, 20, 0.5), #121214 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .photo-display__main {
      display: none;
    }

    .slick-list {
      .image-selector {
        overflow: hidden;
        border-radius: 8px;
      }

      .image-selector,
      .video-holder {
        position: relative;
        height: 360px;
        display: block !important;
        text-align: center;

        img,
        .image-selector-image {
          width: auto;
          max-width: 100%;
          height: 100%;
          display: inline-block !important;
          position: relative;
          top: 50%;
          transform: translateY(-50%);

          &.selected {
            border: none;
          }
        }
      }
    }

    .signup-colleague-prev,
    .signup-colleague-next {
      width: 24px;
      height: 24px;
      font-size: 18px;
      z-index: 9;

      &.disabled {
        opacity: .4;
      }
    }

    .signup-colleague-prev {
      left: 0;
    }

    .signup-colleague-next {
      right: 0;
    }

    @media only screen and (min-device-width : 768px){
      .threesixty-spin{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .tool-header-content-section {
    padding-top: 16px;

    .detail-header-title-block {
      h1 {
        width: 100%;
        font-size: 24px;
        font-weight: $font-weight-bold;
      }
    }

    .detail-header-button-block {
      .detail-btn-row {
        top: 0;
        text-align: right;
        justify-content: flex-end;
        display: flex;
        align-items: center;

        .btn {
          margin-left: 5px;
          padding: 8px 24px;

          &:first-child {
            margin-left: 0;
          }

          &.btn-small-text {
            height: 34px;
          }
        }

        .engagement__share {
          margin-right: 16px;
          i {
            font-size: 18px;
          }
        }
      }
    }

    .header-dropdown-list {
      z-index: 100;
    }
  }


  .tool-details-user-block {
    .user {
      display: flex;
      align-items: center;
    }

    .tool-details-user-image {
      width: 40px;

      .avatar {
        &.avatar-medium {
          font-size: 1px;
          text-align: center;
          @include avatar(40px);
          vertical-align: middle;
        }
      }
    }

    .user__info {
      padding-left: 10px;

      .tool-details-company-title-block {
        .get-in-touch {
          margin-left: 10px;
          display: inline-block;
          position: relative;
          top: -3px;

          .btn {
            &.btn-white {
              padding: 2px 26px 0 26px;

              &:hover {
                color: #fff;
                background: #000;
              }
            }
          }
        }
      }

      a {
        color: #fff;
        font-size: 16px !important;
        line-height: 18px;
      }

      .company-email-block {
        color: rgba(255, 255, 255, .6);
        font-size: 13px;
        line-height: 14px;
      }
    }
  }

  .tool-details-tab-content-section {
    padding-bottom: 50px;
  }

  .tool-details-tabs-row {
    .object-header-bar {
      white-space: nowrap;
      overflow: hidden;
      overflow-x: auto;

      .list-inline-item {
        font-size: 13px;
        font-weight: $font-weight-bolder;
        text-transform: uppercase;
      }
    }
  }

  .tool-content-section {
    width: 100%;
    margin-top: 20px;
    display: flex;
  }

  .tool-content-block {
    width: 53%;
    border-left: solid 1px #787878;
    padding: 20px 40px 30px 60px;

    &:first-child {
      width: 47%;
      border-left: none;
      padding-left: 0;
    }

    .tool-content-inner-row {
      width: 100%;
      margin-top: 35px;
      display: flex;
      justify-content: space-between;

      &:first-child {
        margin-top: 0;
      }

      .tool-content-label-block {
        width: 31%;
        color: #949494;
        font-size: 16px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }

      .tool-content-description-block {
        width: 60%;
        font-size: 18px;
      }
    }
  }

  .head-sticky {
    &.fixed {
      + .tool-details-tab-content-section {
        padding-top: 34px;
      }
      + .tool-data-info-conianter {
        .tool-description-block.information-tab {
          margin-top: 24px;
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .tool-details-header {
      min-height: 1px;
      border-top: 0;

      &> .container {
        min-height: 1px;
        padding: 0;

        &> .tool-header-actions {
          display: none;
        }
      }
    }

    .tool-header-slider-section {
      &> .container {
        min-height: 1px;
        padding: 0;
      }
    }

    .tool-header-content-block {
      min-height: 46px;
      padding: 5px 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background: #000;
      align-items: center;

      .tool-details-back-link {
        width: 9px;
        height: 16px;
        position: absolute;
        top: 21px;
        left: 15px;

        i {
          width: 7px;
          height: 13px;
          background-size: 7px auto;
        }
      }

      .tool-header-content-title {
        width: 210px;
        color: $level-11;
        font-size: 16px;
        font-weight: $font-weight-light;
        line-height: 18px;
        margin: auto;
      }

      .tool-header-content-title,
      .tool-header-content-subtitle {
        text-align: center;
        padding: 0 5px;
        font-weight: normal;
      }

      .tool-header-content-subtitle {
        color: $level-9;
        margin-top: 5px;
        font-size: 14px;
      }

      .user-link-image {
        height: 33px;

        .avatar-medium {
          @include avatar(33px);
        }
      }
    }

    .tool-header-slider-block {
      &.tool-no-image {
        height: 150px;
      }

      .slick-list {
        .slick-slide {
          padding: 0 !important;
        }

        .image-selector,
        .video-holder {
          height: 150px;

          .image-selector-image {
            max-width: 280px;
            max-height: 150px;
          }
        }
      }
    }

    .tool-details-action-row {
      width: 100%;
      height: 42px;
      display: block;

      .counts-block,
      .views-count {
        color: #fff;
        font-size: 10px;
      }

      .tool-details-comments-block {
        color: #787878;
        font-size: 12px;
      }

      .engagement__views__holder,
      .engagement__like__holder {
        margin-right: 0;
        display: inline-block;
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {

    .tool-header-content-title {
      font-size: 24px;
      line-height: 28px;
    }

    .tool-header-content-subtitle {
      font-size: 16px;
    }

    .detail-header {
      .detail-header-title-block {
        h1 {
          font-size: 20px;
          line-height: 26px;
        }
      }

      .detail-header-button-block {
        padding-top: 0;

        .detail-btn-row {
          right: 15px;
        }
      }
    }

    .tool-details-header {
      &> .container {
        min-height: 200px;
      }
    }

    .tool-content-block {
      width: 50%;
      padding: 20px 20px 30px 30px;

      &:first-child {
        width: 50%;
      }

      .tool-content-inner-row {
        margin-top: 20px;

        .tool-content-label-block {
          width: 45%;
          font-size: 13px;
        }

        .tool-content-description-block {
          width: 50%;
          font-size: 14px;
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .tool-details-views-block {
      .engagement {
        .engagement-notes {
          .ifill-os-note {
            width: 15px;
          }
          &.hidden-mobile {
            display: none;
          }
        }
      }
    }
  }
}

.ipad {
  &.portrait {
    .head-sticky {
      &.fixed {
        + .tool-data-info-conianter {
          padding-top: 34px;
        }
      }
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.mt-50 {
  margin-top: 50px;
}

.tool-header-name {
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  color: $level-11;
  margin: 0;

  @media(min-width: 768px) {
    padding-right: 1rem;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.14;
  }
}

.mobile-device {
  .container.tool-container-full-width-mobile {
    width: 100%;
    max-width: 100%;
  }

  .detail-tool {
    .photo-display {
      .threesixty-spin {
        top: -101px !important;
        left: -55px !important;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .detail-tool {
      .photo-display {
        .threesixty-spin {
          left: -75px !important;
        }
      }
    }
  }
}

@media(min-width: 768px) {
  .tool-data-info-conianter {
    padding-bottom: 80px;
  }
}

@media(max-width: 767px) {
  .tool-secondary-heading {
    flex-wrap: wrap;

    .text-nowrap {
      width: 100%;
    }
  }

  .tool-header-name {
    width: 100%;
  }
}
