%plan-form-control {
  @include font-text($font-weight-normal, 16px, 1.2)
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $block-shadow;
  background-color: $block;
  padding: 8px 16px;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  filter: none;
  outline: 0;

  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

  &:active,
  &:focus,
  &:focus,
  &.StripeElement--focus  {
    border:1px solid  $cl-link;
    box-shadow: none;
    outline: none;
    caret-color: $caret;
    background-color: $block;
  }
}


.upgrade {
  &.modal-open {
    #root {
      filter: blur(15px);
    }
  }
}

.plan-payment-modal,
.plan-limit-reach-modal {
  min-width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  min-height: 100%;

  .modal-content {
    padding: 24px;
    width: 100%;
    border: 0;
  }

  .modal-header {
    padding: 0 0 20px;
    position: relative;
  }

  .mobile-back-icon {
    display: none;
    position: absolute;
    left: 0;
    top: calc(50% - 8px);
    font-size: 1rem;
    line-height: 1;
  }

  .modal-body {
    padding: 0;
  }

  .ifill-os-close {
    font-size: 1rem;
    outline: 0;
    border: 0;
    padding: 0;
    background: none;
  }
}

.plan-payment-modal {
  .form-error {
    color: $lt-red;
  }

  .form-control,
  .StripeElement {
    @extend %plan-form-control;

    &.error-field {
      border: 1px solid $lt-red;
    }
  }

  .form-control {
    font-size: 1em;
    line-height: 24px;
  }

  .StripeElement {
    .__PrivateStripeElement {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 767px) {
    .form-control,
    .StripeElement {
      height: 48px;
      font-size: 16px;
    }

    .ElementsApp {
      input {
        font-size: 16px;
      }
    }
  }
}

.payment-form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.payment-col {
  &.card-number-col {
    @include flex-max-width(48.276%);
  }

  &.small-col {
    @include flex-max-width(22.4138%);
  }

  &.card-holder-col {
    @include flex-max-width(74.138%);
    .form-control {
      color: $level-11;

      @include placeholder {
        color: $level-6;
      }
    }
  }
}

.form-label-block {
  padding-bottom: 16px;
  position: relative;
  margin: 0;
  display: block;

  .form-error {
    display: block;
    font-size: 12px;
    line-height: 1.33;
    padding-top: 2px;
  }

  .card-icon-elem {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #646464;
    cursor: pointer;
    right: 16px;
  }
}

.small-label-text {
  font-size: 12px;
  letter-spacing: .39px;
  line-height: 16px;
  padding-bottom: 8px;
  font-weight: $font-weight-bold;
}

.plan-payment-footer {
  padding-top: 20px;
  font-weight: $font-weight-bold;

  .modal-blue-btn {
    margin-left: 1.5rem;
  }
}

@media (min-width: 768px) {
  .plan-payment-modal,
  .plan-limit-reach-modal {
    .modal-content {
      width: 512px;
      border-radius: 10px;
    }
  }

  .plan-payment-modal{
    .modal-content {
      margin: auto;
    }
  }

}

@media (max-width: 767px) and (orientation: portrait) {
  .plan-payment-modal {
    .modal-content {
      padding: 32px 23px 0 16px;
    }

    .payment-col {
      &.card-number-col {
        @include flex-max-width(66.66%);
      }

      &.small-col {
        @include flex-max-width(28.571429%);
      }

      &.card-holder-col {
        @include flex-max-width(100%);
      }

      &.cvc-col,
      &.postal-col {
        @include flex-max-width(47.6190%);
      }

      &.cvc-col {
        order: 3;
      }

      &.postal-col {
        order: 4;
      }
    }
  }

  .small-label-text {
    white-space: nowrap;
  }
}

.iphone,
.mobile-device {
  .plan-payment-modal {
    .modal-content {
      margin: 0 auto;
      width: 100%;
    }

    .modal-title {
      font-size: 1rem;
    }

    .modal-header {
      display: block;
      text-align: center;
      padding: 0;
      margin-bottom: 45px;
    }

    .ifill-os-close {
      display: none;
    }

    .mobile-back-icon {
      display: block;
    }
  }

  .plan-patment-footer {
    padding-top: 32px;
  }
}


.plan-limit-reach-modal {
  .modal-content {
    margin: auto;
    max-width: 95%;
  }
  .ifill-os-close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .heading {
    padding: 24px 0 44px;
  }

  .big {
    font-size: 1.5rem;
    font-weight: $font-weight-light;
    line-height: normal;
    padding-bottom: 8px;
  }
}

.InputContainer {
  .InputElement {
    caret-color: $caret;
  }
}
