$track-color: #303036 !default;
$thumb-color: #4c4c54 !default;
$thumb-radius: 4px !default;

.slider-threesixty {
  &.horizontal {
    height: 36px;
    position: relative;
  }

  &.vertical {
    right: 0;
    top: 48px;
    height: calc(100% - 96px);
    width: 40px;
    position: absolute;
    left: auto;
  }

  .rangeslider-horizontal,
  .rangeslider-vertical {
    position: absolute;
    margin: 0;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background: $track-color;
    height: unset;
    width: unset;
    box-shadow: none;
    cursor: pointer;
    border-radius: 10px;
  }

  .rangeslider__handle {
    background: none;
    box-shadow: none;
    border: 0 !important;
    outline: 0 !important;
    border-radius: 0 !important;

    &:after {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      background: $thumb-color;
      border-radius: $thumb-radius;
    }
  }

  .rangeslider-vertical {
    max-width: unset;
    width: 4px;
    top: 0px;
    @include x-center;

    .rangeslider__handle {
      width: 36px;
      height: 53px;
      @include x-center;

      &:before {
        width: 4px;
        @include x-center;
        height: 100%;
      }
    }
  }

  .rangeslider-horizontal {
    height: 4px;
    left: 0;
    right: 30px;
    @include y-center;

    .rangeslider__handle {
      height: 36px;
      width: 53px;

      &:before {
        width: 100%;
        height: 4px;
        @include y-center;
      }
    }
  }

  .rangeslider__fill {
    opacity: 0;
  }

  .rangeslider__labels,
  .rangeslider__handle-label,
  .rangeslider__handle-tooltip {
    display: none;
  }
}

.case-video-holder {
  .video-scrubber {
    position: static;
    margin: 0;

    .video-fullscreen {
      bottom: 10px;
      right: 10px;
      top: auto;
      left: auto;
    }
  }
}

.mobile-device {
  .slider-threesixty {
    .rangeslider-horizontal {
      right: 8px;
    }
  }
}


.full-screen {
  .slider-threesixty {
    &.vertical {
      right: -16px;
    }
  }
}


