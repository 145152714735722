%os-sm-post {
  height: $card-height-sm;
  max-height: $card-height-sm;
}

%post-card-head{
  @extend %os-card-head;

  .left-post-col, .right-post-col {
    @extend %col-head;
  }

  .post-type-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
  }
}

%ps-card-user-info {
  margin-bottom: 8px;
  padding-left: $card-gutter;
  padding-right: $card-gutter;
}

%ps-card-footer {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

%ps-card-body {
  flex-grow: 1;
  padding-left: $card-gutter;
  padding-right: $card-gutter;

  .a-link {
    z-index: 2;
  }
}


/*big card and min-width card */
%os-post-card {
  @extend %os-card-base;
  display: flex;
  flex-direction: column;

  .pulse-card-overlay {
    left: 0;
    right: 0;
    top: 0;
  }

  .post-card-head{
    @extend %post-card-head;
  }

  .card-dropdown-menu {
    z-index: 3;
  }

  .ps-card-user-info {
    @extend %ps-card-user-info;
  }

  .ps-card-body {
    @extend %ps-card-body;
  }

  .post-text-helper {
    @include fontstyle(16px, normal, 1.45);
    max-height: 100px;
  }

  .pre-tag-data {
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    cursor: default;
    @include multiline-truncate(8);
    overflow: hidden;
    word-break: break-word;
  }

  .ps-card-footer {
    @extend %ps-card-footer;
    padding-left: 16px;
    padding-right: 16px;
  }

  .user {
    @extend %user;
  }

  .overlay-span {
    @extend %overlay-span;
  }
}

.post-image {
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  height: 120px;
  overflow: hidden;
  @include flex_center();

  .img-parent {
    @extend %img-parent;
  }

  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}


.os-lg-post {
  @extend %os-lg-card;
  @extend %os-post-card;
  padding-top: $card-gutter;
  padding-bottom: $card-gutter-16;

  &.with-photos {
    .pre-tag-data {
      @include multiline-truncate(5);
    }

    .user {
      margin-top: 65px;

      .avatar {
        margin-top: -8px;
      }
    }

    &.only-photos {
      .post-image {
        height: 208px;

        img {
          height: 208px;
        }
      }

      .ps-card-body {
        display: none;
      }

      .ps-card-user-info {
        flex-grow: 1;
      }

      .user {
        margin-top: 156px;
      }
    }
  }

  &.post-304 {
    @extend %os-304;

    .post-card-head,
    .ps-card-user-info,
    .ps-card-body {
      padding-left: $card-gutter-16;
      padding-right: $card-gutter-16;
    }

    .card-dropdown-menu {
      right: 8px;
    }

    .user {
      .user-name {
        max-width: 160px;
      }
    }

    .ps-card-footer {
      @extend %ps-card-footer;
      padding-left: 8px;
      padding-right: 8px;
    }

    &.with-photos {
      .user {
        margin-top: 65px;

        .avatar {
          margin-top: -8px;
        }
      }

      &.only-photos {
        .user {
          margin-top: 156px;
        }
      }
    }
  }
}

@media screen and (min-width: 1441px) {
  .os-lg-post {
    .pre-tag-data {
      @include multiline-truncate(8);
    }
  }
}

.share-modal-more {
  position: relative;
  &:hover {
    text-decoration: underline;
  }
}

.only-photos {
  .ps-card-body {
    display: none;
  }
}

/*---small doc */
.os-sm-post {
  @extend %os-sm-card;
  @extend %os-post-card ;
  padding-top: $card-gutter-16;
  padding-bottom: 8px;

  .post-card-head {
    margin-bottom: 12px;
    text-transform: unset;
  }

  .post-card-head, .ps-card-body{
    padding-left: $card-gutter-16;
    padding-right: $card-gutter-16;
  }

  .pre-tag-data {
    @include fontstyle(12px, normal, 1.33);
    @include multiline-truncate(5);
  }

  .left-post-col {
    color: $level-9 !important;
    max-width: 272px
  }

  .card-dropdown-menu {
    right: 8px;
    top: -8px;
  }

  .ps-card-footer {
    padding-left: 8px;
    padding-right: 8px;
  }

  .time-format {
    max-width : 110px;
  }

  &.with-photos {
    .post-image {
      height: 104px;
    }

    .ps-card-footer {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }


    &.with-photos.only-photos {
      .image-icon {
        top: 75%;
      }
    }

  }

  .a-link {
    @include fontstyle(12px, $link-weight, 1.33);
  }

  &.post-240 {
    @extend %os-240;

    .ps-card-footer, .timestamp {
      display: none;
    }

    .post-text-helper {
      @include multiline-truncate(6);
    }

    .post-card-head {
      margin-bottom: 4px;
    }

    .left-post-col {
      max-width: 175px
    }

    .time-format {
      display: none;
    }
  }
}


.extra-small-min-card {
  .os-sm-post {
    @extend %os-extra-sm-card;
    padding-bottom: 16px;

    .post-card-head {
      position: absolute;
      left: 12px;
      padding: 0;
      right: 8px;
    }

    .card-dropdown-menu {
      top: -4px;
    }

    .cm-user-name {
      display: none;
    }

    .ps-card-body {
      padding-left: 52px;
      padding-right: 40px;
    }

    .pre-tag-data {
      @include multiline-truncate(4);
      color: #d4d4d9 !important;
    }
  }
}


