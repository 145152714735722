.space-v2-card {
  display: block;
  @extend %os-card-base;

  .mr-20 {
    margin-right: 20px;

    .ifill-os-fill-course {
      vertical-align: middle;
    }
  }

  .badge {
    position: absolute;
    bottom: 8px;
    right: 10px;
    font-size: 12px;
    line-height: 16px;
    padding: 2px 8px;
  }

  .activity-footer-block {
    margin-top: 0;
    width: 100% !important;
    margin-left: -8px !important;
    margin-right: 0 !important;
  }

  .card-v2-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    position: relative;
    height: 65%;
  }

  .course-price-label {
    border-radius: 8px 0 0 8px;
    background: #4d4df4;
    font-size: 20px;
    line-height: 42px;
    text-align: center;
    padding: 0 12px ;
    font-weight: 600;
    position: absolute;
    right: -16px;
    font-weight: bold;
    top: -8px;
    color: $level-11;

    span {
      vertical-align: middle;
    }

    .sm-price {
      color: $level-11;
      font-size: 12px;
      font-weight: 300;
      margin-left: 4px;
      text-decoration: line-through;
      vertical-align: baseline;
    }
  }

  .card-dropdown-menu {
    right: -8px;
  }

  .caption,
  .space-media-row {
    position: relative;
    display: flex;
  }

  .caption {
    align-items: center;
    margin-top: 4px;
  }

  .space-id {
    text-transform: uppercase;
    line-height: 1.4;
    font-size: 12px;
    color: $level-2;
  }

  .card-v2-hero {
    margin: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.no-hero-image {
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: #5c5c66;
        opacity: 0.5;
      }

      .img-parent {
        display: flex;
      }

      .ifill-os-image {
        margin: auto;
        color: #fff;
        opacity: .5;
      }
    }
  }

  .space-mask {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -1px;
    top: -1px;
    border-radius: 4px;
    background-image: linear-gradient(to bottom, #121214, rgba(18, 18, 20, 0));
    opacity: 0.6;
  }

  .img-parent {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    height: 100%;
    width: 100%;
  }

  .card-hero-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .hero-image-overlay {
    @extend %overlay-span;
  }

  .card-v2-footer {
    padding: 8px;
    display: flex;
    justify-content: space-between;
  }

  .space-user-info {
    display: flex;
    align-items: flex-start;
    position: relative;

  }

  .space-detail {
    overflow: hidden;
  }

  .space-user {
    color: $level-9;
  }

  .space-user {
    font-size: 12px;
    line-height: normal;

    i[data-hover="true"] {
      pointer-events: none;
    }

    i {
      color: #6c6c6f;
      &:before {
        color: inherit;
      }
    }
  }

  .caption {
    i[data-hover="true"] {
      pointer-events: none;
    }
  }

  .aside-user {
    position: relative;
    margin-right: 8px;
  }

  .star-circle {
    height: 22px;
    width: 22px;
    position: absolute;
    bottom: -6px;
    right: -6px;
    text-align: center;
    background: $lt-orange;
    border-radius: 100%;

    i {
      font-weight: 600;
      line-height: 22px;
    }
  }
}

.course-credit-block {
  color: $level-11;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  line-height: 16px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 12px;

  i {
    vertical-align: middle;
    display: inline-block;
  }
}

.progress-span {
  margin-right: 20px;
}

.card-credit-count {
  @include fontstyle(28px, 900, 1.14);
}

.card-credit-year {
  @include fontstyle(12px, normal, 1);
  color: $level-6;
  text-transform: uppercase;
  margin-top: 4px;
}

.s-collection-col {
  min-width: 64px;
}

.space-image-collection {
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 4px;
  background: #121214;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 6px;

  + .space-detail {
    position: relative;
    top: 6px;
  }

  .collection-img-parent {
    width: 50%;
    height: 50%;
    display: inline-flex;
    align-items: center;
    margin: auto;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .ifill-os-image {
    margin: auto;
  }

  .space-mask {
    bottom: 0px;
    top: 0;
  }
}

.ce-credit-card {
  .space-user-info {
    padding-top: 18px;
  }

  .card-v2-hero {
    text-align: center;
  }

  svg {
    max-width: 218px;
    height: 160px;
  }

  &.os-sm-space {
    display: flex;

    .space-user-info {
      padding-top: 0;
      padding-right: 12px;
    }

    .card-v2-credit-img {
      width: 50%;
      position: relative;

      svg {
        width: 164px;
        height: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

/*small card*/
.os-sm-space {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: 160px;

  .card-v2-header {
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .hero-image-overlay {
    opacity: 0.4;
  }

  .space-user-info {
    .space-user {
      i {
        margin-right: 4px;
      }
    }
  }

  &.collection-space {
    .h-caption-icon {
      display: none;
    }
  }

  .space-card-footer {
    min-height: 56px;
    &:empty {
      min-height: 0;
    }
  }

  &.course-space {
    .selectable-item {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .space-detail {
      text-align: left;
    }

    .card-hero-image {
      height: calc(100% + 28px);
      width: 100%;
      max-height: calc(100% + 28px);
      margin-top: -28px;
    }
  }

  &.space-without-action {
    .card-v2-header {
      height: 55%;
    }

    .space-user-info  {
      padding: 16px 16px 0 ;
    }

    .space-card-footer {
      display: none;
    }
  }

  &.space-240 {
    @extend %os-240;

    .card-v2-header {
      padding-bottom: 20px;
    }

    .course-price-label {
      font-size: 14px;
      line-height: 26px;
    }

    &.open-space {
      .space-id, course-valid-date {
        font-size: 10px;
      }

      .space-name {
        font-size: 12px;

        .ellipsis-text{
          text-overflow: ellipsis;
        }
      }
    }

    &.course-space {
      .mr-20 {
        margin-right: 0;
      }

      .space-user-info {
        padding: 12px 12px;
      }

      .space-user {
        @include text-truncate();
      }

      .card-hero-image {
        height: calc(100% + 20px);
        max-height: calc(100% + 20px);
        margin-top: -20px;
      }
    }
  }

  &.course-space {
    .card-v2-header {
      height: 55%;
      padding: 16px;

      .caption {
        margin-top: 0;
      }
    }

    .space-user-info {
      padding: 8px 16px;

      .extra-small-card-space-name {
        font-weight: normal;

      }
    }

    .space-user {
      margin-top: 2px;
      display: flex;
      white-space: nowrap;

      span:not(.c-user-name) {
        overflow: hidden;
        text-overflow: ellipsis;
        i {
          vertical-align: middle;
          display: inline-block;
        }
      }

      .badge {
        line-height: 16px;
        padding: 0 8px;
        position: unset;
        font-weight: 600;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.card-v2-footer {
  .course-price-label {
    display: none;
    padding-left: 8px;
    padding-right: 0;
  }
}

/*large card*/
.os-lg-space {
  display: block;
  @extend %os-card-base;
  height: $card-height;

  .card-v2-header {
    height: 50%;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .space-id {
    margin-left: 4px;
  }

  .space-media-holder {
    width: 72px;
    height: 72px;
    position: relative;

    .space-media-parent {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
    }

    &:not(.with-border-only) {
      .space-media-parent {
        box-shadow: 0 2px 6px 0 #121214;
        background-blend-mode: multiply;
        background-image: linear-gradient(to bottom, #29292e, #29292e);
      }
    }

    &.with-border-only {
      border: 1px dashed $level-9;
      font-size: 28px;
      display: flex;
      border-radius: 4px;
    }

    &:not(.space-placeholder) {
      .space-media {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    + .space-media-holder {
      margin-left: 8px;
    }
  }

  .space-plus-icon {
    margin: auto;
    position: relative;
    width: 25px;
    height: 25px;

    &:after,
    &:before {
      content: '';
      position: absolute;
      background: $level-9;
      width: 100%;
      height: 1px;
      top: 50%;
    }

    &:before {
      width: 100%;
      height: 1px;
      transform: rotate(90deg);
    }

    &:after {
      transform:translateY(-50%);
    }
  }

  .space-media-text {
    margin: auto;
    font-weight: 600;
    position: relative;
  }

  .space-media {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .space-placeholder {
    .space-media {
      max-height: 48px;
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
    width: 100%;
    padding-bottom: 16px;
  }


  .caption {
    margin-top: 12px;
  }

  .card-v2-footer {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: flex-end;

    .course-price-label {
      display: inline-block;
      background-color: transparent;
      box-shadow: none;
      @include fontstyle(20px, bold, 28px);
      position: relative;
      right: 0;
      top: 0;
    }
  }

  .share-mr-auto {
    margin-right: auto;
  }

  .space-lg-y-4 {
    top: 4px;
  }

  .space-user-info {
    padding: 24px 24px 0;
  }

  .space-name {
    @include fontstyle(20px, 600, 1.4);
  }

  .space-user {
    margin-top: 4px;
  }

  .activity-footer-block {
    .left-col {
      margin-left: -8px;
    }

  }

  &.course-space {
    .space-user-info  {
      padding-top: 12px ;
      padding-left: 16px;
      padding-right: 16px;
      align-items: flex-start;
    }

    .ifill-os-fill-course,
    .ifill-os-calendar {
      font-size: 12px;
    }

    .space-user {
      .graduation-icon-span{
        display: inline-flex;
        align-items: center;
      }
    }

    .space-name {
      @include fontstyle(16px, normal, 1.5);

      &.text-truncate {
        @include multiline-truncate(2);
      }
    }

    .activity-footer-block {
      .left-col {
        margin-left: -16px;
      }

      .right-col {
        margin-right: -16px;
      }
    }

    &.space-304 {
      .card-v2-footer {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .badge {
    position: relative;
    right: auto;
    bottom: auto;
    line-height: 16px;
  }

  &:not(.space-304) {
    .card-v2-header {
      padding-left: 16px;
      padding-right: 16px;

      .course-price-label {
        right: -24px;
      }
    }

    .card-v2-footer {
      @extend %card-x-gutter-16;
    }
  }

  &.space-304 {
    .space-user-info {
      padding: 30px 16px 0;
    }

    .space-media-holder + .space-media-holder {
      margin-left: 32px;
    }

    &.course-space {
      .space-user-info {
        padding-top: 16px;
      }

      .space-detail {
        min-height: 88px;
      }
    }

    .activity-footer-block {
      .left-col {
        margin-left: -8px !important;
      }
    }
  }
}

.course-space {
  .space-user {
    .c-user-name {
      margin-right: 20px;
    }

    i {
      font-size: 12px;
      margin-right: 5px;
    }

    .ifill-os-fill-course {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  &.space-304 {
     .card-hero-image {
      max-width: 118%;
      width: 118%;
    }
  }
}


.mobile-device {
  .conversation-card-container {
    .space-v2-card {
      .card-v2-hero,
      .space-user-info {
        z-index: 1;
      }
      .caption {
        z-index: 2;
      }
    }
  }

  .space-240 {
    &.others-space {
      .space-id, .course-valid-date {
        font-size: 10px !important;
      }
    }
  }
}


.icon-space-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  z-index: 1;
}

.list {
  .icon-space-overlay {
    font-size: 16px;
  }
}


.progress-space {
  position: absolute;
  background: transparent;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 4px;

  .progress-bar {
    min-width: 20%;
  }
}


.add-to-message-modal {
  .selection-mode {
    .course-space {
      .space-user,
      .course-credit-block {
        display: none;
        padding-bottom: 0;
      }

      .selectable-item {
        margin-bottom: 0px;
        bottom: 4px;
      }
    }

    .space-without-action {
      .card-v2-header {
        height: 45%;
      }

      .space-user-info {
        padding-top: 8px;
        padding-right: 0 !important;
      }

      .space-user {
        display: none;
      }
    }
  }
}

html {
  &.no-touch-support,
  &:not(.mobile-device), {
    .os-card-v2 {
      .space-v2-card {
        &.os-sm-space {
          .hero-image-overlay {
            opacity: 0.6;
          }
        }
      }

      &:hover {
        .space-v2-card {
          transform: translateY(-2px);

          &.os-sm-space {
            .hero-image-overlay {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
}

.os-sm-space {
  &.cta-icons {
    .card-v2-header {
      height: 100%;
    }

    .card-v2-hero {
      height: 50%;
      margin: 0;
    }

    .space-detail {
      max-width: calc(100% - 60px);
    }

    .space-user-info {
      height: 50%;
      align-items: flex-start;
      padding-top: 20px;
      justify-content: space-between;

      .osbtn-icon {
        padding: 0;
      }

      i {
        position: relative;
        left: 0;
        width: 24px;
        height: 24px;
        font-size: 24px;
        top: 6px;

        &.ifill-os-add {
          color: #5c5c66;
        }

        &.ifill-os-tick-single {
          margin-right: 8px;
          color: $os-link;
        }
      }
    }
  }
}

.extra-small-min-card {
  .os-sm-space {
    @extend %os-extra-sm-card;
    padding: 8px;
    display: flex;
    align-items: center;

    .badge {
      display: none;
    }

    .caption {
      right: 0;
      position: absolute;
      top: -8px;
      z-index: 1;

      .space-id {
        display: none;
      }
    }



    .card-v2-header {
      height: 100%;
      width: 100%;
      padding: 0 0 0 140px;
      display: flex;
      flex-direction: row;
      justify-content: unset;
      align-items: center;
    }

    .card-v2-footer {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);

      .osbtn {
        width: 40px;
        height: 40px;
        overflow: hidden;
      }
    }

    .card-content  {
      max-width: calc(100% - 128px);
    }

    &.space-without-action,
    &.course-with-price,
    &.course-with-valid-date {
      .card-v2-header {
        height: 72px;
        width: 128px;
        display: inline-flex;
        padding: 0;
        position: static;

        .card-dropdown-menu {
          right: 8px;
        }

        .caption {
          top: 50%;
          margin-top: 0;
        }
      }

      .card-content  {
        display: inline-flex;
        width: calc(100% - 80px);
        vertical-align: top;
        height: 100%;
        padding-left: 12px;
        padding-right: 48px;
      }

      .card-v2-hero {
        left: 8px;
        top: 12px;
        max-width: 128px;
        min-width: 128px;
      }
    }

    .card-v2-hero {
      position: absolute;
      max-width: 128px;
      min-width: 128px;
      margin: 0;
      height: 72px;
      left: 0;
      top: 0;
    }

    .space-user-info {
      padding: 0;
      width: 100%;
      align-items: flex-start;
      align-items: center;
      justify-content: space-between;
    }

    .space-name {
      @include text-truncate();
      max-width: 100%;
      display: inline-block;
      vertical-align: middle;
      line-height: 16px;
      padding-right: 0;
      margin-right: 8px;
      text-align: left;
    }

    .space-action-tag {
      background-color: $lt-orange;
      color: $level-1;
      font-size: 8px;
      line-height: 16px;
      padding: 0px 8px;
      border-radius: 10px;
      min-width: 75px;
      text-align: center;
      vertical-align: middle;
      display: inline-block;

      + i {
        display: inline-block;
      }
    }


    .img-parent,
    .hero-image-overlay {
      border-radius: 8px;
    }

    &:not(.with-action-tag) {
      .space-name {
        @include multiline-truncate(2);
        line-height: 20px;
        font-size: 16px;
        max-width: 100%;
      }
    }

    &.with-action-tag {
      .card-dropdown-menu {
        top: 44px;
        right: 0;
      }
    }

    .space-user {
      color: $level-11;
      padding-top: 4px;

      > span {
        margin: 0 !important;

        &.c-user-name {
          margin-right: 20px !important;

          + .mr-20 {
              margin-left: 0px !important;
          }
        }

        + span {
          margin-left: 12px !important;
        }
      }
    }

    &.course-space {
      .space-user {
        .c-user-name {
          margin-right: 20px !important;

          + span {
            margin-left: 0 !important;
          }
        }
      }
    }

    &.course-with-price {
      .space-name {
        padding-right: 0;
        position: relative;

        .badge {
          font-size: 8px;
          margin-left: 6px;
          line-height: 1px;
          padding: 8px 5px 0;
          height: 16px;
          right: 0;
          top: 0;
        }
      }
    }

    .space-id {
      display: none;
    }
  }

  &.multi-selection-mode {
    .os-sm-space {
      padding-right: 60px;
    }
  }
}

.recommended_spaces_listing {
  .space-v2-card {
    &.os-sm-space {
      .space-id {
        color: $level-2;
      }
    }
  }
}

.multi-selection-mode {
  .os-sm-space {
    .selectable-item {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 4px;
    }
  }
}

.extra-small-min-card {
  .content-space {
    &.my-default-space {
      .card-content {
        padding: 4px 12px;
      }
    }
  }

  .sub-space {
    &.space-without-action {
      .card-content  {
        width: 100%;

        .space-detail {
          @include multiline-truncate(0);
          display: block;
        }
      }
    }
  }
}
