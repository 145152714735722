.carenotpresent {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .col {
    max-width: 504px;

    @media(max-width: 767px) {
      max-width:100;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  svg {
    @media(min-width: 768px) {
      width: 300px;
      height: 300px;
    }
  }

  .info-text-area {
    width: 440px;
    max-width: 100%;
    margin: auto;
    padding-top: 24px;
  }

  p{
    @include fontstyle(20px, normal, 28px);
    color: $level-9;
  }
}
