.comment-error {
  position: relative;
  color: $level-11;
  font-size: 12px;
  padding-left: 20px;

  .ifill-os-error {
    position: absolute;
    font-size: 16px;
    top: calc(50% - 8px);
    left: 0;
    color: $lt-red;
  }

  .error-icon {
    color: $lt-red;
  }

  &:empty {
    display: none;
  }
}

.with-error {
  color: $level-11;

  .form-error {
    color: inherit;

    i {
      color: $lt-red;
    }
  }
}

.parse-comment-text {
  h1 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.33;
  }

  p {
    margin: 2px 0;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: normal;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
  }

  h2 {
    text-transform: uppercase;
  }


  p {
    margin: 2px 0;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: normal;
  }

  a {
    text-decoration: underline;
    color: #4a749a;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
  }

  strong {
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: normal;
    font-style: normal;
  }

  ol {
    margin: 2px 0;
    padding: 0 0 0 16px;
  }
}

.detail-comments-header {
  position: relative;
  
  .editor-container{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(53,69,84,.12), 0 3px 6px 0 rgba(53,69,84,.15);
    padding: 24px 48px 24px 32px; 
  }

  .btn-container{
    width: 100% !important;
    padding: 16px;
    box-shadow: $block-shadow;
    background-color: $block;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    align-items: center;
    margin-bottom: 10px;

    .active{
      background-color: #314656 !important;
      color: #fff !important;

      .btn-icon-helper{
        color: #fff !important;
      }
    }
    
    .osbtn-icon{
      border: none;
      width: 33%;
      padding: 0 16px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      
      &:hover{
        color: #fff !important;
        background-color: #314656;
        .btn-icon-helper{
          color: #fff !important;
        }
      }

      .btn-icon-helper{
        position: unset !important;
        margin-top: 16px;
        &:hover{
          color: #fff !important;
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    .btn-container{
      flex-direction: column;
      justify-content: stretch;
      align-items: flex-start;
      gap: 8px;

      .osbtn-icon{
        width: 100%;
        justify-content: flex-start;
        margin: 0;
      }
    }
  }

  .hide-editor{
    display: none;
  }

  .tiptap-editor{
    padding: 16px;
    // box-shadow: $block-shadow;
    background-color: $block;
    border-radius: 8px;

    .schedule-dropdown-container{
      width: 202px;
      height: 166px;
      border-radius: 8px;
      z-index: 12;
      position: absolute;
      bottom: -167px;
      right: -143px;
      background: #fff;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      isolation: isolate;
      box-shadow: 0px 3px 6px rgba(53, 69, 84, 0.15), 0px 2px 4px rgba(53, 69, 84, 0.12);

      .time-block{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 16px;
        isolation: isolate;
        width: 202px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 8px 8px 0px 0px;
        cursor: pointer;

        &:hover{
          background: #EDEFF3;
        }

        span{
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #5D7893;
          gap: 9px;
        }
      }

      .custom{
        color: #354554;
      }
    }

    .calendar-wrapper{
      z-index: 12;
      position: absolute;
      bottom: -360px;
      right: 0;
      box-shadow: 0 2px 4px 3px rgba(53, 69, 84, 0.12), 0 3px 6px 0px rgba(53, 69, 84, 0.15);
      border-radius: 8px;
  
      .react-calendar{
        border: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .react-calendar__viewContainer{
          border-top: 1px solid #C9D1DA;
          border-bottom: 1px solid #C9D1DA;
        }
      }

      .time-block-wrapper{
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: #fff;
      
      .time-block{
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap:24px;
          
        .time-block-container{
          min-width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap:24px;
  
          i{
            cursor: pointer;
          }
  
          .hour-block,.minute-block,.shift-block{
            min-width: 20px;
            display: flex;
            flex-direction: column;
            gap: 4px;
  
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #354554;
          }
        }
      }
      .time-error{
        text-align: center;
        color: red;
        width: 350px;
      }
    }

    }
  }

  .react-select-ortho-2 {
    @extend %react-select-ortho-2;
  }

  .media-comment-head {
    position: relative;
  }

  .white-background{
    background-color: #fff !important;
    border-radius: 8px !important;
    box-shadow: 0 2px 4px 0 rgba(53,69,84,.12), 0 3px 6px 0 rgba(53,69,84,.15) !important;
    padding: 24px 48px 24px 72px !important; 
  }

  @media screen and (min-width: 1440px) {

    .media-comment-head,
    .media-body,
    .card-attachments {
      // padding-left: 56px;
      // display: flex; 
    }

    .media-actions {
      // padding-left: 56px;
    }

    .comments-primary-avatar {
      margin-left: -56px;
    }
  }

  .comment-title-head {
    padding-bottom: 15px;
  }

  .detail-comments-icon {
    position: absolute;
    top: 10px;
    right: 0px;

    span {
      padding-right: 6px;
    }
  }

  .detail-comments-top-block {
    display: block;

    .media-body {
      margin: 0;
    }
  }

  .mention-textarea-wrap__highlighter,
  .mention-textarea-wrap__input {
    padding-left: 16px !important;
    min-height: 132px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .media-actions {
    margin-top: 16px !important;

    .osbtn-16:not(.osbtn-icon) {
      padding: 0px 16px;
      padding-right: 16px !important;
    }

    .osbtn:first-child {
      /*margin-left: -12px;*/
    }
  }

  .user-link {
    color: $level-11;
    font-size: 1.25rem;
    font-weight: $font-weight-semibold;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  .feed-author-info {
    color: $level-11;
    display: inline-block;
  }

  .comments-primary-avatar {
    position: relative;
    left: 0;
    top: 0;
    margin-right: 16px;
  }

  .reply-buttons {
    display: none;

    .btn-group-left {
      max-width: 48px !important;
      display: inline-block;
    }
  }

  &.space-comments {

    .web-add-comment-text,
    .case-hash-tag-listing {
      display: none;
    }
  }

  &.case-comments,
  &.space-comments {
    .case-hash-tag-listing {
      display: none;
    }
  }
}


.case-hash-tag-listing {
  .comment-error {
    margin-top: -5px;
    display: block;
  }

  .legend {
    @include fontstyle(1rem, 400, 32px);
    white-space: nowrap;
    margin: 0;
  }

  .fieldset {
    display: flex;
    align-items: center;
  }

  .checkbox-comment-list {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    padding-left: .5rem;
  }

  .custom-checkbox {
    position: relative;
    padding: 0 3px;
    @include fontstyle(inherit, 400, 32px);
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    transition: all 500ms ease;
    margin-right: 4px;

    &[data-hover='true']:hover {
      background: rgba(72, 172, 253, 0.2);
    }

    &.active {
      background: $cl-link;
      color: $level-11;
    }

    .custom-control-input {
      left: 0;
      top: 0;
      z-index: 1;
      cursor: inherit;
      height: 100%;
    }

    .ifill-os-checkmark-default-white {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      text-align: center;
    }

    input:checked~.ifill-os-checkmark-default-white {
      &:before {
        content: "\e94f";
      }
    }
  }
}

.detail-comments {
  .media-actions {
    @include flexstyle(flex, flex-start, space-between, row);
    position: relative;

    .btn-group-right{

      .time-pill{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 4px;
        height: 32px;
        background: #EDEFF3;
        border-radius: 16px;
        cursor: pointer;
        margin-right: 16px;

        span{
          height: 16px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #354554;
          mix-blend-mode: normal;
        }
      }

      .scheduler-btn{
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        color:#fff;
        background: #354554;
        cursor: pointer;

        &:hover{
          background:#4A749A;
        }
      }

      .dropdown-active{
        background:#4A749A;
      }      
    }
  }
}

// .right-wrap, .activity-listing{
//   z-index: -1;
// }


.comment-title-head {
  @include flex_space_between();
  position: relative;
  padding-top: 44px;
  padding-bottom: 16px;
  line-height: 1;
}

.hashtag-filter-text {
  @include fontstyle(16px, 600, 1);
}

.discussion-thread-section {
  font-weight: 300;
  width: 100%;

  .comment-title-head {
    flex-wrap: wrap;
    padding-bottom: 20px;
    font-weight: 500;
    width: fit-content;
    margin: 0 0 0 auto;
  }

  .hashtag-filter {
    .hashtag-filter-text {
      @include fontstyle(16px, 600, 1);
    }

    .tag-item {
      margin-left: 16px;
      padding: 0px 16px;
      line-height: 40px;
      margin-right: 24px;
      font-size: 16px;

      .ifill-os-close {
        font-size: 16px;
        top: 2px;
        position: relative;
      }
    }

    .os-btn-link {
      color: $level-9;
      font-size: 12px;
      white-space: nowrap;
      margin-left: 0 !important;
    }
  }

  .comment {
    display: block;
  }

  .comment-more {
    margin-right: -10px;
    margin-top: -7px;
  }

  .comments-section {
    margin-top: 16px;
    margin-bottom: 0px;

    p:empty {
      min-height: 16px !important;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
    }

    h2 {
      font-size: 16px;
      font-style: normal;
      line-height: 1.25;
    }

    strong {
      font-size: 16px;
      font-style: normal;
      line-height: 1.5;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.5;
    }

  }

  .user-name-block {
    padding-right: 60px;
    position: relative;

    .tooltip-copy {
      right: auto;
      top: -10px;
      white-space: nowrap;

      &.hover-tooltip {
        right: auto;
        top: -10px;
        display: none;
      }
    }

    .relation-text {
      display: inline-block;
      color: $level-9;
      @include fontstyle(20px, 600, 28px);
      margin-right: 16px;

      @media screen and (max-width: 767px) {
        @include fontstyle(16px, 600, 28px);
      }
    }
  }

  .comment-section> {
    .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .media-body {
    margin-top: 0 !important;
    margin-bottom: 0px !important;
    // padding-left: 56px;
  }

  .comment {
    margin-bottom: 24px;
    background-color: $block;
    border-radius: 12px;
    border: 0;
    display: block;


    @media screen and (min-width: 1920px) {
      // padding: 32px 48px 32px 48px;
    }

    &.pin-comment,
    &.processed {
      background-color: $level-3;

      .space-v2-card {
        background-color: $level-3;
      }
    }

    .ifill-os-questions {
      margin-right: 5px;
      vertical-align: middle;

      &::before {
        color: $lt-orange;
      }
    }

    &:last-child {
      border-bottom: 0px;
    }

    .user-link {
      margin-right: 8px;
      display: inline-block;
      @include fontstyle(16px, 600, 1);
    }

    .media-comment-time {
      display: block;
      @include fontstyle(12px, normal, 16px);
      cursor: pointer;

      .time-stamp {
        padding-left: 5px;
      }
    }

    .avatar {
      &.comments-secondary-avatar {
        &.avatar-initials {
          top: 0;
        }
      }
    }

    .comment-text {
      line-height: 1.5;
      display: block;
      vertical-align: top;
      padding-right: 40px;
      margin-bottom: 16px;
      margin-top: 16px;

      .link-text {
        color: $os-link;
        font-weight: $link-weight;
      }

      +.comment-attachment-block {
        margin-top: -16px;
      }
    }

    .os-sm-comment,
    .os-sm-conversation-message {
      .comment-text-helper {
        @include fontstyle(12px, normal, 1.33);
        @include multiline-truncate(3);
      }
    }

    .comment-text-helper {
      font-size: 16px;
      display: inline;
      line-height: 1.5;
      vertical-align: middle;

      a:not(.white-link) {
        font-weight: $link-weight;
      }

      @media screen and (min-width: 1920px) {
        font-size: 20px;
      }
    }

    .comment-tags {
      word-break: break-word;

      .link-text {
        display: inline-block;
      }
    }

    .osbtn-32 {
      min-width: 40px;
      min-height: 40px;
      line-height: 40px;
      padding: 0px 16px;

      .ifill-os-send .ifill-os-more {
        font-size: 16px !important;
      }
    }

    .ifill-os-question {
      width: 20px;
      height: 20px;
      font-size: 20px;
      vertical-align: top;
      margin-right: 4px;
    }

    .media-footer {
      width: 100%;

      .like-count {
        position: relative;
        margin-left: auto;

        &.no-text {
          margin-left: -10px !important;
        }
      }

      .osbtn {
        padding: 0px 16px;

        &:first-child {
          margin-left: -16px;
        }

        &:last-child {
          margin-right: -8px;
          margin-left: auto !important;
        }
      }

      .osbtn-icon {
        &:not(.no-text) {
          padding-left: 40px;
        }

        &.px-8:not(.no-text) {
          padding-left: 40px;

          .btn-icon-helper {
            left: 16px;
          }
        }
      }

      .os-comment-text {
        display: inline-block;
        position: relative;
        top: 2px;
        margin-left: 4px;
      }

      .like-count {
        &:hover {
          .like-count-tooltip-wrap {
            display: block;
          }
        }
      }

      .like-count-tooltip-row {
        display: flex;
        align-items: center;
        color: $level-10;
        font-size: 13px;
        text-transform: initial;

        .user-image {
          display: inline-flex;
          align-items: center;
        }
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      .comment-show-link {
        padding-left: 5px;

        .avatar-36.comments-secondary-avatar {
          top: 0px;
          @include avatar(20px);
        }
      }

      .comment-reply-list {
        position: relative;
        z-index: 1;
      }
    }
  }
}


.like-count {
  &:hover {
    .like-count-tooltip-wrap {
      display: block;
    }
  }
}

.like-count-tooltip-wrap {
  width: 200px;
  padding: 0px 0 0px;
  display: none;
  position: absolute;
  bottom: 28px;
  left: 50%;
  z-index: 10;
  line-height: 2;
  text-transform: none;
  transform: translateX(-50%);
  margin-left: -2px;
}

.like-count-tooltip {
  background: $block;
  border-radius: 5px;
  padding: 16px;
  position: relative;
  overflow: unset;
  margin-bottom: 10px;


  &:before {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    position: absolute;
    bottom: -5px;
    left: 50%;
    margin-left: -10px;
    background: $block;
    transform: rotate(45deg);
  }
}

.like-count-tooltip-row {
  display: flex;
  align-items: center;
  color: $level-11;
  font-size: 12px;
  text-align: left;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  .user-image {
    height: 16px;

    .avatar {
      display: inline-block;
      float: left;
    }
  }
}

.posts {
  .like-count-tooltip-row {
    .user-image {
      .avatar {
        display: block;
      }
    }
  }
}

.comment-user-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 16px;
}

.comment-edit-cancel {
  .header-dropdown-menu {
    .header-dropdown-list {
      min-width: 127px;
    }
  }
}

.comment-action-bar {
  display: block;
  text-align: right;
  padding: 24px 0 24px 0px;
}

.comment-hide-link {
  font-size: 12px;
  cursor: pointer;
  color: $level-11;
  text-transform: unset !important;

  &.hide-link {
    color: $level-9;
    margin-right: -24px;
  }

  &.show-prev-link {
    margin-left: -24px;
    color: $level-9;
  }
}

.comment-edit-container {
  .mention-textarea-wrap__control {
    min-height: 80px;
  }

  .reply-buttons {
    display: none;
  }
}

.comment-attachment-block {
  padding-bottom: 5px !important;
}

.comment-reply-list {
  width: 100%;

  &:empty {
    margin-top: 0;
  }

  .comment {
    background: transparent;
    border-radius: 0;
    box-shadow: none;

    .avatar {
      &.comments-secondary-avatar {
        @include avatar(32px);

        &.avatar-initials {
          top: 0;

          .ifill-os-outline-doctor {
            margin-top: 0;
          }
        }
      }
    }
  }

  .prev-record-link {
    color: $level-11;
  }

  .media-body {
    padding-left: 0px;
  }

  .user-info-row {
    min-height: 32px;
    align-items: center;
    margin-bottom: 8px;

    .user-link {
      display: inline-block;
    }

    .avatar-listing {
      .icon-span {
        width: 12px;
        height: 12px;
        line-height: 12px;
        min-width: 12px;
        font-size: 7px;
        bottom: -2px;
        right: -2px;

        i {
          font-size: inherit !important;
        }
      }
    }
  }

  .comment-reply-section {
    position: relative;

    @media screen and (min-width: 768px) {
      .btn-hide {
        margin-right: -16px;
      }
    }
  }

  .comment-reply-container {
    padding-top: 0;
    z-index: 1;

    .record-video-btn {
      display: none;
    }

    .detail-comments-top-block {
      .comment-error {
        position: absolute;
        margin-top: 3px;
      }
    }

    .mention-textarea-wrap__control {
      min-height: 40px !important;
      font-size: 16px;
      line-height: 22px;

      @media screen and (min-width: 1441px) {
        min-height: 48px !important;
      }
    }

    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      min-height: 40px !important;
      padding: 10px 144px 6px 16px;
      border-color: $level-3;

      &::placeholder {
        color: $textarea-placeholder !important;
        @include text-truncate();
        max-width: 100%;
        width: 100%;
        display: inline-block;
      }

      @media screen and (min-width: 1441px) {
        min-height: 48px !important;
        padding: 10px 144px 6px 16px;
      }
    }

    .comment-edit-container {
      padding-left: 0;
      margin-top: 24px;

      .mention-textarea-wrap__control {
        min-height: 80px;
      }
    }

    .media-actions-buttons {
      margin-top: 0px;

      .osbtn {
        &:first-child {
          margin-left: -6px;
        }
      }
    }

    &.br-none {
      border-top: 0;
      padding-top: 0;
    }

    .comments-primary-avatar {
      display: none;

      +.media-body {
        .detail-comments-top-block {
          @media(min-width: 768px) {
            margin-bottom: 0;
          }
        }
      }

      .avatar {
        @include avatar(40px);

        .icon-span {
          right: -5px;
        }

        &.avatar-40,
        &.avatar-32 {
          .icon-span {
            right: -4px;
          }
        }
      }
    }

    .user-link {
      color: $level-11;
      font-weight: 600;
    }

    .media-actions {
      display: none !important;
    }

    .reply-buttons {
      display: flex;
      position: absolute;
      bottom: 2px;
      right: 0;
      align-items: flex-end;
      max-height: 40px;

      .reply-button-left-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .btn-group-left {
        max-height: 40px;

        +.osbtn {
          margin-left: 4px;
        }


        @media screen and (max-width: 1440px) {
          margin-bottom: 0px;
        }
      }

      .message-send-btn {
        min-height: 40px;
        min-width: 40px;
        margin-bottom: 0;
      }
    }
  }

  .reply-ul {
    .comment {
      padding: 0;
      margin-bottom: 0px;

      +.comment {
        padding-top: 26px;
      }

      &:last-child {
        margin-bottom: 0px;
        border-bottom: 0;

        .media-footer {
          padding-bottom: 0px;
        }
      }

      .comment-edit-cancel {
        right: 0;
      }
    }

    .user-link {
      margin-right: 16px;
    }

    .time-with-seenlist {
      .count {
        display: none;
      }
    }

    .tooltip-copy {
      right: 36px;
      top: -36px;
      white-space: nowrap;

      &.hover-tooltip {
        right: 36px;
        top: -36px;
        display: none;
      }
    }

    .media-footer {
      padding-bottom: 16px;
    }

    .comment-reply-container {
      border-bottom: 0;
    }
  }
}

.cases {
  .comment-reply-container {
    .mention-textarea-wrap__control {
      min-height: 48px !important;
      font-size: 20px;
    }

    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      min-height: 48px !important;
      padding: 8px 104px 4px 16px;
    }
  }

}

.discussion-thread-section {
  
  .comment {
    .comment-edit-container {
      margin-top: 16px;

      .media-body {
        padding-right: 0;
        padding-left: 0 !important;
        margin-left: 0 !important;
      }

      .media-actions-buttons {
        .osbtn:first-child {
          margin-left: -12px;
        }
      }
    }
  }
}

.on-demand-content-container {
  position: relative;

  .comment-reply-container {
    padding-top: 40px;
  }

  .prev-record-link {
    margin-left: 12px;
  }

  .latest-record-link {
    padding: 24px 0 8px;
  }

  .comment-reply-section:first-child {
    .visibility-hidden:first-child {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }
}

.no-touch-support {
  .posts {
    .comment-attachment-block {
      .os-sm-video.video-240 .video-name {
        max-width: 90px;
      }
    }

    .reply-ul {
      .comment-attachment-block {
        .os-sm-video.video-240 .video-name {
          max-width: 60px;
        }
      }
    }
  }
}

.private-space-cmt {
  background-color: $level-3;
  padding: 16px 25px;
  font-size: 12px;
  line-height: 1.33;
  font-style: italic;
  border-radius: 8px;

  .mentionee-link {
    color: $os-link;
    font-size: 12px;
  }

  @media screen and (min-width: 1440px) {
    margin-left: 64px;
  }
}


.comment-reply-container {
  .private-space-cmt {
    @media screen and (min-width: 1440px) {
      margin-left: 0;
    }
  }
}

.comments-list {
  .group-by-space-name {
    box-shadow: $block-shadow;
    border-radius: 12px 12px 0 0;

    +.comment {
      border-radius: 0 0 12px 12px;
    }
  }

  .user-info-row+.media-body {
    padding-right: 40px;

    @media screen and (min-width: 1441px) {
      padding-right: 64px;
    }
  }
}


.reply-ul {
  .user-info-row+.media-body {
    padding-right: 0 !important;

    @media screen and (min-width: 1441px) {
      padding-right: 0 !important;
    }
  }
}

.desktop-small,
.ipad.landscape {
  .detail-comments-header {
    padding: 40px 32px 32px;

    .feed-author-info {
      color: $level-11;
      display: inline-block;
      font-weight: 500;
    }

    .media-comment-head {
      position: relative;
      margin-bottom: 16px;

      .avatar-48 {
        @include avatar(32px);

        .icon-span {
          @include icon-span(12px);
        }
      }
    }
  }

  .discussion-thread-section {
    .media-body {
      padding-left: 0;
    }

    .prev-record-link {
      margin-left: 0;
    }

    .comment {
      padding: 24px 32px 32px;

      &:last-child {
        margin-bottom: 0;
      }

      .user-link {
        display: inline-block;
      }

      .comment {
        padding: 24px 0 0;

        .user-link {
          display: inline-block;
        }
      }
    }
  }

  .comments-list {
    .comment {
      .user-link {
        display: inline-block;
      }
    }
  }

  .show-prev {
    padding-bottom: 12px !important;
  }

  .comment-reply-list {
    .comment-reply-container {
      border-bottom: 0;

      .comment-edit-container {
        padding-left: 0;
      }
    }

    .reply-ul {
      .comment {
        padding-bottom: 0;
      }
    }
  }
}

.ipad {
  &.portrait {
    .hgt-100 {
      height: 244px !important;
    }

    .detail-comments-header {
      padding: 40px 32px 32px;

      .feed-author-info {
        color: $level-11;
        display: inline-block;
        font-weight: 500;
      }

      .media-comment-head {
        position: relative;
        margin-bottom: 16px;

        .avatar-48 {
          @include avatar(32px);

          .icon-span {
            @include icon-span(12px);
          }
        }
      }

      @media(max-width: 850px) {
        &:not(.add-post-area) {
          padding-top: 24px;

          .btn-group-left,
          .btn-group-right {
            flex-basis: auto;
            width: auto;
            flex-wrap: nowrap;
          }

          .select-box-wrap {
            margin-right: 0;
            width: 270px;
            font-size: 15px;

            .os__placeholder {
              font-size: 15px;
              letter-spacing: -0.05px;
            }

            +.osbtn {
              margin-left: 0.5rem;
            }
          }

          .share-post-dropdown,
          .select-box-wrap {
            margin-bottom: 0;
          }

          .btn-group-left {
            .osbtn {
              @include backface-visibility;

              +.osbtn {
                margin-left: 16px;
              }
            }
          }
        }

        @media(max-width: 768px) {
          .select-box-wrap {
            width: 265px;
            font-size: 14px;

            .os__placeholder {
              font-size: 14px;
            }
          }
        }
      }
    }

    .discussion-thread-section {
      .media-body {
        padding-left: 0;

        @media screen and (max-width: 992px) {
          padding-right: 0;
        }
      }

      .prev-record-link {
        margin-left: 0;
      }

      .comment {
        padding: 24px 32px 32px;

        &:last-child {
          margin-bottom: 0;
        }

        .user-link {
          display: block;
        }

        .comment {
          padding: 24px 0 0;

          .user-link {
            display: inline-block;
          }
        }
      }
    }

    .comments-list {
      .comment {
        .user-link {
          display: inline-block;
        }
      }
    }

    .show-prev {
      padding-bottom: 12px !important;
    }

    .comment-reply-list {
      .comment-reply-container {
        border-bottom: 0;

        .comment-edit-container {
          padding-left: 0;
        }
      }

      .reply-ul {
        .comment {
          padding-bottom: 32px;
          padding-right: 0;
          border-bottom: 1px solid $block;

          &:last-child {
            padding-bottom: 16px;
            border-bottom: 0;
          }
        }
      }
    }

    .activity-listing-item {
      .comments-list {
        padding: 24px 0 0;

        .comment {
          padding: 0;

          .user-link {
            display: inline-block;
          }
        }
      }
    }
  }
}

.mobile-device {
  .comments-section {
    .create-slider {
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 100% !important;
    }
  }

  .user-name-block {
    .tooltip-copy {
      right: -20px;
      top: -22px;
    }
  }

  .reply-buttons {
    .osbtn {
      min-width: 40px;
      padding: 0px 16px;
    }
  }

  .comment-reply-list {
    .media-actions-buttons {
      .osbtn {
        &:last-child {
          margin-left: 0.5rem;
        }
      }
    }

    .comment-reply-container {

      .mention-textarea-wrap__highlighter,
      .mention-textarea-wrap__input {
        padding: 8px 130px 4px 16px;
      }
    }

    .user-info-row {
      align-items: flex-start !important;
    }
  }

  .discussion-thread-section {
    .hashtag-filter {
      margin-top: 0;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;

      .hashtag-filter-text {
        min-width: 100%;

      }

      .tag-item {
        margin-left: 0 !important;
        margin-bottom: 8px;
        margin-top: 8px;
        margin-right: 8px;

        .ifill-os-close {
          top: 0;
        }
      }
    }
  }

  .detail-comments-header {
    &.space-comments {
      // box-shadow: $block-shadow;
      // background-color: $block;
      // padding: 16px 12px;

      .osbtn:first-child {
        margin-left: 0;
      }

      .record-video-btn {
        margin-right: 0 !important;
      }

      .media-comment-head {
        position: relative;
      }

      .feed-author-info {
        display: block;
      }
    }
  }
}

.comment-attachment-block {
  width: auto;
  min-width: 100%;
  padding: 0;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 16px;


  .comment-message-attachment {
    padding-right: 15px;
    padding-left: 15px;
  }

  .comment-message-attachment-holder {
    margin-bottom: 0;
    margin-top: 16px;
    padding: 5px;
    height: 120px;
  }

  .comment-message-attachment-holder {
    width: 120px;
    padding: 8px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    background: #2a2a2a;
    border-radius: 4px;
    cursor: pointer;

    .ifill-os-fill-download {
      width: 23px;
      height: 23px;
      padding-top: 4px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      font-size: 16px;
      text-align: center;
      line-height: 12px;
      background: rgba(0, 0, 0, 0.4);
      border-top-right-radius: 4px;
      border-bottom-left-radius: 4px;
      cursor: pointer;
      color: #fff;

      .ifill-os-fill-download {
        top: 0;
        margin-left: 0;
      }
    }

    .ifill-os-close {
      position: absolute;
      top: -6px;
      right: -15px;
      z-index: 1;
      transition: background 500ms ease;
      width: 30px;
      height: 30px;
      position: absolute;
      font-size: 16px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      border-radius: 30px;
    }

    .uploaded-file {
      width: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      top: 50%;
      text-align: center;
      background: transparent;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      img {
        max-width: 104px;
        max-height: 104px;
      }
    }

    &.attached-file {
      width: 200px;
      padding: 15px 15px 5px;

      .comment-attachment-file-content {
        width: 100%;
        height: 120px;
      }

      .comment-attachment-icon-holder {
        width: 100%;
        height: 55%;
        position: relative;
      }

      .comment-attachment-ext-name {
        width: auto;
        max-width: 100px;
        height: 19px;
        border: solid 2px #DDD;
        padding: 0 7px 0 3px;
        overflow: hidden;
        position: absolute;
        top: 22px;
        left: 7px;
        color: #DDD;
        font-size: 13px;
        font-weight: $font-weight-bold;
        line-height: 16px;
        text-align: left;
        background: #424242;
        border-radius: 4px;
      }

      .uploaded-file {
        width: 100%;
        height: 45%;
        top: 0;
        color: #DDD !important;
        font-size: 11px;
        font-weight: $font-weight-bold;
        line-height: 13px;
        text-align: left;
        white-space: normal;
        overflow-wrap: break-word;
        word-break: break-all;
        word-wrap: break-word;
        word-break: break-word;
        transform: translateY(0);
      }
    }
  }
}


// Comment Transition
.comment-fade-enter {
  opacity: 0.01;
}

.comment-fade-enter.comment-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.comment-fade-exit {
  opacity: 1;
}

.comment-fade-exit.comment-fade-exit-active {
  opacity: 0.01;
  transition: opacity 100ms ease-out;
}

// Replies Transition
.replies-fade-enter {
  opacity: 0.01;
}

.replies-fade-enter.replies-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.replies-fade-exit {
  opacity: 1;
}

.replies-fade-exit.replies-fade-exit-active {
  opacity: 0.01;
  transition: opacity 200ms ease-out;
}


.report-comment-message {
  @include fontstyle(12px, normal, 1.33);
  font-style: italic;
  background: $level-2;
  padding: 16px;
  border-radius: 4px;
  margin: 24px 0 24px;

}

.show-report-comment {
  @include fontstyle(14px, normal, 1.33);
  text-align: center;
  background-color: $level-2;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  font-style: italic;
  margin-bottom: 12px;
}

.rot-icon-90::before {
  transform: rotate(90deg);
}

.rot-icon-180::before {
  transform: rotate(180deg);
}

.no-touch-support {

  .sort-dropdown-toggle:hover,
  .object-view-switcher .sorting-holder:hover {

    .ifill-os-chevron-down,
    .ifill-os-chevron-up {
      color: $os-link;
    }
  }
}

.ipad {
  .pulse-right-sidebar {
    margin-bottom: 40px;
  }

  &.landscape {
    .case-hash-tag-listing {
      .custom-checkbox {
        margin: .5rem 2px .5rem 0;
      }
    }
  }
}

.modal-xl {
  &.modal-global {
    &.comment-likes-modal {
      width: 500px;
      margin-top: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .modal-content {
        padding: 24px;
        border-radius: 4px;
        box-shadow: 0 2px 20px #000;
        min-height: 420px;
      }

      .like-loader {
        margin-top: 55px;
      }

      .modal-header {
        padding: 0 0 16px;
        border-bottom: 0;

        .card-title {
          font-size: 24px;
          display: flex;
          align-items: center;
        }

        .ifill-os-close {
          position: absolute;
          right: 0;
          top: -4px;
        }
      }

      .user-listing {
        max-height: 350px;
        margin: 0 0 15px;
        padding: 0;
        overflow-y: auto;
      }

      .user-listing-row {
        width: 100%;
        margin: 0;
        padding: 12px 0;
        position: relative;
        display: flex;
        flex-direction: row;
        list-style: none;

        .follow-unfollow-block {
          min-width: 135px;
          white-space: nowrap;
          text-align: right;
        }

        .btn {
          &.btn-case-follow {
            font-weight: $font-weight-light;

            &:hover {
              background: transparent;
            }

            i {
              display: none;
            }
          }
        }
      }

      .user-image {
        width: 40px;
        padding-left: 0;
        margin-right: 16px;
      }

      .user-name {
        width: 240px;
        padding: 0 8px 0 0;

        .os-btn-link {
          display: block;
        }

        .degree-list {
          display: block;
          font-size: 12px;
          letter-spacing: .6px;
          opacity: .6;
        }
      }

      .user-action {
        width: 98px;
        padding: 10px 0 15px;

        a {
          color: $level-11;
          font-size: 12px;
          font-weight: 200;
          letter-spacing: .6px;

          &.unfollow {
            opacity: .6;
          }
        }
      }

      @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
        width: 96%;
        margin: 0;
        left: 2%;

        .user-listing {
          max-height: 350px;
          margin: 8px 0 10px;
          padding: 0;
          overflow-y: auto;
        }

        .user-image {
          margin-right: 8px;

          .avatar {
            &.avatar-medium {
              @include avatar(40px);
              font-size: 12px;
            }
          }
        }

        .user-name {
          width: 100px;
          padding-top: 0;
        }

        .user-action {
          width: 80px;

          a {
            font-size: 11px;
          }
        }
      }
    }
  }
}

.break-word {
  word-break: break-word;
  white-space: normal;
}


.mobile-device {
  .private-space-cmt {
    font-weight: normal;
    padding: 8px;
  }

  .cases {
    .add-comment-button {
      margin-top: 0;
    }
  }

  .comment-content-section {
    margin-left: 0px;
    margin-right: 0px;
  }

  .detail-comments-header {
    background: transparent;
    box-shadow: none;
    padding: 0 0px;
    margin-bottom: 24px !important;

    .osbtn {
      &:first-child {
        margin-left: -12px;
      }
    }
  }

  .media-actions-buttons {
    .osbtn {
      &:first-child {
        margin-left: -6px;
      }
    }
  }


  .br-top {
    margin-left: 0px;
  }


  .discussion-thread-section {
    .media-body {
      padding-left: 0;
    }

    .show-prev {
      padding-bottom: 0 !important;
      justify-content: center;

    }

    .prev-record-link {
      margin-left: 0;
      border: 0 !important;

      +.osbtn {
        margin-left: -20px !important;
      }
    }

    .comment-attachment-block {
      justify-content: center;

      .col-6 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .comments-section {
      padding-right: 0;
    }

    .user-info-row {
      margin-bottom: 16px;
    }

    .reply-buttons{
      .btn-group-left{
        .attachment-icon{
          margin-top: 0;
        }
      }
    }

    .comment-more {
      margin-right: -6px;
      margin-top: -7px;

      .osbtn {
        min-height: 40px;
        line-height: 40px;
      }
    }

    .osbtn:not(.web-view-btn) {
      min-height: 40px;
      line-height: 40px;

      &.osbtn-icon {
        min-height: 40px;
        line-height: 48px;
        margin-bottom: 0;
      }
    }

    .media-comment-time {
      padding-left: 0;
    }

    .user-name-block {
      padding-right: 10px;
      line-height: 18px;
      display: flex;
      max-width: calc(100% - 50px);
      flex-direction: column;

      .user-with-relation{
        flex-direction: column;
        align-items: flex-start !important;
      }

      .feed-author-info {
        word-break: break-word;
      }
    }

    .comment {
      box-shadow: none;
      padding: 16px 12px 12px;

      .user-link {
        font-size: 16px;
        line-height: 16px;
      }

      .media-comment-time {
        font-size: 12px;
        line-height: 16px;
      }

      .avatar {
        &.comments-secondary-avatar {
          @include avatar(32px);

          .icon-span {
            @include icon-span(12px);
          }
        }
      }

      .comment-reply-list {
        .comment-reply-section {
          margin-top: 8px;
        }

        // .user-name-block {
        //   flex-direction: row;
        // }

        .avatar {
          &.comments-secondary-avatar {
            @include avatar(24px);

            .icon-span {
              @include icon-span(10px);
            }
          }
        }
      }

      .comment-text-helper,
      .ifill-os-questions,
      pre .a-link {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .comment-reply-section {
      .comment {
        .avatar.comments-secondary-avatar {
          @include avatar(24px);

          .icon-span {
            @include icon-span(10px);
          }
        }
      }
    }

    .media-footer {
      padding: 0;
      margin-top: 10px;

      .osbtn {
        &:first-child {
          margin-left: -14px;
        }

        &:last-child {
          margin-right: -8px;
        }
      }
    }
  }

  .media-actions-buttons {
    .osbtn+.osbtn {
      margin-left: 0;
    }
  }

  .detail-comments {
    .like-count-tooltip-wrap {
      left: 8px;
      transform: none;
    }

    .like-count-tooltip {
      &:before {
        left: 36px;
      }
    }
  }

  .comment-title-head {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 0;
  }

  .comment-reply-list {
    .comment-reply-container {
      position: relative;
      border: 0;

      .reply-buttons{
        margin-top: 8px;
        position: unset;
        justify-content: space-between;

        .reply-button-left-container{
          .btn-group-left, .osbtn{
            border: 1px solid #C9D1DA;
            border-radius: 8px;
          }
        }
        .message-send-btn{
          border: 1px solid #C9D1DA;
          border-radius: 8px;
        }
      }

      .comment-edit-container {
        padding-left: 0;
        margin-top: 0;
      }

      .comments-primary-avatar {
        +.media-body {
          display: block;
          width: 100%;
        }
      }
    }

    .comment-user-info {
      padding-left: 40px;
      margin-bottom: 8px;
    }

    .comment-reply-section {
      margin-top: 0;
    }

    .reply-ul {
      .comment {
        padding: 8px 0 24px 0;
        border-bottom: 1px solid $block;

        &:last-child {
          border-bottom: 0;
        }
      }

      .avatar-listing {
        margin-right: 8px;
      }
    }
  }

  .add-comment-button {
    background-color: #303036;
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    position: relative;
    height: 40px;
    display: flex;
    margin-top: 24px;

    .comment-avatar {
      top: 8px !important;

    }

    .placeholder {
      color: $level-9;
      padding-left: 10px;
    }
  }

  .post-avatar {
    padding-left: 0;
    min-width: 32px;
    top: 8px;

    .avatar-24 {
      @include avatar(24px);

      i {
        font-size: 10px;
      }
    }
  }
}

.comment-attachment-block,
.card-row-pulse,
.write-post-row,
.os-card-listing {
  .os-card-v2 {
    margin-top: 16px;

    >[class*='-240'] {
      max-width: 100%;
    }
  }

  .pulse-entity-card {
    .os-card-v2 {
      >[class*='-240'] {
        max-width: 240px;
      }
    }
  }
}


.comment-text-helper {
  word-break: break-word;
}


.ipad,
.mobile-device {
  .like-count:hover {
    .like-count-tooltip-wrap {
      left: 5px;
      transform: none;
    }

    .like-count-tooltip {
      &:before {
        left: 16px;
        margin-left: 0;
      }
    }
  }
}


.no-touch-support {
  @media (min-width: 992px) and (max-width: 1199px) {
    .case-hash-tag-listing {

      .checkbox-comment-list,
      .legend {
        font-size: 14px;
      }
    }
  }
}

.pinned-post {
  .pinned-post-block {
    display: flex;
    align-items: center;
    position: relative;
    @include fontstyle(12px, normal, 1.33);
    color: $level-11;
    margin-top: -16px;
    margin-left: -64px;

    i {
      color: $level-8;
      margin-right: 8px;
      font-size: 16px;
    }

    +.user-info-row {
      margin-top: 16px;
    }
  }

  .comment-more {
    margin-top: 0 !important;
  }

  .reply-ul {
    .comment-more {
      margin-top: -7px !important;
    }
  }
}

.discussion-thread-section .comment {
  &.pinned-post {
    .pinned-post-block {
      margin-left: 0;
    }

    @media screen and (min-width: 320px) and (max-width: 1439px) {
      padding-top: 40px !important;
    }
  }
}

.media-comment-head {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 56px;
}

.post-avatar {
  position: relative;
  top: 0;
  left: 0;
  min-width: 40px;
  padding-left: 0;
  max-height: 40px;
  margin-left: 8px;

  .avatar-24 {
    @include avatar(40px);
  }
}

.add-post-user-name {
  @include fontstyle(16px, normal, 1.5);
  color: $level-11;
  display: inline-block;
  padding-left: 8px;
}

.pulse-comments {
  padding-right: 32px;
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
  .comment-attachment-block {
    margin-right: -8px;
    margin-left: -8px;
    overflow: unset;

    >[class^="col-"] {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}


.pulse-comments,
.post-thread-section {
  .comment-attachment-block {
    margin-right: -8px;
    margin-left: -8px;
    overflow: unset;

    >[class^="col-"] {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}


@media only screen and (min-width: 1025px) and (max-width: 1439px) {

  .pulse-comments,
  .post-thread-section {
    .comment-attachment-block {
      margin-right: -8px;
      margin-left: -8px;
      overflow: unset;

      >[class^="col-"] {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ipad {
    .post-thread-section {
      .comment-attachment-block {
        margin-right: -4px;
        margin-left: -4px;
        overflow: unset;

        >[class^="col-"] {
          padding-right: 4px;
          padding-left: 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .modal-comment-mobile {
    .feed-author-info {
      display: none;
    }
  }
}


@-moz-document url-prefix() {
  .discussion-thread-section {
    .media-body {
      flex: auto;
    }
  }
}

.no-touch-support {
  .discussion-thread-section {
    .user-name-block {
      .media-comment-time:hover {
        +.tooltip-copy {
          &.hover-tooltip {
            display: block;
          }
        }
      }
    }
  }

  .copy-tooltip:hover {
    .tooltip-copy.hover-tooltip {
      display: block;
    }
  }
}


.detail {
  .detail-comments-header {
    form {
      .fieldset {
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

          .legend,
          .checkbox-comment-list {
            font-size: 14px;
            justify-content: flex-start;
          }
        }

        @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation: landscape) {

          .legend,
          .checkbox-comment-list {
            font-size: 14px;
            justify-content: flex-start;
          }
        }

        @media only screen and (device-width : 1024px) and (orientation: portrait) {
          .checkbox-comment-list {
            justify-content: flex-start;
            padding-left: 0;

            .custom-checkbox {
              margin-left: .5rem;
            }
          }
        }

        @media screen and (min-width: 769px) and (max-width: 896px) and (orientation: landscape) {
          .checkbox-comment-list {
            justify-content: flex-start;
            padding-left: 0;
            flex-wrap: wrap;

            .custom-checkbox {
              margin-left: .5rem;
            }
          }

          .legend,
          .checkbox-comment-list {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.show-prev {
  padding-top: 0px !important;
  padding-bottom: 32px !important;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }
}


.cases {
  .on-demand-content-container {
    .prev-record-link {
      margin-left: 0;
    }
  }

  .comment-content-section {
    padding: 0px 3.61%;
    margin: 0 auto;

    .activity-container {
      width: 100%;
    }

    @media screen and (min-width: 1441px) {
      padding: 0 12px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
      padding: 0 32px;
    }

    @media screen and (max-width: 1024px) and (orientation: portrait) {
      padding: 0 24px;
    }

    @media screen and (max-width: 767px) {
      padding: 0;

      .detail-comments {
        display: flex;
        flex-direction: column;
      }
    }
  }
}


@media screen and (max-width: 767px) {
  html:not(.mobile-device) {
    .detail-comments-header {
      padding: 40px 32px;

      .feed-author-info {
        color: $level-11;
        display: inline-block;
        font-weight: 500;
      }

      .media-comment-head {
        position: relative;
        margin-bottom: 16px;

        .avatar-48 {
          @include avatar(32px);

          .icon-span {
            @include icon-span(12px);
          }
        }
      }
    }
  }
}

.post-comment-footer-action {
  .os-menu-drop {
    .close-single-select {
      pointer-events: none;
    }

    &:hover .close-single-select {
      color: $level-10;
    }
  }
}

.mobile-device {
  .comment-edit-container {
    .post-comment-footer-action {

      .btn-group-left,
      .btn-group-right {
        .osbtn+.osbtn {
          margin-left: 8px;
        }
      }
    }
  }

  .space-comments,
  .modal-comment-mobile {
    .post-comment-footer-action {
      justify-content: space-between !important;
      flex-wrap: wrap;

      .record-video-btn {
        margin-right: -12px;
      }

      .post-comment-footer {
        width: 100%;
        max-width: 100%;
        display: block;
        margin: 0 !important;
        text-align: center !important;
        flex-basis: 100%;
        padding-top: 16px;

        .osbtn {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}


.comment-reply-section {
  .comment {
    .time-with-seenlist {
      display: inline-flex;
    }

    .media-comment-time {
      display: inline-block;
    }
  }
}

.copy-tooltip {
  position: relative;
  line-height: 16px;

  .tooltip-copy {
    cursor: pointer;
    right: auto;
    bottom: 100%;
    top: auto !important;
    white-space: nowrap;

    &.hover-tooltip {
      display: none;
    }
  }
}

@media screen and (min-width: 1441px) {
  .detail-comments-top-block {
    .comments-primary-avatar {
      .avatar.avatar-40 {
        @include avatar(48px);
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .comments-section {

    p:empty {
      min-height: 20px !important;
    }

    h1 {
      font-size: 24px !important;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
    }

    h2 {
      font-size: 20px !important;
      font-style: normal;
      line-height: 1.25;
    }

    p,
    .parse-comment-text {
      font-size: 20px !important;
      font-weight: normal;
      font-style: normal;
      line-height: 1.5;
    }

    strong {
      font-size: 20px !important;
    }

    .parse-comment-text {
      a {
        font-size: 20px !important;
        line-height: 1.5;
      }
    }
  }
}

.mobile-device {
  .tiptap-editor-patient-space-wrapper {
    .reply-button-left-container {
      .btn-group-left {
        border: none !important;
        display: flex;
        gap: 8px;
        flex-direction: column;
        margin-left: -24px;
        .attachment-icon {
          border: 1px solid #C9D1DA !important;
          border-radius: 8px;
        }
        & > span {
          border: 1px solid #C9D1DA !important;
        }
      }
    }
  }
}

.comment-reply-list {
  .tiptap-editor-patient-space-wrapper  {
    .reply-buttons {
      .reply-button-left-container {
        margin-right: 4px;
        height: 40px !important;
        .emoji-picker-btn-container {
          button {
            width: 0 !important;
            visibility: hidden;
          }
        }
      }
    }
  }
}

.hide-editor{
  display: none;
}