.space-pending-table {
  border-radius: 8px;
  background: $block;
  box-shadow: $block-shadow;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 32px;
  margin-top: 24px;
  padding-top: 24px;

  .detail-block {
    padding-left: 40px;
  }

  .space-details-table-row,
  .space-details-table-header {
    display: flex;
    align-items: center;
  }

  .space-details-table-header {
    height: 64px;
    text-transform: uppercase;
    color: $secondary-heading;
    font-weight: 600;
  }

  .space-details-table-row {
    padding: 12px 0;
    min-height: 56px;


    &:nth-child(even) {
      background: $level-2;
    }

    .action-block {
      padding-left: 8px;
    }
  }

  .action-block {
    .osbtn {
      + .osbtn {
        margin-left: 16px;
      }

      &[text="accept"] + .osbtn {
        margin-left: 26px;
      }
    }
  }

  .space-details-table-header {
    .action-block {
      padding-right: 24px;
    }
  }

  .sp-user-name {
    font-size: 16px;
    max-width: calc(100% - 48px);
    width: calc(100% - 48px);

    .os-btn-link {
      color: $level-11;
      display: inline-block;
      text-decoration: none;
      @include text-truncate();
      width: 100%;

      &:hover {
        text-decoration: underline;
        color: $os-link-hover;

        + .degree-text {
          text-decoration: underline;
          color: $os-link-hover;
        }
      }

    }

    .span-helper {
      max-width: 100%;
      display: block;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $os-link-hover;

        .os-btn-link {
          text-decoration: underline;
          color: $os-link-hover;
        }

      }
    }
  }


  .invite-block-group {
    .os-btn-link {
      &:hover {
        text-decoration: none;
      }
    }
    .avatar {
      margin-right: 12px;

      i {
        font-size: inherit;
      }
    }

    .invite-status {
      width: 100%;
      padding-left: 40px;
    }
  }

  .invite-block {
    @include flexwidth(28%)
  }

  .status-block {
    flex: 0 0 15%;
  }

  .date-block {
    flex: 0 0 18.5%;
  }

  .editor-block {
    flex: 0 0 8%;

    .form-group {
      margin-bottom: 0;
    }
  }

  .role-block {
    flex: 0 0 8%;

    .form-group {
      margin-bottom: 0;
    }
  }

  .action-block {
    flex: 0 0 22.5%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .space-pending-table {
    .invite-block {
      min-width: 30%;
    }

    .editor-block {
      min-width: 8%;
      padding-left: 24px;
    }
  }
}

@media(min-width: 1440px) {
  .space-pending-table {
    .invite-block {
      @include flexallwidth(25%)
    }

    .status-block {
      flex: 0 0 12.9503%;
    }

    .date-block {
      flex: 0 0 18.9858%;
    }

    .editor-block {
      flex: 0 0 10%;
      padding-left: 0;
      text-align: center;
    }

    .role-block {
      flex: 0 0 10%;
      padding-left: 0;
      text-align: center;
    }

    .action-block {
      flex: 0 0 23.4042%;
    }
  }
}

/*ipad portrait*/
@media(max-width: 992px) {
  .space-pending-table {
    .detail-block {
      padding-left: 16px;
    }

    .sp-user-name {
      max-width: calc(100% - 40px);
    }

    .invite-block {
      @include flexallwidth(23.77%)
      padding-left: 24px;
    }

    .status-block {
      flex: 0 0 13.77%;
    }

    .date-block {
      flex: 0 0 20.55%;
    }

    .editor-block {
      flex: 0 0 6%;
    }

    .action-block {
      padding-left: 0;
      flex: 0 0 32.88%;

      .osbtn {
        + .osbtn {
          margin-left: 12px;
        }

        &[text="accept"] + .osbtn {
          margin-left: 22px;
        }
      }
    }
  }
}

.ipad.portrait {
  .space-pending-table {
    .detail-block {
      padding-left: 16px;
    }

    .invite-block {
      @include flexallwidth(27.77%)
      padding-left: 16px;
    }

    .status-block {
      flex: 0 0 16.77%;
    }

    .date-block {
      flex: 0 0 23.55%;
    }

    .editor-block {
      @include flexallwidth(10%)
    }

    .action-block {
      padding-left: 0;
      flex: 0 0 25.88%;

      .osbtn + .osbtn {
        margin-left: 12px;

        &[text="ignore"] {
          margin-left: 22px;
        }
      }
    }

    @media screen and (max-width: 820px) {
      .invite-block {
        @include flexallwidth(27.77%)
        padding-left: 24px;
      }

      .action-block {
        flex: 0 0 28.88%;
        .osbtn{
          &[text="Invite New Members"] {
            margin-right: 0px !important;
          }
        }
      }

      .status-block {
        flex: 0 0 12.77%;
      }

      .editor-block {
        flex: 0 0 10%;
      }
      .date-block {
        flex: 0 0 19.55%;
      }
    }
  }
}
/*ipad portrait*/

/* mobile*/
@media(max-width: 767px) {
  .space-pending-table {
    padding-bottom: 14px;

    .sp-user-name {
      font-size: 12px;
      line-height: 1.33;

      .span-helper {
        display: block !important;
      }
    }

    .invite-block-group {
      flex-wrap: wrap;
    }

    .detail-block {
      padding-left: 16px;
      flex: 1;
      max-width: calc(100% - 210px);

      &.editor-block {
        max-width: 70px;
        padding-left: 0;
        text-align: center;
        font-size: 12px;
      }
    }

    .space-details-table-row {
      font-size: 8px;
      min-height: 56px;
    }

    .action-block {
      padding-left: 0;
      flex: 0 0 140px;
      min-width: 140px;
      white-space: nowrap;

      .osbtn {
        min-height: 32px;
        padding: 0px 12px;

        + .osbtn {
          margin-left: 32px;
        }

        .btn-icon-helper {
          font-size: 24px;
        }
      }
    }
  }
}
/* mobile*/

.os-btn-link.mh-40  {
  max-height: 40px;
}
