.group-messenger {
  .mobile-nav-bar {
    display: none;
  }
  .messenger-container {
    height: calc(100vh - 64px);
    margin-bottom: 0;
    min-height: auto;
    overflow: hidden;
    top: 64px;
    position: sticky;
    margin-left: -36px !important;
    margin-top: -16px !important;
    width: calc(100% + 70px) !important;
    padding-top: 16px;
    .messenger {
      height: 100%;
      display: flex;
      position: sticky;
      left: 0;
      right: 0;
      top: 0;
    }
    .chat-window-wrapper {
      height: 100%;
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      .chat-window {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 2px;
        padding: 0 12px;

        .chat-header {
          display: flex;
          border-bottom: 1px solid #c9d1da;
          padding: 0 24px;
          padding-top: 8px;
          width: 100%;
          justify-content: space-between;
          .header-info {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            .folders {
              .image-figure {
                margin-right: 0px;
              }
            }
            .left-header {
              align-items: start;
              display: flex;
              gap: 8px;
              min-height: 48px !important;
              height: fit-content !important;
              .group-back-btn {
                padding: 8px;
                display: none;
              }
              .image-figure {
                width: 64px;
                height: 48px;
              }
              .user-conversation-avatar {
                .avatar {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  min-width: 40px;
                  font-size: 30.07519px;
                  border-width: 2px !important;
                  img {
                    position: unset;
                  }
                }
              }
              .space-hero-image {
                min-width: 48px;
                min-height: 48px;
                width: 100%;
                height: 48px;
                object-fit: cover;
                aspect-ratio: 4/3;
                transition: all 0.3s linear;
                flex-grow: 0;
                border-radius: 4px;
              }
              .space-detail {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                .info {
                  display: flex;
                  gap: 24px;
                  font-size: 12px;
                  line-height: 1.33;
                  color: #5d7893;
                  cursor: pointer;
                  span {
                    letter-spacing: 0.24px;
                    &:hover {
                      color: #354554;
                      font-weight: bold;
                      letter-spacing: 0;
                    }
                  }
                  .pinned-count-text {
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
                .title {
                  font-size: 20px;
                  font-weight: bold;
                  text-transform: capitalize;
                }
              }
            }
            .right-header {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 24px;
              i {
                font-size: 24px;
                color: #7e91a5;
              }
              .actions {
                cursor: pointer;
              }
            }
          }
        }
        .list-messages {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding-bottom: 10px;
          overflow-x: hidden;
          overflow-y: auto;
          padding-left: 24px;
          padding-right: 24px;
          .activity-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #7e91a5;
            padding: 16px 0;
          }
        }

        #new-message-editor {
          position: relative;
          background-color: white;
          padding-top: 16px;
          .conversation-message-box {
            padding: 0 16px;
            position: relative;
            .c-close {
              position: absolute;
              font-size: 12px;
              right: 0;
              margin-right: 24px;
              cursor: pointer;
            }
            .parent-msg-box {
              display: flex;
              flex-direction: column;
              padding-left: 8px;
              border-left: 2px solid #4a749a;
              color: #7e91a5;
              justify-content: flex-start;
              align-items: flex-start;
              .c-author,
              .c-text {
                font-size: 12px;
                line-height: 1.33;
                .parse-comment-text > p {
                  font-size: 14px;
                  a {
                    font-size: 14px;
                  }
                }
              }
              .c-author {
                font-weight: 600;
              }
            }
          }

          span[data-type='mention'] {
            background: #e3e3e3;
            border-radius: 4px;
            padding: 4px;
          }

          .mailinfo,
          .phone-number {
            display: block !important;
          }

          .editor-attachments {
            display: flex;
            flex-wrap: wrap;
            row-gap: 16px;
          }

          .tip-tap-editor-field {
            border: 1px solid grey;
            min-height: 42px !important;
            width: calc(100% - 200px);
            margin-bottom: 65px;
            margin-left: 16px;

            .menu-bar {
              position: absolute;
              bottom: 8px;
              left: 10px;
              zoom: 1.4;

              .emoji-picker-btn-container {
                button {
                  border: 1px solid #eff1f4;
                }
              }
            }
            .ProseMirror {
              p {
                padding-left: 0;
              }
            }
          }

          .drag-overlay {
            position: unset;
          }

          & > button {
            position: absolute;
            bottom: 16px;
            right: 16px;
          }

          .options {
            & > div:first-child {
              margin: 0;
              padding: 0;
              width: fit-content;
              position: absolute;
              bottom: 66px;
              right: 32px;

              .attachment-icon {
                margin-right: 8px;
              }
            }

            .list-unstyled {
              top: -40px;
              left: unset;
              right: 54px;
            }
          }
        }
      }
    }
  }
}

.mobile-device {
  .group-messenger {
    .messenger-container {
      .chat-window {
        .chat-header {
          padding-top: 0px !important;
          .header-info {
            padding: 16px 0px;
            .left-header {
              .group-back-btn {
                display: inline-block !important;
              }
              .space-detail {
                .title {
                  font-size: 16px !important;
                  font-weight: 700 !important;
                }
              }
            }
            .right-header {
              padding: 0 8px;
            }
          }
        }
        .list-messages {
          padding-bottom: 20px;
          height: calc(100% - 224px);
        }
        #new-message-editor {
          position: fixed !important;
          bottom: 0;
          left: 0;
          z-index: 999;
          width: 100%;

          .options {
            & > div:first-child {
              margin: 0;
              padding: 0;
              width: fit-content;
              right: 32px;
              display: flex;
              position: absolute;
              left: 16px;
              bottom: 16px;
            }

            .list-unstyled {
              top: -40px;
              left: 47px;
              right: 54px;
            }
          }

          .tip-tap-editor-field {
            width: calc(100% - 35px);
          }
        }
      }
    }
  }
}
