@font-face {
  font-family: 'fill-os-icon';
  src:  url('/app/styles/fonts/fill-font/os-font.eot?ywms5w');
  src:  url('/app/styles/fonts/fill-font/os-font.eot?ywms5w#iefix') format('embedded-opentype'),
    url('/app/styles/fonts/fill-font/os-font.ttf?ywms5w') format('truetype'),
    url('/app/styles/fonts/fill-font/os-font.woff?ywms5w') format('woff'),
    url('/app/styles/fonts/fill-font/os-font.svg?ywms5w#os-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ifill-"], [class*=" ifill-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fill-os-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ifill-os-partner-doctor:before {
  content: "\e9af";
  color: #7E91A5;
}

.ifill-os-add-task-icon:before {
  content: '\e9ae';
}

.ifill-os-task-icon:before {
  content: "\e9ad";
}
.ifill-os-teams:before {
  content: "\e9ac";
}
.ifill-os-tasks:before {
  content: "\e9a9";
}

.ifill-os-selfie:before {
  content: "\e99d";
}

.ifill-os-label:before {
  content: "\e9a5";
}

.ifill-os-link-copy:before {
  content: "\e9a3";
}

.ifill-os-back:before {
  content: "\e9a2";
}

.ifill-os-addition-link:before {
  content: "\e9a1";
}

.ifill-os-secure:before {
  content: "\e99b";
}
.ifill-os-tiny-grid:before {
  content: "\e9a0";
}

.ifill-os-outline-doctor:before {
  content: "\e99a";
}

.ifill-os-share:before {
  content: "\e910";
}

.ifill-os-question-support:before {
  content: "\e974";
}

.ifill-os-add-emoji:before {
  content: "\e999";
}

.ifill-os-minus:before {
  content: "\e998";
}

.ifill-os-appts-remove:before {
  content: "\e996";
}

.ifill-os-support:before {
  content: "\e994";
}

.ifill-os-phone:before {
  content: "\e992";
}

.ifill-os-qlark-logo:before {
  content: "\e990";
}
.ifill-os-add-new-section:before {
  content: "\e991";
}

.ifill-os-zoom-out:before {
  content: "\e98a";
}
.ifill-os-zoom-in:before {
  content: "\e98b";
}

.ifill-os-mail:before {
  content: "\e901";
}
.ifill-os-chevron-caret-up:before {
  content: "\e906";
}
.ifill-os-reply-left:before {
  content: "\e91a";
}
.ifill-os-upload:before {
  content: "\e907";
}
.ifill-os-plus:before {
  content: "\e908";
}
.ifill-os-audio:before {
  content: "\e909";
}
.ifill-os-mic:before {
  content: "\e90a";
}
.ifill-os-selfie:before {
  content: "\e90c";
}
.ifill-os-no-notification:before {
  content: "\e90d";
}
.ifill-os-appliance:before {
  content: "\e995";
}
.ifill-os-instagram:before {
  content: "\e90f";
}
.ifill-os-adjust:before {
  content: "\e917";
}
.ifill-os-video:before {
  content: "\e91b";
}
.ifill-os-checkmark-dot:before {
  content: "\e925";
}
.ifill-os-report:before {
  content: "\e92c";
}
.ifill-icon_16px_tool:before {
  content: "\e935";
}
.ifill-os-mastercard:before {
  content: "\e938";
}
.ifill-os-account:before {
  content: "\e973";
}
.ifill-os-document-doc:before {
  content: "\e902";
}
.ifill-os-sorting-z-a:before {
  content: "\e900";
}
.ifill-os-chevron-down:before {
  content: "\e903";
}
.ifill-os-chevron-left:before {
  content: "\e904";
}
.ifill-os-chevron-right:before {
  content: "\e905";
}
.ifill-os-chevron-up:before {
  content: "\e90e";
}
.ifill-os-double-tick:before {
  content: "\e90b";
}
.ifill-os-tool:before {
  content: "\e911";
}
.ifill-os-annotation:before {
  content: "\e912";
}
.ifill-os-outline-appts-add:before {
  content: "\e913";
}
.ifill-os-appts-stays:before {
  content: "\e914";
}
.ifill-os-appts-update:before {
  content: "\e915";
}
.ifill-os-archive:before {
  content: "\e916";
}
.ifill-os-calendar-asc:before {
  content: "\e918";
}
.ifill-os-calendar-desc:before {
  content: "\e919";
}
.ifill-os-fill-categories:before {
  content: "\e91c";
}
.ifill-os-checkmark-checked:before {
  content: "\e91d";
}
.ifill-os-checkmark-default-white:before {
  content: "\e91e";
}
.ifill-os-checkmark-radiobutton:before {
  content: "\e91f";
}
.ifill-os-checkmark-round:before {
  content: "\e920";
}
.ifill-os-checkmark-round-default:before {
  content: "\e921";
}
.ifill-os-checkmark-round-grey:before {
  content: "\e920";
  color: #7E91A5
}
.ifill-os-chevron-caret-down:before {
  content: "\e922";
}
.ifill-os-chevron-caret-right:before {
  content: "\e923";
}
.ifill-os-clock:before {
  content: "\e924";
}
.ifill-os-comparisons:before {
  content: "\e926";
}
.ifill-os-connection:before {
  content: "\e927";
}
.ifill-os-fill-course:before {
  content: "\e928";
}
.ifill-os-credit-card-amex:before {
  content: "\e929";
}
.ifill-os-credit-card-visa:before {
  content: "\e92a";
}
.ifill-os-delete:before {
  content: "\e92b";
}
.ifill-os-document-default:before {
  content: "\e92d";
}
.ifill-os-fill-document-empty:before {
  content: "\e92e";
}
.ifill-os-fill-document-mov:before {
  content: "\e92f";
}
.ifill-os-fill-document-pdf:before {
  content: "\e930";
}
.ifill-os-fill-document-xls:before {
  content: "\e931";
}
.ifill-os-fill-document-zip:before {
  content: "\e932";
}
.ifill-os-fill-download:before {
  content: "\e933";
}
.ifill-os-drag:before {
  content: "\e934";
}
.ifill-os-fill-error:before {
  content: "\e936";
}
.ifill-os-everyone:before {
  content: "\e937";
}
.ifill-os-extraoral-face-34-smiling:before {
  content: "\e939";
}
.ifill-os-extraoral-face-profilerelaxed:before {
  content: "\e93a";
}
.ifill-os-extraoral-face-profilesmiling:before {
  content: "\e93b";
}
.ifill-os-extraoral-face-relaxed:before {
  content: "\e93c";
}
.ifill-os-extraoral-face-smiling:before {
  content: "\e93d";
}
.ifill-os-extraoral-lipsrepose:before {
  content: "\e93e";
}
.ifill-os-extraoral-lipssmiling:before {
  content: "\e93f";
}
.ifill-os-extraoral-overjet-left:before {
  content: "\e940";
}
.ifill-os-extraoral-overjet-right:before {
  content: "\e941";
}
.ifill-os-filter:before {
  content: "\e942";
}
.ifill-os-floppy-disc:before {
  content: "\e943";
}
.ifill-os-follower:before {
  content: "\e944";
}
.ifill-os-full-screen:before {
  content: "\e945";
}
.ifill-os-get-started-light-mode:before {	
  content: "\e946";	
}
.ifill-os-get-started:before {
  content: "\e946";
}
.ifill-os-hashtag:before {
  content: "\e947";
}
.ifill-os-hide-password:before {
  content: "\e948";
}
.ifill-os-home:before {
  content: "\e949";
}
.ifill-os-image:before {
  content: "\e94a";
}
.ifill-os-info:before {
  content: "\e94b";
}
.ifill-os-insight-single:before {
  content: "\e94c";
}
.ifill-os-insights-popular:before {
  content: "\e94d";
}
.ifill-os-intraoral-anterior-lower:before {
  content: "\e94e";
}
.ifill-os-intraoral-anterior-occlusal:before {
  content: "\e94f";
}
.ifill-os-intraoral-anterior-open:before {
  content: "\e950";
}
.ifill-os-intraoral-anterior-upper:before {
  content: "\e951";
}
.ifill-os-intraoral-buccal-left:before {
  content: "\e952";
}
.ifill-os-intraoral-buccal-right:before {
  content: "\e953";
}
.ifill-os-intraoral-occlusal-lower:before {
  content: "\e954";
}
.ifill-os-intraoral-occlusal-upper:before {
  content: "\e955";
}
.ifill-os-invite:before {
  content: "\e956";
}
.ifill-os-lecture:before {
  content: "\e957";
}
.ifill-os-link-broken:before {
  content: "\e958";
}
.ifill-os-link-copy:before {
  content: "\e959";
}
.ifill-os-linked:before {
  content: "\e95a";
}
.ifill-os-location:before {
  content: "\e95b";
}
.ifill-os-logout:before {
  content: "\e95c";
}
.ifill-os-loudspeaker:before {
  content: "\e95d";
}
.ifill-os-mentioned:before {
  content: "\e95e";
}
.ifill-os-message:before {
  content: "\e95f";
}
.ifill-os-messages:before {
  content: "\e960";
}
.ifill-os-more:before {
  content: "\e961";
}
.ifill-os-mute:before {
  content: "\e962";
}
.ifill-os-navigate:before {
  content: "\e963";
}
.ifill-os-new-message:before {
  content: "\e964";
}
.ifill-os-note:before {
  content: "\e965";
}
.ifill-os-payment:before {
  content: "\e966";
}
.ifill-os-photo:before {
  content: "\e967";
}
.ifill-os-pin:before {
  content: "\e968";
}
.ifill-os-play:before {
  content: "\e969";
}
.ifill-os-popular:before {
  content: "\e96a";
}
.ifill-os-post:before {
  content: "\e96b";
}
.ifill-os-procedure:before {
  content: "\e96c";
}
.ifill-os-promo:before {
  content: "\e96d";
}
.ifill-os-questions:before {
  content: "\e96e";
}
.ifill-os-recent:before {
  content: "\e96f";
}
.ifill-os-referral:before {
  content: "\e970";
}
.ifill-os-section:before {
  content: "\e971";
}
.ifill-os-service:before {
  content: "\e972";
}
.ifill-os-facebook:before {
  content: "\e975";
}
.ifill-os-linkedin:before {
  content: "\e976";
}
.ifill-os-twitter:before {
  content: "\e977";
}
.ifill-os-sorting-a-z:before {
  content: "\e978";
}
.ifill-os-submissions:before {
  content: "\e979";
}
.ifill-os-target:before {
  content: "\e97a";
}
.ifill-os-text-cursor:before {
  content: "\e97b";
}
.ifill-os-text-format-align-center:before {
  content: "\e97c";
}
.ifill-os-text-format-align-left:before {
  content: "\e97d";
}
.ifill-os-text-format-align-right:before {
  content: "\e97e";
}
.ifill-os-text-format-bold:before {
  content: "\e97f";
}
.ifill-os-text-format-heading-1:before {
  content: "\e980";
}
.ifill-os-text-format-heading-2:before {
  content: "\e981";
}
.ifill-os-text-format-italic:before {
  content: "\e982";
}
.ifill-os-text-format-list-bullet:before {
  content: "\e983";
}
.ifill-os-text-format-list-numbered:before {
  content: "\e984";
}
.ifill-os-text-format-underline:before {
  content: "\e985";
}
.ifill-os-toolbox:before {
  content: "\e986";
}
.ifill-os-unavailable:before {
  content: "\e987";
}
.ifill-os-undo:before {
  content: "\e988";
}
.ifill-os-unlock:before {
  content: "\e989";
}
.ifill-os-user:before {
  content: "\e9fb";
}
.ifill-os-variation:before {
  content: "\e98d";
}
.ifill-os-verified:before {
  content: "\e98e";
}
.ifill-os-outline-video:before {
  content: "\e98f";
}
.ifill-os-case-3-d:before {
  content: "\e997";
}
.ifill-os-clear:before {
  content: "\e99c";
}
.ifill-os-close:before {
  content: "\e9a6";
}
.ifill-os-collapse:before {
  content: "\e99e";
}
.ifill-os-comments:before {
  content: "\e99f";
}
.ifill-os-doctors:before {
  content: "\e9a4";
}
.ifill-os-outline-expand:before {
  content: "\e9a7";
}
.ifill-os-filled-expand:before {
  content: "\e9a8";
}
.ifill-os-fill-folders-bookmark:before {
  content: "\e9aa";
}
.ifill-os-follow:before {
  content: "\e9ab";
}
.ifill-os-link:before {
  content: "\e9b3";
}
.ifill-os-send:before {
  content: "\e9bb";
}
.ifill-os-space-default:before {
  content: "\e9be";
}
.ifill-os-space-event:before {
  content: "\e9bf";
}
.ifill-os-space-private:before {
  content: "\e9c0";
}
.ifill-os-tick-single:before {
  content: "\e9c1";
}
.ifill-os-update:before {
  content: "\e9c5";
}
.ifill-os-uploading:before {
  content: "\e9c6";
}
.ifill-os-views:before {
  content: "\e9c8";
}
.ifill-os-outline-zoom-in:before {
  content: "\e9c9";
}
.ifill-os-outline-zoom-out:before {
  content: "\e9ca";
}
.ifill-os-case-abc:before {
  content: "\e9cb";
}
.ifill-os-case-big:before {
  content: "\e9cc";
}
.ifill-os-case-gallery:before {
  content: "\e9cd";
}
.ifill-os-case-mnd:before {
  content: "\e9ce";
}
.ifill-os-case-records-dicom:before {
  content: "\e9cf";
}
.ifill-os-case-records-hard-tissue:before {
  content: "\e9d0";
}
.ifill-os-case-records-models:before {
  content: "\e9d1";
}
.ifill-os-case-records-models-2:before {
  content: "\e9d2";
}
.ifill-os-case-records-soft-tissue:before {
  content: "\e9d3";
}
.ifill-os-case-records-x-ray:before {
  content: "\e9d4";
}
.ifill-os-case-sbs:before {
  content: "\e9d5";
}

.ifill-os-case-single:before {
  content: "\e993";
}

.ifill-os-case-table:before {
  content: "\e9d8";
}
.ifill-os-filled-accept:before {
  content: "\e9d9";
}
.ifill-os-fill-add:before {
  content: "\e9da";
}
.ifill-os-fill-attachment:before {
  content: "\e9dc";
}
.ifill-os-calendar:before {
  content: "\e9dd";
}
.ifill-os-fill-case-2-d:before {
  content: "\e9de";
}
.ifill-os-company:before {
  content: "\e9e0";
}
.ifill-os-doc:before {
  content: "\e9e1";
}
.ifill-os-edit:before {
  content: "\e9e2";
}
.ifill-os-education:before {
  content: "\e9e3";
}
.ifill-os-hub:before {
  content: "\e9e7";
}
.ifill-os-insights:before {
  content: "\e9e9";
}
.ifill-os-like:before {
  content: "\e9ea";
}
.ifill-os-locked:before {
  content: "\e9eb";
}
.ifill-os-log-out:before {
  content: "\e9ec";
}
.ifill-os-members:before {
  content: "\e9ed";
}
.ifill-os-notification:before {
  content: "\e9f0";
}
.ifill-os-reply:before {
  content: "\e9f2";
}
.ifill-os-search:before {
  content: "\e9f3";
}
.ifill-os-settings:before {
  content: "\e9f5";
}
.ifill-os-software:before {
  content: "\e9f7";
}
.ifill-os-spaces:before {
  content: "\e9f8";
}
.ifill-os-fill-user:before {
  content: "\e9fb";
}
.ifill-os-video-message:before {
  content: "\e9fc";
}
.ifill-os-workspace:before {
  content: "\e9ff";
}
.ifill-os-case-heart:before {
  content: "\ea00";
}
.ifill-os-case-hospital:before {
  content: "\ea01";
}
.ifill-os-case-person:before {
  content: "\ea02";
}
.ifill-os-fill-folder:before {
  content: "\ea04";
}
