.icon-share-medium {
  width: 17px;
  height: 18px;
  display: inline-block;
  background: transparent url('/app/images/icon-share.png') 0 0 no-repeat;
  background-size: 16px auto;
}

.icon-filter-white {
  width: 15px;
  height: 18px;
  display: inline-block;
  background: transparent url('/app/images/mobile-icons/icon-filter-white.png') 0 0 no-repeat;
  background-size: 15px auto;
}

.icon-message-envelope {
  width: 21px;
  height: 15px;
  display: inline-block;
  background: transparent url('/app/images/icon-message.png') 0 0 no-repeat;
  background-size: 21px auto;
}

.icon-connection-grey {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: transparent url('/app/images/icon-connection.png') 0 0 no-repeat;
  background-size: 15px auto;
}

.icon-ok-circle {
  width: 23px;
  height: 23px;
  display: inline-block;
  background: transparent url('/app/images/icon-ok-circle.png') 0 0 no-repeat;
  background-size: 23px auto;
}

.icon-tags {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: transparent url('/app/images/icon-tags-white.png') 0 0 no-repeat;
  background-size: 20px auto;
}

.icon-os-qlark {
  width: 28px;
  height: 28px;
  display: inline-block;
  background: transparent url('/app/images/icon-os-qlark.svg') 0 0 no-repeat;
  background-size: 28px auto;
}


.icon-plus-search {
  width: 25px;
  height: 25px;
  border: solid 1px #ffffff;
  display: inline-block;
  position: relative;
  top: 0px;
  left: -5px;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  margin-left: 10px;
  &:before {
    content: '+';
    font-style: normal;
    ont-size: 8px;
    font-weight: 600;
    line-height: 24px;
  }
}

.icon-remove {
  content: 'X';
  width: 25px;
  height: 25px;
  border: solid 1px #ffffff;
  display: inline-block;
  position: relative;
  top: 1px;
  left: -5px;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  margin-left: 10px;
}
