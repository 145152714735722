.os-toggle-category {
  padding-bottom: 1.5rem;
  color: $level-8;

  .osbtn {
    color: $tab;
  }

  @media(max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  .os-categogry-heading {
    flex: 1;
    max-width: calc(100% - 100px);

    .os-category-block {
      align-items: center;
      display: flex;
      min-height: 16px;
      padding: 0 5px;
      @extend %heading-4-1;
    }

    .os-category-text {
      line-height: 1;

    }

    .total-count {
      line-height: 1;
      margin-left: 4px;
    }

    .ifill-os-more {
      transform: rotate(90deg);
      margin-left: -8px;
      margin-right: 16px;
    }
  }

  .aside-toggle-action {
    position: relative;
    height: 40px;
    .osbtn {
      padding: 0;
      max-width: 40px;
      line-height: 40px;
      margin-left: 4px;
    }

    + .ifill-os-more:not(.vertical-more) {
      display: inline-block;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      text-align: center;
      padding: 12px 12px;
      margin-left: 8px;
    }
  }

  .toggle-click-icon {
    .ifill-os-chevron-right {
      transition: transform .5s ease;
      display: inline-block;
      cursor: pointer;
      left: 12px;
    }
  }
}

.os-toogle-block {
  margin-bottom: 40px;

  .ifill-os-chevron-caret-down {
    transform: rotate(-90deg);
    transform-origin: 0;
  }

  &.active-toggle {
    padding-bottom: 8px;
    margin-bottom: 0;

    .toggle-click-icon{
      .ifill-os-chevron-right {
        &:before {
          content: "\e99d";
        }
      }

      .ifill-os-chevron-caret-down {
        transform: rotate(0) translate(-50%, -50%);
      }
    }
  }
}

.manageable-sections {
  div[aria-roledescription="draggable"]:nth-last-child(2) {
    .os-toogle-block:not(.active-toggle) {
      margin-bottom: 32px;
    }
  }

  div[aria-roledescription="draggable"] {
    .drop-active {
      .section-closed, .empty-section {
        border: 1px solid #4eaefa;
      }
    }
  }
}

.os-categogry-heading{
  &.full-width {
    .os-category-block {
      display: flex;
      position: relative;
      justify-content: flex-start;
    }
  }
}

.os-toggle-category {
  &.full-width {
    padding-bottom: 20px;
  }
}

.no-touch-support {
  .os-toggle-category {
    /*border: 2px solid transparent;*/
  }

  .drop-active {
    .os-toggle-category {
      /*border-color: $os-link;*/
    }
  }
}

html:not(.mobile-device) .ellipsis-title {
  display: block;
  display: -webkit-box;
  height: 47px;
  font-size: 16px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media(max-width: 767px) {
  .os-card-tall,
  .os-video-card {
    height: 120px;
  }

  .os-card-tall .engagement-new,
  .os-card-tall .user-with-date-info,
  .os-video-card .engagement-new,
  .os-video-card .user-with-date-info {
    display: none;
  }

  .os-card-tall .card-title,
  .os-video-card .card-title {
    padding: 10px 16px;
  }

  .os-card-tall .video-title,
  .os-video-card .video-title {
    padding: 8px 16px;
  }

  .edu-file-thumbnail .card-title,
  .os-card-tall .ellipsis-title,
  .os-card-tall .video-title,
  .os-video-card .ellipsis-title,
  .os-video-card .video-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .edu-file-thumbnail .card-title {
    padding: 8px 16px;
    display: block;
  }

  .course-title-text {
    font-size: 16px;
  }

  .course-price-label {
    font-size: 16px;
    line-height: 32px;
  }

  .edu-toogle-block {
    .edu-heading:before {
      left: 8px;
    }

    .mb-4 {
      margin-bottom: 0!important;
    }
  }
}

.small-card-parent-row>[class*=col] {
  max-width: 270px;
  flex-basis: 270px!important;
}

.small-card-parent-row.child-event-none * {
  pointer-events: none;
}

.space-input-field {
  position: relative;
  margin-bottom: 40px;
  .form-control {
    @include fontstyle(20px, 600, 1.4);
    padding-right: 40px;
  }

  .form-error {
    position: absolute;
    bottom: -18px;
  }

  .input-close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.os-category-block {
  .space-input-field {
    width: 100%;
    margin-bottom: 0;

    .form-group {
      margin-bottom: 0;
    }
  }
}

.mobile-device {
  .os-toogle-block {
    .aside-toggle-action {
      height: 32px;

      .osbtn {
        line-height: 32px;
      }
    }
  }

  .os-category-text {
    max-width: 140px;
  }

  .space-input-field {
    padding: 0px 16px;

    .input-close-btn {
      right: 16px;
    }
  }

  .os-category-block {
    .space-input-field {
      padding: 0;
    }
  }

  .spc-add-new-btn {
    margin-left: 16px;
  }
}


.sort-item-drag-active {
  * {
    transform: scaleY(1) !important;
  }
}
