.footer{
  height: 125px;
  width: 100%;
  background-color: $footer-bg;
  padding-bottom: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  ul{
    list-style: none;
    padding: 0;
    margin-top: 16px;
    @media(min-width:767px){
      display: flex;
      flex-direction: row;
      margin-top: 0;
    }
  }
  i{
    color: #fff;
  }
  .container{
    @media(min-width: 768px) {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    @media(max-width: 370px) {
      padding-left: 8px;
      padding-right: 8px; 
    }

    .row{
      margin: 0;
      @media(min-width:767px){
        justify-content: space-between;
      }
      @media(max-width:767px){
        display: block;
      }
      .social{
        display: none;
      }

      > * {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .social{
      display: flex;
      .social-icon{
        &:not(:last-child){
          margin-right: 20px;
        }
        i{
          transform: scale(1.7);
          color: $footer-text;
        }
        .os-btn-link  {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .support {
      .os-btn-link  {
        color: $level-8 !important;
        font-size: 12px;

        &:hover {
          text-decoration: none !important;
          color: $level-8 !important;
        }
      }
    }
    .copyright,
    .support a,
    .support .faq-link{
      font-size: 12px;
      color: $footer-text;
      line-height: 16px;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
    .copyright{
      span{
        margin-left: 10px;
        display: inline-block;
      }
    }
    .support{
      display: flex;
      margin-bottom: 0;
      margin-right: 32px;
     li{
        margin-left: 32px;
     }
    }
  }
  &.mini{
    height: 36px;
    padding-top: 7px;
    padding-bottom: 5px;
    .row{
      align-items: center;

      .social{
        display: flex !important;
        margin-top: 2px;
        margin-bottom: 0;
        .social-icon {
          display: inline-block;

          a{
            line-height: 16px;
            display: block;
          }
        }
        i{
          transform: scale(1.4);
        }
      }
    }
  }
  &.unauth{
    position: static;
    bottom: 0;
    background-color: transparent;
    margin-top: 294px;
    .support{
      display: flex !important;
    }
    .container{
      .row{
        .social{
          display: none !important;
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    height: 90px;
    margin-top: 32px;
    padding-bottom: 20px;

    .container {
      .row {
        align-items: center;
      }
    }

    ul {
      &.social {
        margin-bottom: 10px;
      }

      &.support {
        margin-bottom: 0;
      }
    }
  }

  .row > * {
    width: unset;
  }
}


.ipad {
  .footer {
    z-index: 3;
  }
}

.mobile-device {
  .footer {
    .container {

      ul {
        margin-top: 0;
      }
      .copyright {
        font-size: 8px;
        display: inline-flex;
        padding-top: 4px;

        + .d-flex {
          display: inline-flex !important;
        }
      }

      .support {
        margin-right: 16px;
        line-height: 16px;

        .os-btn-link {
          font-size: 10px;
          line-height: 10px;
        }

        li {
          margin-left: 10px;
        }

        .osbtn {
          min-height: 16px;
          min-width: 16px;
          padding: 0;
          color: $level-11;
        }
      }

      .social {
        margin-top: 0;
        .social-icon:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}
