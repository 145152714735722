.pill_button_container {
  position: fixed;
  left: 40%;
  padding: 0px 12px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 11;
  border: 1px solid #c9d1da;
  box-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24),
    0 1px 3px 0 rgba(53, 69, 84, 0.12);
  background-color: #fff;
  border-radius: 30px;
  cursor: pointer;

  .pill_button_text {
    // color: $level-10;
    color: #4A749A;
  }
}