.empty-state-container {
  padding-top: 42px;
  text-align: center;
  min-height: 430px;

  .svg-icon {
    vertical-align: top;
    max-width: 100%;
  }

  .empty-text-info {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .small-info {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    margin: 24px auto 0;
    max-width: 313px;
  }

}

.modal-edit-board {
  /*.edu-file-thumbnail {
    overflow: unset !important;
    min-height: 120px !important;
    height: 120px;
    max-height: 120px !important;
    margin-bottom: 0;

    .edu-custom-file-span {
      left: 50%;
      top: 50% !important;
      transform: translate(-50%,-50%);
      right: auto !important;

      i {
        margin: 0 !important;
        font-size: 24px !important;
      }

      .conversation-attachement-icon {
        height: 30px !important;
        width: 24px !important;
      }

      .conversation-attachment-ext-name {
        top: 10px !important;
      }
    }

    .image-viewer-wrapper,
    .pg-viewer-wrapper {
      overflow: hidden;
      border-radius: 4px;
    }

    .edu-video-title {
      top: unset !important;
      bottom: 0;
      border-radius: 0 0 4px 4px;

      &:empty {
        display: none;
      }
    }
  }*/

  .invite-member-modal {
    height: 100%;

    &.invite-widget {
      .member-selected,
      .list-group-wrapper {
        height: calc(100vh - 350px);
        max-height: calc(100vh - 350px);
      }
    }
  }
}

.desktop-small {
  .modal-edit-board {
    .invite-member-modal {
      height: 100%;

      &.invite-widget {
        .member-selected,
        .list-group-wrapper {
          height: 525px;
          max-height: 525px;
        }
      }
    }
  }
}

.ipad {
  &.portrait {
    .empty-state-container {
      .empty-text-info {
        font-size: 1.25rem;
        font-weight: 600;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
    }
  }
}

.mobile-device {

  .empty-state-container {
    min-height: 250px;
    padding-top: 39px;

    .svg-icon {
      max-height: 168px;
      max-width: 177px;
    }

    .empty-text-info {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .osbtn {
      line-height: 40px;
      min-height: 40px;
    }
  }
}
