
.insight-tab-header {
  display: inline-flex;
  align-items: center;

  .insight-tab-text {
    @extend %heading-3-semibold;
    color: $level-8;
    padding-top: 8px;
    padding-bottom: 6px;
    cursor: pointer;
    position: relative;
    margin-right: 48px;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      background-color: $level-11;
      content: '';
      transition: .3s ease-in-out;
      opacity: 0;
    }

    &[data-hover="true"]:hover {
      color: $level-11;
    }

    &.active,
    &:only-child {
      color:  $level-11;
      &:after {
        opacity: 1;
      }
    }

    &.active,
    &:only-child {
      cursor: default;
    }
  }

  .osbtn-icon {
    &[text='Get In Touch'] {
      position: absolute;
      right: 8px;
      top: -5px;
    }
  }
}

.new-cs-card {
  .insight-tab-text {
    @extend %heading-3-semibold;
    color: $level-8;
    padding-top: 0px;
    padding-bottom: 6px;
    cursor: pointer;
    position: relative;
    margin-right: 48px;

    @media screen and (max-width: 768px) and (max-width: 1366px) {
      margin-right: 24px;
    }
  }
}

