.course-key-container {
  margin-bottom: 32px;
  color: $level-11;
  padding-bottom: 40px;
  background: #1c1c1f;
}

.course-key-listing {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 0 0;
  margin:0;
}

.course-key-list-item {
  display: inline-flex;
  flex-direction: column;
  padding-right: 20px;

  .aside-icon {
    min-width: 24px;
    font-size: 16px;
    color: $level-11;
    line-height: 1;
    margin-bottom: 4px;
  }

  .header-list-icon {
    margin-right: 4px;
    font-size: 16px;
  }

  .c-key-info {
    @include fontstyle(16px, normal, 1.5);
    max-width: 182px;
  }

  .key-heading {
    @include fontstyle(16px, normal, 1);
    display: inline-block;
  }

  @media(max-width: 1200px) {
    padding-left: 24px;
    position: relative;

    .header-list-icon {
      font-size: 16px;
    }

    .key-heading {
      display: none;
    }

    .aside-icon {
      position: absolute;
      left: 0;
      margin: 0;
      top: 1px;
    }
  }
}

.desktop-small {
  .course-key-listing {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .course-key-list-item {
    max-width: 188px;
    padding-right: 10px;
    flex: 0 0 188px;

    &:last-child {
      padding-right: 0;
    }
  }

  .c-key-info {
    max-width: 100%;
  }
}

.ipad {
  .course-key-listing {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .course-key-list-item {
    max-width: 188px;
    padding-right: 20px;
    flex: 0 0 188px;
  }
}

.mobile-device {
  .course-key-container {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .course-key-listing {
    flex-direction: column;
    padding: 24px  32px 0!important;
    margin-bottom: 0;
  }

  .course-key-list-item {
    .header-list-icon {
      margin-right: 16px;
    }

    .c-key-info {
      padding-left: 38px;
      max-width: 100%;
      font-weight: 300;
    }

    + .course-key-list-item {
      margin-top: 24px;
    }
  }
}
