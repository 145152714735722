.modal-general {
  min-width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  min-height: 100%;

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 420px;
      margin: 0 auto;
    }
  }

  .modal-dialog {
    width: 420px;
    max-width: 420px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &.modal-500 {
    .modal-dialog {
      width: 500px;
      max-width: 500px;
    }
  }


  .modal-content {
    padding: 24px;
    margin: auto;
    max-width: 100%;
    border: 0;
  }

  .modal-body {
    padding: 0;
  }

  .modal-header {
    padding: 0 0 32px;

    .ifill-os-close {
      font-size: 15px;
      margin-right: -10px;
      position: absolute;
      right: 24px;
      top: 22px;
    }

    &.pb-16 {
      padding-bottom: 16px;
    }
  }

  .modal-title {
    padding-right: 40px;
  }

  .svg-wrap {
    margin-bottom: 16px;
  }

  .modal-text {
    @include fontstyle(16px, normal, 1.5);
  }

  .submit-empty-case {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 8px;
    margin-bottom: 40px;
  }

  .modal-footer {
    padding: 0 0 10px 0;
    * {
        margin: 0;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px)  {

    .modal-dialog {
      height: 100%;
      align-items: center;
      display: flex;
      margin: 0 auto;
      width: 95%;
      max-width: 95%;
    }

    .modal-content {
      width: 95%;
      margin: auto;

      .modal-header {
        .ifill-os-close {
          margin-right:0;
        }
      }

      .modal-body {
        p{
          padding: 10px 30px;
        }
      }
    }
  }

  *[role="button"] {
    outline: 0 !important;
  }

  &.speciality_info {
    .loader {
      height: 420px;

      &.loader-active {
        .loader-inner {
          margin-top: 0;
        }
      }

      @media only screen and (max-device-width : 767px) {
        height: 100%;
        min-height: auto;
      }
    }
  }
}


.modal-content {
  .os-header-btn {
    right: 15px;
    position: absolute;
    top: 20px;
    padding: 0 !important;
    z-index: 1;
    color: $level-10;

    .btn-icon-helper {
      width: unset;
      line-height: unset;
      right: auto !important;
      top: 50% !important;
      background: transparent !important;
      border-radius: 0;
      color: inherit !important;
      position: absolute !important;
    }
  }

  .z1 {
    z-index: 1;
  }

  .top-right-0,
  .rel-pos {
    top: 0;
    right: 0;
  }

  .rel-pos {
    position: relative;
  }

  .close-send-requesnt-btn {
    right: 12px;
  }

  .close-video-modal-btn {
    right: -11px;
  }

  .y-center {
    top: calc(50% - 20px);
  }
}

.top-priority-modal {
  + .modal {
    z-index: 1090;
  }
}
