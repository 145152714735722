$video-picker-height: 72px !default;
$video-scrubber-height: 60px !default;

.cases {
  .threesixty-spin {
    background-size: unset !important;
  }
  .signup-colleague-next, .signup-colleague-prev {
    display: none;
  }
}
.case-video {
  overflow: hidden;
  @include keep-aspect-ratio($x: 16, $y: 9);
  height: 0;

  &.x-ray {
    height: 460px;
    padding-bottom: 0
  }
  .center-objects {

    .case-image-full {
      display: inline-block;
    }
  }
}

.case-photo {
  min-height: 200px;
  overflow: hidden;
  @include keep-aspect-ratio($x: 16, $y: 9);
  height: 0;

  .center-objects {

    .case-image-full {
      display: inline-block;
    }
  }
}


.video-holder {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px 0 0 0;
  .video-bar {
    border-radius: 10px 0 0 0;
  }
  .spin-not-processed {
    height: 200px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .threesixty-spin {
    left: -50px;
    transform: scale(.85);
    &:after {
      content: " ";
      right: -2px;
      width: 4px;
      background: #222226;
      height: 100%;
      position: absolute;
      z-index: 2;
    }
    &.comparison {
      margin: -84px 0 0 -123px;
      transform: scale(.55);
      top: -22px;
      left: 0;
      &:after {
        content: " ";
        right: -2px;
        width: 4px;
        background: #222226;
        height: 100%;
        position: absolute;
        z-index: 2;
      }
    }
  }

  &.fs-mode {
    background-color: #121214;
    .video-bar {
      padding-bottom: 30px;
      .video-type-buttons {
        padding-left: 25px;
        top: 35px;
        .video-type-thumbs-block {
          padding-left: 15px;
        }
      }
      .viewing-mode-buttons {
        right: 25px;
        top: 35px;
      }
    }
    .video-scrubber {
      margin-top: 0px;
      padding-left: 25px;
      height: 60px;
      &.slick-scrubber {
        margin-top: -50px;
      }

    }
    .threesixty-spin {
      &:after {
        background: #121214;
      }
      &.comparison {
        &:after {
          background: #121214;
        }
      }
    }
  }

  @media only screen and (min-width: 1367px) and (max-width: 1400px)  {
    &.fs-mode {
      .case-video {
        .threesixty-spin {
          top: -42px;
          left: -86px;
          transform: scale(1.3);
        }
      }
    }
  }

  @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait) and  (-webkit-min-device-pixel-ratio: 2) {
    .threesixty-spin {
      top: -45px;
      left: -88px;
      transform: scale(.75);

      &.comparison {
        top: -30px;
        left: -30px;
        transform: scale(.60);
      }
    }
    &.fs-mode {
      .video-bar {
        padding-top: 0;
        padding-bottom: 107px;
      }
      .video-scrubber {
        margin-top: 0px;
        padding-right: 60px;
        &.slick-scrubber {
          margin-top: -45px;
          padding-right: 45px;
        }
      }

    }
  }

  @media only screen and (min-device-width : 1024px) and (max-device-width : 1365px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .threesixty-spin {
      top: -20px;
      left: -37px;
      transform: scale(0.86);

      &.comparison {
        top: -30px;
        left: -30px;
        transform: scale(.60);
      }
    }
    &.fs-mode {
      .case-video {
        .threesixty-spin {
          transform: scale(1.5);
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and  (orientation : portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .threesixty-spin {
      top: -114px;
      left: -203px;
      transform: scale(0.47);

      &.comparison {
        top: -30px;
        left: -118px;
        transform: scale(0.35);
      }
    }
    &.fs-mode {
      .video-bar {
        padding-top: 0;
      }
      .video-scrubber {
        margin-top: 0px;
        padding-right: 58px;
        &.slick-scrubber {
          margin-top: -45px;
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .threesixty-spin {
      top: -44px;
      left: -86px;
      transform: scale(0.76);

      &.comparison {
        top: -60px;
        left: -100px;
        transform: scale(.40);
      }
    }
    &.fs-mode {
      .video-bar {
        padding-top: 0;
        padding-bottom: 88px;
      }
    }
  }



  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .threesixty-spin {
      width: 800px !important;
      top: -164px;
      left: -276px;
      transform: scale(.31);
      margin-top: 0px;

      &.comparison {
        top: -50px;
        left: -200px;
        transform: scale(.18);
      }
    }

    &.fs-mode {
      .video-bar {
        padding-top: 0;
      }
      .video-scrubber {
        .video-fullscreen{
          right: 4px;
          top: -30px;
        }
        &.slick-scrubber {
          margin-top: -30px;
        }
      }
    }

    .video-fullscreen {
      visibility: hidden;
      display: none;
    }
  }


  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation : portrait) {
    .threesixty-spin {
      top: -131px;
      left: -248px;
      transform: scale(0.38);

      &.comparison {
        top: -50px;
        left: -200px;
        transform: scale(.18);
      }
    }
    &.fs-mode {
      .video-bar {
        padding-top: 0;
      }

    }
  }


  @media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and  (orientation : portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .threesixty-spin {
      top: -128px;
      left: -222px;
      transform: scale(0.42);

      &.comparison {
        top: -30px;
        left: -100px;
        transform: scale(.40);
      }
    }
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation : portrait)  {
    .threesixty-spin {
      top: -131px;
      left: -248px;
      transform: scale(0.38);

      &.comparison {
        top: -50px;
        left: -200px;
        transform: scale(.18);
      }
    }
  }

  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and(orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .threesixty-spin {
      top: -131px;
      left: -228px;
      transform: scale(0.43);
    }
  }


  &.fs-mode {
    .threesixty-spin {
      transform: scale(1.3);
      &.comparison {
        margin: -84px 0 0 -123px;
        transform: scale(0.8);
      }
    }

    .case-image-full {
      display: block;

      &.case-image-sbs {
        display: block;
      }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (-webkit-min-device-pixel-ratio: 1) {
      .threesixty-spin {
        margin-left: -50px;
        transform: scale(0.88);

        &.comparison {
          margin: -100px 0 0 -160px;
          transform: scale(.44);
        }
      }
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio: 1) {
      .threesixty-spin {
        margin-left: -50px;
        transform: scale(1.15);

        &.comparison {
          margin: -100px 0 0 -160px;
          transform: scale(.38);
        }
      }
    }

    @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait) and  (-webkit-min-device-pixel-ratio: 1) {
      .threesixty-spin {
        margin-left: -50px;
        transform: scale(1.1);
        &.comparison {
          top: -30px;
          left: -30px;
          transform: scale(.60);
        }
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      .threesixty-spin {
        transform: scale(.4);

        &.comparison {
          transform: scale(.27);
        }
      }

      .slick-slider {
        .slick-list {
          padding-top: 0px;
        }
      }

    }

    .video-position-holder {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .slick-slider {
      .slick-list {
        height: calc(100vh - 125px);
        padding-top: 40px;
      }
      .case-video {
        height: calc(100vh - 160px);
      }
      .case-photo {
        height: calc(100vh - 190px);
        margin-top: -40px;
      }
    }

    .case-video, .case-photo {
      height: calc(100vh - 160px);
      padding-bottom: 0;
      position: relative;
      .threesixty-spin {
        left: 50%;
        margin-left: -400px;
        top: 50%;
        margin-top: -236px;
      }
    }

    .video-scrubber {
      .slick-slider {
        .slick-list {
          padding-top: 0px;
        }
      }
    }
  }
}


// Transistion between videos
.video-change-enter {
  opacity: 0.01;
}

.video-change-enter.video-change-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.video-change-leave {
  opacity: 1;
  position: absolute;
  top: $video-picker-height;
  bottom: $video-scrubber-height;
  left: 0;
  right: 0;
}

.video-change-leave.video-change-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.video-bar {
  position: relative;
  padding-top: $video-picker-height;
  z-index: 2;

  &.case-side-by-side {
    padding-top: 50px;
  }

  .video-select {
    padding: 4px 8px;
    font-size: 10px;
    cursor: pointer;
    font-weight: $font-weight-bold;
    border-radius: 4px;
    margin-left: 4px;
    height: 28px;
    background-color: $video-tag;
    color: $level-1;
    letter-spacing: .5px;
    &:first-child {
      margin-left: 0;
    }
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
    &:hover,
    &:active {
      background-color: $level-8;
      color: $video-tag;
    }
    &.disabled {
      color: $level-8;
      background-color: $level-8;
    }
    &.selected {
      color: $level-1;
      background-color: $level-10;

    }
    &.photo-select {
      :hover {
        .video-type-thumbs-block {
          display: none;
        }
      }
    }
    @include media-breakpoint-down(lg){
      padding: 4px 6px;
      font-size: 10px;
    }
  }

  .video-type-buttons {
    width: 100%;
    padding-left: 16px;
    position: absolute;
    top: 20px;
    left: 0;
  }

  .video-type-preview {
    position: absolute;
    left: 0;
    cursor: pointer;

    &.Soft-Tissue-1,
    &.Hard-Tissue-1,
    &.Axial {
      top: 30px;
    }
    &.Hard-Tissue-2,
    &.Airway-1,
    &.Coronal {
      top: 110px;
    }
    &.Both,
    &.Airway-2,
    &.Sagittal {
      top: 190px;
    }
    &.Upper {
      top: 270px;
    }
    &.Lower {
      top: 350px;
    }
  }

  .video-type-preview-selected .video-type-preview {
    border: 3px solid $os-link;
  }

  .video-type-thumbs-block {
    width: 100%;
    padding: 10px 10px 0;
    position: absolute;
    top: 28px;
    left: 0;
    z-index: 5;
    text-align: left;
    display: none;
    &.active {
      display: block;
    }
    .video-thumb-container {
      padding-top: 25px;
    }

    .video-type-thumbs-row {
      width: 100%;
      min-height: 30px;
      padding: 8px 10px;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      background: #29292e;
      border-radius: 8px;
      line-height: 1.25;

      &:empty {
        display: none;
      }
    }

    .video-thumbs-holder {
      width: 92px;
      height: 72px;
      margin-left: 2px;
      border: solid 1px transparent;
      display: inline-block;
      position: relative;
      vertical-align: top;

      &.selected {
        border-color: $os-link-hover;
      }

      &:first-child {
        margin-left: 0;
      }

      video {
        display: block;
      }

      .video-thumbs-text {
        width: 100%;
        padding-left: 5px;
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        color: $level-1;
        font-size: 8px;
        font-weight: $font-weight-bold;
        letter-spacing: .5px;
      }
    }
  }

  .video-thumbs-label {
    margin-right: 10px;
    padding-bottom: 2px;
    display: inline-block;
    color: $level-6;
    font-size: 8px;
    font-weight: $font-weight-bold;
    letter-spacing: .7px;

    &.selected {
      color: $level-10;
    }
  }

  .viewing-mode-buttons {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .video-select {
      padding: 4px;
      font-size: 9px;
    }
  }
}

// Images detail


.center-objects{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

// Images Selector
.image-selector-unselected{
  opacity: 0.5;
}

.image-selector-image{
  height: 60px;
}

.case-image-prev,
.case-image-next,
.image-selector-prev,
.image-selector-next{
  position: absolute;
  display: block;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  z-index: 1;
  background-color: transparent;
}

.case-image-prev,
.case-image-next{
  height: 40px;
  width: 20px;
  font-size: 20px;
  line-height: 40px;
  color: $level-6;
}

.case-image-prev{
  left: 8px;
}

.case-image-next{
  right: 8px;
}

.image-selector-prev,
.image-selector-next{
  height: 60px;
  width: 30px;
  font-size: 12px;
  line-height: 60px;
  color: $level-6;
  background-color: transparent;
}

.image-selector-prev{
  top: 30px;
  left: -30px;
}

.image-selector-next{
  top: 30px;
  right: -30px;
}

.video-scrubber {
  position: relative;
  width: 100%;
  /*height: $video-scrubber-height;*/
  padding-left: 10px;
  padding-right: 48px;
  margin-top: 10px;

  &.slick-scrubber {
    padding: 0;
    margin-top: 16px;
  }

  input{
    &:hover{
      @mixin thumb{
        box-shadow: 0 1px 13px 1px rgba(255,255,255,0.79);
        border: 8px solid rgba(255,255,255,0.1);
      }
    }
  }

  .video-play {
    position: absolute;
    left: 20px;
    top: 4px;
    font-size: 20px;
    color: black;
    background-color: $level-1;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    cursor: pointer;
    opacity: .45;
    transition: 300ms;
    .fa-play{
      margin-left: 2px;
    }
    &:hover{
      opacity: 1;
    }
  }

  .video-fullscreen {
    position: absolute;
    right: 20px;
    top: 0px;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
    background-color: $level-8;
    color: $level-1;
    height: 20px;
    width: 20px;
    text-align: center;
    font-size: 16px;
    border-radius: 3px;
    opacity: .9;
  }

  // From https://github.com/darlanrod/input-range-scss
  $track-color: #303036 !default;
  $thumb-color: #4c4c54 !default;

  $thumb-radius: 4px !default;
  $thumb-height: 20px !default;
  $thumb-height-mobile: 70px !default;
  $thumb-width-mobile: 70px !default;
  $thumb-web-bg: url('/app/images/slider-thumb-desk.png');
  $thumb-web-bg-active: $thumb-web-bg;
  $thumb-mobile-bg: url('/app/images/slider-thumb-new.png');
  $thumb-mobile-bg-active: $thumb-mobile-bg;
  $thumb-width: 70px !default;
  $thumb-shadow-size: 2px !default;
  $thumb-shadow-blur: 2px !default;
  $thumb-shadow-color: rgba(0, 0, 0, 0.2) !default;
  $thumb-border-width: 0px !default;
  $thumb-border-color: #eceff1 !default;

  $track-width: 100% !default;
  $track-height: 4px !default;
  $track-shadow-size: 0px !default;
  $track-shadow-blur: 0px !default;
  $track-shadow-color: #303036 !default;
  $track-border-width: 1px !default;
  $track-border-color: #303036 !default;

  $track-radius: 3px !default;
  $contrast: 5% !default;

  @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
    box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
      0 0 $shadow-size lighten($shadow-color, 5%);
  }

  @mixin track {
    cursor: pointer;
    height: $track-height;
    transition: all 0.2s ease;
    width: $track-width;
  }

  @mixin thumb {
    @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
    background-color : transparent;
    background-image: $thumb-web-bg;
    background-repeat: no-repeat;
    background-size: 70px 20px;
    background-position: center;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    box-shadow: none;
    height: $thumb-height;
    width: $thumb-width;
    transition: 300ms;
    /*&:hover{
      box-shadow: 0 1px 5px 2px rgba(255,255,255,0.2);
    }*/
    @media screen and (min-width: 320px) and (max-width: 896px) {
      background-color : transparent;
      background-image: $thumb-mobile-bg;
      background-repeat: no-repeat;
      background-size: 70px 70px;
      background-position: center;
      height: $thumb-height-mobile;
      width: $thumb-width-mobile;
      margin-top: -34px;
      border-radius: 0;
      @include shadow(0, 0, transparent);
      &:hover, &:focus, &:active {
/*        background-image: $thumb-mobile-bg-active;
*/        @include shadow(0, 0, transparent);
      }
    }
  }

  [type="range"] {
    -webkit-appearance: none;
    margin: $thumb-height / 2 0;
    width: $track-width;
    background-color: transparent;
    border-radius: 9px;
    margin-top: 14px;
    @media screen and (min-width: 320px) and (max-width: 896px) {
      margin: $thumb-height-mobile / 2 0;
    }

    &:required {
      box-shadow: 0 0 0 0 transparent;
    }

    &:invalid {
      box-shadow: 0 0 0 0 transparent;
    }

    &:focus {
      outline: 0;

      &::-webkit-slider-runnable-track {
        background: lighten($track-color, $contrast);
      }

      &::-ms-fill-lower {
        background: $track-color;
      }

      &::-ms-fill-upper {
        background: lighten($track-color, $contrast);
      }
    }

    &::-webkit-slider-runnable-track {
      @include track;
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }

    &::-webkit-slider-thumb {
      @include thumb;
      -webkit-appearance: none;
      margin-top: ((-$track-border-width * 2 + $track-height) / 2) - ($thumb-height /
            2);
    }

    &::-moz-range-track {
      @include track;
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius;
    }

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-ms-track {
      @include track;
      background: transparent;
      border-color: transparent;
      border-width: ($thumb-height / 2) 0;
      color: transparent;
    }

    &::-ms-fill-lower {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: darken($track-color, $contrast);
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius * 2;
    }

    &::-ms-fill-upper {
      @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
      background: $track-color;
      border: $track-border-width solid $track-border-color;
      border-radius: $track-radius * 2;
    }

    &::-ms-thumb {
      @include thumb;
      margin-top: 0;
    }
  }

  @-moz-document url-prefix() {
    [type="range"] {
      margin-top: 7px;
    }

  }

  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and(orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    padding-right: 30px;
  }
}

.video-scrubber .video-paused::-webkit-slider-thumb{
  box-shadow: 0 0px 1px 7px rgba(255,255,255,0.3);
}

.video-scrubber .video-paused::-moz-range-thumb{
  box-shadow: 0 0px 1px 7px rgba(255,255,255,0.3);
}

.video-scrubber .video-paused::-ms-thumb{
  box-shadow: 0 0px 1px 7px rgba(255,255,255,0.3);
}

.relative {
  position: relative;
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .video-scrubber {
    margin-top : 10px;
    .video-fullscreen {
      right: 15px;
    }
  }

  .fs-mode {
    .video-scrubber {
      .video-fullscreen {
        right: 25px;
      }
    }
  }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .video-scrubber {
    [type="range"]{
      margin-top: 7px;
    }
    .video-fullscreen {
      right: 15px;
    }
  }

  .fs-mode {
    .video-scrubber {
      .video-fullscreen {
        right: 18px;
      }
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .video-scrubber {
    padding-right: 50px;
    .video-fullscreen {
      right: 15px;
    }

  }
  .ipad {
    .detail {
      .video-scrubber {
        padding-right: 50px;
        margin-top: 7px;
        .video-fullscreen {
          right: 15px;
        }
      }
    }
  }
  .video-holder.fs-mode {
    .video-scrubber {
      padding-right: 50px;

      .slick-slider {
        .slick-list {
          height: calc(100vh - 150px);
        }
        .case-video, .case-photo {
          height: calc(100vh - 200px);
        }
      }
    }

    .case-video, .case-photo {
      height: calc(100vh - 110px);
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder.fs-mode {
    .video-scrubber {
      margin-top: 15px;

      &.slick-scrubber {
        margin-top : -20px;
      }

    }
    .case-video, .case-photo {
      height: calc(100vh - 170px);
    }

    .slick-slider {
      .slick-list {
        height: calc(100vh - 130px);
      }
      .case-video, .case-photo {
        height: calc(100vh - 200px);
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px)  and (-webkit-min-device-pixel-ratio: 1) {
  .video-scrubber {
    .slick-slide {
      &.slick-cloned {
        margin-right: 0px;
      }
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and  (orientation : portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder {
    &.fs-mode {
      .slick-slider {
        .case-video, .case-photo {
          height: calc(100vh - 220px);
        }
      }
    }
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and  (orientation : portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder {
    &.fs-mode {
      .slick-slider {
        .case-video, .case-photo {
          height: calc(100vh - 220px);
        }
      }
    }
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and  (orientation : landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder {
    &.fs-mode {
      .case-video, .case-photo {
        height: calc(100vh - 160px);
      }
      .slick-slider {
        .case-video, .case-photo {
          height: calc(100vh - 200px);
        }
      }
    }
  }
}

.touch-support {
  &.iphone {
    .video-fullscreen {
      visibility: hidden;
      display: none;
    }
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and  (orientation : landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder {
    &.fs-mode {
      .case-video, .case-photo {
        height: calc(100vh - 160px);
      }
      .slick-slider {
        .case-video, .case-photo {
          height: calc(100vh - 200px);
        }
      }
    }
  }
}

@media only screen and (min-device-width : 1650px) and (max-device-width : 1999px) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder{
    &.fs-mode {
      .threesixty-spin {
        transform: scale(1.8);
      }
    }
  }
}
@media only screen and (min-device-width : 2000px) and (max-device-width : 2449px) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder{
    &.fs-mode {
      .threesixty-spin {
        transform: scale(2.25);
      }
    }
  }
}

@media only screen and (min-device-width : 2450px) and (-webkit-min-device-pixel-ratio: 1) {
  .video-holder{
    &.fs-mode {
      .threesixty-spin {
        transform: scale(2.8);
      }
    }
  }
}


.ipad {
  .case-photo:not(.case-side-by-side) {
    .center-objects {
      align-items: flex-start;
      display: block;
      text-align: center;

      .case-image-full {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.case-side-by-side {
  .center-objects {
    align-items: flex-start;
    text-align: center;
    display: block;

    .sbs-image-container:only-child {
      text-align: center;
    }
  }
}

.sbs-image-container {
  display: inline-block;
  width: 50%;
  max-width: 50%;
  height: 100%;
  position: relative;
  vertical-align: top;
  text-align: right;

  .case-image-sbs {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  + .sbs-image-container {
      text-align: left;
  }

  &:first-child {
    .case-image-not-available {
      left: 0;
      transform: translateY(-50%);
      display: block;
    }
  }

  .case-image-not-available {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.mobile-device {
  &.portrait {
    @media only screen and (min-device-width : 360px) and (max-device-width : 760px) {
      .video-scrubber [type="range"] {
        &.vertical-scrubber {
          width: 190px;
          top: -103px;
          right: -87px;
        }
      }

      .video-holder .threesixty-spin {
        top: -140px;
        left: -239px;
        transform: scale(0.40);
      }
    }

    @media only screen and (min-device-width : 414px) and (max-device-width : 760px) {
      .video-scrubber [type="range"] {
        &.vertical-scrubber {
          width: 211px;
          top: -107px;
          right: -96px;
        }
      }
      .video-holder .threesixty-spin {
        top: -120px;
        left: -219px;
        transform: scale(0.45);
      }
    }
  }
}

.mobile-device {
  &.portrait {
    .case-image-prev{
      left: 0px;
    }

    .case-image-next{
      right: 0px;
    }
  }
}
