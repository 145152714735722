.wrapperLarge, .outerWrapperSmall {
  position: relative;
  padding-top: 16px;
  z-index: 10;

  .siteNameLinkWrapper {
    &:hover {
      text-decoration: underline;
    }
  }

  .textWrapperLarge {
    @include fontstyle(16px, 300, 1.5);
    color: $level-11;
    padding: 16px 0;
  }
}

.outerWrapperSmall {
  align-items: flex-start;
  min-width: 100%;
  width: 100%;

  .textWrapperSmall {
    @include fontstyle(12px, 300, 1.33);
    color: $level-11;
    padding: 16px 0 16px 16px;

    p {
      @include multiline-truncate(4);
    }
  }
}
