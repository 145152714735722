.share-link-modal {
  min-height: calc(100vh - 20%);
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .loader-active {
    height: 433px;

    .loader-inner {
      margin-top: 0;
    }
  }

  .form-control {
    &::placeholder {
      letter-spacing: 0 !important;
      line-height: 24px;
      color: $textarea-placeholder;
    }
  }

  .modal-content {
    padding: 24px 0 40px 0;
    margin-top: 20%;
    border: 0;
    transition: padding-bottom 500ms ease;
  }

  .name-link {
    margin-top: 48px;
    label {
      @include fontstyle(16px, 600, 16px);
      color: $label;
      margin-bottom: 0;
    }

    #share-link-form {
      .form-control {
        &.error {
          border-color: $lt-red !important;
        }
      }

      .form-group  {
        margin-bottom: 0;
      }
    }
  }

  .new-space-link-hint {
    @include fontstyle(12px, 500, 16px);
    color: $level-11;
    margin-top: 4px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0 32px;
    margin: 0;
  }

  .modal-body {
    margin-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .os-header-btn {
    position: relative;
    top: -6px;
    right: -10px;

    .btn-icon-helper {
      font-size: 14px;
    }

    @media screen and (max-width: 767px) {
      top: 0;
    }
  }

  .modal-info-helper-text {
    @include fontstyle(16px, normal, 24px);
  }

  .share-link-modal-footer {
    margin-top: 48px;
    justify-content: center;
    @media screen and (max-width: 767px){
      flex-direction: column;
    }
  }

  .modal-footer {
    &.show {
      padding-bottom: 0 !important;
    }
  }

  .osbtn-primary {
    min-width: 195px;
  }

  .ifill-os-calendar {
    position: absolute;
    top: 12px;
    left: 196px;
    z-index: 1;
  }

  &.share-password-modal {
    align-items: center;

    .modal-content {
      padding: 24px 0 40px;
      border-radius: 8px;
      margin-top: 0;
    }

    .modal-header {
      padding-right: 32px !important;
      padding-left: 32px !important;
      align-items: flex-start !important;

      .modal-title {
        line-height: 28px;
      }
    }

    .modal-body {
      padding: 0;
      margin: 24px 0 0 0;
    }

    .modal-info-helper-text {
      padding-right: 32px ;
      padding-left: 32px;

      .osbtn-link {
        min-height: auto !important;
        line-height: 1 !important;
      }

      p{
        margin-top: 24px;
      }
    }

    .modal-body {
      &.with-password-form {
        .modal-info-helper-text {
          p{
            margin-top: 0;
          }
        }
      }
    }

    .osbtn-link {
      font-size: 16px;
    }

    .form-action-row {
      margin-bottom: 0;
      margin-top: 32px;
    }

    .password-form-body {
      margin-top: 0;
      padding: 22px 32px 0;
      border-radius: 0px 0px 8px 8px;

      .modal-title {
        line-height: 28px;
      }

      .reset-password-form {
        margin-top: 24px;
      }

      .form-action-row {
        margin-top: 32px;
        display: block;
      }

      .form-group {
        display: flex;
        align-items: center;
        padding-bottom: 24px;
        margin-bottom: 0;
        position: relative;

        label {
          @include fontstyle(16px, 600, 16px);
          min-width: 180px;
          color: $level-11;
          margin-bottom: 0;


        }

        .with-error {
          position: absolute;
          left: 180px;
          top: 42px;
          font-size: 12px;
          line-height: 14px;
        }

        + .form-error {
          margin-top: -24px;
          padding-left: 180px;
        }

        .password-hint {
          position: absolute;
          left: 180px;
          bottom: -4px;
        }

        .ifill-os-views,
        .ifill-os-hide-password  {
          position: absolute;
          right: 10px;
          top: 12px;
        }


      }

      @media screen and (max-width: 767px) {
        .modal-title {
          line-height: 28px;
        }
        .form-group {
          flex-direction: column;
          align-items: flex-start;

          label {
            width: 100%;
            margin-right: 0;
            margin-bottom: 8px;
          }

          .with-error, .password-hint {
            position: relative;
            left: 0;
            top: 0;
          }

          .password-hint {
            margin-top: 4px;
          }

          .ifill-os-views,
          .ifill-os-hide-password  {
            top: 36px;
          }

          + .form-error {
            margin-top: -8px;
            padding-left: 0;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      align-items: flex-start;
    }
  }

  &.share-link-deactivate-modal {
    align-items: center;
    max-width: 432px;

    .modal-content {
      padding: 40px 0 40px;
      border-radius: 8px;
      margin-top: 0;
    }

    .modal-header {
      padding-right: 32px !important;
      padding-left: 32px !important;
      align-items: flex-start !important;

      .modal-title {
        line-height: 28px;
      }

      .os-header-btn {
        top: -2px;

        @media screen and (max-width: 767px) {
          top: 0;
        }
      }
    }

    .modal-body {
      padding: 0;
      margin: 24px 0 0 0;
    }

    .modal-info-helper-text {
      padding-right: 32px ;
      padding-left: 32px;

      p{
        margin-top: 24px;
      }
    }

    .osbtn-link {
      font-size: 16px;
    }

    @media screen and (max-width: 767px) {
      align-items: flex-start;
    }
  }

  .share-permission-modal {
    width: 100%;
    max-width: 100%;
    align-items: center;
    background-color: $level-3;
    padding: 24px 32px;
    border-radius: 0 0 8px 8px;
    margin-top: 24px;
    position: absolute;
    height: 0;
    opacity: 0;
    bottom: -100px;
    transition: opacity 300ms ease, height 300ms ease, bottom 300ms ease;
    pointer-events: none;
    z-index: 9;

    @media screen and (max-width: 767px) {
      border-radius: 0;
    }

    .share-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .permission-setting-form {
      padding-top: 24px;
    }

    .form-group {
      position: relative;

      &.hide-password-field {
        label {
          margin-bottom: 16px;
        }
        input, .password-hint, i {
         display: none;
        }
      }
    }

    .osbtn-icon {

      &.righticon {
        padding-right: 64px;

        .fa-spinner {
          top: 13px;
          left: 22px;
          position: absolute;
        }
        .btn-icon-helper {
          right: 40px;
        }
      }
    }

    .password-field-group {
      .form-group {
        + .form-group {
          padding-bottom: 0;
        }
      }

      @media screen and (min-width: 768px) {
        .form-group:not(.hide-password-field) + .form-group {
          .switch {
            margin-top: 0;
          }
        }
      }

      @media screen and (max-width: 767px) {
        .form-group:not(.hide-password-field) + .form-group {
          .switch {
            margin-top: -116px;
          }
        }
      }
    }

    .switch {
      position: absolute;
      margin-top: -88px;
      margin-left: 0;
      background-color: #fff;
      min-width: 38px !important;
      height: 12px;
      border-radius: 16px;
      right: 0;

      @media screen and (max-width: 768px) {
        margin-top: -72px;
      }

      .check-icon-input {
        display: none;
      }

      .check-icon {
        position: absolute;
        font-size: 16px;
        transition: all .5s ease;
        left: 0;
        top: -12px;
        color: rgba(255, 255, 255, 0.6);
        width: 36px;
        height: 36px;
        background: rgba(18, 18, 20, 0.7);
        border-radius: 50%;
        transform: scale(1) translateX(-16px);
        cursor: pointer;

        &:before {
          content:'';
          width: 20px;
          height: 20px;
          position: absolute;
          left: 8px;
          top: 8px;
          background-color: $level-11;
          border-radius: 50%;
        }

        + .check-icon {
          display: none;
        }
      }

      .check-icon-input:checked ~ .ifill-os-checkmark-default-white {
        transform: translateX(18px);
        background: $os-link-hover;

        &:before {
          background-color: $os-link;
        }
      }
    }


    .form-group {
      display: flex;
      align-items: center;
      padding-bottom: 24px;
      margin-bottom: 0;

      label {
        @include fontstyle(16px, 600, 16px);
        min-width: 166px;
        margin-right: 14px;
        margin-bottom: 0;
      }

      .form-control {
        &::placeholder {
          letter-spacing: 0;
          color: $textarea-placeholder;
          font-size: 16px;
        }
      }

      .react-datepicker-wrapper {
        min-width: 240px;
      }

      .with-error {
        position: absolute;
        left: 180px;
        top: 42px;
        font-size: 12px;
        line-height: 14px;
      }

      .password-hint {
        position: absolute;
        left: 180px;
        top: 32px;
      }


      .ifill-os-views,
      .ifill-os-hide-password  {
        position: absolute;
        right: 10px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;

        label {
          width: 100%;
          margin-right: 0;
          margin-bottom: 8px;

          &.switch {
            width: 38px;
            top: 26px;
            right: 16px;
            left: auto;
          }

          &[for='password'] {
            margin-bottom: 12px;
          }
        }

        .with-error, .password-hint {
          position: relative;
          left: 0;
          top: 0;
        }

        .password-hint {
          margin-top: 4px;
        }
      }
    }

    &.show {
      opacity: 1;
      height: auto;
      bottom: 0;
      pointer-events: auto;
    }
  }

  .osbtn-ghost {
    min-width: 214px;

    @media screen and (max-width: 767px) {
      min-width: 193px;
      line-height: 40px !important;
      min-height: 40px !important;
      margin-bottom: 16px;
    }
  }

  .only-div {
    + .share-link-modal-footer {
      justify-content: space-between;
      flex-direction: column;
    }
  }
}


.share-backdrop-image {
  background-image: url(./../../images/Desktop-11@3x.png);
  background-size: cover;
  filter: blur(10px);

  &.show {
    opacity: 1;
  }

  &:before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(18,18,20,0.7);
  }
}

.mobile-device {
  .share-link-modal {
    height: 100vh;
    .ifill-os-calendar{
      left: 16px;
      top: 35px;
    }

    .osbtn {
      min-height: 40px !important;
      line-height: 40px !important;
    }

    &:not(.share-permission-modal) {
      .modal-content {
        height: 100%;
        margin: 0;
        border-radius: 0;
        border: 0;
      }

      .modal-body {
        flex: 0 0 auto;
      }
    }

    .share-permission-modal {
      .form-group  {
        .ifill-os-views,
        .ifill-os-hide-password {
          top: 34px;
        }
      }

      .share-link-modal-footer {
        .osbtn {
          min-width: 214px;
          min-height: 40px;
          line-height: 40px;

          + .osbtn {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }

    .osbtn-primary {
      min-height: 40px;
      line-height: 40px;
    }
  }
}

.link-list {
  max-height: 460px;
  overflow-y: auto;
  padding-right: 28px;
  margin-right: -12px;
  margin-top: -8px;
}

.expire-url-div {
  margin-top: 32px;

  &:first-child{
    margin-top: 8px;
  }

  .tooltip-copy {
    top: -42px;
    right: 50%;
    margin-right: -65px;
    &:after {
      content: none;
    }
  }
  .link-name {
    @include fontstyle(16px, 500, 19px);
  }

  .link-shared {
    @include fontstyle(12px, 500, 16px);
    color: $level-11;
  }

  .link-views {
    color: $level-11;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 12px;
    text-align: center;
  }

  .a-link-url {
    @include text-truncate();
    text-align: left;
    line-height: 24px;
    padding: 8px 8px 8px 32px;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    max-width: 100%;
    min-height: 32px;
    position: relative;
    color: $level-8;
    background-color:$level-3;

    @media screen and (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.disabled {
      color: #6c6c6f;
    }

    i {
      position: absolute;
      left: 8px;
      top: 12px;
      font-size: 16px;

      @media screen and (max-width: 767px) {
        top: 14px;
      }
    }

    &:hover {
      background-color: $level-4 !important;
      color: $level-10!important;
    }
  }

  .expire-text {
    font-size: 16px;
    line-height: 16px;
    color: $level-11;
    font-weight: 600;

    small {
      font-size: 12px;
      display: block;
      margin-top: 4px;
    }
  }

  .edit-permission-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .edit-permission-btn{


    @media screen and (max-width: 767px) {
      align-items: flex-start;

      .expire-text {
        padding-top: 10px;
      }
    }

    &.no-text {
      .btn-icon-helper {
        color: $level-8 !important;
      }
      &:hover {
        background-color: $level-4;
        color: $level-8 !important;
        .btn-icon-helper {
          color: $level-8 !important;
        }
      }
    }
  }

  &.only-div {
    .link-name {
      @include fontstyle(16px, 600, 16px);
      margin-bottom: 16px;
    }

    .edit-permission-div {
      justify-content: space-between;
      padding-top: 16px;
    }

    .tooltip-copy {
      top: -7px;
    }
  }
}

.share-backdrop-image + .modal {
  padding-bottom: 50px;
}
