.card--loading {
  background: $level-1;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
  pointer-events: none;
  min-width: 100%;
  box-shadow: $block-shadow;

  .card__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .card-top {
    height: 104px;
    padding: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 24px;
      color: $level-2;
    }
  }

  .card-body {
    background: $level-1;
    width: 47.16%;
  }

  .bar {
    border-radius: 4px;
    background: $level-6;
    display: block;
    position: relative;
  }

  .bar-2,
  .bar-3 {
    height: 16px;
    margin-top: 8px;

  }

  .bar-1 {
    height: 12px;
    width: 80px;
    margin-top: 6px;
  }

  &.v2-card--tall,
  &.v2-card--tall-min,
  &.v2-card--big {
    .card__content {
      min-height: 192px;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .card-top {
      height: 144px;
      padding: 0;
      border-radius: 8px 8px 0 0;
      background: $level-6;
    }

    .card-body {
      padding: 0 16px;
      width: 100%;
    }

    .bar-1 {
      display: none;
    }

    .bar-2{
      margin-top: 6px;
    }

    .bar-3 {
      width: 53.57%;
      height: 12px;
    }
  }
}

.v2-card--small {
  .card__content {
    min-height: 160px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .card-top {
    height: 104px;
    padding: 0;
    border-radius: 8px 8px 0 0;
    background: $level-6;
  }

  .card-body {
    padding: 0 16px;
    width: 100%;
  }

  .bar-1 {
    display: none;
  }

  .bar-2{
    margin-top: 6px;
  }

  .bar-3 {
    width: 53.57%;
    height: 12px;
  }
}

.v2-card--extra-small {
  .card__content {
    flex-direction: row;
    padding: 12px;
  }

  .card-top {
    height: 72px;
    width: 128px;
    border-radius: 8px;
    background: $level-6;
  }

  .card-body {
    padding: 0 0 0 16px;
    width: 47.16%;
  }

}

.v2-card--list {
  .card__content {
    flex-direction: row;
    padding: 12px;
  }

  .card-top {
    height: 72px;
    width: 128px;
    border-radius: 8px;
    background: $level-6;
  }

  .card-body {
    padding: 0 0 0 16px;
    width: 47.16%;
    flex-direction: row;
    align-items: center;
    display: flex;

    .bar{
      display: inline-block;
    }

    .bar-1 {
      width: 25%;
      margin-top: 0;
    }

    .bar-2 {
      width: 10%;
      height: 12px;
      margin-left: 5%;
      margin-top: 0;
    }

    .bar-3 {
      width: 10%;
      height: 12px;
      margin-left: 3%;
      margin-top: 0;
    }
  }

}

.v2-card--small-min,
.v2-card--thumbnail {
  .card__content {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 8px;
  }

  .card-top {
    height: 56px;
    padding: 0;
    border-radius: 8px;
    background: $level-6;
  }

  .card-body {
    padding: 0;
    width: 100%;
  }

  .bar-1 {
    display: none;
  }

  .bar-2{
    height: 12px;
    margin-top: 8px;
  }

  .bar-3 {
    width: 53.57%;
    height: 12px;
  }
}
