.desktop-md,
.desktop-small,
.ipad {
  .left-wrap:hover {

    .patient-directory-option {
      transform: translate(20px, 0);
      transition: all 1s ease-in-out;
      min-height: 36px !important;
      border: none !important;

      & > h2 {
        min-height: 36px;
        height: inherit !important;
      }

      button {
        height: 36px;
        display: flex;
        justify-content: space-between;
        padding: 0 24px 0 50px;
        align-items: center;

        p {
          margin: 0;
          font-size: 12px;
          color: #7E91A5;
          font-weight: 600;
          flex-grow: 2;
        }
      }
    }

    .patients-list-menu__options {
      padding-right: 54px;

      .patients-list-menu__option {
        max-width: unset;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0;
        padding: 2px 4px;
        cursor: pointer;
        border-radius: 4px;
        flex: 1 1;
        font-size: 12px;
        height: 32px;
        font-weight: normal;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &>div:last-child {
          margin-bottom: 12px;
        }

        &:hover {
          background-color: #eff1f4;
        }
      }
    }
  }

  .left-wrap {
    .patient-directory-option {
      transition: all 1s ease-in-out;
      & > h2 {
        display: none;
      }
    }

    .patients-list-menu__options {
      padding-left: 22px;
      padding-right: 16px;

      .patients-list-menu__option {
        margin: 8px 0;
        height: fit-content !important;
        max-width: 56px;
        & > p {
          white-space: break-spaces;
        }
      }
    }
  }
}

.patient-directory-option {
  min-height: 36px !important;
  border: none !important;

  & > h2 {
    min-height: 36px;
    height: inherit !important;
  }

  button {
    height: 36px;
    display: flex;
    justify-content: space-between;
    padding: 0 24px 0 50px;
    align-items: center;

    p {
      margin: 0;
      font-size: 12px;
      color: #7E91A5;
      font-weight: 600;
      flex-grow: 2;
    }
  }
}

.patients-list-menu__options {
  padding: 0 32px 0 60px;

  .patients-list-menu__option {
    max-width: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0 0.25rem 0;
    padding: 2px 4px;
    cursor: pointer;
    border-radius: 4px;
    flex: 1 1;
    font-size: 12px;
    height: 32px;
    font-weight: normal;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &>div:last-child {
      margin-bottom: 12px;
    }

    &:hover {
      background-color: #eff1f4;
    }
  }
}

