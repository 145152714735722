.system_upgrade {
  padding: 0;

  .container {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .system-upgrade-container {
    margin: auto;
    text-align: center;
  }

  .head-1 {
    margin: 24px 0 16px;

    @media(max-width: 500px) {
      font-size: 20px;
    }
  }
}

@media(max-width: 900px) and (max-height: 900px){
  .system-upgrade-container {
    svg {
      width: 179px;
      height: auto;
    }
  }
}