.schedule-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(53, 69, 84, 0.15), 0px 2px 4px rgba(53, 69, 84, 0.12);
    border-radius: 12px;
    margin-bottom: 16px;
    flex: none;
    order: 3;
    flex-grow: 0;
    width: 100%;

    .schedule-activity-bar{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 32px;

        height: 32px;
        background: #F4F7F9;
        border-radius: 12px 12px 0px 0px;

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;

        .schedule-activity-bar-content{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 8px;

            height: 16px;

            flex: none;
            order: 0;
            flex-grow: 1;

            .activity-text{
                height: 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #7E91A5;

                flex: none;
                order: 1;
                flex-grow: 0;

                .name,.time{
                    color: #4A749A;
                    font-weight: 600;
                }
            }
        }
    }

    .internal-class{
        background-color: #3c759e;
        .schedule-activity-bar-content, .activity-text, .name, .time{
            color: #fff !important;
        }

        .clinic-team-text{
            color: #fff !important;
            height: 16px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
        }
    }

    .schedule-post-body{
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 48px 32px 32px;
        gap: 16px;

        background: #FFFFFF;
        border-radius: 8px;

        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;

        .author-details{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            gap: 16px;
            height: 40px;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;

            .author-info-section{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;
                height: 40px;
                flex: none;
                order: 0;
                flex-grow: 0;

                .author-info{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 2px 0px 0px;
                    gap: 4px;
                    height: 38px;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
    
                    .author-name{
                        height: 16px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 16px;
                        color: #354554;
                        mix-blend-mode: normal;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
    
                    .scheduled-time{
                        height: 16px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #5D7893;
                        mix-blend-mode: normal;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                }
            }

            .d-inline-block{
                .comment-more{
                    .header-dropdown-list{
                        box-shadow: 0px 3px 6px rgba(53, 69, 84, 0.15), 0px 2px 4px rgba(53, 69, 84, 0.12);
                             
                        .schedule-dropdown-container{
                            width: 202px;
                            height: 166px;
                            border-radius: 8px;
                            z-index: 11;
                            position: absolute;
                            top: 0;
                            right: -200px;
                            background: #fff;
                      
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            padding: 0px;
                            isolation: isolate;
                            box-shadow: 0px 3px 6px rgba(53, 69, 84, 0.15), 0px 2px 4px rgba(53, 69, 84, 0.12);
                      
                            .time-block{
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              padding: 4px 16px;
                              isolation: isolate;
                              width: 202px;
                              height: 40px;
                              background: #FFFFFF;
                              border-radius: 8px 8px 0px 0px;
                              cursor: pointer;
                      
                              &:hover{
                                background: #EDEFF3;
                              }
                      
                              span{
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                display: flex;
                                align-items: center;
                                color: #5D7893;
                                gap: 9px;
                              }
                            }
                      
                            .custom{
                              color: #354554;
                            }
                        }  
                    }
                }

                .calendar-wrapper{
                    z-index: 11;
                    position: absolute;
                    top: 40px;
                    right: 0px;
                    box-shadow: 0 2px 4px 3px rgba(53, 69, 84, 0.12), 0 3px 6px 0px rgba(53, 69, 84, 0.15);
                    border-radius: 8px;
                    .react-calendar{
                      border: none;        
                      border-top-left-radius: 8px;
                      border-top-right-radius: 8px;
                      .react-calendar__viewContainer{
                        border-top: 1px solid #C9D1DA;
                        border-bottom: 1px solid #C9D1DA;
                      }
                    }
                    .time-block-wrapper{
                        border-bottom-right-radius: 8px;
                        border-bottom-left-radius: 8px;
                        background: #fff;
                        
                        .time-block{
                            height: 80px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap:24px;
                            
                        .time-block-container{
                            min-width: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap:24px;
                    
                            i{
                            cursor: pointer;
                            }
                    
                            .hour-block,.minute-block,.shift-block{
                            min-width: 20px;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                    
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            color: #354554;
                            }
                        }
                        }
                        .time-error{
                            text-align: center;
                            color: red;
                            width: 350px;
                        }
                    }
                }
            }
        }

        .post-body-content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px 40px 0px 56px;
            gap: 16px;
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            width: 100%;

            .comment-section{
                width: 100%;
            }

            .parse-comment-text{
                p{
                    font-size: 16px;
                    @media(min-width: 1920px){
                        font-size: 20px;
                    }
                }
            }

            .schedule-tiptap-editor{
                width: inherit;
                position: relative;
                
                .options{
                    .editor-actions{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .action-button{
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}