.analytics-modal  {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 520px;
    }
  }
  .modal-content {
    padding: 20px 25px;
    display: block;

    h4 {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 15px;
    }
    .ifill-os-close {
      position: absolute;
      top: 20px;
      right: 25px;
      cursor: pointer;
    }
    .page-heading-title {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 5px;
      display: block;
      line-height: 32px;
      padding: 0px 10px;
      color: $level-9;
      background-color: #29292e;
      border-radius: 4px;
      .ifill-os-chevron-up,
      .ifill-os-chevron-down {
        margin-left: 0;
        right : 10px;
        cursor: pointer;
        float: right;
        line-height: 32px;
        &:hover {
          color: $os-link;
        }
      }
    }
  }

  .doctors-list-result {
    font-size: 16px;
    font-weight: bold;
  }

  .doctors-list-ul {
    padding-left: 0;
    margin-left: 0;
    list-style: none;
    margin-top: 25px;
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
  }

  .doctors-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #646464;
    padding: 10px 0;
  }

  .avatar-link {
    display: inline-block;
    vertical-align: top;
    height: 36px;
    .avatar {
      font-size: 11px;
      text-align: center;
      text-indent: 0;
      letter-spacing: 0;
    }

    .icon-span {
      height: 16px;
      width: 16px;
      i {
        font-size: 0.65rem;
      }
    }
  }

  .degree-list {
    font-size: 12px;
    color: $level-9;
  }

  .doctors-info {
    flex: 1;
    display: flex;

    .doctors-name {
      max-width: unset;
      padding-right: 10px;
    }
  }

  .doctors-name {
    font-size: 14px;
    font-weight: $font-weight-bold;
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    max-width: calc(100% - 110px);

    .dr-name-link {
      color: $level-11;
    }

  }

  .doctors-date-span {
    color: #ccc;
    font-size: 12px;
    font-weight: $font-weight-normal;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .doctors-list-ul {
      padding-right: 10px;
    }
    .modal-content {
      h4 {
        font-size: 20px;
        padding-right: 20px;
      }
    }
  }
}
