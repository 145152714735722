.add-message-modal.add-to-board-modal {
  .add-message-title-row {
    margin-bottom: 20px;
  }

  .add-message-nav-block {
    margin-bottom: 0;
    padding-bottom: 15px;
  }

  .add-message-primary-nav {
    height: auto;;
    overflow: initial;

    &.object-header-bar {
      border-bottom: none;
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;

      .btn {
        &.add-board-btn {
          height: 34px;
          border: solid 1px #787878;
          padding: 0 24px;
          font-size: 12px;
          font-weight: $font-weight-bold;
          line-height: 34px;
          background: #787878;

          &:hover {
            color: #fff;
            background: #5a5a5a;
          }

          .plus {
            margin-left: 8px;
            font-size: 17px;
            font-weight: $font-weight-bold;
          }
        }
      }

      .assign-search-container {
        width: 50%;
      }
    }

    .invisible-input {
      width: 50%;
      position: relative;
      display: inline-flex;
      justify-content: flex-start;

      .search-icon-container {
        position: absolute;
        top: 2px;
        right: auto;
        left: 15px;
        font-size: 22px;
      }

      ::-webkit-input-placeholder {
        color: $textarea-placeholder;
      }
      ::-moz-placeholder {
        color: $textarea-placeholder;
      }
      :-ms-input-placeholder {
        color: $textarea-placeholder;
      }
      :-moz-placeholder {
        color: $textarea-placeholder;
      }

      input[type="search"] {
        width: 100%;
        height: 34px;
        padding-left: 40px;
        color: #fff !important;
        font-size: 15px;
        background: #1e1e1e !important;

        &:focus {
          border-bottom: 1px solid #4eaefa;
        }
      }

      .clear-input {
        right: 10px;
        i{
          cursor: pointer;
        }
      }
    }
  }

  .add-message-secondary-nav-block {
    padding-bottom: 10px;
    padding-top: 0;
    margin-bottom: 15px;

    .object-filter {
      padding-left: 0;
    }
  }

  .modal-body {

    .modal-body-container {
      height: calc(100% - 1.5rem);
    }

    &> .row {
      height: 100%;
    }
  }
}

.mobile-device {
  .add-to-message-modal.assign-overlay--show {
    height: 100%;

    .close-div {
      .ifill-os-close {
        top: 16px !important;
      }
    }
  }
  .add-to-board-modal {
    .add-message-nav-block {
      display: none;
    }

    .modal-body {
      height: 100% !important;

      .y-scroll {
        padding-top: 15px;
      }
    }

  }

  .assign-background {
    .assign-message {
      width: 90%;
    }
  }

  &.landscape {
    .assign-background {
      .assign-message {
        width: 70%;
      }
    }
  }
}

.ipad {
  &.portrait {
    .add-message-nav-block {
      padding-left: 0;
      padding-right: 0;
    }
    .assign-search-container {
      width: 60% !important;
    }
  }
}
