.new-conversation{
  .react-select-ortho-2{
    .os__menu-notice {
      text-align: left !important;
    }
    .Select-menu-outer {
      max-height: 330px;
    }

    .Select-menu {
      max-height: 328px;
    }

    .Select-control{
      height: 40px !important;
    }

    .Select-value{
      position: relative;
      padding-right: 20px;
    }

    .Select-value-icon{
      right: 8px;
      position: absolute;
      z-index: 9999;
      background: transparent !important;
      border-radius: 0;
      line-height: 18px;
      color: #fff;
      text-transform: uppercase;
      font-size: 20px;
      top: 6px;
      text-align: center;
      padding: 0;
      text-align: center;
      border: 0;

      &:hover {
        color: #fff;
      }
    }

    .Select-value{
      position: relative;
      padding: 0 34px 0 8px;
      background: $cl-link;
      margin-top: 3px;
      line-height: 25px;
      background-color: $os-link;
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: $os-link-hover;

      }
    }

    .Select-value-label {
      color: #fff !important;
    }
  }


  .media-body{
     @extend %media-body;
  }
}

.touch-support {
  .os__option:not(.os__option--is-disabled){
    &:hover {
      background: transparent!important;
      color: $level-11 !important;
    }

    &.os__option--is-disabled {
      color: rgba(255, 255, 255, 0.3) !important;
    }
  }
}

