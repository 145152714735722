.right-wrap{
  max-width: 368px;
}

.rightbar {
  background-color: $level-1;
  height: calc(100% - 60px);
  padding-top: 16px;
  overflow-y: auto;
  .accordion-button {
    &:hover {
      background-color: #EDEFF3;
    }
  }
}

.rt-ac-header {
  .accordion-button {
    padding: 0;
    display: flex;
    justify-content: space-between;
    min-height: 40px;

    .osbtn {
      .btn-icon-helper {
        font-size: 20px;
      }
    }
  }

  h5,
  span {
    @include fontstyle(16px, $font-weight-semibold, 16px);
    color: $level-8;
    margin: 0;

    @media screen and (min-width: 1920px) {
      @include fontstyle(20px, $font-weight-semibold, 28px);
    }
  }
}

.right-ac {
  .accordion-button {
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent;
    color: $level-8;
    display: flex;
    align-items: center;

    i {
      color: $level-6;
    }

    &:not(.collapsed) {
      box-shadow: none !important;
    }
  }

  .accordion-item {
    background-color: transparent;
    border: 0;

    &:first-of-type,
    &:last-of-type {
      border-radius: 0 !important;

      .accordion-button {
        &.collapsed {
          border-radius: 0 !important;
        }
      }
    }
  }

  .accordion-body {
    padding: 12px 0 0;

    .custom-class {
      p,a {
        font-size: 12px !important;
      }
    }
  }

  .patient-detail-widget{
    .accordion-body{
      .os-sm-person{
        max-height: fit-content;
        height: fit-content;
        padding-bottom: 1px;
      }
    }
  }
}

.task-list-item {
  @include fontstyle(12px, $font-weight-normal, 16px);
  color: $level-11;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $border-input-typed-button-2;

  label {
    font-weight: $font-weight-semibold;
    display: block;
  }

  .date-time {
    color: $level-9;
    line-height: 20px;
    display: inline-block;
    margin-right: 16px;
  }

  .hashtag-label {
    font-weight: $font-weight-semibold;
    color: $level-10;
    margin-bottom: 4px;
    margin-top: 8px;

    i {
      vertical-align: middle;
    }

    span {
      text-decoration: underline;
    }
  }

  .badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 8px;
    line-height: 1.5;
    vertical-align: middle;
  }
}


.task-page {
  .task-list-item {
    padding: 16px 24px;
    background-color: $level-1;
    box-shadow: $block-shadow;
    @include fontstyle(16px, $font-weight-normal, 1.5);
    border-radius: 12px;

    label {
      display: inline-block;
    }

    .date-time {
      @include fontstyle(12px, $font-weight-normal, 16px);
      padding-left: 8px;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}

.team-shared-files{
  ul{
    li{
      list-style: none;
      a {   
        padding: 8px;
        display: flex;
        gap: 6px;
        justify-content: flex-start;
        align-items: center;
        .team-avatar{
            div {
                width: 24px;
                height: 24px;
            }
        }
        .file_text {
          display: flex;
          gap: 6px;
          justify-content: flex-start;
          flex-direction: column;
          .category {
            font-size: 0.75rem;
            font-weight: 200;
          }
        }
    }
    }
  }
}