body {
  &:has(.integration-section-header) {
	overflow-y: scroll !important;
  }
}

.integration-section-header {
  align-items: center;
  h3, .form-group {
    margin: 0
  }
}

.integration-form {
  & > div {
    margin: 0;
    display: flex;
    align-items: center;
  }
  p {
    margin: auto 0;
    &:hover {
      cursor: pointer;
    }
  }
  .ifill-os-delete {
    position: relative;
    display: flex;
    align-items: center;
    zoom: 1.4;
    &:hover {
      cursor: pointer;
    }
  }
}