.company-section {
  padding-top: 24px;
  &.p-3 {
    padding-top: 0 !important;
  }

  .company-head-row {
    color: #fff;
    font-size: 10px;
    font-weight: $font-weight-bolder;
    text-transform: uppercase;
    letter-spacing: .7px;
  }
  .company-details-row {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .company-logo-block {
      width: 145px;
      height: auto;
      text-align: center;
    }
  }
  .company-logo-img {
    width: 110px;
    height: 110px;
    border-radius: 100%;
  }

  .company-details-section {
    width: calc(100% - 110px);
    padding-left: 10px;
    display: flex;
  }

  .get-in-touch {
    margin-top: 5px;
    .btn {
      &.btn-white {
        padding: 2px 26px 0 26px;

        i {
          margin-left: 5px;
          font-size: 9px;
        }

        &:hover {
          color: #fff;
          background: #000;
        }
      }
    }
  }

  .company-details-block {
    width: 45%;
    padding-top: 12px;
    padding-left: 0;
  }
  .company-title-row {
    color: #fff;
    font-size: 24px;
    font-weight: $font-weight-bold;
    line-height: 30px;
    white-space: nowrap;
  }
  .company-email-row {
    margin-top: 8px;
    color: rgba(255, 255, 255, .6);
    font-size: 20px;
  }

  .company-contact-button-row {
    margin-top: 10px;

    .btn {
      &.btn-white {
        padding: 5px 30px;
      }
    }
  }

  .company-buttons-block {
    width: 55%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .engagement__share {
      margin-right: 16px;
      i {
        font-size: 18px;
      }
    }

    &> div {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }

    .btn {
      padding: 5px 30px;
    }

    .btn-small-text {
      height: 34px;
    }
  }

  .company-actions-row {
    margin-top: 10px;

    .views-count {
      margin-left: 5px;
      font-size: 12px;
    }
    .company-like-block,
    .company-share-block {
      margin-left: 30px;
      display: inline-block;
    }

    .engagement {
      .engagement__views {
        display: flex;
        align-items: center;
      }

      .btn {
        padding: 0;
        position: relative;
        top: 2px;
        line-height: normal;
      }

      .btn-gray8 {
        background: transparent;
      }

      .btn-small-text {
        height: auto;
      }
    }
  }

  .user-profile-bio {
    padding-top: 42px;
    padding-bottom: 28px;

    &::after {
      display: none;
    }
  }
  .company-content-section {
    margin-top: 40px;
  }
  .company-image-block {
    padding-right: 80px;
  }
  .company-primary-image {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .25);
  }
  .company-primary-img {
    max-width: 100%;
    max-height: 420px;
  }
  .company-thumbs-block {
    width: 100%;
    margin-top: 36px;
    overflow: hidden;
  }
  .company-thumbs-row {
    width: auto;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .company-thumbs-holder {
    width: 160px;
    height: 120px;
    margin-left: 30px;
    display: inline-block;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .25);
     &:first-child {
      margin-left: 0;
    }
     &.selected {
      border: solid 1px #A0A0A0;
    }
  }
  .company-thumbs-img {
    width: 100%;
    height: 100%;
  }
  .company-text-block {
    margin-top: 70px;
     &:first-child {
      margin-top: 0;
    }
  }
  .company-text-block-title {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: $font-weight-bolder;
  }
  .company-text-block-content {
    color: #d2d2d2;
    font-size: 18px;
    line-height: 25px;
  }
  .company-text-block-link {
    color: #368BED;
    font-weight: $font-weight-bold;
    text-decoration: underline;
     &:hover {
      text-decoration: none;
    }
  }

  .tags-container {
    padding-top: 0;

    &.tags-cases {
      padding-top: 0;
      padding-right: 0;

      .tags-view-all {
        top: 5px;
      }
    }

    .btn {
      &.tags-view-all {
        padding: 5px 25px;
      }
    }
  }


  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    &.container {
      padding: 0 !important;
    }

    .tags-container {
      padding-top: 0;
    }

    .company-details-row {
      margin-top: 0;

      .company-logo-block {
        display: none;
      }
    }

    .company-actions-row,
    .company-buttons-block {
      display: none;
    }

    .company-details-section {
      width: 100%;
      padding-left: 0;
    }

    .company-details-block {
      width: 100%;
      height: 64px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .company-logo-img {
        width: 34px;
        height: 34px;
        border-radius: 100%;
      }

      .get-in-touch {
        .btn {
          &.btn-white {
            height: 24px;
            padding: 2px 10px 0 10px;
            font-size: 9px;
          }
        }
      }
    }

    .company-about-details {
      width: calc(100% - 34px);
      padding: 0 10px;
      display: flex;
      align-items: center;

      .company-title-row {
        width: calc(100% - 105px);
        font-size: 16px;
        font-weight: $font-weight-normal;
        line-height: 18px;
        white-space: normal;
      }

      .get-in-touch {
        margin-top: 0;
      }
    }

    .company-engagement-row {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .engagement {
        width: 44%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .engagement__views__holder,
        .engagement__like__holder {
          margin-right: 0;
          display: inline-block;
        }

        .engagement__views__holder {
          margin-left: -20px;
        }

        &> div {
          .ifill-os-views {
            position: relative;
            top: 2px;
            font-size: 17px;
          }

          .ifill-os-note {
            width: 15px;
          }

          span {
            font-size: 10px;
          }
        }
      }

      .share-block {
        .icon-share {
          position: relative;
          top: 2px;
        }
      }

      .btn {
        &.btn-small-text {
          height: auto;
          padding: 0;
          line-height: normal;
          background: transparent;
          text-indent: -9999px;
        }
      }
    }

    .user-profile-bio {
      width: 100%;
      border-top: solid 1px rgba(255, 255, 255, .15);
      padding: 15px;
      background: #1e1e1e;

      .my-3 {
        font-size: 12px;
        line-height: 16px;
      }

      .user-bio-link {
        font-size: 12px;
      }
    }

    .comapny-tab-section {
      &.mt-5 {
        margin-top: 0 !important;
      }

      .cards {
        &.row {
          padding: 20px;
        }
      }
    }

    .tools-cards-container {
      padding:0 20px 20px;

      .tool-cards-row {
        margin-top: 0;

        .cards {
          &.row {
            padding: 20px 0 0;
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {

    .company-title-row {
      font-size: 24px;
      white-space: normal;
    }

    .company-buttons-block {
      width: 70%;
      white-space: nowrap;
    }
  }
}

.company-tab-section {
  .px-tab-0 {
    padding-right: 0;
    padding-left: 0;
  }
}

.company-card-content {
  .tool-info {
    padding-top: 16px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .company-name {
    height: 40px;
    font-size: 14px;
    line-height: 15px;
    @include ellipsis-lines($font-size: 14px, $line-height: (18/14), $lines-to-show: 2);
    white-space: normal;
    min-height: 35px;
    margin: 10px 0 0;
  }

  .company-views {
    font-size: 12px;

    i {
      position: relative;
      top: 3px;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .company-name {
      margin: 5px 0;
    }
  }
}
