.os-lg-user {
  .doc-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar{
      margin-bottom: 24px;
      border-width: 5px;

      &.avatar-72 {
        @include avatar(120px);
        border-width: 6px;
      }
    }

  }
}


.os-gray-btn {
  color: #5c5c66;

  &[data-hover="true"]:hover,
  &:hover {
    background-color: transparent !important;
    color: $os-link !important;
  }
}


.icon-span{
  position: absolute;
  right: -4px;
  bottom: -4px;
  background-color: #5c5c66;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}

/*.os-card-v2 {
  .icon-span {
    display: flex;
  }
}*/

.os-lg-user {
  .doc-card-body {
    flex-grow: 1;
    padding: 0 14px;
  }
  .doc-info-actions {
    min-height: 45px;
  }

  .icon-span {
    @include icon-span(40px);
    font-size: 24px;
  }
}

.doc-info-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #5c5c66;
  padding-bottom: 4px;

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-center {
    justify-content: center;
  }
}

.doc-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}

.card-address {
  @include fontstyle(12px, normal, 1.5);
  color: $card-sm-text;
  margin-top: 12px;
  margin-bottom: 26px;
  @include multiline-truncate(2);
  overflow: hidden;
}

/*big card and min-width card */

.os-lg-user {
  @extend %os-lg-card;
  @extend %os-card-base;
  display: flex;
  flex-direction: column;
  padding:  32px 24px 10px;

  .card-dropdown-menu {
    z-index: 1;
    right: 16px;
  }

  .card-address {
    @include  multiline-truncate(2);
    min-height: 20px;
    margin-top: 16px;
  }

  .doc-card-footer {
    padding-top: 0;

    .osbtn-icon {
      padding-left: 24px !important;
    }
  }

  .doc-info {
    .clinic-text {
      @include text-truncate();
      max-width: 100%;
      font-size: 20px;
      line-height: 20px;
      overflow: hidden;
    }
  }

  .doc-info-actions {
    .osbtn {
      +.osbtn {
        margin-left: 8px;
      }
    }
  }

  &.user-304 {
    @extend %os-304;
    padding:  32px 16px 16px;

  }

  .general-user-detail {
    text-align: center;
  }
}

/*---small doc */
.os-sm-user {
  @extend %os-sm-card;
  @extend %os-card-base;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 8px;

  .doc-card-body {
    min-height: 50px;
    padding-bottom: 8px;
  }

  .doc-info {
    .clinic-text {
      padding-left: 80px;
      padding-right: 28px;
      @include multiline-truncate(2);
      max-width: 100%;
      overflow: hidden;
      @extend %card-heading;
    }

    .avatar{
      border-width: 3px;

      &.avatar-64 {
        @include avatar(64px);
        position: absolute;
        left: 24px;
        top: 24px;

        .icon-span {
          @include icon-span(28px);
          font-size: 15px;
        }
      }
    }
  }

  .doc-info-actions {
    border-bottom:0;
    padding-left: 63px;
    color: $level-9;
    line-height: 10px;
    font-size: 12px;
    justify-content: flex-start;
    flex-wrap: wrap;

    .btn-normal{
      padding-right: 4px;
      white-space: nowrap;

      i{
        padding-right: 8px;

        &.ifill-os-connection {
          padding-right: 6px;
        }
      }

      + .btn-normal {
        padding-left: 4px;
      }
    }
  }

  .card-address {
    display: none;
  }

  .academy-address {
    @include fontstyle(12px, normal, 1.5);
    padding-left: 80px;
    padding-bottom: 0;
    @include multiline-truncate(2);
    overflow: hidden;
  }
  .doc-card-footer {
    padding-top: 0;
    margin-top: auto;
  }
  &.user-240 {
    @extend %os-240;

    .doc-info {
      .clinic-text {
        padding-left: 48px;
      }
      .avatar{
        &.avatar-64 {
          @include avatar(40px);

          .icon-span {
           @include icon-span(18px);
          }
        }
      }
    }

    .doc-card-body {
      min-height: 50px;
      margin-bottom: 10px;
      display: block;
    }

    .academy-address {
      padding-left: 48px;
      @include multiline-truncate(2);
      overflow: hidden;
      padding-bottom: 0;
      line-height: 1.33;
      min-height: 30px;
    }

    .doc-info-actions {
      padding-left: 0;
      flex-wrap: wrap;
      font-size: 0.71rem;

      .osbtn {
        padding-right: 10px;
      }

      .btn-normal{
        padding-right: 4px;
        font-size: 0.71rem;

        + .btn-normal {
          padding-left: 4px;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .pr-6 {
        padding-right: 4px;
      }
    }

    .doc-card-footer {
      padding-top: 0;
    }
  }
}

.doctor-suggest-modal {
  .os-sm-user {
    .osbtn {
      margin-right: 4px;

      + .osbtn {
        margin-left: 4px;
      }
    }
  }
}

/*extra-small-min-card*/
.extra-small-min-card {
  .os-sm-user {
    @extend %os-extra-sm-card;
    padding: 16px 40px 16px 88px;

    .avatar {
      left: 12px !important;
      top: 16px !important;
    }

    .card-dropdown-menu {
      .os-gray-btn {
        width: 100%;
        color: $level-11;
        padding-left: 40px;
        display: block;
        text-align: left;

        .btn-icon-helper {
          left: 16px;
          transform: translateY(-50%);
        }
      }
    }

    .doc-info {
      .clinic-text,
      .academy-address {
        padding-left: 0;
        padding-right: 0;
        display: block;
        @include text-truncate();
      }

      .academy-address {
        padding-top: 4px;
        padding-bottom: 0;
        line-height: 1.33;
      }
    }

    .doc-card-footer {
      @include extra-small-card-footer;

      .osbtn-icon {
        padding: 0 8px;
        color: #5c5c66;

        .btn-icon-helper {
          font-size: 24px;
        }

        &:hover {
          color: $os-link;
        }
      }
    }
  }
}
