html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  * {
      user-drag: none;
      -webkit-user-drag: none;
      -moz-user-drag: none;
  }

  &.element-drag-active {
    .mention-textarea-wrap__input {
      z-index: 0;
    }
  }

  &.hide-footer {
    #footer {
      display: none;
    }
  }

  &.hide-floater {
    .floater-message-wrapper {
      display: none;
    }
  }

  .mention-textarea-wrap__input {
    z-index: 1;
  }

  #footer {
    opacity: 1;
  }
}

.page-overflow-hidden {
  overflow: hidden;

  body {
    overflow: inherit;
  }
}

.position-unset {
  position: unset;
}

.position-unset-imp {
  position: unset !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-color {
  color: #fff;
}

:focus {
  outline: none;
}

.text-right {
  text-align: right;
}

.ipad {
  .navbar {
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
  }

  .checkbox-comment-list {
    overflow-x: hidden;
  }

  .login-container .login-modal {
    max-width: 78%
  }
  .account-plan-card{
    &.signup-card  {
      overflow-x: hidden;
      flex-wrap: wrap;
      max-width: 100%;
      .user-active-subscription-details {
        width: 295px;
      }
    }
  }

}

body {
  min-height: 100%;
  height: auto;
  padding-bottom: 50px;
  position: relative;
  color: $level-11;
  background-color: $body-bg;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: $body-bg;
  }

  &.workspace-body{
    overflow-x: hidden;
  }

  &.no-scroll {
    max-height: none;
  }

  &.tools {
    padding-bottom: 36px;
  }

  &.cases {
    #root {
      z-index: 2;
    }

    &.modal-open {
      padding-bottom: 0;
    }

    .with-old-case-card {
      .back-to-curriculum  {
        display: none;
      }
    }
  }

  &.conversations {
    padding-bottom: 0;

    &.chat-detail,
    &.new-chat {
      .layout {
        &.no-scroll {
          min-height: -webkit-fill-available;
        }
      }

      .footer {
        z-index: 0;
      }
    }

    #root {
      z-index: 2;
    }
  }

  &.unauthenticated {
    padding-bottom: 0;

    .layout {
      .unauth-container {
        &:empty {
          display: none;
        }

      }
    }

    #footer,
    .footer {
      display: none;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    padding-bottom: 0;

    &.conversations {
      &.chat-detail {
        background: #121214;
      }
    }

    &.tools {
      padding-bottom: 100px;

      .layout {
        padding-bottom: 0;
      }
    }

    &::after {
      display: none;
    }

    &.account_settings {
      #toast-container {
        .toast-close-button {
          top: 32px;
          right: 10px;
        }
      }
    }
  }


  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    padding-bottom: 36px;

    &.tools {
      padding-bottom: 36px;
    }
  }

  #footer {
    opacity: 1;
  }
}


.empty-hide {
  &:empty {
    display: none;
  }
}

#root {
  position: relative;
  z-index: 2;
}

#root,
#root > div {
  height: 100%;
}

.slick-slider .slick-list * {
  outline: 0;
}

a {
  color: $os-link;

  &:hover{
    text-decoration: none;
    color: $os-link-hover;
  }

  &.white-link {
    color: $os-link;

    &.hover {
      text-decoration: underline;
      color: $os-link;
    }

    &.white-link-blue:hover {
      color: $os-link;
      text-decoration: underline;
    }
  }
}

.mw-100 {
  min-width: 100px;
}

.mw-150 {
  min-width: 150px;
}

.mw-168 {
  min-width: 168px;
}

.mr-10 {
  margin-right: 10px;
}

.mr--16 {
  margin-right: -16px;
}

.mr--10 {
  margin-right: -10px;
}

.mr--20 {
  margin-right: -20px;
}

.mr--32 {
  margin-right: -32px;
}

.mw-205 {
  min-width: 205px !important;
}

.attention {
  color: $os-secondry;
}

.opacity-0 {
  opacity: 0;
}

.event-none {
  pointer-events: none;
}

.no-touch-support {
  .link-hover-elem {
    transition: opacity 500ms;
    opacity: 1;

    &:hover {
      opacity: .8;
    }
  }

  .hover-underline {
    position: relative;

    &:not(.disabled) {
      cursor: pointer;
    }

    &::after {
      content: none;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 250ms ease;
      position: absolute;
      background: $os-link-hover;
    }
  }
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.extrabold {
  font-weight: 700;
}

.fwblack {
  font-weight: $font-weight-black;
}
.bold {
  font-weight: bold;
}

.fs-12 {
  font-size: .75rem;
}

.fs-14 {
  font-size: .875rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-32 {
  font-size: 2rem;
}

.wc-60 {
  color: #fff-60;
}

.wc-90 {
  color: #fff-90;
}

.blue-primary {
  color: #4294e5;
}

.bg-blue-primary {
  background: #48acfd;
}

.blue-secondry {
  color: #4eaefa;
}

.bg-blue-secondry {
  background: #4eaefa;
}

.blue3 {
  color: #48acfd;
}

.blue-hover-change {
  transition: color .3s ease;

  &:hover {
    color: #4294e5;
  }
}

.bg-blue3 {
  background: #48acfd;
}

.pointer,
.ifill-os-clear {
  cursor: pointer;
}

.pointer-not-allowed {
  cursor: not-allowed !important;
}

.pointer-default {
  cursor: default;
}

.pointer-none {
  pointer-events: none;
}

.v-align-top {
  vertical-align: top;
}

.v-align-middle {
  vertical-align: middle;
}

.pre-wrap {
  white-space: pre-wrap;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.loading-text {
    color: $level-11;
    z-index: 1;
  }
}

.list-disc {
  li {
    list-style-type: disc;
  }
}

.layout {
  min-height: 100%;

  > .container {
    height: 100%;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .body-content-holder {
      position: relative;
      z-index: 8;
    }
  }
}

body{
  &.spaces {
    .layout {
      &.gradient-white {
        /*background: linear-gradient(180deg, #1e1e1e 0px, #646464 380px, #ffffff 800px)*/
      }
    }
  }

  &.cases {
    .layout {
      &.gradient-white {
        /*background: linear-gradient(180deg, #1e1e1e 0px, #646464 700px, #ffffff 1000px)*/
      }
    }
  }
}

.hidden {
  display: none !important;
}

.hidden-drag-active {
  display: inline;
}

.react-scrollbar-default {
  width: 100%;
  height: 250px;
  padding-right: 29px;
  .-reactjs-scrollbar-track {
    background-color: rgba(#fff, 0.1);
    width: 12px;
    border-radius: 6px;
  }
  .-reactjs-scrollbar-thumb {
    background-color: #5a5a5a;
    width: 12px;
    border-radius: 6px;
  }
}

.react-slidedown.custom-slidedown {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}

.d-inline-block {
  display: inline-block !important;
}

.home-loader {
  width: 100%;
  margin-top: 20px;
  color: #b9b9b9;
  font-family: serif;
  font-size: 60px;
  text-align: center;

  &:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}

.visible-mobile {
  display: none;
}

.for-mobile {
  display: none;
}

.custom-file-field {
  font-weight: $font-weight-bold;
  color: $os-link;
  line-height: 40px;
  padding: 0 24px;
  border: 0;
  text-transform: uppercase;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: color 500ms ease, background 200ms ease;
  will-change: color, background;
  min-height: 40px;
  min-width: 40px;
  position: relative;
  overflow: hidden;

  &:focus, &:active, &.active {
    color: $os-link;
    background: $os-link-hover
    $os-link-hover;
  }

  &.disabled {
    color: $level-6 !important;
    border-color: transparent !important;
    pointer-events: none;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    height: 100%;
    width: 200%;
    cursor: pointer;
  }
}

.mobile-device {
  .transgif {
    display: none !important;
  }

  .mention-all-m {
    line-height: 1.5;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .hidden-mobile {
    display: none !important;
  }

  .visible-mobile {
    display: inline-block;
  }

  .for-mobile {
    display: block;
  }

  .btn-black-mobile {
    min-width: 60px;
    height: 24px;

    &.btn-small {
      height: 19px;
      font-size: 7px;
      line-height: 18px;
    }
  }

  .mobile-clear-btn {
    top: -8px;
  }

  .sub-nav-background {
    padding: 0;

    &> .container {
      padding-right: 0;
      padding-left: 0;
    }

    .object-header-bar {
      margin: 0;
    }

    .object-filter {
      width: 100%;
      height: 38px;
      margin: 0;
      padding: 0 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: auto;

      .list-inline-item {
        height: 38px;
        margin: 0 15px 0 0 !important;
        white-space: nowrap;

        .communication-unread-count {
          margin-left: 3px;
          font-size: 11px;
        }

        &.object-filter-active {
          &::after {
            bottom: 0;
            box-shadow: 0 0 0 0 transparent;
          }
        }

        a {
          margin: 0 !important;
          font-size: 11px;
        }
      }
    }
  }

  .mobile-filter-section {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1021;
    background: #121214;

    &.tags-container {
      padding-top: 0;
      position: fixed;
    }

    .tags-container {
      height: 100%;
      padding-top: 0 !important;

      &.tags-cases {
        padding-right: 0;
      }
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .tags-view-all {
      display: none;
    }

    .filters-header {
      width: 100%;
      height: 64px;
      padding: 24px 30px 0;
      position: relative;
      font-size: 18px;
      background: #1c1c1f;

      .ifill-os-filter {
        margin-right: 26px;
      }

      .filters-header-text {
        position: relative;
        top: -3px;
      }

      .os-header-btn {
        position: absolute;
        top: 14px;
        right: 16px;
        padding: 0;
        cursor: pointer;
        font-size: 12px;

        .ifill-os-close {
          font-size: inherit !important;
        }
      }
    }

    .filter-tags-block {
      width: 100%;
      min-height: 60px;
      padding: 10px 15px 0;
      background: transparent;

      .tag-item {
        margin: 5px 3px;
        padding: 5px 12px;
        font-size: 12px;
        background-color: #121214;

        &.btn {
          height: 28px;
        }

        .tag-count {
          width: 15px;
          height: 15px;
          padding-top: 2px;
          top: -7px;
          right: -6px;
          font-size: 8px;
          line-height: 11px;
        }

        .filter-results-arrow-block,
        .filter-results-ok-icon-block,
        .tags-clear-btn {
          display: none;
        }
      }

      .btn {
        &.tag-buttons {
          display: none;
        }
      }

      .tag-sap {
        height: 15px;
        padding: 0 10px;
        display: inline-block;
        font-size: 8px;

        .tag-sap-img {
          max-width: 5px;
        }
      }

      .filter-tags-text-block {
        width: 100%;
        padding-top: 8px;
        position: relative;
        font-size: 12px;

        b {
          margin-right: 3px;
          font-size: 15px;
          font-weight: $font-weight-bold;
        }

        .btn-black-mobile {
          line-height: 22px;
        }
      }
    }

    .filter-results-block {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background: #1c1c1f;
      -webkit-overflow-scrolling: touch;

      .filter-results-block-inner {
        height: calc(100% + 1px);
      }

      .tag-sap {
        display: none;
      }

      .tags-selected {
        width: 100%;
        margin-bottom: 0;

        .btn {
          &.tag-item {
            .tags-clear-btn {
              display: inline-block;
            }
          }
        }
      }

      .btn {
        &.tag-item,
        &.tag-buttons {
          width: 100%;
          height: 48px;
          margin: 0;
          border-bottom: solid 1px $border-button-1;
          padding: 0 110px 0 50px;
          display: block;
          font-weight: $font-weight-normal;
          text-align: left;
          background: transparent;
          border-radius: 0;

          .tag-text {
            line-height: 46px;
            display: inline-block;
            font-size: 12px;
          }

          .filter-results-ok-icon-block,
          .filter-results-arrow-block {
            display: none;
          }
        }

        &.tag-item {
          .filter-results-arrow-block {
            display: block;
          }
        }

        &.tag-buttons {
          top: 0;

          &.selected {
            color: #fff;

            .filter-results-ok-icon-block {
              display: inline-block;
              color: $os-link;
            }
          }
        }
      }

      .filter-results-primary-row {
        width: 100%;
        min-height: 48px;
        border-bottom: solid 1px #000;

        &.secondary-row {
          padding: 0 50px 0 38px;
          position: relative;
        }
      }

      .tag-count {
        width: 15px;
        height: 15px;
        padding-top: 2px;
        display: inline-block;
        position: absolute;
        top: 15px;
        left: 20px;
        font-size: 8px;
        line-height: 10px;
        font-weight: $font-weight-bold;
        text-align: center;
        border-radius: 100%;
      }

      .filter-results-name-block {
        width: calc(60% - 5px);
        padding-top: 15px;
        display: inline-block;
        font-size: 13px;
        vertical-align: top;
      }

      .filter-results-btn-block {
        width: calc(20% - 5px);
        padding-top: 10px;
        display: inline-block;
        vertical-align: top;

        .btn-black-mobile {
          position: relative;
          top: -2px;
        }
      }

      .tags-clear-btn {
        position: absolute;
        top: 50%;
        right: 10px;
        padding: 0;

        &.mr-30 {
          right: 30px;
        }
        margin-top: -16px;
      }

      .filter-results-arrow-block {
        width: calc(5% - 5px);
        display: inline-block;
        position: absolute;
        top: 12px;
        right: 20px;
        text-align: right;
        vertical-align: top;

        .ifill-os-chevron-right{
          width: 5px;
          height: 8px;
          font-size: 8px;
          color: #fff;
        }
      }

      .filter-results-secondary-text {
        padding-top: 14px;
        color: #fff;
        font-size: 13px;
        display: block;
      }

      .filter-results-ok-icon-block {
        position: absolute;
        top: 8px;
        left: 22px;
      }
    }

    .filter-footer {
      width: 100%;
      height: 140px;
      padding-top: 35px;
      position: absolute;
      bottom: 0;
      left: 0;

      .filter-footer-text {
        color: $level-9;
        font-size: 12px;
        text-transform: uppercase;
      }

      .filter-footer-btn-row {
        padding-top: 20px;

        .btn-black-mobile {
          line-height: 22px;
        }
      }
    }
  }

  .row {
    &.no-margin-m {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.sticky-top {
  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    z-index: 99;
  }
}

.add-button-dropdown {
  width: 180px;
  margin-left: -90px;
  padding: 5px 10px 5px 30px;
  position: absolute;
  top: 48px;
  left: 50%;
  z-index: 9;
  background: #212121;
  border-radius: 5px;

  &::before,
  &::after {
    content: '';
    position: absolute;
  }

  &::before {
    width: 0;
    height: 0;
    margin-left: -5px;
    border-right: solid 10px transparent;
    border-left: solid 10px transparent;
    border-bottom: solid 10px #212121;
    top: -10px;
    left: 50%;
  }

  &::after {
    right: 0;
    left: 0;
    top: -6px;
    height: 12px;
  }

  .add-button-dropdown-row {
    &:first-child {
      .add-button-dropdown-link {
        &::before {
          display: none;
        }
      }
    }
  }

  .add-button-dropdown-link {
    width: 100%;
    padding: 10px 0;
    display: table;
    position: relative;
    color: #fff;
    font-size: 16px;
    font-weight: $font-weight-normal;
    text-align: left;
    text-transform: none;

    &::before {
      content: '';
      width: 70%;
      height: 1px;
      position: absolute;
      top: 0;
      right: 0;
      background: #363636;
    }
  }

  .add-button-icon-block {
    width: 30%;
    float: left;

    i {
      margin-left: 0 !important;
    }

    &.eye-pt-5 {
      padding-top: 5px;
    }
  }

  .add-button-dropdown-text {
    width: 70%;
    float: left;
  }
}

.joyride {
  .joyride-hole  {
    border: 2px solid $lt-orange;
    border-radius: 4px;
  }
  // .joyride-overlay {
  //   background-color: rgba(18,12,20,0.8);
  // }
  .joyride-tooltip {
    background-color: #29292e !important;
    width: 360px;
    border-radius: 8px;
    padding: 24px;
    @media (max-width: 768px) {
      width: 300px;
    }

    background-color: $joyride-tooltip-bg-color;
    @include box-shadow(0px 2px 2px 0px rgba(0,0,0,0.25));

    &::before {
      content: none;
      position: absolute;
      border: solid 18px transparent;
    }

    &.bottom {
      &::before {
        content: none;
        margin-left: -9px;
        bottom: 100%;
        left: 50%;
        border-bottom-color: $joyride-tooltip-bg-color;
      }
    }

    &.top {
      &::before {
        margin-left: -9px;
        top: 100%;
        left: 50%;
        border-top-color: $joyride-tooltip-bg-color;
      }
    }

    &.left {
      &::before {
        top: calc(50% - 18px);
        left: 100%;
        border-left-color: $joyride-tooltip-bg-color;
      }
    }

    &.right {
      &::before {
        top: calc(50% - 18px);
        right: 100%;
        border-right-color: $joyride-tooltip-bg-color;
      }
    }
  }


  .joyride-tooltip__header {
    font-size: 16px;
    font-weight: 600;
    border-bottom: 0;
    padding-bottom: 0;
    color: $heading;
  }

  .joyride-tooltip__main {
    padding: 16px 0 32px;
    line-height: 1.5;
    color: $level-11;
  }

  .joyride-overlay {
    z-index: 10000; // Note: 1 more than .ortho-help-section
  }

  .joyride-tooltip {
    .joyride-tooltip__triangle {
      display: none;
    }
  }

  .joyride-beacon {
    display: none;
  }

  .joyride-tooltip__button--secondary {
    color: $level-11;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.67;
    letter-spacing: 0.5px;
    min-width: 83px;
  }

  .joyride-tooltip__button--primary {
    border-radius: 4px;
    background-color: $btn-primary;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.67;
    font-weight: bold;
    min-width: 83px;
    letter-spacing: 0.5px;
    color: $btn-primary-color;
  }
}


.no-touch-support {
  .joyride-tooltip__button--secondary {
    background: #222226;
    &:hover {
      color: $os-link;
    }
  }

  .joyride-tooltip__button--primary {
    &:hover {
      background-color: $btn-primary-hover;
    }
  }

}

html {
  &.joyride-started {
    body {
      .joyride-overlay {
        top: -64px;
      }

      &.spaces {
        .with-clv {
          .joyride-overlay {
            top: -224px;
          }
        }
      }
    }
    .joyride {
      ~ .container {
        .cards {
          [class^="col-"]:first-child {
            z-index: 999;
          }
        }
      }

      &:empty {
        ~ .container {
          .cards {
            [class^="col-"]:first-child {
              z-index: 0;
            }
          }
        }
      }

      + .collection-page {
        .head-sticky {
          z-index: 0;
        }
      }
    }

    .cs-sort-btn-group {
      pointer-events: auto !important;
      opacity: 1 !important;
    }
  }
}

.modal-backdrop {
  &.dark {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

body {
  &.unauthenticated {
    &.pending-view, &.restricted-view {
      .navbar {
        display: none !important;
      }
    }
    .policy-not-accepted {
      .form-errors {
        bottom: 78px;
        left: 75px;
        text-align: center;
      }
      .subscription-policy {
        padding: 30px;

        .signup-card-group {
          .form-error {
            color: $lt-red;
          }
        }

        .modal-header {
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left;
          flex-direction: column;
          padding: 10px;
          i {
            display: none;
          }
        }
        .signup-card-group {
          .custom-checkbox {
            label {
              padding-left: 15px;
            }
            .ifill-os-checkmark-default-white {
              left: 0px;
            }
          }
        }
        .modal-body {
          max-height: 322px;
          overflow-y: scroll;
          padding: 20px 15px;
          margin-bottom: 20px;
        }

        .modal-footer {
          border: 0;

          .btn-signup {
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }

      @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
        width: 95%;
        height: 550px;

        .signup-account-details-card {
          &.signup-mobile-auth-card {
            &.singup-credentials-card {
              .user-policy {
                .modal-header {
                  padding-bottom: 20px;
                }

                .modal-body {
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }

      .subscription-policy {
        &.user-policy {
          .modal-footer {
            padding-top: 25px !important;
          }
        }
      }
    }
  }
}

.card-hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .35);

  .btn {
    &:hover {
      color: #000;
    }
  }
}

.notification-banner {
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: $font-weight-bold;
  text-align: center;
  line-height: 50px;
  background: #000;

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 11px;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    font-size: 10px;
    height: 45px;
    word-break: break-word;
    line-height: 12px;
    padding: 10px;
  }

}

.bg-black {
  background-color: #000;
}

.br-radius-10 {
  border-radius: 10px;
}

.link-text {
  cursor: pointer;
}

.link-primary {
  color: $os-link
}

.underline {
  text-decoration: underline;
}

.password-hint {
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  margin-top: 15px;
  display: block;
  color: $level-9;
}

.download-page {
  min-height: calc(100vh - 125px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 70%;
  margin: 0px auto;
  .download-text {
    background-color: #000;
    padding: 61px;
    border-radius: 14px;
    span {
      color: #48acfd;
      cursor: pointer;
    }
  }
}

.ifill-os-submissions  {
  font-size: 18px;
}

.joyride-tooltip__close {
  font-family: 'fill-os-icon';
  background-image: none;
  color: #fff;
  font-size: 15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  &:before {
    content: "\e99d";
  }

  &:hover {
    background-color: rgba(72, 172, 253, 0.2);
    color: $os-link;
    border-radius: 4px;
  }
}

.row {
  &.row-col-space-16,
  .row-col-space-16 {
    margin-right: -$os-gutter-space / 1.875;
    margin-left: -$os-gutter-space / 1.875;

    > [class^="col-"] {
        padding-right: $os-gutter-space / 1.875;
        padding-left: $os-gutter-space / 1.875;
      }
  }

  &.row-col-space-8,
  .row-col-space-8 {
    margin-right: -4px;
    margin-left: -4px;

    > [class^="col-"] {
        padding-right: 4px;
        padding-left: 4px;
      }
  }
}

.row-col-space-24, .row-24 {
  margin-right: -12px;
  margin-left: -12px;

  > [class^="col-"] {
      padding-right: 12px;
      padding-left: 12px;
    }
}

.cursor-pointer {
  cursor: pointer;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

i {
  font-style: normal;
}

.ltr-spac-5 {
  letter-spacing: .5px;
}
.font-weight-bold {
  font-weight: $font-weight-bold;
}
.btn-os-blue {
  .icon-plus {
    font-size: 10px;
    margin-left: 8px;
  }
}

.case-image-not-available {
  padding: .5rem 1.56rem .5rem 1.56rem;
  text-align: center;
  margin: 0px auto;
  max-width: 80% !important;
  @media screen and (max-width: 1366px) {
    max-width: 75% !important;
  }

  @media screen and (max-width: 1024px) {
    max-width: 84% !important;
  }

  @media screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}

.mobile-show-portrait {
  display: none;
}

.mobile-device {
  .page-overflow-hidden {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .mobile-filter-section {
    .mobile-tag-filter-container .tag-item  {
      max-width: 100%;
      overflow: unset;
      text-overflow: unset;
      white-space: normal;
    }
  }

  .mobile-hide {
    display: none;
  }

  &.landscape {
    .mobile-hide-landscape {
      display: none;
    }

    &.keyboard-open {
      .mobile-nav-bar {
        display: none;
      }

      .head-sticky  {
        top: 0;
        position: relative;

        .sub-nav-background {
          padding-top: 0;
        }
      }
    }
  }

  &.portrait {
    .mobile-hide-portrait {
      display: none;
    }

    .mobile-show-portrait {
      display: initial;
    }
  }

  .head-sticky.for-mobile {
    .sub-nav-background {
      background: transparent;
    }
  }

  .sub-nav-background {
    padding: 0;
  }

  .tags-container {
    background-color: #1c1c1f;
  }

  .navbar {
    + .loader-active {
        min-height: calc(100vh - 164px);
        height: auto;

        .loader-inner {
          margin-top : 0;
        }
      }
  }

  .pp-container {
    padding-top: 60px;
  }

}

.pp-container {
  text-align: justify;

  tr td:first-child {
    text-align: left;
  }

  li + li {
    padding-top: 10px;
  }
}

.modal {
  .ifill-os-close {
    cursor: pointer;
  }
}

.a-link {
  font-size: 16px;
  font-weight: $link-weight;
  color: $os-link;

  &:hover {
    text-decoration: underline;
  }

  &.fs-12 {
    font-size: 12px;
  }

  &.fs-14s {
    font-size: 14spx;
  }

  &.fw-light {
    font-weight: $font-weight-light;
  }
}

.full-width {
  width: 100%;
}

.lh-1 {
  line-height: 1;
}

.lh-15 {
  line-height: 1.5;
}

.lh-12 {
  line-height: 1.2;
}

.lh-133 {
  line-height: 1.33;
}

.lh-14 {
  line-height: 1.4;
}

.lh-normal {
  line-height: normal;
}

.mt-10px {
  margin-top: 10px;
}

.text-transform-none {
  text-transform: none;
}

.w-space-nowrap {
  white-space: nowrap;
}

.user-profile-bio {
  .interweave {
    ~ .a-link {
        white-space: nowrap;
      }

    + .a-link {
      margin-left: 5px;
    }

    + .desc-ellipsis + .a-link {
        margin-left: 1px;
      }
  }


}

html:not(.mobile-device) {
  .mobile-show {
    display: none;
  }
}

.no-touch-support {
  .a-link{
    &:hover {
      text-decoration: underline !important;
      color: $btn-link-hover;
    }
  }

  .white-link {
    &:hover {
      color: $os-link !important;
    }
  }

  .hover-underline {
    &:hover {
      color: $os-link-hover;
      text-decoration: underline;

      &::after {
        opacity: 1;
        will-change: opacity;
      }
    }
  }

  .category-secondary-content-tag {
    .ifill-os-checkmark-round-default:hover {
      &:before {
        color: $os-link;
      }
    }
  }
  .upload-file-div .ifill-os-close:hover {
    color: $os-link;
  }

  .custom-file-field:hover {
    background-color: rgba(72, 172, 253, 0.2);
    color: $os-link;
  }
  .modal{
    .ifill-os-close:not(.os-close-link) {
      line-height: 40px;
      width: 40px;
      text-align: center;
      font-weight: bold;

      &:not(.no-hover):hover {
        background-color: rgba(72, 172, 253, 0.2);
        border-radius: 4px;
        color: $os-link;
        width: 40px;
        height: 40px;
      }
    }
  }
}

.ipad {
  .modal {
    .modal-close {
      top: 18px;
      right: 40px;
    }
    .ifill-os-close {
      line-height: 40px;
      width: 40px;
      height: 40px;
      text-align: center;
    }
  }

  @media(width: 768px) {
    .col-ipad768-50 {
      min-width: 50%;
      flex: 0 0 50%;
    }
  }
}

.error-field {
  border: 1px solid $lt-red !important;
}

.chart-bar-animate {
  position: relative;
  height: 12px;
  display: inline-block;
  transition: all .90s ease-in-out;
}

.update-password.login-bg-require {
  z-index: 1;

  + .modal {
      z-index: 1
    }
}

.tooltip-copy {
  background-color: $block;
  box-shadow: $block-shadow-m;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  padding: 4px 12px;
  top: 0px;
  font-size: 12px;
  right: 161px;
  line-height: 26px;
  font-weight:$font-weight-normal;

  &:after {
    content: '';
    border: 8px solid transparent;
    border-top-color: $block;
    border-bottom: 0;
    top: 100%;
    position: absolute;
    left: 50%;
    margin-left: -8px;
  }

  &.without-arrow {
    bottom: 48px;
    top: auto;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    max-width: 137px;
    white-space: nowrap;
    background-color: $level-11;
    box-shadow: 0 2px 6px 4px rgba(18, 18, 20, 0.2), 0 1px 2px 0 rgba(18, 18, 20, 0.2);
    border: 0;
    padding: 6px 16px;
    line-height: 1;
    cursor: pointer;
    border-radius: 8px;
    color: $level-1;

    &:after {
      content: none;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}

.mobile-device {
  &.landscape {
    .tooltip-copy {
      right: 194px;
    }
  }

  .mobile-tag-filter-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 140px;
    overflow: hidden;

    .filter-results-block {
      height: auto;
    }
  }
}

.ipad, .mobile-device {
  .ElementsApp {
    input {
      display: inline-block !important;
    }
  }
}

.keyboard-ui-additional-bar-visible {
  &.ipad {
    .new-conversation .conversation-input-section,
    .conversation-messages .conversation-input-section {
      margin-bottom: 40px;
    }

    .floater-message-holder {
      margin-bottom: 33px;
    }
  }
}

@media (max-width: 700px) {
  .pp-table {
    width: 600px;
  }
}

.text-upcase {
  text-transform: uppercase;
}

.mobile-device {
  .users {
    .activity-container {
      padding-top: 27px !important;
    }
  }

  &.landscape {
    .pulses {
      padding-top: 20px;

      .sub-nav-background {
        background: transparent;
        padding-top: 0px;

        .col-12 {
          padding: 0;
        }

        .page-heading-title {
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 1399px) {
  .show-hd-only {
    display: none;
  }
}

.landscape-restriction {
  display: none;
  z-index: 2000;
  position: fixed;
  font-size: 4vw;
  padding: 20px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.mobile-device:not(.iphone) {
  &.portrait {
    .landscape-restriction {
      display: none;
    }

    #root {
      opacity: 1;
    }
  }
}

@media (orientation: landscape) {
  .mobile-device {
    .landscape-restriction {
      display: flex;
      opacity: 1;
    }
    #root,
    .modal {
      opacity: 0
    }
  }
}

.li-style-none {
  list-style: none;
}



body {
  &.cases, &.insights-confirmation-modal {
    &.modal-open {
      .full-screen {
        display: none;
      }
    }
  }
}

.ifill-os-checkmark-checked {
  &.disabled {
    color: $level-3;
    &:before {
      color: $level-6;
    }
  }
}

.visibility-hidden {
  visibility: hidden;
}

.x-hellosign-embedded__modal-content {
  padding-top: 40px;
}

.x-hellosign-embedded--in-modal .x-hellosign-embedded__modal-close {
  right: 0 !important;
  left: 0;
  display: flex !important;
  width: calc(100% + 1px);
  height: 40px;
  align-items: center;
  background: #1a1a1a;
  padding: 0 4px;

  button {
    width: 40px;
    height: 40px;
    background: none;
    border-radius: 4px;
    position: relative;
    box-shadow: none;
    top: 4px;
    margin-left: auto;

    &:hover {
      background: $os-link-hover
$os-link-hover;

      &:after,
      &:before {
        background: $cl-link;
      }
    }
  }
}

.space-top-32 {
  margin-top: 32px;
}

.space-top-40 {
  margin-top: 40px;
}

.space-btm-32 {
  margin-bottom: 32px;
}

.index-btn {
  position: absolute;
  right: 16px;
  top: -8px;
}

.avtar-block,
.instructors-avatar,
.floater-message-wrapper,
.conversation-left-panel,
.user-details-block,
.conversation-message-author,
.user-image-edit-btn + .avatar,
.case-details-user-block,
.dropdown-menu,
.big-avatar-row,
.chat-listing,
.os__menu {
  .icon-span {
    display: none;
  }
}

.dropdown-menu {
  border: 0;
  &.show.d-none {
    display: block !important;
  }

  .osbtn {
    text-transform: none;
  }
}


.purchase_course {
  .pt-80 {
    padding-top: 80px;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      padding-top: 32px;
    }

    @media screen and (max-width: 767px) {
      padding-top: 4px;
    }
  }

  .subs-term {
    margin-top: 0;
    margin-left: 12px;
  }
}

.spotify-podcast {
  padding-top: 50px;
}

.case-ratio {
  position: relative;
  min-height: 600px;

  .transgif {
    max-width: 100%;
    width: 100%;

    @media(max-width: 767px) {
      display: none;
    }
  }
}

.ipad {
  &.portrait {
    .new-cs-card {
      position: static;
      height: auto;
    }
  }

  &.landscape {
    .case-ratio {
      min-height: 700px;
    }
  }
}

.mobile-device {
  .profile_incomplete {
    #footer {
      display: block !important;
    }
  }
}

.px-361 {
  padding-left: 3.61%;
  padding-right: 3.61%;
}

.clinics {
  .collection-page {
    padding-top: 26px;

    .head-sticky {
      margin-bottom: 16px;
    }
  }
}

.svg-icon {
  > * {
    width: 100%;
  }
}

.required-label {
  label {
    &::after {
      content: '*';
      color: $os-link;
    }
  }
}

.link-url-copy {
  @include text-truncate();
  text-align: left;
  line-height: 24px;
  padding: 8px 8px 8px 32px;
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  max-width: 100%;
  min-height: 32px;
  position: relative;
  display: inline-block;
  margin: -10px 0 8px;
  color: $level-8;
  background-color:$level-3;

  @media screen and (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.disabled {
    color: #6c6c6f;
  }

  i {
    position: absolute;
    left: 8px;
    top: 12px;
    font-size: 16px;

    @media screen and (max-width: 767px) {
      top: 14px;
    }
  }

  &:hover {
     background-color: $level-4 !important;
    color: $level-10!important;
  }
}

.ml-auto {
  margin-left: auto;
}

.lt-red {
  color: $lt-red;
}

.ball-triangle-path > div {
  border-color: $level-11;
}

.global-search-field {
  width: 480px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    padding-left: 36px;
  }
  .search-icon {
    position: relative;
    left: 30px;
    font-size: 18px;
  }

  .form-group {
    margin-bottom: 0rem;
  }
}
