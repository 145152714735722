.ce-survey {
  margin-top: 24px;
  background-color: #1c1c1f;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(18, 18, 20, 0.2);
  padding: 28px 40px 40px;;
  max-width: 704px;
  margin-bottom: 30px;
}

.back-survey {
  max-width: 704px;
  position: relative;
  display: inline-block;
  margin: 24px 0 0 -12px;
}

.ce-survey-logo {
  text-align: center;
  margin-bottom: 32px;

  img {
    max-width: 110px;
  }
}

.ce-survey-tab {
  text-align: center;
}

.survey-textarea {
  height: 40px !important;
  resize: none;
  margin-top: 8px;
  padding-top: 11px;
  padding-bottom: 11px;

  &.error {
    border: 1px solid $lt-red;
  }
}

.error-icon {
  position: absolute;
  top: 2px;
  left: 3px;
  color: $lt-red;
}

.ce-survey-tab-item {
  color: $level-9;
  text-transform: uppercase;
  display: inline-block;
  position: relative;

  &:first-child {
    margin-right: 30px;
  }

  &:after {
    content: none;
    height: 3px;
    width: 100%;
    background-color: #fff;
    position: absolute;
    bottom: -4px;
    left: 0;
  }

  &.active,
  &:active,
  &:focus {
    color: $level-11;

    &:after {
      content: '';
    }
  }
}

.no-touch-support {
  .ce-survey-tab-item {
    &:hover {
      color: $level-9;
      text-decoration: none;

      &:after {
        content: none;
      }
    }

    &.active {
      &:hover {
        color: $level-11;
        text-decoration: none;

        &:after {
          content: '';
        }
      }
    }
  }
}

.ce-survey-ques-wrap {
  margin-top: 24px;
}

.ce-survey-title {
  @include fontstyle(20px, 600, 1.4);

  .ce-quote {
    @include fontstyle(16px, 300, 1.5);
  }
}

.ce-survey-ques {
  margin-top: 12px;

  &:first-child {
    margin-top: 24px;
  }
}

.ce-survey-label {
  font-size: 12px;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
  margin: 0;
  display: block;
  margin-top: 16px;
  color: $level-9;

  .check-icon {
    position: absolute;
    font-size: 16px;
    transition: all .5s ease;
    left: 0;
    top: 0;
    color: $level-9;
  }

  .ifill-os-checkmark-checked {
    transform: scale(0);
  }

  .check-icon-input {
    display: none;

    &:checked ~ {
      .ifill-os-checkmark-default-white {
        transform: scale(0);
      }

      .ifill-os-checkmark-checked {
        transform: scale(1);
        color: #48acfd;
      }
    }
  }
}

.ce-action {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 16px;
}

.ipad {
  .ce-survey {
    margin-bottom: 68px;
  }
}


.mobile-device {
  .ce-survey {
    padding: 24px 16px;
  }

  .ce-survey-tab {
    text-align: left;
  }

  .ce-survey-tab-item {
    font-size: 14px;

    &:first-child {
      margin-right: 16px;
    }
  }

  .back-survey {
    margin-left: 0;
  }
}
