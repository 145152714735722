.mobile-device {
  .paywall-container  {
    .p-bottom {
      width: unset !important;
    }
  }
}
.paywall-container {
  background: rgba(244, 247, 249, 0.9);
  backdrop-filter: blur(15px);
  position: relative;
  .p-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 75px;
    margin-bottom: 60px;
    .close-paywall {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }
    .p-heading {
      color: var(--neutrals-level-12, #222b35);
      font-family: Inter;
      font-style: normal;
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
    }
    .p-middle {
      color: var(--neutrals-level-12, #222b35);
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
    }
    .p-bottom {
      color: var(--neutrals-level-12, #222b35);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 32px;
      width: 690px;
      text-align: center;
    }
  }
  .lo-emb-vid {
    width: 640px;
    height: 360px;
    margin-bottom: 16px;
  }
}
