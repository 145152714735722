/*.modal-nav-visible,*/

.element-drag-active .profile-file-btn {
  input {
    height: 100% !important;
    display: block !important;
  }
}

.user_account_modal:not(.mobile-device) {
  .modal-open {
    .modal-general {
      display: flex !important;
    }
  }
}

.user_account_modal .modal-general {
  .modal-dialog {
    width: 480px;
    max-width: unset;
  }

  .modal-content {
    padding: 32px 32px 40px;
    min-height: 520px;
  }

  &.profile_info {
    .loader-active {
      max-height: 420px;

      .loader-inner {
        margin-top: 0;
      }
    }
  }

  &.mobile_verification {
    .modal-content {
      min-height: 380px;
    }

    .two-step-verify {
      padding-top: 16px;

      .verify-text-info {
        padding-top: 0;
      }
    }

    .loader-active {
      height: 266px;

      .loader-inner {
        margin-top: 0;
      }
    }
  }

  &.email_verification {
    .modal-content {
      min-height: 428px;
      color: $level-11;
    }

    label {
      color: $level-11;
    }
  }

  &.password_update {
    .modal-content {
      min-height: 492px;
      color: $level-11;
    }

    .form-group + .form-group {
      margin-top: 2rem;
    }
  }

  .modal-header {
    padding: 0;
    width: 100%;
    align-items: center;
  }

  .modal-body {
    padding: 0;
    color: $level-11;
  }

  .user-profile-image {
    height: 216px;
    text-align: center;
    padding-top: 24px;

    &.cropping {
      .profile-file-btn {
        display: none;
      }
      .custom-file-control {
        margin-top: 42px;
      }
    }
  }

  .profile-file-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;

    .custom-file-input {
      display: none;
    }
  }

  .custom-file-control {
    position: relative;

    .os-btn-link {
      display: inline-block;
      cursor: pointer;
    }

    .ifill-os-questions {
      vertical-align: middle;
      position: relative;
      color: $lt-orange;

      &::before {
        color: inherit;
      }
    }

    input {
      position: absolute;
      margin: 0;
      left: 50%;
      top: -23px;
      transform: translateX(-50%);
    }
  }

  .user-profile-avatar-area {
    position: relative;
    margin: 0 auto 16px;
    height: 120px;

    .cropper-crop-box {
      transform: none !important;
    }

    .cropper-container img {
      left: -40px;
    }
  }

  .signup-user-camera-icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    text-align: center;
    background: $level-3;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .signup-card-logo {
    width: 100%;
    height: 100%;
    display: flex;

    .avatar {
      border-width: 8px;
    }

    i {
      margin: auto;
    }

    .avatar,
    img {
      width: 100%;
      height: 100%;
      box-shadow: none;
      background: none;
    }
  }

  .image-edit-cropper-block {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    > div,
    .cropper-container {
      max-width: 120px;
      max-height: 120px;
    }

    input[type="range"] {
      cursor: pointer;
      background-color: transparent;
      -webkit-appearance: none;
      width: 200px;
    }
  }

  .current_profile_status {
    color: $level-11;
  }

  .c_p_list {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .cp_label {
    position: relative;
    border-radius: 8px;
    background-color: $level-3;
    font-weight: $font-weight-semibold;
    input {
      display: none;

      &:checked ~ .cp_label_helper {
        border:1px solid  $os-link;
        color: $os-link;
        background: $block;

        i {
          color: inherit;
        }
      }
    }

    &:not(.disable):hover {
      color: $os-link;

      i {
        color: inherit;
      }
    }

    &.disable {
      background-color: $level-3;
      color: $level-8;
      .cp_label_helper {
        cursor: default;
      }
    }
  }

  .cp_label_helper {
    border-radius: 8px;
    height: 80px;
    display: flex;
    width: 128px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;

    i {
      font-size: 14px;
    }
  }

  .speciality_label_heler {
    height: 40px;
  }

  .profile-action-btn {
    .back_btn {
      width: 104px;
    }

    .next_btn {
      margin-left: 1.5rem;
      width: 120px;
    }
  }

  .user-specialty {
    padding-top: 32px;

    .form-control {
      &.error {
        border-color: $lt-red;
      }
    }
  }

  .user-specialty-list {
    margin-top: 8px;
    margin-right: -4px;
    margin-left: -4px;
    margin-bottom: 24px;

    .cp_label {
      flex: 1;
      margin-left: 4px !important;
      margin-right: 4px !important;


      &.orthodontic_resident {
        min-width: 130px;
      }
    }

    .cp_label_helper {
      width: 100%;
    }

    @media(min-width: 768px) {
      margin-right: -8px;
      margin-left: -8px;

      .cp_label {
        margin-left: 8px !important;
        margin-right: 8px !important;

        &.orthodontic_resident {
          min-width: 140px;
        }
      }
    }
  }

  .group-url {
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      .website-url {
        flex: 1;
      }

      > .form-group {
        width: 124px;
        margin-left: 16px;
      }
    }
  }

  .check-listing {
    display: flex;
    flex-wrap: wrap;
    color: $level-11;
    margin-top: 16px;

    .form-group {
      margin-bottom: 0;
      width: 50%;
    }

    label {
      margin-bottom: 0;
      display: block;
      position: relative;
      padding-left: 20px;
      font-size: 12px;
      cursor: pointer;


      input {
        display: none;
      }

      input:checked ~ i {
        &.ifill-os-checkmark-checked {
          opacity: 1;
          transform: scale(1);
        }

        &.ifill-os-checkmark-default-white {
          opacity: 0;
          transform: scale(0);
        }
      }
    }

    i {
      position: absolute;
      left: 0;
      top: calc(50% - 8px);
      transition: all 200ms ease;
      font-size: 16px;
    }

    .ifill-os-checkmark-checked {
      opacity: 0;
      transform: scale(0);
    }

    @media(min-width: 768px) {
      .form-group {
        label {
          font-size: 16px;
          padding-left: 24px;

          i {
            top: calc(50% - 8px);
          }
        }
      }
    }
  }

  .with-error {
    position: absolute;
    padding-top: 4px;
  }

  .send-code-btn,
  .verify-btn {
    width: 120px;
    padding: 0;
  }

  .code-send-col {
    margin-right: -8px;
    padding-top: 2rem;
    position: relative;

    .form-error {
      bottom: -12px;
    }

    .onboard-label {
      margin: 0;
    }
  }

  .otp-form-group {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    .form-group {
      width: 96px;
      max-width: 96px;
      margin: 0 0 5px;

      input {
        width: 100% !important;
      }

      @media(max-width: 767px) {
        width: 23%;
        max-width: 23%;
      }
    }

    &.error {
      input {
        border-color: $lt-red;
      }
    }
  }

  .resend-code-link {
    background: none;
    outline: none;
    border: none;
    -webkit-appearance: none;
    color: $os-link;
    padding: 0 !important;
    font-size: 12px;
    line-height: 1.33;
  }

  .device-trust-checkarea {
    padding-top: 0;
    padding-left: 0;
  }

  .verify-text-info {
    padding: 15px 0 32px;
  }

  .email_resend_btn {
    min-width: 128px;
  }

  .email-verify-footer {
    padding-top: 16px;
  }
}

.user_account_modal {
  @include hideInputNumerArrow;
}

.phone-number-focus {
  .verify-mobile-group {
    border-color: $os-link;
  }
}

.verify-mobile-group.error {
  border-color: $lt-red;
}

.verify-mobile-group {
  position: relative;
  display: flex;
  border-radius: 8px;
  border: 1px solid $border-input-typed-button-2;

  .single-select {
    flex: unset;
    width: unset;
    position: static;

    .react-select-ortho-2 {
      position: static;
    }

    .os__option {
      padding: 4px;
      line-height: 32px;
    }

    .os__control {
      background: none;
      text-align: right;
      color: $level-11;
      border: 0;
      padding: 0 20px 0 5px;
    }

    .os__menu,
    .os__control {
      pointer-events: auto;
    }

    .os__menu {
      left: 0;
    }

    .os__single-value {
      right: 0;
      color: $level-11;
      position: static;
      transform: none;
      margin: 0;
      padding: 0;
      max-width: unset;
      order: 1;
      margin-left: auto;
    }

    .os__value-container {
      padding: 0;
    }

    .os__indicators {
      display: inline-flex;
      align-items: center;
      @include osfont;
      color: inherit;
      right: 0;
      font-size: 12px;

      &:before {
        content: "\e922";
      }
    }
  }

  #phone_number {
    border: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
  }

  .form-group {
    margin: 0 !important;
    flex: 1;
    position: static;
  }

  .form-control {
    background: none;
    border: none;
    box-shadow: none;
    padding-left: 5px;
  }

  .with-error {
    left: 0;
  }
}

@media(min-width: 768px) {
  .profile-action-btn,
  .user-action-footer,
  .verify-action-footer {
    position: absolute;
    bottom: 0;
    right: 0;

    .osbtn + * {
      margin-left: 24px;
    }
  }

  .verify-action-footer {
    left: 0;
  }
}

@media(max-width: 767px) {
  .user_account_modal .modal-general {
    .modal-dialog {
      width: 100%;
      max-width: 100%;
    }

    .modal-content {
      padding: 60px 20px 0;
      width: 100%;
      border-radius: 0;
      height: 100%;
      max-width: 100%;
    }

    .modal-header {
      position: relative;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .osbtn {
        right: 0;
        top: -4px;
      }

      .modal-title {
        padding-top: 3px;
        padding-right: 35px;
      }
    }

    .user-profile-image {
      height: 240px;
      padding-top: 32px;
    }

    .cp_label {
      width: 31.045%;
    }

    .cp_label_helper {
      width: 100%;
    }

    .profile-action-btn,
    .user-action-footer {
      .back_btn,
      .next_btn {
        height: 48px;
        line-height: 46px;
        border-radius: 8px;
      }

      .next_btn {
        margin-left: 6px;
      }
    }

    .user-action-footer,
    .profile-action-btn {
      margin-top: 3.5rem;
    }

    .verify-action-footer {
      margin-top: 40px;
    }

    .verify-text-info {
      padding: 8px 0 40px;
    }

    .send-code-btn,
    .verify-btn {
      line-height: 48px;
      width: 160px;
    }
  }
}

@media(max-width: 340px) {
  .user_account_modal .modal-general {
    .modal-content {
      padding: 15px 20px 0;
    }

    .user-profile-image {
      height: 220px;
    }
  }
}

.verificationBanner {
  background: $level-3;
}


.ifill-os-questions {
  color: $lt-orange;
}

.device-trust-checkarea {
  padding-left: 0;
}
