.template-section {
  padding: 24px 32px 16px !important;

  .template-header-section {
    display: flex;
    justify-content: space-between;

    button {
      margin-right: 0 !important;
    }

    .osbtn{
      min-width: 111px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
  }

  .heading-description{
    width: 86%;
    margin-top: 21px;
    font-family: Inter,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

.template-name-error-message {
  font-size: 12px;
  color: red;
  margin: 0;
  bottom: 8px;
  margin-top: 8px;
  margin-left: 32px;
}

.tip-tap-editor-field-error-message {
  color: red;
  font-size: 12px;
  margin: 0 15.6px;
  padding-left: 8px;
}

.data-row-with-editor{
  margin-top: 24px;
}

.template-header-row {
  display: flex;

  &>div {
    &:hover {
      cursor: pointer;
    }
  }

  .template-header-name {
    width: 600px;
  }

  &>div {
    width: 300px;

    //width: 230px;
    padding: 8px 16px;
    color: #5D7893;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-style: normal;
  }

  &>div:last-child {
   width: 50px;
   align-items: center;
   padding: 0;
  }

  [class^='ifill-'] {
    line-height: 0.5 !important;
  }

  .sorting-block {
    display: flex;
    flex-wrap: wrap;
    width: 24px;
    line-height: 0.5;
    align-items: center;
  }
}

.template-row {
  border-bottom: 1px solid #C9D1DA;

  .template-editor {
    &>div:first-child {
      position: relative;
    }

    .tip-tap-editor-field {
      width: 97.5%;
      margin: 8px auto;

      span[data-hashtag="hashtag"] {
        background: #e1e1e1;
        border-radius: 4px;
        padding: 4px;
      }

      &:hover {
        cursor: text;
      }

    }

    .options {
      margin-left: 16px;
      width: 97.5%;

      .editor-actions {
        margin-right: 0;
        display: flex;
        justify-content: space-between;

        .action-button {
          display: flex;
        }

        .osbtn-primary {
          margin-right: 0 !important;
        }
      }

      .editor-attachments {
        width: 99%;
      }
    }
  }
}

.template-data-row {
  display: flex;
  padding: 4px 0;
  border-radius: 4px;

  .template-name-input {
    width: 600px;
  }

  &>div {
    width: 300px;

    //width: 230px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: black;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.2px;
    font-style: normal;
  }

  &>div:last-child {
   margin-right: 8px;
   width: 50px;
   align-items: center;
   padding: 0;

  }

  .latest-activity-field {
    display: flex;
    flex-wrap: wrap;

    .last-update-date {
      padding-left: 20px;
      width: 100%;
      font-weight: 400;
      color: #354554;
      font-size: 16px;
    }

    .last-update-author-name {
      padding-left: 20px;
      color: #5D7893;
      line-height: 16px;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .list-unstyled {
    top: 54px;
    right: 8px !important;
    box-shadow: $block-shadow-m;
    min-width: 120px !important;

    .dropdown-item {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 32px;
      margin: auto 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  input {
    border: none;

    &:hover {
      border: none;
    }

    &:focus-within {
      border: none;
    }
  }

  &:hover {
    background-color: #F5F5F5;
    cursor: pointer;
    border-radius: 8px;
  }
}



// CSS FOR TEMPLATES IN THE EDITOR.
#comments {
  &:has(.os__control--menu-is-open) {
    .discussion-thread-section {
      &>div:last-child {
        &>div {
          position: relative;
          z-index: -1000;
        }
      }
    }
  }
}
