$heading: $level-11;
$secondary-heading: $level-9;
$sidebar-widget-title: $heading;

.heading-color {
  color: $heading;
}
.s-heading-color{
  color: $secondary-heading;
}

.os-title {
  color: $level-11;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

.font-semibold {
  font-weight: $link-weight;
}

%heading-1 {
  @include fontstyle(24px, $font-weight-black, 28px);
}

%heading-2-bold {
  @include fontstyle(20px, $font-weight-bold, 24px);
}

%heading-2-semibold {
  @include fontstyle(20px, $font-weight-semibold, 1.4);
}

%heading-3-semibold {
  @include fontstyle(16px, $font-weight-semibold, 24px);
}

%heading-4-1 {
  @include fontstyle(16px, $font-weight-semibold, 16px);
}

%heading-4-1-2 {
  @include fontstyle(16px, $font-weight-semibold, 20px);
}

%body-big {
  @include fontstyle(20px, $font-weight-normal, 28px);
}

%body-small-1 {
  @include fontstyle(16px, $font-weight-normal, 16px);
}

%body-small-1-2 {
  @include fontstyle(16px, $font-weight-normal, 20px);
}

%body-small-1-5 {
  @include fontstyle(16px, $font-weight-normal, 24px);
}

%heading-5 {
  @include fontstyle(12px, $font-weight-semibold, 16px);
}

%caption-default {
  @include fontstyle(12px, $font-weight-normal, 16px);
}

%caption-semibold {
  @include fontstyle(12px, $font-weight-semibold, 16px);
}

%caption-tiny {
  @include fontstyle(8px, $font-weight-normal, 12px);
  color: $level-8;
}

%label-small {
  @include fontstyle(8px, $font-weight-bold, 12px);
}

%link-big {
  @include fontstyle(20px, $font-weight-semibold, 28px);
}

%link-small {
  @include fontstyle(16px, $font-weight-semibold, 16px);
}

%link-tiny {
  @include fontstyle(12px, $font-weight-semibold, 16px);
}
