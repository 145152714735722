.switch {
  &.sm-switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;
    margin-bottom: 0;
    input {
      top: -2px;
      position: relative;
      &:checked + .slider {
        border-color: #48acfd;
        &:before {
          transform: translateX(8px);
          width: 10px;
          height: 10px;
          top: 1px;
        }
      }
      + .slider {
        border-color: #48acfd;
        &:before {
          background-color: #48acfd;
          transform: translateX(-1px);
          width: 10px;
          height: 10px;
          top: 1px;
        }
      }
    }
  }
}
