.guide-tour-modal {
  .svg-icon {
    width: 176px;
    height: 144px;
    vertical-align: top;
  }

  .modal-header {
    .os-header-btn {
      top: -12px;
      right: -10px;
    }
  }

  .modal-footer {
    margin-top: 24px;
    margin-bottom: 8px;
    padding: 0;
  }

  .guided-tour-icon-area {
    padding: 16px 0 32px;
  }
}

.mobile-device {
  .guide-tour-modal {
    .modal-header .os-header-btn {
      top: -2px;
      right: -12px;
    }
  }
}
