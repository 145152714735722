.sidebar-folder-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 28px;

  @media screen and (min-width: 1920px) {
    padding: 0 16px 0 52px;
  }

  @media (max-width: 1440px) {
    padding: 0;
    li {
      margin-left: 16px;
      padding: 0 20px 0 8px;
    }
  }

  li {
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-top: 0.25rem;
    position: relative;

    .actions {
      cursor: pointer;

      &.active {
        visibility: visible;
      }
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 8px;
    }

    &:hover {
      background: transparent !important;

      &::after {
        background: #edeff3 !important;
        transition: all 0.4s linear;
      }

      .actions {
        visibility: visible;
      }
    }

    &.active {
      background: transparent !important;

      .actions {
        visibility: visible;
      }

      &::after {
        background: #edeff3 !important;
        transition: all 0.4s linear;
      }
    }

    ul > li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 16px;
      height: 40px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 8px 8px 24px;
      width: calc(100% - 32px);
      flex: 1;
      text-decoration: none;
      flex-wrap: wrap;

      &:hover {
        text-decoration: none;
      }

      .ac-user-name {
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: space-between;
        flex: 1;
        overflow: hidden;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;

        .text {
          display: inline-block;
          align-items: center;
          gap: 4px;
          flex-wrap: wrap;
          color: #5d7893;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status {
          display: flex;
          align-items: center;
          margin-top: 1px;
        }
      }
    }

    .actions {
      visibility: hidden;
      padding: 4px 8px;
      width: fit-content;
    }
  }

  .container-section {
    .container-section__header {
      padding-left: 24px;
    }
  }
}

.mobile-device {
  .sidebar-folder-list {
    li {
      .actions {
        &.active {
          visibility: hidden;
        }
      }

      &:hover {
        .actions {
          visibility: hidden;
        }
      }

      &.active {
        .actions {
          visibility: hidden;
        }
      }

      .actions {
        display: none;
        visibility: hidden;
      }
    }
  }
}
