//
// Icon Font: ortho
//

@font-face {
  font-family: "ortho";
  src: url("/app/styles/fonts/ortho.eot");
  src: url("/app/styles/fonts/ortho.eot?#iefix") format("embedded-opentype"),
       url("/app/styles/fonts/ortho.woff2") format("woff2"),
       url("/app/styles/fonts/ortho.woff") format("woff"),
       url("/app/styles/fonts/ortho.ttf") format("truetype"),
       url("/app/styles/fonts/ortho.svg#ortho") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "ortho";
    src: url("/app/styles/fonts/ortho.svg#ortho") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.icon-OrthoScience-Logo-Beta:before,
.icon-bell:before,
.icon-board:before,
.icon-bubbles:before,
.icon-camera:before,
.icon-cards:before,
.icon-case:before,
.icon-comments:before,
.icon-comments-filled:before,
.icon-comparison:before,
.icon-down-arrow:before,
.icon-edit:before,
.icon-expand:before,
.icon-follower:before,
.icon-fullscreen:before,
.icon-group:before,
.icon-hash:before,
.icon-left-dash:before,
.icon-like:before,
.icon-liked:before,
.icon-list:before,
.icon-messages:before,
.icon-ortho-science-logo:before,
.icon-plus:before,
.icon-right-arrow:before,
.icon-right-dash:before,
.icon-save-board:before,
.icon-search:before,
.icon-share:before,
.icon-snapshot:before,
.icon-theory:before,
.icon-update:before,
.icon-user:before,
.icon-users:before,
.icon-views:before {
  display: inline-block;
  font-family: "ortho";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-OrthoScience-Logo-Beta:before { content: "\f12b"; }
.icon-bell:before { content: "\f12a"; }
.icon-board:before { content: "\f109"; }
.icon-bubbles:before { content: "\f120"; }
.icon-camera:before { content: "\f121"; }
.icon-cards:before { content: "\f117"; }
.icon-case:before { content: "\f10a"; }
.icon-comments:before { content: "\f10b"; }
.icon-comments-filled:before { content: "\f123"; }
.icon-comparison:before { content: "\f124"; }
.icon-down-arrow:before { content: "\f10c"; }
.icon-edit:before { content: "\f127"; }
.icon-expand:before { content: "\f128"; }
.icon-follower:before { content: "\f125"; }
.icon-fullscreen:before { content: "\f112"; }
.icon-group:before { content: "\f122"; }
.icon-hash:before { content: "\f11b"; }
.icon-left-dash:before { content: "\f11c"; }
.icon-like:before { content: "\f10d"; }
.icon-liked:before { content: "\f11a"; }
.icon-list:before { content: "\f118"; }
.icon-messages:before { content: "\f10e"; }
.icon-ortho-science-logo:before { content: "\f10f"; }
.icon-plus:before { content: "\f113"; }
.icon-right-arrow:before { content: "\f119"; }
.icon-right-dash:before { content: "\f11d"; }
.icon-save-board:before { content: "\f114"; }
.icon-search:before { content: "\f129"; }
.icon-share:before { content: "\f115"; }
.icon-snapshot:before { content: "\f116"; }
.icon-theory:before { content: "\f110"; }
.icon-update:before { content: "\f126"; }
.icon-user:before { content: "\f11e"; }
.icon-users:before { content: "\f11f"; }
.icon-views:before { content: "\f111"; }

$font-ortho-OrthoScience-Logo-Beta: "\f12b";
$font-ortho-bell: "\f12a";
$font-ortho-board: "\f109";
$font-ortho-bubbles: "\f120";
$font-ortho-camera: "\f121";
$font-ortho-cards: "\f117";
$font-ortho-case: "\f10a";
$font-ortho-comments: "\f10b";
$font-ortho-comments-filled: "\f123";
$font-ortho-compare: "\f124";
$font-ortho-down-arrow: "\f10c";
$font-ortho-edit: "\f127";
$font-ortho-expand: "\f128";
$font-ortho-follower: "\f125";
$font-ortho-fullscreen: "\f112";
$font-ortho-group: "\f122";
$font-ortho-hash: "\f11b";
$font-ortho-left-dash: "\f11c";
$font-ortho-like: "\f10d";
$font-ortho-liked: "\f11a";
$font-ortho-list: "\f118";
$font-ortho-messages: "\f10e";
$font-ortho-ortho-science-logo: "\f10f";
$font-ortho-plus: "\f113";
$font-ortho-right-arrow: "\f119";
$font-ortho-right-dash: "\f11d";
$font-ortho-save-board: "\f114";
$font-ortho-search: "\f129";
$font-ortho-share: "\f115";
$font-ortho-snapshot: "\f116";
$font-ortho-theory: "\f110";
$font-ortho-update: "\f126";
$font-ortho-user: "\f11e";
$font-ortho-users: "\f11f";
$font-ortho-views: "\f111";
