.os-card-thumbnail {
  @extend %os-card-thumbnail;

  &.selected {
    background: $block;
  }
}

.thumbnail-image {
  height: 56px;
  max-height: 56px;
  min-width: 100%;
  max-width: 100%;

  .overlay-span,
  .attach-custom-file-icons {
    height: 100%;
    text-align: center
  }

  .overlay-span,
  .img-parent {
    border-radius: 8px 8px 0 0;
    max-width: 100%;
    height: 100%;
    width: 100%;

    .card-attach-image, SVG {
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .case-img {
    width: 100%;
  }
}

.col-thumb {
  .thumbnail-image {
    border-radius: 8px;

    img {
      border-radius: 8px;
    }
  }
}

.thumbnail-title{
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  padding-top: 8px;
  @include multiline-truncate(2);
  overflow: hidden;

  .title-text,  .subtitle-text {
    @include text-truncate();
    overflow: hidden;
    &.multi-line {
      @include multiline-truncate(2);
      white-space: normal;
    }
  }

  .subtitle-text {
    color: $level-9;
  }
}

.thumbnail {
  .attach-custom-file-icons {
    .icon {
      font-size: 24px;
      margin-left: 5px;
      margin-top: 4px;
      display: block;
    }
  }
}

.thumbnail, ,
.big,
.list {
  .img-parent img[alt] {
    word-break: break-all;
    white-space: normal;
    font-size: 10px;
    line-height: 12px;
    border-radius: 4px;
  }

  .link-card,
  .case-card,
  .doc-card,
  .image-card,
  .tool-card,
  .post-card,
  .video-card {
    .thumbnail-image,
    .cd-list-img {
      color: $level-1;
      border-radius: 8px;
    }
  }

  .link-card {
    .thumbnail-image,
    .cd-list-img {
      background-color: $bg-orange;
    }
  }

  .image-card {
    .thumbnail-image,
    .cd-list-img {
      background-color: $bg-yellow;
    }
  }

  .doc-card {
    .thumbnail-image,
    .cd-list-img {
      background-color: $bg-green;
    }
  }

  .case-card {
    .thumbnail-image,
    .cd-list-img {
      background-color: $bg-red;
    }
  }


  .doc-card,
  .case-card {
    .thumbnail-image,
    .cd-list-img {
      display: flex;

      .attach-custom-file-icons {
        margin: auto;
        height: 100%;
      }
    }
  }

  .tool-card {
    .thumbnail-image,
    .cd-list-img {
      background-color: $bg-blue;
    }
  }

  .post-card {
    .thumbnail-image,
    .cd-list-img {
      background-color: $bg-pink;
    }
  }

  .video-card {
    .thumbnail-image,
    .cd-list-img {
      background-color: $bg-purple;
    }
  }
}

.big {
  .link-card,
  .case-card,
  .doc-card,
  .image-card,
  .tool-card,
  .post-card,
  .video-card {
    .thumbnail-image,
    .cd-list-img {
      border-radius: 8px 8px 0 0;
    }
  }
}

.modal-content {
  .os-card-thumbnail {
    border: 0 !important;
    box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
  }

  .col-thumb {
    .os-card-thumbnail {
      border-radius: 4px !important;
    }
  }
}
