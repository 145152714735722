.os-visiting-card-v2  {
  .team-header-image:only-child {
    margin-bottom: 24px;
  }
}

.team-header-image {
  max-height: 148px;
  height: 148px;
  background-color: $block;
  border-radius: 8px 8px 0 0;
  text-align: center;
  display: block;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
    margin: 0px auto;
  }

  .non-image-wrap {
    text-align:center;
    width: 100%;
    height: 100%;
    padding: 32px 0;

    i {
      color: #6c6c6f;
      font-size: 32px;
      margin-bottom: 16px;
      display: block;
    }

    .non-image-text {
      color: $level-11;
      font-size: 16px;
      line-height: 16px;
    }
  }
}



.care-team-widget {
  padding: 0 !important;

  .doctor-list {
    padding: 0 16px 24px 24px;
  }

  .dr-connect-list {
    max-height: 310px;
    overflow-y: auto;
    padding-right: 8px;
    margin-top: 24px;
  }

  .dr-connect-list-item {
    align-items: center;
    &:first-child {
      padding-top: 0;
    }

    .connection-info {
      max-width: none;
    }

    .user-name-info {
      padding-right: 0;
      max-width: calc(100% - 48px);
      @include text-truncate();
    }

    .small {
      margin-top: 0;
    }
  }

  &.with-listing {
    .doctor-list {
       padding: 0 24px 24px !important;
    }

    .dr-connect-list-item {
      .connect-link {
        .btn-icon-helper {
          font-size: 16px !important;
        }
      }
    }
  }

  &.associated_clinics {
    padding-bottom: 36px !important;
  }

  .slick-dots {
    bottom: 0px;

    li {
      width: 16px;
      height: 16px;
      margin: 0px 8px 0 0;
      button:before {
        content: "\e925";
        font-family: 'fill-os-icon' !important;
        color: #fff;
        font-size: 16px;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          color: $os-link;
        }
      }
    }
  }
}

.care-team-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
  font-size: 12px;
  font-weight: normal;

  address{
    padding-left: 24px;
    position: relative;
    margin-bottom: 0;
  }

  .mail-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .info-text-col, address {
    flex-basis: 50%;
    max-width: 50%;
    color: $level-11;
    @extend %caption-default;

    i {
      position: absolute;
      left: 0;
      font-size: 16px;
      color: $level-11;
      top: 2px;
    }

    a {
      color: $level-11;
      @extend %caption-default;

      &:hover {
        color: $os-link;
      }
    }

    div{
      padding-left: 24px;
      position: relative;
    }

    .phone-text {
      padding-bottom: 16px;
    }
  }
}
