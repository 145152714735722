.cd-detail-conatiner {
  background: $level-2;
  border-radius: 0;
  padding: 28px 20px 24px;
  margin-bottom: 32px;

  .case-details-user-block {
    .doc-name-case {
      display: inline-block;
      color: $level-9;

      .mx-50 {
        max-width: 85%;
      }

      &:hover {
        color: $os-link !important;
        text-decoration: underline;

        a, span {
          color: $os-link !important;
        }
      }

      > a, span {
        &:hover {
          color: $level-11;
        }
      }
    }

    .user-title {
      font-size: 12px;
      color: $level-9;
    }
  }

  &.new-case-card {
    @media(min-width: 768px) {
      padding-bottom: 64px;
    }
  }

  .cs-treatment-sec {
    height: calc(100% - 5px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;
  }
}

.case-card-detail-header {
  @extend %heading-2-bold ;
  padding: 0;
  margin-bottom: 8px;
  position: relative;

  .detail-subheader {
    @extend %heading-2-bold ;
    padding-bottom: 0;
  }

  .ifill-os-verified {
    color: $level-10;
  }

}

.cs-dropdown   {
  .header-dropdown-list-item  {
    &.dropdown-group-header {
      padding: 8px 12px;
      color: $level-11 !important;
    }
  }
}

.case-details-video-section {
  height: 100%;
  position: relative;
}

.mobile-device {
  .cd-detail-conatiner {
    padding: 0;
    border-radius: 0;
  }

  .doc-name-case {
    display: none !important;
  }
}

.quiz-right-section {
  padding-right: 16px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
  }
}

.cs-dropdown {
  display: inline-block;
  min-width: 145px;

  &.mw-88 {
    min-width: 160px;
    .header-dropdown-list {
      min-width: 100%;
    }
  }

  &.ml-32 {
    margin-left: 16px;
  }

  .page-heading-title {
    padding: 8px 36px 8px 12.5px;
    border-radius: 4px;
    line-height: 1;

    .pointer {
      word-break: break-all;
    }
  }

  &.dropdown-open {
    .page-heading-title {
      border-radius: 4px 4px 0px 0px ;
    }
  }

  .ifill-os-chevron-down,
  .ifill-os-chevron-up {
    right: 12px;
  }

  .header-dropdown-list {
    .header-dropdown-list-item {
      > div {
        padding: 8px 12px;
      }
    }
  }
}

.user-avatar-block {
  @include flexstyle(flex, center, space-between, row)
  .cs-sbs-dropdown {
    z-index: 10;
  }
}

.desktop-small {
  .user-avatar-block {
    .case-details-user-block {
      max-width: 40%;
    }
  }
}

.desktop-small,
.ipad:portrait {
  .cs-dr-down-block {
    position: absolute;
    right: 0;
    top: 0;
  }

  .cd-detail-conatiner {
    padding-right: 32px;
  }

  .case-card-detail-header {
    @include fontstyle(20px, 300, 1.2);
    padding: 0;
    margin-bottom: 8px;

    .detail-subheader {
      @include fontstyle(20px, 300, 1.2)
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .user-avatar-block {
    flex-direction: row-reverse;
  }

  .case-details-user-block {
    > div {
      flex-direction: row-reverse;
    }
  }

  .cs-dr-down-block {
    display: flex;
  }

  .cs-dropdown{
    margin-left: 16px;

    &.mw-88 {
      margin-right: 0;
    }
  }

}

.case-right-landing-header {
  .cs-right-dropdown {
    right: 0;
  }

  .cs-close-fullscreen {
    right: 30px;
    top: 24px;
  }
}

.full-screen {
  background-color: black;
  padding: 0;
  min-height: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;

  .case-details-video-section {
    padding: 32px !important;
  }

  .case-details-user-block,
  .cs-right-dropdown {
    display: none;
  }

  .cs-dr-down-block {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  .case-timeline-right-section {
    background-color: $level-1;
    padding: 32px !important;

    .case-right-header {
      top: 0;
      left: 0;
      right: 0;
    }

    .activity-footer-block {
      display: none;
    }

    .case-treatment-right-section {
      height: 100%;

      &.treatment-data-unavailable {
        .timeline-right-section {
          max-height: calc(100vh - 110px);
        }
      }
    }

    .timeline-right-section {
      max-height: calc(100vh - 220px);
    }

    .quiz-right-section {
      max-height: calc(100vh - 125px);

      &.questions {
        max-height: calc(100vh - 168px);
        margin-top: 0px !important;
      }
    }

    .quiz-submit-btn {
      bottom: 32px;
    }
  }

  .submit-insight-header {
    position: relative;
    left: 0;
    right: 0;
    top: 0;

    .cs-close-fullscreen {
      top: 0;
    }
  }
}


.desktop-small,
.ipad.landscape {
  .case-card-detail-header {
    @include fontstyle(20px, 300, 24px);

    .detail-subheader {
      @include fontstyle(20px, 300, 24px);
    }
  }

  .cd-detail-conatiner {
    &:not(.full-screen) {
      .case-timeline-right-section {
      }
    }
  }

  .full-screen {
    .case-details-video-section {
      padding: 24px !important;
    }

    .case-timeline-right-section {
      padding: 24px 32px 24px 24px !important;

      .case-right-header {
        top: 0;
        left: 0;
        right: 0;
      }
    }

    .cs-close-fullscreen {
      right: 0;
    }

    .detail-header {
      padding-top: 10px;
    }

    .cs-dr-down-block {
      right: 24px;
      top: 24px;

      .cs-dropdown {
        .page-heading-title {
          padding: 8px 32px 8px 10px;
        }

        &.mw-88 {
          min-width: 150px;
        }

        &.mx-135 {
          min-width: 135px;
          margin-left: 8px;

          .header-dropdown-list  {
            min-width: 135px;
          }
        }
      }
    }
  }
}

.ipad {
  &.landscape {
    .full-screen {
      height: 100%;
      .case-timeline-right-section {
        .quiz-right-section {
          max-height: calc(100vh - 110px);
        }

        .timeline-right-section {
          max-height: calc(100vh - 204px);
        }
      }

      .case-card-detail-header {
        padding-left: 60px;
      }
    }
  }
}

.ipad {
  &.portrait {
    /*.case-details-video-section {
      height: 544px;
    }*/

    .full-screen {
      height: 100% !important;
      .case-details-video-section {
        height: 100% !important;
      }
    }

    .quiz-right-section {
      padding-right: 8px;
      width: 100%;
    }

    .case-left-wrapper {
      height: calc(100% - 64px);
    }
    .case-right-landing-submission {
      left: 24px;
      right: auto;
      top: 0;
    }

    .case-details-content-block {
      margin-top: 0px !important;
    }

    .cs-dropdown {
      &.mw-88 {
        min-width: 120px;
      }
    }

    .cs-dr-down-block {
      right: 24px;
      top: 24px;

      .cs-dropdown {
        .page-heading-title {
          padding: 8px 38px 8px 10px;
        }

        &.mw-88 {
          min-width: 110px;
        }

        &.mx-135 {
          min-width: 145px;
          margin-left: 8px;
        }
      }
    }


    .submit-right-container {
      min-height: 336px;
    }

    .case-card-detail-header {
      @include fontstyle(20px, 300, 24px);

      .detail-subheader {
        @include fontstyle(20px, 600, 24px);
      }

    }

    .cs-dr-down-block {
      position: absolute;
      top: -2px;
      right: 0;

      .cs-right-dropdown {
        position: relative;
        display: inline-block;
        top: -6px;
        right: 0;
        margin-left: 8px;
      }
    }

    .case-detail-height {
      min-height: auto;
      max-height: none;
      height: auto
    }

    .case-timeline-right-section {
      .case-right-header {
        position: relative;
        left: 0;
        right: 0;
        margin-top: 24px;
        border-bottom: 1px solid #4c4c54;

        .cs-right-dropdown {
          display: none;
        }
      }

      .activity-footer-block {
        bottom: auto;
        top: 12px;
        width: auto;
        right: 0;
      }
    }

    &.guest-layout {
      .case-timeline-right-section {
        min-height: 336px;
      }
    }

    .full-screen {
      .cs-dr-down-block {
        top: 24px;
        right: 32px;
      }

      .cs-right-dropdown {
        display: none;
      }

      .case-timeline-right-section {
        display: none;
      }

      .cs-close-fullscreen {
        display: none;
      }

      .case-card-detail-header {
        padding-left: 55px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .user-avatar-block {
    margin-top: 12px;
  }

  .case-details-user-block {
    > div > a + span {
      display: none;
    }

    .avatar {
      &.avatar-24 {
        @include avatar(32px);
        margin-right: 0;
      }
    }
  }

  .case-detail-header-mobile {
    height: 56px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ifill-os-verified {
      color: $os-link;
    }

    .detail-header  {
      + div {
        flex-direction: row-reverse !important;
      }
    }

    .detail-subheader  {
      padding-bottom: 0 !important;
      font-size: 12px;
      line-height: 1;
      padding-left: 0;
      font-weight: 300;

      .details-time {
        font-size: 12px;
      }
    }


    &.visible-mobile {
      display: flex !important;
    }

    i {
      font-size: 16px;
      &.ifill-os-chevron-left {
        flex-basis: 20%;
      }
    }

    .case-title-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-basis: 40%;
      white-space: nowrap;

      .ifill-os-fill-case-2-d {
        margin-right: 0px;
        font-size: 17px;
        display: none;
      }

      h1 {
        height: auto;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: $font-weight-light;
        line-height: 18px;
        width: calc(100% - 50px);
        text-align: center;
        padding-left: 10px;
      }

      p {
        &.user-case-id {
          font-size: 12px;
          padding-left: 0;
          margin-bottom: 0px;
          line-height: 1;
          color: $level-11;
        }
      }
    }

    .cs-right-dropdown {
      position: relative;
      display: inline-block;
      top: 0;
      right: -8px;
    }
  }

  .cs-sbs-dropdown {
    .appointment-dates-ul {
      left: 0;
      right: auto;
    }
  }

  .cs-dropdown {
    &.mw-88 {
      min-width: 100px;
      margin-left: 8px;
    }

    .page-heading-title {
      font-size: 14px;
    }

    + .cs-sbs-dropdown {
        .appointment-dates-ul {
          left: auto;
          right: 0;
        }
    }
  }

  .case-right-header {
    border-bottom: 1px solid #4c4c54;

    .cs-right-dropdown {
      display: none;
    }
  }

  .submit-right-container {
    padding: 60px 4px 24px 4px;
    height: 100%;
  }
}

.mobile-device {
  .case-timeline-right-section {
    .quiz-right-section {
      &.questions {
        .accordion-section {
          margin-bottom: 0;
        }
      }
    }
  }

  .quiz-submit-btn {
    position: relative;
    bottom: 0;
  }

  .cs-fullscreen {
    display: none;
  }

  &.guest-layout {
    .case-detail-header-mobile {
      justify-content: center;

      .ifill-os-chevron-left {
        margin-right: auto;
        flex-basis: auto;
      }

      .case-title-block{
        margin-right: auto;
      }
    }
  }
}
