.group-label-board {
  height: inherit;
  .react-kanban-board {
    height: inherit;
    & > div {
      grid-template-rows: none !important;
      grid-template-columns: none !important;
      grid-template-areas: none;
      display: flex !important;
      .react-kanban-column {
        max-width: 400px;
        height: 75vh !important;
        & > div:last-child {
          & > div {
            & > div {
              width: 100% !important;
              & > div {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }

  .unified-column-header {
    display: flex !important;
    justify-content: center !important;
    color: #5d7893;
    flex-grow: 1;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.33;
    text-align: center;
  }
}
