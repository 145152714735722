@media (min-width: 1200px) {
  .modal-xl {
    max-width:1180px;

    &.modal-analytics-xl {
      max-width: 100%;
      margin: 0;
      height: 100%;
    }
  }
}

@media (min-width: 992px) AND (max-width: 1199px) {
  .modal-xl {
    max-width: 972px;
  }
}

@media (min-width: 768px) AND (max-width: 991px) {
  .modal-xl {
    max-width: 768px;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 1024px) {
  .ipad-no-scroll {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: fixed;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .modal-open {
    .modal-search {
      &.xs-d-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 3%;
        max-width: 94%;

        .modal-content {
          padding: 100px 50px;
          text-align: center;
          .modal-header {
            position: absolute;
            top: 25px;
            right: 25px;
          }
        }
      }
    }
  }
}

body {
  &.modal-open {
    -webkit-overflow-scrolling: touch;
    overflow: hidden !important;
  }
}

.modal-backdrop {
  background-color: $modal-backdrop;
  &.notes-backdrop {
    + .modal {
      height: 100%;
      overflow: hidden;
    }
  }
}

.modal-content {
  border-radius: 16px;
  background: $modal-bg;
  padding: 26px 45px;
  border: 0;
  box-shadow: $modal-shadow;
}

.modal-header {
  .ifill-os-close {
    font-size: 20px;
    color: $level-8 !important;
  }

  .close-search-modal {
    background: transparent;
    color: $level-8;
  }
}

.modal {
  .form-label {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 0.75rem;
  }
  .row-margin-bottom {
    margin-bottom: 30px;
  }

  .modal-header {
    .card-title {
      margin: 0;
    }

    border: none;
  }

  .modal-body{
    &.image-edit{
      .btn{
        margin-top: 10px;
        width: 200px;
      }
      .col-md-12{
        text-align: center;
      }
    }
  }

  .modal-close{
    position: absolute;
    top: 30px;
    right: 60px;
  }

  .modal-footer {
    border: none;
  }
}

.modal-xl {

  &.modal-global {
    .modal-content {
      padding: 0;
    }

    .modal-header {
      padding: 25px 30px;

      .add-message-header-row {
        width: 100%;
      }
    }

    .modal-body {
      padding: 30px;

      .modal-form-block {
        textarea {
          height: 150px !important;
        }
      }
    }

    .modal-footer {
      padding: 30px;

      & > :not(:first-child) {
        margin-left: 15px;
      }

      & > :not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  &.modal-search {
    width: 96%;
    max-width: 650px;

    &.modal-get-started {
      max-width: 900px;
    }

    .modal-content {
      padding: 24px 24px 32px;
    }

    .modal-header {
      padding: 0;
    }

    .modal-body {
      padding: 20px 0 0;

      .cases-search-block {
        .nav-search {
          width: 100%;
        }
      }
    }

    .category-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .category-button {
        width: 24%;
        height: 40px;
        border: solid 1px $btn-secondary;
        padding: 0 10px 0 16px;
        display: flex;
        align-items: center;
        color: $btn-secondary;
        font-size: 13px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        letter-spacing: .8px;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 1.33%;

        &:last-child {
          margin-right: 0;
        }

        &.object-filter-active {
          border-color: $btn-secondary-hover;
          background: $btn-secondary-hover;
        }

        i {
          margin-right: 10px;
          position: relative;
          top: 0px;
          font-size: 16px;
        }

        &:hover {
          background-color: $btn-secondary-hover;
        }
      }
    }

    .nav-search-with-text {
      margin-top: 25px;
      font-size: 20px;
    }

    .nav-search-holder {
      margin-top: 20px;
      height: auto;
      flex-direction: column;

      &.input-focus {
        .nav-search-container {
          border: none;
          border-radius: 0;
        }
      }

      .nav-search-container {
        padding-left: 0;
        border: none;
        background-color: transparent;
      }
    }

    .search-results {
      width: 100%;
      height: auto;
      padding-bottom: 20px;
      position: relative;
      top: 25px;
      background: transparent;

      .search-group {
        margin: 0;
        border-bottom: none;
        border-top: solid 1px rgba(255, 255, 255, .15);
        padding-top: 10px;

        &:first-child {
          padding-top: 0;
          border-top: none;
        }
      }

      .search-group-headding {
        padding-top: 0;
        padding-bottom: 15px;
        font-size: 15px;

        .search-group-info-icon {
          cursor: pointer;
          position: relative;


          .search-group-info-tooltip {
            width: 160px;
            padding: 15px 10px;
            display: none;
            position: absolute;
            top: 30px;
            right: -10px;
            z-index: 9;
            color: $level-11;
            font-size: 11px;
            font-weight: $font-weight-bold;
            text-align: center;
            background: $block;
            border-radius: 5px;

            &::before {
              content: '';
              width: 0;
              height: 0;
              border-left: solid 10px transparent;
              border-right: solid 10px transparent;
              border-bottom: solid 10px $block;
              position: absolute;
              top: -10px;
              right: 9px;
            }
          }

          &:hover {
            .search-group-info-tooltip {
              display: block;
            }
          }
        }
      }

      .search-group-icon {
        margin-right: 12px;
        cursor: default;

        &.icon-tags{
          position: relative;
          top: 2px;
          opacity: .5;
        }

        &.ifill-os-fill-case-2-d {
          position: relative;
          top: 3px;
          font-size: 12px;
        }
      }

      .search-result {
        margin: 0 -24px;
        padding: 10px 16px;
        font-size: 12px;

        &:first-child {
          margin-top: 0;
        }
      }

      .search-view-all {
        margin-top: 2px;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  &.modal-invite {
    .modal-header {
      &.modal-invite-header {
        border: none;
        justify-content: flex-end;

        &.border-none {
          border-bottom: 0;
          padding-top: 20px;
        }

        .ifill-os-invite {
          width: 20px;
          height: 20px;
          background-size: 20px auto;
        }
      }
    }

    .modal-invite-body {
      padding-bottom: 0;

      .invite-modal-text-row {
        padding: 30px 20px 50px;

        &.px-25 {
          padding: 30px 25px 30px;
        }
      }
    }

    .share-modal-content-row {
      .form-error {
        font-size: 12px;
      }
    }
  }

  &.modal-users {
    &.modal-chatgroup {
      width: 500px;
      margin-top: 0;
      position: relative;
      top: 10%;

      .modal-content {
        padding: 0px;

        .modal-header {
          padding: 16px 24px 32px;

          .ifill-os-close {
            cursor: pointer;
          }
        }
      }
      .user-listing {
        &.chat-listing {
          max-height: 300px;
          margin: 0px 24px 15px 24px ;
          padding: 0px 0px;
          list-style: none;
          overflow: hidden;
          overflow-y: auto;

          .user-listing-row,
          .dr-connect-list-item {
            width: 100%;
            margin: 0px 0 24px 0;
            padding: 0;
            display: flex;
            align-items: center;

            &:last-child {
              margin-bottom: 10px;
            }
          }

          .dr-connect-list-item {
            padding: 0;
          }
          .user-image {
            width: 40px;

            img {
              width: 40px;
              height: 40px;
            }
            div {
              &.avatar {
                @include avatar(40px);
                margin-left: 2px;
              }
            }
          }

          .user-name {
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            margin-left: 16px;

            .degree-list {
              display: block;
              font-size: 12px;
              color: $level-9;
            }
          }

          .user-image {
            .icon-span {
              display: flex;
              width: 16px;
              height: 16px;
              line-height: 12px;
              min-width: 16px;
              font-size: 10px;

              i {
                font-size: inherit !important;
              }
            }
          }
        }
      }
      @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
        width: 100%;
        margin-top: 0;
        position: relative;
        top: 0%;

        .modal-content {
          height: 100vh;
          padding: 0;
          min-height: 100vh;

          .modal-header {
            height: 64px;
            padding: 15px 25px 15px 15px;
            margin :0;
            line-height: normal;

            .card-title {
              font-size: 18px;
            }
          }
        }
        .user-listing {
          &.chat-listing {
            height: calc(100vh - 64px);
            max-height: unset;
            padding: 15px 15px 15px;
            margin: 0px 0px;
            .user-image {
              width: 36px;
              margin-right: 10px;

              img {
                width: 36px;
                height: 36px;
              }

              div {
                &.avatar {
                  @include avatar(36px);
                }
              }
            }
            .user-name {
              font-size: 13px;
              line-height: 14px;

              .degree-list {
                display: block;
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    &.label-modal {
      width: 536px;

      .modal-header {
        padding: 24px 32px !important;
      }
      .modal-body {
        padding: 0 32px 40px;
      }

      @media screen and (max-width: 767px) {
        width : 100%;

        .modal-header {
          padding: 24px 16px !important;
        }
        .modal-body {
          padding: 0 16px 40px;
        }

      }
    }
    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      width: 100%;
      max-width: unset;
      height: 100%;
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
      &.modal-chatgroup {
        .modal-content {
          height: 100%;
          border: none;
          padding: 0;
          border-radius: 0;
          min-height: 100%;

        }
      }

      .modal-header {
        height: 64px;
        padding: 15px 25px 15px 15px;
        line-height: normal;

        .card-title {
          font-size: 18px;
        }
      }

      .user-listing {
        height: calc(100vh - 64px);
        margin: 0;
        padding: 15px 15px 15px;
        list-style: none;
        overflow: hidden;
        overflow-y: auto;

        .user-listing-row {
          width: 100%;
          margin: 15px 0 0;
          padding: 0;
          display: flex;
          align-items: center;
        }

        .user-image {
          width: 36px;
          margin-right: 10px;

          img {
            width: 36px;
            height: 36px;
          }

          div {
            &.avatar {
              @include avatar(36px);
            }
          }
        }

        .user-name {
          font-size: 13px;
          line-height: 14px;

          .degree-list {
            display: block;
            font-size: 10px;
          }
        }
      }
    }
  }


  &.modal-attachment {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    margin: 0;

     .modal-content {
      height: 100%;
    }

     .modal-header {
      .ifill-os-close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
    }

    .attachments-content-sidebar {
      width: 300px;
      padding: 30px 20px 0;
      position: absolute;
      top: 50px;
      right: 30px;
      z-index: 9;

       .attachment-sidebar-row {

         &.file-name {
          font-size: 16px;
          font-weight: $font-weight-bold;
          line-height: 18px;
          word-break: break-all;
        }

         &.file-created-at {
          margin-top: 10px;
          font-size: 12px;
        }

         &.attachment-file-size {
          font-size: 12px;
        }

         &.btns-row {
          margin-top: 15px;
        }

         .attachment-sidebar-btns {
          width: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          + .attachment-sidebar-btns {
            margin-left: 0;
          }

          &:first-child {
            margin-top: 0;
          }

           i {
            margin-right: 10px;
            position: relative;
            order: -1;

            &.ifill-os-views {
              font-size: 14px;
            }
          }
        }
      }
    }

     .modal-body {
      height: 100%;
      margin-top: 20px;
      padding: 0;
      overflow: hidden;
      overflow-y: auto;
    }

     .attachments-content-holder {
      color: $level-11;
      width: calc(100% - 300px);
      height: 100%;
      margin: 0;
      text-align: center;

       .react-pdf__Document {
        .react-pdf__Page__canvas {
          max-width: 100%;
        }
      }

       .pdf-canvas {
        canvas {
          width: 100%;
        }
      }

       .attachment-content-img {
        max-width: 100%;
        max-height: 600px;
      }

       .conversation-attachment-icon-holder {
        width: 200px;
        margin: 0 auto;
        padding-bottom: 20px;
        position: relative;

         .conversation-attachement-icon {
          background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
          width: 200px;
          height: 252px;
          display: inline-block;
          background-size: 200px auto;
        }

         .conversation-attachment-ext-name {
          width: auto;
          max-width: 160px;
          min-width: 120px;
          height: 50px;
          border: solid 4px $level-5;
          padding: 0 10px;
          overflow: hidden;
          position: absolute;
          top: 120px;
          left: 130px;
          color: $level-1;
          font-size: 24px;
          font-weight: $font-weight-bold;
          line-height: 40px;
          text-align: center;
          background: $level-12;
          border-radius: 4px;
          white-space: nowrap;
        }
      }
      .video-container {
        video {
          max-width: 100%;
          max-height: 600px;
        }
      }

      .pdf-loading {
        color: $level-1;
      }

       .unsupported-message {
          .attachments-content-holder {
            width: 100%;
          }
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      width: 100%;
      height: 100%;
      top: 0;


      .modal-body {
        margin-top: 0;
        padding-top: 60px;
      }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      .attachments-content-sidebar {
        width: 220px;
        padding: 30px 10px 0 10px;
        right: 10px;
      }

       .attachments-content-holder {
        width: calc(100% - 220px);
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    &.modal-invite {
      .modal-header {
        .ifill-os-close {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

.modal-xl {
  &.classification-modal {
    .modal-content {
      height: 100%;
      border: 0;
    }

    .sidebar-subcategory-container {
      .sidebar-back-link-block {
        display: none;
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    &.modal-invite {
      .modal-header {
        .ifill-os-close {
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

body {
  &.free-upgrade-plan {
    .modal-backdrop {
      z-index: auto;
    }

    .modal {
      z-index: 1;
      top: 90px;
    }

    #root {
      z-index: 1;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      .modal {
        z-index: 1;
        top: 70px;
      }
    }
    @media only screen and (min-width : 320px) and (max-device-width : 767px) {
      .modal {
        z-index: 1;
        top: 0px;
      }
      #root {
        z-index: 1;
      }
    }
  }
}

body {
  &.header-visible {
    #root {
      z-index: auto;
    }

    .modal-update-backdrop {
      z-index: 1;
      + .modal {
        z-index: 1;
        .modal-header {
          padding: 15px 30px
        }
      }
    }
  }
  @media only screen and (min-width : 320px) and (max-device-width : 767px) {
    .modal-update-backdrop {
      z-index: 1;
      margin-top: 0px;
      + .modal {
        z-index: 999;
        top: 0px;
        .modal-update-password {
          .btn-save-user {
            background-color: #fff;
          }
        }
      }
    }
    #root {
      z-index: auto;
    }
  }
}

.modal-comment-mobile {
  padding: 0;
  width: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;

  .modal-content {
    border-radius: 0;
    padding: 40px 20px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .comment-mobile-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 24px;
      position: relative;
      i {
        font-size: 24px;
        color: $level-11;
        position: absolute;
        left: 0;
        top: 0;
      }
      span {
        font-size: 16px;
        font-weight: $font-weight-bold;
      }
    }

    .comments-primary-avatar {
      .avatar {
        &.avatar-47 {
          text-indent: 0;
        }
      }
    }

    .case-hash-tag-listing {
      padding-top: 5px;
      padding-bottom: 30px !important;

      .fieldset {
        flex-direction: column;
        align-items: flex-start;

        + .comment-error {
          position: relative;
          top: 0;
        }

        .checkbox-comment-list {
          flex-direction: column;
          align-items: flex-start;
          padding-left: 0;
          margin-left: -5px;
        }

        .custom-checkbox {
          margin-top: 0px;
          padding: 0px 5px;;
        }

        .legend {
          margin:0;
        }
      }
    }

    .detail-comments-top-block {
      .comments-primary-avatar {
        top: 6px;
        left: 5px;

        .avatar {
          @include avatar(26px);
          padding-top: 0;
        }
      }
    }

    .textarea-block {
      position: relative;
    }
    .comment-error {
      position: absolute;
      top: 100%;
      left: 0;
      color: $lt-red;
      font-size: 12px;
      margin-top: 5px;

      .error-icon {
        margin-right: 5px;
      }
    }

    .reply-buttons {
      display: none;
    }


    form {
      .detail-comments-top-block {
        margin: 0px 0 18px !important;
        align-items: center;

        .comments-primary-avatar {
          top: 8px;
          left: 5px;

          .avatar {
            @include avatar(26px);
          }
        }

        .comment-add-attachments-link {
          .helper-holder {
            max-height: 35px;
          }
        }
      }
      .media-actions {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        margin-left: -8px;

        .comment-attachment-icons {
          display: flex;
          flex-direction: column;
          width: 100%;
          .attachment-label {
            font-size: 14px;
            font-weight: $font-weight-normal;
            text-transform: capitalize;
            span{
              display: none;
            }
          }
          .media-links {
            flex-direction: column;
            width: 100%;
            ul {
              list-style: none;
              padding-left: 0;
              margin: 0;

              li {
                padding-left: 16px;
                padding-right: 16px;
                display: block;
                padding: 10px 0;
                display: flex;
                align-items: center;

                &:last-child {
                  border-bottom: 0;
                }
                span {
                  font-size: 13px;
                }
                i{
                  margin-right: 12px;
                  width: 20px !important;
                  color: #8c8c8c;
                }

              }
            }
          }
        }

        .media-actions-buttons {
          text-align: right;

          .btn {
            font-size: 12px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            border-radius: 17px;
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }

    .media-body{
      position: relative;

      .user-suggestion-listings {
        width: 100%;
        max-height: 150px;
        border: solid 1px $block;
        padding: 0px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $block;
        border-radius: 0 0 4px 5px;
        z-index: 2;
        cursor: auto;
      }

      .user-suggestion-listings-row {
        margin: 0;
        border-top: 0;
        padding:0 10px;
        list-style: none;
        cursor: pointer;
        font-size: 12px;
        line-height: 2.25rem;
        font-weight: normal;

        &:hover {
          background-color: rgba(72, 172, 253, 0.2);
          color: $os-link;
        }
      }

      .avatar {
        margin-right: 8px;
        padding-right: 1px;
        font-size: 8px;
        line-height: 19px;
        text-indent: 0;
        position: relative;
        top: -1px;
      }
    }
  }
  @media only screen and (min-device-width : 320px) and (max-device-width : 812px) and (orientation: landscape) {
    max-width: 100%;

    .modal-content {
      border-radius: 0;
      padding: 40px 20px;
      min-height: 100vh;

      .comment-mobile-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        position: relative;

        span {
          font-size: 16px;
          color: $level-11;
          font-weight: $font-weight-bold;
        }
      }
      .comments-primary-avatar {
        position: relative;
        top: 4px;
      }

      .detail-comments-top-block {
        .comments-primary-avatar {
          position: absolute;
        }
      }

      textarea{
        font-size: 14px;
        resize: none;
      }

      .textarea-block {
        position: relative;
      }
      form {
        fieldset {
          display: block;
          margin-inline-start: 1px;
          margin-inline-end: 1px;
          padding-block-start: 0.35em;
          padding-inline-start: 0.75em;
          padding-inline-end: 0.75em;
          padding-block-end: 0.625em;
          min-inline-size: min-content;
          border-width: 1px;
          border-style: groove;
          border-color: rgba(100,100,100,0.2);
          border-image: initial;
          border-radius: 5px;

          legend {
            display: block;
            width: auto;
            padding-inline-start: 2px;
            padding-inline-end: 2px;
            border-width: initial;
            border-style: none;
            border-color: initial;
            border-image: initial;
            font-size: 14px;
            font-weight: $font-weight-bold;
            margin-bottom: 0.8rem;
            span {
              font-size: 9px;
              font-weight: normal;
            }
          }

          .checkbox-comment-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            .custom-checkbox{
              position: relative;
              font-size: 14px;
              padding-bottom: 0px;

              .custom-control-input {
                left: 0;
                top: 0;
                z-index: 1;
              }

              .ifill-os-checkmark-default-white{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0%, -50%);
                text-align: center;
              }
              input:checked ~ .ifill-os-checkmark-default-white {
                &:before {
                  content: "\e94f";
                  color: $level-4;
                }
              }
            }
          }
        }

        .media-actions {
          .comment-attachment-icons {
            flex-direction: row;
            align-items: center;
            width: 100%;
            .media-links {
              flex-direction: row;
              ul {
                list-style: none;
                padding-left: 30px;
                margin: 0;
                li {
                  display: inline-block;
                  border-bottom: 0;

                  &:last-child {
                    border-bottom: 0;
                  }
                  span {
                    display: none;
                  }
                  i{
                    margin-right: 12px;
                    width: 20px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .media-body {
    position: relative;
  }
}

.modal-all-board-mobile {
  padding: 0;
  width: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;

  .modal-content {
    border-radius: 0;
    padding: 0 20px 20px;
    height: 100%;
    overflow: hidden;

    .modal-mobile-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 40px;
      background: $modal-bg;

      i {
        &.ifill-os-close {
          font-size: 14px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      span {
        &.all-text-heading {
          font-size: 14px;
          font-weight: $font-weight-bold;
          display: flex;
          i {
            margin-right: 7px;
            font-size: 16px;
          }
          span{

          }
        }
      }
    }

  }
  .media-body {
    position: relative;
  }

  .modal-card-section {
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    max-height: 100%;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 812px) and (orientation: landscape) {
    max-width: 100%;
    .modal-card-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .col-12 {
        max-width: 50%;
        flex-basis: 50%;
      }
    }
  }
}

.modal-xl {
  &.modal-global {
    &.modal-privacy-policy {
      .modal-header {
        border-bottom: 0;
        border-bottom: 0;
        flex-direction: column;
        position: relative;
        h4{
          text-decoration: none;
          margin-top: 20px;
          font-size: 1.5rem;
        }
        h6{
          margin-top: 0;
          text-align: left;
          margin-bottom: 0px;
          width: 100%;
        }
        i {
          position: fixed;
          right: 50px;
          cursor: pointer;
          top: 30px;
          font-size: 22px;
          z-index: 9;
        }
      }

      .modal-footer {
        .btn-signup {
          cursor: pointer;
        }
      }

      h4 {
        margin-top: 0px;
        margin-bottom: 15px;
        text-decoration: underline;
      }
      p {
        margin-bottom: 20px;
        font-weight: 300;
        span {
          font-size: 18px;
          letter-spacing: 4px;
          font-weight: 600;
        }
        b{
          font-weight: bold;
        }
      }

      .invisible {
        display: none !important;
      }


      li {
        font-weight: 300;
        b {
          font-weight: bold;
        }
      }



      @media (min-width: 768px) and (max-width: 1023px) {
        .modal-header {
          i {
            right: 12px;
            top: 15px;
          }
        }
      }
      @media (min-width: 1024px) and (max-width: 1366px) {
        .modal-header {
          i {
            right: 30px;
            top: 15px;
          }
        }
      }
      @media (min-width: 320px) and (max-width: 767px) {
        .modal-content {
          .m-text {
            font-size: 14px;
          }
          .modal-header {
            i {
              right: 20px;
              top: 15px;
            }
            h6{
              text-align: center;
              margin-top: 10px;
            }
          }
        }

      }
    }
  }
}

.comment-add-message-modal {
  .modal-content {
    background: transparent;
    border: 0;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    position: absolute;
    width: 100%;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;

    .modal-content {
      height: 100%;
      padding: 0;
      margin: 0;

      .assign-background {
        height: 100%;
        position: relative;
        .assign-overlay {
          &.add-to-message-modal {
            width: 100%;

            &.assign-overlay--show {
              position: relative;
              top: 0;
              height: 100%;
            }
            .add-modal-top-block {
              position: relative;
            }
            .modal-body {
              height: calc(100% - 130px) !important;
              > .row:not(.header-row) {
                height: 100% !important;
                padding-bottom: 0;
                &.tools-row {
                  height: 100% !important;
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .add-to-message-modal  {
      .modal-xl.add-message-modal {
        .modal-body {
          .tools-cards-container {
            height: auto;
            max-height: 100% !important;
            padding-bottom: 0;
            .cards {
              max-height: none !important;
            }
          }
        }
      }
    }
  }
}

.modal-small-custom {
  height: 100%;
  display: flex;
  margin-top: 0;
  margin-bottom: 0;

  .modal-content {
    width: 500px;
    margin: auto;
    text-align: center;
    padding:0px 0 0 0;

    i.main-icon {
      font-size: 24px;
      border: 1px solid;
      border-radius: 100%;
      padding: 16px;
      color: #a0a0a0;
      display: inline-block;

      &.ifill-os-close {
        position: absolute;
        right: 15px;
        top: 13px;
        cursor: pointer;
        border: 0;
        margin-bottom:0;
        padding: 0;
        font-size: 15px;
      }

      &.no-border {
        border: 0;
        border-radius: 0;
        padding: 0;
        color: $level-11;
        font-size: inherit;
      }
    }

    .modal-body {
      padding: 40px 20px;

      h4 {
        font-size: 16px;
        font-weight: $font-weight-bold;
      }

      p{
        font-size: 18px;
        font-weight: lighter;
        padding: 25px 50px;
      }
    }

    .btn {
      width: auto;
      height: 34px;
      margin: 0;
      border: 1px solid;
      padding: 0 30px;
      display: inline-block;
      font-size: 12px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 34px;
      border-radius: 20px;
      cursor: pointer;
      background-color: transparent;

      &.mt-40 {
        margin-top: 30px;
      }
    }

    .continue-btn{
      font-size: 12px;
      text-align: center;
      display: block;
      text-transform: uppercase;
      margin-top: 20px;
      font-weight: bold;
      letter-spacing: 0.5px;
      cursor: pointer;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px)  {
    .modal-content {
      width: 300px;
      margin: auto;

      i {
        font-size: 14px;
      }

      .modal-body {
        p{
          font-size: 14px;
          font-weight: $font-weight-normal;
          padding: 10px 30px;
        }
      }
    }
  }
}

body {
  &.modal-open {
    &.login{
      #root {
        filter: blur(7px) brightness(0.9);
      }
    }


    #root {
      filter: blur(3px) brightness(0.9);
    }


    &.pulse-add-body {
      #root {
        filter: none;
      }
    }


    &.cases {
      #root {
        min-height: 100vh;
      }
    }
  }
}

.mobile-device {
  .w-1006 {
    width: 100%;
  }
  &.portrait {
    .modal-comment-mobile {
      .comment-attachment-block {
        .col-6 {
          flex: 100%;
          max-width: 100%;
        }
      }

      .media-actions {
        .osbtn {
          margin-left: -7px;
        }
      }
    }
  }

  .modal-comment-mobile {
    .modal-content {
      max-height: 100%;
      overflow:  hidden;
      overflow-y: auto;
    }
  }

  .attachments-top-content-holder {
    padding-right: 60px;
  }
}

.modal-all-board-mobile
.calendly-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}

.image-edit-loader-modal-465 {
  height: 465px;
}

.image-edit-loader-modal-512 {
  height: 512px;
  min-height: auto;

  .loader-inner {
    margin-top: 0;
  }
}


.mobile-device {
  .modal-xl.modal-attachment {
    .attachments-content-holder {
      .conversation-attachment-icon-holder {
        .conversation-attachement-icon {
          width: 78px;
          height: 120px;
          background-size: 78px auto;
          background-position: center center;
        }

        .conversation-attachment-ext-name {
          max-width: 80px;
          min-width: 60px;
          height: 32px;
          border: solid 2px #d2d2d2;
          top: 56px;
          left: 120px;
          font-size: 16px;
          line-height: 30px;
        }
      }

      .process-text {
        font-size: 16px;
      }
    }
  }

  .companies {
    .modal-height-80 {
      padding: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}


.modal {
  + .modal-backdrop-custom {
    z-index: 1051;

    + .modal {
      z-index: 1055;
    }
  }
}


.companies {
  .modal-height-80 {
    height: 100%;
    padding: 3% 0;

    @media(min-height: 700px) {
      padding: 7.5% 0;
    }

    margin: auto;

    .modal-content {
      height: 100%;
    }

    .card-title {
      @include text-truncate();
      padding-right: 30px;
    }

    .case-video {
      padding-bottom: 0;
      height: 100% !important;

      .video-player {
        padding-top: 10px;
      }
    }
  }
}

.video-player {
  iframe {
    height: 100%;
    width: 100%;
  }
}


.modal-attachment {
  .modal-content {
    background-color: $level-3;

    .modal-body {
      background-color: $block;
    }

    .create-slider {
      .slick-slider{
        &.thumb-slider {
          .slick-slide {
            img {
              opacity: 0.4;
            }

            &.slick-current {
              img {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.media-comment-head {
  .editor-modal-placeholder {
    width: 100vw;
    height: 52px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    background-color: #f4f7f9;

    .placeholder-text {
      padding-left: 24px;
      line-height: 1.4;
      font-size: 20px;
      color: #7E91A5;
    }
  }
}