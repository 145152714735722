.message-card-head{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.left-message-col{
  display: flex;
  gap: 8px;
  align-items: center;
}

.right-message-col{
  display: flex;
  margin-left: 48px;
}
