.global-search-view-container {
  display: flex;
  justify-content: center;

  mark {
    background: #c1d7f7;
    border-radius: 4px;
  }
  .global-search-view-wrapper {
    margin-top: 43px;
    min-width: 900px;
    max-width: 900;
    font-family: 'Inter', sans-serif;
    font-style: normal;

    display: flex;
    flex-direction: column;
    gap: 24px;

    .header {
      .global-search-view-query-text {
        margin-bottom: 24px;
        height: 33px;
        align-self: stretch;
        color: #5d7893;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;

        .global-text {
          color: #354554;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .global-search-view-tab-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        .tab {
          display: flex;
          height: 40px;
          flex-direction: column;
          align-items: center;
          gap: 2px;
          cursor: pointer;
          color: #7e91a5;

          .tab-text {
            color: inherit;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }

          .underline {
            height: 2px;
            flex-shrink: 0;
            align-self: stretch;
            color: transparent;
          }
        }
      }
    }

    .global-search-view-tab-content {
      max-width: 1300px !important;
      min-width: 1300px !important;
      .table-wrap {
        .patient-directory-accordion {
          .accordion-item {
            border-style: none;
            .accordion-header {
              .collapsed[type='button'] {
                .ifill-os-chevron-caret-down {
                  display: unset;
                }
                .ifill-os-chevron-caret-up {
                  display: none;
                }
              }

              button {
                border: none;
                color: unset;
                background-color: unset;
                box-shadow: none;

                .ifill-os-chevron-caret-down {
                  display: none;
                }

                .section-title {
                  color: var(--neutrals-level-9, #5d7893);
                  font-family: Inter, sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 16px;
                  letter-spacing: 0.2px;
                  text-transform: uppercase;
                }
              }
            }
            .accordion-body {
              padding: 0;
            }
          }
        }
      }

      .space-links-container {
        .table-list {
          position: relative;
        }
      }
    }

    .empty-state {
      height: 96px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
      .global-search-view-query-text {
        margin-bottom: 24px;
        height: 33px;
        align-self: stretch;
        color: #5d7893;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;

        .global-text {
          color: #354554;
          font-weight: 600;
          line-height: 24px;
        }
      }
      h2 {
        color: #354554;
      }

      p {
        color: #5d7893;
      }
    }
  }
}
