.comming-soon-section {
  height: calc(100vh - 136px);
  flex-direction: column;
  background-size: cover;
  background-position: center top;
  position: relative;
  inset: 72px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: auto;
  margin-left: auto;

  &::before {
    position: absolute;
    inset: 0;
    content: '';
  }

  .col {
    max-width: 504px;
    display: contents;

    svg {
      margin-top: -72px;

      @media(min-width: 768px) {
        width: 200px;
        height: 200px;
      }
    }
  }

}
