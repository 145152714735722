%plan-form-control {
  @include font-text($font-weight-normal, 16px, 1)
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $block-shadow;
  background-color: $block;
  color: $level-11;
  padding: 0px 16px;
  border: 1px solid transparent;
  outline: 0;

  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

  &:active,
  &:focus,
  &:focus,
  &.StripeElement--focus {
    border:1px solid  $cl-link;
    box-shadow: none;
    outline: none;
  }

  @include placeholder {
    color: $stripe-placeholder-color !important;
  }
}


.Select-value-label {
  color: $level-11 !important;
}

.Select--multi {
  .Select-value-label {
    color: $level-11 !important;
    padding-left: 8px !important;
  }
}

.Select-clear {
  display: none !important;
}

.center-content{
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-card .row .col-6{
  &:nth-child(odd){
    .signup-topic-name{
      margin-left: 21px;
    }
    .signup-topic-tick{
      left: 33px;
    }
  }
}

.icon-row{
  font-size: 24px;
}

.signup-account-details-card {
  padding: 64px 200px 0;

  &.signup-mobile-auth-card {
    padding-top: 64px;
    &.signup-payment-card {
      padding-top: 30px;

      .signup-auth-icon-block {
        padding:0px  10px;
        .ifill-os-payment {
          font-size: 60px;
        }
      }

      .signup-card-headding {
        margin-top: 10px;
      }

      .signup-payment-form-block {
        margin-top: 5px;

        form {
          position: relative;

          .form-error {
            &.form-error-1 {
              position: absolute;
              top: 0;
              left: 0;
              right: auto;
              bottom: auto;
            }
          }
        }
      }

      .signup-payment-form-row {
        width: 100%;
        margin-top: 15px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .signup-payment-number-row {
          width: 49%;
          margin-top: 0;
        }
        .payment-d-flex {
          width: 100%;
          flex-direction: row;
          display: flex !important;
          justify-content: space-around;

          .date-cvc {
            flex-basis: 49%;
            width: 49%;
            display: inline-block;

            .signup-payment-number-row {
              width: 100% !important;
            }

            .form-error {
              position: relative;
              top: 2px;
              margin: 0;
              line-height: 11px;
            }
          }
        }
      }

      .signup-payment-number-row {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 0;
        position: relative;
        color: $level-11;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: .8px;

        .StripeElement--invalid,
        .error-field
        {
          border: 1px solid $lt-red !important;
          color: $lt-red !important;
        }

        .StripeElement,
        .signup-payment-input-field {
          width: 100%;
          margin-top: 5px;

          &.pt-0 {
            padding-top: 0px;
          }

          @extend %plan-form-control;
        }

        .signup-payment-input-field {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }

        .StripeElement {
          &+ .form-error {
            top: auto;
            bottom: -16px;
            right: auto;
            left: 0;
            text-align: left;
          }
        }
      }
      .signup-payment-required-text {
        position: absolute;
        right: 0;
        top: 3px;
        color: $level-6;
        font-size: 12px;
        font-weight: $font-weight-bold;
        letter-spacing: .8px;
      }

      .card-action {
        margin-top: 25px;

        .btn{
          &:disabled {
            opacity: 1;
          }
        }
      }
    }
  }
}

.mobile-device {
  .signup-account-user-card {
    .os__value-container {
      max-height: 65px;
    }

    .Select--multi {
      .Select-multi-value-wrapper {
        max-height: 65px;
      }
    }
    .card-action {
      margin-top: 25px;
    }
  }

  .os__placeholder {
    font-size: 13px;
  }

  .account_settings {
    .os__placeholder {
      font-size: 16px;
    }
  }
}

.signup-account-details-card {
  &.signup-account-user-card {
    .Select-menu-outer {
      max-height: 170px;
    }

    .Select-menu {
      max-height: 140px;
    }
  }
}

.mobile-device {
  .signup-account-details-card {
    &.signup-account-user-card {
      .Select-menu-outer {
        max-height: 130px;
      }

      .Select-menu {
        max-height: 120px;
      }
    }
  }

  .signup-card-edit-link {
    top: -3px !important;
  }

  .signup-card-group {
    .signup-card-group-name{
      &.name-row {
        max-height: 43px;
      }
    }
  }
}

.signup-card {
  .os__value-container {
    padding: 2px 16px;
  }

  .os__multi-value {
    .os__multi-value__label {
      line-height: 1.4;
      padding: 0 5px;
      font-weight: 300;
      font-size: 1rem;
    }

    .os__multi-value__remove {
      margin-left: 0;
    }
  }

  .os__menu-list {
    max-height: 198px;
  }
}

body {
  .os__value-container {
    padding: 4px 16px;

    .os__multi-value__remove {

    }
  }

  .os__menu {
    box-shadow: none;
  }

  .error {
    .os__control {
      border-color : $lt-red;
    }
  }

  .os__control--menu-is-open,
  .os__control--is-focused {
    border-color: $input-border-active !important;
    box-shadow: $input-shadow;
  }

  .os__menu-notice {
    text-align: left;
    font-size: 14px;
  }

}
