.inbox-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .inbox {
    max-width: calc(100% - 128px);
    width: 1024px;
    .is-hidden {
      display: none;
    }
    .inbox-header {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      margin-top: 8px;
      margin-bottom: 16px;

      .pending-label {
        font-size: 13px;
        padding-top: 8px;
        font-weight: 600;
        color: var(--neutrals-level-9, #5d7893);
      }

      .label {
        display: flex;
        height: 40px;
        align-items: center;
        flex: 1 0 0;
        color: var(--neutrals-level-11, #354554);
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        .i-action {
          display: flex;
          font-size: 16px;
          padding: 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .header-action {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--neutrals-level-11, #354554);
        color: var(--surface-borders-level-1, #fff);
        cursor: pointer;
        .text {
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }
      }
    }
  }
}
