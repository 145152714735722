$breakpoint-tablet: 1024px;

.tasks{
  .left-wrap{
    @media(min-width: 768px) and (max-width: 1170px){
      top:80px;
    }
  }
}
.board-tasks-manager{
  .xl-middle-bar{
    flex: 0 0 80%;
    max-width: calc(100% - 368px);
  }
  .row-boxed {
    @media(max-width: 1439px){
      max-width: 100%;
    }
  }
}
.tasks {
  overflow-y: scroll;
}

.taskManagerWrapper {
  width: 100%;
  height: 100%;

  @media (max-width: $breakpoint-tablet) {
    padding: 20px 32px;
  }

  .taskManagerHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    &.mobile-bar{
      .team-and-search-bar{
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;

        .board-view-header{
          flex-direction: column;
          .back-and-heading{
            display: flex;
            gap: 16px;
            align-items: center;

            span{
              display: block;
            }
          }
        }

        .taskManagerButtonContainer{
          width:100%;
          justify-content: flex-end;

          .filterInput{
            width: 100%;

            .hidden_for_list_view{
              display: block;
            }
          }
        }
      }
    }

    .team-and-search-bar{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .teamMembersDropdown {
        position: relative;
        color: #354554;
        font-size: 20px;
        font-family: Inter;
        font-weight: 600;
        line-height: 28px;
        .avatar {
          border-radius: 50% !important;

          img {
            border-radius: 50% !important;
          }
        }

        .os-menu-drop,
        .os__control,
        .os__value-container {
          z-index: 11;
          padding: 0;
          padding-right: 20px;
          background-color: #f8f8f8;
          border-color: #f8f8f8;

          .os-menu-drop,
          .os__single-value,
          .css-y6eqrk-singleValue {
            max-width: 100%;
            min-width: 100px;
            flex-grow: 0;
            font-family: Inter;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: #354554;
          }
        }

        .os__menu {
          position: absolute;
          left: 90%;
          box-shadow: none;
          border-radius: 8px;

          .os__menu-list {
            max-height: 400px;
            min-width: max-content;
            background-color: #fff;
            border-color: #f8f8f8;
            border-radius: 8px;
            box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);

            .os__option {
              padding: 0 0px;
              height: 40px;
              display: flex;
              align-items: center;
              flex-grow: 0;
              font-family: Inter;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
              text-align: left;
              color: #5d7893;
            }

             .os__option:nth-child(-n+3) {
               padding: 0 16px;
             }
          }
        }
      }

      .board-view-header {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        .back-and-heading{
          display: flex;

          span{
            display: none;
          }

        }

        // Person filter applied only when task assigned by you option is selected.
        .member {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: #edeff3;
          border-radius: 16px;
          padding: 4px 8px;
          margin-left: 8px;
          justify-content: center;
          height: 32px;

          .memberName {
            font-size: 16px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: #7E91A5;
          }
        }
      }

      .labelMisc {
        max-width: max-content;
        height: 32px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        padding: 8px 12px;
        border-radius: 16px;

        flex-grow: 0;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        cursor: pointer;

      }

      .taskManagerButtonContainer {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .filterInput {
        width: 280px;
        height: 40px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 12px 16px;
        border-radius: 8px;
        border: solid 1px #eff1f4;
        background-color: #ffffff;
        cursor: pointer;

        .hidden_for_list_view {
          display: none;
        }

        input {
          width: 100%;
        }

        // @media (max-width: $breakpoint-tablet) {
        //   .hidden_for_list_view {
        //     display: flex;
        //   }
        // }
      }

      .openTaskBtn {
        width: 96px;
        height: 40px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: #354554;
        border: none;
        cursor: pointer;
      }

      .openTaskBtnText {
        width: 64px;
        height: 16px;
        flex-grow: 0;
        font-family: Inter;
        font-size: 11px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.2px;
        text-align: left;
        color: #ffffff;
      }
    }


    .labels-container{
      display: flex;
      width: 100%;
      gap: 8px;
      margin: 0 16px;
      .labelMisc {
        max-width: max-content;
        height: 32px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        padding: 8px 12px;
        border-radius: 16px;

        flex-grow: 0;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        cursor: pointer;

      }
    }

    .date-and-view{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 16px;

      .expand-btn {
        line-height: 16px;
        font-size: 14px;
        padding-top: 2px;

        .righticon {
          right: 0px !important;
          top: 0%;
          padding-top: 16px;
        }

        &.status-layout {
          line-height: 40px;
          .righticon {
            top: 25%;
          }
        }
      }


      .date-section{
        .date {
          height: 20px;
          flex-grow: 0;
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #354554;
          display: flex;
          align-items: center;
          position: relative;

          .backToToDayButton {
            width: 156px;
            height: 40px;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding: 12px 16px;
            border-radius: 8px;
            cursor: pointer;

            .backToToday {
              height: 16px;
              flex-grow: 0;
              font-family: Inter;
              font-size: 12px !important;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: 0.2px;
              text-align: left;
              color: #5d7893;
            }
          }

          .thisWeekText {
            height: 20px;
            flex-grow: 0;
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #354554;
          }

          .ifill-os-chevron-left,
          .date-arrow-back {
            right: "8px" !important;
            cursor: "pointer" !important;
            width: 16px !important;
            height: 16px !important;
          }
        }
      }

      .label-dropdown-container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        .bootstrap-dropdown{
          .dropdown-toggle{
            display: flex;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid var(--border-input-typed-button-2, #C9D1DA);
            background: var(--surface-borders-level-1, #FFF);
            color: #354554;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.2px;
            text-transform: uppercase;
          }
          .dropdown-menu{
            width:100%;
            border-radius: 8px;
            padding-top: 0;
            padding-bottom: 0;
            max-height: 200px;
            min-height: max-content;
            background: var(--surface-borders-level-1, #FFF);
            box-shadow: 0px 2px 4px 0px rgba(53, 69, 84, 0.12), 0px 3px 6px 0px rgba(53, 69, 84, 0.15);
            cursor: pointer;
          }
        }

        .clear-btn{
          display: flex;
          padding: 12px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          color: var(--neutrals-level-9, #5D7893);
          font-feature-settings: 'clig' off, 'liga' off;
          /* Heading 4 */
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }
      }

      .view-section{
        display: flex;
        justify-content: space-between;
        .listBtn {
          width: 40px;
          height: 40px;
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          padding: 10px;
          border-radius: 8px;
          border: none;
          cursor: pointer;

          @media (max-width: $breakpoint-tablet) {
            display: none;
          }

          .ifill-os-case-single {
            background-color: $os-platinum;
            padding: 1.22px;
          }
        }
      }
    }
  }

  .taskManagerView {
    height: auto;

    .listViewWrapper {
      display: flex;
      justify-content: center;
      gap: 24px;
      width: 100%;

      .labelMisc {
        max-width: max-content;
        height: 32px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        padding: 8px 12px;
        border-radius: 16px;

        flex-grow: 0;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;

        margin-bottom: 30px;
        cursor: pointer;

      }

      .listView {
        // width: calc(100% - 438px);
        // max-width: 1054px;
        width: 100%;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
          max-width: 100%;
        }
      }

      .labelAndTeamContainer {
        display: flex;
        flex-direction: column;
        gap: 24px;

        @media (max-width: $breakpoint-tablet) {
          display: none;
        }

        // .labelsContainer {
        //   width: 438px;
        //   height: max-content;
        //   flex-grow: 0;
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: flex-start;
        //   align-items: stretch;
        //   gap: 12px;
        //   padding: 12px 20px 24px;
        //   border-radius: 12px;
        //   box-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);
        //   background-color: #fff;

        //   .labelsContainerHeader {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;

        //     span {
        //       flex-grow: 0;
        //       font-family: Inter;
        //       font-size: 16px;
        //       font-weight: 600;
        //       font-stretch: normal;
        //       font-style: normal;
        //       line-height: 1;
        //       letter-spacing: normal;
        //       text-align: left;
        //       color: #7e91a5;

        //       svg {
        //         cursor: pointer;
        //       }
        //     }
        //   }

        //   .labels {
        //     margin-top: 24px;
        //     display: flex;
        //     flex-wrap: wrap;
        //     gap: 8px;

        //     .label {
        //       flex-grow: 0;
        //       display: flex;
        //       flex-direction: row;
        //       justify-content: center;
        //       align-items: center;
        //       gap: 4px;
        //       padding: 8px 12px;
        //       border-radius: 16px;
        //       cursor: pointer;

        //       .labelText {
        //         flex-grow: 0;
        //         font-family: Inter;
        //         font-size: 12px;
        //         font-weight: 600;
        //         font-stretch: normal;
        //         font-style: normal;
        //         line-height: 1.33;
        //         letter-spacing: normal;
        //         text-align: left;
        //         color: #354554;
        //         z-index: 1;
        //       }
        //     }
        //   }
        // }

        // .teamContainer {
        //   width: 438px;
        //   height: max-content;
        //   flex-grow: 0;
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: flex-start;
        //   align-items: stretch;
        //   gap: 12px;
        //   padding: 12px 20px 24px;
        //   border-radius: 12px;
        //   box-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);
        //   background-color: #fff;

        //   .teamContainerHeader {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;

        //     span {
        //       flex-grow: 0;
        //       font-family: Inter;
        //       font-size: 16px;
        //       font-weight: 600;
        //       font-stretch: normal;
        //       font-style: normal;
        //       line-height: 1;
        //       letter-spacing: normal;
        //       text-align: left;
        //       color: #7e91a5;
        //     }
        //   }

        //   .members {
        //     margin-top: 24px;
        //     display: flex;
        //     flex-wrap: wrap;
        //     gap: 8px;

        //     .member {
        //       flex-grow: 0;
        //       display: flex;
        //       flex-direction: row;
        //       justify-content: center;
        //       align-items: center;
        //       gap: 4px;
        //       padding: 4px 8px;
        //       border-radius: 16px;
        //       background-color: #edeff3;
        //       cursor: pointer;

        //       .avatar {
        //         img {
        //           position: relative;
        //         }
        //       }

        //       img {
        //         width: 24px !important;
        //         height: 24px !important;
        //         border-radius: 50%;
        //       }

        //       .memberName {
        //         flex-grow: 0;
        //         font-family: Inter;
        //         font-size: 12px;
        //         font-weight: 600;
        //         font-stretch: normal;
        //         font-style: normal;
        //         line-height: 1.33;
        //         letter-spacing: normal;
        //         text-align: left;
        //         color: #7e91a5;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

.mobile-device{
  .taskManagerHeader{
    .board-view-header{
      flex-direction: column;
      align-items: flex-start;

      .back-and-heading{
        span{
          padding-top: 4px;
        }
      }

      .labelMisc{
        margin-left: 0;
      }
    }
    .taskManagerButtonContainer{


      width: 100%;
      .osbtn{
        i{
          left: 9px ;
        }
      }
      .filterInput{
        width: inherit;
      }
    }
  }
}
