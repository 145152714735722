.detail {
  padding-bottom: 30px;
  padding-left: 24px;
  .my-3{
    white-space: pre-wrap;
  }
  .mt-5{
    h2{
      font-weight: $font-weight-lighter;
    }
  }

  &.detail-user{

    .object-header-bar {
      &.fixed {
        position: sticky;

        &::after {
          content: '';
          width: 100vw;
          height: 40px;
          position: fixed;
          top: 0;
          left: 0;
          z-index: -1;
          background: #000;
        }
      }
    }
  }
  .user-bio-toggle{
    font-weight: $font-weight-bold;
    font-size: 11px;
    color: #fff;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    .times{
      opacity: 0.5;
    }
  }
  .user-profile-bio {
    width: 75%;
    padding-top: 38px;
    padding-bottom: 30px;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #5c5c66;
    }

    .my-3 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      > div {
        display: inline;

        ~ .a-link {
            white-space: nowrap;
          }

        + .a-link {
          margin-left: 5px;
        }

        + .desc-ellipsis + .a-link {
            margin-left: 1px;
          }
      }
    }
  }
  .detail-header {
    position: relative;

    .detail-type {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      span {
        margin-left: 8px;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        color: #fff;
        font-size: 12px;
        line-height: 12px;
      }
      .detail-icon{
        display: flex;
        align-items: center;
        .ifill-os-fill-case-2-d{
          height: 20.5px;
        }
        .ifill-os-chevron-left {
          font-size: 14px;
        }
      }
    }
    hr {
      border-color: $border-button-1;
      margin-top: 11px;
    }
    .detail-btn-row {
      position: absolute;
      top: 54px;
      right: 0;
      .get-in-touch {
        &:hover {
          color: #fff;
        }
      }
    }
    .detail-timestamp{
      position: absolute;
      bottom: 30px;
      right: 0;
    }
    .detail-content{
      margin-top: 35px;
      color: #c8c8c8;
      font-weight: $font-weight-lighter;
      padding-right: 33%;
      font-size: 18px;
      line-height: 25px;
      &.short{
        @include ellipsis-lines($font-size: 18px, $line-height: (25/18), $lines-to-show: 1);
      }
    }
    .btn--pill i{
      &.ifill-os-support {
        font-size: 15px;
      }
    }
  }
  .detail-holder{
    margin-top: 37px;
  }
  .detail-subheader{
    font-size: 20px;
    font-weight: $font-weight-lighter;
    padding-bottom: 10px;
    color: $level-11;

    /*.details-time {
      font-size: 12px;
    }*/
  }
  .case-active-treatment-block {
    width: 100%;
    margin-top: 35px;
    padding-right: 35px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .timeline {
      margin: 0;
      padding: 0;
    }

    .case-treatment-text-block {
      width: 100px;
    }

    .case-treatment-text-small {
      display: block;
      color: #ccc;
      font-size: 9px;
      text-transform: uppercase;
      line-height: 16px;
    }

    .case-treatment-time-block {
      display: block;
      color: #fff;
      font-size: 18px;
      font-weight: $font-weight-bold;
      line-height: 16px;
    }

    .case-treatment-progress-block {
      width: calc(100% - 190px);
    }

    .case-treatment-progress-grey-bar {
      width: 100%;
      height: 10px;
      position: relative;
      background: #2a2a2a;
      border-radius: 6px;

      .base-line {
        top: 10px !important;
      }
    }

    .case-treatment-progress-blue-bar {
      width: auto;
      height: 10px !important;
      position: absolute;
      background: $cl-link;
      border-radius: 6px;

      .occurance-line {
        display: none;
      }
    }

    .case-treatment-progress-lightblue-bar {
      width: auto;
      height: 10px !important;
      position: absolute;
      background: #76A9D0;
      border-radius: 6px;

      .occurance-line {
        display: none;
      }
    }
  }
  .engagement {
    display: flex;
    color: #fff;
    .engagement-notes {
      .ifill-os-note {
        vertical-align: middle;
      }
      span {
        font-weight: 700 !important;
      }
      .engagement-notes-link {
        &.active {
          .ifill-os-note {
            opacity: 1;
            &.active {
              opacity: 1;
              color: #48acfd;
            }
          }
        }
      }
    }
    .engagement__comments {
      a, span.comments {
        position: relative;
        top: 3px;
        color: #fff;
      }
    }
    > div {
      display: flex;
      align-items: center;
      margin-right: 1.25rem;
      &.engagement__share,
      .engagement__like,
      .engagement__liked{
        cursor: pointer;
      }
      &.engagement__share,
      .engagement__liked{
        color: #fff;
        .ifill-os-like {
          color: #48acfd;
        }
      }
      span {
        margin-left: 6px;
        font-size: 12px;
        line-height: 1;
        color: #fff;

        &.comments {
          margin-left: 0;
          top: 2px !important;
        }
      }
    }
  }
  h1,
  .board-header-title{
    width: 45%;
    font-size: 24px;
    font-weight: $font-weight-normal;
    line-height: 41px;
  }
  h1 {
    &.case-details-title-row {
      width: 50%;
      height: auto;
      line-height: 36px;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 0;
      .ifill-os-info {
        font-size: 15px;
        color: $lt-orange;
      }
    }
  }
  .detail-header,
  .detail-text{
    .user {
      bottom: 0;
      display: flex;
      align-items: center;

      &.case-details-user-block {
        .user__info {
          padding-left: 0;
          display: flex;
          align-items: center;

          p {
            &.user-name{
              font-size: 14px;
              padding-left: 10px;
              padding-right: 50px;
            }
            &.user-case-id {
              font-size: 14px;
              padding-left: 10px;
            }
          }

        }
        + .detail-btn-row {
          top: -5px;
        }

        .degree-text{
          font-size: 12px;
        }
      }

      .user-profile-pic-block {
        position: relative;

        .user-image-edit-btn {
          width: 40px;
          height: 40px;
          border: solid 1px #fff;
          position: absolute;
          bottom: 10px;
          right: 2px;
          border-radius: 100%;
          z-index: 2;
          background-color: $btn-primary;
          color: $btn-primary-color;
          text-align: center;
          line-height: 40px;
          font-size: 24px;

          .ifill-os-edit {
            margin-left: 2px;
          }

          .btn-icon-helper {
            color: $level-1;
          }

          &:hover {
            background: $btn-primary-hover;
            color: $btn-primary-color !important;
          }
        }

        .avatar + .osbtn {
          margin-top: 16px !important;
        }
      }

      .user__info {
        padding-top: 0;
        padding-left: 32px;
        h2{
          @extend %heading-2-bold;
          margin-bottom: -3px;
          padding-right: 170px;
          .ifill-os-info {
            font-size: 15px;
          }
        }

        p {
          margin-bottom: 0;
          &.user-name {
            color: $level-11;
            font-size: 16px;
            font-weight: $font-weight-normal;
            line-height: 20px;
          }
          &.user-title {
            color: $level-11;
            font-size: 12px;

            .engagement {
              .ifill-os-views {
                position: relative;
                top: 5px;
                height: 14px;
              }
            }
          }
        }

        .user-options-block {
          margin-top: 2px;
          display: flex;
          align-items: center;
          font-weight: $font-weight-bold;

          &> div {
            display: inline-block;
          }

          .engagement-notes {
            margin-right: 15px;
            margin-top:5px;

            .ifill-os-note{
              vertical-align: top;
            }
            span {
              margin-left: 5px;
              font-size: 12px;
              vertical-align: top;
            }
            .engagement-notes-link {
              display: inline-flex;
              align-items: center;

              &.active {
                .ifill-os-note {
                  opacity: 1;
                  &.active {
                    opacity: 1;
                    color: #48acfd;
                  }
                }
              }
            }
          }

          .engagement__views {
            display: flex;
            align-items: center;
            .ifill-os-views {
              width: 22px;
              height: auto;
              display: inline-block;
              position: relative;
              font-size: 20px;
              color: #fff;
            }
          }

          .views-count {
            margin-left: 5px;
            font-size: 12px;
          }

          .share-block {
            margin-left: 30px;
          }

          .engagement {
            display: inline-block;
            position: relative;
            top: 5px;
            color: #fff;
            font-size: 18px;
            cursor: pointer;
          }
        }

        .user-clinic-details {
          margin-top: 5px;
          display: flex;
          align-items: flex-start;

          i {
            margin-top: 8px;
            margin-right: 8px;
            color: $level-9;
            font-size: 12px;
          }
        }
      }
    }

    .user-edit-options-block {
      .detail-btn-row {
        top: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .engagement__share {
          margin-right: 16px;
          i {
            font-size: 18px;
          }
        }
        .btn {
          margin-left: 7px;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .btn-edit-profile,
      .btn-user-follow {
        padding: 5px 20px;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
      }

      .user-edit-message-btn {
        margin-left: 10px;

        .icon-messages {
          position: relative;
          top: 2px;
        }
      }
    }
  }
  .detail-gradient{
    background: linear-gradient(0deg, #5a5a5a 0%, #151515 100%);
    margin-top: -7px;
  }
  .detail-text{
    padding: 18px 25px 50px;
    font-size: 18px;
    font-weight: 400;
  }
  .pb-100{
    padding-bottom: 100px;
  }

  .content-expand{
    font-size: 11px;
    margin-top: 11px;
    cursor: pointer;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 15px;
    display: flex;
    align-items: center;
    .show-icon{
      display: inline-block;
      right: -20px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      color: rgba(255, 255, 255, 0.15);
      height: 13px;
      width: 13px;
      font-size: 7px;
      line-height: 10px;
      text-align: center;
      border-radius: 2px;
      margin-right: 11px;
      padding: 0.5px 2.5px;
      &.active{
        border-color: #fff;
        color: #fff;
      }
      i.fa-minus{
        padding: 1px 0.7px;
      }
    }
  }

  .video-sidebar {
    .related {
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    h5 {
      font-size: 18px;
      font-weight: $font-weight-light;
    }
  }
  .detail-deleted{
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: right;
    font-weight: $font-weight-bold;
    color: $lt-red;
  }
  .following-users{
    .avatar{
      margin-left: -12px;
    }
  }
  .detail-private-container{
    position: relative;
    .detail-private{
      position: absolute;
      right: 0;
      top: -45px;
      background-color: #fff;
      color: #191919;
      padding: 6px 15px;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: $font-weight-bold;
      width: 100px;
      i{
        margin-left: 10px;
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    min-height: 1px;

    &.p-3 {
      padding: 0 !important;
    }

    .detail-type {
      display: none !important;
    }

    .detail-type-mobile {
      height: 42px;
      padding: 0 10px;
      display: block;

      .engagement {
        justify-content: space-around;
        display: flex;
        align-items: center;

        &> div {
          margin-right: 0;

          &.engagement-notes {
            &.hidden-mobile {
              display: none;
            }
          }


          &.engagement__comments {
            a {
              color: #fff;
            }
          }

          .engagement__views {
            i {
              height: auto;
              font-size: 17px;
              vertical-align: middle;
              vertical-align: -webkit-baseline-middle;
            }
          }

          .engagement__like,
          .engagement__liked {
            i {
              font-size: 13px;
            }
          }

          i {
            height: auto;
            font-size: 12px;
            &.ifill-os-note{
              height: 15px;
            }
          }

          span {
            font-size: 10px;
          }
        }

        .engagement__views {
          height: auto;
        }

        .engagement__liked,
        .engagement__like {
          position: relative;
        }
      }

      .engagement__share {
        color: #fff;
      }

      .engagement_follow {
        position: relative;
        top: 2px;
        color: #fff;


      }

      .detail-type-icons-mobile {
        width: 40%;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }

    .case-details-content-block {
      position: relative;

      &.p-2 {
        padding: 0 !important;
      }

      &.mt-4 {
        margin-top: 0 !important;
      }

      .case-details-video-section {
        width: 100% !important;
        max-width: none !important;
        padding-left: 15px;
        padding-right: 15px;

        &+ .col-md-3 {
          &.pl-2 {
            padding-left: 0 !important;
          }
        }
      }
    }

    .video-holder {
      .video-scrubber {
        .video-fullscreen {
          top: -20px;
          right: 2px;
        }
      }

      .slick-track {
        display: flex;

        .center-objects {
          text-align: center;
        }

        .case-image-full {
          display: inline-block;

          &.case-image-sbs {
            display: inline-block;
          }
        }
      }

      .image-selector-prev {
        width: 20px;
        left: -20px;
        background-color: transparent;

        i {
          margin-left: -2px;
        }
      }

      .image-selector-next {
        width: 20px;
        right: -20px;

        i {
          margin-right: -2px;
        }
      }
    }

    .video-bar {
      width: 100%;
      height: 42px;
      padding-top: 0;
      display: table;

      &> div {
        width: 50%;
        float: left;
        display: none;
      }

      .video-type-buttons {
        width: 100%;
        height: 42px;
        margin: 0;
        padding: 0;
        position: relative;
        top: 0;
      }

      .viewing-mode-buttons {
        width: 50%;
        height: 42px;
        position: relative;
      }

      .video-select {
        width: 100%;
        height: 42px;
      }

      .video-bar-mobile {
        width: 100%;
        height: 100%;
        margin: 0 0 0 0px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 0 20px ;
        justify-content: space-between;
      }
    }

    .video-scrubber {
      padding-left: 0px;
      padding-right: 30px;
      margin-top  : 0;

      &.slick-scrubber {
        padding-left:20px;
        padding-right:20px;
        /*margin-top  : 18px; may be required */
      }
    }

    .video-options-block {
      height: 30px;
      padding: 0 0px;
      /*flex: auto 2 1;*/
      /*border-left: solid 1px #5a5a5a;*/

      &:first-child {
        border-left: none;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.thumbs-block {
        max-width: 125px;
        padding: 0;

        &:hover {
          .video-thumbs-section {
            display: block;
            text-transform: uppercase;
          }
        }
      }

      &.details-block {
        position: relative;

        &:hover {
          .video-details-dropdown {
            display: block;
          }
        }

        .video-details-dropdown {
          margin: 0 10px;
          padding: 10px;
          display: none;
          list-style: none;
        }
      }

      &.selection-block {
        min-width: 80px;
        position: relative;
        text-align: right;
        .video-thumbs-header {
          float: right;
          width: 80px !important;
        }

        &:hover {
          .video-details-dropdown {
            display: block;
          }
        }

        .video-details-dropdown {
          margin: 0 10px;
          margin-right: 0;
          padding: 10px;
          display: none;
          left: auto;
          right: 0;
          list-style: none;
        }
      }
    }

    .video-thumbs-header {
      width: 105px;
      height: 100%;
      padding: 0 10px;
      display: flex;
      position: relative;
      color: #fff;
      font-size: 10px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      line-height: 30px;
      background: #29292e;
      border-radius: 4px;
    }

    .ifill-os-chevron-down {
      &.active-view-btn {
        position: relative;
        top: 0;
        right: auto;
        width: 9px;
      }
    }

    .video-thumbs-section {
      width: 100%;
      padding: 0 10px;
      position: absolute;
      display: none;
      top: 42px;
      left: 0px;
      overflow: hidden;
      background: #000;
    }

    .video-thumbs-row,
    .video-thumbs-block {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .video-thumbs-row {
      overflow-y: hidden;
      overflow-x: auto;
      display: flex;
      flex-direction: row;
    }

    .video-thumbs-block {
      width: 94px;
      height: auto;
      padding: 10px 7px;
      color: #fff;
      font-size: 9px;
      text-align: center;
      &.selected {
        .video-thumbs-holder {
          border:  1px solid #48acfd;
        }
      }
    }

    .video-thumbs-holder {
      width: 80px;
      height: 60px;
      margin-bottom: 4px;
      display: inline-block;
      border-radius: 4px;

      img,
      video {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }

      &.selected {
        border: solid 2px #fff;
      }
    }

    .video-details-dropdown {
      width: 100%;
      margin: 0 !important;
      padding: 10px;
      position: absolute;
      top: 29px;
      left: 0;
      background: #29292e;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .video-details-dropdown-row {
      margin: 0;
      border-top: solid 1px #5a5a5a;
      padding: 0;
      list-style: none;

      &:first-child {
        border-top: none;
      }

      .btn {
        &.video-select {
          height: auto;
          margin: 0;
          border-top: solid 1px #5a5a5a;
          padding: 5px 0;
          display: inline-block;
          color: #fff;
          font-size: 12px;
          font-weight: $font-weight-bold;
          text-align: left;
          background: transparent;
          -webkit-appearance: none;

          &:first-child {
            border-top: none;
          }
        }
      }
    }

    .video-details-dropdown-link,
    .video-details-dropdown-text {
      padding: 5px 0;
      display: block;
      color: #fff;
      font-size: 12px;
      font-weight: $font-weight-bold;
    }

    .detail-text {
      margin-top: 0;
      padding: 15px;
    }

    .detail-subheader {
      padding-bottom: 40px;
      padding-left: 10px;
      font-size: 14px;

      .details-time {
        font-size: 14px;
      }
    }

    .case-active-treatment-block {
      margin-top: 0;
    }

    .case-active-treatment-block {
      padding: 0;
      position: relative;

      .case-treatment-progress-block {
        width: 100%;

        .timeline {
          .appliance-log {
            .duration {
              .base-line {
                bottom: 10px;
                opacity: 0;
              }
            }
          }
        }
      }

      .case-treatment-progress-blue-bar,
      .case-treatment-progress-lightblue-bar {
        height: 9px !important;
      }

      .case-treatment-text-block {
        width: auto;
      }

      .case-treatment-text-small,
      .case-treatment-time-block {
        display: inline-block;
        font-size: 8px;
      }

      .case-treatment-time-block {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .case-treatment-tooltip {
      box-shadow: 0 0 0 transparent;


      &.without-label {
        .case-treatment-tooltip-content {
          padding: 0 5px;
        }

        &::before {
          margin-left: -10px;
          border-top: 10px solid #4a4a4a;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          background: transparent !important;
          left: 50%;
        }
      }

      &.left {
        transform: translateX(0);
        left: 0;
      }

      &.right {
        direction: rtl;
        transform: translateX(0);
        right: 0;

        .case-treatment-tooltip-label {
          padding-right: 5px;
          padding-left: 2px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      .case-treatment-tooltip-label {
        padding-right: 2px;
        padding-left: 5px;
        position: relative;
        z-index: 2;
        font-size: 8px;
        background: #343434;
      }

      .case-treatment-tooltip-content {
        padding: 0 5px;
        position: relative;
        z-index: 2;
        font-size: 8px;
      }
    }

    .case-treatment-text-block {
      position: absolute;
      top: -12px;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
    }

    .detail-text {
      font-size: 11px;
      line-height: 15px;
    }

    .detail-comments-header {
      border-top: none;
      padding: 10px 0;

      .media-body {
        margin-top: 5px;

        ::-webkit-input-placeholder {
          color: $textarea-placeholder;
        }

        ::-moz-placeholder {
          color: $textarea-placeholder;
        }

        :-ms-input-placeholder {
          color: $textarea-placeholder;
        }

        :-moz-placeholder {
          color: $textarea-placeholder;
        }
      }

      .detail-comments-icon {
        top: 2px;
      }

      .media-actions {
        justify-content: flex-end !important;

        .media-links {
          display: none !important;
        }
      }
    }
  }

  @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .video-holder {
      .video-scrubber {
        .video-fullscreen {
          top: 0px;
          right: 20px;
          z-index: 9;
        }
      }
      .video-bar {
        .video-bar-mobile {
          padding-bottom: 10px;
        }
      }
    }
  }

  @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .video-holder {
      .video-scrubber {
        padding-right: 32px;

        &.slick-scrubber {
          padding-right: 40px;
          padding-left: 40px;
        }

        .video-fullscreen {
          top: -5px;
          right: 3px;
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    min-height: 768px;

    &.p-3 {
      padding-right: 0 !important;
      padding-left: 0 !important;

      &.detail-board {
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
    }



    h1 {
      height: auto;
      font-size: 21px;

      &.case-details-title-row {
        font-size: 21px;
        line-height: 26px;
      }
    }
    &.detail-comparison {
      h1 {
        width: 38%;
      }
    }
    .user-profile-bio {
      width: 100%;
    }

    .detail-header {

      .btn--pill {
        i {
          &.ifill-os-edit {
            font-size: 10px;
            margin-top: 2px;
          }
          &.ifill-os-invite {
            font-size: 12px;
          }
        }
      }
    }

    .case-details-content-block {
      &.p-2 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }

      .slick-slider {
        .slick-track {
          display: flex;
        }
      }
    }

    .detail-text {
      padding-right: 10px;
    }

    .video-sidebar {
      h5 {
        font-size: 13px;
      }

      .related {
        a {
          font-size: 11px;
        }
      }
    }

    &.detail-case {
      .detail-header {
        .detail-type {
          display: none;
          + hr{
            display: none;
          }
        }

        .detail-btn-row {
          top: 0;
        }
      }
    }

    .video-scrubber {
      padding-left: 10px;
      padding-right: 45px;
      margin-top : 0;
      &.slick-scrubber {
        padding-left: 20px;
        padding-right: 50px;
      }
    }
  }


  @media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (orientation: portrait)  and (-webkit-min-device-pixel-ratio: 1) {
    .detail-header {
      .user {
        &.user-profile-header {
          padding-top: 30px;
        }
      }
    }
    .video-bar {
      width: 100%;
      height: 42px;
      padding-top: 0;
      display: table;

      &> div {
        width: 50%;
        float: left;
        display: none;
      }

      .video-type-buttons {
        width: 100%;
        height: 42px;
        margin: 0;
        padding: 0;
        position: relative;
        top: 0;
      }

      .viewing-mode-buttons {
        width: 50%;
        height: 42px;
        position: relative;
      }

      .video-select {
        width: 100%;
        height: 42px;
      }

      .video-bar-mobile {
        width: 100%;
        height: 100%;
        margin: 0 0 0 0px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 25px 25px 20px 25px ;
        justify-content: space-between;
      }
    }

    .video-scrubber {
      padding-left: 10px;
      padding-right: 45px;
      margin-top : 0;
    }

    .video-options-block {
      height: 30px;
      padding: 0 0px;
      /*flex: auto 2 1;*/
      /*border-left: solid 1px #5a5a5a;*/

      &:first-child {
        border-left: none;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &.thumbs-block {
        max-width: 125px;
        padding: 0;

        &:hover {
          .video-thumbs-section {
            display: block;
          }
        }
      }

      &.details-block {
        position: relative;

        &:hover {
          .video-details-dropdown {
            display: block;
          }
        }

        .video-details-dropdown {
          margin: 0 10px;
          padding: 10px;
          display: none;
          list-style: none;
        }
      }

      &.selection-block {
        min-width: 58px;
        position: relative;
        text-align: right;
        .video-thumbs-header {
          float: right;
        }

        &:hover {
          .video-details-dropdown {
            display: block;
          }
        }

        .video-details-dropdown {
          margin: 0 10px;
          margin-right: 0;
          padding: 10px;
          display: none;
          left: auto;
          right: 0;
          list-style: none;
        }
      }
    }

    .video-thumbs-header {
      width: 105px;
      height: 100%;
      padding: 0 10px;
      display: flex;
      position: relative;
      color: #fff;
      font-size: 10px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      line-height: 30px;
      background: #29292e;
      border-radius: 4px;
    }

    .ifill-os-chevron-down {
      &:not(.active-view-btn) {
        position: absolute;
        top: 12px;
        right: 8px;
        width: 9px;
      }

      &.istoggleicon {
        top: 8px;
      }
    }

    .video-thumbs-section {
      width: 100%;
      padding: 0 10px;
      position: absolute;
      display: none;
      top: 56px;
      left: 0px;
      overflow: hidden;
      background: #29292e;
    }

    .video-thumbs-row,
    .video-thumbs-block {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .video-thumbs-row {
      overflow-y: hidden;
      overflow-x: auto;
      display: flex;
      flex-direction: row;
    }

    .video-thumbs-block {
      width: 94px;
      height: auto;
      padding: 10px 7px;
      color: #fff;
      font-size: 9px;
      text-align: center;
      &.selected {
        .video-thumbs-holder {
          border:  1px solid #48acfd;
        }
      }
    }

    .video-thumbs-holder {
      width: 80px;
      height: 60px;
      margin-bottom: 4px;
      display: inline-block;
      border-radius: 4px;

      img,
      video {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }

      &.selected {
        border: solid 2px #fff;
      }
    }

    .video-details-dropdown {
      width: 100%;
      margin: 0 !important;
      padding: 10px;
      position: absolute;
      top: 29px;
      left: 0;
      background: #29292e;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .video-details-dropdown-row {
      margin: 0;
      border-top: solid 1px #5a5a5a;
      padding: 0;
      list-style: none;

      &:first-child {
        border-top: none;
      }

      .btn {
        &.video-select {
          height: auto;
          margin: 0;
          border-top: solid 1px #5a5a5a;
          padding: 5px 0;
          display: inline-block;
          color: #fff;
          font-size: 12px;
          font-weight: $font-weight-bold;
          text-align: left;
          background: transparent;
          -webkit-appearance: none;

          &:first-child {
            border-top: none;
          }
        }
      }
    }

    .video-details-dropdown-link,
    .video-details-dropdown-text {
      padding: 5px 0;
      display: block;
      color: #fff;
      font-size: 12px;
      font-weight: $font-weight-bold;
    }

    .detail-subheader {
      margin-bottom: 30px;
    }

  }
  @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {

    .video-scrubber {
      padding-left: 10px;
      padding-right: 45px;
      margin-top: 5px;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {

    .video-bar {
      padding-top: 80px;
    }
  }

}

.user-tabs-title {
  margin-bottom: 30px;
  font-size: 24px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .case-details-video-section {
    .detail-subheader {
      margin-bottom: 30px;
    }
  }
}


@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .user-container {
    .user-profile-header {
      .user-profile-info {
        padding-top: 16px;
        &.user__info {
          padding: 0 !important;

          .spce-left {
            padding-left: 50px;
          }

          .header-dropdown-menu {
            position: absolute;
            top: 0;
            right: 0;
          }

          h2 {
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
          }

          .user-title {
            font-size: 11px !important;
            font-weight: $font-weight-semibold;
            letter-spacing: .5px;
          }

          .user-degree-block {
            display: block;
            padding-right: 50px;
          }

          .user-profile-clinics {
            display: block;
            line-height: 15px;
            margin-bottom: 24px;
          }

          .user-clinic-details {
            margin-top: 16px;
          }

          .user-clinics-block-mobile {
            margin-bottom: 4px;
            color: $level-9;
          }

          .user-clinics-sap {
            margin: 0 8px;
          }

          .user-options-block {
            .engagement-notes {
              &.hidden-mobile {
                display: none;
              }
            }
          }
        }
      }
    }

    .user-profile-options-mobile {
      width: 100%;
      height: 42px;
      border-top: solid 1px rgba(255, 255, 255, .2);
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #fff;

      .engagement-notes {
        .ifill-os-note {
          width: 15px;
          background-size: 15px auto;
        }
        .engagement-notes-link {

          &.active {
            .ifill-os-note {
              opacity: 1;
              &.active {
                opacity: 1;
                color: #48acfd;
              }
            }
          }
        }
      }

      .user-profile-veiws-block {
        font-size: 10px;
        .engagement__views__holder {
          .engagement__views {
            display: flex;
            align-items: center;
          }
        }
      }

      .user-profile-share-block {
        position: relative;
        top: 2px;
      }

      .user-profile-clock-block {
        position: relative;
        top: 2px;
      }
    }

    .user-profile-bio {
      width: 100%;
      padding: 16px 0 0 0px !important;

      &::after {
        display: none;
      }

      .my-3 {
        font-size: 12px;
        font-weight: $font-weight-normal;
        line-height: 18px;

        .user-bio-link {
          font-size: 12px;
        }
      }
    }

    .user-details-tabs-block {
      &.mt-5 {
        margin-top: 0 !important;

        .object-header-bar {
          height: 38px;
          border-bottom: none;
          padding: 0 10px;
          position: sticky;
          top: 75px;
          z-index: 99;

          .object-view-switcher {
            display: none;
          }

          .object-filter {
            height: 38px;
            padding: 0px 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            overflow-x: auto;
            overflow-y: hidden;

            .list-inline-item {
              margin-right: 16px !important;
              font-size: 11px;
              white-space: nowrap;

              &.object-filter-active {
                &::after {
                  width: calc(100% + 4px);
                  margin-left: -2px;
                  bottom: 1px;
                  box-shadow: 0 0 0 0 transparent;
                }
              }
            }
          }
        }

        .user-details-tabs-header {
          margin-bottom: 0;

          .user-plans-block {
            width: 100%;
            border-top: solid 1px rgba(255, 255, 255, .15);
            padding: 10px;
            text-align: center !important;
          }
        }
      }

      .board-listing-cards-row {
        &.row {
          margin: 0;

          .tool-cards-row {
            &.selected {
              .cards {
                &.row {
                  padding: 20px 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .deatil {
    .detail-header {
      .engagement-notes {
        .engagement-notes-link {

          &.active {
            .ifill-os-note{
              opacity: 1;
              &.active {
                opacity: 1;
                color: #48acfd;
              }
            }
          }
        }
      }
    }
  }
}

.detail-comments-top-block {
  margin: 0 0 .5rem 0;
  position: relative;
  flex-wrap: wrap;

  input[type="file"] {
    max-width: 100%;
    width: 100%;
  }

  .avatar {
    &.avatar-47 {
      position: relative;
      top: -8px;
    }
  }

  .comment-add-attachments-link {
    top: 1px;
    bottom: 1px;
    border-left: solid 1px #fff;
    text-align: center;
    width: 48px;
    right: 0;
    cursor: pointer;
    border-radius: 0 .25rem .25rem 0;
    font-size: 16px;
    color: #fff;
    transition: all .5s ease;

    .helper-holder {
      position: relative;
      width: 100%;
      height: 48px;
    }
  }

  .comments-primary-avatar {
    position: absolute;
    left: 16px;
    top: 8px;
    padding: 0;
    z-index: 1;

    .avatar {
      text-indent: 0;
    }
  }

  .form-error {
    border: 1px solid $lt-red !important;
  }
}

.no-touch-support {
  @media only screen and (min-width : 993px) and (max-width : 1199px) {
    .case-details-user-block {
      .span-helper {
        .white-link {
          max-width: 150px !important;
        }
      }
    }
  }
  @media only screen and (max-width : 992px) {
    .detail {
      .case-details-content-block {
        .case-details-video-section {
          .detail-text {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}


.ifill-os-follow {
  &.active {
    color: #48acfd;
  }
}


.case-card-detail-header {
  padding: 0 8px 0 16px;
  .h1 {
    font-size: 16px;
    font-weight: 300;
    padding: 0;
    margin: 0;
    line-height: 1.5;
  }

  .action-link-info {
    font-size: 16px;
  }
}

.detail-header-top-row {
  font-size: 24px;
  font-weight: 300;

  .icon-block {
    font-size: 20px;
    margin-right: 10px;
  }

  .detail-subheader  {
    font-size: inherit;
    padding: 0;
    font-weight: inherit;
  }

  .user-case-id + .detail-subheader:before {
    content: " - "
  }

  .header-dropdown-menu {
    position: relative;
  }
}

.user-options-block,
.board-header-views {
  [class^="icon-os"] {
    font-weight: inherit;
  }
}


.iphone,
.mobile-device {
  .board-header-action-block,
  .user-profile-options-mobile {
    font-weight: $font-weight-bold;

    [class^="icon-os"] {
      font-weight: inherit;
    }
  }
}

[data-hover='true']:not(.nohover):hover {
  color: $os-link-hover;
}

.no-touch-support, .ipad {
  .mb-80 {
    margin-bottom: 80px !important;
  }
  .mt-80 {
    margin-top: 80px !important;
  }

  .case-details-user-block  {
    a:hover .user-name:hover {
      color: $os-link-hover;
    }

    .span-helper {
      display: flex;
      align-items: center;

      .white-link {
        @include text-truncate();
        max-width: 200px;
      }
    }
  }

  .comment-add-attachments-link:hover {
    background: $cl-link;
  }

  .comment-add-attachments-link:hover {
    background: $os-link-hover;
  }
}

@media only screen and (max-width: 1023px) {
  .detail-case {
    .case-video-holder {
      .mid-option-block .video-details-dropdown {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
        top: 55px;
        padding-bottom: 8px;
        border-radius: 4px;

        .video-thumbs-block {
          padding: 0 10px 0 0;
        }

        .video-details-dropdown-text {
          padding: 0;
        }
      }
    }
  }
}
.touch-support {
  .detail,
  .video-bar {
    .video-options-block:not(.mid-option-block) {
      position: relative;
    }
  }

  .modal-create-comparison {
    .comparison-video-holder {
      .left-case-video,
      .right-case-video {
        overflow: hidden;
        @include backface-visibility;
      }
    }
  }
}

.ipad {
  .detail {
    .video-details-dropdown {
      width: 100%;
      margin: 0!important;
      padding: 10px;
      position: absolute;
      top: 100%;
      left: 0;
      background: #29292e;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .video-details-dropdown-row {
      margin: 0;
      border-top: 1px solid #5a5a5a;
      padding: 0;
      list-style: none;

      &:first-child {
        border-top: none;
      }
    }
  }

  &.portrait {
    .detail-user {
      @media(max-width: 1023px) {
        .sub-nav-background {
          .tab-nav-container {
            &:after {
              left: 10px;
              right: 10px;
            }

            .ifill-os-chevron-down:not(.active-view-btn) {
              right: 0;
            }
          }
        }
      }
    }
  }
}

.no-touch-support {
  body {
    &.users {
      .user-profile-bio {
        width: 100%;
        padding-bottom: 40px;
        &:after {
          content: none;
        }
      }

      .user-details-tabs-block {
        margin:20px 0px -15px;
      }
    }
  }
}

.ipad {


  body {
    &.users {
      .user-profile-bio {
        &:after {
          content: none !important;
        }
      }
    }
  }
  &.fs-mode-active {
    .slick-big-slider {
      .slick-slide {
        min-width: 100vw;
      }
    }

    #root {
      position: fixed;
      top: 0;
      bottom: 0;
      width: 100%;
      overflow: hidden;
    }

    .detail-case,
    .detail-comparison,
    .modal-create-comparison {
      min-height: unset !important;
    }

    .comment-content-section,
    .footer.mini,
    .detail-text {
      display: none;
    }

    .comparison-modal-content {
      padding: 0 !important   ;
    }

    .modal-create-comparison,
    .modal-open .modal {
      height: unset;
      bottom: 0;
    }

    .modal-open {
      padding-bottom: 0;
    }

    .modal-backdrop {
      width: 100%;
      height: unset;
      bottom: 0;
    }
  }

  &.full-screen-fix {
    .slick-big-slider {
      opacity: 0;
    }
  }
}


.mobile-device {
  .user-container {
    &.container {
      padding-top: 0;
    }

    .user-edit-options-block {
      .detail-btn-row {
        top: 16px;
        right: 12px;
      }
    }

    .user-profile-header {
      position: relative;

      &.user {
        min-height: 75px;
        margin-top: 0;
        padding: 24px 0;
        align-items: center;
        margin: 0;
      }

      .user-profile-back-block {
        width: 10px;
        height: 17px;

        .ifill-os-chevron-left {
          font-size: 14px;
        }
      }

      .user-profile-info {
        h2 {
          padding-right: 50px;
        }
      }

      .user-profile-pic-block {
        width: 40px;
        height: 40px;
        position: absolute !important;
        left: 0;
        padding: 0px;
        top: 24px;

        .avatar {
          &.avatar-103 {
            @include avatar(35px);
            font-size: 12px;
            text-align: center;
          }

          &.avatar-168 {
            @include avatar(39px);
            font-size: 12px;
            text-align: center;

            .icon-span {
              @include icon-span(18px);
            }
          }
        }
      }

      .user-image-edit-btn {
        display: none;
      }

      .user-profile-info {
        &.user__info {
          padding: 0 !important;
          flex: 0 0 100%;
          max-width: 100%;

          .spce-left {
            padding-left: 50px;
            min-height: 40px;
          }
          h2 {
            font-size: 16px;
            line-height: 22px;
            font-weight: normal;
          }

          .header-dropdown-menu {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 991;
          }

          .user-title {
            font-size: 11px !important;
            font-weight: $font-weight-semibold;
            letter-spacing: .5px;
          }

          .user-degree-block {
            display: block;
            padding-right: 50px;
          }

          .user-profile-clinics {
            display: block;
            line-height: 15px;
            margin-bottom: 24px;
          }

          .user-clinic-details {
            margin-top: 16px;
          }

          .user-clinics-block-mobile {
            margin-bottom: 4px;
          }

          .user-clinics-sap {
            margin: 0 8px;
          }

          .user-options-block {
            .engagement-notes {
              &.hidden-mobile {
                display: none;
              }
            }
          }
        }
      }
    }

    .user-profile-bio {
      width: 100%;
      padding: 24px 0 0 0;

      &::after {
        display: none;
      }

      .my-3 {
        font-size: 12px;
        font-weight: $font-weight-normal;
        line-height: 18px;

        .user-bio-link {
          font-size: 12px;
        }
      }
    }
  }

  &.landscape {
    .user {
      .user-profile-info {
        h2 {
          padding-right: 100px !important;
        }
      }
    }
  }

  .no-comment-added {
    margin-bottom: 30px;
  }
}


.no-touch-support, .ipad {
  .case-card-detail-header{
    .case-head-flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .case-head {
        width: auto;
      }
    }
  }
}
