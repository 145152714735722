.os-lg-private,
.os-sm-private,
.os-lg-private-object,
.os-sm-private-object,
.os-lg-deleted,
.os-sm-deleted,
.os-lg-dynamic-template,
.os-sm-dynamic-template,
.os-lg-deleted-object,
.os-sm-deleted-object,
.os-sm-unavailable,
.os-lg-unavailable,
.os-sm-unavailable-object,
.os-lg-unavailable-object,
.os-lg-reported,
.os-sm-reported,
.os-lg-reported-object,
.os-sm-reported-object {
  @extend %os-card-base;
  padding: 16px;
  display: flex;
  @extend %os-sm-card;

  .white-spc-normal {
    white-space: normal;
    max-width: 100%;
  }
}

.os-sm-private,
.os-sm-deleted,
.os-sm-unavailable,
.os-sm-reported {
  &.object-240 {
    @extend %os-240;
  }
}

.os-lg-unavailable-object {
  @extend %os-lg-card;
  height: $card-height;
  max-height: $card-height;
}

.os-sm-unavailable-object {
  @extend %os-sm-card;
  height: $card-height-sm;
  max-height: $card-height-sm;
}

.extra-small-min-card {
  .os-lg-private,
  .os-sm-private,
  .os-lg-private-object,
  .os-sm-private-object,
  .os-lg-deleted,
  .os-sm-deleted,
  .os-lg-deleted-object,
  .os-sm-deleted-object,
  .os-lg-dynamic-template,
  .os-sm-dynamic-template,
  .os-sm-unavailable,
  .os-lg-unavailable,
  .os-sm-unavailable-object,
  .os-lg-unavailable-object,
  .os-lg-reported,
  .os-sm-reported,
  .os-lg-reported-object,
  .os-sm-private-object,
  .os-sm-unavailable-object,
  .os-sm-reported-object {
    height: 96px;
    max-height: 96px;
  }

  .os-sm-reported-object {
    line-height: 18px;
  }

  .os-sm-dynamic-template {
    padding: 8px 12px !important;
    display: flex !important;
    align-items: center;

    .card-v2-header {
      width: 128px;
      height: 72px;
      display: inline-block;
      position: absolute;

      .card-v2-hero {
        border-radius: 8px;
      }
    }

    .card-content {
      display: inline-flex;
      padding-left:140px !important;
    }

    .extra-small-card-space-name {
      @include multiline-truncate(2);
    }
  }

  .os-sm-private-object,
  .os-sm-deleted-object,
  .os-sm-unavailable-object,
  .os-sm-reported-object {
    font-size: 12px;
    line-height: 16px;

    i {
      font-size: 16px;
    }
  }
}

.small-card {
  .my-default-space {
    .space-user-info  {
      padding: 16px 16px 0;
    }
  }
}

.extra-small-min-card {
  .os-sm-space {
    &.add-new-card {
      padding: 8px 12px !important;
      display: flex !important;
      align-items: center;

      .card-v2-header {
        width: 128px;
        height: 72px;
        display: inline-block;
        position: absolute;
        left: 8px;
        top: 12px;

        .card-v2-hero {
          left: 0;
          top: 0;
        }
      }

      .without-media {
        display: inline-flex;
        padding-left: 140px !important;
      }

      .space-user-info {
        padding: 0 0 0 0;

        .with-error {
          position: absolute;
        }
      }

      .os-close-link {
        right: 6px;
        min-height: 32px;
        max-height: 32px;
        background-color: transparent;
        line-height: 32px;
        top: 46px;
      }

      .form-control {
        padding: 0px 32px 0 8px;
        border-radius: 4px;
        min-height: 32px;
        height: 32px;
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
  }
}
