.speaker-listing {
  margin: 0 -12px 16px;
  display: flex;
  flex-wrap: wrap;

  .list-block {
    padding: 8px 15px 0;
    text-align: center;
    margin-bottom: 24px;
    width: 50%;
  }

  .speakers-avatar {
    @include avatar(100px);
    vertical-align: middle;
    border: 0;
    margin-bottom: 8px;
  }

  .speaker-name {
    font-weight: 600;
    color: #fff;
    display: block;

    a {
      @include text-truncate;
      display: block;
    }
  }

  @media(min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    
    .list-block {
      padding-left: 0;
      padding-right: 0;
      max-width: 25%;
      width: auto;

      .speaker-name {
        margin: 0 -19px;
      }

      + .list-block {
          padding-left: 54px;
        }
    }
  }

  @media(min-width: 1200px) {
    .speakers-avatar {
      @include avatar(120px);
    }
  }

  @media(max-width: 991px) {
    .speakers-avatar {
      @include avatar(120px);
    }
  }

  @media(max-width: 767px) {
    .speakers-avatar {
      max-width: 50%;
      vertical-align: middle;
      border: 0;
      @include avatar(120px);
    }
  }
}

.speaker-bio-container {
  + .cmn-heading {
      margin-top: 40px;
    } 
}

.speaker-bio-detail {
  display: flex;
  align-items: flex-start;

  .avatar {
    vertical-align: middle;
  }

  .speaker-bio-col {
    padding-left: 20px;
  }
}

#course-speaker-wrapper {
  .speaker-bio-col {
    .white-link {
      font-weight: 600;
      color: #fff;
    }


    .bio-text-info {
      display: inline;
      
      ~ .os-btn-link {
        font-size: 16px;
        font-weight: 600;
        display: inline-block;

        &::first-letter {
          text-transform: uppercase;
        }

        &[text="show less"] {
          margin-left: 3px;
        }
      }
    }

    @media(min-width: 768px) {
      .course-owner-bio {
        padding-top: 12px;
      }
    }
  }
}


.ipad {
  &.portrait {
    .speaker-listing {
      .speakers-avatar {
        @include avatar(120px);
      }
    }
  }
}

.mobile-device{
  .speaker-bio-detail {
    flex-wrap: wrap;
    align-items: center;
  }

  .speaker-bio-col {
    padding-left: 0;
    padding-top: 10px;
    width: 100%;
  }
}
