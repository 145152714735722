.tip-tap-editor-field {
  padding: 8px;
  background-color: #F4F7F9;
  border-radius: 8px;
  min-height: 240px;
  margin-top: 16px;

  p {
    padding: 0 4px 0 12px;
    margin: 0;
  }

  ol, ul {
    margin-bottom: 4px;
  }

  .menu-bar {
    margin-bottom: 4px;
  }


}

// CSS for Token node
.tip-tap-editor-field {
  span[data-type='token'] {
    background-color: #d8dcec;
    border-radius: 8px;
    padding: 0 8px;
  }
  // CSS FOR TOKEN NODE
  span[data-type='tokenNode'] {
    background-color: #d8dcec;
    border-radius: 8px;
    padding: 0 8px;
  }
  span[data-type="mention"] {
    background: #e3e3e3;
    border-radius: 4px;
    padding: 4px;
  }
}



// CSS for fixing date picker position issue.
.react-date-picker__calendar--open, .react-date-picker__calendar--closed {
  position: absolute;
}

.drag-overlay {
  position: relative;
}

.drag-overlay::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(224,221,221,80%);
  color: white;
  padding: 8px;
  border-radius: 8px;
}