#course-testimonial-wrapper {
  .row-col-space-16 > .mb-4  {
    margin-bottom: 1rem !important;
  }

  .os-card-v2,
  .os-testimonial,
  .os-card-testimonial {
    height: 100%;
  }

  .os-card-testimonial {
    min-height: 312px;
  }
}

.os-testimonial {
  display: block;
  @extend %os-card-base;
  background: #29292e;
  padding: 24px;
  font-weight: 300;

  .testimonial-user-name {
    color: #fff;
    font-weight: 600;
    margin: 18px 0 16px;
    display: block;
  }
}
