.modal-skip-quiz,
.modal-upgrade-alert {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    width: 500px;
    text-align: center;
    padding: 0;

    i{
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    .modal-body {
      padding: 40px 20px;

      h4 {
        font-size: 16px;
        font-weight: $font-weight-bold;
      }

      p{
        font-size: 14px;
        font-weight: $font-weight-normal;
        padding: 10px 50px;
      }
    }

    .btn-blue {
      width: auto;
      height: 34px;
      margin: 0;
      border: none;
      padding: 0 30px;
      display: inline-block;
      color: #fff;
      font-size: 12px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 34px;
      background: #48acfd;
      border-radius: 20px;
      cursor: pointer;

      &.mt-40 {
        margin-top: 30px;
      }

      &:hover {
        color: #fff;
        background: #4294e5;
      }
      &.btn-width-auto {
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 15px;
      }
    }

    .continue-btn{
      font-size: 12px;
      text-align: center;
      display: block;
      text-transform: uppercase;
      margin-top: 20px;
      font-weight: bold;
      letter-spacing: 0.5px;
      cursor: pointer;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px)  {
    .modal-content {
      width: 300px;
      margin: auto;

      i {
        font-size: 14px;
      }

      .modal-body {
        p{
          font-size: 14px;
          font-weight: $font-weight-normal;
          padding: 10px 30px;
        }
      }
    }
  }
}


.mobile-device {
  .os-header-btn {
    &.close-skip-survey {
      top: 3px;
      right: 3px;
    }
  }
}
