.lecture-preview-modal {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 6% 0;

  .modal-header {
    font-size: 16px;
    padding:0px 40px 16px 0;
  }

  .modal-content {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    border: 0;

  }

  .zoomable-content {
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.26);
    background-color: #1c1c1f;
    padding: 24px 24px 8px;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
  }

  .modal-body {
    padding: 0 15px 0 0;
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px);
    line-height: 1.5;
    overflow: hidden;
    overflow-y: auto;
    transition: all 200ms ease;

    .slick-track {
      transition: all 400ms ease;
    }
  }

  .ifill-os-close {
    font-size: 1rem;
    outline: 0;
    border: 0;
    padding: 0;
    background: none;
  }

  .pg-viewer-wrapper {
    overflow-y: unset;
    canvas {
      width: 100%;
    }
  }

  .document-container {
    width: 100% !important;
  }

  .pdf-loading,
  .pg-viewer-wrapper .loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $level-1;
  }

  .pg-viewer-wrapper .loading {
    max-height: 30px;
    max-width: 30px;
    background-size: 100%;
  }
}

.zoomable-content {
  .zoom-ctas {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1c1c1f;
    text-align: center;
  }
}

.zoom-btn {
  min-width: 32px;
  min-height: 32px;
  background-color: #303036;
  padding: 0px 12px;
  margin-top: 8px;

  &:hover, &:focus, &.active {
    background-color: #4c4c54 !important;
    color: $level-11 !important;
  }
}

.iphone,
.mobile-device {
  .lecture-preview-modal {
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    padding: 0;

    .modal-content,
    .zoomable-content {
      height: 100%;
      padding: 0;
    }

    .zoomable-content {
      padding:  0px;
      min-width: 300px;

      &.scale-1 {
        .create-slider{
          width: 100%;
        }
      }

      &.scale-2 {
        .create-slider {
          width: 120%;
        }
      }

      &.scale-3 {
        .create-slider {
          width: 150%;
        }
      }

      &.scale-4 {
        .create-slider {
          width: 170%;
        }
      }
    }

    .modal-body {
      height: calc(100vh - 125px);
      width: 100% !important;
      overflow-x: auto;
      top: 70px;
    }

    .modal-header {
      justify-content: flex-start;
      align-items: center;
      padding-right: 8px;
      padding-left: 16px;
      font-size: 20px;
      font-weight: 600;
      padding-top: 24px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      white-space: nowrap;

      span {
        padding-left: 28px;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .zoom-ctas {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

@media (min-width: 768px) {
  .lecture-preview-modal {
    .modal-content {
      width: auto;
      padding-bottom: 8px;
      height: 100%;
    }
  }
}

.zoomable-content {
  min-width: 588px;
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;

  &.scale-1 {
    .modal-body {
      width: 540px;
    }
  }

  &.scale-2 {
    .modal-body  {
      width: 668px;
    }
  }

  &.scale-3 {
    .modal-body  {
      width: 788px;
    }
  }

  &.scale-4 {
    .modal-body  {
      width: 908px;
    }
  }
}

.ipad {
  &.landscape {
    .lecture-preview-modal {
      .modal-content {
        height: 100%;
      }
    }
  }

  &.portrait {
    .lecture-preview-modal {
      padding: 0;

      .modal-content {
        width: 100%;
      }

      .zoomable-content {
        min-width: 100%;
        padding-bottom: 0;

        &.scale-1 {
          .create-slider{
            width: 100%;
          }
        }

        &.scale-2 {
          .create-slider {
            width: 120%;
          }
        }

        &.scale-3 {
          .create-slider {
            width: 150%;
          }
        }

        &.scale-4 {
          .create-slider {
            width: 170%;
          }
        }
      }

      .modal-body {
        width: 100% !important;
        overflow-x: auto;
        padding-right: 0;
      }
    }
  }
}

.ipad,
.mobile-device {
  .react-transform-component {
    width: 100%;
    height: auto;
  }

  .react-transform-element {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  &.portrait {
    .lecture-preview-modal {
      .create-slider {
        width: 100%;
        margin: 0;
      }

      .slick-track,
      .slick-list {
        transform: none !important;
        transition: none !important;
      }

      .slick-slide {
        min-height: calc(100vh - 150px);
      }

      .zoomable-content {
        .create-slider{
          .main-slider{
            &.slick-slider {
              > div {
                height: inherit;
                overflow: unset !important;
              }
            }
          }
        }
      }
    }
  }
}


