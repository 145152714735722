@import 'columnspace';

.add-folder-modal {
  .modal-header {
    .card-title {
      @include fontstyle(20px, 600, 28px);
      color: $level-11;
      margin-bottom: 8px;

      .ifill-os-chevron-right {
        color: $level-8;
      }

      @media screen and (max-width: 767px) {
        @include fontstyle(24px, 800, 32px);
      }
    }

    .card-sm-title {
      @include fontstyle(12px, normal, 16px);
      color: $level-9;
      width: 35%;
      margin-bottom: 0;

      @media screen and (max-width: 1366px) {
        width: 50%;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .close-assign-overlay {
      top: 16px;
      right: -12px;
    }
  }

  .with-sorting {
    margin-right: 24px;
  }

  .folder-views-area {
    margin-left: 0;
    padding: 0 0 16px 0;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 2;

    @media screen and (min-width: 768px) {
      .header-dropdown-list {
        right: auto;
        left: 0;
      }
      .dropdown-open {
        .page-heading-title {
          background-color: $level-3 !important;
          color: $level-12;
          border-radius: 8px 8px 0px 0px !important;
        }
      }
    }

    .header-dropdown-menu {
      .header-dropdown-list-item {
        &:hover {
          background-color: $dropdown-list-hover;
          color: $level-11;

          &:last-child {
            border-radius: 0 0 8px 8px;
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      background: $level-1;
    }

    .nav-search-holder {
      width: calc(61% - 24px) !important;
      margin-right: auto;

      .nav-search-input.form-control {
        font-size: 16px;

        @media screen and (min-width: 768px) {
          background-color: $level-3;
        }
      }

      .input-focus-active {
        .nav-search-input.form-control {
          box-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);
        }
      }

      @media screen and (max-width: 1366px ) {
        width: calc(58% - 24px) !important;
      }

      @media screen and (max-width: 1180px ) {
        width: calc(53% - 24px) !important;
      }

      @media screen and (max-width: 1024px ) {
        width: calc(45% - 24px) !important;
      }

      @media screen and (max-width: 1024px ) and (orientation:portrait) {
        width: calc(69% - 24px) !important;
      }

      @media screen and (max-width: 992px ) {
        width: calc(59.8% - 24px) !important;
      }

      @media screen and (max-width: 767px ) {
        width: 100% !important;

        .ifill-os-search {
          top: 20px !important;
          font-size: 16px;
        }

        .form-control {
          font-size: 12px;
        }
      }
    }
  }

  .add-message-title-row {
    margin: 40px 0 24px;

    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  .modal-body {
    margin-right: 0;
  }

  .user-board-modal-footer-buttons {
    padding-top: 0 !important;
  }

  .add-message-modal-footer-right {
    .osbtn + .osbtn {
      margin-left: 24px;
    }
    @media screen and (max-width: 767px) {
      justify-content: space-between;
      display: flex;
    }
  }
}



.filter-dropdown {
  margin-right: 4px;
  min-width: 90px;

  .istoggleicon {
    display: none;
  }

  .active {
    background-color: $level-11;
    color: $level-1;
  }

  &.disabled {
    pointer-events: none;

    .page-heading-title {
      color: $level-6 !important;
    }
  }

  &.with-list-sorting {
    margin-right: 24px;

    @media(min-width: 1025px) {
      min-width: 97px;
    }

    @media(min-width: 1024px) and (max-width: 1200px) {
      margin-right: 8px;
    }

    @media(max-width: 800px) {
      margin-right: 4px;
    }


  }

  &.with-sorting {
    .header-dropdown-list-item > .space-sort-option {
      padding: 0 12px;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .header-dropdown-list {
    min-width: 170px;
    margin-top: 0;
  }

  .header-dropdown-list-item {
    padding: 0 !important;

    .form-group {
      margin-bottom: 0;
      padding: 0 !important;
      min-height: 40px;
    }

    .os-custom-checkbox {
      line-height: 40px;
      padding: 0 12px 0 40px;

      .check-icon {
        top: calc(50% - 8px);
        left: 16px;
      }
    }

    label,
    .check-icon {
      color: inherit;
    }

    &:first-child{
      &:before {
        content: none !important;
      }
    }

    &:last-child {
      .os-custom-checkbox[for='folder-detail-clear'],
      .os-custom-checkbox[for='attachment-modal-clear'] {
        padding-left: 0;
        color: $level-10 !important;
        text-transform: none;
        text-align: center;
        display: block;

        .check-icon {
          display: none !important;
        }
        &.disabled {
          background-color: $level-3;
          width: 100%;
          color: $level-8 !important;
        }
      }
    }

    &:hover {
      color: $level-11;
    }
  }

  .ifill-os-chevron-caret-up,
  .ifill-os-chevron-caret-down {
    right: 14px;
    color: #e4e49e;
    font-size: 16px;
  }

  .page-heading-title {
    color: $level-11;
    padding: 8px 40px;
    text-transform: uppercase;

    i {
      color: inherit;
      &.sort-rel-icon {
        left: 14px !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .pointer {
      font-size: 12px;
    }
  }

  .cs-slider-after {
    + span:hover {
      background-color: transparent !important;
    }
  }

  @media(min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    min-width: 40px;

    .page-heading-title {
      padding: 0;
      width: 40px;

      span, i:not(.sort-rel-icon) {
        font-size: 0 !important;
      }

      i.sort-rel-icon {
        left: 12px !important;
      }
    }
  }
}

.modal-cards-list-wrap {
  padding: 0px 12px;
}

.space-links-container{
  &.list {
    .cards {
      counter-reset: list-number;
    }
  }
}

.no-touch-support {
  .space-links-container{
    &.list {
      .cards {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}


.folder-modal-body {
  .space-card-col {
    margin-bottom: 1rem !important;

    @media screen and (max-width: 767px) {
      margin-bottom: 0 !important;
    }
  }

  .cs-title {
    padding-bottom: 0 !important;
    color: $level-11;
    font-size: 12px;
  }

  .thumbnail-image  {
    position: relative;
  }

  .ifill-os-play {
    cursor: pointer;
  }

  .thumbnail {
    &.modal-body-container {
      .row {
        margin-right: -6px;
        margin-left: -6px;
      }
    }

    .os-card-v2 {
      margin-left: 0 !important;
    }

    @include thumbnailSpaecCardCol(8px);

    .card--loading {
      height: 120px;
      .card__content {
        min-height: 100%;
      }

      .bar-1 {
        max-height: 8px;
      }
      .bar-2 {
        max-height: 16px;
      }
    }

    @media(min-width: 768px) {
      @include thumbnailSpaecCardCol(10px);
      @include dragHandlePosition(15px, $width: true);
      @include dragHandleForThumbnail(5, 10px, 12%)

    }

    @media(min-width: 1024px) {
      /*@include thumbnailSpaecCardCol(10px);*/
      @include thumbnailSpaecCardCol2(10px, 14.2857%);
      @include dragHandlePosition(10px, $width: true);
      @include dragHandleForThumbnail(7, 10px, 14.2857%)
    }

    @media(min-width: 1100px) {
      @include thumbnailSpaecCardCol2(8px, 11.11%);
      @include dragHandlePosition(10px, $width: true);
      @include dragHandleForThumbnail(9, 8px, 11.11%)
    }

    @media(min-width: 1250px) {
      /*@include thumbnailSpaecCardCol(8px);*/
      @include thumbnailSpaecCardCol2(8px, 10%);
      @include dragHandlePosition(8px, $width: true);
      @include dragHandleForThumbnail(10, 8px, 10%)
    }
  }

  .extra-small {
    @media (min-width: 768px) and (max-width: 1023px) {
      .space-card-col {
        @include minmaxwidth(50%)
      }
    }

    @media (min-width: 1023px) and (max-width: 1439px) {
      .space-card-col {
        @include minmaxwidth(33.33%)
      }
    }

    @media(min-width: 1440px) {
      .space-card-col {
        ~ .space-card-col {
            max-width: 25%;
          }
      }
    }
  }

  .big {
    @include dragHandle(196px);
    @include dragHandlePosition(12px, $width: true);

    .os-card-thumbnail {
      .thumbnail-title {
        padding-right: 40px;
      }
    }
    .cs-title {
      font-size: 0.75rem;
    }

    .space-card-col:nth-child(5n),
    .space-card-col:last-child {
      .add-new-section-division:last-child,
      .add-new-section:last-child {
        right: -8px;
        left: auto;
      }
    }

    @media (min-width: 768px) and (max-width: 1099px) {
      .space-card-col {
        @include minmaxwidth(33.33%)
      }

      .space-card-col:nth-child(3n),
      .space-card-col:last-child {
        .add-new-section-division:last-child,
        .add-new-section:last-child {
          right: -12px;
          left: auto;
        }
      }
    }

    @media (min-width: 1100px) and (max-width: 1439px) {
      .space-card-col {
        @include minmaxwidth(25%)
      }

      .space-card-col:nth-child(4n),
      .space-card-col:last-child {
        .add-new-section-division:last-child,
        .add-new-section:last-child {
          right: -12px;
          left: auto;
        }
      }
    }

    @media(min-width: 1440px) {
      @include dragHandlePosition(8px, $width: true);
    }

    .space-card-col ~ .space-card-col {
      .card__content {
        height: 196px;
      }
    }
  }

  .list {
    .modal-body-container {
      .row {
        margin-right: 0;
        margin-left: 0;
      }
    }

    @include spaceCardCol(100%, 0px);
    .cd-list-img {
      position: relative;
    }

    .cd-file-name,
    .cd-extra-info {
      @include text-truncate;
      display: block;
    }

    .table-list {
      position: relative;
      @media screen and (min-width: 768px) {
        margin-bottom: 2px !important;
      }

      @media screen and (min-width: 1440px) {
        padding: 0;

        ~ .space-card-col {
          padding: 0;
        }
      }
    }

    .cards {
      .space-card-col {
        &:first-child {
          .add-new-section-division {
            display: none !important;
          }
        }
      }
    }
  }

  .table-card-row  {
    border: 0 !important;
    border-bottom: 1px solid $level-4 !important;
    border-radius: 0 !important;
    box-shadow: none !important;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
      .cd-date-added {
        width: 14.6%;
      }

      @media screen and (max-width: 767px) {
        .name-block {
          min-width: 100%;
        }
      }
    }

    @media screen and (max-width: 767px) {
      background-color: $level-1;
      border-radius: 4px !important;

      .cd-list-img {
        border-radius: 8px !important;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(53, 69, 84, 0.2);
          border-radius: 8px !important;
          opacity: 0;
        }
      }

      .selectable-item {
        bottom: 16px !important;
        left: 42px !important;
        opacity: 0;
        right: auto;
      }

    }

    &.header {
      font-size: 12px;
      line-height: 16px;
      font-weight: $font-weight-semibold;
      color: $level-7;
      padding-top: 0;
      text-transform: uppercase;
      border: 0 !important;

      .sorting-option {
        font-weight: $font-weight-semibold;

        &.cd-list-select {
          padding-right: 60px;

          @media screen and (min-width: 1300px) and (max-width: 1440px) {
            padding-right: 40px;
          }

          @media screen and (min-width: 768px) and (max-width: 1280px) {
            padding-right: 20px;
          }
        }
      }

      @media screen and (max-width: 767px) {
        padding-top: 8px;
      }
    }
  }

  .selected {
    .table-card-row,
    .os-sm-attachment  {
      background-color: $level-4;

      .selectable-item {
        opacity: 1;
        color: $level-10 !important;
      }

      .cd-list-img {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .table-list:before {
    color: $level-6;
  }
}

.folder-modal-body {
  .big {
    .table-card-row {
      .image-icon {
        font-size: 16px;
      }
    }
  }

  .top-row-cards {
    .row {
      margin: 0px -8px;
    }
  }
  .selectable-item {
    padding-left: 8px !important;
    position: absolute;
    right: 8px;
    bottom: 6px;
    color: $level-8 !important;
    border: 0;
    background-color: transparent !important;

    .btn-icon-helper {
      left: 10px;
      font-size: 20px;
      color: $level-8 !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }


  .list {
    .selectable-item {
      right: 50px;
    }
  }

  .col-thumb {
    .selectable-item {
      opacity: 0;

      .btn-icon-helper {
        left: 6px;
      }
    }
    .os-card-thumbnail:hover {
      .selectable-item {
        opacity: 1;
        background-color: $level-1 !important;
      }
    }

    .selected {
      .os-card-thumbnail {
        .selectable-item {
          opacity: 1;
          background-color: $level-1 !important;
        }
      }
    }
  }
}

.mobile-device {
  .with-sorting {
    .header-dropdown-list {
      right: auto;
      left: 0;
    }
  }
}

.col-thumb {
  .selectable-item {
    bottom: auto;
    top: 24px;
    right: 46px;
    opacity: 0;
    transition: opacity 500ms ease;
    will-change: opacity;
    width: 24px;
    height: 24px;
    line-height: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 4px;
    padding: 0px 6px !important;
    background-color: $level-1 !important;
    position: absolute;
    opacity: 1;
    z-index: 1;

    .btn-icon-helper {
      font-size: 12px;
      left: 5px;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    .selectable-item {
      right: 40px;
    }
  }

}


.big {
  .selectable-item {
    padding-left: 8px !important;
    position: absolute;
    right: 8px;
    bottom: 6px;
    color: $level-8 !important;
    border: 0;
    background-color: transparent !important;

    .btn-icon-helper {
      left: 10px;
      font-size: 20px;
      color: $level-8 !important;
    }

    &:hover {
      box-shadow: none !important;
    }
  }
}


.modal-content {
  .extra-small-min-card {
    .os-sm-space,
    .os-sm-dynamic-template {
      .card-v2-hero, .card-v2-header {
        max-width: 80px;
        min-width: 80px;
      }
    }
    .os-sm-dynamic-template {
      .card-content {
        padding-left: 92px !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .add-folder-modal {
      .folder-views-area {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        justify-content: flex-start;
        background: $level-3;
        top: -1px;
      }
    }
  }
}

html:not(.ipad) {
  @media (max-width: 1199px) {
    .multi-selection-mode {
       &.image-card {
        min-height: 56px;
        max-height: 56px;
      }
    }
  }
}

.table-card-row  {
  .conversation-attachment-icon-holder {
     .fs-24 {
       font-size: 16px !important;
     }
   }
}
