.cropper-view-box{
  border-radius: 50%;
}

.custom-file,
.custom-file .custom-file-input,
.custom-file .custom-file-control{
  border: none;
  background: rgba(0, 0, 0, 0.0);
  width: 100%;
}

.custom-file{
  height: 236px;
  label{
    font-weight: $font-weight-bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    color: #a0a0a0;
  }
  input[type="range"]{
    margin: auto;
    display: inherit;
    width: 200px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.custom-file.preview .custom-file-input
.custom-file.preview .custom-file-control{
  height: 200px;
}

.custom-file.cropping .custom-file-input,
.custom-file.cropping .custom-file-control{
  height: 36px;
}

.custom-file-input:focus ~ .custom-file-control{
  box-shadow: none;
}
