.invoice-list {
  .btn {
    white-space: nowrap;
  }

  .status-summary {
    display: flex;
    gap: 0.75rem;
    
    .status-card {
      flex: 0 0 auto;
      padding: 0.75rem 1.25rem;
      border-radius: 0.5rem;
      text-align: center;
      transition: all 0.2s ease;
      border: 2px solid transparent;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      
      &.selected {
        border-color: currentColor;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      
      .status-title {
        font-weight: 600;
        font-size: 0.875rem;
        margin-bottom: 0.25rem;
      }
      
      .status-count {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }

  .status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    min-width: 80px;

    &.paid {
      background-color: #e8f5e9;
      color: #2e7d32;
    }

    &.unpaid {
      background-color: #fff3e0;
      color: #ef6c00;
    }

    &.overdue {
      background-color: #ffebee;
      color: #c62828;
    }
  }

  .action-buttons {
    .osbtn-icon {
      padding: 0 16px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      width: 48px !important;
      height: 40px !important;
      border: 1px solid #C9D1DA !important;

      &:hover {
        color: #fff !important;
        background-color: #314656;
        .btn-icon-helper {
          color: #fff !important;
        }
      }
      
    }
  }
} 