.update-plan-modal-active {
  .modal-header {
    display: block;
    padding: 0;

    .os-header-btn {
      right: -10px;
    }
  }

  .ifill-os-close {
    position: absolute;
    right: -10px;
    top: 0;
  }

  .modal-footer {
    * {
      margin: 0;
    }

    .update-next-button {
      margin-left: .5rem;
    }
  }

  .plan-subscription-heading {
    margin-top: 32px;
  }

  &:not(.mobile-device) .user-update-modal {
    &.confirmation {
      .modal-content {
        min-height: 624px;
      }

      .user-info-plan-list {
        min-height: 432px;
      }

      .step-2-loader {
        max-height: 298px;
        height: 300px;
      }
    }
  }
}

.user-update-modal {
  .form-label-block {
    padding-bottom: 24px;
  }

  .form-error {
    position: absolute;
    font-size: 12px;
  }

  .form-label-text {
    line-height: 1;
    padding-bottom: 8px;
    white-space: nowrap;
  }

  .modal-footer {
    margin-top: 24px;
  }

  .update-plan-form + .modal-footer {
    margin-top: 48px;
  }

  .payment-form-block {
    padding-top: 40px;

    @meida(min-width: 1400px) {
      padding-bottom: 8px;
    }
  }

  .loader-active {
    max-height: 208px;
    min-height: unset;
    height: 208px;

    .loader-inner {
      margin-top: 0;
    }

    &.step-2-loader {
      max-height: 272px;
      height: 272px;
    }

    &.step-3-loader {
      max-height: 528px;
      height: 528px;
    }
  }

  .card-saving-error {
    top: 10px;
  }
}

.step-svg-icon {
  margin-top: 32px;
  margin-bottom: 24px;
  text-align: center;

  @media(min-width: 1400px) {
    margin-bottom: 32px;
  }
}

.update-plan-label-row {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.update-plan-label {
  position: relative;
  height: 120px;
  width: 240px;
  line-height: 1.33;
  font-weight: normal;
  margin: 0;

  .label-text,
  .label-helper,
  .offer-svg {
    cursor: pointer;
  }

  .label-text {
    position: relative;
    z-index: 1;
    text-align: center;
    margin: auto;
  }

  .plan-validity {
    margin-bottom: 8px;
  }

  .label-helper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 8px;
    border: solid 2px $cl-link;
    background: transparent;
    transition: all 300ms ease;
  }

  .small-text {
    line-height: normal;
  }

  input:checked ~ {
    .label-text,
    .label-helper,
    .offer-svg {
      cursor: default;
    }

    .label-helper {
      background: $cl-link;
    }

    .label-text .small-text {
      color: $level-11;
    }

    .offer-svg {
      transform: scale(0);
    }
  }

  .offer-svg {
    position: absolute;
    right: -6px;
    top: -6px;
  }
}

.plan-info-helper-text {
  padding-bottom: 40px;
}

.plan-info-helper-text,
.view-plan-table {
  margin-bottom: 24px;
}

.view-plan-table {
  border-radius: 8px;
  background: #222226;
  padding: 1rem 1px 1rem 0;
  margin-top: 1rem;

  @media(min-width: 1400px) {
    margin-bottom: 48px;
  }


  .plan-table-row {
    display: flex;
    min-height: 40px;
    align-items: center;

    &:nth-child(even) {
      background: rgba(28, 28, 31, .7);
    }
  }

  .plan-title-col {
    font-size: 12px;
    color: $heading;
    flex: 1;
    padding-left: 31px;
    line-height: normal;
  }

  .plan-action-col {
    font-weight: 600;
    color: #fff;
    width: 43%;
    text-align: center;
    white-space: nowrap;
    line-height: 1;
  }

  .ifill-os-checkmark-round {
    color: $os-link;
    font-size: 24px;
  }
}


.due-today-list-item {
  padding: 10px 0 2px;
}

.user-card-detail {
  border-radius: 6px;
  padding: 16px;
  background: #222226;
}

.step-card-row {
  display: flex;
  padding-top: 24px;

  dt {
    color: $level-11;
  }

  .d-data-list {
    margin: 0;

    &:not(.card-holder-name) {
      white-space: nowrap;
    }

    &.card-holder-name {
      overflow: hidden;
    }

    + .d-data-list {
      margin-left: 40px;
    }
  }
}

.user-plan-dropdown {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(23, 23, 23, 0.2);
  background-color: $dropdown-bg;
  margin-bottom: 32px;

  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin: 0;
    width: 100%;
    line-height: 40px;
    height: 40px;
    cursor: pointer;
    color: $level-11;

    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: $dropdown-bg;
    border-radius: 0 0 4px 4px;
    margin-top: -2px;
    border: 0;
  }

  .dropdown-item {
    line-height: 40px;
    padding: 0 16px;
    font-size: 16px;
    color: $level-11;
    background: none;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }


    &:hover,
    &.active {
      background: $dropdown-list-hover;
      color: $dropdown-hover-text;
    }

    &.active {
      cursor: default;
      color: $dropdown-hover-text;
    }
  }
}

.ipad,
.no-touch-support {
  .user-update-modal {
    .card-holder-col,
    .postal-col {
      .form-label-block {
        padding-bottom: 16px;
      }
    }
  }
}

.step-uesr-agreement-item {
  padding: 8px 0 0;
  margin-top: 16px;

  @media(min-width: 1400px) {
    padding-top: 18px;
    margin-top: 22px;
  }
}

.ipad {
  &.update-plan-modal-active {
    .plan-info-helper-text,
    .view-plan-table {
      margin-bottom: 0;
    }

    .step-card-row {
      .d-data-list + .d-data-list {
        margin-left: 24px;
      }
    }

    .plan-info-helper-text {
      padding-bottom: 0;
    }

    .step-uesr-agreement-item {
      padding-bottom: 16px;
      margin-top: 16px;
    }
  }
}

.mobile-device {
  .step-svg-icon {
    margin: 0;
    order: -1;
  }

  .plan-info-helper-text {
    padding-bottom: 0;
  }

  .update-plan-label {
    width: 216px;
  }

  .step-card-row {
    .d-data-list + .d-data-list {
      margin-left: 36px;
    }
  }

  &.update-plan-modal-active {
    .user-update-modal {
      .modal-header {
        margin-bottom: 24px;
        justify-content: initial;
        display: flex;
        position: static;

        .os-header-btn {
          right: 7px;
          margin-top: 0;
          top: 38px;
        }
      }

      .modal-footer {
        display: flex;
        margin-top: 24px;
      }
    }

    .features-btn {
      padding-left: 16px;
      padding-right: 16px;
      margin-left: -16px;
    }

    .ifill-os-close {
      right: 20px;
      margin-top: 0;
      top: 50px;
    }

    .modal-content {
      max-width: unset;
      width: 100%;
      margin: 0;
      min-height: 100%;
      max-height: 100%;
      display: block;
    }

    .view-plan-table {
      margin-bottom: 0;

      .plan-action-col {
        width: 25%;
      }
    }

    .plan-info-helper-text {
      margin-bottom: 0;
    }

    .step-uesr-agreement-item {
      padding-top: 16px;
    }

    .edit-address-step-svg {
      svg {
        width: 93px;
      }
    }

    &.landscape {
      .modal-body,
      .modal-header,
      .modal-footer {
        width: 460px;
        margin-left: auto;
        margin-right: auto;
      }

      .card-saving-error {
        top: -15px;
      }

      .modal-title {
        align-self: flex-end;
        margin-left: 18px;
        position: relative;
        top: -2px;
      }

      .update-plan-label-row {
        padding-top: 23px;
      }

      .update-plan-label {
        height: 80px;

        &.monthly .plan-validity {
          width: 75px;
        }

        &.yearly .plan-validity {
          width: 60px;
        }
      }

      .card-holder-col,
      .postal-col {
        .form-label-block {
          padding-bottom: 8px;
        }
      }

      .label-text {
        display: flex;
        padding: 0 5px 0 24px;
        text-align: left;
        width: 100%;
      }

      .plan-validity {
        margin: 4px 0 0 0;
      }

      .offer-svg {
        right: -4px;
        top: -6px;
        height: 82px;
        width: 82px;
      }

      .step-uesr-agreement-item {
        padding-bottom: 8px;
      }

      .plan-info-helper-text {
        padding-bottom: 6px;
      }

      .payment-form-block {
        padding-top: 8px;
      }

      .plan-subscription-heading {
        margin-top: 25px;
      }
    }

    &.portrait {
      .card-saving-error {
        top: -23px;
        line-height: 1.2;
      }

      .modal-content {
        padding-left: 15px;
        padding-right: 15px;
      }

      .update-plan-label-row {
        padding-top: 15px;
      }

      .offer-svg {
        right: -5px;
      }

      .step-svg-icon {
        margin: 5px auto 17px;
      }

      .modal-header {
        flex-wrap: wrap;
      }

      .modal-title {
        width: 100%;
      }

      .cvc-col,
      .postal-col {
        .form-label-block {
          padding-bottom: 16px;
        }
      }

      .manage-portrait-footer {
        margin-top: 0 !important;

        .features-btn {
          margin-right: auto;
          margin-top: 13px;

          + .portrait-divider {
              height: 121px;
              width: 100%;
          }
        }
      }

      .view-plan-table {
        margin-left: -16px;
        margin-right: -16px;

        .plan-title-col {
          padding-left: 16px;
        }
      }

      .update-plan-label {
        width: calc(50% - 12px);
      }

      .step-uesr-agreement-item {
        padding-bottom: 24px;
      }

      .plan-subscription-heading {
        margin-top: 24px;
      }

      .payment-form-block {
        padding-top: 8px;
        padding-bottom: 48px;
      }
    }
  }
}

@media(min-width: 1400px) {
  .payment-form-block {
    padding-bottom: 8px;
  }
}
