.my-edit-prf-title {
  @include fontstyle(20px, $font-weight-semibold, 28px);
  margin-bottom: 40px;
}

.my-edit-prf-footer {
  height: 80px;
  width: 100%;
  background-color: $level-1;
  position: fixed;
  bottom: 0;
  z-index: 9;

  .row {
    justify-content: flex-end;
    align-items: center;
    padding:20px 0;
    margin: 0;
  }

  .osbtn + .osbtn {
    margin-left: 24px;
  }

  @media screen and (max-width: 767px) {
    .row {
      justify-content: center;
    }
  }
}

.users.modal-open {
  #root {
    height: 100vh;
  }
}

.profile-back-link {
  margin-top: 24px !important;
  margin-bottom: 32px;
  display: block;

  @media screen and (max-width: 767px) {
    margin-left: 0px !important;
    margin-top: 24px;
    margin-bottom: 32px;
  }
}

.email-no-box {
  label {
    color: $level-11;
    font-size: 16px;
    letter-spacing: normal;
    text-transform: none;
    line-height: 24px;
  }

  .email-field {
    margin-bottom: 24px;
  }

  .email-input-text {
    height: 40px;
    border-radius: 4px;
    padding: 0 16px;
    line-height: 40px;
    cursor: not-allowed;
    background-color: $level-3;
    color: $level-6;
  }

  .contact-action {
    margin: 32px 0 0;
    text-align: right;

    .osbtn {
      width: 100%;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape) and(-webkit-min-device-pixel-ratio: 1) {
      .osbtn {
        padding: 0;
      }
    }

    @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation: portrait) and(-webkit-min-device-pixel-ratio: 1) {
      .osbtn {
        padding: 0;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 24px;
    }

    @media screen and (max-width: 767px) {
      .osbtn {
        min-height: 40px;
        line-height: 40px;
        height: 40px;
      }
    }
  }

}

.my-edit-profile {
  background-color: $block;
  box-shadow: $block-shadow-m;
  padding: 24px 12px;
  margin-bottom: 24px;

  label.form-label {
    letter-spacing: .8px;
  }

  .detail-user-form-bio {
    .form-group {
      height: auto;
      textarea {
        height: 180px;
        color: $level-11;
      }
    }
  }

  label {
    color: $level-11;
    font-size: 16px;
    letter-spacing: normal;
    text-transform: none;
    line-height: 24px;
  }



  input[id*="password"] {
    padding-right: 40px;
  }

  .form-group {
    position: relative;
    margin-bottom: 24px;
  }

  .form-error,
  .password-hint {
    position: absolute;
    top: 72px;
    right: auto;
    left: 0;
    font-size: 10px;
    font-weight: normal;
    text-align: left;
    line-height: 12px;
    margin: 0;
  }

  .form-group {
    width: 100%;
  }

  .detail-user-form-bio {
    textarea {
      line-height: 25px;
    }
  }

  @media screen and (max-width: 767px) {
    .detail-user-form-bio {
      margin-bottom: 54px;
    }
  }
}

.detail-user-form-bio {
  .custom-file {
    &.user-profile-image {
      height: auto !important;
      margin-bottom: 16px;
      text-align: center;

      .signup-user-camera-icon {
        margin-bottom: 0;
      }

      .user-profile-avatar-area {
        display: inline-block;
        text-align: center;
        margin-bottom: 16px;
        height: 120px;
      }

      .signup-card-logo {
        height: 120px;
        width: 120px;
        line-height: 120px;
        margin-bottom: 0;
        margin-top: 0;
        background: $level-3;
        border-radius: 50%;
        text-align: center;
        color: $level-11;
        font-size: 24px;

        .avatar {
          margin-top: 0;
        }

      }

      .profile-file-btn {
        position: absolute;
        left: calc(50% - 8px);
        width: 185px;
        height: 22px;
        bottom: 16px;
        top: auto;
        overflow: hidden;
        transform: translateX(-50%);

        .custom-file-input {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0;
          width: 100%;
          cursor: pointer;
          max-width: unset;
          height: 100%;
        }
      }

      .custom-file-control {
        z-index: 3;
        height: 100px;
        margin-top: 0;
        text-align: right;
        vertical-align: middle;
        text-align: left!important;
        height: auto!important;
        position: relative!important;

        .os-btn-link {
          cursor: pointer;
        }
      }

      .image-edit-cropper-block {
        > div,
        .cropper-crop-box  {
          width: 120px !important;
          height: 120px !important;
        }
        .cropper-container {
          &.cropper-bg {
            width: 120px !important;
            height: 120px !important;
            border: 1px solid hsla(0,0%,100%,.9);
            overflow: hidden;
            border-radius: 100px;

            .cropper-crop-box {
              transform: translateX(0)!important;
            }
          }
        }
      }
    }
  }
}

.account-setting-container {
  .email-no-box {
    margin-top: 32px;
    max-width: 672px;
  }
}

@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation: portrait) and(-webkit-min-device-pixel-ratio: 1) {
  .account-setting-container {
    .email-no-box {
      .email-field {
        flex: 0 0 62%;
        max-width: 62%;
      }
      .col-md-6 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
      .contact-action {
        margin-top: 32px;
      }
    }
  }
}

.security-container {
  padding-bottom: 24px;

  @media(min-width: 768px) {
    display: flex;
    gap: 24px;
  }
}

.security-box {
  font-size: 16px;
  line-height: 1.5;
  background: $block;
  border-radius: 8px;
  padding: 18px 40px 24px;
  width: 100%;
  margin-top: 24px;
  text-align: center;
  box-shadow: $block-shadow;

  @media(min-width: 768px) {
    width: 50%;
    height: 380px;
    padding-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(min-width: 1024px) {
    min-width: 400px;
    width: 400px;
    height: 370px;
    padding-left: 40px;
    padding-right: 40px;
  }

  p {
    line-height: 1.33;
    padding-top: 8px;
    font-size: 12px;
    margin: 0;
  }

  .osbtn {
    width: 168px;
    padding-left: 0;
    padding-right: 0;
  }

  svg {
    width: 173px;
    height: 120px;
    margin-top: 6px;
    margin-bottom: 8px;
  }

  &.security-number-box {
    svg {
      margin-top: 0;
      width: 140px;
    }
  }
}
