.add-message-nav-block {
  padding: 0;
  position: relative;

  .object-filter {
    padding: 0;
    display: inline-flex;
  }

  .list-inline-item {
    margin: 0 !important;
    line-height: 36px;

    &:after {
      content: '';
      margin: 0 !important;
      width: auto !important;
      left: 28px !important;
      right: 28px !important;
      bottom: 0 !important;
    }
  }

  @media(min-width: 1024px) {
    .object-filter {
      margin: 0 -28px;
    }
    .list-inline-item {
      padding: 0 28px !important;

      &:after {
        left: 28px !important;
        right: 28px !important;
      }
    }
  }

  @media(min-width: 768px) and (max-width: 1023px) {
    .object-filter {
      margin: 0 -20px;
    }
    .list-inline-item {
      padding: 0 20px !important;

      &:after {
        left: 20px !important;
        right: 20px !important;
      }
    }
  }

  @media(max-width: 767px) {
    .object-filter {
      margin: 0 -8px;
    }
    .list-inline-item {
      margin: 0 !important;
      padding: 0 8px !important;
      font-size: 12px !important;
      white-space: nowrap;

      &:after {
        left: 8px !important;
        right: 8px !important;
      }
    }
  }

  .object-filter-active {
    cursor: default;
  }
}


%flex-col-h-full {
  height: 100%;
  display: flex;
  flex-direction: column;
}

%flex-vertical-scroll {
  overflow-y: auto;
  max-height: 100%;
}

.modal-body-container {
  @extend %flex-col-h-full;
}


.modal-body-content-header,
.modal-body-content {
  overflow: hidden;
}

.modal-body-content-header {
  max-height: 150px;
  overflow-x: auto;

  &:empty {
    display: none;
  }
}


.modal-xl.modal-global {
  .modal-header .add-message-header-row {
    .nav-search-holder {
      width: 100%;
    }
  }

}

.modal-body-content {
  /*position: relative;*/
  height: 100%;

  &.y-scroll {
    @extend %flex-vertical-scroll;
    padding-right: 16px;
  }

  &.tools-row-body-content {
    overflow: hidden;
    padding-right: 0px;

    #header {
      @extend %flex-col-h-full;
    }
  }
}

.file-dropzone-area {
  border: dashed 1px #8c8c8c;
  height: 100%;
  width: 100%;
  position: relative;
  text-align: center;
}

.non-bs-modal-open {
  overflow: hidden;

  .assign-overlay {
    transition: none;
  }

  #root  {
    .navbar-expand-lg {
      filter: blur(3px) brightness(0.9);

      ~ .container {
          filter: blur(3px) brightness(0.9);
        }
    }
  }

  #footer {
    filter: blur(3px) brightness(0.9);
  }

  @media(min-width: 768px) {
    overflow: unset;

    body {
      overflow: hidden;
      touch-action: none;

      #root {
        touch-action: auto;
      }
    }

    .layout.no-scroll {
      max-height: unset;
      overflow: unset;
    }
  }
}

.dropdown-filters-container {
  .header-dropdown-list-item {
    padding: 0px 16px;
  }
}

.add-to-message-modal {
  background: $modal-bg;
  border-radius: 10px;
  padding: 0 24px;
  min-width: 100%;
  box-shadow: $modal-shadow;

  .tag-sap {
    color: $level-11;
    position: relative;
    top: 3px;
    padding: 0;

    .ifill-os-chevron-right {
      font-size: 16px;
      margin: 0;
    }
  }

  .container {
    max-width: unset;
    width: 100%;
  }

  .small-card {
    > [class*='-240'] {
       max-width: 100%;
       margin-top: 0;
      }
  }

  .dropzone-instruction {
    .mt-3 {
      margin-top: 4rem !important;
    }
  }


  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (-webkit-min-device-pixel-ratio: 1) {
    overflow: hidden;

    .dropzone-instruction {
      .small {
        padding: 0 10px;
      }
    }
  }

  .add-message-modal {
    @extend %flex-col-h-full;
    padding: 0;

    .os-card-v2 {
      margin-left: 2px;
    }
  }

  .header-dropdown-list-item {
    padding-left: 15px !important;

    &::before {
      opacity : 0;
    }
  }

  &.assign-overlay--show {
    height: 85%;
    width: auto;
    max-width: 1160px;
    min-width: unset;
    max-height: 1070px;
    padding-bottom: 24px;
  }

  &.add-folder-modal{
    max-width: 96% ;
    padding-bottom: 40px;
    border-radius: 16px;

    .add-message-modal {
      padding-bottom: 64px;
    }

    .modal-footer {
      bottom: 40px;
    }

    @media screen and (min-width: 1441px) {
      max-width: 1392px ;
    }

    @media screen and (max-width: 767px) {
      max-width: 100% ;
      border-radius: 0;
      padding-bottom: 30px;
      background: $level-2;

      .add-message-modal {
        padding-bottom: 0;
      }

      .modal-footer {
        bottom: 30px;
      }
    }

    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .modal-body {
      height: calc(100% - 1000px);
      max-height: calc(100% - 100px);
    }



    .ifill-os-close {
      font-size: 16px !important;
      color: $level-11 !important;
    }
  }

  .modal-header {
    border-bottom: none !important;
    padding: 0 !important;
  }

  .object-header-bar {
    display: flex;
    border: 0;
    overflow:  initial;

    .header-dropdown-menu {
      height: 22px;
      position: relative;
      margin-top: 5px;
    }

    .page-heading-title {
      padding-right: 20px;
    }
  }


  .add-message-header-row {
    margin: 0;
    width: 100%;
  }

  .add-message-primary-nav {
    position: relative;
    flex: 1;
    overflow: hidden;
    overflow-x: auto;
    height: 44px;
  }

  .add-message-title-row {
    margin: 20px 0;
    padding: 0;
    position: relative;

    .card-title {
      padding-top: 0;
      word-break: break-word;

      i {
        vertical-align: middle;
      }
    }
  }

  .modal-body {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .comment-attachment-block {
    margin: 0;

    &:empty {
      display: none;
    }

    > [class^="col-"] {
      min-width: 256px;
      max-width: 360px;
      max-height: 176px;
      padding: 0 8px;

      &:first-child {
        margin-left: -8px;
      }

      .comment-message-attachment-holder {
        height: 100%;
        max-height: 100%;

        .ifill-os-close {
          top: 5px;
          right: 5px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 24px;
          font-size: 12px;
        }
      }
    }

    .col-image {
      max-width: 136px;
    }

    .card.card--medium,
    .comment-message-attachment-holder {
      margin-bottom: 10px !important;
      margin-top: 0 !important;
    }
  }

  .tags-selected {
    margin: 0 -4px;
  }

  .tags-container {
    .tag-item,
    .tag-buttons {
      height: 40px;
      padding: 0 16px;
      margin: 0 4px 4px;
      line-height: 40px;
      top: 0;
    }

    .tag-item {
      &.selected-tag {
        background: $cl-link;
        color: $level-11;
      }
    }

    .tag-count {
      padding-top: 0;
      top: -9px;
      height: 20px;
      line-height: 20px;
      width: 20px;
      font-size: 12px;
      font-weight: 600;

      .ifill-os-close {
        width: inherit;
        height: inherit;
        font-size: 10px;
        line-height: inherit;

        &:before {
          margin-left: 1px;
        }
      }
    }
  }


  .modal-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;
    padding: 0 24px;

    .user-board-modal-footer-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;

      .add-message-modal-footer-left {
        padding-bottom: 0;

        .private-space-cmt {
          margin-top: 0 !important;
        }
      }
    }

    .container {
      max-width: unset;
      width: 100%;
      padding: 0 0;
    }

    > * {
      margin: 0;
    }
  }

  .add-message-modal-footer-left {
    overflow: hidden;
    padding-bottom: 5px;

    .private-space-cmt {
      margin-left: 0 !important;
    }

    .add-message-modal-footer-tags-container {
      width: auto;
      white-space: nowrap;
      overflow-x: auto;
    }

    .ifill-os-close {
      font-size: 12px;
      margin-left: 8px;
      display: inline-block;
      position: relative;
      color: #fff;
      font-family: sans-serif;
      text-align: center;
      line-height: 12px;
      vertical-align: text-bottom;
      cursor: pointer;
      width: auto;

    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (-webkit-min-device-pixel-ratio: 1) {
    overflow: hidden;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    width: 96%;

    .add-modal-top-block {
      height: auto !important;
    }

    .add-message-header-row {
      width: 100% !important;
      margin: 0;
    }

    .add-message-title-row {
      .card-title {
        font-size: 20px;
        padding-right: 24px;
      }
    }

    .add-message-secondary-nav-block {
      margin: 0 !important;
      padding-top: 15px !important;

      .object-filter {
        li {
          margin-right: 12px !important;
        }
      }
    }

    .modal-body {
      height: auto !important;
      padding: 0 !important;

       & > .row {
        height: auto !important;
       }
    }

    .add-message-tags-row {
      .tags-container {
        &.tags-cases {
          display: block !important;
        }
      }
    }

    .modal-footer {
      .user-board-modal-footer-buttons {
        display: block;
      }

      .container {
        padding: 0;
      }

      .add-message-modal-footer-right {
        text-align: center;
        min-width: 100%;
      }
    }
  }

  .comment-attachment-block:empty {
    display: none;
  }

  .comment-attachment-block {
    padding: 0;
    min-height: 210px;
    overflow: hidden;
    overflow-x: auto;
  }

  .comment-attachment-block {
    flex-wrap: nowrap;
  }
}


.modal-xl {
  &.add-message-modal {

    .add-message-secondary-nav-block {
      width: 100%;
      padding-top: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      display: flex;
      flex-direction: row;

      .object-header-bar {
        width: 80%;
        border-bottom: none;
      }

      .object-filter {
        margin: 0;
        padding: 0 0 0 10px;
      }

      .object-search-block {
        width: 60%;
        height: 29px;
        position: relative;

        .ifill-os-search {
          position: absolute;
          top: 3px;
          left: 10px;
          font-size: 18px;
        }

        .modal-cases-search {
          width: 100%;
          height: 29px;
          border: none;
          padding: 0 10px 0 35px;
          color: #fff !important;
          font-size: 12px;
          line-height: normal;
          background: #1e1e1e !important;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;

          &:focus {
            outline: none;
          }
        }

        .fa-search {
          font-size: 13px;
        }
      }
    }

    .add-message-tags-row {
      .tags-container {
        padding-right: 24px;
      }

      .object-search-block {
        min-width: 320px;
        max-width: 100%;
        margin-bottom: 16px;
        padding-top: 13px;
      }
    }

    .add-message-tags-row,
    .tools-row {
      .hidden-mobile {
        overflow: hidden;
        overflow-y: auto;
        min-height: 48px;
      }

      .tags-container {
        padding-top: 0;

        &.tags-cases {
          max-height: 100px;
          padding-top: 3px;

          .tags-view-all {
            height: 40px;
            padding: 0 16px;
            line-height: 38px;
            margin: 0 4px 4px;
            top: auto;
          }
        }
      }
    }

    .modal-body {
      padding: 0;

      .tools-row {
        .tags-container {
          &.tags-cases {
            overflow-y: auto;
          }
        }
      }

      .tools-cards-container {
        height: unset;
        margin-top: 0;

        .tool-cards-row {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
          }
        }

        .tools-catds-title-row {
          margin-bottom: 10px;
        }

        .cards {
          max-height: 100%;
          padding-bottom: 0;
          overflow: none;
        }
      }
    }

    .cards {
      padding-top: 2px;;
      padding-bottom: 0px;

      &:empty {
        padding-bottom: 0;
      }

      .card--loading {
        margin-bottom: 30px;
      }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      .modal-body {
        .add-message-tags-row {
          .tags-container {
            &.tags-cases {
              .tags-view-all {
                top: 0 !important;
              }
            }
          }
        }
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (-webkit-min-device-pixel-ratio: 1) {
      .modal-body {
        .tools-cards-container {
          height: auto;
          .cards {
            max-height: none !important;
          }
        }
      }
    }

    .modal-dropzone-area {
      position: absolute;
      bottom: 0;
      top: 0;
      background: $block;
      width: auto;
      left: 0;
      right: 12px;
      border-radius: 8px;

      @media screen and (max-width: 767px) {
        right: 0;
      }

      .svg-icon {
        transition: transform 500ms ease;
      }

      &.dropzone-active {
        border: dashed 1px $border-active;

        .svg-icon {
          transform: scale(1.2);
        }
      }
    }

    .dropzone-instruction {
      padding-bottom: 28px;
    }

    .small-file-info {
      max-width: 411px;
      text-align: left;
      margin: 0 auto;
      font-size: 12px;
      color: $level-8;
      line-height: normal;
    }

    .file-finder-btn {
      font-weight: 600;
    }
  }
}

.dropzone-base-container {
  .modal-body-content {
    height: 100% !important;
    position: unset;
  }
}

@media only screen  and (min-device-width : 768px)  and (max-device-width : 1366px)  and (-webkit-min-device-pixel-ratio: 1) {
  .add-to-message-modal {
    &.assign-overlay--show {
      max-width: 95%;
    }
  }
}


@media only screen and (max-device-width : 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
  .cases {
    .add-to-message-modal {
      .object-filter {
        li {
          margin: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) and (-webkit-min-device-pixel-ratio: 1) {
  .add-to-message-modal {
    padding: 0 15px;

    .comment-attachment-block {
      justify-content: flex-start !important;
    }

    .add-message-title-row {
      margin: 20px 0 10px;
    }
  }

  .add-message-nav-block {
    margin-bottom: 0px;
  }
}

.add-to-message-modal {
  &.comment-share  {
    .card--loading {
      .avatar {
        position: absolute;
      }
      .card__title, .loading-content-1 {
        height: 15px;
      }
      .loading-content-2, .loading-content-3{
        display: none;
      }
    }
  }
}

.mobile-device {
  .add-to-message-modal {
    .dropzone-instruction {
      z-index: 1;
    }
  }

  .comment-add-message-modal {
    &.attachment-available  {
      .add-message-modal {
        padding-bottom: 100px;
      }

      .add-folder-modal {
        .add-message-modal {
          padding-bottom: 62px !important;
        }
      }
    }
  }

  &.landscape {
    .add-message-tags-row {
      margin-top: 16px;
    }
  }
}

.ipad {
  &.portrait {
    .add-to-message-modal {
      &.comment-share {
        padding: 0px 15px;

        .modal-body-container {
          height:calc(100% - 85px);
        }

        .col-md-4 {
          max-width: 32.33%;
          @media screen and  (min-device-width: 1024px) {
            max-width: 25%;
          }
        }
      }
    }
  }

  &.landscape {
    .add-to-message-modal {
      &.comment-share {
        .col-lg-3 {
          max-width: 25%;
        }
      }
    }

    @media screen and  (min-device-width: 1024px) {
      .add-to-message-modal {
        &.comment-share {
          .col-md-4 {
            max-width: 32.33%;
          }
          .col-lg-3 {
            max-width: 25%;

          }
        }
      }
    }
  }
}


.add-to-message-modal {
  .modal-dropzone-area {
    opacity: 0;

    &.dropzone-active {
      opacity: 1;
      z-index: 10;
    }
  }

  @media(min-width: 768px) {
    .add-message-title-row {
      .close-assign-overlay {
        right: -14px;
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 124px);
    max-height: calc(100% - 124px);

    &.folder-modal-body {
      overflow-y: auto;
    }

    @media(min-width: 768px) {
      margin-right: -12px;

      .add-message-tags-row {
        padding-right: 12px;
      }
    }

    .mb-4 {
      margin-bottom: 1rem !important;
    }

    .top-row-cards {
      max-height: 44%;
      overflow: hidden;
      overflow-y: auto;
      height: unset;
      margin: 0 0 16px;
      padding-right: 6px;
      padding-top: 12px;
      position: relative;
      padding-bottom: 8px;

      @media(max-width: 767px) {
        max-height: 200px;
        overflow-y: hidden;
        overflow-x: auto;
        margin-bottom: 0;

        .cards {
          flex-wrap: nowrap;
          margin: 0 -8px;

          [class^="col-"] {
            max-width: 90%;
            padding: 0 8px;

            &:only-child {
              max-width: 100%;
            }
          }
        }
      }

      &:empty {
        margin-bottom: 0;
      }

      ~ .modal-body-container {
          .object-search-block  {
            padding-top: 0 !important;
          }
      }
    }

    .modal-body-container {
      padding-right: 6px;
      height: unset;
      overflow: hidden;
      overflow-y: auto;
      flex: 1;
    }

    .modal-body-content {
      padding: 10px 0 0 0;
      height: unset;
      overflow: unset;

      .add-new-card {
        .without-media {
          width: 100%;
          form {
            width: 100%;
          }
        }
      }

    }

    .tags-container .filter-results-block {
      margin-bottom: 24px;
    }
  }

  .osbtn {
    &.osbtn-tag {
      line-height: 38px;
      top: 0;
      margin: 0 4px 4px;
      height: unset;
    }
  }
}

.comment-add-message-modal {
  &.no-attachment-available {
    .top-row-cards {
      display: none;
    }
  }

  &.hide-footer {
    .user-board-modal-footer {
      display: none;
    }
  }

  &.attachment-available {
    .add-message-modal {
      padding-bottom: 56px;
    }

    @media(max-width: 767px) {
      padding-bottom: 0;

      .dropzone-base-container  {
        position: absolute;
        z-index: -9999;
      }
    }
  }

  .no-cases-block {
    padding-top: 48px;
  }

  .no-cases-block,
  .no-cases-message-block {
    padding-bottom: 0;
  }

  .no-cases-message-block {
    color: $primary-text;
    opacity: 0.7;
    font-size: 16px;
    font-weight: 300;
  }
}

.dropzone-with-search {
  .error-field-container {
    .nav-search-input {
      border: solid 1px $lt-red;
    }
  }
}

@media(max-width: 767px) {
  .dropzone-with-search {
    .nav-search-holder {
      height: unset;

      .ifill-os-search {
        left: 10px;
        font-size: 16px;
        top: 50% !important;
        transform: translateY(-50%);
      }

      .nav-search-input {
        padding-left: 30px !important;
        padding-right: 25px !important;
        font-size: 14px;
      }

      .clear-input {
        right: 6px;
      }
    }

    .nav-search-container {
      display: block;
    }

    .search-aside {
      padding-left: 0;
      width: 100%;
      margin-top: 10px;

      .osbtn {
        width: 100%;
      }
    }

    .search-results {
      max-height: 120px !important;
    }
  }
}

@media(max-width: 400px) {
  .dropzone-with-search {
    .nav-search-holder {
      .nav-search-input {
        font-size: 12px;
      }
    }
  }
}

.ipad {
  &.portrait {
    .comment-add-message-modal  {
      .add-message-modal {
        padding-bottom: 80px;
      }

      &.attachment-available  {
        .add-message-modal {
          padding-bottom: 100px;
        }

        .modal-footer {
          padding: 0px 16px;
        }
      }
    }
  }
}

.desktop-small,
.ipad.landscape {
  @media screen and (min-width: 992px) and (max-width: 1024px) {
    .autosuggest-search-container {
      &.with-attached-cards {
        .search-results {
          max-height: 115px;
        }
      }
    }
  }
}

.add-message-modal {
  .record-video-btn {
    position: absolute;
    top: 50%;
    left : 50%;
    margin-left: -75px;
    margin-top: -20px;
  }
}
