.group-inbox-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  .grp-container {
    display: flex;
    width: 100%;
    max-height: 500px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: var(--surface-borders-level-2, #f8f8f8);

    /* shadow-m */
    box-shadow: 0px 3px 6px 0px rgba(53, 69, 84, 0.1),
      0px 10px 20px 0px rgba(28, 50, 79, 0.15);
    .header {
      display: flex;
      padding: 12px;
      width: 100%;
      align-items: center;
      gap: 4px;
      border-radius: 12px 12px 0px 0px;
      background: var(--surface-borders-level-1, #fff);
      box-shadow: 0px 2px 4px 0px rgba(53, 69, 84, 0.12),
        0px 3px 6px 0px rgba(53, 69, 84, 0.15);
      cursor: pointer;
      .info-box {
        display: flex;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        .banner {
          .group-banner-img {
            width: 72px;
            height: 72px;
            border-radius: 8px;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 4px;
          flex: 1 0 0;
          font-size: 12px;
          line-height: 16px;
          font-family: Inter;
          .title {
            color: var(--neutrals-level-7, #818d99);
            font-weight: 600;
            letter-spacing: 0.2px;
            text-transform: uppercase;
          }
          .desc {
            color: var(--neutrals-level-11, #354554);
            font-weight: 400;
            align-self: stretch;
          }
        }
      }
      .header-menu {
        .actions {
          display: flex;
          width: 40px;
          height: 40px;
          padding: 12px;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
          border-radius: 8px;
          cursor: pointer;
          &:hover {
            border-color: #4a749a;
            color: #4a749a;
            background-color: #eff1f4;
            box-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24),
              0 1px 3px 0 rgba(53, 69, 84, 0.12);
          }
          i {
            font-size: 16px;
          }
        }
      }
    }
    .message-box {
      display: flex;
      padding: 0px 16px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      flex: 1 0 0;
      align-self: stretch;
      max-height: 320px;
      overflow: auto;
    }
  }
}
