.care_spaces,
.visitors {
  .loader-active {
    height: 250px;
    background-color: transparent;
    border-bottom: 0;

    .loader-inner {
      margin-top: 0;
    }
  }

  .cards {
    [class^="col-"]:first-child {
      .visitors-table-row {
        border-radius: 12px 12px 0 0;
      }
    }

    [class^="col-"]:last-child {
      .visitors-table-row {
        border-radius: 0 0 12px 12px;
      }
    }
  }
}

.lt-red {
  color: $lt-red !important;
}

.lt-green {
  color: $lt-green !important;
}

.prescription-list {
  .visitors-table-row {
    .v-tb-block {
      align-items: center;
      min-height: 40px;
    }
  }

  .no-records-row {
    padding: 40px 0;
  }

  .section-title {
    display: flex;
    height: 40px;
    align-items: center;
    flex: 1 0;
    color: var(--neutrals-level-11, #354554);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
}

.visitors-table-row {
  padding: 11px 4px 11px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: $level-1;
  margin-bottom: 0;
  color: $level-11;
  border-bottom: 1px solid $border-input-typed-button-2;
  min-height: 64px;
  max-height: max-content;


  .noSorting {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -21px;
    bottom: 5px;

    i {
      height: 8px;
    }
  }

  &.sorting-options-bar {
    background-color: transparent;
    margin-bottom: 0 !important;
    display: none;
  }

  &.selected {
    background-color: $block;
  }

  .v-tb-block {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    padding: 0 8px 0 0;
    @include fontstyle(16px, normal, 1.33);
    // outline: 1px solid red;

    &:last-child {
      padding-right: 0;
    }

    &.pos-rel {
      position: relative;
    }

    &.counter {
      width: 40px;
      min-width: 40px;
      text-align: right;
      color: $level-6;
      justify-content: flex-end;
      align-items: center;
      min-height: 40px;
      z-index: 2;
    }

    &.name-block {
      min-width: 300px;
      max-width: 300px;
      height: 100%;
      padding-left: 8px;
      z-index: 2;

    }

    &.cd-pid{
      @include fontstyle(12px, normal, 1.33);
      min-width: 100px;
      max-width: 100px;
      height: 100%;
      padding-left: 20px;
    }

    &.cd-status{
      min-width: 100px;
      max-width: 100px;
      height: 100%;

      display: flex;
      justify-content: center;
    }

    &.cd-contacts {
      min-width: 150px;
      max-width: 150px;
      height: 100%;
      .vs-ac-toggle {
        white-space: nowrap;
      }
    }

    &.selected {
      color: #dddde0;

      .carespace-name {
        color: $level-11;
      }
    }

    &.cd-label {
      width: 150px;
      height: 100%;
    }
  }

  .contact-text {
    font-size: 12px;
    color: $level-9;
    padding-left: 16px;
  }

  .status-circle {
    justify-content: center;
    display: inline-flex;
    width: 46px;

    .small-status-icon {
      font-size: inherit;
      color: $level-8;
      transform: scale(1.7);
      transform-origin: center;
      align-self: flex-start;

      &.ifill-os-checkmark-round-default {
        transform: scale(0.98);
      }
    }

    .big-status-icon {
      font-size: 20px;
      color: $level-8;

      +.small-circle-group {
        margin-left: 2px;
      }

      &.red {
        position: relative;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background: $lt-red;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .red {
      color: $lt-red;
    }

    .green {
      color: $lt-green;
    }


    .small-circle-group {
      display: inline-flex;
      width: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: 10px;
      height: 20px;
    }
  }

  .sorting-option {
    @include fontstyle(12px, normal, 18px);

    &.name-block {
      padding-left: 12px;
    }
  }

  .cd-list-img {
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    @media(max-width: 767px) {
      margin-right: 6px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .cd-list-info {
    word-break: break-word;

    .cd-file-name+.cd-extra-info {
      padding-top: 4px;
    }

    .cd-extra-info {
      line-height: 1.33;
    }
  }

  .cd-pid {
    padding-left: 20px;
  }

  .cd-information {
    @include fontstyle(12px, normal, 1.33);
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    padding-left: 8px;
  }

  .cd-label{
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    .header-dropdown-menu{
      position: relative;

      .header-dropdown-list{
        overflow: unset;
        max-height:none;

        .header-dropdown-list-item{
          overflow-y: auto;
          max-height: 210px;
          position: unset;

          .sub-labels{
            background: #fff;
            border-radius: 8px;
            box-shadow: $block-shadow;
            min-width: 150px;
            max-height: 200px;
            overflow-y: auto;

            .sub-label{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0 16px;
              &:hover{
                background: #edeff3;
              }
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .cd-lt-activity {
    min-width: 200px;
    max-width: 200px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start !important;
    padding-left: 9px;

    .cd-file-name {
      @include text-truncate();
      display: block;
      font-size: 16px;
    }

    @media(max-width: 1024px) {
      // display: none;
    }
  }

  .cd-extra-info {
    display: block;
  }

  .cd-extra-info,
  .carespace-name {
    color: $level-9;
    @include fontstyle(12px, normal, 16px);
  }

  .cd-label {
    // width: 200px;
  }


  .cd-list-info {
    .cd-file-name {
      line-height: 1.25;
    }
  }

  .cd-file-name {
    @include fontstyle(12px, normal, 1.33);
    color: $level-11;

    .etc-icon {
      &.ifill-os-checkmark-round {
        color: $lt-green;
      }
    }

    .name-hover {
      cursor: pointer;
      color: $level-11;
      font-size: 16px;
      font-weight: normal;

      &.unread {
        font-weight: 600;
      }
      &:hover {
        text-decoration: underline;
        color: $level-10;
      }
    }
  }

  .cd-btn {
    width: 40px;
    margin-left: auto;
  }

  .card-dropdown-menu {
    position: relative;
    top: 0;
    right: 0;
    z-index: unset;
  }

  .badge {
    line-height: 32px;
    padding: 0 16px;
    border-radius: 12px;
    text-transform: none;

    &.contact {
      background-color: #edeff3;
      border: 1px solid $border-button-1;
    }

    &.badge-yellow {
      background-color: $lt-yellow;
    }

    &.badge-sm {
      font-size: 8px;
      line-height: 20px;
      padding: 0 8px;
      margin-left: 4px;
      top: -3px;
      position: relative;
      text-transform: uppercase;
    }

    @media screen and (max-width: 767px) {
      padding: 3px 8px 3px 8px;
      white-space: nowrap;
      @include fontstyle(8px, $font-weight-bold, 12px);
    }
  }

  .cs-file-dropdown {
    min-width: auto;

    .header-dropdown-list {
      left: 0;
      right: auto;
      max-width: 250px;
      max-height: 210px;
      overflow-y: auto;
    }

    .page-heading-title {
      padding: 7px 16px 7px 16px;
      border-radius: 12px;
      @include fontstyle(12px, $font-weight-semibold, 16px);
      background-color: $level-2;
      color: $level-11;
      border: 1px solid transparent;

      .istoggleicon {
        font-size: 16px;
        right: 0;
      }

      .badge-color {
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        border-radius: 12px;
        top: 0;
        z-index: 0;
        opacity: 0.38;
      }

      .pointer {
        z-index: 1;
        padding-right: 22px;
        position: relative;
        text-transform: capitalize;
      }
    }

    .header-dropdown-list-item {
      div {
        padding: 0;

        &.label-list-item {
          padding: 0 16px;
          text-transform: capitalize;
          @include text-truncate();

          .label-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            .sub-label-list{
              position: absolute;
              border-radius: 8px;
              box-shadow: $block-shadow;
            }
          }
        }
      }
    }

    &.dropdown-open {
      .page-heading-title {
        color: $level-12;
      }
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 12px;

      .page-heading-title {
        padding: 3px 0 3px 8px;
        // white-space: nowrap;
        @include fontstyle(8px, $font-weight-bold, 12px);

        .istoggleicon {
          right: 8px;
          font-size: 12px;
        }

        .pointer {
          padding-right: 26px;
        }

      }

      .header-dropdown-list {
        left: 0;
      }
    }
  }

  &.header-row {
    background: transparent;
    border-bottom: 0;
    padding-bottom: 16px;

    i {
      font-size: 1rem;
    }

    .v-tb-block {
      font-weight: $font-weight-bold;
      font-size: 12px;
      line-height: 1.33;
      text-transform: uppercase;
      color: $level-9;
      display: flex;
      justify-content: flex-start;

      >span {
        display: inline-flex;
        align-items: center;
      }

      &.up-sorting {
        color: $level-11;
      }

      &.counter {
        width: 92px;
      }

      &.counter-40 {
        width: 40px;
      }

      &.name-block {
        // position: sticky;
        left: 0px;
        background-color: #f8f8f8;
        padding-left: 50px;
        min-width: 290px;
        max-width: 290px;

        // @media(max-width: 767px) {
        //   padding-left: 22px;
        //   width: 41.5% !important;
        // }

        // @media(min-width: 768px) {
          // @include equalWidth(calc(30% + 40px));
        // }

        // @media(min-width: 1025px) {
        //   @include equalWidth(calc(24% + 40px));
        // }
      }

      &.cd-pid{
        padding-left: 20px;
      }

      &.label-block{
        min-width: 200px;
        max-width: 200px;
        height: 100%;
      }

      &.latest_activity-block{
        // margin-left: 154px;
        min-width: 150px;
        max-width: 150px;
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    // margin-left: 0;
    // margin-right: 0;

    // .counter {
    //   font-size: 8px;
    //   width: 20px !important;
    //   min-width: 20px !important;
    // }

    // &.header-row {
    //   .v-tb-block {
    //     &.name-block {
    //       // @include equalWidth(calc(24% + 20px));
    //       padding-left: 72px;
    //     }
    //   }
    // }
  }

  @media screen and (min-width: 1024px) and (max-width: 1360px) and (orientation: landscape) {
    // margin-left: 0;
    // margin-right: 0;
  }

  @media screen and (max-width: 767px) {
    // padding-left: 0;
    // padding-right: 4px;
    // align-items: flex-start;

    // .counter {
    //   font-size: 8px;
    //   line-height: 12px;
    //   width: 20px !important;
    //   min-width: 20px !important;
    // }

    .cd-list-img {
      width: 24px;
      height: 24px;
    }

    // .cd-contacts {
    //   width: 36%;
    // }

    // .cd-pid {
    //   width: 36%;
    // }

    .v-tb-block {
      &.name-block {
        align-items: flex-start;
        width: 48% !important;

        .avatar-40 {
          @include avatar(24px);
        }
      }
    }
  }
}

.visitors-table-row::-webkit-scrollbar {
  display: none;
}

.label-list-item {
  padding: 0 16px;
  color: $level-9;
  @include fontstyle(12px, normal, 32px);
  white-space: nowrap;
  width: 100%;

  &.form {
    padding: 8px 16px !important;

    .form-group {
      margin-bottom: 0;

      .form-control {
        font-size: 12px;

      }

      .with-error {
        line-height: 16px;
        padding-top: 4px;
      }

      .error {
        border-color: $lt-red;
      }
    }
  }

  @media screen and (min-width: 767px) {
    &:hover{
      background-color: $level-4;
      color: $level-11;

      &.form {
        background-color: transparent;
      }
    }
  }

  &.selected {
    background-color: $level-4;
    color: $level-11;

    &.form {
      background-color: transparent;
    }
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  +.osbtn {
    margin-top: 8px;

    .btn-icon-helper {
      color: $level-8;
    }
  }

  .lb-dot {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    display: inline-block;
  }

}

.header-dropdown-menu {
  .lb-li-footer {
    .osbtn {
      border-radius: 8px;
    }
  }

  .header-dropdown-list-item {
    .lb-li-footer:only-child {
      .osbtn {
        border-radius: 8px;
      }
    }

    .lb-li-body~.lb-li-footer {
      .osbtn {
        border-radius: 0 0 8px 8px;
      }
    }
  }
}


.label-modal {
  .header-dropdown-list-item {
    .lb-li-footer {
      padding-top: 16px;

      .label-form {
        min-width: 100%;
        display: flex;
        justify-content: space-between;

        .save-btn,
        .cancel-btn {
          max-height: 40px;
        }

        .cancel-btn {
          padding-left: 16px;
        }
      }
    }

    .lb-li-body {
      max-height: 480px;
      overflow-y: auto;
    }

    .lb-add-new {
      margin-left: 0;
    }

    @media screen and (max-width: 767px) {
      .lb-li-footer {
        left: 16px;
        right: 16px;
      }
    }

  }

  .lb-list-modal {
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $level-5;

    +.osbtn {
      margin-top: 16px;
      border-color: transparent;
    }

    .label-list-item {
      padding: 0 !important;

      &:hover {
        background-color: transparent;
      }

      .form-group {
        margin-bottom: 0;
        padding-right: 8px;

        .form-control {
          margin-top: 0 !important;
          height: 32px;
          font-size: 12px;
        }
      }
    }
  }

  .badge {
    line-height: 32px;
    padding: 0 16px;
    border-radius: 12px;
    max-width: 100%;
    @include text-truncate();
    position: relative;

    .badge-color {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      opacity: 0.38;
      top: 0;
    }

    .bd-text {
      z-index: 1;
      position: relative;
      text-transform: none;
    }
  }

  .bd-pill {
    width: 47%;
    min-width: 42%;

    // @media screen and (max-width: 767px) {
    //   width: 32%;
    //   min-width: 32%;
    // }
  }

  :not(.header-dropdown-list),
  :not(.lb-li-footer) {
    .label-list-item {
      width: 47%;
      min-width: 42%;

      // @media screen and (max-width: 767px) {
      //   width: 32%;
      //   min-width: 32%;
      // }
    }

    .new-label-container {
      width: 100%;
      min-width: 100%;
    }

  }

  .lb-dropdown {
    min-width: 160px;
    border-left: 1px solid $level-4;
    border-right: 1px solid $level-4;
    margin-right: 8px;

    .page-heading-title {
      padding: 8px 40px 8px 16px;
      border-radius: 8px;
      @include fontstyle(12px, $font-weight-semibold, 16px);
      color: $level-9;

      .istoggleicon {
        font-size: 16px;
        right: 16px;
        color: $level-8;
      }
    }

    .label-list-item {
      padding: 0 16px !important;
      width: 100%;
      white-space: nowrap;
      @include text-truncate();

      &:first-child {
        border-radius: 0;
      }
    }

    .header-dropdown-list {
      left: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .badge {
      padding: 3px 8px 3px 8px;
      white-space: nowrap;
      @include fontstyle(8px, $font-weight-bold, 12px);
    }

    .lb-dropdown {
      min-width: 130px;
    }
  }
}

.vs-accordion {
  .visitors-table-row {
    background-color: $level-3;

    .v-tb-block {
      &.name-block {
        background-color: #f4f7f9 !important;
      }
    }

    .counter {
      font-size: 0;
      background-color: #f4f7f9 !important;
    }

    .cd-file-name {
      @include fontstyle(12px, $font-weight-semibold, 16px);

      .name-hover {
        font-size: 12px;
      }
    }

    .cd-information {
      .cd-file-name {
        font-weight: normal;
      }
    }

    &:last-child {
      border-radius: 0 0 0 0 !important;
    }

    .avatar-40 {
      @include avatar(32px);
    }

    .cd-contacts {
      color: $level-9;
      font-size: 12px;
    }

    .cd-pid {
      color: $level-11;
      font-size: 12px;
    }

    @media screen and (max-width: 767px) {
      // padding-left: 30px;

      .v-tb-block {
        &.name-block {
          line-height: 16px;
          // width: 43.5%;
        }
      }
    }
  }

  .loader-active {
    height: 150px;

    .loader-inner {
      margin: 0;
    }
  }
}

.vs-heading {
  .workspace-space-page-heading {
    span {
      width: max-content;
    }

    i {
      color: $level-8;
      cursor: pointer;
    }

    .info-hover {
      font-size: 16px;
    }

    .space-small-total-pateint {
      min-height: 16px;
      font-size: 12px;
      line-height: 1.33;
      font-weight: normal;
      color: $level-9;
      margin-top: 6px;
    }
  }

  .page-heading-right {
    width: 40%;
    flex-basis: auto;

    .osbtn-icon {
      background-color: $level-1;
      border: 1px solid $level-4;
    }

    .add-new-btn{
      min-width: 142px;
    }
  }

  .nav-search-holder {
    max-width: 70%;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .page-heading-right {
      .osbtn {
        margin-left: 16px !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .nav-search-container+.osbtn {
      display: none;
    }

    .row {
      flex-direction: row !important;
    }

    .page-heading-right {
      flex-direction: row !important;
      margin-top: 0 !important;

      .nav-search-container {
        width: 100%;
      }

      .add-new-btn {
        display: none;
      }

      .osbtn {
        position: relative !important;
        margin-left: 16px !important;

        .ifill-os-search {
          left: 8px !important;
        }
      }

      .input-wrapper {
        max-width: calc(100% - 24px);
        left: 12px;
        right: 12px;
        position: absolute;
        z-index: 9;
      }

      .clear-btn {
        position: absolute;
        right: 12px;
        top: 12px;
        color: $level-8;
      }
    }
  }
}

.vs-ac-toggle {
  color: $level-9;
  font-size: 12px;
  line-height: 1.33;
  cursor: pointer;
  transition: color 500ms ease;
  will-change: color;


  i {
    color: $level-6;
    font-size: 16px;
    vertical-align: middle;
  }


  &.ac-open,
  &:hover {
    color: $level-10;

    span {
      text-decoration: underline;
    }

    i {
      color: $level-9;
      text-decoration: none;
    }
  }
}

.vs-list {
  margin-bottom: 100px;
  overflow: scroll;
  width: 100%;

  .table-wrap{
    min-width: fit-content;
  }

  .cards {
    [class^="col-"]:last-child {
      .cd-label {
        .header-dropdown-menu .header-dropdown-list {
          top: auto;
          bottom: 100%;
          z-index: 9999;
        }
      }
    }

    [class^="col-"] {

      &:only-child,
      &:first-child {
        .cd-label {
          .header-dropdown-menu .header-dropdown-list {
            top: 100%;
            bottom: auto;
            z-index: 9999;
          }
        }
      }
    }
  }
}

.cr-space-modal {
  .cs-legend-list {
    .cs-legend-list-item {
      font-size: 12px;
      line-height: 16px;
      padding-left: 48px;
      position: relative;
      padding: 12px 0 12px 48px;
      font-weight: normal;

      b {
        font-weight: $font-weight-semibold;
      }
    }

    .big-status-icon {
      width: 32px;
      height: 32px;
      line-height: 32px;
      background-color: $level-4;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      margin-right: 26px;
      color: $level-8;
      display: inline-block;

      &.green {
        color: $lt-green;
      }

      &.red {
        color: $lt-red;
        position: relative;

        &:after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 100%;
          background: $lt-red;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    .header-list-icon {
      color: inherit;
    }

    .header-list-icon,
    .big-status-icon {
      border: 1px solid $level-4;
      background: $level-1;
      position: absolute;
      left: 0;
      top: 12px;

      &.uv-user,
      &.v-user {
        background-color: $level-6;
        font-size: 24px;
        color: $level-1;
        border-radius: 50%;
        line-height: 30px;
      }

      &.v-user {
        border: 2px solid $level-10;
        background-color: $level-9;
        line-height: 28px;
      }

      &.info-badge {
        border-radius: 12px;
        padding: 4px 6px;
        background-color: #ffe4ae;
        height: 20px;
        position: absolute;
        box-shadow: none;
        border: 0;

        &:before {
          content: 'info';
          text-transform: uppercase;
          color: $level-11;
          font-size: 8px;
          position: absolute;
          top: 6px;
          line-height: 1;
          left: 7px;
          font-weight: 600;
        }
      }
    }
  }
}

.workspace-space-page-heading {
  .tab-link-tooltip {
    top: 0;
    bottom: auto;
    width: 376px;
    box-shadow: $block-shadow-xs;
    z-index: 9;
    background-color: $level-1;
    padding-top: 0;
    padding-bottom: 0;

    .tooltip-container {
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 28px;
      box-shadow: none;
      border-radius: 16px;
    }

    .legend-heading {
      @include fontstyle(20px, $font-weight-bold, 20px);
      padding-bottom: 18px;
      padding-top: 14px;
    }

    ul {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    @media screen and (max-width: 767px) {
      left: 16px;
      transform: translateX(0);
      width: 320px;
    }
  }

  &.legend-access-tooltip {
    position: relative;

    .no_response-legend {
      .tab-link-tooltip {
        left: 95% !important;
      }
    }

    .not_accepted-legend {
      .tab-link-tooltip {
        left: 75% !important;
      }
    }

    .waiting_for_response-legend {
      .tab-link-tooltip {
        left: 90% !important;
      }
    }
    .tab-link-tooltip {
      left: 90% ;
    }
  }

  .info-hover {
    &:hover {
      +.tab-link-tooltip {
        visibility: visible;
        opacity: 1;
        top: 32px;
        bottom: auto;
      }
    }
  }
}

.with-arrow-icon {
  position: relative;
  z-index: 8;

  &:hover {
    span {
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    pointer-events: none;

    &::after,
    &::before {
      position: absolute;
      content: '';
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
      border: 6px solid transparent;
    }

    &::before {
      border-bottom-color: $dropdown-bg;
      z-index: 1;
      pointer-events: none;
    }

    &::after {
      border-bottom-color: $caret-arrow-border;
      top: 13px;
    }
  }
}


.label-list-item {
  overflow-y: auto;
  max-height: 210px;
  position: unset;

  .sub-labels{
    background: #fff;
    border-radius: 8px;
    box-shadow: $block-shadow;
    min-width: 150px;
    max-height: 250px;
    overflow-y: auto;

    .sub-label{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 16px;
      &:hover{
        background: #edeff3;
      }
      cursor: pointer;
    }
  }
}

.carespaces {
  .vs-heading.workspace-page-head {
    .page-heading-right .osbtn {
      margin-left: 16px;
    }
  }

  .space-search-container {
    .header-list-icon {
      content: '';
    }

    .ifill-os-filter {
      content: '\e942';
    }

    .nav-search-input {
      font-size: 12px;
      padding-left: 40px !important;
    }
  }
}
