$breakpoint-mobile: 768px;
.taskListWrapper {
  margin-bottom: 15px;

  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 8;
    transform: translateZ(0);
    transition: transform .3s, -webkit-transform .3s;
    background: #f8f8f8;
    cursor: pointer;

    @media(max-width: $breakpoint-mobile){
      .btn{
        margin-bottom: 15px;
      }
    }

    .section-title {
      text-transform: capitalize;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #7E91A5;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-bottom: 14px;

      .currentDay {
        flex-grow: 0;
        font-family: Inter;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #354554;
      }
    }
  }
}

.task-completed {
  background: #f4f7f9 !important;
}

.taskWrapper {
  min-height: 80px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 32px 24px;
  gap: 8px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(53, 69, 84, 0.12), 0px 1px 2px rgba(53, 69, 84, 0.24);
  border-radius: 12px;
  cursor: pointer;
  flex: none;
  order: 1;
  flex-grow: 0;

  font-family: 'Inter';
  font-style: normal;

  @media(max-width: $breakpoint-mobile){
    padding: 16px;

    .taskDescription{
      padding-left: 0;
    }
    .card-entity-post{
      margin-left: 0;

      .os-sm-care{
        max-height: unset;
        height: max-content;
        padding: 20px;

        .mailinfo{
          max-width: 140px;
        }
      }
    }

    .task-bottom-space{
      width: 100%;
      margin-left: 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .task-information{
        width: 100%;
        margin-left: 6px;
        .title-wrapper {
          flex-direction: column;
          gap: 4px;
        }
      }
    }
  }
}

// CSS related to additional links in a single task
.taskWrapper {
  .additional-links {
    display: flex;
    flex-wrap: wrap;
    & > div {
      padding: 8px;
      min-width: 300px;
      max-width: 33% !important;
    }
  }
}

.line-through {
  text-decoration: line-through;
}


.taskInformation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .taskTitleAndDueDate {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    // cursor: pointer;

    .title-wrapper {
      display: flex;
      gap: 8px;
    }

    .checked-image-container {
      cursor: pointer;
    }

    .click-popup-wrapper {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      border: 1px solid #979797;
      border-radius: 50%;
      cursor: pointer;
    }

    .title {
      padding-left: 10px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #354554;
      mix-blend-mode: normal;

      .task-card-type {
        font-size: 12px;
        color: #5d7893;
        font-weight: 400;
      }
    }


    .dueDate {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #5D7893;
      mix-blend-mode: normal;

      span {
        margin-right: 4px;
      }

      img {
        margin-bottom: 3px;
        margin-right: 4px;
      }
    }

    .emptyCircle {
      width: 16px;
      height: 16px;
      border: 1px solid #979797;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .taskLabel {
    display: flex;
    align-items: flex-start;
    gap: 4px;

    .header-dropdown-list {
      box-shadow: $block-shadow-m !important;
    }

    .task-chevron {
      min-width: 40px;
      min-height: 40px !important;
      padding: 8px;
      cursor: pointer;
    }

    .teamMembersDropdown {

      .os__menu {
        min-width: 250px !important;
      }

      .react-select-ortho-2,
      .css-b62m3t-container {
        height: 24px;

        .os__control,
        .css-j9wwj5-control {
          border-color: #edeff3;
          background-color: #edeff3;
          height: 24px;
          max-height: 24px;
          min-height: 24px;
          overflow: hidden;
          border-radius: 12px;

          .os__value-container {
            padding: 0;
            border-radius: 12px;

            .os__input-container {
              margin: 0;
              padding: 0;
            }

            .os__single-value {
              max-width: 100%;
              min-width: 140px;


              div {
                display: flex;

                .avatar {
                  margin-right: 4px;
                  width: 16px;
                  min-width: 16px;
                  height: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                  }
                }

                .result-text-data {
                  flex-grow: 0;
                  font-family: Inter;
                  font-size: 12px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  letter-spacing: normal;
                  text-align: left;
                  color: #354554;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }

    .taskLabelContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 8px;
      gap: 4px;
      border-radius: 12px;
      min-height: 24px;

      .singleTaskLabel {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #354554;
      }
    }

    .comment-more {
      .header-dropdown-list {
        z-index: 9;
      }
    }
  }

}

.singleTaskLabel{
  .label-dropdown{
    .dropdown{
      .btn-success{
        padding: 0;
        margin: 0;
        border-color: transparent;
        color: #354545;
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        background-color: transparent;
        height: 24px;
      }

      .dropdown-menu{
        border-radius: 8px;
        min-height: max-content;
        box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
        .dropdown-item{
          height: 32px;

          &:hover{
            background-color: #edeff3;
          }
        }
      }
    }
  }
}

.taskDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #354554;

  mix-blend-mode: normal;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding-left: 35px;

  span {
    cursor: pointer;
  }

  p {
	margin: 0;
  }
}

.attachedEntity {
  margin-left: 35px !important;
  max-width: 60%;
  height: 96px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 12px;
  border-radius: 12px;
  border: solid 1px #c9d1da;
  background-color: #fff;

  .authorAvatar {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(93, 120, 147);
    border-radius: 50%;

    .avatar .avatar-40 {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .entityContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 64px);


    .entityInfoContainer {
      display: flex;
      align-items: center;
      gap: 4px;

      .entityInfo {
        height: 70px;
        margin-top: 15px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        padding: 0;

        .entityTitle {
          height: 16px;
          align-self: stretch;
          flex-grow: 0;
          font-family: Inter;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.2px;
          text-align: left;
          color: #818d99
        }

        .entityDescription {
          height: 34px;
          align-self: stretch;
          flex-grow: 0;
          font-family: Inter;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #354554;
          display: block !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2 !important;
          -webkit-box-orient: vertical !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;

          pre {
            display: block !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
        }

        .entityCreatedAt {
          height: 12px;
          flex-grow: 0;
          font-family: Inter;
          font-size: 8px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #5d7893;
        }
      }

      .entityBtn {
        margin-top: 15px;
        width: 40px;
        height: 40px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    .creatorAndDate {
      margin-bottom: 14px;
      height: 12px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #818d99;
    }

  }
}

.entityContainer {
  margin-left: 35px !important;

  .os-sm-care {
    &:hover {
      background-color: #c9d1da;
    }
  }

  .os-card-v2 {
    .os-sm-comment,.os-sm-conversation-message {
      &:hover {
        background-color: #c9d1da;
      }

      max-height: none !important;
      height: fit-content;

      .commentDate {
        margin-left: 52px !important;
        height: 12px;
        flex-grow: 0;
        font-family: Inter;
        font-size: 8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #7E91A5;
      }

      .cm-card-body {
        .parse-comment-text {
          p {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  .mailinfo,
  .phone-number {
    display: block !important;
  }


}

.taskDate {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #7E91A5;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.assigneeContainer {
  .assigneeList {
    top: -40px;
    left: 170px;
  }
}

.task-bottom-space {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 32px);
  margin-left: 32px;
  gap: 10px;


  .taskLabel {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
    max-width: 85%;

    .task-comment-count {
      font-size: 12px;
      line-height: 16px;
      padding: 4px 8px;
      color: #5d7893;
      mix-blend-mode: normal;
      flex: none;
      display: flex;
      align-items: center;
      gap: 4px;
      padding-left: 0;
      cursor: pointer;
    }

    .teamMembersDropdown {
      .os__menu {
        min-width: 250px !important;
      }
      .os__control {
        border: none;
        box-shadow: none;
        .os__value-container {
          padding: 0;

          .os__single-value {
            max-width: unset;

            &>div:first-child {
              background-color: #edeff3;;
              border-radius: 16px;
              display: flex;
              padding-right: 4px;

              .avatar {
                margin-right: 4px;
              }

              .result-text-data {
                flex-grow: 0;
                font-family: Inter, serif;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                display: flex;
                align-items: center;
                color: #354554;
              }
            }
          }
        }
      }
    }

    .taskLabelContainer {
      display: flex;
      align-items: center;
      border-radius: 16px;
      padding: 0 4px;
      min-height: 24px;

      .singleTaskLabel {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #354554;
      }

    }
  }

  .task-information {
    display: flex;
    white-space: nowrap;
    margin-top: 8px;
    gap: 4px;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #7E91A5;
    mix-blend-mode: normal;
    flex: none;
    order: 0;
    flex-grow: 0;
    flex: 1 1;
  }
}

.task-completed {
  .task-bottom-space {
    .taskLabel {
      .teamMembersDropdown {
        .os__control {
          background-color: #f4f7f9 !important;;
        }
      }
    }
  }
}

.flex-1 {
  flex: 1 1;
}

$breakpoint-tablet: 1024px;

.scheduledTaskContainer {
  position: relative;

  .calendar {
    position: absolute;
    top: -40px;
    left: 170px !important;

    input {
      height: 40px;
      width: 310px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .scheduledTaskContainer {
    position: relative;

    .calendar {
      position: absolute;
      top: -40px;
      left: -138px !important;

      input {
        z-index: 100;
        position: absolute;
        top: -15px;
        height: 40px;
        width: 308px;
      }
    }
  }
}
