$broswer-scroll-width : 10px;

::-webkit-scrollbar {
  width: $broswer-scroll-width;
  height: $broswer-scroll-width;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border: solid 1px transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $scrollBarThumb;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: $broswer-scroll-width;
}

%white-blue-scoller {
  &::-webkit-scrollbar-track {
    background: transparent;
    border: solid 1px transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollBarBlueThumb;
  }
}

%os__scroll,
%black-base-scroller,
%select2-scoller {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: solid 1px transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $scrollBarThumb;
    }
  }
}

body * {
  ::-webkit-scrollbar-track {
    background: transparent;
    border: solid 1px transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: $broswer-scroll-width - 6px;
    height: $broswer-scroll-width - 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: $broswer-scroll-width - 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: $broswer-scroll-width - 6px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $scrollBarThumb;
    }
  }


  .black-base-scroller {
    @extend %black-base-scroller;
  }

  .white-blue-scroller {
    @extend %white-blue-scoller;
  }

  .Select-menu-outer,
  .react-select-ortho-2 [class*="-menu"] {
    .os__menu-notice {
      text-align: left !important;
    }

    .Select-menu {
      @extend %os__scroll;
    }
  }

  .user-suggestion-listings,
  .os__menu-list,
  .black-base-scroller {
    @extend %os__scroll;
  }
}
