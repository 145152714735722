.compare-v2-card {
  display: block;
  @extend %os-card-base;
  height: $card-height-sm;

  .compare-v2-header {
    position: relative;
    height: 100%;
  }

  .card-dropdown-menu {
    right: -8px;
  }

  .caption {
    position: relative;
    align-items: center;
    margin-top: 14px;
    display: flex;
  }

  .compare-id {
    text-transform: uppercase;
    line-height: 1.33;
    font-size: 12px;
  }

  .card-v2-hero {
    overflow: hidden;
  }

  .img-parent {
    display: flex;
    justify-content: space-between;
  }

  .hero-fig {
    margin: 0;
    position: relative;
  }

  .locked-fig {
    .card-hero-image {
      -webkit-filter: blur(4px);
      filter: blur(4px);
    }
  }

  .card-hero-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    filter: saturate(80%);
  }

  .hero-image-overlay {
    @extend %overlay-span;
    top: -1px;
    bottom: -1px;
    
  }

  .card-v2-footer {
    padding: 8px;
    display: flex;
    justify-content: space-between;
  }

  .compare-detail {
    padding-left: 8px;
  }

  .compare-user {
    font-size: 12px;
    color: $level-9;
    line-height: normal;
    margin-top: 4px;
    @include text-truncate;
  }

  .compare-user-fig {
    width: 32px;
    min-width: 32px;
    height: 40px;
    margin: 0;
  }

  .no-user-image {
    background: #1c1c1f;
    display: inline-flex;
  }

  .compare-user-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .compare-user-detail {
    font-size: 12px;
    line-height: 1.33;
  }
}


/*small card*/
.os-sm-comparison {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;

  .img-parent,
  .card-content {
    display: flex;
    flex-direction: column;
  }

  .card-v2-hero {
    height: 100%;
  }

  .img-parent {
    height: inherit;
    overflow: hidden;
  }

  .img-parent,
  .hero-image-overlay {
    border-radius: 8px 0 0 8px;
  }

  .hero-image-overlay {
    left: -1px;
  }

  .hero-fig {
    height: 50%;
  }

  .compare-v2-header {
    width: 40%;
    max-width: 144px;
  }

  .card-content {
    width: 60%;
    justify-content: space-between;
  }

  .compare-info-holder {
    padding-left: 8px;
    padding-right: 8px;
  }

  .caption {
    color: $level-9;
  }

  .compare-name {
    word-break: break-word;
    word-wrap: break-word;
    @include ellipsis-lines(14px, 1.43,2);
  }

  .compare-complete-info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 8px;
  }

  .compare-user-group {
    max-width: 64px;
    display: flex;
    margin-right: 4px;
    border-radius: 2px;
    overflow: hidden;
  }

  .compare-detail-info {
    flex: 1;
    overflow: hidden;
    white-space: normal;
  }

  .compare-user {
    width: 100%;
    margin-top: 8px;
  }

  .card-v2-footer {
    padding: 0 8px 5px 4px;
  }

  .h-caption-icon {
    display: none;
  }

  &.comparison-240 {
    @extend %os-240;

    .compare-v2-header {
      display: none;
    }

    .card-content {
      width: 100%;
    }

    .card-dropdown-menu {
      margin-top: -2px;
      right: -6px;
    }
    .caption {
      margin-top: 16px;
    }

    .compare-info-holder {
      padding-left: 14px;
      padding-right: 14px;
    }

    .card-v2-footer {
      padding-left: 8px;
      padding-bottom: 8px;
    }
  }
}

/*large card*/
.os-lg-comparison {
  width: 100%;
  @extend %os-card-base;
  height: $card-height;
  display: flex;
  flex-direction: column;

  .compare-v2-header {
    min-height: 37.5%;
    height: 37.5%;
    padding: 12px 24px;
  }

  .card-v2-hero {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .img-parent {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .img-parent,
  .hero-image-overlay {
    border-radius: 8px 8px 0 0;
  }

  .hero-image-overlay {
    top: -1px;
    bottom: -1px;
  }

  .hero-fig {
    width: calc(50% - 2px);
  }

  .compare-id {
    margin-left: 8px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    padding-bottom: 16px;
  }

  .caption {
    margin-top: 12px;
  }

  .compare-user-fig {
    width: 32px;
    height: 40px;
    background: #d8d8d8;
    position: relative;
    margin: 0 8px 0 0;
    overflow: hidden;
    border-radius: 2px;
  }

  .card-v2-footer {
    padding-top: 0;
    padding-bottom: 0;
    justify-content: unset;
  }

  .share-mr-auto {
    margin-right: auto;
  }

  .compare-detail-info {
    padding: 8px 24px 0;
  }

  .with-user-info {
    padding-top: 0;
  }

  .compare-name {
    @include fontstyle(20px, 600, 1.4);
    @include text-truncate;
  }

  &:not(.compare-304) {
    .card-v2-footer {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &.comparison-304 {
    .compare-detail-info,
    .compare-v2-header {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
