.new-cs-card {
  .case-card-detail-header, {
    @include fontstyle(20px, 500, 28px);
    padding: 0;
    margin: 0 0 8px;

    .cs-id {
      @include fontstyle(12px, normal, 16px);
      color: $level-9;
      padding-right: 24px;
    }

    a {
     color: $level-9;
      &:hover {
        color: $os-link-hover;
      }
    }

    .cate + .detail-subheader {
      margin-left: 24px;
    }

    .detail-subheader {
      @include fontstyle(20px, 600, 16px)
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .indicator-badge {
      margin-left: 8px;
    }

    .cs-user-info {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
    }

    .cs-id-user-name {
      display: flex;
      align-items: center;

      .os-btn-link {
        .user-title {
          color: inherit !important;
        }
      }

      .detail-subheader {
        @include fontstyle(12px, normal, 16px)
      }
    }

    .user-title {
      font-size: 12px;
      color: $level-9;
    }

    .doc-name-case {
      display: inline-block !important;
      color: $level-9;
      font-size: 12px;
      line-height: 16px;

      &:hover {
        color: $os-link !important;
        text-decoration: underline;

        a, span {
          color: $os-link !important;
        }
      }

      > a, span {
        &:hover {
          color: $level-11;
        }
      }
    }
  }
}

.ipad{
  &.portrait {
    .case-card-detail-header {
      padding-left: 0;

      .detail-subheader {
        margin-bottom: 0;
      }
    }

    .new-cs-card {
      .case-card-detail-header {
        padding-left: 24px;
        padding-right: 16px;
      }
    }

    .cs-header-btn-group {
      .cs-header-btn {
        min-width: 40px;
        min-height: 40px;

        &.reset-btn {
          min-width: 72px;
          line-height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .new-cs-card {
    .case-card-detail-header {
      .detail-subheader {
        padding-left: 0;
        line-height: 20px;
      }

      .back-btn {
        width: 40px;
        padding: 0;
        margin-left: 0;
        color: #6c6c6f;
        margin-right: 16px;
      }

      .cs-user-info {
        padding-bottom: 0;
      }

      .cs-id {
        @include fontstyle(8px, normal, 16px);
        padding-right: 0;
        color: $level-11;
      }

      .detail-subheader {
        @include fontstyle(16px, normal, 18px)
        padding-bottom: 0;
      }

      .or {
        font-size: 8px;
        line-height: 16px;
        color: #6c6c6f;
        font-weight: bold;
        padding: 0 5px;
      }

      .doc-name-case, .user-title {
        font-size: 8px;
        line-height: 16px;
        * {
          font-size: 8px;
        }
      }
    }
  }
}

.cs-header-btn-group {
  text-align: right;
  padding-right: 0;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 0;

  .cs-header-btn {
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    padding: 0  ;
    color: $appt-text !important;
    background-color: $appt-btn !important;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    @include fontstyle(12px, 600, 32px);
    transition: transform .1s ease-out;
    margin: 0 8px 0 0;

    .ifill-os-update {
      font-size: 20px;
    }

    &:last-child {
      margin-right: 0;
    }


    &.active {
      background-color: $appt-btn-hover;
      color: $appt-btn-hover-icon;
      text-decoration: none;

      i {
        color: inherit;
      }
    }

    .button-tooltip {
      width: auto;
      margin: 0;
      white-space: nowrap;
      top: auto;
      bottom: calc(100% + 7px);
      transform: translateX(-50%);
      display: block;
      visibility: hidden;
      pointer-events: none;
    }

    &:hover {
      .button-tooltip {
        visibility: visible;
        pointer-events: auto;
      }
    }
  }
}

.mobile-device {
  .cs-header-btn-group {
    -webkit-user-select: none; /* Safari */
    user-select: none; /* Standard syntax */

    .cs-header-btn {
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      line-height: 34px;

      &.reset-btn {
        min-width: 46px;
        width: auto;
        padding: 0 0;
      }

      &:hover {
        .button-tooltip {
          transition: all 10ms ease;
          transition-delay: 300ms;
        }
      }
    }
  }
  .new-cs-card {
    .cs-id-user-name {
      flex-wrap: wrap;

      .os-btn-link {
        font-size: 8px;
        vertical-align: top;

        + .visible-mobile {
          padding: 0px 2px !important;
        }
      }

      .degree-text {
        display: none;
      }
    }
  }
  .cs-header-btn-group {
    max-width: 104px;
    transition: max-width 200ms ease;
    will-change: max-width;
    position: absolute;
    right: 8px;
    top: 0;
    padding-top: 0;
    padding-right: 48px !important;
    justify-content: flex-start;
    flex-direction: row-reverse;


    .close-btn {
      display: none;
    }

    .more-btn, .close-btn {
      position: absolute;
      right: 8px;
      background-color: transparent;

      .btn-icon-helper {
        color: $level-8;
        font-weight: 600;
      }
    }

    &.full-width-header {
      max-width: calc(100% - 48px);
      justify-content: flex-end;
      flex-direction: row;
      background-color: $level-2;
      padding-right: 0 !important;

      .close-btn {
        display: inline-block;
        right: 0;
      }

      .more-btn {
        display: none;
      }
    }

    .cs-header-btn {
      min-width: 40px;
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
}
