.invite-member-modal {
  max-width: 480px;

  .modal-content {
    width: 480px;
    padding: 24px 24px 24px 16px;
  }

  .modal-header {
    h4 {
      padding-left: 8px;
      margin-bottom: 0;
    }
  }

  .os-header-btn  {
    top: 12px;
  }

  .modal-body {
    padding-top: 24px;
    padding-bottom: 0;
  }

  .nav-search-holder {
    width: 100%;
    height: 40px;
    margin-bottom: 30px;
    padding-left: 8px;

    .form-group {
      margin-bottom: 0;
    }

    .form-control.nav-search-input {
      height: 40px;
    }

    .ifill-os-search {
      line-height: 40px;
      font-size: 16px;
      top: 0px !important;
      transform: none;
    }
  }

  .list-group-wrapper,
  .member-selected {
    display: flex;
    flex-direction: column;
    max-height: 440px;
    height: 440px;
  }

  .suggested-members{
    overflow-y: auto;
    margin-bottom: 12px;
    min-height: 10px;
  }

  .invited-members{
    margin-bottom: 26px;
  }

  .member-list-group {
    max-height: 192px;

    @media(max-width: 400px) {
      max-height: 150px;
    }

    overflow-y: auto;
    padding-right: 0;
  }

  .col-bottom {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding: 0;
  }

  .suggest-member-list-group{
    margin-bottom: 12px;
  }

  .suggest-member-group {
    max-width: 435px;
  }

  .member-list-item {
    max-width: 435px;
  }

  .suggest-member-list-item,
  .member-list-item {
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.5s ease;
    position: relative;

    &:before {
      content: '';
      height: 100%;
      width: 100%;
      border-radius: 2px;
      background-color: $dropdown-list-hover;
      position: absolute;
      display: none;
      z-index: 0;
    }

    &:hover {
      &:before {
        display: block;
      }
    }

  }

  .list-type-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding:4px 8px 4px 8px ;
    border-radius: 2px;
    cursor: pointer;
    @include fontstyle(16px, normal, 1.5);
    position: relative;
    z-index: 1;

    .ifill-os-close {
      &:hover {
        background-color: transparent !important;
      }
    }

    .invite-avtar-name {
      min-width: 225px;
      max-width: 225px;
    }
  }

  .avatar-user-icon {
    font-size: 14px;
    background: #303036;
    text-shadow: none;
    box-shadow: none;
  }

  .member-sugest-label,
  .selected-member-label {
    @include fontstyle(16px, 600, 16px);
    padding-bottom: 14px;
    display: block;
    margin-left: 8px;
  }

  .icon-helper {
    margin-left: 16px;
    margin-right: 0px;
    padding: 12px 8px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .ifill-os-checkmark-round-default {
    color: $level-10;
  }

  .ifill-os-checkmark-round {
    color: $os-link;
  }

  .doctor-name {
    max-width: 225px;
    white-space: nowrap;
    @include text-truncate();
    padding-left: 8px;
    font-weight: $font-weight-semibold;
  }

  .new-member-text {
    @include fontstyle(12px, normal, 16px);
  }

  .loader-active {
    max-height: 288px;

    .loader-inner {
      margin: 0
    }
  }

  .modal-footer {
    padding: 0px 8px 0px 0;
    margin-top: 16px;
  }

  .not-selected {
    padding: 8px 24px;
    color: $level-11;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-left: 8px;

    i {
      font-size: 16px;
      padding-right: 8px;
      color: $lt-red;
    }
  }

  .no-results-text {
    text-align: center;
    display: block;
    padding: 0 10px 0 28px;
  }

  .os__control {
    background: none;
    border: 0;
    min-height: unset;
    width: 70px;
    color: $level-9;
    cursor: pointer;

    &:after {
      content: "\e99d";
      position: absolute;
      font-size: 12px;
      font-family: 'fill-os-icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      right: 0;
      transition: transform 300ms ease;
    }

    &.os__control--menu-is-open {
      color: $os-link;
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  .three-dot {
    .os__control {
      width: 40px;
      right: 8px;

      &:after {
        content: "\e95a" !important;
      }
    }
  }

  .os__value-container {
    padding: 0;
  }

  .os__single-value{
    margin: 0;
    position: static;
    transform: none;
    font-size: 14px;
    max-width: 100%;
    color: $level-9;
    line-height: 32px;
  }

  &.invite-widget {
    padding: 20px 16px 24px 8px;
    border-radius: 10px;
    background-color:  $block;
    box-shadow:  $block-shadow;

    .nav-search-holder {
      margin-top: 24px;
      margin-bottom: 20px;
    }

    .selected-member-label,
    .member-sugest-label {
      color: $level-11;
    }

    .invite-widget-title {
      padding-left: 8px;
      color: $heading;
    }

    .suggested-members {
      padding-right: 8px;
    }

    .list-type-label {
      justify-content: space-between;
      padding: 8px 0px 8px 8px;

      .ifill-os-close {
        font-size: 12px;
        margin-right: 12px;
      }
    }

    .invite-avtar-name {
      max-width: 69%;
      min-width: 69%;
    }

    .list-group-wrapper,
    .member-selected {
      max-height: 468px;
      height: 468px;
    }
  }

}

#footer ~ [class^="css-"] {
  z-index: 1051 !important;
  margin-top: -7px;
  width: 145px;

  .os__menu {
    height: unset;
    margin: 8px 0 0 0;
  }

  .os__menu-list {
    padding: 8px 0;
  }

  .os__option {
    cursor: pointer;
    padding: 0 6px 0 12px;
    line-height: 32px;
    background: none;
    font-size: 14px;
    font-weight: 400
  }

  .header-list-icon {
    margin-right: 0.25rem;
  }
}

.desktop-small {
  .invite-member-modal {
    &.invite-widget {
      .list-group-wrapper,
      .member-selected {
        max-height: 550px;
        height: 550px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .invite-member-modal {
    &.invite-widget {
      border-radius: 0 0  10px 10px;
      max-width: 100%;
      padding: 24px;

      .suggest-member-group {
        max-width: 100%;
      }

      .member-list-item {
        max-width: 100%;
      }

      .invite-widget-title {
        margin-left: 8px;
      }

      .list-group-wrapper,
      .member-selected {
        max-height: 500px;
        height: 500px;
      }
    }
  }
}



.ipad {
  .invite-member-modal:not(.invite-widget ) {
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .invite-widget {
    max-width: 100%;

    .suggest-member-group {
      max-width: 100%;
    }

    .member-list-item {
      max-width: 100%;
    }
  }

  &.portrait {
    .invite-widget {
      padding: 28px 40px 0 32px;
      border-radius: 0;

      .member-list-item {
        .form-group {
          margin-bottom: 0;
        }
      }
    }

    .invite-member-modal {
      .doctor-name {
        max-width: none;
      }
    }
  }
}


.mobile-device {
  &.edit-page {
    .spaces.modal-open {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .invite-member-modal {
    margin: 0px;
    height: 100%;
    max-width: 100%;

    .suggested-members{
      padding-right: 8px;
    }

    .modal-content {
      max-width: 100%;
      height: inherit;
      padding: 32px 9px 16px 8px;
      border-radius: 0;
    }

    .os-header-btn {
      top: 16px;
      right: 8px;
    }

    .modal-body {
      flex-grow: 1;
      overflow: hidden;
      height: 100%;
    }

    .list-group-wrapper,
    .member-selected, {
      height: calc(100% - 64px);
      max-height: calc(100% - 64px);
      overflow: hidden;
      overflow-y: auto;
    }

    .col-bottom {
      height: 100%;
    }

    .nav-search-holder {
      margin-bottom: 24px;
      padding-right: 8px;
    }

    .modal-header {
      h4 {
        margin-bottom: 0;
      }
    }

    .invite-avtar-name {
      width: calc(100% - 120px);
      max-width: calc(100% - 120px);
    }

    .doctor-name {
      max-width: calc(100% - 50px);
      white-space: nowrap;
      @include text-truncate();
    }

    .suggest-member-group {
      padding-left: 8px;

      .invite-avtar-name {
        width: calc(100% - 60px);
        max-width: calc(100% - 60px);
      }
    }

    .suggest-member-list-group{
      padding-bottom: 8px;
      margin-bottom: 0;
    }

    .member-list-group {
      padding-left: 8px;
      padding-right: 8px;
    }

    .member-sugest-label,
    .selected-member-label {
      line-height: 1.75;
    }

    .icon-helper {
      margin-left: 5px;
    }

    .loader-active {
      min-height: unset;
      height: 200px;

      .loader-inner {
        margin: 0
      }
    }

    &.invite-widget {
      border-radius: 0 0;
      padding: 20px 16px 0 16px;
      height: auto;

      .list-group-wrapper,
      .member-selected, {
        height: 500px;
        max-height: 500px;
      }

      .nav-search-holder {
        margin-top: 8px;
      }

      .member-sugest-label,
      .selected-member-label {
        margin-left: 16px;
      }

      .member-list-item {
        .form-group {
          margin-bottom: 0;
        }
      }
    }
  }

  .subspace-container .invite-member-modal {
    .all-select-members {
      .member-list-group {
        height: calc(100% - 42px);
        max-height: unset;
      }

      .col-bottom {
        height: unset;
      }
    }

  }
}

.no-touch-support {
  #footer ~ [class^="css-"] {
    .os__option:hover {
      background-color: $dropdown-list-hover;
      color: $os-link;
    }
  }
}

.spaces,
.companies {
  .os__menu {
    min-width: 138px;
    right: -8px;

    .os__option {
      > span {
        display: flex;
        align-items: center;
        font-size: 12px;

        i {
          margin-right: 8px;
        }
      }
    }
  }

  .modal-share-entity {
    .os__menu {
      right: 0;
    }
  }
}

.editor-custom-checkbox {
  display: inline-block;
  padding-left: 24px;

  + .ifill-os-close {
      display: inline-block;
  }
}

.three-dot-menu {
  display: none !important;
}

.desktop-hd {
  .three-dot-menu {
    display: none !important;
  }
}

.desktop-small {
  .three-dot-menu {
    display: flex !important;
  }
}


@media(min-width: 991px) {
  .subspace-container .modal-edit-board {
    .desktop-landscape-only-col {
      .invite-member-modal {
        height: 100%;
        display: flex;
        flex-direction: column;

        .member-selected {
          height: calc(100vh - 350px);
          max-height: calc(100vh - 350px);
        }

        .member-selected-list-group.all-select-members {
          height: calc(100vh - 350px);
          max-height: calc(100vh - 350px);

          .invited-members {
            margin-bottom: 0;
          }

          .member-list-group {
            max-height: 280px;
          }
        }
      }
    }
  }
}

.member-head {
  .osbtn-primary {
    &.me-3 {
      margin-right: 0 !important;
    }
  }

  .space-details-table-row {
    position: relative;

    .cloud-member-dropdown {
      top: 11px;
    }
  }
}
