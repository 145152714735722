@import 'app/styles/_style_guide';

.desktop-small,
.ipad.landscape {
  .pulse-right-sidebar {
    display: none;
  }
}

.team-conversation {
  height: calc(100vh - 64px);
  margin-bottom: 0;
  min-height: auto;
  overflow: hidden;
}

.dm-chat-window {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 2px;
  padding: 0 12px;

  .dm-chat-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #c9d1da;
    padding: 0 24px;
    padding-top: 8px;
  }

  .header-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .left-header {
      align-items: center;
      display: flex;
      gap: 8px;
      min-height: 48px !important;
      height: -moz-fit-content !important;
      height: fit-content !important;

      .dm-back-btn {
        padding: 8px;
        display: none;
      }

      .avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
        min-width: 40px;
        font-size: 30px;
        border-width: 2px !important;
        vertical-align: middle;
        display: inline-flex;

        i {
          margin: auto;
        }
      }

      .space-detail {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .title {
          text-transform: capitalize;
          font-size: 16px;
          font-weight: 700;
          line-height: 18px;
          margin-left: 8px;

          .os-btn-link {
            color: #354554;
          }
        }

        .info {
          color: #5d7893;
          cursor: pointer;
          display: flex;
          font-size: 12px;
          gap: 24px;
          line-height: 1.33;

          span {
            letter-spacing: 0.24px;
          }
        }
      }
    }
  }

  .team-nav-list {
    margin-top: 16px;

    ul {
      display: flex;
      flex-direction: row;
      gap: 24px;
      padding: 0 16px;
      margin: 4px;

      li {
        font-size: 12px;
        font-weight: 600;
        color: #7e91a5;
        cursor: pointer;
        text-transform: uppercase;
        list-style: none;

        &.active {
          color: #354554;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            background-color: #354554;
            top: 21px;
            left: 0;
            width: 100%;
            height: 2px;
          }
        }
      }
    }
  }

  .list-messages {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;
  }

  .conversation-input-section {
    background-color: #fff;

    .conversation-input-holder {
      position: relative;

      .messenger-input-container {
        position: relative;
      }
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    .dm-chat-header {
      background: #fff;
      left: 0;
      margin-left: 0;
      margin-right: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;
      padding: 8px 8px 0;
    }
  }

  // CSS REGARDING NEW MESSAGE EDITOR.
  #new-message-editor {
    position: relative;
    background-color: white;
    padding-top: 16px;

    .mailinfo,
    .phone-number {
      display: block !important;
    }

    .editor-attachments {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
    }

    .tip-tap-editor-field {
      border: 1px solid grey;
      min-height: 42px !important;
      width: calc(100% - 200px);
      margin-bottom: 65px;
      margin-left: 16px;

      .menu-bar {
        position: absolute;
        bottom: 8px;
        left: 10px;
        zoom: 1.4;

        .emoji-picker-btn-container {
          button {
            border: 1px solid #eff1f4;;
          }
        }
      }
    }

    .drag-overlay {
      position: unset;
    }

    & > button {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }

    .options {
      & > div:first-child {
        margin: 0;
        padding: 0;
        width: fit-content;
        position: absolute;
        bottom: 66px;
        right: 32px;

        .attachment-icon {
          margin-right: 8px;
        }
      }

      .list-unstyled {
        top: -40px;
        left: unset;
        right: 54px;
      }
    }
  }
}

.teamDirectMessenger {
  overflow-y: auto;
  height: calc(100vh - 60px);
  background: #fff;
  top: 60px;
  position: sticky;

  .three-block-sticky {
    background-color: var(--bs-body-bg);
  }
}

@media only screen and (max-width: 1023px) {
  .teamDirectMessenger {
    height: auto;
  }
}

.mobile-device {
  .dm-conversation {
    margin-top: 0 !important;
    height: calc(100vh);
  }

  .dm-chat-window {
    .header-info {
      .dm-back-btn {
        display: inline-block;
      }
    }

    .list-messages {
      margin-top: 100px;
      padding-bottom: 20px;
      height: calc(100% - 224px);
    }

    .conversation-input-section {
      width: 100%;
      min-height: 72px;
      padding: 16px 15px;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
      margin-top: 4px;

      .conversation-input-holder {
        display: inline-block;
        width: 100%;

        .messenger-input-container {
          margin-bottom: 0;
        }

        .media-body {
          margin-top: 0;

          .mention-textarea-wrap__input {
            padding-top: 10px !important;
          }
        }
      }
    }

    #new-message-editor {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
      width: 100%;

      .options {
        & > div:first-child {
          margin: 0;
          padding: 0;
          width: fit-content;
          right: 32px;
          display: flex;
          position: absolute;
          left: 16px;
          bottom: 16px;
        }

        .list-unstyled {
          top: -40px;
          left: 47px;
          right: 54px;
        }
      }

      .tip-tap-editor-field {
        width: calc(100% - 35px);
      }
    }
  }
}
