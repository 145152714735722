.container-section {
  .container-section__header {
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 52px;
    justify-content: start;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #EDEFF3;
    }

    .title,
    .alternate-title {
      font-size: 12px;
      color: #7E91A5;
      margin: auto 0;
      font-weight: 600;
      flex-grow: 2;
    }

    .carespace-section-count {
      margin-right: 16px;
    }

    .dropdown-icon {
      margin-right: 14px;
    }

    .alternate-title {
      display: none;
      opacity: 0;
    }
  }

  .notification-count {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .ac-user-list {
    margin-left: -4px;
	.os-btn-link {
		&:hover {
			text-decoration: none;
       .ac-user-name {
          & > span {
            text-decoration: underline !important;
          }
      }
		}
	}
  }
}



.desktop-md,
.desktop-small,
.ipad {
  .left-wrap {
    .left-care-space-section {
      .container-section {
        .container-section__header {
          display: flex;
          justify-content: center;
          transform: translate(-25px, 0);
          transition: all 1s ease-in-out;

          .carespace-section-count {
            display: none;
          }

          .dropdown-icon {
            display: none;
            opacity: 0;
          }

          .alternate-title {
            display: block;
            opacity: 1;
          }

          .title {
            display: none;
            opacity: 0;
          }
        }

        .ac-user-list {
          margin-left: -14px !important;
        }

        .group-space-list {
          margin-left: -16px !important;
          padding: 0px 16px 0 50px;
          li {
            a {
              padding: 8px;
              .ac-user-name {
                display: none;
              }
            }
          }
          .actions {
            display: none;
          }
        }
      }
    }

  }

  .left-wrap {
    &:hover {
      .left-care-space-section {
        overflow: hidden;

        .container-section {
          .container-section__header {
            display: flex;
            align-items: center;
            height: 32px;
            transform: translate(0px, 0);
            transition: all 1s ease-in-out;
            justify-content: space-between;

            .dropdown-icon {
              display: block;
              opacity: 1;

              &:hover {
                cursor: pointer;
              }
            }

            .alternate-title {
              display: none;
              opacity: 0;
            }

            .title {
              display: block;
              opacity: 1;
            }
          }

          .ac-user-list {
            margin-left: 0 !important;

            .notification-count {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .group-space-list {
            margin-left: 0 !important;
            padding-right: 16px;
            li {
              a {
                padding: 8px;
                .ac-user-name {
                  display: flex;
                }
              }
            }
            .actions {
              display: block;
            }
          }
        }

      }
    }

  }
}