.smallerScreenWidget {
  position: absolute;
  top: 40px;
  left: 2px;
  background: #fff;
  z-index: 9;
  width: 100%;
  max-height: 70vh;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 12px 12px 24px;
  box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: scroll;

  .heading {
    height: 16px;
    flex-grow: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    text-align: left;
    color: #7e91a5;
    margin-bottom: 12px;
  }

  .labels {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .label {
      padding: 2px 8px;
      border-radius: 12px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      .labelText {
        white-space: pre-wrap;
        flex-grow: 0;
        font-family: Inter;
        font-size: 8px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        text-align: left;
        color: #354554;
      }
    }
  }

  .members {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .member {
      height: 24px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding: 4px 8px;
      border-radius: 12px;
      background-color: #edeff3;

      .avatar.avatar-initials {
        height: 16px;
        width: 16px;
      }

      .memberName {
        height: 16px;
        flex-grow: 0;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #354554;
      }
    }
  }
}