.three-col-wrapper {

  flex: 1 1 auto; 
  display: flex !important;
  justify-content: space-between;
  flex-wrap: nowrap !important;

  .row-boxed {
    flex: 1;

    @media (max-width: 1439px) {
      flex: auto;
    }
  }

  &.full-view {
    .row-boxed {
      flex: 1 1;
      width: calc(100% - 368px);
      max-width: 80%;

      @media (max-width: 1440px) {
        max-width: 100%;
      }
    }
  }
}
