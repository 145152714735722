.popular-topic-modal {
  .modal-content {
    height: 100vh;

    @media(min-width: 768px) {
      min-height: 208px;
      max-height: 350px;
    }
  }

  .modal-body-container {
    overflow: auto;
  }

  .modal-header,
  .modal-body {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;

    .os-header-btn {
      right: 0;
    }
  }


  .modal-body {
    height: 100%;
    overflow: hidden auto;
  }

  .popular-topics {
    margin-top: -16px;

    .hashtag-blocks {
      flex-wrap: wrap;
    }
  }
}
