.modal-global.modal-update-password {
  width: 480px;
  max-width: 100%;
  padding: 0;
  margin: auto;
  height: 100%;
  display: flex;

  .modal-content {
    margin: auto;
    width: 100%;
    min-height: 438px;
    padding: 24px 32px 40px;
  }

  &.old-password-not-required {
    .modal-content {
      min-height: 400px;
    }

    .form-group {
      margin-bottom: 24px !important;
    }
  }

  .modal-header {
    padding: 0 0 24px;
    border: 0;
    display: flex;
    align-items: center;

    .card-title {
      font-family: $font-family-base;
      font-size: 20px;
      font-weight: $font-weight-bold;
      letter-spacing: 0;
      text-transform: none;
      line-height: 28px;
    }

    .change-password-close {
      margin-right: -12px;
    }
  }
  .modal-body {
    padding: 0;

    input.error {
      border: 1px solid $lt-red;
      color: $lt-red;
    }
  }

  .password-hint {
    font-size: 12px;
    font-weight: normal;
    line-height: normal;
    margin-top: 8px !important;
    display: block;
    color: $level-11;
    letter-spacing: -0.1px;
  }

  .form-error + .password-hint {
    margin-top: 0;
  }

  .ifill-os-views,
  .ifill-os-hide-password {
    position: absolute;
    top: 43px;
    right: 16px;
    cursor: pointer;
    color: $level-11;
  }

  label {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: $level-11;
  }

  .password-info-text {
    font-size: 12px;
  }

  .form-group {
    margin-bottom: 18px;
  }

  .form-action-row {
    text-align: right;
    margin-top: -10px;
  }

  .form-label-hint {
    width: 15px;
    margin-left: 10px;
    display: inline-block;
    position: relative;
    top: 2px;

    &:hover {
      .form-label-info-box {
        display: block;
      }
    }

    i {
      width: 13px;
      height: 13px;
      cursor: pointer;
      background-size: 13px auto;
    }

    .form-label-info-box {
      width: 200px;
      margin-left: -100px;
      padding: 10px;
      display: none;
      position: absolute;
      bottom: 17px;
      left: 50%;
      background: #1e1e1e;
      border-radius: 5px;

      &::after {
        content: '';
        width: 0;
        height: 0;
        margin-left: -10px;
        border-right: solid 10px transparent;
        border-left: solid 10px transparent;
        border-top: solid 10px #1e1e1e;
        position: absolute;
        bottom: -10px;
        left: 50%;
      }

      .form-label-info-row {
        margin: 3px 0 0;
        padding: 0;
        list-style: none;
        color: #fff;
        font-size: 12px;
        font-weight: $font-weight-normal;
        line-height: 15px;
        text-transform: none;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .form-group {
    position: relative;
  }

  .btn-save-user {
    background-color: #fff;
  }

  .svg-icon {
    max-width: 100%;
  }

  .password-success-svg {
    margin-top: -14px;
  }

  .password-success-text {
    font-size: 24px;
    font-weight: 300;
    margin: 1rem 0;
    line-height: normal;
  }

  .password-success-msg {
    margin-bottom: 63px;
    padding: 0 10%;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 1024px) {
    .form-error {
      top: 71px !important;
    }
  }
}


@media (max-width: 767px) {
  .modal-global.modal-update-password {
    .modal-content {
      padding: 60px 20px 0;
      min-height: 100%;
      border: 0;
      border-radius: 0
    }
  }
}
