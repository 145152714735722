/*.pt-file-card-wrap {
  height: 340px;
  overflow: hidden;
  overflow-y: auto;
  transform: translateY(-8px);
}
*/
.top-selected-card-row {
  .row {
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 24px;
    height: 130px;
    max-height: 130px;
    overflow: hidden;
    overflow-y: auto;

    &:empty {
      margin-bottom:0;
      height: 0;
    }

    [class^="col-"] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.card-row-list {
  max-height: 164px;
  overflow: hidden;
  overflow-y: auto;
}

.pt-file-card {
  max-height: 64px;
  height: 64px;
  padding: 12px 48px 12px 72px;
  background-color: #222226;
  border-radius: 4px;
  position: relative;
  margin-bottom: 16px;

  .ifill-os-more {
    &.vertical {
      transform: rotate(90deg);
      top: 12px;
      left: 12px;
      color: #6c6c6f
    }
  }
}

.pt-file-icon {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  font-size: 24px;
  text-align: center;
  line-height: 48px;
  background: linear-gradient(43deg, rgba(187,140,199,1) 10%, rgba(214,148,193,1) 84%);
  position: absolute;
  left: 16px;
  top: 8px;
}

.pt-file-title {
  color: $level-11;
  font-size: 16px;
  @include text-truncate();
}

.pt-file-type {
  color: #e7e7ea;
  font-size: 12px;
}

.pt-card-dropdown {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 4px;
}

.pt-search-block {
  margin-top: 0;
  margin-bottom: 32px;

  .input-wrapper {
    width: 100%;
    height: 40px;

    .form-control.nav-search-input {
      height: 40px;
      padding-left: 40px;
    }

    .ifill-os-search {
      line-height: 40px;
      color: #6c6c6f;
    }

    ::-webkit-input-placeholder {
      color: $level-8;
    }
    ::-moz-placeholder {
      color: $level-8;
    }
    :-ms-input-placeholder {
      color: $level-8;
    }
    :-moz-placeholder {
      color: $level-8;
    }
  }

  label {
    margin-right: 32px !important;
    @media (max-width: 767px) {
      margin-right: 0 !important;
    }
  }

  .search-aside {
    .osbtn {
      padding: 0px 16px !important;
    }
  }
}

.pt-file-card-wrap {
  .modal-dropzone-area  {
    height: 100%;
    border-color: transparent;
    background-image: repeating-linear-gradient(-60deg, #3e3e45, #3e3e45 3px, transparent 3px, transparent 5px, #3e3e45 5px), repeating-linear-gradient(30deg, #3e3e45, #3e3e45 3px, transparent 3px, transparent 5px, #3e3e45 5px), repeating-linear-gradient(120deg, #3e3e45, #3e3e45 3px, transparent 3px, transparent 5px, #3e3e45 5px), repeating-linear-gradient(210deg, #3e3e45, #3e3e45 3px, transparent 3px, transparent 5px, #3e3e45 5px);
    background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;


    .dropzone-instruction {
      padding-bottom: 0;
      font-size: 12px;
      color: #6c6c6f;
      justify-content: center;
    }

    .file-finder-btn {
      text-transform: uppercase;
      font-size: 12px;
      margin-top: 0;
      cursor: pointer;
    }
  }

  .prev-cards-block {
    height: 260px;
    overflow: hidden;
    overflow-y: auto;
    transform: translateY(-8px);

    .row {
      margin-left: -8px;
      margin-right: -8px;

      [class^="col-"] {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .pt-search-block {
    .input-wrapper {
      height: auto;

      .ifill-os-search {
        top: 20px !important;

      }

      .nav-search-input {
        padding-left: 44px !important;
      }
    }

    .search-aside {
      margin-top: 16px;
      width: 100%;
      .osbtn {
        min-width: 100%;
      }
    }
  }

  .pt-file-card-wrap {
    height: 100%;

    .prev-cards-block {
      height: calc(100% - 116px);
    }

    &.top-visible {
      .prev-cards-block {
        height: calc(100% - 260px);
      }
    }
  }
}
