.custom-bootstrap-checkbox {
  input[type='checkbox'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    margin-bottom:0;
  }
}

.mnd-label {
  &:after {
    content: '*';
    color: $level-10;
  }
}

.form-group  {
  margin-bottom: 1rem;
}

.wysisyg-editor{
  background-color: $level-1 !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  background-clip: padding-box !important;
}

.react-select-ortho {
  .os__menu-notice {
    text-align: left !important;
  }
  .Select-control {
    background: $level-2;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .Select-placeholder {
    background: $level-2;
  }

  &.Select--multi .Select-value {
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 126, 255, 0.10);
  }

  &.Select--multi .Select-value-icon {
    border-right: 1px solid rgba(0, 126, 255, 0.10);
  }

  &.is-focused:not(.is-open) > .Select-control {
    border-color: inherit;
    box-shadow: none;
  }
}

.os__control--is-disabled {
  pointer-events: none;
  background-color: $input-disabled-bg !important;
  border-color: $input-disabled-border-color;
  // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
  opacity: 1;
}

input[type='text'],
input[type='search'],
input[type='password'],
input[type='email'],
input[type='number'],
textarea, input[type='url'] {
  &.form-control {
    background: $input-bg;
    color: $input-text-color;
    font-size: 1rem;
    min-height: 40px;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus,
    &:active {
      border-color: $input-border-active;
    }

    &:placeholder {
      color: $textarea-placeholder;
    }

    @include placeholder {
      color: $input-placeholder;
    }

    &.disabled,
    &[disabled="true"],
    &[readonly] {
      @include placeholder {
        color: $input-placeholder;
      }
    }

    &.error {
      border-color: $lt-red;
    }

    &:-webkit-autofill,
    &:-internal-autofill-selected,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background: $block !important;
      -webkit-text-fill-color: $level-11!important;
    }

    &[type="password"] {
      font-size:18px !important;
    }
  }
  &.form-control:disabled {
    pointer-events: none;
    background-color: $input-disabled-bg !important;
    border-color: $input-disabled-border-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

textarea::-webkit-input-placeholder { /* WebKit browsers */
    color: $lt-red
}
textarea:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: $lt-red
}
textarea::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: $lt-red
}
textarea:-ms-input-placeholder { /* Internet Explorer 10+ */
   color: $lt-red
}

hr.hr--boardform {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  opacity: 0.13;
  border-color: $level-2;
}

.floating-status-toggle{
  position: absolute;
  top: -17px;
}

.invisible-input{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.input-modal{
    .form-control{
      border: 1px solid $level-8;
      background-color: $level-8;
      width: 30%;
      &::placeholder {
        color: $level-1;
      }
      &:focus{
        width: 50%;
        background-color: $level-10;
      }
    }
  }
  .form-control{
    border: 1px solid $level-11;
    box-shadow: none;
    width: 60%;
    transition: 500ms;
    background-color: $level-11;
    padding-left: 30px;
    &::placeholder {
      color: $level-1;
    }
    &:focus{
      border: 1px solid $level-2;
      width: 100%;
      background-color: $level-11;
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .search-icon-container{
    color: $level-1;
    position: relative;
    right: -25px;
  }
  .clear-input{
    color: $level-1;
    position: absolute;
    right: 20px;
    i{
      cursor: pointer;
    }
  }
}


.address-card {
  .user-edit-block {
    .os--is-disabled {
      pointer-events: unset !important;
      cursor: not-allowed;
    }
  }
}

.switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 48px;
  margin-bottom: 0;


  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 24px;
    border-radius: 2px;
    background-color: $level-5 ;
    -webkit-transition: .4s;
    transition: .4s;
    border-color: transparent;

    + .cs-slider-after {
      opacity: 0;
    }

    &:before{
      position: absolute;
      content: "";
      background-color: $level-4;
      border: 5px solid $level-7;
      -webkit-transition: .4s;
      transition: .4s;
      z-index: 1;
      border-radius: 2px;
      width: 20px;
      height: 20px;
      left: 2px;
      top: 2px;
    }
    &.round {
      width: 56px;
      height: 32px;
      border-radius: 16px;
      background-color: $level-4;

      &:before {
        height: 24px;
        width: 24px;
        left: 4px;
        top: 4px;
        border-radius: 50%;
        background-color: $level-1;
      }
    }
  }

  input {
    &:checked {
      + .slider {
          &:before {
            -webkit-transform: translateX(24px);
            -ms-transform: translateX(24px);
            transform: translateX(24px);
            background-color: $level-10;
            left: -11px;
            width: 20px;
            height: 20px;
            transform:translateX(36px);
            border: 5px solid $level-10;
            border-radius: 2px;
            top: 2px;
          }
          &.round {
            &:before {
              height: 24px;
              width: 24px;
              top: 4px;
              border-radius: 50%;
            }
          }
      }

      &:disabled {
        + .slider{
            cursor: not-allowed;

          &:before {
            border: 5px solid rgb(43,43, 47);
          }
        }
      }
    }

    &:focus{
      + .slider {
        box-shadow: 0 0 1px $cl-link;
      }
    }
  }

  &[data-hover="true"]:hover {
    .round:after {
      opacity: 1;
    }
  }
}


.checkbox-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  .form-group {
    margin-bottom: 0 !important;
    display: inline-block;
  }
}

.os-custom-checkbox {
  font-size: 12px;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
  margin: 0 0 0 0;

  &:after {
    content: none !important;
  }

  .check-icon {
    position: absolute;
    font-size: 16px;
    transition: all .5s ease;
    left: 0;
    top: 0;
    color: $level-9;
  }

  .ifill-os-checkmark-checked {
    transform: scale(0);
  }

  .check-icon-input {
    display: none;

    &:checked ~ {
      .ifill-os-checkmark-default-white {
        transform: scale(0);
      }

      .ifill-os-checkmark-checked {
        transform: scale(1);
        color: $os-link;
      }
    }
  }
}

.os-custom-radio {
  font-size: 12px;
  padding-left: 24px;
  cursor: pointer;
  position: relative;
  margin: 0;

  .icon-helper {
    position: absolute;
    font-size: 16px;
    transition: all .5s ease;
    left: 0;
    top: 0;
    color: $level-9;
  }

  .default-check {
    transform: scale(0);
  }

  input {
    display: none;

    &:checked ~ {
      .default-uncheck {
        transform: scale(0);
      }

      .default-check {
        transform: scale(1);
        color: $os-link;
      }
    }
  }
}

.space-accept-form {
  padding: 80px 0;
}

.space-accept-welcome-text {
  margin-top: -240px;
}
