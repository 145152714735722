.clinic-space {
  position: relative;
}
.clinic-space-id{
  position:absolute;
  left: 24px;
  top: 16px;
  @include fontstyle(12px, normal, 16px);
  color: #e7e7ea;
  display: flex;
  align-items:center;
}

.clinic-space-card-body{
  display: flex;
  flex-wrap: wrap;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  color: #e7e7ea;
}

.dr-name-with-degree {
  color: $level-11;
  @include fontstyle(12px, normal, 16px);
}

.clinic-space-title {
  @include fontstyle(16px, normal, 24px);
  flex-wrap: wrap;
  @include text-truncate();
  overflow: hidden;
  max-width: calc(100% - 48px);
}

.clinic-space-image {
  height: 88px;
  overflow: hidden;
  position: relative;
  border-radius: 8px 8px 0 0;

  .img-parent {
    @include flex_center();
    @extend %img-parent;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay-span {
    @extend %overlay-span;
  }
}

.clinic-space-logo-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  @include flex_center();

  img {
    max-width: 100%;
  }

  &:hover {
    text-decoration: none !important;
  }
}

.os-lg-clinic {
  @extend %os-lg-card;
  @extend %os-card-base;
  display: flex;
  flex-direction: column;

  .clinic-space-card-body{
    padding-left: $card-gutter;
    padding-right: $card-gutter;
  }

  .clinic-space-image {
    height: 200px;
  }

  .clinic-space-title {
    @include multiline-truncate(3);
  }
  .overlay-span {
    @extend %overlay-span;
  }

  &.without-image {
    .clinic-space-image {
      background: #29292e;
      img {
        max-height: 80px;
      }
    }
  }

  &.clinic-304 {
    @extend %os-304;

    .clinic-space-id {
      left: $card-gutter-16;
      right: $card-gutter-16;
    }

    .clinic-space-card-body{
      padding-left: $card-gutter-16;
      padding-right: $card-gutter-16;
    }

  }
}

.os-sm-clinic {
  @extend %os-sm-card;
  @extend %os-card-base;

  .clinic-space-image {
    height: 88px;
  }

  .clinic-space-id{
    left: 16px;
    top: 12px;
    right: 16px;
  }

  &.without-image {
    img {
      max-height: 80px;
    }

    .overlay-span {
      @extend %overlay-span;
    }
  }

  .clinic-space-card-body{
    padding: 16px;
    align-items: center;

    .clinic-space-title {
      @include multiline-truncate(1);
    }
  }
}

.extra-small-min-card {
  .os-sm-clinic {
    @extend %os-extra-sm-card;
    padding: 16px 12px 16px 88px;

    .clinic-space-logo-img {
      width: 64px;
      min-width: 64px;
      max-width: 64px;
      position: absolute;
      left: 12px;
      top: 16px;
      height: 64px;
      max-height: 64px;
      margin-right: 0;

      .avatar {
        @include avatar(64px);
      }
    }

    .clinic-space-id,
    .clinic-space-image {
      display: none;
    }


    .clinic-space-title {
      @include multiline-truncate(2);
      max-width: 100%;
    }

    .clinic-space-card-body {
      padding: 0;
    }
  }
}
