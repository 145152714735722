.status-widget{
    .status-widget-title{
        padding-left: 8px;
    }

    .widget-body{
        width: 100%;
        max-width: 100%;
        border-radius: 16px;
        padding: 4px 16px 4px 16px;
        height: 100%;
        max-height: 36px;
        .header-dropdown-menu{
            width: inherit;
            position: relative;
            .dropdown-info-title{
                .page-heading-title{
                    padding: 0;
                }
            }
            .header-dropdown-list{
                width: 105%;
                left: -5px;
                top: 45px;
                
                overflow: unset;
                max-height:none;
                .header-dropdown-list-item  {
                    padding: 0;
                    max-height: 500px;
                    overflow: scroll;
                    position: unset;
                    .header-dropdown-list-item{
                        .sub-labels{
                            background: #fff;
                            border-radius: 8px;
                            box-shadow: $block-shadow;
                            min-width: 150px;
                            max-height: 200px;
                            overflow-y: auto;
                    
                            .sub-label{
                                
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 0 16px;
                                &:hover{
                                background: #edeff3;
                                }
                                cursor: pointer;
                            }
                            }
                        .label-list-item {
                            padding: 0 16px !important;

                            &:first-child{
                                border-radius: 0;
                            }
                        }
                    }
                }
                .label-list-item{
                    white-space: break-spaces;
                }
            }
        }
    }
}