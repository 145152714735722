.layout-normal {
  background-color: #F4F7F9;
  cursor: text;
  padding: 0 5px;
  position: relative;
  border-radius: 8px;
  border: 1px solid transparent;

  .referenceContainer {
    display: none;
  }
}

.active-input {
  border-color: #4a749a;
  background-color: #F4F6F9;
}

.editor-layout {
  min-height: 100px;
}

.menu-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  .is-active {
    background-color: #e3e3e3 !important;
  }
}
//
// .menu-bar>button {
//   background-color: white;
//   margin: 5px 5px;
//   border-radius: 4px;
//   border: 1px solid #eff1f4;
//   padding: 3px 6px;
//
//   img {
//     height: 16px !important;
//   }
// }

.menu-bar>button {
  display: flex;
  align-items: center !important;
  background-color: white;
  margin: 5px 2px;
  border-radius: 4px;
  border: 1px solid #eff1f4;
  min-height: 24px;
  height: 24px !important;
  min-width: 24px;

  img {
    height: 10px !important;
    width: 9px !important;
  }
}

.reply-editor {
  position: relative;
  min-height: 38px;
  display: flex;
  align-items: center;
  padding: 4px 0;

  p {
    margin-bottom: 5px;
  }

  hr {
    margin: 4px 0;
  }

  margin-right: 128px;
  @media(max-width: 500px){
    margin-right: 0;
  }
}

.media {
  display: block !important;
}

.editor-style {
  p {
    margin: 2px 0;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: normal;
  }

  a {
    text-decoration: underline;
    color: #4a749a;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
  }

  strong {
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: normal;
    font-style: normal;
    font-size: 16px;
  }

  ol {
    margin: 2px 0;
    padding: 0 0 0 16px;
  }

  span[data-type="mention"] {
    background: #e3e3e3;
    border-radius: 4px;
    padding: 4px;
  }

  span[data-hashtag="hashtag"] {
    background: #e1e1e1;
    border-radius: 4px;
    padding: 4px;
  }

  h1 {
    font-family: Inter, serif;
    font-size: 20px;
    width: 100% !important;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1.33;
  }

  h2 {
    text-transform: uppercase;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
  }
}

.editor {
  min-height: 100px;
  cursor: text !important;

  .ProseMirror{
    min-height: 100px !important;
    padding: 8px;
  }
}

.menu-bar {
  .is-active {
    background-color: #e3e3e3;
  }
}

#emoji-popover {
  min-width: fit-content !important;
  .popover-body {
    width: fit-content !important;
  }
}

.menu-bar {
  .emoji-picker-btn-container {
    border-style: none;
    button {
      border: none;
      margin: 5px 2px;
      background: white;
      border-radius: 4px;
      min-width: 24px;
      display: flex;
      height: 24px !important;
      align-items: center;
      color: #B9C4CF;
      padding: 0;
      justify-content: center;

      i:before {
        zoom: 0.8;
      }
    }
  }
}

.ProseMirror {
  >*+* {
    margin-top: 0.75em;
  }
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.reply-container-btn {
  bottom: 0 !important;

}

// Mention and hashtag styles
.editor-items {
  box-sizing: content-box;
  min-height: 0;
  max-height: 160px;
  width: fit-content;
  overflow-y: auto;
  text-align: justify;
  box-shadow: $dropdown-shadow;
  overflow-y: auto;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 8px;


  .item {
    min-height: 32px;
    padding: 0 10px;
    cursor: pointer;
    .custom-menu-option {
      color: var(--neutrals-level-9, #5D7893);
      display: flex;
      align-items: center;
      gap: 8px;

      .header-list-icon {
        zoom: 1.2;
      }

    }

    &:hover {
      span {
        color: var(--neutrals-level-11, #354554);
        ;
      }
    }
  }

  .item:hover {
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .is-selected {
    background-color: #f5f5f5;
  }

  .avatar-suggestion {}
}

@media screen and (min-width: 1920px) {
  .editor-style {
    p {
      font-size: 20px;
      line-height: 1.5;
    }

    a {
      font-size: 20px;
      line-height: 1.5;
    }

    strong {
      font-size: 20px;
      line-height: 1.5;
    }

    h1 {
      font-size: 24px;
      line-height: 1.4;
    }

    h2 {
      font-size: 20px;
      line-height: 1.5;
    }
  }
}

.layout-normal {
  .message-secure-container {
    width: fit-content;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 8px 8px 0;
    gap: 4px;
    &:hover {
      cursor: pointer;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      line-height: 16px;
      font-family: Inter;
      color: #5D7893;
    }

    .ifill-os-locked, .ifill-os-views {
      color: #5D7893;
    }
  }
}

#editor-box+div {
  .tippy-box {
    background-color: white;
    color: #000;
    padding: 0;
    font-size: 14px !important;
    border-radius: 8px;
  }

  .tippy-content {
    padding: 0;
    z-index: 1
  }

  .tippy-arrow {
    display: none;
  }
}

.tiptap-editor {
  .reply-buttons {
    bottom: 0 !important;
  }
}

.tiptap-editor-container, .tiptap-editor-patient-space-wrapper   {
  z-index: 12;

  &:has(.os__menu) {
    position: relative;
    z-index: 0;

    .tiptap-editor {
      z-index: -2;
      position: relative;
    }
    .cta-wrap {
      z-index: -1;
      position: relative;
    }
  }
}

.tiptap-editor-container, .tiptap-editor-patient-space-wrapper  {
  &:has(.dropdown-menu) {
    position: relative;
    z-index: 0;

    .tiptap-editor {
      z-index: -2;
      position: relative;
    }
    .cta-wrap {
      z-index: -1;
      position: relative;
    }
  }
}

.editor-template-selector {

  .template-option {
    width: 100% !important;
    min-width: 100% !important;
  }

  .os__control {
    background-color: #F4F7F9 !important;

    .os__placeholder {
      color: #7E91A5;
    }
    .os__input-container {
      color: black;
    }
    .os__single-value {
      max-width: 85% !important;
    }
    z-index: 999 !important;
  }
  .os__menu {
    left: 0;
    border-radius: 8px !important;
    max-height: 170px !important;

    .os__menu-list {
      z-index: 1000 !important;
      max-height: 170px !important;
      border-radius: 8px !important;
    }
    .os__option {
      display: flex;
      align-items: center;
      color: #4A749A !important;
      background-color: white !important;
      min-height: 32px !important;
      z-index: 11 !important;

      &>div {
        display: flex;
        align-items: center;
        position: relative !important;
        font-size: 12px;
        line-height: 16px;
        padding: 8px 8px 8px 16px;
        text-align: center;
        overflow: visible;
        color: rgb(126, 145, 165) !important;;
      }

      .template-info {
        display: none;
        position: absolute;
        top: 50px;
        border: 2px solid red;
        left: -240px;
        overflow: clip !important;
        z-index: 1000 !important;
      }



      &:hover {
        .template-info {
          display: block ;
        }
      }
    }
  }
}



.tiptap-editor-patient-space-wrapper{
  z-index: 12;
  margin-top:16px;
  box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
  border-radius: 8px;

  &.hide-box-shadow{
    box-shadow: none;
  }

  .btn-container{
    max-width: 168px;
    position: absolute;
    top: -81px;
    right: 0;
    padding: 16px 0px 0 8px;
    box-shadow: none;
    background-color: transparent;
    border-radius: none;
    display: flex;
    justify-content: flex-end;
    gap:8px;
    align-items: center;

    @media (max-width: 950px) {
      flex-direction: row;
    }

    @media (max-width : 1440px){
      top: -64px;
    }
    div{
      span{
        width: 48px !important;
        height: 40px !important; 
        border: 1px solid #C9D1DA !important;
      }
    }
  }

  .media-comment-head{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px 48px 24px 32px;
    margin-bottom: 0px;
    
    .feed-author-info{
      display: block;
      opacity: 1;
      transition: none;
      will-change: unset;
    }
  }
  
  .tiptap-editor{
    .btn-group-left{
      position: unset !important;
    }

    .btn-group-right{
      .time-pill{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
        gap: 4px;
        height: 32px;
        background: #EDEFF3;
        border-radius: 16px;
        cursor: pointer;
        margin-right: 16px;

        span{
          height: 16px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #354554;
          mix-blend-mode: normal;
        }
      }

      .scheduler-btn{
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        color:#fff;
        background: #354554;
        cursor: pointer;

        &:hover{
          background:#4A749A;
        }
      }

      .dropdown-active{
        background:#4A749A;
      }   
    }
  }

  &:has(.os__menu) {
    position: relative;
    z-index: 0;

    .tiptap-editor {
      z-index: -2;
      position: relative;
    }
    .cta-wrap {
      z-index: -1;
      position: relative;
    }
  }
}