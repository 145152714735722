.policies-modal {
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 8% 0;

  .modal-content {
    padding: 24px;
    width: 100%;
    max-height: 100%;
    min-height: 400px;
  }

  .modal-header {
    padding: 0 0 35px;
  }

  .content-update-area {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .modal-body {
    padding: 0 15px 0 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    line-height: 1.5;
    height: calc(100% - 117px);
    overflow: hidden;
    overflow-y: auto;
  }

  .ifill-os-close {
    font-size: 1rem;
    outline: 0;
    border: 0;
    padding: 0;
    background: none;
    color: #fff;
  }


  .btn-policy-accept {
    width: 120px;
    height: 32px;
    border-radius: 17px;
    line-height: 32px;
    background: #48acfd;
    color: #fff;
    font-size: 12px;
    padding: 0;
    text-transform: uppercase;
    border: 0;
    opacity: 1;

    &.disabled,
    &:disabled {
      color: $policy-btn-disable-text;
      background: #fff;
    }
  }
}

%policy-label-design {
  .modal-policy-label {
    margin: 0;
    position: relative;
    padding-left: 24px;
    cursor: pointer;
  }

  .ifill-os-checkmark-default-white,
  .ifill-os-checkmark-checked {
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    font-size: 16px;
    opacity: 0;
    color: #8c8c8c;
  }

  .ifill-os-checkmark-default-white {
    opacity: 1;
  }

  .policy-checkbox {
    display: none;

    &:checked ~ {
      .ifill-os-checkmark-default-white {
        opacity: 0;
      }

      .ifill-os-checkmark-checked {
        opacity: 1;
      }
    }
  }
}

.policy-label-check {
  @extend %policy-label-design;
}

.modal-policy-footer {
  padding-top: 24px;
  font-size: 14px;

  @extend %policy-label-design;
}

.modal-policies-tabs-list {
  display: flex;
  margin: 0 0 30px;
  width: calc(100% - 22px);
  list-style: none;
  padding: 0;

  .pl-tab-item {
    padding: 0 10px 0 0;
    margin: 0 25px 0 0;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      width: 0%;
      left: 50%;
      height: 3px;
      bottom: -2px;
      background: #fff;
      opacity: 0;
      position: absolute;
      transition: all .3s;
    }

    &.active-item {
      font-weight: 600;
      
      &::after {
        width: 100%;
        left: 0;
        opacity: 1;
      }
    }
  }

  .pl-tab-link {
    font-size: 14px;
    color: inherit;
    line-height: 22px;
    text-transform: uppercase;
  }
}

.faq-modal {
  .faq-title {
    text-decoration: underline;
  }

  p {
    text-align: justify;
  }
}

body.policy-not-accepted-view {
  padding-right : 0 !important;

  &.conversations {
    .modal-policies-tabs-list  {
      display: none;
    }

    .header-tab-combo {
      .modal-header {
        padding-bottom: 16px;

        .rel-pos, .top-right-0 {
          right: -12px;
        }
      }
    }
  }

  &.without-blur {
    #root {
      filter: none;
    }

    .modal {
      top: 70px;
      height: auto;
      bottom: 0;
      overflow: hidden !important;
    }

    .modal-backdrop {
      display: none;
      visibility: hidden;
    }
  }
}

.iphone,
.mobile-device {
  .policies-modal {
    padding: 0;

    .modal-content {
      min-height: unset;
    }
  }
}

@media (min-width: 768px) {
  .policies-modal {
    .modal-content {
      width: 648px;
    }
  }

  .modal-policies-tabs-list {
    .pl-tab-link {
      font-size: 1rem;
    }
  }
}

.no-touch-support {
  .ifill-os-checkmark-default-white,
  .ifill-os-checkmark-checked {
    &:hover {
      color: $os-link;
    }
  }
}
