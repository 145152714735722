/*-----SVG ICONS PAGE--*/

.icon-svg-tables {
  justify-content: center;
  margin-top: 24px;

  .nav-link {
    font-size: 20px;
    &.active {
      font-weight: 600;
    }
  }
}

.svg-table {
  margin-bottom: 50px;
  svg {
    min-height: 100px;
    min-width: 100px;
    max-height: 150px;
    max-width: 150px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20px;
  }

  tr {
    width: 25%;
    display: inline-table;
    border: 2px solid #1c1c1f;
    vertical-align: top;

    label {
      display: block;
      padding: 15px 0;
      font-weight: bold;
    }
  }

  .td-svg-name {
    width: 100%;
    text-align: center;
    height: 250px;
    position: relative;
  }
}

.icon-name-table {
  margin-bottom: 50px;

  tr {
    width: 25%;
    display: inline-table;
    border: 4px solid #fff;
    vertical-align: top;

    @media screen and (min-width: 1024px) and (max-width: 1366px) {
      width: 33.33%;
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 50%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    td{
      display: block;
      padding: 0 12px 0 0;
      font-weight: bold;
    }

    label {
      padding-left: 10px;
      margin-bottom: 0;
      line-height: 48px;
    }

    i {
      font-size: 24px;
      width: 50px;
      padding: 12px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
  }
}
