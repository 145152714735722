.course-complete-bar {
  margin-bottom: 10px;
  width: 100%;

  @media(min-width: 1024px) {
    margin: 0;
  }

  .complete-text-info {
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  
  .course-bar {
    height: 4px;
    border-radius: 2px;
    position: relative;
    background: $block;
    overflow: hidden;
  }

  .complete-blue-bar {
    position: absolute;
    height: 100%;
    border-radius: 2px;
    left: 0;
    top: 0;
    background: $os-link-hover;
  }
}

.ipad {
  &.portrait {
    .header-with-clv {
      .space-sub-header {
        margin-bottom: 0;
      }
    }

    .space-header-right {
      min-width: 280px;
      width: 280px;
    }

    .credi-btn-col {
      position: unset;
      padding: 0 1.5rem;
    }

    .credi-btn-col {
      margin-top: 10px;
    }
  }

  .clv-attachement-title {
    padding: 0.5rem 1.5rem 0;
  }
}
