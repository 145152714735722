.space-type-list {
  padding: 0;
  margin: 0 0 20px 0;

  &.field-with-error {
    border: 1px solid $lt-red !important;
  }

  dt {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 300;
  }

  dd {
    margin: 0 0 0;
    padding: 16px 0 0 32px;
    position: relative;
  }

  .list-type-label {
    line-height: 1;
    position: relative;
    cursor: pointer;
    margin: 0;

    .default-check {
      opacity: 0;
    }

    .default-uncheck {
      opacity: 1;
    }

    .icon-helper {
      font-size: 16px;
      color: #5c5c66;
      position: absolute;
      left: -32px;
      top: 0;
      transition: all 300ms ease;
    }

    input {
      display: none;

      &:checked ~ {
        .default-check {
          color: $os-link;
          opacity: 1;
        }

        .default-uncheck {
          opacity: 0;
        }
      }
    }
  }

  .type-info-text {
    font-size: 12px;
    line-height: normal;
    margin-top: 4px;
  }

  .os-title {
    @extend %heading-3-semibold;
  }
}

@media(min-width: 1280px) {
  .space-type-list {
    dd {
      padding-left: 32px;
    }

    .list-type-label {
      .icon-helper {
        left: -32px;
      }
    }
  }
}


@media(max-width: 767px) {
  .space-type-list {
    display: block;
    padding-right: 0;

    dt {
      padding-left: 0;
      margin-bottom: 0;
    }

    dd {
      padding: 32px 0 8px 32px;
      width: 100%;
    }

    .collaboration-space {
      order: unset;
    }
  }
}
