.folders {
  .os-toggle-category.full-width {
    padding-bottom: 24px;
  }

  #root {
    z-index: 3;
  }
}

.ipad .folders {
    @media(max-width: 800px) {
      .folder-author-info {
        .rename-form {
          padding-right: 48px;
        }
      }

      .space-header-right {
        .cs-right-dropdown {
          right: -10px;
          top: 4px;
        }
      }
    }
  }

.mobile-device {
  .folders {
    .header-title-row {
      padding-top: 0 !important;
    }

    .non-clv-header{
      .cs-right-dropdown {
        top: 8px;
      }
    }

    .space-links-container.list {
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 16px;

      .cards {
        padding: 0 0 5px;
      }
    }

    .os-toggle-category {
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 12px;
    }

    .tab-page-header {
      display: flex;
      align-items: flex-start;
      background-color: $level-1;
      padding: 4px 0;
      position: sticky;
      top: 0;
      z-index: 9;

      .back-btn {
        position: absolute;
        left: 16px;
      }

      .folder-space-name {
        min-width: 100%;
        text-align: center;
        @include fontstyle(16px, 600, 24px);
      }

      .seleted-tab-name {
        @include fontstyle(10px, 600, 16px);
        letter-spacing: 0.2px;
        color: $level-8;
        text-transform: uppercase;
      }
    }
  }

  &.mobile-space-view {
    .folders {
      .workspace-page-head {
        .page-heading-right:empty {
          margin-top: 0;
        }
      }

      .head-sticky {
        top:  48px;
        margin-bottom: 0;
      }

      .mobile-nav-bar, .page-header-dropdown-menu {
        display: none !important;
      }

      .detail-board {
        padding-top: 0;
      }

      .folder-views-area {
        margin-top: 12px;
        margin-bottom: 0;
      }

      .workspace-page-head {
        margin-bottom: 0;
      }
    }
  }
}
