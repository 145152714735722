%head-text {
  color: $heading;
}
.head-1{
  @include font-text(300, 24px, 1);
  @extend %head-text;
}

.head-2{
  @include font-text(600, 20px, 1.4);
  @extend %head-text;
}

.head-3 {
  @include font-text($font-weight-normal, 16px, 1);
  @extend %head-text;
}

.head-info {
  @include font-text($font-weight-normal, 16px, 1);
  color: $lt-orange;
}

.head-4 {
  @include font-text($font-weight-bold, 16px, 1);
  @extend %head-text;
}

.head-5 {
  @include font-text($font-weight-normal, 12px, 1.33);
  @extend %head-text;
}


.line-16 {
  line-height: 16px;
}

.line-24 {
  line-height: 24px;
}

.opacity-6 {
  opacity: 0.6;
}

.font-italic {
  font-style: italic;
}

.all-caps {
  text-transform: uppercase;
}

.body-text {
  @include font-text($font-weight-normal, 16px, 24px);
  @extend %head-text;
}

.os-blue-text {
  @include font-text($font-weight-bold, 16px, 24px);
  color: $os-link;
}

.os-quote {
  @include font-text($font-weight-bold, 16px, 24px);
  color: $level-11;
  font-style: italic;
}

.os-caption {
  @include font-text($font-weight-normal, 12px, 16px);
  color: $level-11;

  &.cap-blue {
    color: $os-link;
  }
}

.os-error {
  @include font-text($font-weight-normal, 12px, 16px);
  color: $lt-red;
}

.os-semi-caption {
  @include font-text($font-weight-normal, 8px, 12px);
  @extend %head-text;
}

.os-font-24 {
  font-size: 24px;
  line-height: 32px;
}

.os-font-20 {
  font-size: 20px;
  line-height: 28px;
}

.os-font-16 {
  font-size: 16px;
  line-height: 24px;
}

.os-font-12 {
  font-size: 12px;
  line-height: 16px;
}

.os-font-8 {
  font-size: 8px;
  line-height: 12px;
}

.action-link,
.action-link-info {
  color: $lt-orange;
}

