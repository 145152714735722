.testimonial_sidebar {
  margin-bottom: 24px;
  .testimonial_sidebar-title {
    @include fontstyle(20px, 600, 28px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    color:  $level-11;
  }

  .testimonial_sidebar-body {
    margin: 0 ;
    padding: 24px;
    border-radius: 4px;
    background-color: $block;
    height: 100%;
  }

  .user-name-testimonial {
    display: block;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 24px;
    @include fontstyle(16px, 600, 16px);
    color: $level-10;
  }

  .testimonial_slider_text {
    color: $level-11;
    line-height: 24px;
  }

  .avatar {
    display: block;

    &.avatar-24 {
      .icon-span {
        @include icon-span(10px);
        bottom: -3px;
        right: -2px;
      }
    }
  }

  .slider-controll-buttons {
    display: inline-flex;
    margin-bottom: 0;

    .btn-control {
      width: 40px;
      height: 40px;
      border: 1px solid $level-10;
      border-radius: 50%;
      color: $level-10;
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;

    .slick-slide {
      height:auto;

      > div {
        height: 100%;
      }
    }
  }
}

.desktop-small {
  .testimonial_sidebar {
    margin-bottom: 40px;
  }
  .testimonial_sidebar-body {
    margin-right: 24px;
    max-width:calc(100% - 24px);
  }
}


.ipad {
  &.portrait {
    .testimonial_sidebar {
      margin-bottom: 80px;
    }
    .testimonial_sidebar-body {
      margin-right: 24px;
      max-width:calc(100% - 24px);
      padding: 24px 16px;
    }

    .testimonial_sidebar {
      .slick-slider {
        padding-right: 40px;
      }
      .slick-list{
        overflow:visible;
      }
    }

    .testimonial_slider_text {
      @include multiline_truncate(7);
      overflow: hidden;
    }
  }

  &.landscape {
    .testimonial_sidebar-body {
      margin-right: 0;
      max-width: 100%;
    }
  }
}

.mobile-device {
  .testimonial_sidebar {
    margin-top: 32px;
    overflow: hidden;

    .slick-slider {
      padding-right: 40px;
      padding-left: 12px;
    }

    .slick-list{
      overflow:visible;
    }

    .testimonial_sidebar-body {
      margin-right: 16px;
      max-width:calc(100% - 16px);
      background-color: $block;
    }

    .testimonial_sidebar-title {
      @include fontstyle(20px, 600, 28px);
      padding-left: 12px;
    }

    .user-name-testimonial {
      margin-top: 18px;
      margin-bottom: 16px;
    }

    &.single-slide {
      .slick-slider {
        padding-right: 0;
      }

      .testimonial_sidebar-body {
        margin-right: 0;
        max-width: 100%;
      }
    }
  }
}

