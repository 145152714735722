.create-slider {
  position: relative;

  &[data-hover="true"] {
    &:hover {
      .slide-img-holder:before,
      .video-player:before {
        z-index: -1;
        opacity: 0;
      }
    }

    .video-player,
    .slide-img-holder {
      &:before {
        background-image: linear-gradient(to bottom, rgba(18, 18, 20, 0), rgba(34, 34, 38, 0.2) 96%);
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        opacity: 0.8;
        z-index: 1;
        border-radius: 4px;
        transition: all 500ms ease;
        cursor: pointer;
      }
    }
  }

  .slide-video-holder {
    min-width: 100%;
  }

  video {
    width: 100%;
  }

  .pg-viewer-wrapper{
    color: #1c1c1f;

    .loading {
      position: absolute;
      top: calc(50% - 48px);
      left: calc(50% - 48px);
    }
  }

  .spreadsheet-viewer, .pg-viewer-wrapper {
    height: 100%;

    .react-grid-Container {
      height: calc(100% - 40px);
    }

    .react-grid-Main,
    .react-grid-Grid {
      height: 100%;
    }
  }


  &:not(.full-ss-active) {
    .slick-slider,
    .slick-slide,
    .case-video {
      height: 288px;
    }

    .slick-slide {
      cursor: pointer;
    }

    .slide-img-holder {
      width: auto !important;
    }
  }

  .slick-slider {
    width: 100%;

    &.chrome-pip-dropdown-fix-slider {
      .slick-track,
      .slick-list {
        transform: none;
      }
    }

    .case-video  {
      padding: 0;

      &.with-audio {
        position: relative;
      }
    }

    .attachments-content-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
      width: 100%;
    }

    .conversation-attachment-icon-holder {
      position: relative;
      text-align: center;
    }

    .conversation-attachement-icon {
      background: transparent url('./../images/icon-doc-exn.png') 0 0 no-repeat;
      width: 38px;
      height: 48px;
      display: inline-block;
      background-size: 38px auto;
    }

    .conversation-attachment-ext-name {
      width: auto;
      max-width: 100px;
      height: 19px;
      border: solid 2px #DDD;
      padding: 0 7px 0 3px;
      overflow: hidden;
      position: absolute;
      top: 22px;
      left: 50%;
      color: #DDD;
      @include fontstyle(12px, $font-weight-bold, 13px);
      text-align: left;
      background: $block;
      border-radius: 4px;
      white-space: nowrap;
      text-transform: lowercase;
    }

    &.thumb-slider {
      height: 56px;
      padding-right: 50px;
      padding-left: 50px;
      margin: 16px auto;

      .attachments-content-holder {
        margin-left: -8px;
      }

      .slick-list:first-child {
        .slick-track {
          justify-content: center;
        }
      }

      .slick-track {
        display: flex;
        flex-wrap: nowrap;
      }

      .slick-slide {
        cursor: pointer;
        height: 56px;
        border: 1px solid transparent;
        border-radius: 4px;
        position: relative;
        max-width: 80px;
        overflow: hidden;
        min-width: 80px;

        &:after {
          background: #000000;
          background: -webkit-linear-gradient(top, #000000, #00000000);
          background: -moz-linear-gradient(top, #000000, #00000000);
          background: linear-gradient(to bottom, #000000, #00000000);
          border-radius: 4px;
        }


        > div {
          width: 100%;
        }

        img{
          height: 100%;
          width: 100% !important;
          object-fit: cover;
          border-radius: 4px;
        }

        &.slick-current {
          border-color: $os-link;

          &:after {
            content: none;
          }
        }
      }
    }
  }

  .slick-slide {
    text-align: center;

    > div {
      height: inherit;
    }

    .slide-img-holder {
      position: relative;
      height: inherit;
      display: inline-flex !important;
      margin: 0 auto;
      max-width: 100%;
      align-items: center;
    }

    img {
      margin: 0 auto;
      display: block !important;
      max-height: 100%;
      width: auto !important;
      max-width: 100%;
    }

    video,
    img {
      border-radius: 8px;
    }
  }

  .pulse-slick-dots {
    top: auto;
    bottom: 28px;
    position: absolute;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);

    li {
      margin: 0;
      display: inline-block;
      cursor: pointer;
      padding: 0;
      position: relative;

      button {
        width: 16px;
        height: 16px;
        border: transparent;
        padding: 3px;
        display: block;
        outline: none;
        color: transparent;
        font-size: 0;
        line-height: 0;
        background: transparent;
        cursor: pointer;
        border-radius: 50%;

        &:before {
          position: relative;
          top: 0;
          left: 0;
          content: ' ';
          width: 8px;
          height: 8px;
          background-color: $level-11;
          opacity: 1;
          display: block;
          border-radius: 50%;
        }
      }

      &.slick-active {
        button {
          border: 1px solid $cl-link;

          &:before {
            background-color: $os-link;
          }
        }
      }
    }
  }

  .slick-arrow {
    width: 24px;
    height: 24px;
    background-color: rgba(18, 18, 20, 0.2);
    border-radius: 4px;
    font-family: 'fill-os-icon';
    z-index: 1;
    cursor: pointer;
  }

  .slick-prev {
    left: 16px;

    &:before {
      content: "\e904";
    }
  }

  .slick-next {
    right: 16px;

    &:before {
      content: "\e905";
    }
  }

  .slick-next, .slick-prev {
    &:before {
      font-family: 'fill-os-icon';
      font-size: 16px;
    }

    &:hover {
      background-color: rgba(48, 103, 150, 0.36);
      color: $os-link;

      &:before {
        color: $os-link;
      }
    }
  }
}

html {
  &:not(.mobile-device) {
    .modal-attachment {
      .modal-body {
        .create-slider {
          .main-slider {
            height: calc(100vh -  160px);
          }

          .slide-video-holder {
            height: inherit;
          }
        }
      }
    }
  }
}

.video-player-overlay {
  display: none;
}

.video-player-overlay {
  display: none;
}

.modal-attachment {
  .modal-body {
    max-width: calc(100% - 350px);

    .create-slider {
      height: 100%;
      overflow: hidden;

      .video-player-overlay:not(.hidden) {
        display: block;
        pointer-events: none;

        .svg-loader {
          height: 75px;
          width: 75px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin-top: -3px;
        }

        + .video-player {
            opacity: 0;
          }
      }

      .process-text {
        font-size: 20px;
        color: $level-11;
      }

      .slick-track {
        height: 100%;
      }

      .main-slider {
        .case-video {
          height: inherit;
          padding-bottom: 0;
        }

        .video-player,
        .slide-img-holder {
          &::before {
            display: none;
          }
        }

        .slick-slide {
          .case-video {
            width: 100%;
          }

          &:after {
            content: none;
          }
        }

        .slick-list {
          height: 100%;
        }

        .slick-slide {
          height: 100%;
          text-align: inherit;

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        img {
          height: auto;
          max-height: 100%;
        }
      }

      &.single-slider {
        .main-slider {
          height: 100% !important;
        }
      }

      .slide-img-holder {
        position: relative;

        .imgloader {
          width: 48px;
          height: 48px;
        }
      }

      .slide-video-holder {
        height: inherit;
      }
    }
  }
}

.case-video {
  &.with-audio {
    min-height: 32px;
    height: auto !important;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}

.pdf-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ipad {

  .explore
  .posts {
    &.modal-open {
      position: fixed;
      overflow: hidden;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .modal-attachment {
    .modal-body {
      max-width: calc(100% - 220px);
      margin: 0;
    }

    .attachments-content-sidebar {
      width: 220px;
    }
  }


  .fullscreen-button {
    bottom: -20px;
  }

  .thumb-slider + .fullscreen-button {
    bottom: 62px;
  }

  &.landscape {
    .thumb-slider + .fullscreen-button {
      bottom: 55px;
    }
  }

  .slick-slide .video-player {
    height: 100% !important;
  }

  .modal-xl.modal-attachment {
    position: fixed;
    height: unset;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;

    .modal-content {
      overflow: hidden;
    }

    .create-slider {
      .main-slider {
        max-height: calc(100% -  100px);

        .slick-list {
          height: 100%;
        }

        .slick-slide {
          height: 100%;
        }
      }

      .conversation-attachment-icon-holder {
        padding-bottom: 0 !important;
      }
    }
  }

}

.fullscreen-button {
  position: absolute;
  bottom: 0;
  right: 0px;
  color: $level-9 !important;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;

  i {
    margin: auto;
  }

  &:hover {
    background-color: $level-4;
    box-shadow: $block-shadow-xs;
  }
}

.thumb-slider + .fullscreen-button {
  bottom: 70px;
}

.mobile-device {
  &.portrait {
    .fullscreen-button {
      right: 20px;
    }
  }
}

.modal-attachment {
  .fullscreen-button {
    display: none;
  }
}

.modal-attachment {
  .main-slider {
    .conversation-attachment-icon-holder {
      .icon {
        font-size: 34px;
        color: #fff;
        margin-left: 12px;
        display: inline-block;
        line-height: 53px;
      }
    }

    .pg-viewer-wrapper {
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
    }

    .pdf-canvas, canvas {
     width: 100%;
     height: auto;
    }
  }
  .thumb-slider {
    .attachments-content-holder {
      .conversation-attachment-icon-holder {
        max-width: 100%;
        width: 100% !important;
        padding-bottom:0;

        .conversation-attachement-icon {
          width: 38px;
          height: 48px;
          display: inline-block;
          background-size: 38px auto;
        }

        .conversation-attachment-ext-name {
          min-width: auto;
          width: auto;
          max-width: 100px;
          height: 20px;
          padding: 0 5px;
          top: 22px;
          left: 35px;
          color: #DDD;
          @include fontstyle(13px, $font-weight-bold, 15px);
          white-space: nowrap;
          border-width: 2px;

        }

        .icon {
          font-size: 34px;
          color: #fff;
          margin-left: 12px;
          display: inline-block;
          line-height: 53px;
        }
      }
    }
  }
}


.mobile-device {
  .fullscreen-button {
    bottom: -12px;
  }

  .thumb-slider + .fullscreen-button {
    bottom: 54px;
  }

  .create-slider {
    .thumb-slider {
      .slick-track {
        justify-content: center !important;
      }
    }
  }

  .modal-attachment {
    .modal-body {
      max-width: 100%;
    }

    .create-slider {
      margin: 0;
      width: 100%;
    }

    .attachment-sidebar-btns {
      height: 30px !important;
      margin-top: 0px !important;
    }

    .main-slider {
      .slick-slide:not(.slick-current) {
        padding-left: 1px;
        padding-right: 1px;
      }
    }
  }

  .fullscreen-button {
    right: 24px;
  }

  .create-slider {
    &:not(.full-ss-active) {
      .main-slider {
        .case-video {
          padding: 0;
          height: 233px;
        }

        &.slick-slider {
          height: 233px;

          .slick-slide {
            height: 233px;
          }
        }
      }
    }

    &.full-ss-active {
      .pdf-viewer-container,
      .pdf-viewer,
      .pdf-canvas {
        height: 100%;
      }

      .pdf-canvas {
        display: flex;
        align-items: flex-start;

        canvas {
          margin: auto;
        }
      }

      .sheet-names {
        input[type="button"] {
          font-size: 13px;
        }
      }
    }
  }

  .create-slider {
    position: relative;

    .main-slider {
      .case-video {
        padding: 0;
      }

      &.slick-slider {
        .slick-slide {
          max-width: 100%;
          overflow: hidden;

          .video-player {
            height: 100% !important;
          }

          &:after {
            content: none;
          }
        }
      }
    }
  }

  .modal-attachment {
    .create-slider {
      height: calc(100% - 35px);
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .main-slider {
        max-height: 100%;
        overflow: hidden;
        flex: 1;

        .slick-track {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
        }

        .slick-slide {
          height: 100%;

          > div {
            min-height: 233px;
            width: 100%;
          }

          .pg-viewer-wrapper,
          .pg-viewer {
            min-height: inherit;
          }
        }
      }

      .thumb-slider {
        height: 40px;
        padding-left: 25px;
        padding-right: 25px;

        .slick-slide {
          max-width: 50px;
          min-width: 50px;
          height: 40px;
        }

        .attachments-content-holder{
          .icon {
            line-height: 38px !important;
            font-size: 24px !important;
          }
        }


      }
      .slick-prev {
        left: 0;
      }

      .slick-next {
        right: 0;
      }
    }
  }

  .thumb-slider + .fullscreen-button {
    bottom: 75px;
  }
}

.no-touch-support,
.touch-support:not(.mobile-device),
.create-slider.listing-slider {
  .icon-text-holder {
    height: inherit;

    .attachments-content-holder {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.slide-img-holder {
  position: relative;

  .imgloader {
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('./../images/download.png') center center no-repeat;
    background-size: 100% 100%;
    -webkit-animation: loaderRotating 2s linear infinite;
    animation: loaderRotating 2s linear infinite;
    pointer-events: none;
    margin-left: -15px;
  }

  .attachment-content-img {
    position: relative;
  }
}

@media only screen and (max-width: 767px)  {
  .modal-xl {
    &.modal-attachment {
      .icon-text-holder {
        height: inherit;
      }

      .thumb-slider {
        .attachments-content-holder {
          .conversation-attachment-icon-holder {

            .conversation-attachement-icon {
              width:30px;
              height: 35px;
              background-size: 26px auto;
            }

            .conversation-attachment-ext-name {
              min-width: 30px;
              height: 18px;
              padding: 0px 2px;
              left: 15px;
              top: 13px;
              @include fontstyle(12px, normal, 14px);
            }
          }
        }
      }
    }
  }
}


.no-touch-support {
  .fullscreen-button {
    &:hover {
      background-color: $os-link-hover
$os-link-hover;
      color: $os-link !important;
    }
  }
}

@-webkit-keyframes loaderRotating {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes loaderRotating {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}


.mobile-device {
  .explore {
    .listing-slider {
      .slick-slider {
        max-width: calc(100vw - 24px);
      }
    }
  }
}


.modal-search {
  &.with-audio {
    .case-video {
      &.with-audio {
        position: relative;
      }
    }
  }
}

.lo-emb-vid{
  height: 100%;
  width: 100%;

  iframe{
    height: 100%;
    width: 100%;
  }
}
