.team-space {
  padding-bottom: 0;
  overflow-x: hidden !important;

  .modal-content {
    padding: 26px 24px;
  }
}

.chat-detail {
  padding-bottom: 0 !important;
}

.team-list {
  margin: 0;
  margin-left: 8px;

  li {
    list-style: none;
    margin: 2px 0;

    a {
      padding: 8px;
      display: flex;
      gap: 6px;
      justify-content: flex-start;
      align-items: center;

      .team-avatar {
        div {
          width: 24px;
          height: 24px;
        }
      }
    }

    a:hover {
      background: #edeff3;
      border-radius: 8px;
    }
  }

  li.active {
    a {
      background: #edeff3;
      border-radius: 8px;
    }
  }
}

.team-conversation {
  top: 64px;
  position: sticky;
  width: 100% !important;

  .page-header {
    display: none !important;
  }

  .conversation-input-section {
    padding: 24px !important;
  }

  .conversation-message .conversation-message-attachment-row .conversation-card-container{
    display: flex;
  }
}

.chat-shared-files {
  max-height: 252px !important;
}

@media only screen and (max-width: 767px){
  .team-conversation {
    padding-top: 16px !important;
  }
}

@media screen and (min-width: 1170px ){
  .team-conversation {
    padding-top: 16px !important;
  }
}

.pl-120 {
  .team-conversation {
    margin-left: -16px !important;
    width: calc(100% + 36px) !important;

    .conversation-center-panel {
      padding: 0 !important;
    }
  }
}


.group-space-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 28px;

  @media screen and (min-width: 1920px) {
    padding: 0 16px 0 52px;
  }

  li {
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-top: 0.25rem;
    position: relative;

    .actions {
      cursor: pointer;

      &.active {
        visibility: visible;
      }
    }

    &::after{
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 8px;
    }

    &:hover {
      background: transparent !important;

      &::after{
        background: #edeff3 !important;
        transition: all 0.4s linear;
      }

      .actions {
        visibility: visible;
      }
    }

    &.active {
      background: transparent !important;

      .actions {
        visibility: visible;
      }

      &::after {
        background: #edeff3 !important;
        transition: all 0.4s linear;
      }
    }

    ul > li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 16px;
      height: 40px;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 8px 8px 24px;
      width: calc(100% - 32px);
      flex: 1;
      text-decoration: none;
      flex-wrap: wrap;

      &:hover {
        text-decoration: none;
      }

      .ac-user-name {
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: space-between;
        flex: 1;
        overflow: hidden;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;

        .text {
          display: inline-block;
          align-items: center;
          gap: 4px;
          flex-wrap: wrap;
          color: #5d7893;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status {
          display: flex;
          align-items: center;
          margin-top: 1px;
        }
      }
    }

    .actions {
      visibility: hidden;
      padding: 4px 8px;
      width: fit-content;
    }
  }

  .container-section {
    .container-section__header {
      padding-left: 24px;
    }
  }
}

.space-hero-image {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  object-fit: cover;
  aspect-ratio: 4/3;
  flex-grow: 0;
  border-radius: 2px;
}

.teamWindow, .teamGroupMessenger, .teamDirectMessenger {
  .header-dropdown-menu {
    .space-action {
      font-size: 16px;
      color: #7e91a5;

      &:hover {
        color: #5d7893;
      }
    }
  }

  // Flexi Accordion
  .flexi-ac-wrapper {
    // height: calc(100% - 16px);
    height: 100%;

    .accordion-button {
      border-radius: 0 !important;
      box-shadow: none !important;
      background-color: transparent;
      color: $level-8;
      display: flex;
      align-items: center;

      i {
        color: $level-6;
      }
    }

    .active {
      .accordion-button {
        color: $level-9;
        background-color: $level-4;
        box-shadow: none !important;

        i {
          color: $level-9;
        }

        .lt-count {
          background-color: $level-3;
        }
      }
    }

    .accordion-item {
      background-color: transparent;
      border: 0;
      border-top: 4px solid $level-2;
      min-height: 40px;

      .ac-body {
        overflow-y: auto;
      }

      &.ac-scroll {
        height: calc(100% - 40px);

      }

      .accordion-collapse {
        overflow-y: auto;
        max-height: 100%;
        scrollbar-gutter: stable;
      }

      &:first-child {
        border-top: 0;
      }

      &:first-of-type,
      &:last-of-type {
        border-radius: 0 !important;

        .accordion-button {
          &.collapsed {
            border-radius: 0 !important;
          }
        }
      }
    }

    .accordion-body {
      padding: 0;
    }
  }
}

.teamspace-chat-window {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2px;
  height: calc(100% - 60px);
  position: sticky;

  .group-activity-listing {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }

  .message-end {
    padding-bottom: 8px;
  }

  .activity-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #7e91a5;
    padding: 16px 0;
  }

  .chat-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .folders {
      .image-figure {
        margin-right: 0;
      }
    }

    .left-header {
      align-items: start;
      display: flex;
      gap: 8px;
      min-height: 48px !important;
      height: fit-content !important;

      .image-figure{
        min-width: 64px;
        min-height: 48px;
      }

      .user-conversation-avatar {
        .avatar {
          width: 40px;
          height: 40px;
          line-height: 40px;
          min-width: 40px;
          font-size: 30.0752px;
          border-width: 2px !important;

          img {
            position: unset;
          }
        }
      }

      .space-hero-image {
        min-width: 48px;
        min-height: 48px;
        width: 100%;
        height: 48px;
        object-fit: cover;
        aspect-ratio: 4/3;
        transition: all 0.3s linear;
        flex-grow: 0;
        border-radius: 4px;
      }

      .space-detail {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .info {
          display: flex;
          gap: 24px;
          font-size: 12px;
          line-height: 1.33;
          color: #5d7893;
          cursor: pointer;

          span {
            letter-spacing: 0.24px;

            &:hover{
              color: #354554;
              font-weight: bold;
              letter-spacing: 0;
            }
          }

          .pinned-count-text {
            &:hover{
              text-decoration: underline;
            }
          }
        }

        .title {
          font-size: 20px;
          font-weight: bold;
          text-transform: capitalize;
        }
      }
    }

    .right-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;

      i {
        font-size: 24px;
        color: #7e91a5;
      }

      .actions {
        cursor: pointer;
      }
    }
  }

  .m-attachments{
    flex-direction: column;
  }

  .conversation-card-container {
    .m-files{
      max-width: 344px !important;
      min-width: 344px !important;
    }
  }
}

.chat-messages {
  display: flex;
  width: 100%;
  flex-direction: column;

  .msg-block {
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
  }
}

.m-box {
  display: flex;
  flex-direction: column;

  .m-msg {
    display: flex;
    gap: 16px;
    align-items: center;

    img {
      border-radius: 50%;
    }

    .text {
      display: flex;
      align-items: center;
      gap: 1rem;
      position: relative;
      max-width: 45%;

      div.m-text {
        padding: 16px;
        border-radius: 1rem;
        position: relative;
      }

      .m-actions {
        display: flex;
        gap: 16px;
        font-size: 20px;
        position: absolute;
        left: calc(100% + 16px);
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}

.msg-block.left {
  justify-content: flex-start;
  align-items: center;

  .m-text {
    background-color: #fff;
    color: #4a749a;
  }
}

.msg-block.right {
  justify-content: flex-end;
  align-items: center;

  .m-msg {
    justify-content: flex-end;
  }

  .text {
    flex-direction: row-reverse;

    .m-text {
      background-color: #4a749a;
      color: #fff;
    }

    .m-actions {
      right: calc(100% + 16px);
      left: auto;
    }
  }
}

.m-data-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  max-width: 45%;

  .m-actions {
    display: flex;
    gap: 16px;
    font-size: 20px;
    position: absolute;
    left: calc(100% + 16px);
    top: 50%;
    transform: translate(0, -50%);
  }
}

.m-data {
  .m-text-container {
    padding: 16px;
    border-radius: 1rem;
    background-color: #fff;

    .m-r-container {
      font-size: 12px;
      padding-left: 8px;
      border-left: 2px solid #4a749a;
      margin-bottom: 8px;

      .m-r-name {
        font-weight: 600;
        color: #7e91a5;
      }

      .m-r-text {
        color: #7e91a5;
      }
    }
  }
}

.m-actions {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-left: 12px;
  position: relative;

  .icon {
    padding: 8px 12px;
    border-radius: 8px;
    color: #7e91a5;
  }

  .icon:hover {
    background-color: #eff1f4;
    cursor: pointer;
  }

  .user-emoji-list {
    left: -24px !important;
  }
}

.ownMessage {
  .chat-emoji-holder {
    flex-direction: row-reverse !important;

    .m-actions {
      flex-direction: row-reverse !important;
      right: 12px;

      .emoji-bar {
        order: unset !important;
      }

      .user-emoji-list {
        left: unset !important;
        right: -24px !important;
      }
    }
  }
}

.user-avatar {
  .avatar-wrapper {
    position: relative;
    width: max-content;

    .oval {
      position: absolute;
      top: -2px;
      right: -2px;
      z-index: 2;
    }
  }
}

.ac-user-list > li{
  margin-top: 0.25rem;

  &:hover{
    background: transparent !important;
  }

  &:hover > a::after{
    background: #eff1f4 !important;
    transition: all 0.4s linear;
  }

  a{
    background: transparent !important;
    position: relative;

    &::after{
      position: absolute;
      top: 0;
      left: 45px;
      content: '';
      width: 80%;
      height: 100%;
      border-radius: 4px;
      background: transparent;
      z-index: -1;
    }
  }
}

.ac-user-list > .active{
  a{
    &::after{
      background-color: #eff1f4 !important;
    }
  }
}

.m-attach-btn{
  max-height: 40px;
}

.space-message, .direct-message {
  .space-reply-row {
    background-color: #edeff3;
    padding: 8px 0;
  }

  .emoji-bar {
    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .conversation-message-author{
    top: 1.5rem !important;
  }

  .conversation-message-content{
    padding-left: 64px !important;
  }

  .have-emoji{
    gap: 8px;
  }

  .conversation-content{
    .creator-info {
      font-size: 0.5rem;
      color: #7e91a5;
      line-height: 1.5rem;

      .seen-icon-tag {
        font-size: 0.5rem;
        margin-left: 0.25rem;

        .emoji-add-list {
          width: 200px;

          ul {
            width: 100%;
          }
        }
      }
    }

    .reply-info {
      color: #4a749a;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.5rem;
      cursor: pointer;
    }
  }

  .conversation-message-box{
    .parent-msg-box {
      display: flex;
      flex-direction: column;
      padding-left: 8px;
      border-left: 2px solid #4a749a;
      color: #7e91a5;
      justify-content: flex-start;
      align-items: flex-start;

      .c-author, .c-text {
        font-size: 12px;
        line-height: 1.33;
      }

      .c-author {
        font-weight: 600;
      }
    }

    .parent-msg-box.own {
      border-left: 2px solid #fff;
      color: #fff;

      .cl-l10 {
        color: #fff;
      }
    }

    p {
      font-size: 16px;
    }
  }

  .space-editor-box {
    display: flex;
    width: 100%;
  }

  &.ownMessage {
    .conversation-content {
      .seen-icon-tag {
        &:hover {
          .emoji-add-list {
            right: -8px;
            left: auto;
          }
        }
      }
    }

    .conversation-message-text {
      color: #fff !important;

      a, .cl-l10 {
        color: #fff;
      }
    }

    .reply-message-content-row {
      .reply-wrapper {
        flex-direction: row-reverse;

        .reply-content {
          .user-emoji-list {
            right: -8px;
            left: auto;
          }

          &.ownReply {
            .reply-message-box {
              .reply-emoji-wrapper {
                flex-direction: row-reverse;

                .d-inline-flex {
                  flex-direction: row-reverse;
                }
              }
            }

            .user-emoji-list {
              left: -8px;
              right: auto;
            }
          }
        }
      }
    }
  }

  .conversation-message, .conversation-content, .reply-message-content-row {
    .chat-emoji-holder,
    .conversation-card-container {
      .mailinfo, .phone-number {
        display: block !important;
      }

      .add-emoji-btn {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }

      &:hover {
        .add-emoji-btn {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }
      }
    }
  }

  // REPLY LAYOUT
  .reply-message-content-row {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .reply-wrapper {
      display: flex;
      gap: 8px;
      flex-direction: row;

      .creator-info {
        display: flex;
        align-items: center;
        font-size: 0.5rem;
        color: #7e91a5;
        line-height: 1.5rem;
      }

      .reply-content {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-direction: row;

        .conversation-message-box {
          padding: 16px;
          position: relative;
          background: $talk-bubble;
          border-radius: 12px;
          box-shadow: $talk-bubble-shadow;
          display: inline-block;
          word-break: break-word;
          margin-bottom: 0;
          color: $level-11;

          .conversation-message-text {
            color: #354554 !important;

            a {
              color: #4a749a;
            }
          }

          &::after{
            content: '\e923';
            width: 8px;
            height: 8px;
            top: 35px;
            left: 3px;
            position: absolute;
            font-family: fill-os-icon !important;
            transform: rotate(180deg);
            font-size: 25px;
            color: #fff;
            text-shadow: 1px 0 3px rgba(53, 69, 84, 12%);
          }
        }

        .user-emoji-list {
          right: 8px;
          left: auto;
        }

        &.ownReply {
          flex-direction: row-reverse;

          .creator-info {
            justify-content: right;
          }

          .conversation-message-box {
            background: $talk-bubble-own;
            direction: ltr;
            word-break: break-word;

            .conversation-message-text {
              color: #fff !important;

              a, .cl-l10 {
                color: #fff;
              }
            }

            &::after{
              content: '\e923';
              width: 8px;
              height: 8px;
              top: 8px;
              left: auto;
              right: 5px;
              position: absolute;
              font-family: fill-os-icon !important;
              transform: rotate(0);
              font-size: 25px;
              color: $talk-bubble-own;
              text-shadow: 1px 0 3px rgba(53, 69, 84, 12%);
            }
          }

          .user-emoji-list {
            left: -8px;
            right: auto;
          }
        }

        .reply-avatar {
          display: flex;
          margin-top: 12px;
        }

        .reply-message-box {
          display: flex;
          flex-direction: column;

          .reply-emoji-wrapper {
            display: flex;
            position: relative;
          }
        }
      }

      .m-actions {
        margin-top: 16px;
        margin-left: 0;
        right: 0;
      }
    }
  }
}

.space-editor {
  flex: 1;

  .reply-editor {
    margin-right: 0;

    .ProseMirror {
      max-height: 300px;
      overflow: auto;
      padding: 0 4px;
    }
  }

  .chat-action-group {
    transform: unset;
    height: 100%;
    position: relative;
    gap: 8px;
    align-items: center;

    .video-filled {
      margin-bottom: 0;
    }
  }
}

.conversation-pin{
  cursor: pointer;

  &.active {
    color: #4c89e4;
  }
}

.pin-active {
  background: #c9d1da;

  .active{
    color: #222b35;
  }
}

.pinned-chat-attachment-row {
  padding: 8px 0;

  .attachment {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 272px !important;

    .conversation-card-container {
      display: flex;
    }
  }
}

.pinned-tooltip {
  .popover{
    width: 386px;
    max-width: 386px;
    height: 404px;
  }

  .popover-body{
    max-height: 404px !important;
    overflow-y: unset !important;
  }

  .pinned-chat-container {
    padding: 0 8px;

    .pinned-head{
      display: flex;
      gap: 8px;
      padding: 16px 16px 12px 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 16px;
    }

    .pinned-body{
      height: 332px;
      overflow-y: scroll;
    }
  }
}

.group-pending-table {
  background: $block;
  box-shadow: $block-shadow;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 32px;
  padding-top: 24px;

  .detail-block {
    padding-left: 40px;
  }

  .space-details-table-row,
  .space-details-table-header {
    display: flex;
    align-items: center;
  }

  .space-details-table-header {
    height: 64px;
    text-transform: uppercase;
    color: $secondary-heading;
    font-weight: 600;

    .action-block {
      padding-right: 24px;
    }
  }

  .space-details-table-row {
    padding: 12px 0;
    min-height: 56px;


    &:nth-child(even) {
      background: $level-2;
    }

    .action-block {
      padding-left: 8px;
    }
  }

  .action-block {
    flex: 0 0 22.5%;

    .osbtn {
      + .osbtn {
        margin-left: 16px;
      }

      &[text="accept"] + .osbtn {
        margin-left: 26px;
      }
    }
  }

  .sp-user-name {
    font-size: 16px;
    max-width: calc(100% - 48px);
    width: calc(100% - 48px);

    .os-btn-link {
      color: $level-11;
      display: inline-block;
      text-decoration: none;

      @include text-truncate;

      width: 100%;

      &:hover {
        text-decoration: underline;
        color: $os-link-hover;

        + .degree-text {
          text-decoration: underline;
          color: $os-link-hover;
        }
      }

    }

    .span-helper {
      max-width: 100%;
      display: block;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $os-link-hover;

        .os-btn-link {
          text-decoration: underline;
          color: $os-link-hover;
        }

      }
    }
  }


  .invite-block-group {
    .os-btn-link {
      &:hover {
        text-decoration: none;
      }
    }

    .avatar {
      margin-right: 12px;

      i {
        font-size: inherit;
      }
    }

    .invite-status {
      width: 100%;
      padding-left: 40px;
    }
  }

  .invite-block {
    @include flexwidth(32%)
  }

  .status-block {
    flex: 0 0 16.5%;
  }

  .date-block {
    flex: 0 0 28%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .group-pending-table {
    .invite-block {
      min-width: 30%;
    }
  }
}

@media(min-width: 1440px) {
  .group-pending-table {
    .invite-block {
      @include flexallwidth(32%)
    }

    .status-block {
      flex: 0 0 16.9503%;
    }

    .date-block {
      flex: 0 0 28.9858%;
    }

    .action-block {
      flex: 0 0 22%;
    }
  }
}

.member-modal {
    .popover{
      height: 404px;
      max-width: calc(100% - 16px);
      width: 100%;
    }

    .popover-body{
      max-height: 404px !important;
      overflow-y: scroll !important;




    }

    .member-list-container{
      padding: 8px 24px;

    .member-head{
      display: flex;
      gap: 8px;
      padding: 16px 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }

    .member-body{
      height: 324px;
      margin-bottom: 0;
      overflow-y: scroll;

      .info {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        font-weight: 600;
        padding: 8px 0;
      }
    }
  }
}

.pinned-msg-block{
  display: flex;
  gap: 8px;
  padding: 12px 8px 12px 12px;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;

  &:hover{
    background-color: #edeff3;
  }

  .info{
    .author{
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      color: #354554;

      .created_at{
        font-size: 8px;
        color: #7e91a5;
      }
    }
  }

  .content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-wrap: anywhere;

    .text{
      font-size: 12px;
      color: #5d7893;

      p {
        font-size: 16px;
      }
    }
  }

  .action{
    display: flex;
    justify-content: flex-end;
    flex: 1;
    font-size: 16px;
  }
}

.left-wrap .ac-user-list .avatar-wrapper .oval{
  display: none;
}

.left-wrap .ac-user-list .lt-count .oval{
  position: absolute;
  margin-top: 4px;
}

.desktop-md .left-wrap .ac-user-list .avatar-wrapper .oval,
.desktop-small .left-wrap .ac-user-list .avatar-wrapper .oval,
 .ipad .left-wrap .ac-user-list .avatar-wrapper .oval{
  display: block !important;
}

.desktop-md .left-wrap:hover .ac-user-list .avatar-wrapper .oval,
.desktop-small .left-wrap:hover .ac-user-list .avatar-wrapper .oval,
 .ipad .left-wrap:hover .ac-user-list .avatar-wrapper .oval{
  display: none !important;
}

.dm-add-message{
  display: flex;
  margin-left: 20px;
}

/* TeamSpaceMessenger Modal */
.space-member-header {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 40px;
    height: 40px;
  }
}

.space-members-modal{
  display: flex;
  flex-direction: column;
  gap: 4px;

  .user-block{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #edeff3;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .info {
    gap: 4px;
    display: flex;
    flex-direction: column;

    .text {
      color:#354554;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
    }

    .role {
      color: #5d7893;
      font-size: 12px;
      line-height: 1.33;
    }
  }
}

/* TeamspaceMessanger comment input style */

.space-input-comments{
  background-color: #fff;
  padding: 24px 32px;
  position: relative;

  .conversation-message-box{
     padding-bottom: 16px;
     position: relative;

    .c-close {
      position: absolute;
      font-size: 8px;
      right: 0;
      padding: 0 8px;
      cursor: pointer;
    }

    .parent-msg-box {
      display: flex;
      flex-direction: column;
      padding-left: 8px;
      border-left: 2px solid #4a749a;
      color: #7e91a5;
      justify-content: flex-start;
      align-items: flex-start;

      .c-author, .c-text {
        font-size: 12px;
        line-height: 1.33;
      }

      .c-author {
        font-weight: 600;
      }
    }
  }
}

.mobile-device {
  .conversation-content {
    .chat-emoji-holder {
      flex-direction: column !important;
      gap: 4px;
    }
  }

  .ownMessage {
    .conversation-content {
      .chat-emoji-holder {
        direction: rtl;
      }
    }
  }

  .space-message {
    .conversation-content {
      .chat-emoji-holder {
        .add-emoji-btn {
          opacity: 0;
          display: none;
          pointer-events: none;
        }

        &:hover {
          .add-emoji-btn {
            opacity: 1;
            display: block;
            pointer-events: auto;
          }
        }
      }
    }
  }

  .space-input-comments {
    width: 100%;
    min-height: 82px;
    padding: 0 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
  }

  .team-nav-widget {
    .right-ac {
      margin-top: 96px;
    }
  }
}

.tip-bottom-menu.hide{
  display: none;
}

.editor-input-layout{
  display: flex;
  gap: 20px;

  button {
    background-color: white;
    margin: 5px;
    border-radius: 10px;
    border: 1px solid #eff1f4;
    padding: 4px 8px;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    font-size: 24px;
  }
}

.editor-input-layout > .layout-normal{
  display: flex;
  flex: 1;
}

.editor-tool-layout{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-bar {
  .seperator{
    width: 3px;
    height: 26px;
    margin: 12px 8px;
    background: #eff1f4;
  }
}

.menu-bar>button {
  min-width: 42px;
  min-height: 42px;
  max-width: 42px;
  max-height: 42px;
}

.group-ac-menu-btn {
  padding: 8px;
}

.team-modal {
  .group-info {
    max-height: 360px;
    overflow-y: auto;
  }

  .group-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
  }

  .group-footer {
    padding-top: 16px;
  }

  .group-heading {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  .group-members-list {
    max-height: 200px;
    overflow: auto;
  }

  .group-timestamp, .createdByText {
    font-size: 8px;
    line-height: 1.5;
    text-align: left;
    color: #7e91a5;
    flex: 1;
  }

  .group-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .group-form {
    .btn-group {
      display: flex;
      justify-content: space-between;
      gap: 24px;

      .web-view-btn {
        flex: 1;
      }
    }
  }

  .group-archive-check-label {
    position: relative;
    padding-left: 40px;
    font-size: 16px;
    margin-bottom: 16px;
    cursor: pointer;

    .ifill-os-checkmark-default-white,
    .ifill-os-checkmark-checked {
      position: absolute;
      top: 2px;
      left: 0;
      font-size: 24px;
      opacity: 0;
      color: #8c8c8c;
    }

    .ifill-os-checkmark-default-white {
      opacity: 1;
    }

    .archive-checkbox {
      display: none;

      &:checked ~ {
        .ifill-os-checkmark-default-white {
          opacity: 0;
        }

        .ifill-os-checkmark-checked {
          opacity: 1;
          transform: scale(1);
          color: #4A749A;
        }
      }
    }
  }

}

.team-modal-header {
  display: flex;
  gap: 8px;
  align-items: center;

  .space-hero-image {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    object-fit: cover;
    aspect-ratio: 4/3;
    flex-grow: 0;
    border-radius: 4px;
  }

  .team-modal-heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: #354554;
    margin: 0;
  }
}

.teamspace-modal-form {
  .form-container {
    padding: 0;
    min-width: auto;

    .form-group {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .group-name {
      display: flex;
      gap: 16px;

      .space-hero-image {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
        object-fit: cover;
        aspect-ratio: 4/3;
        transition: all 0.3s linear;
        flex-grow: 0;
        border-radius: 4px;
      }
    }

    .radio-button-group {
      align-self: stretch;
      flex-grow: 0;
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      gap: 16px;

      div {
        flex: 1;
      }
    }

    .radio-input-element {
      display: none;
    }

    .radio-block {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 16px;
      border-radius: 8px;
      background-color: #f4f7f9;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.33;
      text-align: center;
      color: #4a749a;
    }

    .radio-input-element:checked + .radio-block {
      background-color: #4a749a;
      color: #fff
    }

    .group-banner-field {
      .banner-field {
        display: flex;
        gap: 8px;
      }

      .banner-preview-box {
        height: 120px;
        width: 120px;
        background-color: #B9C4CF;
        border-radius: 8px;

        .banner-preview {
          height: inherit;
          width: inherit;
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
        }
      }

      .banner-dropzone-box {
        width: 100%;
        height: 120px;
        padding: 16px;
        border: 1px dashed #4A749A;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        .description {
          font-size: 16px;
          font-weight: 400;
          padding: 0;
          margin-bottom: 2px;

          span {
            color: #4a749a;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .description-limit {
          font-size: 12px;
          font-weight: 400;
          color: #7E91A5;
        }
      }
    }
  }
}

.spacelist-section {
  overflow-y: auto;
}

.side-menubox {
  .side-menu {
    background: rgba(255, 255 ,255, 1);
    padding: 0;
    border-radius: 8px;
    position: relative;
    list-style: none;
    box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      border: 8px solid transparent;
      border-bottom-color: #ddd;
    }

    &::after{
      border-bottom-color: #fff;
    }

    &.left::before,
    &.left::after {
      right: 8px;
    }

    &.right::before,
    &.right::after {
      left: 8px;
    }

    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 8px 16px;
      color: #5d7893;
      font-size: 12px;
      line-height: 1.33;
      gap: 8px;
      cursor: pointer;

      &:hover{
        background-color: #edeff3;
        color: #354554;
      }
    }

    .have-submenu {
      position: relative;

      &:hover > ul{
        display: block;
      }

      ul {
        display: none;
        position: absolute;
        background: rgba(255, 255 ,255, 100%);
        padding: 0;
        border-radius: 8px;
        list-style: none;
        top: 0;
        left: 100%;
        min-width: max-content;
        box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 12%), 0 3px 6px 0 rgba(53, 69, 84, 15%);
      }
    }
  }
}

.team-nav-list {
  margin-top: 16px;

  ul {
    display: flex;
    flex-direction: row;
    gap: 24px;

    li {
      font-size: 12px;
      font-weight: 600;
      color: #7e91a5;
      cursor: pointer;
      text-transform: uppercase;

      &.active {
        color: #354554;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          background-color: #354554;
          top: 21px;
          left: 0;
          width: 100%;
          height: 2px;
        }
      }
    }
  }
}

.team-nav-widget {
  padding: 8px 24px;

  .chat-shared-files {
    max-height: 510px !important;
  }
}
