.conversation-list-row {
  a {
    gap: 8px;
    .lt-count {
      margin-right: 0px !important;
    }
    .conversion-arrow-icon {
      display: flex;
    }
  }
}

.mobile-device {
  .conversation-list-row {
    a {
      padding-right: 24px !important;
    }
  }
}
