.task-modal-heading {
  font-size: 20px;
  line-height: 40px;
  font-weight: 600;
}

.task-modal-form {


  #title {
	padding-right: 36px;
  }
  .title-field {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    &>div:first-child {
      flex-grow: 1;
    }
  }

  .task-modal-patient-dropdown {
    .dropdown {
      margin: 0;
    }
    .dropdown-toggle {
      background-color: white;
      color: var(--neutrals-level-11, #354554);
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
      border: 1px solid var(--border-input-typed-button-2, #C9D1DA) !important;
      height: 40px;
      border-radius: 8px;

      .title-component {
        display: flex;
        align-items: center;
        gap: 4px;

        .btn-icon-helper {
          transform: translateY(-4%);
        }
      }

      &::after {
        display: none;
      }
    }
  }

  .task-modal-entity {
    .card-dropdown-menu {
      visibility: hidden;
    }
    .os-card-container {
      .card-action {
        display: none;
      }
    }
  }

  .tippy-box {
    background-color: white !important;
    box-shadow: $block-shadow-m;
  }

  .not-found {
    color: black;
  }
  .suggestion-item {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    margin: 4px;

    p {
      padding: 4px;
      width: fit-content;
      margin: 0;
      color: black;
    }

    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
      border-radius: 8px;
    }
  }

  .description-field {
    margin-top: 8px;

    .tip-tap-editor-field {
      min-height: 168px;

      &:hover {
        cursor: text;
      }
    }
  }

  .react-date-picker__wrapper {
    color: black;
  }

  // labels dropdown
  .labels-dropdown {
    margin-bottom: 16px;
    .react-select-ortho-2 {
      .os__control {
        height: 42px;
      }

      .os__value-container {
        .os__multi-value {
          display: none;
        }
      }

      .os__indicator {
        color: black;

        &:hover {
          color: black;
        }

        &::after {
          content: none;
        }
      }
    }

    .input-with-dropdown-wrapper{
      position: relative;
      .input-field{
        height: 40px;
        width: 100%;
        padding: 0 16px;
        border-radius: 8px;
        border: 1px solid #eff1f4;

        &:hover {
          border-color: black;
        }
      }
      .dropdown-container{
        position: absolute;
        top: 75px;
        height: 120px;
        width: max-content;
        min-width: 200px;
        overflow-y: scroll;
        z-index: 1;
        box-shadow:  0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
        .labelContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          padding: 0 16px;
          cursor: pointer;
          position: relative;

          &:hover{
            background-color: #eff1f4;
            .sub-label-dropdown{
              display: block;
            }
          }

          .label-info{
            display: flex;
            align-items: center;
            .labelColor {
              margin-right: 8px;
              border-radius: 50%;
              height: 8px;
              width: 8px;
            }
  
            .result-text-data {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: normal;
              line-height: 32px;
              color: #5d7893;
            }
          }
        }
      }
    }
  }

  // calendar and repeat dropdown
  .calendar-repeat-container {
    display: flex;
    gap: 8px;

    .repeat-dropdown {
      flex: 1;

      .os__menu-list {
        .os__option {
          padding: 0 16px;
          height: 40px;
          display: flex;
          align-items: center;
          flex-grow: 0;
          font-family: Inter, sans-serif;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          text-align: left;
          color: #5d7893;
        }
      }
    }
  }

  .modal-footer {
    div {
      margin: 0;
    }

    .task-modal-buttons {
      & > button:last-child {
        margin-left: 8px;
      }

      .osbtn {
        font-size: 10px;
      }
    }

    button {
      margin: 0;
    }
  }

  // tippy changes
  .tippy-content {
    padding: 0;
  }
}

// Display labels CSS.
.task-modal-form {


  .task-modal-labels-container {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;

    .label {
      .label__color {
        display: flex;
        gap: 4px;
        align-items: center;
        padding: 2px 8px;
        border-radius: 12px;
        height: 16px;
      }

      .label__name {
        font-weight: bold;
        line-height: 1.5;
        text-align: left;
        font-size: 8px;
      }

      img {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.task-modal {
  max-width: 488px;

  .modal-content {
    padding: 24px;
  }

  .modal-header {
    .os-header-btn {
      top: 26px;
    }
  }

  .modal-title {
    @include fontstyle(20px, $font-weight-semibold, 40px);
  }

  .modal-body {
    padding-top: 12px !important;
  }

  .draftPluginsEditorWrapper {
    &.modeEdit {
      margin-top: 16px;
      padding: 54px 16px 16px;
      margin-bottom: 16px;
    }

    .draftJsPluginStaticToolbarWrapper {
      height: 24px;
      top: 16px;
    }

    .DraftEditor-editorContainer {
      min-height: 72px;
    }
  }

  .react-select-ortho-2 {
    &.form-control {
      border: 0;
      padding: 0;
    }

    .os__single-value {
      div {
        display: flex;
      }
    }

    .os__menu {
      .os__menu-list {
        .labelContainer {
          display: flex;
          align-items: center;

          .labelColor {
            margin-right: 8px;
            border-radius: 50%;
            height: 8px;
            width: 8px;
          }

          .result-text-data {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: normal;
            line-height: 32px;
            color: #5d7893;
          }
        }
      }
    }
  }

  .with-error {
    padding-top: 4px;
  }

  .date-calendar {
    position: relative;

    .cool-date-picker {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border-color: #eff1f4;
      cursor: pointer;

      .react-date-picker__wrapper {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        border: 1px solid #eff1f4;
        border-radius: 8px;
        padding-left: 16px;
      }

      .react-date-picker__calendar {
        margin-top: 5px;

        .react-calendar {
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12),
          0 3px 6px 0 rgba(53, 69, 84, 0.15);
        }
      }
    }

    .ifill-os-calendar {
      right: 24px;
      color: $level-6;
    }
  }

  .repeat {
    .os__menu-list {
      .os__option {
        padding: 0 16px;
        height: 40px;
        display: flex;
        align-items: center;
        flex-grow: 0;
        font-family: Inter;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #5d7893;
      }
    }
  }

  .react-datepicker-popper {
    inset: 0px 0px 0px 0px !important;
  }

  .description {
    .form-control {
      height: 81px;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;

    .createdByText {
      display: flex;
      justify-content: flex-end;
      color: #7E91A5;
      flex-grow: 0;
      font-family: Inter;
      font-size: 8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }
}

.labelsDropdown {
  .form-control {

    height: fit-content;

    .os__multi-value {
      display: none;
    }
  }
}

.labelMisc {
  max-width: max-content;
  height: 16px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 12px;
  font-family: Inter;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: left;
  cursor: pointer;
}

.appliedLabel {
  max-width: max-content;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border-radius: 16px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}

.entityWrapper {
  position: relative;

  .entity-clear-icon {
    position: absolute;
    top: 6px;
    transform: translateX(-50%);
    right: 4px;
    z-index: 1;
    cursor: pointer;
    color: #5d7893;
  }

  // Visitor card CSS.
  .visitor-card-container {
    position: absolute;
    top: 35%;
    right: 10%;
    z-index: 10;

    & > i:first-child {
      margin-right: 16px;

      &:hover {
        cursor: pointer;
      }
    }

    & > i:last-child {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .os-card-v2 {
    .os-sm-comment, .os-sm-conversation-message {
      max-height: none !important;
      height: fit-content;

      .commentDate {
        margin-left: 52px;
        height: 12px;
        flex-grow: 0;
        font-family: Inter, sans-serif;
        font-size: 8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #7E91A5;
      }

      .cm-card-body {
        .parse-comment-text {
          p {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}