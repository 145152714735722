%attach-custom-file-icons {
  width: 100%;
  height: 100%;
  @include flex_center();

  .attachments-content-holder {
    width: 24px;
    height: 24px;
    position: relative;
  }

  .conversation-attachement-icon {
    background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
    width: 30px;
    height: 40px;
    display: inline-block;
    background-size: 30px auto;
  }

  .fs-40 {
    font-size: 40px;
  }

  .conversation-attachment-ext-name {
    @include fontstyle(10px, 600, 12px);
    width: auto;
    max-width: 100px;
    height: 16px;
    border: 2px solid #f8f8f8;
    padding: 0 4px;
    overflow: hidden;
    position: absolute;
    top: 16px;
    left: 22px;
    color: $level-11;
    text-align: center;
    background: $level-1;
    border-radius: 4px;
    white-space: nowrap;
  }

  .icon {
    font-size: 40px;
  }

  .overlay-span {
    display: none;
  }
}

%card-type-block {
  position: absolute;
  display: flex;
  align-items: center;
  top: $card-gutter;
  left: $card-gutter;
  z-index: auto;
  text-transform: uppercase;
}

%card-icon {
  font-size: 16px;
  padding-right: 8px;
}

%card-type-format {
  @include fontstyle(12px, normal, 1.33);
}

%attachment-card-image {
  width: 100%;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  overflow: hidden;
  position: relative;
  @include flex_center();

  .ifill-os-addition-link {
    font-size: 24px;
    color: $level-1;
  }
}

%attach-img-200 {
  height: 200px;
  max-height: 200px;
}

%attach-img-88 {
  height: 88px;
  max-height: 88px;
}

%attach-img-56 {
  height: 56px;
  max-height: 56px;
}

%attach-card-head{
  @include fontstyle(12px, normal, 1.33);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 16px;
  position: absolute;
  left: 24px;
  right: 0px;
  top: 24px;
  z-index: 1;


  i{
    font-size: 16px;

    &.ifill-os-image {
      font-size: 0.8634rem;
    }
  }

  .left-attach-col, .right-attach-col {
    display: flex;
    align-items: center;
  }

  .attach-type-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
  }

  /*.right-attach-col {
    position: absolute;
    top: -12px;
    right: 0;
  }*/
}

.no-image {
  &.ifill-os-image {
    color: #fff;
    opacity: .5;
  }
}

.attach-custom-file-icons {
  @extend %attach-custom-file-icons;
}

.lecture-card {
  .img-parent {

    .ifill-os-double-tick {
      opacity: 1;
    }


    .ifill-os-play {
      opacity: 0.6;
    }
  }
  .overlay-span {
    @extend %overlay-span;
  }

  .attachments-content-holder {
    opacity: 0.6;
  }

  [class^="os-lg-"],
  [class^="os-sm-"] {
    &:before {
      content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(18, 18, 20, 0.75);
    }
  }

  .case-card-body {
    position: relative;
  }

  .case-card-head {
    z-index: 1;
  }

  .img-parent {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(18, 18, 20, 0.75);
      z-index:1;
    }
  }
}


/*big card and min-width card */
%os-attachment-card-default {
  @extend %os-card-base;

  .attach-card-head{
    @extend %attach-card-head;
  }

  .card-type-block {
    @extend %card-type-block;
  }

  .card-icon {
    @extend %card-icon;
  }

  .card-type-format {
    @extend %card-type-format;
  }

  .attachment-card-image {
    overflow: hidden;
    position: relative;
  }

  .img-parent {
    @extend %attachment-card-image;
    height: 100%;

    img {
      white-space: normal;
      font-size: 12px;
    }
  }

  .card-attach-image {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .overlay-span {
    @extend %overlay-span;
    max-height: 200px;
  }

  .card-attachment-body {
    padding: $card-gutter;
    display: block;
    position: relative;
  }

  .attachment-card-name {
    @include text-truncate;
  }

  .file-size {
    @include fontstyle(12px, normal, 16px);
    color: $card-sm-text;
    padding-top: 8px;
    text-transform: lowercase;

    &.sub-type {
      text-transform: uppercase;
    }

    &.multiline-truncate {
      @include multiline-truncate(1);
    }
  }

  .pg-viewer-wrapper {
    overflow: hidden;
    border-radius: 8px 8px 0 0 ;

    .pg-viewer {
      max-width: 100%;
      border-radius: 8px 8px 0 0 ;
    }
  }

  .pdf-canvas {
    width: 100% !important;
    border-radius: 8px 8px 0 0;

    canvas {
      width: 100% !important;
      border-radius: 8px 8px 0 0 !important;
    }
  }

  .attach-custom-file-icons {
    @extend %attach-custom-file-icons;
    position: relative;
  }

  &.attachment-304, &.video-304 {
    @extend %os-304;

    .attach-card-head {
      left: 16px;
      right: 16px;
    }

    .card-attachment-body {
      padding: $card-gutter-16;
    }

    .attachment-card-name {
      @include text-truncate;
    }

    .card-dropdown-menu {
      right: -8px;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .pmb-conatiner {
    margin: -6px -9px 0;

    .post-form-control {
      height: 70px;
      padding-top: 5px;
      font-size: 20px;
      padding-left: 8px;
    }

    .post-btn-helper-icon {
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      font-size: 22px;
    }
  }

  .with-error {
    margin-left: -9px;
    margin-right: -9px;
  }
}

.os-lg-attachment, .os-lg-video {
  @extend %os-lg-card;
  @extend %os-attachment-card-default;

  .attachment-card-image {
    @extend %attach-img-200;
  }

  svg {
    height: 160px;
  }
}

.os-video-card {
  .play-icon {
    font-size: 20px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
  }
}

.space-card-col {
  .selection-mode {
    %os-attachment-card-default {
      .file-size {
        padding-top: 0;
      }
    }
  }
}



/*---small doc */
.os-sm-attachment {
  @extend %os-sm-card;
  @extend %os-attachment-card-default;

  svg {
    max-width: 100% !important;
    max-height: 100% !important;

    image {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }

  .attachment-card-image {
    @extend %attach-img-88;
  }

  .attach-custom-file-icons {
    height: 88px;
  }

  .overlay-span {
    height: 88px;
  }

  .card-attachment-body {
    padding: 16px;
  }

  .attachment-card-name {
    @extend %card-heading;
  }

  .card-icon {
    font-size: 14px;
  }

  &.attachment-240 {
    @extend %os-240;
  }

  .pmb-conatiner {
    margin: -10px 0 0;
    .post-form-control {
      font-size: 16px;
      padding-top: 6px;
      height: 50px;
      line-height: 1.3;
    }
  }

  .with-error {
    margin-right: 0;
    margin-left: 0;
  }
}

.os-sm-video {
  @extend %os-sm-card;
  @extend %os-attachment-card-default;

  .selectable-item {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
  }

  .attachment-card-image {
    height: inherit;
    max-height: 100%;
  }

  .os-video-card {
    height: inherit;
  }

  .video-name {
    max-width: calc(100% - 98px);
    @include text-truncate();
    text-transform: none;
    color: $heading;
  }

  .left-attach-col {
    overflow: hidden;
    min-width: calc(100% - 98px);
  }

  .overlay-span {
    height: 100%;
    border-radius: 8px;
  }

  .with-rename {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    padding: 0 16px;

    .pmb-conatiner {
      margin: -10px 0 0;
      .post-form-control {
        font-size: 16px;
        padding-top: 6px;
        height: 50px;
        line-height: 1.3;
      }
    }
  }

  .file-duration{
    @include fontstyle(12px, normal, 1.4);
    color: $level-9;
    text-transform: lowercase;
  }

  .attachment-card-name {
    max-width: 200px;
  }

  .img-parent {
    border-radius: 8px;
  }

  &.video-240 {
    @extend %os-240;

    .attachment-card-name {
      max-width: 100px;
    }

    .video-name {
      max-width: 115px
    }
  }
}

.with-rename {
  .pmb-conatiner {
    .post-message-button {
      border-color: $level-4;
    }
  }
}

/*extra-small-min-card*/
.extra-small-min-card {
  .attach-custom-file-icons {
    .fs-24 {
      font-size: 16px !important
    }
  }

  .os-sm-attachment,
  .os-sm-video {
    @extend %os-extra-sm-card;
    padding: 12px 62px 12px 12px;

    .attachment-link,
    > div,
    .os-video-card  {
      display: flex;
    }

    .left-attach-col {
      i {
        display: none;
      }
    }

    .attach-card-head {
      position: static;
    }

    svg {
      max-width: 90% !important;
      max-height: 90% !important;

      image {
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
  }

  .os-video-card {
    height: 100%;

    .attach-card-head {
      width: calc(100% -  80px);
      max-width: calc(100% -  80px);
      padding: 0 0 8px 12px;

      .left-attach-col {
        width: 100%;
        min-width: 100%;
        line-height: 1.5;
        margin: auto 0;
        display: block;

        i {
          display: none;
        }
      }

      .video-name {
        display: block;
        @include multiline-truncate(2);
        width: 100%;
        max-width: 100%;
        padding: 0 !important;
        max-height: 36px;
      }

      .file-duration {
        padding-top: 4px !important;
        line-height: 1.33;
        display: block;
      }
    }


    .ifill-os-play {
      font-size: 16px;
    }
  }

  .attachment-card-image {
    height: 72px;
    max-height: 72px;
    min-width: 128px;
    max-width: 128px;

    .overlay-span,
    .attach-custom-file-icons {
      height: 100%;
      text-align: center
    }

    .overlay-span,
    .img-parent {
      border-radius: 8px;
    }
  }

  .card-attachment-body {
    width: calc(100% -  128px);
    max-width: calc(100% -  128px);
    padding: 0 0 0 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;

    .attachment-card-name {
      width: 100%;
      @include multiline-truncate(2);
      @include fontstyle(12px, normal, 16px);
      max-height: 36px;

      i {
        display: none;
      }
    }

    .file-size {
      padding-top: 4px !important;
      align-self: flex-start;
      line-height: 16px;
      width: 100%;
    }

    .attachments-content-holder {
      text-align: center;
    }

    .pmb-conatiner {
      margin: 10px 0 0;
    }
  }

  &.multi-selection-mode {
    .os-sm-attachment,
    .os-sm-video {
      padding-right: 60px;
    }
  }
}

.selection-mode {
  &.multi-selection-mode {
    &.selected {
      &.os-card-v2 {
        .os-card-thumbnail {
          background-color: $level-4;
        }
      }
    }
  }
}

.pulse-right-sidebar, .pinned-chat-container {
  .extra-small-min-card {
    @media screen and (max-width: 1919px) {
      .os-sm-attachment , .os-sm-video, .os-sm-case, .os-sm-space{
        height: 56px;
        max-height: 56px;
        padding:8px 12px;
        .sm-case-card-images {
          height: 40px;
          max-height: 40px;
          min-width: 72px;
          max-width: 72px;
          top: 8px;
  
          .img-parent {
            border-radius: 4px;
          }
        }

        .sm-case-card-body{
          width: calc(100% -  88px);
          max-width: calc(100% - 88px);
          padding: 0 0 0 84px;
          overflow: hidden;
          .card-dropdown-menu{
            top: 16px;
          }
          .cs-head {
            font-size: 12px;
            line-height: 16px;
            @include multiline-truncate(1);
          }
        }

        .card-v2-header{
          padding: 0 0 0 84px;
          overflow: hidden;
        }
        .cs-title-wrap{
          margin: 0;
        }
        .card-v2-hero {
          height: 40px;
          max-height: 40px;
          min-width: 72px;
          max-width: 72px;

          .img-parent {
            border-radius: 4px;
          }
        }
      }

      .attachment-card-image {
        height: 40px;
        max-height: 40px;
        min-width: 72px;
        max-width: 72px;

        .img-parent {
          border-radius: 4px;
        }
      }

      .video-name {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
      }

      .attach-card-head {
        margin-bottom: 0;
      }

      .card-attachment-body {
        width: calc(100% -  88px);
        max-width: calc(100% - 88px);

        .attachment-card-name {
          font-size: 12px;
          line-height: 16px;
          @include multiline-truncate(1);
        }

        .file-size {
          line-height: 1;
        }
      }
    }

    @media screen and (min-width: 1920px) {
      .os-sm-attachment , .os-sm-video, .os-sm-case, .os-sm-space{
        height: 80px;
        max-height: 80px;
        padding: 12px 12px;
        .sm-case-card-images {
          height: 56px;
          max-height: 56px;
          min-width: 104px;
          max-width: 104px;
          top: 8px;
  
          .img-parent {
            border-radius: 4px;
          }
        }

        .sm-case-card-body{
          width: calc(100% -  120px);
          max-width: calc(100% - 120px);
          padding: 0 0 0 84px;
          overflow: hidden;
          .card-dropdown-menu{
            top: 16px;
          }
          .cs-head {
            font-size: 12px;
            line-height: 16px;
            @include multiline-truncate(1);
          }
        }

        .card-v2-header{
          padding: 0 0 0 84px;
          overflow: hidden;
        }
        .cs-title-wrap{
          margin: 0;
        }
        .card-v2-hero {
          height: 56px;
          max-height: 56px;
          min-width: 104px;
          max-width: 104px;

          .img-parent {
            border-radius: 4px;
          }
        }
      }

      .attachment-card-image {
        height: 56px;
        max-height: 56px;
        min-width: 104px;
        max-width: 104px;

        .img-parent {
          border-radius: 4px;
        }
      }

      .video-name {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
      }

      .card-attachment-body {
        width: calc(100% -  120px);
        max-width: calc(100% - 120px);

        .attachment-card-name {
          font-size: 16px;
          line-height: 20px;
        }

        .file-size {
          line-height: 1;
        }
      }
    }

    .os-sm-attachment ,
    .os-sm-video {
      border-radius: 8px;
      .file-duration, .file-size {
        display: none;
      }

      .left-attach-col, .card-attachment-body {
        display: inline-flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        padding-right: 48px;

      }

      .video-name {
        margin-bottom: 4px;
      }

      &:hover {
        background-color: $level-4;

        .overlay-span {
          top: 0;
          border-radius: 4px;
        }
      }
    }

    .os-sm-attachment {
      .attachment-card-name {
        font-weight: 600;
        margin-bottom: 4px;
      }

      .attach-card-head {
        display: none;
      }
    }

    .col-12 {
      &:last-child {
        .os-card-v2:after {
          display: none;
        }
      }
    }
  }
}
.cd-type-name {
  color: $level-7;
  @include fontstyle(12px, 600, 16px);
  text-transform: uppercase;
  display: block;
  margin-bottom: 4px;
}

.extra-small-min-card {
  .pmb-conatiner {
    margin-top: 0;

    .post-message-button {
      width: 40px;
      font-size: 16px;
    }

    .post-btn-helper-icon {
      font-size: 16px;
    }

    .post-form-control {
      height: 32px !important;
      font-size: 12px !important;
      max-height: 50px !important
    }

    + .with-error {
      padding-top: 4px;
    }
  }

  .os-sm-video {
    .card-attachment-body.with-rename {
      padding-left: 152px;
      width: 100%;
      max-width: 100%;
      padding-right: 12px;
      z-index: 1;
      bottom: auto;
    }
  }

  .rename {
    &.os-sm-attachment {
      padding-right: 12px;
    }
  }
}
.col-thumb, .extra-small-min-card {
  .icon-space-overlay {
    font-size: 16px;
  }

  .attach-custom-file-icons {
    .fs-24 {
      font-size: 16px !important
    }
  }
}
