.pagination-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;

  .page-numbers {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .page-number {
    min-width: 32px;
    height: 32px;
    padding: 0 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover:not(:disabled) {
      background: var(--hover-bg, #f5f5f5);
      border-color: var(--border-color, #ddd);
    }

    &.active {
      background: var(--primary-color, #007bff);
      color: var(--white, #ffffff);
      border-color: var(--primary-color, #007bff);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .ellipsis {
    padding: 0 4px;
    color: #666;
  }

  .pagination-button {
    padding: 6px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    font-size: 14px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background: var(--hover-bg, #f5f5f5);
      border-color: var(--border-color, #ddd);
    }
  }

  .page-info {
    font-size: 14px;
    color: #666;
  }
} 