$spin-664: 664px;
$spin-height: 391px;
$transform-50: translate(-50%, -50%);

.case-left-wrapper {
  height: calc(100% - 90px);
  margin-top: 16px;
}

.new-cs-card {

  .do.cate {
    .os-btn-link {
      font-weight: normal !important;
    }
  }
  .case-left-wrapper {
    height: calc(100% - 64px);
    background-color: $block;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    
  }

  .case-thumbnail-slider {
    pointer-events: none;
  }

  .sbs-wrapper,
  .case-left-wrapper {
    &:hover {
      .case-thumbnail-slider {
        pointer-events: auto;
      }
    }
  }

}
.no-touch-support {
  .new-cs-card {
    .sbs-wrapper {
      .case-thumbnail-slider {
        bottom: 0;
        margin-right: 4px;
        width: calc(100% - 4px);
      }

      .sbs-right {
        .case-thumbnail-slider {
          margin-right: 0;
        }
      }
    }

    .sbs-wrapper,
    .case-left-wrapper {
      .slide-with-scrubber + .case-thumbnail-slider {
        margin: 0;
      }

      .slide-with-scrubber {
        .slider-threesixty,
        .new-os-slider {
          transition: all 350ms ease;
        }
      }

      &:hover {
        .slide-with-scrubber {
          .new-os-slider {
            height: calc(100% - 78px);
          }

          .slider-threesixty,
          ~ .slider-threesixty {
             &.vertical {
              height: calc(100% - 146px);
             }

             &.horizontal {
              bottom: -12px;
             }
          }

          ~ .slider-threesixty.horizontal {
            bottom: 64px;
          }
        }

        .slide-with-scrubber + .case-thumbnail-slider,
        .case-thumbnail-slider {
          bottom: 0;
        }

        .slide-with-scrubber + .case-thumbnail-slider {
          height: 72px;
        }
      }
    }

    .case-left-wrapper {
      .case-thumbnail-slider {
        height: 0;
        bottom: 0;
        transition: height 500ms ease, opacity  500ms ease;
        will-change: height, opacity;
      }

      &:hover {
        .case-thumbnail-slider {
          bottom: 0;
          opacity: 1;
          z-index: 1;
          height: 72px;
        }
      }
    }
  }
}

.spin-slide-wrapper {
  height: calc(100% - 104px);
  width: 100%;
  position: relative;
  background-color: $block;
  padding: 8px 16px 0 16px;

  @media(min-width: 768px){
    border-radius: 8px;
  }
}

.spin-slide-header {
  text-align: center;
}

.spin-slide-body {
  height: calc(100% - 40px);
}

.spin-wrapper {
  width: 100%;
  position: relative;
}

.height-40 {
  height: 16px;
}

.spin-slider {
  height: 100%;
  width: 100%;
  article {
    height: inherit;
  }
}

.new-cs-card {
  .spin-slide-wrapper {
    padding: 0;
    height: 100% !important;
  }

  .spin-slide-body {
    height: 100%;
  }
}

.spin-video {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  > div {
      position: absolute;
      top: 50%;
      left: 50%;
      bottom: 0;
      right: 0;
      margin-left: -8px;
  }

  &:before {
    content: '';
    background-color:  $level-1;
    height: 100%;
    top:0;
    width: 3px;
    position: absolute;
    z-index: 99;
    right: 0px;
  }

  &:after {
    content: '';
    background-color:  $level-1;
    height: 4px;
    bottom:0px;
    width: 100%;
    position: absolute;
    z-index: 99;
    left: 0;
  }

  .threesixty-spin {
    transform: $transform-50 scale(0.8);

    &:before {
      content: '';
      background-color:  $level-1;
      width: 4px;
      top:0px;
      bottom:0px;
      height: 100%;
      position: absolute;
      z-index: 99;
      right: -1px;
    }

    &:after {
      content: '';
      background-color:  $level-1;
      height: 4px;
      bottom:0px;
      width: 100%;
      position: absolute;
      z-index: 99;
      left: 0;
    }
  }
}

.full-screen {
  .case-left-wrapper {
    height: calc(100% - 56px);
  }
  .spin-slide-wrapper {
    margin-top: 9px;
    height: calc(100% - 120px);
    padding-right: 0;
    padding-left: 0;
  }

  .spin-video {
    .threesixty-spin {
      transform: $transform-50 scale(1.05);
    }
  }
}

@media screen and (min-width: 1920px) {
  .spin-video {
    .threesixty-spin {
      transform: $transform-50 scale(0.8);
    }
  }

  .full-screen {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50 scale(1.9);
      }
    }
  }
}

@media screen and (min-width: 2520px) {
  .spin-video {
    .threesixty-spin {
      transform: $transform-50 scale(0.79);
    }
  }

  .full-screen {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50 scale(3);
      }
    }
  }
}

.desktop-small {
  .spin-video {
    .threesixty-spin {
      transform: $transform-50 scale(0.58)
    }
  }

  .new-os-spin {
    .slick-slider {
      height: calc(100% - 36px);

      article {
        height: calc(100% - 8px;);
      }
    }
  }

  .full-screen {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50 scale(0.9);
      }
    }
  }
}

.ipad{
  .new-os-spin {
    .slick-slider {
      height: calc(100% - 36px);

      article {
        height: calc(100% - 8px;);
      }
    }
  }

  @media(max-width:  1024px) {
    .quiz-right-section {
      .submission-selection-small-holder.four-submission-link .submission-selection-link {
        font-size: 7px;
      }
    }
  }

  &.landscape {
    .spin-video {
      .threesixty-spin {
        transform: $transform-50 scale(0.6);
      }
    }

    .full-screen {
      .spin-video {
        .threesixty-spin {
          transform: $transform-50 scale(0.9);

          @media screen and (min-width: 1300px) {
            transform: $transform-50 scale(1.5);
          }
        }
      }


      @media screen and (max-width: 1024px) {
        .user__info {
          display: flex;
          align-items: center;

          .user-case-id,
          .detail-subheader  {
            display: inline-block !important;

          }

          .detail-subheader  {
            @include text-truncate();
            max-width: 86px;
          }
        }
      }
    }
  }

  &.portrait {
    .spin-slide-wrapper {
      margin-top: 8px;
      height: 392px;
    }

    .spin-slide-body {
      height: calc(100% - 56px);
    }

    .new-os-slider {
      .cs-fullscreen {
        right: 24px;
      }

      &:not(.new-os-spin) {
        height: calc(100% - 34px);
      }

      .slick-slide > div,
      .slick-slide > div > div {
        height: 100%;
      }
    }

    .slider-threesixty {
      &.vertical {
        right: 8px;
        top: 48px;
        height: calc(100% - 96px);
      }

      .rangeslider-horizontal {
        right: 40px;
        margin-top: 16px;
      }
    }

    .spin-video {
      .threesixty-spin {
        transform: $transform-50 scale(0.62);

        @media screen and (min-width: 1300px) {
          transform: $transform-50 scale(1.02);
        }
      }
    }

    .full-screen {
      .slider-threesixty {
        &.vertical {
          right: -16px;
          height: calc(100% - 104px);
        }
      }

      .case-left-wrapper {
        height: calc(100vh - 230px);
      }

      .spin-slide-wrapper {
        height: 100%;
      }

      .spin-video {
        .threesixty-spin {
          transform: $transform-50 scale(1.5);

          @media screen and (min-width: 1024px) {
            transform: $transform-50 scale(2);
          }
        }
      }

      .new-os-slider {
        .cs-fullscreen {
          right: 0;
        }
      }
    }

    .new-cs-card {
      .spin-slide-body {
        height: 100%;
      }

      .new-os-slider:not(.new-os-spin) {
        height: 100%;
      }
    }
  }
}

.case-left-wrapper {
  .new-os-slider {
    &.new-os-spin {
      .slick-slider {
        height: calc(100% - 48px);
      }
    }
  }
}

.mobile-device {
  .spin-slide-wrapper {
    height: calc(100% - 72px);
  }
  .spin-slide-body {
    height: calc(100% - 26px);
  }

  .case-left-wrapper {
    height: 320px;
  }

  .slider-threesixty {
    &.vertical {
      top: 34px;
      height: calc(100% - 70px);
    }
  }


  .case-left-wrapper {
    .new-os-slider {
      &.new-os-spin {
        .case-left-wrapper {
          .slick-slider {
            height: calc(100% - 36px);
          }
        }
      }
    }
  }

  .spin-video {
    .threesixty-spin {
      &:after {
        bottom: -1px;
      }
    }
  }

  .new-cs-card {
    .case-left-wrapper {
      height: 422px;
      border-radius: 0;

      .spin-slide-wrapper {
        height: calc(100% - 74px) !important;
        border-radius: 0;

        .cs-sort-btn-group {
          bottom: -112px;
        }

        &.slide-with-scrubber {
          .slider-threesixty {
            &.vertical {
              height: calc(100% - 80px);
            }

            &.horizontal {
              bottom: 0px;
            }
          }
        }
      }

      &:hover {
        .slide-with-scrubber {
          .slider-threesixty {
            &.vertical {
              height: calc(100% - 150px);
            }

            &.horizontal {
              bottom: 72px;
            }
          }
        }
      }

      .spin-slide-body {
        height: 100%;
      }

      .new-os-slider {
        &.new-os-spin {
          .case-left-wrapper {
            .slick-slider {
              height: calc(100% - 36px);
            }
          }
        }

        .slick-slide > div,
        .slick-slide > div > div {
          height: 100%;
        }
      }
    }
  }
}
