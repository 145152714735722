.unified-board {
  .react-kanban-board {
    & > div {
      grid-template-rows: none !important;
      grid-template-columns: repeat(4, 1fr) !important;
      grid-template-areas: 'done unschedule overdue inProgress';

      .react-kanban-column {
        height: 75vh !important;

        [data-rbd-droppable-id='done'] {
          grid-area: done;
          & > div {
            & > div {
              width: 100% !important;
              & > div {
                display: block !important;
              }
            }
          }
        }

        [data-rbd-droppable-id='unscheduled'] {
          grid-area: unschedule;
          & > div {
            & > div {
              width: 100% !important;
              & > div {
                display: block !important;
              }
            }
          }
        }

        [data-rbd-droppable-id='overdue'] {
          grid-area: overdue;
          & > div {
            & > div {
              width: 100% !important;
              & > div {
                display: block !important;
              }
            }
          }
        }

        [data-rbd-droppable-id='inProgress'] {
          grid-area: inProgress;
          & > div {
            & > div {
              width: 100% !important;
              & > div {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }

  .unified-column-header {
    display: flex !important;
    justify-content: center !important;
    color: #5d7893;
    flex-grow: 1;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.33;
    text-align: center;
  }

  .teamMembersDropdown {
    .os__control {
      background-color: #ffffff !important;
      border-color: #ffffff !important;
      .os__value-container {
        padding-left: 0;
        padding-right: 8px;
      }
      &:hover {
        border-color: transparent;
      }
    }
  }
}
