.labels-view-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 32px;

    max-width: 1376px;
    background: #FFFFFF;
    border-radius: 12px;

    flex: none;
    order: 2;
    flex-grow: 0;

    .labels-view-header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-height: 100px;
        height: 93px;

        .header-text{
            display: flex;
            flex-direction: column;
            gap:21px;

            .heading{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #354554;
                flex: none;
                order: 0;
                flex-grow: 0;
            }
            .text{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #354554;
                flex: none;
                order: 0;
                flex-grow: 0;
                max-width: 889px;
            }

        }
    }

    .labels-view-body{
        width: 100%;
        .table-wrap{
            margin-bottom: 32px;
            .table-heading{
                display: flex;
                padding: 16px 0px;
                align-items: center;
                gap: 10px;
                justify-content: space-between;
                margin-bottom: 8px;
                cursor: pointer;

                .heading-text{
                    display: flex;
                    padding: 16px 0px;
                    align-items: center;
                    gap: 10px;
                    align-self: stretch;
                    margin-bottom: 8px;
                    cursor: pointer;

                    .text{
                        color: #354554;
                        font-family: Inter;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px;
                    }
                }
            }
            .label-table{
                @media(max-width: 1024px){
                    overflow: scroll;
                }
                .table-head{
                    display: flex;
                    height: 20px;
                    align-items: center;
                    padding: 0 8px;

                    .table-head-item{
                        height: 16px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        letter-spacing: 0.2px;
                        text-transform: uppercase;
                        color: #5D7893;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }

                    .name{
                        width: 292px;
                        display: flex;
                        align-items: center;
                        gap:8px;
                        cursor: pointer;
                    }

                    .label-color{
                        width: 147px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .usage-count{
                        display: flex;
                        width: 128px;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 4px;
                        flex-shrink: 0;
                    }

                    .label-options{
                        display: flex;
                        width: 128px;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 4px;
                        flex-shrink: 0;
                    }

                    .task-count{
                        // margin-left: 56px;
                    }

                    .patient-count{
                        // margin-left: 33.5px;
                    }
                }

                .table-body{
                    // max-height: 384px;
                    // overflow-y: scroll;

                    .table-body-row{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        min-height: 64px;
                        padding: 16px 8px;
                        border-bottom: 1px solid #C9D1DA;

                        @media(max-width: 1024px){
                            overflow: scroll;
                        }
                        .label-info-container{
                            display: flex;
                            width: calc(100% - 90px);
                            .labels-name-wrapper{
                                width: 292px;
                                display: flex;
                                align-items: center;
                                .label-name{
                                    width: fit-content;
                                    padding: 8px 16px;
                                    border-radius: 8px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-family: 'Inter';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 16px;
                                    color: #354554;
                                }
    
                                .label-field{
                                    width: '100%';
                                    height: '100%';
                                    background: #FFFFFF;
                                    border: 1px solid #4A749A;
                                    border-radius: 8px;
                                    display: flex;
                                    align-items: center;
                                    padding: 8px 8px 8px 12px;
                                }
    
                                .label-field::placeholder {
                                    font-family: 'Inter';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 16px;
                                    display: flex;
                                    align-items: center;
                                    color: #354554;
                                    opacity: 1;
                                }
                            }
    
                            
    
                            .color-dropdown{
                                padding: 0 12px;
                                border-right: 1px solid #eff1f4;
                                border-left: 1px solid #eff1f4;
                                min-height: 40px;
                                display: flex;
                                align-items: center;
    
                                .cs-dropdown.mw-88{
                                    min-width: 120px;
                                }
                                .selected-color{
                                    font-family: 'Inter';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 16px;
                                    display: flex;
                                    align-items: center;
                                    gap:12px;
                                    color: #5D7893;
                                }
                                
                                .header-dropdown-list{
                                    z-index: 99;
                                    box-shadow: $block-shadow;
                                }
                            }
    
                            .tasks-count, .patients-count, .usage-count{
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #5D7893;
                            }
    
                            .usage-count{
                                width: 128px;
                            }
    
                            .tasks-count{
                                padding-left: 56px;
                                width: 80px;
                            }
    
                            .patients-count{
                                padding-left: 100px;
                                width: 80px;
                            }
    
                            .option-container{
                                width: calc(100% - 567px);
                                .label-option-field{
                                    width: '100%';
                                    height: '100%';
                                    background: #FFFFFF;
                                    border: 1px solid #4A749A;
                                    border-radius: 8px;
                                    display: flex;
                                    align-items: center;
                                    padding: 8px 8px 8px 12px;
                                }
    
                                .label-option-field::placeholder {
                                    font-family: 'Inter';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 16px;
                                    display: flex;
                                    align-items: center;
                                    color: #354554;
                                    opacity: 1;
                                }
                                .label-options{
                                    display: flex;
                                    width: 100%;
                                    padding: 8px 0px;
                                    align-items: flex-start;
                                    align-content: flex-start;
                                    gap: 10px;
                                    flex-wrap: wrap;
    
                                    .option{
                                        display: flex;
                                        padding: 8px 16px;
                                        align-items: center;
                                        gap: 8px;
                                        border-radius: 8px;
                                        justify-content: center;
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 12px;
                                        line-height: 16px;
                                        color: #354554;
                                    }
                                }
                            }
                        }

                        

                        .action-btn{
                            display: flex;
                            .save-btn{
                                border: none;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }

        }
    }
}