.os-sm-board-link,
.os-sm-restricted-space-link {
  position: relative;

  .card-wrapper {
    display: flex;
    width: 100%;
    padding: 8px 4px 8px 16px;
    border-radius: 4px;
    color: #e7e7ea;
    cursor: pointer;
    background: #222226;

    &:hover {
      color: #fff;
    }

    &.active {
      background: $block;
    }
  }

  .figure {
    border-radius: 8px;
    overflow: hidden;
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    max-height: 80px;
    min-height: 48px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &[src*="entity-icons"] {
        object-fit: contain;
      }
    }

    .blurspan {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      /*background: linear-gradient(53deg, rgba(148,121,131,1) 0%, rgba(99,64,72,1) 50%, rgba(148,121,131,1) 99%);*/
      background-color: rgba(18, 18, 20, 0.7);
      /*-webkit-backdrop-filter: blur(27.2px);
      backdrop-filter: blur(27.2px);*/
      border-radius: 8px;
      backdrop-filter: blur(2px);
    }

    i {
      font-size: 24px;
      color:  #fff;

      &.ifill-os-checkmark-round {
        color: #e7e7ea;

        + i {
          opacity: 0;
        }
      }
    }


    &.image-bg {
      height: 48px;
      display: flex;
      background-image: linear-gradient(to right, #7C86D4 , #C491C9);

      i {
        margin: auto;
      }

      &.bg-2 {
        background-image: linear-gradient(90deg, rgba(150,155,217,1) 0%, rgba(123,118,204,1) 50%, rgba(156,138,210,1) 100%);
      }
    }
  }

  .card-dropdown-menu {
    @include verticalCenter();
    right: 4px;
    z-index: 1;

    .card-btn-more {
      min-width: 40px;
      min-height: 40px;
      color: #6c6c6f;
      transform: rotate(90deg);

      i {
        margin-bottom: 2px;
      }
    }
  }

  .card-info {
    padding: 0 48px 0 8px;
    font-size: 16px;
    line-height: 1.33;
    width: calc(100% - 120px);
    max-width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .time-info {
      color: $level-11;
      margin-top: auto;
    }
  }

  .toc-heading {
    padding: 0 !important;
    color: $heading;
    word-break: normal;
    letter-spacing: 0.5px;
    word-wrap: normal;
    font-size: 12px;
  }

  .toc-heading {
    margin-bottom: 8px;
  }

  .card-small-content {
    color: #e7e7ea;

    + .time-info {
      padding-top: 4px;
    }
  }
}

.card-z-index {
  .os-sm-board-link,
  .os-sm-restricted-space-link {
    .card-dropdown-menu {
      z-index: 4;
    }
  }
}

.multi-selection-mode {
  .os-sm-space {
    .selectable-item {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 4px;
    }
  }
}
