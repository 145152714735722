%search-result-item {
  cursor: pointer;
  padding: 11px 15px;
  display: inline-block;
  width: 100%;
  span {
    width: 50%;
    text-align: right;
  }
}

.communication{
  .search{
    position: relative;
    padding-left: 27px;
    border-top: 1px solid rgba(255,255,255,0.15);
    border-bottom: 1px solid rgba(255,255,255,0.15);

    input{
      width: 100%;
      background: none;
      border: none;
      padding: 6px 0;
      outline: none !important;
      font-size: 15px;
    }

    i{
      position: absolute;
      left: 2px;
      top: -1px;
      font-size: 26px;
      color: $level-11;
    }
  }
}

.search{
  .list-unstyled{
    .list-title{
      font-size: 18px;
    }
    .list-text{
      span{
        font-size: 14px;
        line-height: 19px;
        font-weight: 200;
      }
    }
  }
}

.nav-search-holder {
  .ifill-os-search {
    position: absolute;
    top: 0;
    @media(min-width: 768px) {
      line-height: 40px;
    }
    left: 16px;
    color: $level-8;
  }
}

.autosuggest-search-container {
  width: 100%;
  position: relative;
  text-align: center;

  .ifill-os-search {

  }

  .clear-input {
    font-size: 16px;
    color: $level-8;
    @include y-center;
    cursor: pointer;
  }

  .clear-input {
    right: 12px;
  }

  .nav-search-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .search-results {
    width: 100%;
    height: auto;
    max-height: 300px;
    padding: 0 15px;
    position: absolute;
    top: 32px;
    background: $dropdown-bg;
    border-radius: 8px;
    text-align: left;
    font-size: 12px;
    z-index: 3;
    overflow-y: auto;
    color: $dropdown-text;

    .search-group{
      margin: 0 -15px;
      border-bottom: none;
      border-top: solid 1px rgba(255, 255, 255, 0.15);
      padding-top: 10px;

      &:first-child {
        padding-top: 0;
        border-top: none;
      }

      .search-result {
        @extend %search-result-item;
      }
    }

    .nav-search-input {
      padding-right: 36px;
    }

    .search-group-headding {
      padding: 11px 15px;
      font-size: 12px;
      color: $secondary-heading;

      + div {
        @extend %search-result-item;
      }
    }

    .search-group-icon {
      margin-right: 6px;
      opacity: .5;
      cursor: default;
      position: relative;
      width: 12px;
      height: 12px;
      background-size: 12px auto;
    }

    .icon-tags {
      top: 3px;
    }

    .search-group-info-icon {
      cursor: pointer;
      position: relative;
      top: 2px;

      &:hover {
        .search-group-info-tooltip {
          display: block;
        }
      }
    }

    .search-group-info-tooltip {
      width: 160px;
      padding: 10px 10px;
      display: none;
      position: absolute;
      top: 26px;
      right: -13px;
      z-index: 9;
      font-size: 11px;
      font-weight: 600;
      text-align: center;
      background: #1e1e1e;
      border-radius: 5px;

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #646464;
        position: absolute;
        top: -10px;
        right: 9px;
      }
    }

    .search-view-all {
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      padding: 0px;
      margin: 8px 15px 14px;
      cursor: pointer;

      &:empty {
        display: none;
      }
    }
  }

  &.with-attached-cards {
    .search-results {
      @media(min-width: 1024px) {
        max-height: 150px;
      }
    }
  }
}

.dropzone-with-search {
  display: flex;
  width: 100%;

  .object-search-block {
    flex: 1;
  }

  .nav-search-holder {
    height: 40px;
  }

  .search-results {
    top: 38px;
  }

  .form-control.nav-search-input {
    height: 40px;
  }

  .nav-search-holder {
    width: 100%;
  }

  .search-aside {
    margin-left: auto;
    padding-left: 24px;
  }

  .object-search-block {
    position: relative;
  }

  .dropdown-filters-container {
    position: relative;
    height: 22px;
    margin-top: 5px;

    .dropdown-filters {
      position: absolute;
      list-style: none;
      z-index: 1;
    }
  }
}

.nav-search-input {
  width: 100%;
  height: 100%;
  padding: 0 30px 0 40px;
  font-size: 16px;
  text-align: left;
  cursor: pointer;

}

.form-control {
  &.nav-search-input {
    min-height: 40px !important;
    height: 40px;
    padding: 0 30px 0 35px !important;
    font-size: 16px;
    text-align: left;
    line-height: 40px;
  }
}

.cases-search-block {
  .nav-search {
    width: 656px;

    .input-wrapper {
      width: 100%;

      .nav-search-container {
        width: 100%;
      }
    }

    .search-category {
      width: 95px;
      right: 0;

      i {
        top: 12px;
        left: auto;
        right: 10px;
        font-size: 12px;
      }
    }

    .clear-input {
      display: none;
    }

    .search-results {
      border: none;
    }

    .search-category-options {
      width: 95px;
      right: 0;

      .search-category-option {
        font-size: 14px;
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    display: none;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .nav-search {
      width: 540px;
    }
  }
}

.no-touch-support {
  .search-result,
  .search-group-headding + div {
    &:hover {
      background-color: $dropdown-list-hover !important;
      color: $dropdown-hover-text;
    }
  }

  .autosuggest-search-container {
    .clear-input:hover {
      color: $os-link-hover;
    }
  }
}

.trending-head-case {
  @include fontstyle(24px, $font-weight-bold, 32px);
  padding-top: 8px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  color: $heading;

  .small-16 {
    @include fontstyle(16px, 300, 24px);
    padding-left: 16px;
    color: $level-11;
  }

  + .head-sticky {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 767px) {
    @include fontstyle(20px, $font-weight-bold, 24px);

    + .head-sticky {
      margin-bottom: 16px;
    }
  }
}