.invoice-header {
    .status-pill {
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-weight: 500;

    &.shared-with-patient {
      background-color: #e3f2fd;  // Light blue background
      color: #1976d2;             // Darker blue text
      border: 1px solid #90caf9;  // Medium blue border
    }
  }
}

.invoice-details {
  .invoice-info {
    padding: 1rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;

    .info-item {
      margin-bottom: 0.5rem;
      
      label {
        font-weight: 600;
        margin-right: 0.5rem;
        opacity: 0.8;
      }

      span {
        font-weight: 500;
      }
    }
  }

  .activity-section {
    .activity-list {
      max-height: 300px;
      overflow-y: auto;
      padding-right: 0.5rem;
    }

    .activity-item {
      padding: 1rem;
      border: 1px solid #dee2e6;
      border-radius: 0.5rem;
      margin-bottom: 0.75rem;
      background-color: #fff;

      .activity-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        .activity-type {
          font-weight: 600;
          color: #495057;
        }

        .activity-time {
          font-size: 0.875rem;
          color: #6c757d;
        }
      }

      .activity-content {
        margin-bottom: 0.5rem;
      }

      .activity-user {
        font-size: 0.875rem;
        color: #6c757d;
      }
    }
  }
} 