.cs-gallery-grid {
  padding: 10px 0 0;
  width: 100%;
  height: calc(100% - 56px);

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait ) {
    padding-bottom: 0;
    padding-top: 0;
  }

  &.classic-view {
    padding-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding-top: 0;
    height: 320px;
    background-color: $level-1;
  }

  &:not(.classic-view) {
    .pic-6,
    .pic-7,
    .pic-8 {
      img {
        object-fit: unset;
        height: auto;
        width: 100%;
      }
    }

    .pic-6 {
      text-align: right;
    }

    .pic-7 {
      text-align: center;
    }
  }

  li {
    list-style: none;
    padding: 0;
  }
}

.cs-gallery-grid-row {
  width: 100%;
  height: 100%;
  margin: 0;
  justify-content: space-between;
  gap: 0.5vw;

  li {
    list-style: none;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait ) {
    padding: 16px 0;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 52px;
    padding-top: 52px;
  }
}

.cs-grid-col,
.cs-grid-col-9 {
  &.unavailable {
    border: 1px solid $level-6;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: #6c6c6f;
    position: relative;

    &:empty {
      border-color: transparent;
    }

    .cs-grid-pic-4a,
    .cs-grid-pic-4b {
      position: relative;
    }

    .unavailable-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 767px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.cs-grid-col {
  max-width: 24.50%;
  flex: 0 0 24.50%;
  border-radius: 8px;
  height: 65.53%;
  overflow: hidden;

  &.object-position-right {
    img {
      object-position: right;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait ) {
    height: 60.53%;
  }

  @media screen and (max-width: 767px) {
    height: 128px;
  }

  ~ .cs-grid-col-3 {
    max-width: 32.76%;
    flex: 0 0 32.76%;
    height: 34.46%;
    margin-right: 0;

    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait ) {
      height: 37.46%;
    }

    @media screen and (max-width: 767px) {
      height: 80px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 100% ;
    max-width: 100%;
    border-radius: 8px;
  }

  &.cs-grid-col-9-3 {
    max-width: 23%;
    flex: 0 0 23%;

    img {
      width: 100%;
      height: unset;
      object-fit: unset;
      border-radius: 8px;
    }

    .cs-grid-pic-4a,
    .cs-grid-pic-4b {
      display: flex;
    }

    .cs-grid-pic-4a {
      align-items: flex-end;
      img {
        border-radius: 8px 8px 0 0;
        border-bottom: 0 !important;
      }
    }

    .cs-grid-pic-4b {
      align-items: flex-start;
      img {
        border-top: 0 !important;
        border-radius: 0 0 8px 8px;
      }
    }
  }

  &.pic-1 {
    img {
      object-position: right bottom;
    }
  }

  &.pic-2,
  &.pic-3 {
    img {
      object-position: bottom;
    }
  }

  &.pic-4 {
    &.unavailable {
      border: 0;

      .cs-grid-pic-4a,
      .cs-grid-pic-4b {
        img {
          border: 1px solid $level-6;
        }
      }
    }
  }

  &.cs-grid-col-9-3 {
    &.unavailable {
      .cs-grid-pic-4a,
      .cs-grid-pic-4b {
        :not(img) {
          border: 1px solid $level-6;
          border-radius: 8px 8px 0 0;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          transform: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 32px;
        }
      }

      .cs-grid-pic-4b {
        :not(img) {
          border-radius: 0 0 8px 8px ;
        }
      }

    }
  }
}

.cs-grid-pic-4a,
.cs-grid-pic-4b {
  width: 100%;
  height: 50%;
  border-radius: 8px;
}

.cs-gallery-grid-row-9 {
  width: 100%;
  height: 100%;
  margin: 0;
  /*justify-content: space-between;*/
  justify-content: center;
  padding: 0px 10.63%;
  gap: 0.25vw;

  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}

.cs-gallery-grid-9 {
  padding: 0px 0px !important;
  width: 100%;
  height: 560px;
}

.cs-grid-col-9 {
  max-width: 32.9%;
  flex: 0 0 32.9%;
  border-radius: 8px;
  height: 47.32%;
  overflow: hidden;
  text-align: center;

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait ) {
    height: 46.32%;
  }

  @media screen and (max-width: 767px) {
    height: 120px;
  }

  ~ .cs-grid-col-9-3 {
    height: 25.71%;
    margin-right: 0;

    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait ) {
      height: 24.71%;
    }

    @media screen and (max-width: 767px) {
      height: 92px;
    }
  }

  &.pic-6 {
    ~ .pic-6 {
      @media(max-width: 767px) {
        height: 80px;
      }
    }
  }

  &:nth-child(3n + 1) {
    text-align: right;
  }

  &:nth-child(3n + 3) {
    text-align: left;
  }

  img {
    height: 100%;
    border-radius: 8px;
    margin: auto;
  }

  &.pic-1,
  &.pic-2,
  &.pic-3 {
    img {
      object-fit: cover;
      object-position: bottom;
      width: 100%;
    }
  }

  &.pic-6-1 ~ .pic-6 {
    img {
      height: unset;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

.ipad {
  &.portrait {
    .cs-gallery-grid-row-9,
    .cs-gallery-grid-row {
      gap:  0.5vw;
    }

    .cs-grid-col-9 {
      &.pic-1,
      &.pic-2,
      &.pic-3 {
        height: 285px;
      }

      &.pic-4,
      &.pic-5,
      &.pic-6-1 {
        height: 198px;
      }
    }

    .cs-grid-col {
      &.pic-1,
      &.pic-2,
      &.pic-3 {
        max-width: 23.95%;
        flex: 0 0 23.95%;
      }

      &.cs-grid-col-9-3 {
        flex: 0 0 25.5%;
        max-width: 25.5%;
      }
    }

    .pic-6-1 ~ .pic-6 {
      max-height: 149px;
    }

    @media(max-width: 900px) {
      .cs-grid-col-9 {
        &.pic-1,
        &.pic-2,
        &.pic-3 {
          height: 202px;
        }

        &.pic-4,
        &.pic-5,
        &.pic-6-1 {
          height: 148px;
        }
      }
    }
  }

  &.landscape {
    .cs-grid-col {
      &.pic-1,
      &.pic-2,
      &.pic-3 {
        max-width: 23.95%;
        flex: 0 0 23.95%;
      }

      &.cs-grid-col-9-3 {
        flex: 0 0 25.5%;
        max-width: 25.5%;
      }
    }

    .cs-grid-col-9 {
      height: 224px;

      &.pic-4,
      &.pic-5,
      &.pic-6-1 {
        height: 172px;
      }

      &.pic-6-1 ~ .pic-6 {
        height: 96px;
      }
    }

    @media(max-width:  1024px) {
      .cs-grid-col-9 {
        height: 40%;

        &.pic-4,
        &.pic-5,
        &.pic-6-1 {
          height: 128px;
        }

        &.pic-6-1 ~ .pic-6 {
          height: 96px;
        }
      }
    }

  }
}


@media(max-width: 767px) {
  .cs-gallery-grid-row,
  .cs-gallery-grid-row-9 {
    gap: 2px;
  }
}
