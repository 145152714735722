.care, .workspace {
  .non-clv-header {
    .btn-with-title-bar {
      margin-top: 8px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: $level-9;
    }

    ~ .sticky-tab-with-dropdown {
      margin-top: 16px;

      .space-nav-list-mobile{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 16px;
        gap: 16px;
        width: 100%;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

        .space-nav-list-item{
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px 16px;
          gap: 8px;
          width: 100%;
          height: 48px;
          background: #FFFFFF;
          border: 1px solid #C9D1DA;
          border-radius: 8px;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
  
          i{
            color:  #7E91A5;
          }
  
          span{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2px;
            text-transform: uppercase;
            color: #354554;
            flex: none;
            order: 1;
            flex-grow: 0;
          }
        }
      }

      .tiptap-editor-wrapper{
        padding: 0 16px;
        min-height: 275px;
        height: max-content;


        .tiptap-editor-with-button{
          height: 100%;
          position: relative;
          padding: 1px 16px;
          background: #fff;

          div{
            .tip-tap-editor-field{
              max-height: 160px;
              min-height: 160px;
              overflow: scroll;
            }
          }

          .options{
            .editor-actions{
              display: flex;
              justify-content: space-between;
              align-items: center;

              .action-button{
                display: flex;
              }
            }
            .editor-attachments{
              padding-bottom: 16px;
            }
          }

          .time-pill-and-submit{
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 4px;
            position: absolute;
            top: 193px;
            right: 16px;
            .time-pill{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 8px 12px;
              gap: 4px;
              height: 32px;
              background: #EDEFF3;
              border-radius: 16px;
              cursor: pointer;
      
              span{
                height: 16px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #354554;
                mix-blend-mode: normal;
              }
            }
            .submit-btn-container{
              display: flex;
              .btn-save-info{
                position: initial;
                .btn-link-helper{
                  width: 69%;
                }
              }
              
              .clinic-design{
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
  
              .scheduler-btn{
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                color: #fff;
                background: #354554;
                cursor: pointer;
              }
  
              .dropdown-active{
                background: #4A749A;
              }

              
            }
          }
          
        }

        .schedule-dropdown-container{
          width: 202px;
          height: 166px;
          border-radius: 8px;
          z-index: 12;
          position: absolute;
          top: 50px;
          right: 0;
          background: #fff;
    
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          isolation: isolate;
          box-shadow: 0px 3px 6px rgba(53, 69, 84, 0.15), 0px 2px 4px rgba(53, 69, 84, 0.12);
    
          .time-block{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 16px;
            isolation: isolate;
            width: 202px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 8px 8px 0px 0px;
            cursor: pointer;
    
            &:hover{
              background: #EDEFF3;
            }
    
            span{
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              display: flex;
              align-items: center;
              color: #5D7893;
              gap: 9px;
            }
          }
    
          .custom{
            color: #354554;
          }
        }

        .calendar-wrapper{
          z-index: 12;
          position: absolute;
          top: 50px;
          right: 0;
          box-shadow: 0 2px 4px 3px rgba(53, 69, 84, 0.12), 0 3px 6px 0px rgba(53, 69, 84, 0.15);
          border-radius: 8px;
      
          .react-calendar{
            border: none;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            .react-calendar__viewContainer{
              border-top: 1px solid #C9D1DA;
              border-bottom: 1px solid #C9D1DA;
            }
          }
    
          .time-block-wrapper{
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            background: #fff;
          
          .time-block{
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap:24px;
              
            .time-block-container{
              min-width: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap:24px;
      
              i{
                cursor: pointer;
              }
      
              .hour-block,.minute-block,.shift-block{
                min-width: 20px;
                display: flex;
                flex-direction: column;
                gap: 4px;
      
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #354554;
              }
            }
          }
          .time-error{
            text-align: center;
            color: red;
            width: 350px;
          }
        }
    
        }
      }
      
    }
  }

  &.ipad{
    &.portrait {
      .non-clv-header {
        .btn-with-title-bar {
          padding-top: 0;
          padding-bottom: 0;
        }

        ~ .sticky-tab-with-dropdown {
          margin-top: 16px;
        }
      }
    }
  }

  &.mobile-device {
    .non-clv-header {
      .share-wth-patient-btn {
        width: 100%;
        text-align: center;
        min-height: 40px;
        line-height: 40px;
      }
      .header-dropdown-menu {
        position: absolute !important;
        right: 12px;
        top: 20px;
        border-radius: 8px;
      }
    }

    .space-detail-discussion-care {
      .col-12 {
        padding: 0;
      }

      .cards {
        padding: 0;
      }
    }
  }
  .detail-comments-header {
    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      padding-right: 16px !important;
      min-height: 104px;

      @media(max-width: 767px) {
        min-height: 176px !important;
      }
    }
  }
}
