.free-course-form {
  padding: 48px 8.3% 52px 8.3%;
  background-color: #1c1c1f;
  margin: 80px 0;
  visibility: visible;
  opacity: 1;
  transition: visibility 500ms ease, opacity 500ms ease;
  will-change: visibility, opacity;

  .email_heading {
    @include fontstyle(28px, bold, 32px);
    color: #e7e7ea;
    margin-bottom: 8px;
  }

  .email_sm_text {
    @include fontstyle(20px, normal, 32px);
    color: $level-11;
  }

  &.fadeout {
    height: 0;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.email-update-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .form-group {
    margin-bottom: 16px;

    + .form-control {
      margin-bottom: 0;
    }

    .with-error {
      position: absolute;
    }
  }

  .form-control {
    width: 320px;
    max-width: 320px;
    margin-right: 4px;
  }

  input::-webkit-input-placeholder {
    color: $level-11 !important;
  }
  input::-moz-placeholder {
    color: $level-11 !important;
  }
  input:-ms-input-placeholder {
    color: $level-11 !important;
  }
  input:-moz-placeholder {
    color: $level-11 !important;
  }
}

.desktop-small {
  .free-course-form {
    padding: 48px 32px;

    .col-lg-7{
      max-width: 55%;
      flex: 0 0 55%;
    }

    .osbtn {
      max-width: 80px;
      padding: 0;
      min-width: 80px;

      &:hover {
        padding: 0;
      }
    }
  }

}

.ipad {
  &.portrait {
    .free-course-form {
      padding: 24px 32px;

      .col-md-6 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .email_heading {
      @include fontstyle(24px, bold, 32px);
    }

    .email_sm_text {
      @include fontstyle(16px, normal, 24px);
    }

    .email-update-form {
      .form-control {
        min-width: 528px;
        max-width: 528px;
        margin-top: 16px;
      }

      .osbtn {
        margin-top: 16px;
      }

      .agree-custom-checkbox {
        margin-left: 16px;
      }
    }
  }

  &.landscape {
    @media(min-width: 1025px) {
      .free-course-form {
        padding: 48px 8.3%;
      }
    }
  }
}

.mobile-device {
  .free-course-form {
    padding: 40px 24px;

    .row {
      flex-direction: column;
    }

    .email_heading {
      @include fontstyle(24px, bold, 32px);
    }

    .email_sm_text {
      @include fontstyle(16px, normal, 24px);
    }
  }
  .email-update-form {
    flex-direction: column;

    .form-control {
      width: 100%;
      max-width: 100%;
      margin-top: 40px;
    }

    .col:last-child {
      text-align: center;
    }

    .osbtn {
      margin-top: 36px;
    }
  }
}
