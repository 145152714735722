.user-details-table {
  border-radius: 6px;
  background: #1c1c1f;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 24px;
  margin-bottom: 80px;

  .user-details-table-header {
    height: 64px;
    text-transform: uppercase;
    color: $secondary-heading;
    font-weight: 600;
  }

  .user-details-table-row {
    padding: 4px 0;

    &:nth-child(even) {
      background: rgba(34,34,38, 0.7);
    }
  }

  .osbtn {
    font-size: 12px;
    display: inline-block;
    cursor: pointer;
    color: $os-link;
    line-height: 32px;
    text-transform: unset;
    padding: 0;
    min-height: unset;
    min-width: unset;
    border: 0;
    background: none !important;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }

    + .osbtn {
        margin-left: 0;
      }
  }

  .user-details-table-header,
  .user-details-table-row {
    display: flex;
    align-items: center;
  }
}

.user-subscription-table {
  .user-details-blocks {
    padding-left: 16px;

    + .user-details-blocks {
        margin-left: 24px;
      }
  }

  .user-details-table-row {
    min-height: 40px;
  }

  .date-block {
    flex: 0 0 14.583333%;
    padding-left: 24px;
  }

  .description-block {
    flex: 0 0 23.125%;
  }

  .card-number-block {
    flex: 0 0 16.25%;
  }

  .amount-block {
    flex: 0 0 10.416666%;
  }

  .receipt-block {
    flex: 0 0 9.375%;
  }
}

.user-invitee-table {
  .user-details-blocks {
    padding-left: 24px;
  }

  .a-link {
    font-size: 12px;
    font-weight: normal;
  }

  .inv-name-block {
    flex: 0 0 31.675675%;

    @media(min-width: 1201px) {
      flex: 0 0 36.675675%;
    }
  }

  .inv-join-block {
    flex: 1;
  }

  .inv-action-block {
    flex: 0 0 35.864864%;

    @media(min-width: 1201px) {
      flex: 0 0 30.675675%;
    }
  }

  .view-profile-btn {
    margin-right: 32px;
  }

  .accepted-connection-button {
    display: inline;
  }
}

@media (min-width: 500px) and (max-width: 1023px) {
  .user-subscription-table {
    .user-details-blocks {
      padding-left: 16px;

      + .user-details-blocks {
          margin-left: 0;
        }
    }

    .date-block {
      flex: 0 0 18.965517%;
    }

    .description-block {
      flex: 0 0 21.666666%;
    }

    .card-number-block {
      flex: 0 0 20.555555%;
    }

    .amount-block {
      flex: 0 0 11.944444%;
    }

    .receipt-block {
      flex: 0 0 13.888888%;
    }

    .text-invoice {
      display: none;
    }
  }
}

.mobile-device {
  .user-details-table {
    margin-bottom: 16px;
  }

  .connect-send-btn {
    display: none;
  }

  .view-profile-btn {
    margin: 0;
  }

  &.portrait {
    .m-portrait-block-hide {
      display: none;
    }

    .m-portrait-block-show {
      display: initial;
    }

    .user-details-table-holder {
      margin-left: -12px;
      margin-right: -12px;
    }

    .user-details-table-row {
      align-items: flex-start;
    }

    .user-details-table {
      .user-details-table-row {
        .user-details-blocks {
          padding-top: 10px;
        }

        .inv-action-block,
        .documents-block {
          padding-top: 2px;
        }
      }
    }

    .user-subscription-table {
      .user-details-blocks {
        padding-left: 8px;

        + .user-details-blocks {
            margin-left: 0;
          }
      }

      .date-block {
        flex: 0 0 19.733333%;
        padding-left: 16px;
      }

      .description-block {
        flex: 0 0 34.5%;
      }

      .amount-block {
        flex: 0 0 20.8%;
      }
    }

    .user-invitee-table {
      .user-details-blocks {
        padding-left: 16px;
      }

      .a-link {
        font-size: 12px;
        font-weight: normal;
      }

      .inv-name-block {
        flex: 0 0 49.6%;
      }

      .inv-action-block {
        flex: 0 0 25%;
      }

      .accepted-connection-button {
        display: inline;
      }
    }
  }
}

.ipad {
  .user-subscription-table {
    .text-invoice {
      display: none;
    }
  }

  .user-invitee-table {
    .view-profile-btn {
      margin-right: 24px;
    }

    .inv-name-block {
      flex: 0 0 33%;
    }

    .inv-action-block {
      flex: 0 0 33%;
    }
  }

  &.portrait {
    .user-invitee-table {
      .inv-name-block {
        flex: 0 0 33%;
      }

      .inv-action-block {
        flex: 0 0 46%;
      }
    }
  }
}

.course-billing-table {
  border-radius: 6px;
  background: #1c1c1f;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 24px;
  margin-bottom: 80px;
  margin-top: 30px;

  .course-billing-table-header {
    height: 64px;
    text-transform: uppercase;
    color: $level-9;
    font-weight: 600;
  }

  .course-billing-table-row {
    padding: 4px 0;

    &:nth-child(even) {
      background: rgba(34,34,38, 0.7);
    }


    .osbtn {
      font-size: 12px;
      display: inline-block;
      cursor: pointer;
      color: $os-link;
      line-height: 32px;
      text-transform: unset;
      padding: 0;
      min-height: unset;
      min-width: unset;
      border: 0;
      background: none !important;
      font-weight: normal;

      &:hover {
        text-decoration: underline;
      }

      + .osbtn {
          margin-left: 0;
        }
    }

  }

  .course-billing-table-header,
  .course-billing-table-row {
    display: flex;
    align-items: center;
  }

  .course-details-blocks {
    padding-left: 16px;

    + .course-details-blocks {
        margin-left: 24px;
      }
  }

  .date-block {
    flex: 0 0 11.583333%;
    padding-left: 24px;
  }

  .course-name {
    flex: 0 0 20.125%;
  }

  .card-number-block {
    flex: 0 0 14.25%;
  }

  .amount-block {
    flex: 0 0 14.416666%;
  }

  .receipt-block {
    flex: 0 0 9.375%;
  }

  .invoice-block {
    flex: 0 0 9.375%;
    padding-left: 0;
    margin-left: 0 !important;
  }

  .status-block {
    flex: 0 0 8%;
  }
}

@media (min-width: 500px) and (max-width: 1023px) {
  .course-billing-table {
    .course-details-blocks  {
      padding-left: 8px;

      + .course-details-blocks  {
          margin-left: 0;
        }
    }

    .btn-link-32 {
      line-height: 16px;
    }

    .date-block {
      flex: 0 0 16.965517%;
    }

    .course-name {
      flex: 0 0 18.666666%;
    }

    .card-number-block {
      flex: 0 0 18.555555%;
    }

    .amount-block {
      flex: 0 0 18.944444%;
    }

    .receipt-block {
      flex: 0 0 9.888888%;
    }

    .invoice-block {
      flex: 0 0 9.888888%;
      padding-left: 0;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .course-billing-table {
    .course-details-blocks  {
      padding-left: 8px;

      + .course-details-blocks  {
          margin-left: 0;
        }
    }

    .date-block {
      flex: 0 0 13.965517%;
    }

    .course-name {
      flex: 0 0 19.666666%;
    }

    .card-number-block {
      flex: 0 0 16.555555%;
    }

    .amount-block {
      flex: 0 0 16.944444%;
    }

    .receipt-block {
      flex: 0 0 11.888888%;
    }

    .invoice-block {
      flex: 0 0 11.888888%;
    }
  }
}

.m-portrait-block-show {
  display: none;
}

.mobile-device {
  .course-billing-table {
    margin-bottom: 16px;

    .course-details-blocks {
      padding-left: 8px;

      + .course-details-blocks {
          margin-left: 0;
        }

      &.receipt-block {
        .os-btn-link {
          margin-top: -8px;
        }
      }
    }

    .course-billing-table-holder {
      margin-left: -12px;
      margin-right: -12px;
    }

    .course-billing-table-row {
      align-items: flex-start;
    }

    .date-block {
      flex: 0 0 30.733333%;
      padding-left: 16px;
    }

    .course-name{
      flex: 0 0 24.5%;

      .btn-link-32 {
        line-height: 16px;
      }
    }

    .amount-block {
      flex: 0 0 20.8%;
    }
  }
}

.no-detail-dash {
  display: block;
  &.ml-24 {
    margin-left: -24px;
  }
  &.ml-45 {
    margin-left: 45px;
  }
  &.ml-15 {
    margin-left: 15px;
  }
  &.ml-25 {
    margin-left: 25px;
  }
}
