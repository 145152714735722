.slider-img-truncate {
  @include text-truncate();
  max-width: 50px;
}

.appointment_hint_text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(34, 34, 38, 0.2);
  border-radius: 8px;
  color: $level-9;
  @include fontstyle(24px, 600, 0.67);
}

.new-os-slider {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .cs-fullscreen {
    bottom: 16px;
  }

  .case-photo {
    padding-bottom: 0;
  }

  .slick-slider {
    flex: 1;
    height: 100%;
  }

  .slick-list,
  .slick-track,
  .case-photo,
  .slick-slide {
    height: 100%;

    > div {
      &:before {
        background-color: transparent;
        content: "";
        position: absolute;
        left: 0px;
        bottom: 0;
        top: 0;
        z-index: 1;
        width:1px;
        height:100%;
      }
    }
  }

  .slick-slide > div,
  .slick-slide > div > div  {
    height: 100%;
    position: relative;
    text-align: center;

    .slide-img-holder {
      height: 100%;
      position: relative;
      display: inline-flex!important;
      margin: 0 auto;
      max-width: 100%;
      align-items: center;
      width: auto !important;

      &:before {
        background-color: transparent;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        opacity: .8;
        z-index: 1;
        border-radius: 8px;
        transition: all .5s ease;
        cursor: pointer;
      }

      &:after {
        background-color: $level-1;
        content: "";
        position: absolute;
        right: -1px;
        bottom: 0;
        top: 0;
        z-index: 1;
        width:1px;
      }

      img {
        margin: 0 auto;
        display: block!important;
        max-height: 100%;
        max-width: 100%;
        width: auto !important;
      }
    }
  }

  /*.slick-slide {
    .slide-img-holder {
      height: 100% !important;
      position: relative;
      width: auto !important;

      &:before {
        content :'';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 0;
        visibility: visible;
        background-color: rgba(48, 48, 54, 0.2);
        z-index: 1;
      }
    }
  }*/

  article {
    height: calc(100% - 16px);
  }

  &.new-os-spin {
    .slick-slide > div,
    .slick-slide > div > div  {
      height: 100%;
    }
  }

  /*.case-image-full{
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.case-image-sbs{
      max-width: 50%;
      display: inline-block;
    }
  }*/
}

.slider-controll-buttons,
.switch-classic-mode {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  margin-bottom: 8px;

  .btn-control {
    cursor: pointer;
    width: 32px;
    height: 32px;
    text-align: center;
    padding: 0;
    min-width: unset;
    min-height: unset;
    line-height: 32px;

    .btn-icon-helper {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .slider-image-name {
    padding: 0 2px;
    text-align: center;

    &.mw-90 {
      min-width: 90px;
    }
  }
}

.full-screen {
  .new-os-slider {
    .cs-fullscreen {
      right: 0;
    }
  }

  &.case-detail-height {
    width: 100%;
  }
}

.ipad {
  &.portrait {
    .new-os-slider {
      article {
        height: 100%;
      }

      .new-os-slider {
        .cs-fullscreen {
          bottom: 0;
        }
      }
    }
  }
}


@media(max-width: 1023px) {
  .default-image-block {
    position: absolute;
    z-index: 1;
    right: 16px;
    top: 56px;
  }
}


@media(max-width: 767px) {
  .slider-controll-buttons {
    justify-content: unset;
    padding: 0;
    margin: 0 0 8px 0;
    height: auto;

    &:not(.switch-classic-mode) .osbtn {
      display: none;
    }
  }

  .new-os-slider {
    .slick-slide > div,
    .slick-slide > div > div  {
      height: calc(100% - 8px);
      position: relative;
    }

    &.new-os-spin {
      .slick-slide > div,
      .slick-slide > div > div  {
        height: 100%;
        position: relative;

        .spin-video {
          height: 100%;
        }
      }

      article {
        height: 100%;
      }
    }

    .spin-video {
      .threesixty-spin {
        transform: $transform-50 scale(0.38);
      }
    }
  }
}


.comparisons {
  .new-os-slider {
    margin-top: 0;
    border-radius: 0;
  }

  .left-case-video,
  .right-case-video {
    padding: 0;
    min-height: unset;
  }
}

.appointment-dates-list-item {
  padding-left: 30px !important;
}

.mobile-device {
  .appointment-dates-ul {
    left: 0;
    z-index: 99;
  }
}

.appointment-dates-ul {
  min-width: 220px !important;
  margin-top: 2px;
}

.appointments-header-class > li:first-child {
  pointer-events: none;
  opacity: 0.5;
}
