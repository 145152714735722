.avatar {
  border-radius: 50%;
  color: $level-1;
  display: inline-block;
  position: relative;
  text-shadow: none !important;

  .ifill-os-outline-doctor {
    margin-top: 1.5px;
  }

  &.avatar-initials {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $level-6;
    padding-top: 0;
  }

  &.avatar-medium {
    @include avatar(54px);
  }

  &.avatar-16 {
    @include avatar(16px);
    border-width: 1px;
    line-height: 16px - 2px;
  }

  &.avatar-30 {
    @include avatar(30px);
  }

  &.avatar-32 {
    @include avatar(32px);

    .icon-span {
      @include icon-span(16px);
    }
  }

  &.avatar-35 {
    @include avatar(35px);
  }

  &.avatar-40 {
    @include avatar(40px);

    .icon-span {
      @include icon-span(16px);
    }
  }

  &.avatar-67{
    @include avatar(67px);
  }

  &.avatar-72{
    @include avatar(72px);
  }

  &.avatar-47 {
    @include avatar(47px);
  }

  &.avatar-48 {
    @include avatar(48px);

    .icon-span {
      @include icon-span(20px);
      right: -2px;
      bottom: -2px;
    }
  }

  &.avatar-42 {
    @include avatar(42px);
  }

  &.avatar-38 {
    @include avatar(38px);
  }

  &.avatar-36 {
    @include avatar(36px);
  }

  &.avatar-34 {
    @include avatar(34px);
  }

  &.avatar-24 {
    @include avatar(24px);
    border-width: 2px;

    .icon-span {
      @include icon-span(14px);
    }
  }

  &.avatar-64 {
    @include avatar(64px);
  }

  &.avatar-120 {
    @include avatar(120px);

    .icon-span {
      @include icon-span(40px);
    }
  }

  &.avatar-responsive {
    height: 23%;
    width: 23%;
  }

  &.avatar-large {
    @include avatar(109px);
  }

  &.avatar-103 {
    @include avatar(103px);
    font-size: 30px;
  }

  &.avatar-168 {
    @include avatar(168px);
    font-size: 52px;

    .icon-span {
      @include icon-span(40px);
    }
  }

  &.avatar-80 {
    @include avatar(80px);
  }

  &.avatar-98 {
    @include avatar(98px);
    font-size: 52px;
  }

  &.avatar-200 {
    @include avatar(200px);

    .icon-span {
      @include icon-span(64px);
    }
  }

  &.avatar-full {
    height: 100%;
    width: 100%;
  }

  &.avatar-signup {
    @include avatar(77px);
  }

  img {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    border: 0;
    left: 0px;
    top: 0;
    padding: 0;
    margin: 0 !important;
    border-radius: 50%;
    display: block !important;
    font-size: 0;
  }

  &.role-trial-expired {
    border-color: $os-trial;
  }

  &.role-blue {
    border-color: $os-link;
  }

  &.role-platinum {
    border-color: $os-platinum;
  }

  &.default-role-border {
    border-color: $level-9;
  }

  &.role-gold {
    border-color: $os-gold;
  }
}

// initials
div.avatar{
  font-weight: $font-weight-lighter;
  text-align: center;
  text-transform: uppercase;
  padding-top: 1px;
  letter-spacing: -0.5px;
}
