.companycard-logo-img {
  max-width: 72px;
  min-width: 72px;
  height: 72px;
  border-radius: 100%;
  margin-right: 16px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.company-info-text {
  max-width: 65%;
}

.company-icon-with-name {
  @include fontstyle(16px, 600, 1.5);
  @include text-truncate();
}

.company-address {
  @include fontstyle(12px, normal, 16px);
  margin: 8px 0 8px;
  color: $level-9;
  @include multiline-truncate(2);
  overflow: hidden;
}

.company-tools-agents {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include fontstyle(12px, normal, 1.33);
  color: $level-5;

}

.cm-icon {
  margin-right: 8px;
}

.mar-32 {
  margin-right: 32px;
}

.company-card-images {
  display: flex;
  align-items: center;
  margin: 0 -12px 31px;
  position: relative;

  .company-img-col {
    padding: 0px 12px;
    border-radius: 4px;
  }

  .company-img {
    min-width: 88px;
    max-width: 88px;
    height: 72px;
    text-align: center;
    @include flex_center();
    position: relative;

    img{
      max-width: 100%;
      max-height: 100%;
      border-radius: 4px;
      object-fit: cover;
      width: 100%;
    }

    &.tl-placeholder-img {
      img {
        width: auto;
        max-height: 48px;
      }
    }
  }

  .overlay-span {
    @extend %overlay-span;
    border-radius: 4px;
  }

}

.company-card-footer {
  padding-top: 14px;
  position: relative;
  margin-top: auto;
}


.os-lg-company {
  @extend %os-lg-card;
  @extend %os-card-base;
  padding: $card-gutter $card-gutter $card-gutter-16 ;
  color: $level-11;
  display: flex;
  flex-direction: column;

  .company-card-footer {
    &:after {
      height: 1px;
      background-color: #5c5c66;
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 100%;
    }
  }

  .company-card-info-wrap {
    height: 120px;
  }

  &.justified-images {
    .company-card-images {
      justify-content: space-between;

    }
  }


  &.company-304 {
    @extend %os-304;
    padding: $card-gutter $card-gutter-16 $card-gutter-16 ;

    .company-info-text {
      max-width: 58%;
    }

    .card-dropdown-menu {
      right: 8px;
    }

    .cm-icon {
      margin-right: 5px;
    }

    .company-card-images {
      margin-left: -8px;
      margin-right: -8px;

      .company-img-col {
        padding: 0px 8px;
      }

      .company-img {
        min-width: 80px;
        max-width: 80px;
      }
    }
  }

  &.without-tool-address {
    .company-img {
      box-shadow: 0 2px 6px 0 #121214;
      background-color: #121214;
    }

    .company-card-footer {
      padding-top: 24px;
    }
  }
}


.os-sm-company {
  @extend %os-sm-card;
  @extend %os-card-base;
  padding: $card-gutter-16;
  color: $heading;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .company-card-images,
  .ifill-os-company,
  .cm-icon {
    display: none;
  }

  .company-icon-with-name {
    text-transform: capitalize;
  }

  .companycard-logo-img {
    max-width: 48px;
    min-width: 48px;
    height: 48px;
    overflow: unset;
  }

  .card-dropdown-menu {
    &.top-18 {
      top: 8px;
      right: 8px;
    }
  }

  .tool-icon-count {
    padding-right: 10px;
    position: relative;

    // &:after {
    //   height: 10px;
    //   background-color: $level-9;
    //   content: '';
    //   position: absolute;
    //   right: 0px;
    //   top: 2px;
    //   width: 1px;
    // }
  }

  .mar-32 {
    margin-right: 10px;
  }

  .company-address {
    @include multiline-truncate(2);
    max-height: 43px;
    overflow: hidden;
  }

  .company-info-text {
    max-width: 65%;
  }

  &.company-240 {
    @extend %os-240;

    .company-icon-with-name {
      @include multiline-truncate(2);

      .small-truncate {
        @include text-truncate();
      }
    }

    .companycard-logo-img {
      max-width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 6px;
      position: absolute;
      left: 16px;
    }

    .company-info-text {
      max-width: 100%;
    }

    .company-icon-with-name {
      padding-left: 46px;
      min-height: 40px;
      padding-right: 30px;
    }

    .company-address {
      @include text-truncate();
      display: block;
    }
  }
}

/*extra-small-min-card*/
.extra-small-min-card {
  .os-sm-company {
    @extend %os-extra-sm-card;
    padding: 8px 40px 8px 8px;

    .avatar {
      left: 0;
      top: 0;
    }
  }

  .companycard-logo-img {
    height: 64px;
    min-width: 64px;
    max-width: 64px;
    position: absolute;
    top: 16px;
    left: 12px;
    height: 64px;
    max-height: 64px;
    margin-right: 0;

    .avatar {
      @include avatar(64px);
    }
  }

  .company-card-info-wrap {
    height: 100%;
    align-items: center;
    padding-left: 80px;
  }

  .company-info-text {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .company-icon-with-name {
    align-self: flex-end;
  }

  .company-tools-agents {
    align-self: flex-start;
    padding-top: 4px;
    width: 100%;
  }

  .company-card-footer {
    @include extra-small-card-footer;
  }

  .company-address  {
    display: none;
  }
}
