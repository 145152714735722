.mention-textarea-wrap__control {
  background: $textarea-bg;
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  min-height: 48px;
  border-radius: 8px;
  color: $input-text-color;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
}


.comment-add-attachments-link {
  z-index: 1;

  &.active {
    border-color: $os-link !important;
    background: $cl-link;
    color: $level-1;
  }
}

.mention-textarea-wrap__highlighter,
.mention-textarea-wrap__input {
  margin-top: 0 !important;
  margin-left: 0 !important;
  min-height: 48px;
  padding: 12px 16px;
  font-weight: inherit !important;
  border: 1px solid transparent;
  border-radius: 8px;
}

.mention-textarea-wrap__input {
  caret-color: $caret ;
  color: inherit !important;
  outline: none;
  line-height: inherit !important;
  font-weight: inherit;
  overflow: auto !important;
  background-color: $textarea-bg;

  &::placeholder {
    color: $textarea-placeholder !important;
  }

  &:focus {
    border-color: $input-border-active !important;
    outline: none;
    height: 100% !important;
  }

  &.form-error {
    border: 1px solid $input-error !important;

    + .comment-add-attachments-link {
        border-color: $lt-red !important;
        background: $lt-red !important;
        color: $level-1;
      }
  }
}

.mention-textarea-wrap {
  &.form-error-field {
    + .comment-add-attachments-link {
        border-color: $lt-red !important;
        background: $lt-red !important;
        color: $level-1;
      }
  }

  &.error {
    .mention-textarea-wrap__input {
      border-color: $lt-red !important;
    }
  }
}

.mention-textarea-wrap__suggestions {
  width: 100%;
  left: 0;
  /*top: calc(100% - 14px) !important;*/
  background-color: $dropdown-bg !important;
  z-index: 999 !important;
  margin-top: 6px !important;
  max-height: 160px !important;
  overflow: hidden;
  box-shadow: $dropdown-shadow;
  border-radius: 8px;
}
.mention-textarea-wrap__suggestions__list {
  width: 100%;
  max-height: 150px;
  border: solid 0 transparent;
  padding: 0px 15px;
  overflow-y: auto;
  background-color: transparent;
  border-radius: 8px;
  z-index: 2;
  cursor: auto;
  overflow-y: auto;
  height: 100%;

  .user-suggestion-listings-row {
    margin: 0;
    border-top: 0;
    padding:0 16px;
    list-style: none;
    cursor: pointer;
    font-size: 12px;
    line-height: 2.5rem;
    font-weight: normal;

    &:hover, &.focused {
      background-color: $dropdown-list-hover;
      color: $dropdown-hover-text;
    }
  }

  .mention-textarea-wrap__suggestions__item {
    .avatar {
      text-indent: 0;
      vertical-align: middle;
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
  }
}

.mention-tag {
  background-color: $level-5 !important;
  border-radius: 2px;
  position: relative;
  top: -1.5px;
}

.chat-detail,
.new-chat,
.conversations {
  .mention-textarea-wrap__highlighter {
    padding: 8px 66px 8px 16px;
  }
}

.conversation-input-section {
  .media-body {
    &.overflow-inherit{
      overflow: inherit !important;
    }
  }

  .mention-textarea-wrap__control {
    min-height: 48px;
  }

  .mention-textarea-wrap__highlighter {
    min-height: 48px;
  }

  .mention-textarea-wrap__input {
    min-height: 48px !important;
  }

  .mention-textarea-wrap__suggestions {
    top: auto !important;
    bottom: 100%;
  }

}

.detail-comments-top-block {
  .mention-textarea-wrap__suggestions {
    top: calc(100% - 6px) !important;
  }
}
.detail-comments-header {
  .mention-textarea-wrap__highlighter {
    padding-left: 65px;
  }
  .mention-textarea-wrap__input {
    padding-left: 65px;
  }
}

.floater-message-wrapper {
  .mention-textarea-wrap__control {
    font-size: 14px !important;
    line-height: 1.3;
  }
  .mention-textarea-wrap__highlighter,
  .mention-textarea-wrap__input {
    padding-left: 10px !important;
    padding-right: 124px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}

.mention-textarea-wrap {
  &.form-error-field {
    .mention-textarea-wrap__input {
      border: 1px solid $lt-red!important;
    }
  }
}

.mobile-device {

  &.portrait {
    .comment-reply-container {
      .mention-textarea-wrap__highlighter,
      .mention-textarea-wrap__input {
        font-size: 16px !important;
      }
    }
  }

  .comment-reply-container {
    .mention-textarea-wrap__highlighter,
    .mention-textarea-wrap__input {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
  }

  .mention-textarea-wrap__highlighter,
  .mention-textarea-wrap__input {
    max-height: 108px !important;
    overflow-y: auto !important;
    padding: 14px 16px 12px;
  }

  .mention-textarea-wrap__input {
    max-height: 108px !important;
    overflow-y: auto !important;
  }
  .conversation-input-section {
    .media-body {
      .mention-textarea-wrap__highlighter {
        padding-left: 50px;
        max-height: 108px !important;
        overflow-y: auto !important;
        margin-left: 0 !important;
      }
      .mention-textarea-wrap__input {
        margin-left: 0px !important;
        letter-spacing: 0px !important;
        max-height: 108px !important;
        overflow-y: auto !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
      }
    }
  }

  .conversations {
    .mention-textarea-wrap__suggestions {
      top: auto !important;
      bottom: 100%;
    }
  }
}

.modal-comment-mobile {
  .mention-textarea-wrap__control {
    min-height: 144px;
  }
  .mention-textarea-wrap__highlighter {
    min-height: 144px;
    padding: 7px 16px 7px 40px;
    margin-left: 0px !important;
    margin-top: 1px !important;
  }
  .mention-textarea-wrap__input {
    padding: 7px 16px 7px 40px;
    min-height: 144px !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    max-height: 100%;
  }

}

.ipad {
  .conversations, .floater-message-wrapper  {
    .mention-textarea-wrap__suggestions {
      top: auto !important;
      bottom: 100%;
    }
  }
}

.no-touch-support, .ipad {
  .mention-textarea-wrap__highlighter {
    max-height: 40vh !important;
  }

  .mention-textarea-wrap__input {
    max-height: 100% !important;
    overflow: auto !important;
    outline: none;
  }

  .floater-message-holder {
    .mention-tag {
      font-size: 14px;
    }
    .mention-textarea-wrap__highlighter {
      max-height: 80px !important;
      overflow-y: auto !important
    }
    .mention-textarea-wrap__input {
      max-height: 80px !important;
      overflow-y: auto !important
    }
  }
}

pre {
  font-family: $font-family-base;
  color: inherit !important;
}


@-moz-document url-prefix() {
  .mention-textarea-wrap__highlighter {
    &:focus {
      border-color: $level-10 !important;
    }
  }
}
