.partner-spaces-modal {
  .modal-dialog {
    max-width: 1117px;
  }

  .modal-content {
    padding: 40px 24px;
    width: 1000px;
  }

  .modal-header {
    padding: 0;

    h4 {
      font-family: Inter, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    span {
      top: 40px;
      right: 32px;
    }
  }


  .modal-body {
    padding: 0;

    .form-group-fields {
      display: grid;

      // three columns
      grid-template-columns: repeat(3, 1fr);

      // only two rows
      grid-template-rows: repeat(2, 1fr);

      // gap of 8px between rows and columns
      gap: 8px;

      // Make the additional emails field take up full width in the
      // Partner spaces invitation form
      .additional-email-field {
        grid-column: 1 / 4;
      }

      // make the last element take up all the three columns
      &>div:last-child {
        grid-column: 1 / 4;
      }

      .cell-row {
        &>div:first-child {
          position: relative;
          border: 1px solid #eff1f4;
          border-radius: 9px 0 0 9px;
          border-right-style: none;
          margin: 0 0 0 0;

          .os__control {
            background-color: white;
            border-radius: 9px 0 0 9px;


            &:active {
              border: 1px solid #4A749A;
            }

            .os__input-container {
              color: #354554;
            }

            .os__single-value {
              color: #354554;
              width: max-content;
            }
          }
        }

        &>div:last-child {
          border-radius: 0 8px 8px 0;
          border-left-style: none;
          #phoneNumber {
            padding-left: 4px;
            border-radius: 0 8px 8px 0;
            border: 1px solid #eff1f4;
            border-left-style: none;

            &:active, &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

.label-selector {
  .dropdown-menu {
    height: 280px;

    .loader {
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .os__control {
    min-height: 20px !important;
    overflow-y: hidden !important;
    border-radius: 12px !important;
  }

  .os__value-container {
    min-width: 230px;
    padding-right: 28px !important;
  }

  .os__indicators {
    &::after {
      top: 0% !important;
      color: #7E91A5;
    }
  }
}

.partner-space-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  h3 {
    margin: 0 0 0 8px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    font-family: Inter, sans-serif;
  }

  .selected-label {
    border-radius: 12px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;

    h2 {
      color: var(--neutrals-level-12, #222B35);
      font-family: Inter, 'sans-serif';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      margin: 0;
      line-height: 16px;
      text-align: center;
    }
    .clear-icon {
      display: flex;
      margin: 0 0 0 4px;
    }
  }

  .label-option-color {
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}


.partner-space-editor {
  padding: 24px 32px;
  margin: 16px 0;
  background: var(--surface-borders-level-1, #FFF);
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);

  .field-placeholder {
    background: var(--surface-borders-level-3, #F4F7F9);
    min-height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 24px;

    span {
      color: var(--neutrals-level-8, #7E91A5);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .tip-tap-editor-field {
    padding-bottom: 40px;
    border: 1px solid var(--neutrals-level-10, #4A749A);
    min-height: 180px;
    margin: 0;
    position: relative;

    .editor-template-selector {
      position: absolute;
      top: 6px;
      right: 0;
    }

    .editor-label-selector {
      position: absolute;
      bottom: 0px;

      .bootstrap-dropdown {
        width: fit-content;
        display: block;
        margin: 0;
      }
    }
  }

  .options {
    .editor-actions {
      display: flex;
      justify-content: space-between;

      .action-button {
        display: flex;
      }
    }
  }
}

// Partner spaces left side bar
#left_side_bar {
  .partners {
    .accordion-item {
      &:has(.show) {
        .accordion-header {
          background: var(--surface-borders-level-3, #F4F7F9);
        }
      }

      .accordion-header {
        .partner-space-listing-avatar {
          height: 16px !important;
          width: 16px !important;
          min-width: 16px !important;
        }
        .pinned-icon {
          display: none;
        }
        &:hover {
          .default-doctor-icon {
            display: none;
          }
          .pinned-icon {
            display: unset;
          }
        }
      }

      .accordion-body {
        .partner-subspace-list {
          margin: 4px 0;

          .active {
            background: var(--surface-borders-level-3, #F4F7F9);
          }

          .subspace-list-row {
            height: 32px;
            padding: 0 52px;
            display: flex;
            align-items: center;

            &:hover {
              background: var(--surface-borders-level-3, #F4F7F9);
              cursor: pointer;
            }

            .list-name {
              margin-left: 8px;
              color: var(--neutrals-level-9, #5D7893);
              font-family: Inter, sans-serif;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

// dropdown overlapping with day-dropdown-with-date css block
.xl-middle-bar {
  &:has(.show) {
    .activity-listing {
      z-index: -1;
    }
  }
}
