.edit-page,
.detail-page {
  .editor-container {
    .form-group {
      margin-bottom: 0;
    }
  }

  .space-links-container {
    .os-lg-user {
      .doc-card-footer {
        min-height: 56px;
      }
    }
  }
}
