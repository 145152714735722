.timeline {
  margin-top: 40px;
  padding: 0 35px 0 20px;

  .timeline-block-title {
    margin-top: 20px;
    color: $level-11;
    font-size: 16px;
    font-weight: 400;
  }

  .appliance-log {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0;
    $colors: (#2D74AC, #2a6df4, #488BBA, #5799C3, #71ABCD, #98c2db, #c1dff1, #e4eff6);
    @for $i from 1 through length($colors) {
      &:nth-of-type(#{$i}) {
        div.duration > div.occurance > div.occurance-line {
          background: nth($colors, $i);
        }
      }
    }

    .name {
      width: 190px;
      padding: 0 10px 0 20px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #fff;
      font-weight: $font-weight-bold;
      white-space: nowrap;
      .appliance-log-count{
        padding-left: 4px;
        display: inline;
      }
    }

    .duration {
      width: calc(100% - 190px);
      display: flex;
      position: relative;
      flex: 1;
      align-items: center;

      .base-line {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 12px;
        border-radius: 12px;
        width: 99%;
        background-color: $level-3;
      }

      .month-marker {
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #d2d2d2;
      }

      .show-icon{
        position: absolute;
        right: -35px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        height: 13px;
        width: 13px;
        font-size: 7px;
        line-height: 12px;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;

        &.active{
          border-color: #fff;
          color: #e7e7ea;
        }

        &.disabled{
          opacity: .5;
          pointer-events: none;
        }
      }

      .occurance {
        display: flex;
        position: absolute;
        top: 0;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .occurance-line {
          background-color: $level-10 !important;

          &:hover {
            background-color: $level-10 !important;
          }
        }

        &.hovered {
          .occurance-line {
            background-color: $os-link-hover !important;
            .case-treatment-tooltip {
              display: inline-block;
              @media only screen and (min-device-width : 320px) and (max-device-width : 1366px)  and (-webkit-min-device-pixel-ratio: 1) {
                display: none;
              }
            }
          }
        }

        .start-age, .end-age {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          font-size: 0.7em;
          height: 16px;
          width: 50px;
          color: #ccc;
          opacity: 0;
          transition: 100ms;
          z-index: 10;

          span {
            color: white;
          }
        }

        .start-age {
          left: -58px;
          text-align: right;
        }

        .end-age {
          right: -58px;
          text-align: left;
        }

        .occurance-line {
          height: 12px;
          position: relative;
          border-radius: 12px;
          transition: 100ms;
          background: $cl-link;

          .case-treatment-tooltip {
            display: none;
            bottom: 16px;
            background-color: #5c5c66;

            &::before {
              background-color: #5c5c66;
            }
          }

          // &:hover {
          //   .case-treatment-tooltip {
          //     display: inline-block;
          //   }
          // }
        }

        &:hover {
          .occurance-line {
            height: 9px;
          }

          .start-age, .end-age {
            opacity: 1;
          }
        }
      }
    }
    .appliance-list{
      width: 100%;

      .cards {
        margin-left: 0;
        margin-right: 0;
        .col-12 {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .appliance-info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 68px;
        margin-left: 190px;
        margin-bottom: 2px;
        cursor: pointer;
        &:hover {
          .appliance-name {
            background: #393939;
            .company-logo{
              background: #393939;
            }
          }
          .appliance-date {
            background: #484848;

            &::before {
              background: #393939;
            }
          }
        }
        &.company-info {
          height: 42px;

           .appliance-tool-image {
            width: 38px;
            max-height: 38px;
            margin-right: 30px;
          }

           .appliance-date {
            &::before {
              height: 42px;
            }
          }
        }
        .appliance-details-holder {
          width: 100%;
          display: flex;
        }
        .appliance-tool-image{
          object-fit: contain;
          width: 58px;
          max-height: 58px;
          margin-right: 10px;
          border-radius: 4px;
        }
        .appliance-name{
          font-size: 15px;
          width: 75%;
          height: 100%;
          padding: 5px;
          display: flex;
          align-items: center;
          border-radius: 2px 0px 0px 2px;
          transition: all .4s ease-in-out;
          box-shadow: -3px 3px 10px 0 rgba(0,0,0,0.1);
          background-color: #2a2a2a;
          position: relative;
          z-index: 99;
          .appliance-details-row{
            color: #FFFFFF;
            display: flex;
            align-items: center;
            width: 100%;
            .appliance-details{
              padding-right: 20px;
              display: flex;
              flex-direction: column;
              font-size: 14px;
              font-weight: $font-weight-bold;
              letter-spacing: .8px;
              .appliance-tool-name {
                line-height: 16px;
              }
              .appliance-company-name{
                font-size: 9px;
                letter-spacing: .66px;
                font-weight: $font-weight-bold;
                line-height: 12px;
                color: #DDD;
              }
              .appliance-details-points {
                margin-top: 2px;
                color: #71ABCD;
                font-size: 10px;
                font-weight: $font-weight-bold;
                letter-spacing: .66px;
              }
              .appliance-details-points-left {
                margin-right: 30px;
              }
            }
          }
          .company-logo{
            width: 40px;
            max-width: 40px;
            height: 40px;
            max-height: 40px;
            margin: 0;
            position: absolute;
            right: -16px;
            top: 14px;
            border-radius:  100%;
            padding: 0;
            display:  block;
            z-index:  100;
            background-color: transparent;
            transition: all .4s ease-in-out;
          }
        }
        .appliance-date{
          font-size: 13px;
          letter-spacing: .88px;
          height: 100%;
          width: 25%;
          border-radius: 0px 2px 2px 0px;
          transition: all .4s ease-in-out;
          box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
          background-color: #3a3a3a;
          position: relative;
          &::before {
            content: '';
            width: 34px;
            height: 68px;
            position: absolute;
            top: 0px;
            left: 0;
            z-index: 1;
            background: #2a2a2a;
            border-radius:0 68px 68px 0;
            transition: all .4s ease-in-out;
          }
          a{
            width: 100%;
            height: 100%;
            padding-right: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            z-index: 2;
            color: #fff;
          }
        }
      }
    }
    // Handle empty state
    &.appliance-log-empty{
      .name{
        color: $level-9;
      }
    }
  }

  .cbct-appliance {
     margin-top: 30px;

    &.timeline-points-holder {
      .cbct-appliance-row{
        .timeline-block-title {
          width: 190px;
          padding-right: 20px;
        }
      }
      .appliance-log {
        .appliance-list {
          margin-top: 0px;
        }
      }
    }
    .cbct-appliance-header{
      width: 170px;
      white-space: nowrap;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: $font-weight-lighter;
      padding-bottom: 12px;
      color: white;
    }
    .cbct-appliance-row{
      display: flex;
      justify-content: space-between;
      /*align-items: center;*/
      .cbct-appliance-name{
        width: 170px;
        padding-right: 20px;
        a {
          text-decoration: none;
          width: 100%;
          display: flex;
          font-size: 12px;
          font-weight: 400;
        }
        img{
          display: block;
          object-fit: contain;
          width: 50px;
          max-height: 50px;
          margin-right: 4px;
        }
      }
      .duration {
        width: calc(100% - 190px);
        display: flex;
        position: relative;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        .cbct-line {
          position: absolute;
          width: 100%;
          background-color: hsla(0,0%,100%,.15);
          height: 4px;
        }
        .cbct-line-dot{
          width: 8px;
          height: 8px;
          background-color: #fff;
          margin: 6px 0px;
        }
        .cbct-line-date{
          font-weight: $font-weight-light;
        }
        .cbct-line-header{
          font-weight: $font-weight-bold;
        }
        .cbct-line-t2{
          text-align: right;
          .cbct-line-dot{
            margin-left: auto;
          }
        }
      }
    }
  }
  .expand-all {
    color: #5c5c66;
    padding-left: 12px;
    padding-right: 20px;
    text-decoration: none;
    margin-top: -8px;
    cursor: pointer;
    text-transform: capitalize;
    text-align: right;
    i {
      left: auto;
      right: 0;
      font-size: 12px;
      transform: translateY(-50%);
    }

    &:hover, &.active, &:focus, &:active {
      background-color: transparent !important;
      color: #e7e7ea !important;
      text-decoration: none;

      i {
        color: white !important;

        @media screen and (max-width: 767px) {
          right: 4px;
        }
      }
    }

  }
  .hovered{
    .occurance-line {
      height: 9px !important;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    margin-top: 15px;
    padding: 0;

    .expand-all {
      margin-right: 0;
      font-size: 12px;
    }

    .timeline-block-title {
      font-size: 12px;
    }

    .appliance-log {
      width: 100%;
      flex-direction: column;

      .name {
        width: 100%;
        margin-bottom: 2px;
        padding-left: 15px;
        font-size: 10px;
        font-weight: $font-weight-bold;
      }

      .duration {
        width: 100%;
        height: 9px;

        .base-line {
          width: 100%;
        }

        .show-icon {
          right: 0;
          bottom: 12px;
        }
      }

      .appliance-list {
        .appliance-info {
          width: 100%;
          height: 49px;
          margin-left: 0;

          .appliance-tool-image {
            width: 39px;
            max-height: 39px;
          }

          .appliance-name {
            .appliance-details-row {
              .appliance-details {
                .appliance-tool-name {
                  font-size: 10px;
                }

                .appliance-company-name {
                  font-size: 9px;
                }

                .appliance-details-points {
                  font-size: 9px;
                }
              }
            }

            .company-logo {
              width: 49px;
              max-height: 49px;
              padding: 4px;
              top: 3px;
            }
          }

          .appliance-date {
            padding-left: 20px;
            font-size: 9px;

            &::before {
              width: 49px;
              height: 49px;
              top: 0;
              left: -28px;
            }
          }
        }
      }
    }
    .cbct-appliance {
      &.timeline-points-holder {
        .cbct-appliance-row {
          .timeline-block-title {
            width: 100%;
            margin-bottom: 5px;
          }

           .duration {
            width: 100%;

             .show-icon {
              bottom: 17px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    padding-right: 20px;

    .expand-all {
      margin-right: -20px;

      .ifill-os-chevron-down {
        top: 50% !important;
      }

      &.active {
        .ifill-os-chevron-down {
          left: auto;
          right: 5px;
        }
      }
    }

    .appliance-log {
      .name {
        width: 170px;
        font-size: 11px;
      }

      .duration {
        width: calc(100% - 170px);

        .show-icon {
          right: -22px;
        }
      }

      .appliance-list {
        .appliance-info {
          margin-left: 170px;

          .appliance-name {
            width: 72%;

            .appliance-details-row {
              .appliance-tool-image {
                margin-right: 7px;
              }

              .appliance-details {
                font-size: 11px;

                .appliance-tool-name {
                  line-height: 11px;
                }

                .appliance-details-points {
                  line-height: 10px;
                }

                .appliance-details-points-left {
                  margin-right: 5px;
                }
              }
            }
          }

          .appliance-date {
            width: 28%;
            padding-left: 23px;
            font-size: 11px;
          }
        }
      }
    }
    .cbct-appliance {
      &.timeline-points-holder {
        .cbct-appliance-row {
          .timeline-block-title {
            width: 170px;
          }

           .duration {
            width: calc(100% - 170px);
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (-webkit-min-device-pixel-ratio: 1) {

    .expand-all {
      .ifill-os-chevron-down {
        top: 50% !important;
        left: auto;
        right: 5px;
      }

      &.active {
        .ifill-os-chevron-down {
          left: auto;
          right: 5px;
        }
      }
    }
  }
}

.case-timeline-right-section {
  .timeline {
    margin: 0;
    padding: 0;

    @media screen and (max-width: 767px) {
      padding-right: 12px;
    }

    .timeline-block-container {
      .timeline-block-title {
        .expand-all {
          font-size: 12px;
          margin-right: 0;
        }
      }

      .icon-tool-placeholder-image {
        min-height: 40px;
      }

      .appliance-log {
        flex-direction: column;
        align-items: flex-start;
        margin: 16px 0;

        .name {
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          color: $level-11;
          margin-bottom: 8px;
          padding-left: 0;
          text-transform: capitalize;
        }

        .appliance-log-count {
          color: $level-11;
        }

        .duration {
          width: 100%;
          margin-top: 5px;

          .occurance {
            height: 12px;
            top: 0;
          }

          .show-icon {
            right:3px;
            top: -30px;
          }
        }

        .appliance-list {
          margin-top: 13px;
          .appliance-info {
            margin-left: 0;
            margin-bottom: 8px;
            height: 52px;
            .appliance-name {
              border-radius: 2px 50px 50px 2px;
              width: 100%;
              .company-logo {
                right: 9px;
                width: 35px;
                max-width: 35px;
                height: 35px;
                max-height: 35px;
                top: 8px;
              }

              .appliance-details-row {
                .appliance-details {
                  width: calc(100% - 100px);
                  padding-right: 0px;
                  .appliance-tool-name {
                    font-size: 12px;
                    letter-spacing: 0.7px;
                    margin-bottom: 3px;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    a{
                      color: #fff;
                      padding-left: 3px;
                    }
                  }
                  .appliance-details-points{
                    margin-top: 0px;
                  }
                }
              }
            }

            .appliance-tool-image {
              width: 40px;
              max-height: 40px;
            }
            .appliance-date{
              display: none;
            }
          }
        }
      }
      .cbct-appliance {
        &.timeline-points-holder {
          .appliance-log {
            .appliance-list {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .timeline-right-section {
      max-height: none;
      margin-top:10px;
      .timeline-block-container {
        .timeline-block-title {
          font-size: 12px;
        }

        .appliance-log {
          .duration {
            .occurance {
              top: 0px;
              height: 8px;
            }
          }
          .name {
            text-transform: capitalize;
            font-weight: $font-weight-normal;
          }
          .appliance-list {
            .appliance-info {
              .appliance-name {
                .appliance-details-row {
                  .appliance-details {
                    width: calc(100% - 100px);
                    .appliance-tool-name {
                      margin-bottom: 3px;
                      line-height: 12px;
                      max-width: 100%;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    }
                    .appliance-company-name {
                      line-height: 9px;
                      font-weight: normal;
                    }
                  }
                }
                .company-logo {
                  padding: 0;
                  top: 6px;
                  right: 7px;
                }
              }
            }
          }
        }
      }
    }

    &.prioritized-listing {
      .case-treatment-right-section {
        margin-top: 0;
        .timeline-right-section {
          max-height: none;
          margin-top: 0;
        }
      }
    }
  }
}

.case-treatment-tooltip {
  min-width: 100px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  position: absolute;
  bottom: 19px;
  left: 0;
  color: #ccc;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  background-color: $block;
  border-radius: 8px;
  box-shadow: none;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    margin-left: -4px;
    position: absolute;
    left: 50%;
    bottom: -4px;
    background: $block;
    box-shadow: none;
    transform: rotate(45deg);
  }

  &.left {
    transform: translateX(-50%);
  }

  &.right {
    left: auto;
    right: 0;
    transform: translateX(50%);
  }

  &.without-label {
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 0 transparent;
    z-index: 100;
    &::before {
      box-shadow: 0 0 0 transparent;
    }

    .case-treatment-tooltip-content {
      padding: 3px 8px;
    }
  }

  .case-treatment-tooltip-label {
    height: auto;
    padding: 0 6px;
    color: #fff;
    font-weight: $font-weight-bold;
    background: #29292e;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .case-treatment-tooltip-content {
    height: 30px;
    padding: 3px 8px;
    display: block;

    span {
      display:block;

      + span {
        white-space: nowrap;
      }
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    &.right {
      right: 5px;
    }
  }
}

.mobile-device:not(.iphone){
  .base-line {
    bottom: -8px !important;
  }
}
