.create-space-slider {
  margin-bottom: 32px;

  .slick-arrow {
    width: 48px;
    height: 40px;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    z-index: 1;
    color: #fff;

    &:before {
      font-family: 'fill-os-icon';
      font-size: 16px;
      color: inherit;
    }
  }

  .slick-prev {
    left: 4px;

    &:before {
      content: "\e904";
    }
  }

  .slick-next {
    right: 4px;

    &:before {
      content: "\e905";
    }
  }

  .slick-list {
    border-radius: 8px;
    padding-left: 0px !important;  // Note: Partial slide hack
  }

  .slick-slide {
    div > div {
      vertical-align: top;
      cursor: pointer;
      height: 120px;
      position: relative;

      &:after {
        border: solid 2px $cl-link;
        border-radius: 2px;
        transition: all 200ms ease;
        opacity: 0;
      }

      &:before,
      &:after {
        content :'';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      &:before {
        background: #121214;
        opacity: 0;
      }

      &.active {
        cursor: default;
        overflow: hidden;
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
          z-index: 1;
          visibility: visible;
        }

        img {
          border-radius: 2px;
        }
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &.first-visible {
      .active {
        &:after,
        img {
          border-radius: 8px 2px 2px 8px;
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .space-slider-image-heading {
    padding-left: 4px;
    padding-bottom: 16px;
  }

  .create-space-slider {
    margin-right: -12px;
    margin-left: -12px;
    margin-bottom: 30px;

    .slick-arrow {
      width: 40px;
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }

    .slick-slide div > div {
      height: 80px;
    }
  }
}

.no-touch-support {
  .create-space-slider {
    .slick-arrow:hover {
      background: rgba(72, 172, 253, 0.2);
      color: $os-link;
    }
  }
}
