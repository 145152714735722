%search-result-item {
  cursor: pointer;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  span {
    width: 50%;
    text-align: right;
  }
}

%device-search-category-option {
  border: 0;
  top: 45px;
  right: 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  z-index: 9;
  padding: 0;

  .search-category-option {
    font-size: 13px;
    padding: 0 16px;
    line-height: 32px;

    &.selected {
      background: $cl-link;
    }
  }
}

.nav-cmd-launcher-icon {
  margin: 1px 16px 0 4px;
  zoom: 1.45;
  display: grid;
  cursor: pointer;
  place-items: center;
  color: #B9C4CF;
  position: relative;
  &:hover {
	color: #4A749A;
  }
}

.nav-cmd-launcher-icon:hover{
  .nav-tooltip {
    &::after {
      content: attr(data-tooltip);
      font-size: 6.5px;
      padding: 0 3px;
      color: $level-10;
      position: absolute;
      left: -22px;
      top: 22px;
      white-space: nowrap;
      z-index: 20;
      border-radius: 2px;
      background: $block;
      line-height: 16px;
      box-shadow: $block-shadow-xs;
    }
  }
}

.nav-setting-block:hover{
  .nav-tooltip {
    &::after {
      content: attr(data-tooltip);
      font-size: 10px;
      padding: 0 5px;
      color: $level-10;
      position: absolute;
      left: -22px;
      top: 100%;
      white-space: nowrap;
      z-index: 20;
      border-radius: 2px;
      background: $block;
      line-height: 20px;
      box-shadow: $block-shadow-xs;
    }
  }
}

.navbar {
  height: 64px;
  box-sizing: content-box;
  padding: 0;
  background-color: $nav-bg;
  display: block;
  box-shadow: 0px 1px 2px 0px rgba(53, 69, 84, 0.24), 0px 1px 3px 0px rgba(53, 69, 84, 0.12);

  .mr-32 {
    margin-right: 32px;
  }

  .os-main-nav-inner {
    padding: 0 48px;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
  }


  .see-all {
    color: $level-11;
  }

  .nav-setting-block {
    .os-btn-link {
      color: $level-6;
      font-size: 23px;
    }
  }

  .nav-search-block, .nav-setting-block {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @extend %hover-active-dropdown;
    position: relative;
    margin-right: 17px;
    cursor: pointer;

    .os-btn-link {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        text-decoration: none;
      }
    }

    .ifill-os-search {
      font-size: 23px;
      color: $nav-link;
    }

    &:hover {
      .ifill-os-search {
        color: $nav-link-icon-hover;
      }
    }

    &:active, &:focus {
      .ifill-os-search {
        color: $nav-link-active;
      }
    }

    &.active {
      .ifill-os-settings {
        color: $nav-link-active;
      }
    }
  }

  .site-nav {
    height: 40px;
    padding: 0;
    align-items: center;

    .nav-item {
      width: 40px;
      height: 40px;
      &:not(:last-of-type) {
        margin-right: 24px;
      }

      .site-nav-text {
        display: none;
      }

      .icon-nav {
        &:after {
          content:'';
          width: 4px;
          height: 4px;
          background-color: $nav-link-icon-hover;
          border-radius: 50%;
          position: absolute;
          top: 90%;
          left: 50%;
          margin-left: -2px;
          opacity:0;
        }
      }

      .notification-available {
        &:after {
          content:'';
          width: 4px;
          height: 4px;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          top: 90%;
          left: 50%;
          margin-left: -2px;
          opacity:1;
          z-index: 1000;
        }
      }
    }
    .nav-link {
      @include fontstyle(24px, normal, 24px);
      text-transform: uppercase;
      padding: 0;
      color: $nav-link;
      position: relative;
      transition: 500ms;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;


      &.active {
        color: $nav-link-active;
        position: relative;
        transition: 500ms;
        text-decoration: none;
        &::after {
          transition: 500ms;
          opacity: 1;
        }

        .icon-nav {
          &:after {
            transition: 500ms;
            opacity: 1;
            background-color: $nav-link-active;
          }
        }
      }

      &:hover {
        color: $nav-link-icon-hover;
        position: relative;
        transition: 500ms;
        text-decoration: none;

        &::after {
          transition: 500ms;
          opacity: 1;
        }

        .icon-nav {
          &:after {
            transition: 500ms;
            opacity: 1;
          }
        }
      }

      .ifill-os-fill-folders-bookmark {
        font-size: 20px;
      }
    }
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    margin-right: 40px;
    padding: 0;

    .logo {
      .logo-primary {
        max-width: 24px;

        &.with-text {
          max-height: 24px;
          max-width: unset;
        }
      }

      .logo-workspace {
        max-height: 50px;
      }

      .logo-secondary {
        display: none;
      }
    }
  }
  .onboard-account {
    .down-arrow {
      margin-left: 6px;
    }
  }
  &.navbar-unauth {
    z-index: 10;

    .os-main-nav-inner {
      justify-content: space-between;
      width: 100%;
    }

    &.nav-transparent {
      background-color: transparent;
    }

    .navbar-unauth-text {
      color: $level-11;
    }

    .nav-profile__link {
      font-weight: $font-weight-bold;
    }

    .navbar-brand {
      padding: 0;
    }
  }
  .navbar-nav{
    flex-direction: row;

    .ifill-os-fill-course {
      padding-top: 3px;
    }

    &.header-notifications {
      align-items: center;
      margin-right: 30px;
    }

    .nav-search-holder {
      .ifill-os-search {
        position: relative;
        left: 0;
        font-size: 24px;
        line-height: 24px;
        color: $nav-link-icon;
      }

      &:hover, &:active, &:focus {

        &:after {
          opacity: 1;
        }
        .ifill-os-search {
          color: $nav-link-icon;
          margin-left: 2px;
        }
      }
    }

  }
}
.logo .logo-beta {
  border: 0.5px solid #979797;
  border-radius: 1.5px;
  color: $level-1;
  font-size: 8px;
  font-weight: $font-weight-light;
  text-align: center;
  display: inline;
  text-transform: uppercase;
  margin-left: 18px;
  padding: 3px 3px 2px 3px;
}
.nav-search {
  position: relative;
  color: $level-11;
  width: 100%;

  .input-wrapper {
    margin-left: auto;
    width: 540px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    input[type='search'].form-control{
      background-color: transparent;
      border: none;
      font-size: 15px;
      font-weight: $font-weight-bold;

      &::placeholder {
        color: $textarea-placeholder;
      }
    }

    .nav-search-container {
      position: relative;
      padding-left: 35px;
      border: 1px solid $input-border;
      box-shadow: none;
      width: 60%;
      transition: 500ms;
      background-color: $input-bg;
      border-radius: 4px;

      .ifill-os-close{
        display: none;
      }
    }
  }
  .ifill-os-search {
    position: absolute;
    top: 8px;
    left: 15px;
    color: $input-icon;
  }

  .search-category{
    position: absolute;
    top: 0px;
    right: 35px;
    width: 110px;
    font-size: 15px;
    color: $level-1;
    cursor: pointer;
    border: 1px solid $level-5;
    border-bottom: none;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 14px;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    i {
      position: absolute;
      left: 74px;
      top: 10px;
      color: $input-icon;
    }
    &.input-focus{
      border: 1px solid $input-border-active;
      box-shadow: $input-shadow;
    }
  }

  .search-category-options{
    position: absolute;
    z-index: 10;
    top: 34px;
    right: 35px;
    width: 110px;
    color: $level-1;
    background-color: $block;
    border: 1px solid $cl-link;
    border-radius: 4px;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-left: 14px;
    box-shadow: 0 20px 30px 0 rgba(0,0,0,0.5);

    .search-category-option{
      cursor: pointer;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .search-results {
    position: absolute;
    z-index: 10;
    top: 34px;
    left: 0;
    right: 0;
    color: $level-11;
    background-color: transparent;
    border-radius: 0px;
    border-top: none;

    .no-results {
      padding: 15px;
      font-style: italic;
    }

    .search-group{
      border-bottom: 1px solid $level-10;
      margin: 0px 30px 0px 44px;
    }

    .search-group-headding{
      padding-top: 10px;
      padding-bottom: 2px;
      font-size: 13px;
      color: $level-9;
    }

    .search-view-all{
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: $os-link;
      text-transform: uppercase;
      padding: 0;
      margin: 0 0 14px;
      cursor: pointer;

      &:hover {
        background: $level-10;
      }
    }

    .search-result {
      padding: 10px 30px 10px 44px;
      margin: 0 -30px 0 -44px;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .clear-input{
    color: $level-11;
    position: absolute;
    right: 10px;
    top: 6px;
    cursor: pointer;
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .search-category-options {
      border: 0;
      top: 45px;
      right: 20px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      z-index: 9;
      padding: 0;

      .search-category-option {
        font-size: 13px;
        padding: 0 15px;
        line-height: 32px;

        &.selected {
          background: $cl-link;
        }
      }
    }
  }
}

.nav-item {
  color: $nav-link;

  .notification-count {
    position: absolute;
    left: 20px;
    background: $lt-notification-bg;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    flex-grow: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $level-1;
    min-height: 16px;
    min-width: 16px;
    padding: 2px;
  }
}


.nav-profile {
  position: relative;
  z-index: 1050;

  .nav-profile__toggle {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
    z-index: 1;

    &.active {
      .ifill-os-chevron-caret-down {
        transform: rotate(-180deg);
      }

      .nav-avatar-profile {
        position: relative;
      }
    }

    .ifill-os-chevron-caret-down {
      font-size: 16px;
    }

    .nav-profile__user-photo {
      width: 32px;
      height: 32px;
      border-radius: 40px;
      font-size: 16px;
      line-height: 30px;
      margin-top: 1.5px;

      .icon-span {
        @include icon-span(16px);

        .header-list-icon {
          margin-right: 0;
          font-size: 10px;
        }
      }

      &.hidden-mobile {
        max-height: 32px;
      }

      &:not(.visible-mobile) {
        display: flex;
        justify-content: center;
      }
    }


    .ifill-os-chevron-caret-down {
      display: block;
      margin-left: 8px;
      position: static;
      transform: none;
    }
  }

  &.header-dropdown-menu .page-heading-title {
    padding: 0;
  }

  .nav-profile__dropdown-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background: rgba(0, 0, 0, 0.3);
  }
}


.nav-messages-block {
  position: relative;
  cursor: pointer;
  margin-right: 17px;

  > a {
    display: block;
    max-height: 24px;
  }

  .ifill-os-messages {
    font-size: 23px;
    margin-left: -2px;
    color: $nav-link;
  }

  .btn-link-helper {
    z-index: 1;
  }
}

.message-count {
  @include fontstyle(12px, 600, 16px);
  position: absolute;
  top: -2px;
  color: $lt-notification-text;
  background-color: $lt-notification-bg;
  min-width: 8px;
  max-width: 24px;
  height: 16px;
  border-radius: 6px;
  text-align: center;
  right: auto;
  left: 22px;
  z-index: 1;
  padding: 0 4px;
}

.no-touch-support, .ipad {
  .nav-messages-block {
    &.with-count {
      margin-right: 10px;
      padding-right: 14px;
      width: 54px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      div {
        padding-right: 18px;
      }

      .message-count {
        min-width: 16px;
        display: inline-block;
        top: 3px;
      }

    }
  }

  .navbar-unauth-text {
    .osbtn {
      padding: 0px 16px;
    }
  }

  .navbar {
    .navbar-nav {
      &.header-notifications {
        margin-right: 25px;
      }
    }
  }
  &.desktop-hd {
    .nav-messages-block {
      &.with-count {
        margin-right: 3px;
      }
    }

    .navbar {
      .navbar-nav {
        &.header-notifications {
          margin-right: 0;
        }
      }

      .nav-profile {
        margin-left: 17px;
      }
    }
  }
}

.ipad {
  .nav-messages-block {
    &.with-count {
      margin-right: 10px;
      padding-right: 14px;
      width: 54px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      div {
        padding-right: 18px;
      }

      .message-count {
        display: inline-block;
        top: -6px;
        left: 14px;
      }

    }
  }
}

.no-scroll{
  overflow-y: hidden;
  max-height: 100vh;
}

.nav-messages-block,
.notification-list-item  {
  line-height: 1;
  width: 40px;
  height: 40px;
  color: $nav-link;

  &.active {
    color: $nav-link-active;
  }

  &.no-count {
    .os-btn-link {
      width: inherit;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ifill-os-messages,
  .ifill-os-notification {
    vertical-align: middle;
    line-height: inherit;
    color: $nav-link;
  }

  .icon-os-notification {
    color: $level-8;
  }

  .nav-item {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notifications-count {
    top: 3px;
    right: -11px;
  }

  .notification-dropdown {
    > .empty-class {
      width: inherit;
      height: inherit;

      .dropdown-toggle {
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}



.mobile-device {
  .navbar {
    height: 64px;
    padding: 0 20px;
    display: flex;
    flex-wrap: initial;
    position: sticky;
    top: 0;
    z-index: 999;

    input[type='search']::placeholder {
      opacity: .5;
    }

    .navbar-brand {
      margin-right: 0;
      padding-top: 0;
      padding-bottom: 0;
      display: inline-block;

      .logo {
        padding-right: 20px;
      }

      .visible-mobile {
        .logo-mobile {
          width: 36px;
          max-width: 36px;
          height: 36px;
        }
      }
    }

    .nav-messages-block {
      display: none;
    }

    .mobile-header-search {
      width: calc(100% - 180px);
      position: relative;
      &.mobile-header-focus {
        min-width: 160px;
      }

      .form-control {
        box-shadow: none;
        min-height: unset;
        height: auto;
        box-shadow: none;
      }

      .mobile-search-input {
        width: 100%;
        height: 33px;
        border: none;
        padding: 0 5px 0 40px;
        color: $level-1;
        font-size: 14px;
        background: #282828;

        &:focus {
          outline: none;
        }
      }

      .nav-search {
        height: 33px;
        background: $nav-bg;

        .ifill-os-search {
          width: 16px;
          height: 16px;
          display: inline-block;
          top: 8px;
        }

        .input-wrapper {
          width: 100%;

          &.input-focus {
            width: 100%;
            height: 64px;
            max-width: none;
            margin-left: 0;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            background: #282828;

            .nav-search-container {
              padding-left: 60px;
              transition: initial;
            }

            .ifill-os-search {
              left: 27px;
              top: 25px;
            }

            input[type='search'] {
              width: calc(100% - 120px);
              height: 64px;
              padding-left: 50px;
            }

            .search-category {
              width: 70px;
              border: none;
              padding-left: 0;
              display: block;
              top: 18px;
              right: 45px;
              font-size: 13px;

              i {
                left: 52px;
              }
            }

            .ifill-os-close {
              display: inline-block;
              position: absolute;
              top: 27px;
              right: 15px;
              cursor: pointer;
              font-size: 12px;
            }
          }

          .nav-search-container {
            width: 100%;
            border: none;
            background: transparent;
            transition: initial;
          }

          input[type='search']{
            &.form-control {
              padding: 7px 5px;
              font-size: 14px;
              font-weight: $font-weight-light;
              transition: initial;
            }
          }

          .ifill-os-close {
            display: none;
          }
        }

        .search-category,
        .clear-input {
          display: none;
        }

        .search-results {
          width: 100%;
          max-height: 300px;
          border: none;
          overflow-x: hidden;
          overflow-y: auto;
          position: fixed;
          top: 64px;
          left: 0;
          z-index: 11;
          background-color: $block;

          .search-group {
            margin: 0px 30px;
            padding-bottom: 5px;
          }

          .search-group-headding {
            padding-bottom: 0;
            font-size: 11px;
            letter-spacing: .5px;
          }

          .search-result {
            font-size: 12px;
          }

          .search-view-all {
            display: none;
          }
        }
      }
    }



    .mobile-header-communication {
      position: relative;
      top : 4px;
      line-height: 1;

      + .header-notifications {
          top: 4px;
        }

      a {
        font-size: 20px;
      }

      .message-count {
        position: absolute;
        top: -13px;
        right: -18px;
        font-size: 10px;
      }
    }

    .navbar-nav {
      margin-top: 0;

      &.site-nav {
        width: 100%;
        height: 72px;
        flex-direction: row;
        justify-content: space-around;
        position: fixed;
        padding-top: 10px;
        bottom: 30px;
        left: 0;

        &::after {
          content: '';
          width: 100%;
          height: 31px;
          position: fixed;
          bottom: -1px;
          left: 0;
        }

        .nav-item {
          margin-right: 0;

          .nav-link {
            flex-direction: column;
            justify-content: flex-start;
            color: $level-1;
            font-size: 11px;
            opacity: .6;

            &::after {
              display: none;
            }

            &.active {
              opacity: 1;
            }
          }
        }



        .icon-nav {
          min-height: 19px;
          margin-bottom: 5px;
          display: inline-block;
          font-size: 18px;
        }
      }

      &.header-notifications {
        margin: 0 20px;
        position: relative;

        .notification-dropdown {
          .dropdown-toggle {

            i {
              &.ifill-os-notification {
                top: 0;
                font-size: 18px;
              }
            }
          }
        }

        .notifications-count {
          position: absolute;
          top: -13px;
          font-size: 10px;
        }
      }

      &.nav-profile {
        padding-right: 0;
        z-index: 9;

        .nav-profile__user-photo {
          border-width: 1px;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        div {
          &.avatar {
            font-size: 9px;
            line-height: 24px;
          }
        }

        .nav-profile__dropdown {
          width: 160px;
          top: 45px;
          right: 0;

          .nav-profile__user {
            display: none;
          }

          .nav-profile__links {
            padding: 15px 0;
          }
        }
      }
    }
  }


  &.landscape {
    .navbar {
      .navbar-nav.site-nav {
        height: 60px;
        bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }
}


@media only screen and (max-device-width : 340px) {
  .navbar {
    padding-right: 5px;
    padding-left: 5px;

    .navbar-brand {
      .logo {
        padding-right: 10px;
      }
    }

    .mobile-header-search.mobile-header-focus {
      min-width: 150px;
    }
  }
}



@media only screen and (min-width: 768px) and (max-width: 1079px) {
  .nav-profile {
    padding: 0;

    .nav-profile__toggle {
      font-size: 12px;
    }

    .nav-profile__dropdown {
      top: 45px;
      right: 0px;

      .nav-profile__user {
        .nav-profile__user-info {
          white-space: normal;
        }
      }
    }
  }

  .notification-dropdown {
    .dropdown-toggle {
      position: relative;
      font-size: 11px;

      i {
        &.ifill-os-notification {
          font-size: 15px;
          white-space: nowrap;
        }
      }
    }
  }

  .nav-search {
    .input-wrapper {
      width: 450px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {
  .navbar {
    .header-notifications {
      margin-left: 10px;
    }

    .notification-dropdown {
      .dropdown-toggle {
        white-space: nowrap;
      }
    }

    .nav-profile {
      padding: 0 10px 0 0;
    }
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar {
    .navbar-nav {
      &.nav-profile {
        position: relative;
      }
    }
  }

  .nav-profile {
    .nav-profile__toggle {
      font-size: 12px;
    }

    .nav-profile__dropdown {
      top: 45px;
      right: 0px;

      .nav-profile__user {
        .nav-profile__user-info {
          white-space: normal;
        }
      }
    }
  }

  .notification-dropdown {
    .dropdown-toggle {
      position: relative;
      font-size: 11px;

      i {
        &.ifill-os-notification {
          font-size: 15px;
          white-space: nowrap;
        }
      }
    }
  }

  .nav-search {
    .input-wrapper {
      width: 450px;
    }
  }
}

@media only screen and (min-width : 768px) and (max-width : 1170px) {
  .no-touch-support{
    .navbar {
      height: 80px;

      .os-main-nav-inner {
        padding: 0 32px;
      }
    }
  }
}

.ipad {
  .navbar {
    .navbar-brand {
      margin-right: 32px;
    }

    .site-nav {
      .nav-item:not(:last-of-type) {
        margin-right: 50px;
      }
    }

    .navbar-nav.header-notifications {
      margin-right: 24px;
    }
  }

  &.portrait {
    .navbar {
      .os-main-nav-inner {
        padding: 0px 24px;
      }

      .navbar-brand {
        .logo {
          .logo-primary {
            max-width: 88px;
          }
        }
      }

      .site-nav {
        .nav-item {
          margin-right: 24px;
        }
      }
    }
  }
}

@media only screen and (min-width : 1441px) and (-webkit-min-device-pixel-ratio: 1) {
  .navbar {
    .notification-dropdown {
      margin-left: 0;

      .dropdown-toggle {
        padding-right: 0;
      }
    }

    .navbar-nav {
      .nav-search-block {
        padding-right : 8px;
      }
    }
  }
}

.no-touch-support .navbar {
  .nav-messages-block:hover .ifill-os-messages,
  .see-all:hover .ifill-os-notification {
    color: $nav-link-icon-hover;
  }
}

.mobile-device {
  &.hidden-more {
    overflow-y: hidden;
    .layout {
      min-height: 100%;
      height: 100%;
      overflow: hidden;
      backface-visibility: hidden;
      padding-bottom: 0;
    }

    &.landscape {
      .layout {
        padding-bottom: 0;
      }
    }
    body {
      overflow-y: hidden;
      height: 100%;
      &.tools {
        padding-bottom: 0;
      }
      #root {
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
    .new-conversation-block {
      z-index: 9 !important;
    }

    .mobile-nav-bar {
      > div {
        height: calc(100vh - 70px);
        padding-bottom: 50px;
        border-top: 1px solid $border-button-1;

      }
    }
  }
  .nav-search  {
    .search-category-options {
      @extend %device-search-category-option;
      z-index: 11;
      right: 40px;
    }
    .input-wrapper {
      width: 100%;
      min-height: 40px;
    }
    .nav-search-container{
      width: 100%;
      padding-left: 0px;
      background-color: transparent;
      border: 0px;
      .ifill-os-close {
        display: inline-block;
      }
    }
    input[type='text']{
      &.nav-search-input {
        padding: 8px 80px 8px  45px;
        max-width: calc(100% - 40px);
        border: 1px solid transparent;
        line-height: 26px;
        font-size: 15px;
        font-weight: 600;
        min-height: 40px !important;
        padding-left: 40px !important;

        &:focus {
          border-color: $os-link;
        }
      }
    }
    .clear-input {
      top: 13px;
      color: $level-11;
      right: 5px;
    }
    .search-results {
      background-color: $block;
      box-shadow: $box-shadow;
      top: 40px;
      border: 0;
      width: calc(100% - 40px);
      max-height: 300px;
      overflow-y: auto;

      .search-group {
        margin: 0 12px;
        border-bottom: 0;
      }

      .search-result {
        padding: 10px 0;
        margin: 0;
      }
    }
  }
  .search-category {
    border: 0;
    font-size: 12px;
    padding-top: 11px;
    padding-bottom: 11px;
    right: 40px;
    width: 90px;
    padding-right: 38px;
    text-align: right;
    i {
      left: 62px;
      font-size: 16px;
      top: calc(50% - 8px);
    }
  }
  .ifill-os-search:not(.icon-nav) {
    font-size: 24px;
    left: 12px;
    top: 50% !important;
    transform:  translateY(-50%);
  }
  .mobile-header-search {
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0;
    padding: 16px 20px 0px;
    z-index: 9;
    background-color: $block;

    .search-group-icon {
      display: none;
    }
    .search-group-info-icon {
      display: none;
    }
  }
  .mobile-nav-bar {
    position: sticky;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    box-shadow: $block-shadow-xs;
    > div {
      height: inherit;
    }
  }
  .site-nav {
    flex-direction: row;
    justify-content: space-around;
    background: $nav-bg;

    .nav-item {
      margin-right: 0;

      .nav-link {
        flex-direction: column;
        align-items: center;
        color: $level-8;
        font-size: 12px;
        padding: 12px 0 8px;
        position: relative;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;


        &::after {
          display: none;
        }

        &.active {
          opacity: 1;
          color: $level-12;
        }

        .site-nav-text {
          display: block;
          width: 100%;
        }
      }

      &.feed {
        .icon-nav {
          font-size: 20px;
          margin-left: -12px;
        }

        .ifill-os-chevron-caret-down {
          display: inline-block;
          position: absolute;
          right: -9px;
          top: 20px;
          font-size: 14px;
        }

        .site-nav-text {
          position: relative;
          top: 2px;
        }
      }
    }

    .ifill-os-chevron-caret-down {
      display: none;
    }

    .icon-nav {
      display: inline-block;
      font-size: 24px;
    }
  }

  .head-sticky{
    &.fixed {
      &:before {
        content: none;
      }
    }
  }

  .nav-more-dropdown {
    position: absolute;
    top: 70px;
    height: calc(100% - 70px);
    background-color: $dropdown-bg;
    width: 100%;
    z-index: 999;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .ifill-os-search {
      font-size: 16px;
    }
  }

  .more-dropdown-list, .workspace-more-dropdown-list {
    padding-left: 0;
    padding-top: 8px;
    margin-bottom: 0;

    .nav-item {
      &:empty {
        height: 40px;
      }
    }
    .nav-profile__user-info {
      font-size: 20px;
      line-height: 1.4;
      white-space: normal;
      padding: 12px 24px 30px;

      .user-name {
        color: $level-11
      }
    }

    &.mt-72 {
      .nav-item:last-child {
        .nav-link {
          border-bottom: 0;
        }
      }
    }

    + .more-dropdown-list {
      // margin-top: 72px;
      padding-top: 0;
    }

    .nav-link {
      color: $dropdown-text;
      padding: 12px 20px;
      border-bottom: 1px solid $border-button-1;
      font-size: 16px;
      display: flex;
      align-items: center;

      &.active {
        background-color: $dropdown-list-hover;
        color: $dropdown-hover-text;
      }

      .icon-nav {
        font-size: 24px;

        &.ifill-os-fill-folders-bookmark {
          font-size: 20px;
        }
      }

    }
    .site-nav-text {
      padding-left: 16px;
    }

    /*.icon-os-qlark {
      width: 24px;
      height: 24px;
      background-size: 24px auto;
      opacity: 0.9;
    }*/
  }
  .mobile-notification-count {
    font-size: 12px;
    font-weight: bold;
    color: $lt-notification-text;
    background-color: $lt-notification-bg;
    min-width: 25px;
    height: 20px;
    border-radius: 8px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    margin-left: 8px;
    padding: 0px 4px;
  }

  .nav-profile {
    .nav-profile__toggle.active {
      .nav-profile__user {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: 17px;
          left: 50%;
          transform: translateX(-50%);
          border: 10px solid transparent;
          border-bottom-color: $block;
          z-index: 1;
        }
      }

    }

    .nav-profile__dropdown {
      right: 10px !important;
      top: 37px !important;
    }
  }

  &.portrait {
    .nav-profile {
      padding-right: 16px !important;
    }
  }

  body {
    &.team-direct-messenger {
      .mobile-nav-bar {
        display : none;
      }
    }
  }
}


.no-touch-support {
  .os-main-nav {
    &.navbar {
      .site-nav {
        height: 40px;
        margin-top: 0px;
      }
    }
  }
}

.mobile-device {
  &.guest-layout,
  &.unauthorized-session {
    .navbar-unauth-text {
      .osbtn {
        padding: 0px 16px;
        margin-left: 0;
        font-weight: normal;
        line-height: 38px;
      }
    }
  }

  .nav-item.more {
    position: relative;
  }

  .m-notifications-count {
    @include fontstyle(8px, bold, 12px);
    position: absolute;
    top: 12px;
    color: $lt-notification-text;
    background-color: $lt-notification-bg;
    min-width: 16px;
    max-width: 35px;
    height: 12px;
    border-radius: 8px;
    text-align: center;
    right: -6px;
    padding: 0px 6px;
  }

  .nav-item.messages {
    .m-notifications-count {
      right: 0;
    }
  }

  .mr-m-16 {
    margin-right: 16px;
  }

  .workspace-icon-logo {
    font-size: 24px;

    + span {
      padding-left: 16px;
    }
  }

  .workspace-title-list {
    padding: 12px 48px 12px 20px;

    .ifill-os-chevron-right {
      right: 20px;
      margin-top: 2px;
      position: absolute;
    }

    .ifill-os-chevron-left {
      margin-top: 4px;
      margin-right: 16px;
      float: left;
    }
  }

  .workspace-more-dropdown-list {
    .more-dropdown-list {
      margin-bottom: 0;
    }

    .nav-link {
      @include text-truncate();
      max-width: 100%;
      display: flex;
    }

    img {
      max-width: 100px;
      height: 27px;
    }
  }
}


.ipad {
  &.portrait:not(.unauthorized-session) {

    .logo-secondary  {
      display: none;
      max-width: 40px;

      img {
        max-width: 40px;
      }
    }

    &.unauthorized-session {
      .logo-secondary {
        display: none;
      }
    }
  }
}

.mobile-device {
  .navbar-unauth {
    .os-main-nav-inner {
      padding: 0px ;

      .nav-profile {
        padding-right: 0 !important;
      }

      .user-profile__dropdown {
        width: 150px;
      }
    }
  }
}


.universalBannerWrapper ~ {
  .mobile-nav-bar {
    height: calc(100% - 52px) !important;

    .nav-more-dropdown {
      height: calc(100% - 72px);
    }
  }

  .collection-page.conversations-page {
    height: calc(100vh - 138px);
  }

  .custom-fixed-banner {
    top: 128px;
  }
}

.notification-banner ~ {
  .custom-fixed-banner {
    top: 130px;
  }
}

.mobile-device .conversations {
  &:not(.chat-detail) {
    .universalBannerWrapper ~ {
      .collection-page {
        top: 142px;
      }
    }
  }

  &.new-chat {
    .universalBannerWrapper ~ {
      .conversations-page {
        .user-selection-row {
          top: 142px;
        }
      }
    }
  }
}

html:not(.mobile-device) {
  body {
    padding-top: 64px;

    &.modal-open {
      padding-top: 0;

      .os-main-nav {
        position: relative !important;
        top: 0 !important;
      }
    }

    &.conversations {
      &.chat-detail,
      &.new-chat {
        padding-top: 0;

        .os-main-nav {
          position: relative !important;
          top: 0 !important;
        }
      }
    }
  }

  .navbar.os-main-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1022;
    //transition: transform 300ms;
  }
}

.scroll-up .os-main-nav {
  transform: none;
}

.scroll-down:not(.mobile-device) .os-main-nav {
  // transform: translate3d(0, -100%, 0);
}

.scroll-up:not(.mobile-device) {
  .hide-extra-action {
    .head-sticky.fixed {
      .sub-nav-background {
        /*padding-top: 84px;*/
        transition: all 400ms ease;
      }

      .sticky-before {
        /*top: 84px;*/
        transition: all 400ms ease;
        transition-delay: 10ms;
      }
    }
  }

  .collection-page {
    .head-sticky.fixed {
      .sub-nav-background {
        transform: translateY(84px);
        transition: all 400ms ease;
      }

      .sticky-before {
        content: "";
        background: $block;
        height: 84px;
        left: 0;
        right: 0;
        position: absolute;
        top: -34px;
      }
    }
  }
}

.mobile-device {
  .workspace-nav-block {
    background-color: $mobile-dropdown-bg;
    height: 264px !important;
    position: fixed;
    bottom: -264px;
    left: 0;
    right: 0;
    z-index: 99999;
    padding: 0px 0 20px;
    overflow-y: hidden;
    opacity: 0;
    transition: opacity 500ms ease, bottom 500ms ease;
    will-change: opacity, bottom;

    @media screen and (max-width: 767px) {
      display: block;

      &.active {
        opacity: 1;
        bottom: 0;
      }
    }

    .selected-nav {
      text-align: center;
      height: 40px;
      padding: 0px 16px;
      margin-bottom: 1px;

      > div {
        height: inherit;
        font-size: 16px;
        border-bottom: 1px solid $border-button-1;
      }

      span {
        @include text-truncate();
        max-width: calc(100% - 54px);
      }
    }

    ul {
      overflow-y: auto;
      max-height: 100%;
    }

    .workspace-nav-block-list {
      padding: 0px 16px;
      width: 100%;
      display: block;
      line-height: 40px;
      color: $dropdown-text;
      font-weight: normal;
      position: relative;

      .wrk-spc-name {
        color: inherit;
        padding-left: 32px;
        @include text-truncate();
        display: inline-block;
        max-width: calc(100% - 54px);
        @extend %caption-default;
        line-height: 1;
        transform: translateY(1px);
      }

      span {
        font-weight: bold;
      }

      i {
        position: absolute;
        top: 12px;

        &.ifill-os-company {
          top: 11px;
        }
      }

      .ifill-os-workspace {
        &:before {
          display: none;
        }
        width: 16px;
        height: 16px;
        background: transparent url('/app/images/cloudberry-logo.png') 0 0 no-repeat;
        background-size: cover;
      }

      .oval {
        position: relative;
        background-color: $lt-orange !important;
        right: auto;
        left: 12px;
        top: calc(50% - 2px);
        width: 4px;
        height: 4px;
      }

      &.active {
        color: $dropdown-hover-text;
        font-weight: bold;
        background-color: $dropdown-bg;

        .current-text {
          float: right;
          font-size: 8px;
        }

        .wrk-spc-name {
          color: $os-link;
        }
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  .workspace-dropdown-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh !important;
    z-index: 99998;
  }
}



.marcom-nav {
  height: 100px !important;
  background-color: $block;

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navbar-brand {
    .logo {
      .logo-primary, img {
        max-width: 148px !important;
      }
    }
  }

  .os-main-nav-inner {
    padding: 0px 40px !important;
    max-width: 100% !important;
  }

  .os-btn-link {
    padding: 0 30px !important;
    color: hsla(0,0%,100%,.7);
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 40px;
    display: inline-block;

    &:hover, &:focus {
      text-decoration: none !important;
    }

    &.active{
      text-decoration: none !important;
    }
  }

  .signup-marcom {
    padding: 0px 15px;
    background-color: #3898ec !important;
    color: #fff;

    &:hover, &.active, &:focus {
      color: #000 !important;
      background-color: #3898ec !important;
    }
  }

  .navbar-toggler {
    color: #fff;
  }
}

@media screen and (max-width: 992px) {
  .marcom-nav {
    .navbar-toggler {
      padding: 18px;
      font-size: 24px;
      border-radius: 0;
      background-color: #1f6fff;
      color: #fff;

      &.collapsed {
        background-color: transparent;
      }
    }

    .navbar-collapse {
      position: absolute;
      left: 0;
      right: 0;
      top: 100px;
      background: #000;

      .navbar-nav {
        max-width: 728px;
        margin: 0px auto;
        flex-direction: column;
      }

      .os-btn-link {
        padding: 10px 30px !important;
        width: 100%;
      }

      .signup-marcom {
        margin-left: 30px;
        margin-bottom: 10px;
      }
    }
  }
}
.mobile-device {
  .marcom-nav {
    padding: 0 !important;

    .navbar-collapse {
      background-color: #1c1c1e;
    }

    .os-main-nav-inner {
      padding: 0px 20px !important;
    }

    .signup-marcom {
      min-height: 40px !important;
      line-height: 40px !important;
      width: 100% !important;
      margin-left: 0 ;
    }
  }
}

.wrk-drodown {
  .oval {
    &.absolute {
      right: 16px;
      width: 4px;
      height: 4px;
      top: 50%;
      background-color: $lt-orange !important;
      margin-top: -2px;

      &:hover {
        background-color: $lt-orange !important;
      }
    }
  }

  .page-heading-title {
    .oval {
      &.absolute {
        width: 12px;
        height: 12px;
        top: 4px;
        border: 2px solid  $lt-orange;
        left: 20px;
        background-color: $lt-orange !important;
      }
    }
  }

  .navbar-brand {
    + .oval {
      &.absolute {
        width: 12px;
        height: 12px;
        left: 85px;
        top: 6px;
      }
    }
  }

  .header-dropdown-list {
    .header-dropdown-list-item  {
      &:last-child {
        .os-btn-link {
          border-radius: 0 0 8px 8px;
        }
      }

      &:first-child {
        .os-btn-link {
          border-radius: 8px 8px 0 0;
        }
      }

      &:only-child {
        .os-btn-link {
          border-radius: 8px;
        }
      }
    }
  }
}


.mobile-nav-bar {
  &.os-main-nav {
    .nav-item {
      &:not(.workspace-nav-block) {
        .oval {
          background-color: $lt-orange !important;
          width: 12px;
          height: 12px;
          top: 10px !important;
          border: 2px solid  $lt-orange;
          right: 8px !important;
        }
      }
    }
  }
}

.nav-link:hover{
  .nav-tooltip {
    &::after {
      content: attr(data-tooltip);
      font-size: 10px;
      padding: 0 5px;
      color: $level-10;
      position: absolute;
      left: 0;
      top: 100%;
      white-space: nowrap;
      z-index: 20;
      border-radius: 2px;
      background: $block;
      line-height: 20px;
      box-shadow: $block-shadow-xs;
    }
  }
}
