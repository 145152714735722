$btn-height: 40px;
$m-btn-height: 32px;

.btn {
  cursor: pointer;
  height: 34px;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bold;

  a &:hover{
    text-decoration: none;
  }

  &.add-to-board {
    position: relative;

    .add-button-dropdown {
      display: none;
    }
    &:not(.no-hover):hover {
      .add-button-dropdown {
        display: block;
        margin-top: -5px;
      }
    }
  }

  &.btn-outline-transparent {
    border-color: transparent;
  }

  &.text-blue3 {
    color: #48acfd;
  }

  &.tt-none {
    text-transform: none;
  }

  &.active-blue {
    background-color: #48acfd;
    color: #fff;
    border-color: #48acfd;
  }

  &.btn-attachment {
    min-width: 192px;
    height: 36px;
    line-height: 36px;
    border-radius: 17px;
    font-weight: normal;
    color: $discuss-textarea-color;
    border: solid .5px #646464;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.modal-blue-btn {
  line-height: 36px;
  border-radius: 17px;
  background-color: #48acfd;
  font-size: 12px;
  color: #fff;
  min-width: 104px;
  padding-left: 5px;
  padding-right: 5px;
  border: 0;
  outline: 0 !important;
  cursor: pointer;
  height: 36px;
}

.btn-primary {
  &.ob-3 {
    background-color: #48acfd;
    border-color: #48acfd;

    &:hover {
      background-color: #4294e5;
      border-color: #4294e5;
    }
  }
}

.btn--bordered {
  color: $os-link;
  background-color: transparent;
  border: 1px solid $cl-link;
  transition: 500ms;
}
.btn--pill {
  border-radius: 999px;
}

.btn-small-text {
  font-size: 12px;
  height: 34px;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: .5px;

}

.modal-footer {
  .btn {
    min-width: 130px;
  }

  .add-message-modal-footer-right {
    min-width: 150px;
  }

  .btn-attachment {
    min-width: 100%;
  }
}

.btn-white{
  background-color: #fff;
  color: #000;
  &:hover{
    background-color: #d2d2d2;
  }
}

%btn-group-active{
  background-color: $os-link;
  color: #fff;
}



.btn-white-outline{
  background-color: initial;
  color: #fff;
  border-color: #fff;
  &:hover{
    color: #d2d2d2;
    border-color: #d2d2d2;
  }
  &:disabled{
    opacity: 1;
  }
}


.btn-signup{
  font-size: 12px;
  font-weight: $font-weight-bold;
  color: $level-11;
  line-height: 40px;
  padding: 0 16px;
  border: 0;
  text-transform: uppercase;
  background-color: $os-link;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: color 500ms ease, background 200ms ease;
  will-change: color, background;
  height: $btn-height;
  position: relative;

  &.btn-signup-skip{
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #5a5a5a;
  }
  &.btn-signup-invalid{
    color: $level-6;
    background-color: $os-btn-disabled;

    &:active, &:focus {
      box-shadow: none;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }

  &:hover:not(.disabled-only) {
    background-color: $btn-primary-hover;
  }
}

.mobile-device {
  .btn-signup {
    height: 32px;
    line-height: 32px;
  }
}

.btn--gray {
  color: #fff;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  background-color: #5a5a5a;
  padding: 0 22px;
  height: 34px;
  border-radius: 17px;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

.btn--red {
  color: #fff;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  background-color: $lt-red;
  padding: 0 32px;
  height: 34px;
  border-radius: 17px;
  &:hover {
    text-decoration: none;
    background-color: lighten($lt-red, 10%);
  }
}

.btn--group {
  display: flex;

  .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn:last-child:not(:first-child), .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
  }

  .btn + .btn {
    margin-left: -1px;
  }

  .btn:first-child {
      margin-left: 0;
  }

  .btn {
    min-width: 83px;
    -webkit-appearance: initial;
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 0 32px;
    height: auto;
    height: 40px;

    &.btn-default {
      @extend .btn--bordered;
    }

    &.active {
      @extend %btn-group-active;
    }

    &.disabled {
      border-color: #4c4c54;
      color: $level-9;

      &.active {
        background: #4c4c54;
        color: $level-9;
      }
    }

  }
}

// animation
.btn-animatable{
  outline:none;
  text-align: center;
  letter-spacing: 1px;
  font:{
    size:12px;
    weight:bold;
  }
  cursor: pointer;
  transition: all 0.25s ease;
  &:active {
    letter-spacing: 2px;
  }
  &.btn-text-save{
    &:after {
      content:"SAVE";
    }
  }
  &.btn-text-saved{
    &:after {
      content:"SAVED";
    }
  }
  &.btn-submitting{
    min-width: 36px;
    width: 34px;
    border-color:#8c8c8c;
    border-width:3px;
    padding: 0;
    font-size:0;
    border-left-color:#4eaefa;
    background-color: #fff;
    animation: rotating 2s 0.25s linear infinite;

    &:after {
      content:"";
    }
    &:hover {
      color:#4eaefa;
      background-color: #fff;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {

  .btn-small-text {
    height: 28px;
    font-size: 10px;

    i {
      &.icon-follow{
        position: relative;
        top: -1px;
      }
    }
  }

  .btn--gray {
    font-size: 10px;
  }
}


//toogle button
.toggle-comment-label {
  padding-left: 32px;
  position: relative;
  margin: 0;
  font-weight: 600;
  cursor: pointer;

  input {
    display: none;

    &:checked ~ {
      .toggle-comment-type {
        &::before {
          color: #fff;
          border-color: #48acfd;
        }

        &::after {
          background-color: #48acfd;
          border-color: transparent;
          left: calc(100% - 11px);
          width: 9px;
          height: 9px;
        }
      }
    }
  }

  .toggle-type-helper {
    color: inherit;
  }
}

.no-touch-support {
  .toggle-comment-label {
    &:after {
      content: '';
      width: 40px;
      height: 30px;
      position: absolute;
      top: -4px;
      left: -9px;
      opacity: 0;
      background: $os-link-hover
$os-link-hover;
      -webkit-transition: opacity 500ms ease;
      transition: opacity 500ms ease;
      border-radius: 4px;
    }

    &[data-hover="true"]:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}

.toggle-comment-type {
  position: absolute;
  width: 22px;
  height: 12px;
  left: 0;
  top: calc(50% - 6px);

  &::before,
  &::after {
    position: absolute;
    content: '';
    transition: all .15s;
  }

  &::before {
    border-radius: 15px;
    border: #646464 solid 1px;
    width: 100%;
    height: 100%;
  }

  &::after {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: solid 1px #646464;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.btn-os-blue {
  background-color: #48acfd;
  color: #fff;
}

.btn-outline-secondary {
  &.btn-hover-secondary {
    &[data-hover='true']:hover {
      background-color: #48acfd !important;
      color: #fff !important;
      border-color: #48acfd !important;
    }
  }
}

.btn-link {
  color: #48acfd;
  &:focus {
    color: #48acfd;
  }
}


.no-touch-support {
  .btn--group {
    .btn:not(.disabled):hover {
      background-color: rgba(72, 172, 253, 0.2);
      color: #fff;
    }
    .active:not(.disabled):hover {
      background: $os-link-hover;
      border-color: $os-link-hover;
      color: #fff;
    }
  }

  .active-blue {
    &:not(.disabled):hover {
      background-color: $os-link-hover;
      border-color: transparent;
    }
  }
}
