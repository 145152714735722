.skill_gain_sidebar {
  margin-bottom: 28px;

  .skills_sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      @include fontstyle(20px, 500, 28px);
      margin-bottom: 0px
    }
  }

  .skilltag-blocks {
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
  }

  .skilltags{
    @include fontstyle(16px, 400, 1 );
    color: $level-11;
    padding: 12px 16px;
    border-radius: 4px;
    margin-bottom: 12 px;
    background: #303036;
    margin-right: 12px;
    margin-top: 12px;

    &:last-child {
      margin-right: 0;
    }

    &.active,
    &[data-hover="true"]:hover{
      background-color: $os-link-hover
$os-link-hover;
    }
  }
}

.ipad {
  &.portrait {
    .skill_gain_sidebar {
      margin-bottom: 80px;
    }

    .skilltag-blocks {
      display: flex;
      padding-top: 0;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 0;
    }

    .skills_sidebar-title {
      margin-bottom: 16px;
    }

    .skilltags {
      margin-top: 0;
      white-space: nowrap;
    }
  }
}

.mobile-device {
  .skill_gain_sidebar {
    padding: 40px 0 16px 16px;

  }

  .skilltags {
    white-space: nowrap;
  }

  .skills_sidebar-title {
    @include fontstyle(16px, normal, 16px);

    .osbtn {
      margin-right: 16px;
    }
  }

  .skilltag-blocks {
    padding-top: 16px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
  }
}
