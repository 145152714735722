.floater-message-wrapper {
  position: fixed;
  bottom: 4px;
  right: 32px;
  width:615px;
  max-height: 400px;
  z-index: 999;

  .loader {
    opacity: 0;
  }

  .chat-action-group {
    .osbtn {
      min-width: 32px;
      min-height: 32px;
      margin-bottom: 8px;

      + .osbtn {
        margin-left: 0;
      }
    }

    .conversation-input-button {
      min-width: 40px;
      min-height: 38px;
      margin-bottom: 0;
    }
  }
}
.floater-message-holder {
  position: absolute;
  bottom: 0;
  right: 0px;
  width:296px;
  background-color: $block;
  box-shadow: $block-shadow;
  border-radius: 4px;
  max-height: 400px;
  z-index: 999;
  &.pr-10 {
    padding-right: 12px;
  }
  &.left {
    right: 312px;
    display: flex;
    flex-direction: column;

    &.group {
      .conversation-message-author-name {
        display: block;
      }
    }

    .floater-message-body {
      &.open {
        padding-bottom: 45px;
        overflow: hidden;

        .conversation-input-section {
          height: auto;
        }
      }
    }
    .floater-message-header {
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 12px;
    }
  }

  .os-sm-space {
    &.course-space {
      .space-user-info {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .extra-small-min-card {
    .card-attachment-body {
      width: calc(100% - 80px);
      max-width: calc(100% - 80px);
    }
  }
}

.floater-message-header {
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  padding: 7px 18px;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  .icon-wrap {
    font-size: 16px;
    line-height: 16px;
    .osbtn-icon {
      min-width: 32px;
      min-height: 32px;
      padding: 0px ;
    }
  }
}
.floater-message-heading {
  font-size: 16px;
  color: $level-11;
  line-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    padding-left: 8px;
    span {
      padding-left: 5px;
    }
  }
  .message-unread-count {
    color: $lt-notification-bg;
  }
  .chat-user-name {
    margin-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 130px;
    font-size: 14px;
  }

  &.group-chat-heading {
    padding-left: 40px;
    font-size: 12px;
    line-height: 1.33;
    .chat-user-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
      max-width: 130px;
      font-size: inherit;
    }
  }

  .multiple-avatar {
    position: absolute;
    width: 40px;
    left: 16px;
    height: 32px;
    .avatar {
      position: absolute;
      left: 0;
      +.avatar {
        left: 7px;
      }
    }
  }

}

.multiline-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.floater-message-body {
  padding-left: 12px;
  padding-right: 12px;
  height: 0;
  transition:height 0.3s ease-out;
  visibility: hidden;
  pointer-events: none;

  &.open {
    height: calc(400px - 57px);
    max-height: calc(400px - 57px);
    padding-bottom: 0px;
    margin-top: 9px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 9px;
    visibility: visible;
    pointer-events: auto;

    &:before {
      background-color: $border-button-1;
      height: 1px;
      left: 12px;
      width: calc(100% - 24px);
      content:'' ;
      position: absolute;
      z-index: 1;
      top: 48px;
    }

    .floater-records-body {
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 10px;
    }

    .loader-active {
      opacity: 1;
    }
  }
  .loader-active {
    height: auto;
    top: 50%;
    left: 50%;
    position: absolute;

    .loader-inner {
      margin-top: 0;
    }
  }

}

.floater-records-body {
  height: calc(400px - 114px);
  max-height: calc(400px - 114px);
}

.floater-records-body, .floater-message-body {
  @extend %os__scroll;
}

.floater-message-holder {

  &:not(.left) {
    .floater-message-body.open {
      margin-bottom: 16px;
      height: 327px;
      max-height: 327px;
    }
  }

  .conversations-list {
    padding-left: 0;
  }

  .floater-conversation-row {
    min-height: 52px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 52px;
    height: auto;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    border-bottom: 1px solid $border-button-1;

    .conversation-avatar {
      .oval {
        display: none;
      }
    }
    .oval {
      border-radius: 10px;
      position: absolute;
      top: 22px;
      left: -2px;
    }
    .conversation-avatar {
      left: 12px;
      width: 32px;
      top: 10px;
      position: absolute;
      div {
        &.avatar {
          @include avatar(32px);
        }
      }
      img {
        text-indent: 0;
      }
    }

    .conversation-avatar-2 {
      left: 8px;
      width: 35px;
      top: 10px;
      position: absolute;
      div.avatar {
        position: absolute;
        left: 0;
        top: 0;
        @include avatar(32px);
        &+ div.avatar {
          left: 7px;
        }
      }
      img{
        text-indent: 0;
      }

      .oval {
        display: none;
      }
    }

    .conversation-content-top-row {
      display: flex;
      justify-content: space-between;
    }

    .conversation-name {
      max-width: 150px;
      color: $level-11;
      margin-bottom: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.group-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        margin-bottom: 7px;
      }
    }

    .lateset-message-date {
      color: $level-8;
      font-size: 12px;
    }

    .conversation-name,
    .lateset-message {
      font-size: 14px;
      font-weight: $font-weight-semibold;
      line-height: 1.33;

      svg {
        width: 16px;
        height: 16px;
        vertical-align: top;
      }
    }

    .conversation-content-block {
      width: 100%;
    }

    .lateset-message {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: $level-11;
      font-weight: normal;
      height: auto;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
  }

  .conversation-message {
    .conversation-message-content-row {
      flex-direction: column;
      width: 100%;
    }

    .conversation-message-author {
      width: 24px;
      height: 24px;
      top: 0;
      div.avatar {
        @include avatar(24px);
      }
    }

    .conversation-message-box {
      border-radius: 12px;
      font-size: 14px;
      color: $level-11;
      line-height: 1.33;
      padding: 12px;
      margin-bottom: 0;
      &:after {
        content: none !important;
      }

      ~ .conversation-message-attachment-row {
          margin-top: 10px;
        }
    }

    .a-link {
      font-size: 12px;
    }

    .conversation-message-author {
      min-width: auto;
    }

    .conversation-message-content {
      padding-left: 31px;
      padding-right: 5px;

      .white-link {
        color: inherit;
        text-decoration: underline;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }

      .conversation-message-text {
        font-size: 14px;
        color: white;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: normal;

        .a-link {
          font-size: 12px;
        }
      }
    }

    .conversation-message-author-name {
      .os-btn-link  {
        text-decoration: none;
        color: $level-9;
        font-weight: normal;

        &:hover {
          text-decoration: underline;
          opacity: 1;
        }
      }
    }
    .conversation-card-container {
      width: 240px;
      margin-bottom: 0px;
      margin-left: 0px;
      min-height: 96px !important;

      &.w-344 {
        max-width: 240px;
        width: 240px;
        min-width: 240px;

        .os-card-v2 {
          width: calc(100% - 32px);
        }

      }
    }

    .conversation-message-attachment {
      margin-bottom: 0;
      margin-right: 5px;
      width: 120px;
      height: 120px;
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
    .conversation-message-time {
      font-size: 12px;
      color: $level-11;
      line-height: 1.5;
      padding: 12px 0;
      text-align: center;
    }
    .conversation-message-author-name {
      font-size: 10px;
      color: $level-9;
      line-height: 1.5;
      display: none;
    }

    .conversation-date {
      display: none;
    }

    &.ownMessage {
      .conversation-message-author {
        bottom: 0;
        top: auto;
      }

      .conversation-message-content {
        padding-right: 36px;
        padding-left: 0;

        .white-link {
          color: inherit;
        }
      }
      .conversation-message-author {
        right: 4px;
      }
      .conversation-date {
        right: 0;
      }
      .conversation-card-container {
        margin-left: 0px;
      }
    }
  }

  .conversation-input-section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    height: 0;
    z-index: 3;
    transition:height 0.3s ease-out;
    width: 100%;
    background: $level-2;
  }
  .conversation-input-holder {
    .media-body {
      padding: 0px 0 0 0;
      border-radius: 4px;
      max-width: 100%;
    }
  }

  textarea {
    width: 100%;
    min-height: 40px;
    height: 40px;
    padding: 7px 70px 7px 50px;
    font-size: 14px;
    border: 1px solid transparent;
    overflow-y: auto;
    resize: none;
    &:focus {
      border-color: $os-link;
    }
  }

  .conversation-options-row{
    position: absolute;
    bottom: 0px;
    height: 40px;
    z-index: 1;
  }

  .conversation-input-button {
    border: none;
    background: $cl-link;
    width: 40px;
    min-height: 40px;
    height: 100%;
    display: inline-block;
    bottom: 0;
    right: 0;
    cursor: pointer;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.9);
    padding: 0;
    border-radius: 0px 4px 4px 0;
    z-index: 1;
    font-weight: normal;

    &:disabled,
    &.disabled {
      pointer-events: none;
      background-color: transparent;
      z-index: 0;
    }
  }

  .conversation-attachment-file-block {
    width: 100%;
    min-width: 100%;
    padding: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .conversation-attachment-files-count {
    width: 100%;
    margin-bottom: 10px;
    color: $level-11;
    font-size: 13px;
    font-weight: 600;
  }

  .conversation-attachment-file-holder {
    margin-right: 10px;
    padding: 5px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;

    &.attached-card {
      width: 240px;
      padding: 0;
    }

    &.attached-file {
      width: 200px;
      padding: 0;

      &.w-230 {
        width: 230px;
      }

      .conversation-attachment-file-content {
        height: 100%;

        .icon {
          font-size: 38px;
        }
      }

      .conversation-attachment-icon-holder {
        width: 100%;
        height: 55%;
        position: relative;
      }

      .conversation-attachement-icon {
        background: transparent url('/app/images/icon-doc-exn.png') 0 0 no-repeat;
        width: 38px;
        height: 48px;
        display: inline-block;
        background-size: 38px auto;
      }

      .conversation-attachment-ext-name {
        width: auto;
        max-width: 100px;
        height: 19px;
        border: solid 2px #DDD;
        padding: 0 7px 0 3px;
        overflow: hidden;
        position: absolute;
        top: 22px;
        left: 7px;
        color: #DDD;
        font-size: 13px;
        font-weight: $font-weight-bold;
        line-height: 16px;
        text-align: left;
        background: $level-9;
        border-radius: 4px;
        white-space: nowrap;
      }

      .uploaded-file {
        width: 100%;
        height: 60%;
        top: 0;
        color: #DDD !important;
        font-size: 11px;
        font-weight: $font-weight-bold;
        line-height: 13px;
        text-align: left;
        white-space: normal;
        overflow-wrap: break-word;
        word-break: break-all;
        word-wrap: break-word;
        word-break: break-word;
        transform: translateY(0);
      }
    }

    .ifill-os-close {
      z-index: 11;
      text-align: center;
      cursor: pointer;
      top: 5px;
      right: 5px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 24px;
      font-size: 12px;
      position: absolute;
      z-index: 4;
    }
    .uploaded-file {
      width: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      top: 50%;
      text-align: center;
      background: transparent;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .cannot-reply {
    width: 100%;
    .form-group {
      margin-bottom: 0;
      textarea {
        padding: 11px 10px 9px 10px;
      }
      .ifill-os-send {
        display: none;
      }
    }
    textarea::placeholder {
      font-size: 12px;
    }
    .conversation-options-row{
      display: none;
    }
  }

  .messenger-input-container {
    margin-bottom: 0;
  }

  .conversation-input-row {
    position: relative;
  }
}

body {
  &.modal-open {
    .floater-message-holder {
      display: none;
    }
  }
}

.ipad {
  .floater-message-wrapper {
    bottom: 13px;
  }
  &.floater-focus {
    height: auto !important;
    body {
      height: inherit;
    }
    .floater-message-holder {
      bottom: 0px !important;
    }
    .floater-message-wrapper {
      bottom: 13px !important;
    }
  }

  .floater-message-holder {
    .conversations-list {
      display: none;
    }

    .floater-records-body{
      display: none;
    }

    .conversation-input-section{
      display: none;
    }

    .floater-message-body {
      &.open {
        .conversations-list {
          display: block;
        }
        .floater-records-body{
          display: block;
        }
        .conversation-input-section {
          display: block;
        }
      }
    }
  }
}

@-moz-document url-prefix() {
  .floater-message-holder .conversation-message.ownMessage .conversation-message-author {
    right: 10px;
  }
}


.joyride-tooltip[data-target='.floater-message-holder'] {
  margin-left: -50px !important;
}


.floater-message-body {
  .extra-small-min-card {
    .attachment-card-image,
    .sm-case-card-images,
    .tl-image,
    .card-v2-header,
    .card-v2-hero {
      height: 72px !important;
      max-height: 72px !important;
      min-width: 72px !important;
      max-width: 72px !important;
    }

    .sm-case-card-body,
    .tl-card-body  {
      padding-left: 84px !important;
    }
    .sm-case-card-head {
      padding-left: 0 !important;
      left: 94px;
    }

    .os-sm-space {
      .card-content {
        max-width: calc(100% - 80px);
        padding-left: 8px !important;
      }

      .space-user {
        @include text-truncate();
      }

      .space-name {
        margin-right: 0;
        word-break: break-all;
        font-size: 12px !important;
      }

      &.with-action-tag {
        .card-v2-header {
          min-width: 100% !important;
          max-width: 100% !important;
          padding-left: 80px;
        }
      }

      .extra-small-card-space-name, .space-detail {
        @include multiline-truncate(2);
      }
    }
  }
}
