.add-post-area,
.activity-listing,
.pulse-sidebar {
  .os-card-v2>div {
    box-shadow: none;
    border: solid 1px $level-5;
    border-radius: 12px;
  }
}

.pulse-sidebar {
  .os-card-v2>div {
    transform: none !important;
  }

  .patient-detail-widget,
  .document-list {
    .os-card-v2 {
      >div {
        border: 0;
      }

      &:after {
        content: '';
        width: calc(100% - 16px);
        background-color: $level-5;
        height: 1px;
        margin-left: 8px;
        display: block;
      }

      >div {
        border: 0;
        box-shadow: none;
      }
    }

    .row {
      [class*="col"] {
        &:last-child {
          .os-card-v2 {
            &:after {
              content: none;
            }
          }
        }
      }
    }
  }
}


.ipad.landscape {
  .patient-detail-widget {
    .os-card-v2 {
      @media screen and (max-width: 1200px) {
        .badge {
          margin-left: 0 !important;
          @include text-truncate();
          max-width: 130px;
          display: block;
        }
      }
    }
  }
}