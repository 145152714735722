/* Global css to use @extend on new message */
%media-body {
  margin-top: 10px;

  textarea {
    border: none;
    resize: none;
    font-size: 15px;
    line-height: 20px;
    height: 36px;
    min-height: 36px;
    color: #5a5a5a;
    overflow: hidden;

    &.form-error {
      border: 1px solid $lt-red !important;
    }
  }
  &.active {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #a0a0a0;
    border-radius: 8px;
    padding: 8px 12px;
    textarea {
      background-color: inherit;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #e6e6e6;
      padding: 0;
      color: #5a5a5a;
      height: 96px;
      overflow: auto;
    }
    .media-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      .media-links {
        display: flex;
        align-items: center;
      }
      .filedrop-manual-open{
        color: #b4b4b4;
        cursor: pointer;
      }
      i {
        margin-right: 15px;
        color: #b4b4b4;
        font-size: 17px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

      }
      .upload {
        color: #4294e5;
      }
      .btn-comment {
        height: 30px;
      }
    }
  }

}

.filedrop-overlay{
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 20px;
  left: 0;
  padding: 2.5em 0;
  background: rgba(42,42,42,0.8);
  text-align: center;
  color: #fff;
  border-radius: 4px;
  z-index: 1;

  &.elem-full-hw {
    top: 0;
    bottom: 0;
  }


  &.small-overlay {
    .ifill-os-uploading {
      position: relative;
      top: 2px;
    }
  }
}

.comment-share{
  .comment-share-title{
    color: #fff;
    font-size: 24px;
    font-weight: $font-weight-light;
    margin-bottom: 20px;
  }
  .share-type{
    color: #b4b4b4;
    width: 100%;
    border: 1px solid #787878;
    font-size: 12px;
    justify-content: left;
    margin-bottom: 16px;
    line-height: 22px;
    text-transform: uppercase;
    i{
      padding-right: 11px;
      font-size: 16px;
    }
    &.share-type-selelected{
      color: #fff;
      background-color: #787878;
    }
  }
  .comment-share-input{
    display: flex;
    align-items: center;
    .form-control{
      border: none;
      background-color: #1e1e1e;
      width: 60%;
      transition: 500ms;
      background-color: #1e1e1e;
      padding-left: 30px;

      &:focus{
        width: 100%;
        background-color: #e6e6e60;
      }
    }
    .search-icon-container{
      color: #e6e6e6;
      position: relative;
      right: -25px;
    }
    .clear-input{
      color: #e6e6e6;
      position: relative;
      right: 20px;

      i{
        cursor: pointer;
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .comment-share-input {
      .form-control {
        width: 100%;
        height: 30px;
        font-size: 12px;
      }
    }

    .assign-options-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .share-type {
      width: 40px;
      padding: 8px 5px 5px;
      display: flex;
      flex-direction: column;
      color: #000;
      font-size: 1px;
      border-radius: 4px;

      &.share-type-selelected {
        color: #000;

        i {
          color: #fff;
        }
      }

      i {
        padding-right: 0;
        color: #787878;
      }
    }
  }
}

.suggest-avatar {
  display: inline-flex !important;
  margin-right: 8px;
}

.detail-comments {
  .media-body{
    @extend %media-body;

    .user-suggestion-listings {
      width: 100%;
      max-height: 150px;
      border: solid 1px $block;
      padding: 0px;
      overflow-y: auto;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: $block;
      border-radius: 0 0 4px 5px;
      z-index: 2;
      cursor: auto;
    }

    .user-suggestion-listings-row {
      margin: 0;
      border-top: 0;
      padding:0 10px;
      list-style: none;
      cursor: pointer;
      font-size: 12px;
      line-height: 2.5rem;
      font-weight: normal;
    }

    .avatar {
      text-indent: 0;
      vertical-align: middle;
      position: relative;
      top: -1px;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .active {
      &.media-body {
        .media-actions {
          justify-content: flex-end;

          .media-links {
            display: none;
          }
        }
      }
    }
  }
}

.blurred-section {
  @include filter(5px);
  * {
    pointer-events: none !important;
  }
}