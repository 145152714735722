.appliance-icon-pls-minus {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #29292e;
  border-radius: 50%;
  z-index: 1;
  font-size: 12px;
  text-align: center;

  @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    left: -62px;
  }

  &.added {
    .tl-icon-right {
      &.add {
        display: block;
      }
    }

  }

  &.adjusted {
    .tl-icon-right {
      &.adjust {
        display: block;
      }
    }
  }

  &.replaced {
    .tl-icon-right {
      &.replace {
        display: block;
      }
    }
  }

  &.removed {
    .tl-icon-right {
      &.minus {
        display: block;
      }
    }
  }
}
.tl-icon-right {
  font-size: 12px;
  line-height: 24px;

  &.add {
    color: #57b894;
    display:none ;
  }

  &.adjust {
    color: $level-11;
    display:none;
  }

  &.replace {
    color: #fcb947;
    display:none;
  }

  &.minus {
    color: #cf667a;
    display:none;
  }

  &:hover {
    &.add {
      color: #57b894 !important;
    }

    &.adjust {
      color: $level-11 !important;
    }

    &.replace {
      color: #fcb947 !important;
    }

    &.minus {
      color: #cf667a !important;
    }
  }
}
/*extra-small-min-card*/
.extra-small-min-card {
  .os-sm-case-appliance-log, .os-sm-variant-log {
    @extend %os-extra-sm-card;
    padding: 0 12px 0 0;
    display: flex;
    position: relative;
    height: 80px;
    background-color: #29292e;
    border-radius: 4px;

    .appliance-tag {
      width: 50px;
      height: 16px;
      flex-grow: 0;
      padding: 0px 8px;
      font-size: 12px;
      line-height: 16px;
      color: $level-11;
      font-weight: normal;
      background-color: $block;
      border-radius: 10px;
      margin-left: 4px;
      display: inline-block;
      vertical-align: top;
      margin-top: 1px;
    }

    &.highlighted {
      .appliance-tag {
        background-color: #29292e;
      }
    }

    .os-btn-link {
      color: $level-11;
    }

    .tl-image {
      position: relative;
      min-height: auto;
      height: auto;
      max-height: 160px;
      min-width: 80px;
      max-width: 80px;
      border-radius: 4px 0 0 4px;

      .img-parent,
      .overlay-span {
        padding: 0;
        border-radius: 4px;
      }
    }

    .tl-card-body {
      padding: 0 0 0 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: calc(100% - 80px);
    }

    .tl-name-info {
      padding: 0;
      margin: 8px 0 0 0;
      max-width: 100%;
    }

    .lg-text-9 {
      color: #e7e7ea;
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      @include multiline-truncate(2);
      line-height: 20px;
      overflow: hidden;
    }

    .cm-name {
      @include multiline-truncate(2);
      margin-bottom: 8px;
      line-height: 16px;
      overflow: hidden;
      height: auto;
      max-height: 34px;
    }

    .right-tool-col {
      display: none;
    }

    .tl-position-name {
      color: $os-link;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;

      &:empty {
      padding-bottom: 0;
      }
    }

    .cm-image {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 8px;
      max-width: calc(100% - 108px);

      .os-btn-link {
        max-width: calc(100% - 32px);
        display: inline-block;
        @include text-truncate();
      }

      .img-parent {
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 2px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        margin-right: 8px;
        overflow: hidden;
        padding: 2px;

        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
        }
      }
    }

    &.highlighted {
      background: $block;
    }

    &.sponsored {
      min-height: auto;
      height: auto;
      max-height: 160px;

      .tl-card-body {
        padding-bottom: 32px;
      }

      .os-btn-link {
        cursor: pointer;
      }
    }

    &.unsponsored {
      min-height: auto;
      height: auto;
      max-height: 160px;

      .sponsored-icon {
        position: relative;
        height: 100%;
        width: 36px;
        padding: 8px 0 8px 8px;

        i {
          font-size: 24px;
          color: #6c6c6f;
        }
      }

      .cm-image {
        max-width: calc(100% - 75px);

        .os-btn-link {
          max-width: 100%;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .tl-card-body {
        max-width: calc(100% - 48px);
        padding-bottom: 24px;
      }
    }
  }
}
