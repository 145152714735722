.notification {
  padding: 12px 0;
  border-bottom: 1px solid $overlay;
  transition: background-color 1000ms linear;
  color: $level-11;
  display: flex;

  &.notification-next-page {
    border: 0;
  }

  // dropdown view
  .notification__image {
    padding-right: 20px;
    box-sizing: content-box;
    position: relative;

    .avatar {
      vertical-align: top;
      &.avatar-40 {
        @include avatar(40px);
        text-indent: 0;
      }
    }
    i {
      position: absolute;
      right: 12px;
      top: 30px;
    }
  }

  .notification-title {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: 24px;
    color: $level-11;
  }

  .notification__text {
    width: 100%;
    font-size: 12px;

    p {
      margin-bottom: 0;
    }
  }
  .col-2,
  .col-md-2{
    div,
    .notification-date{
      color: $level-11;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      font-weight: 200;
    }
  }
  // Notifications view
  .notification-heading {
    margin-bottom: 6px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
    color: $level-12;
    height: 28px;
    max-width: 300px;
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 28px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    i{
      font-size: 15px;
      color: $level-9;
      margin-left: 11px;
      margin-right: 11px;
    }
    img{
      margin-top: -3px;
      height: 15px;
      margin-left: 11px;
      margin-right: 11px;
    }
  }
  .notification-text {
    color: #d2d2d2;
    @include ellipsis-lines($font-size: 14px, $line-height: (19/14), $lines-to-show: 2);
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .notification__text {
      p {
        white-space: normal;
      }
    }
  }
}

.notification-dropdown {
  > div > a {
    display: block;
    max-height: 24px;
  }

  .dropdown-reply-bar {
    margin-top: 1rem;
  }

  .dropdown-toggle {
    display: inline-block;
    align-items: center;
    cursor: pointer;

    .notifications-count {
      @include fontstyle(12px, 600, 16px);
      top: 3px;
      position: absolute;
      color: $lt-notification-text;
      background-color: $lt-notification-bg;
      min-width: 8px;
      max-width: 27px;
      border-radius: 6px;
      text-align: center;
      left: 20px;
      right: auto;
      z-index: 1;
      padding: 0 4px;
    }

    i {

      &.icon-messages {
        margin-right: 5px;
        position: relative;
      }

      &.ifill-os-notification{
        position: relative;
        font-size: 23px;
      }
    }

    &::after {
      display: none;
    }
  }


  .dropdown-menu {
    position: absolute;
    touch-action: none;
    top: calc(100% + 14px);
    width: 416px;
    left: auto;
    right: -20px;
    padding: 0 16px;
    background-color: $dropdown-bg;
    z-index: 1050;
    border: 0;
    color: $level-11;
    box-shadow: $block-shadow;

    .notifications-gear {
      position: absolute;
      right:  7px;
      top: 7px;
    }

    .no-notifications {
      margin-top: 82px;
      margin-right: -12px;
      padding-right: 12px;
    }

    .no-notifications-svg {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto 24px;
    }


    .no-notification-text {
      font-size: 14px;
      font-weight: 400;
    }


    .row-view-notification {
      margin-top: 48px;
      margin-bottom: 44px;
    }

    a {
      text-decoration: none;
    }

    .see-all {
      text-transform: uppercase;
      margin-top: 21px;
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      i {
        color: #fff;
        height: 14px;
        font-size: 12px;
        margin-left: 7px;
      }
      span {
        color: #4eaefa;
        font-size: 12px;
        line-height: 20px;
      }
    }
    .-reactjs-scrollbar-track {
      background-color: rgba(#fff, 0.1);
    }
    .-reactjs-scrollbar-thumb {
      background-color: #5a5a5a;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .dropdown-menu {
      top: 43px;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .dropdown-menu {
      top: 45px;
      left: auto;
      right: -10px;
    }

  }

  @media only screen and (min-width : 1441px) and (-webkit-min-device-pixel-ratio: 1) {
    margin-left: 10px;
    .dropdown-toggle {
      padding-right: 10px;
    }
  }
}

.notificaiton-clear-all{
  cursor: pointer;
  color: #fff;
  font-size: 11px;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  bottom: 5px;
  letter-spacing: 0.5px;
  line-height: 15px;
  display: flex;
  &.btn-small-text {
    padding: 5px 20px;
    letter-spacing: 1px;
  }
  i{
    border: 1px solid #b4b4b4;
    border-radius: 2px;
    height: 13px;
    width: 13px;
    line-height: 11px;
    text-align: center;
    margin-left:8px;
    color: #b4b4b4;
    &.fa-plus{
      margin-left: 10px;
      margin-right: 3px;
      line-height: 12px;
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .notifications-page {
    .nf-header {
      padding-left: 0;
      padding-right: 0;
    }

    .sub-nav-background {
      &.notifications-header-bar {
        .object-filter {
          justify-content: flex-start;
        }

        .object-header-bar {
          .btn {
            &.notificaiton-clear-all {
              height: auto;
              padding: 0;
              border: none;
              right: 10px;
              bottom: 12px;
              color: #4eaefa;
              font-size: 12px;
              line-height: normal;
              text-transform: none;
              letter-spacing: 0;
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  .notification {
    .notification-title {
      margin-bottom: 0;
      font-size: 14px;
      line-height: normal;
    }

    .notification-text {
      height: auto;
      margin-top: 3px;
      font-size: 12px;
    }

    .notification-date {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}

.nf-left {
  display: inline;
}

.notification-dropdown {
  .heading {
    @extend %heading-4-1;
    color: $level-8;
  }

  .dropdown-menu__notifications {
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
    margin: 18px -16px 0 -12px;
    padding: 0 12px;
    overscroll-behavior: contain;

    @extend %os__scroll;

    .nf-card-details-row {
      padding: 20px 0;
      border: 0;
      transition: background-color 1000ms linear;
      color: $level-9;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;

      + .nf-card-details-row {
          border-top: 1px solid $level-4;
        }
    }

    .post-message-button {
      .btn-icon-helper {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .space-notification-action {
      display: none;
    }
  }

  .nf-text-info,
  .nf-title-bar {
    line-height: 1.33;
  }

  .nf-title-bar {
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .nf-title {
      margin-right: 4px;
      @extend %heading-4-1-2;
      color: $level-12;
      max-width: calc(100% - 90px);
      display: inline;
      a {
        color: $level-12;

        &:hover {
          color: $level-10;
          text-decoration: underline;
        }
      }

      + small {
        color: $level-9;
        @extend %heading-5;
      }
    }

    small {
      min-width: 90px;
      font-weight: normal;
      text-align: right;
      padding-top: 2px;;
    }
  }

  .nf-content-block {
    padding-right: 0;
  }

  .nf-avatar-block {
    flex: 0 0 48px;
    max-width: 48px;
  }

  .nf-avatar-info {
    padding: 0;
    height: 32px;

    .avatar {
      vertical-align: top;
      &.avatar-40 {
        text-indent: 0;
      }
    }
  }

  .nf-unread-icon {
    width: 8px;
    height: 8px;
    background: $lt-notification-bg;
    border-radius: 50%;
    display: inline-block;
  }

  .unread-notification-count {
    @extend %caption-semibold;
    color: $level-1;
    border-radius: 6px;
    background-color: $lt-notification-bg;
    padding: 0px 4px;
  }

  .nf-content-block {
    flex: 1;
    width: auto;
    position: relative;
    white-space: normal;
  }

  .n-d-action {
    margin-right: -80px;
    display: none;

    .osbtn {
      background: none !important;
      line-height: normal;
      border: 0;
    }


    .a-non-link,
    .a-link {

      &.abs-link {
        position: absolute;
      }

      right: 0;
      font-size: 12px;
    }

    .a-non-link {
      font-size: 10px;
      top: 1px;
    }

    .a-link {
      padding: 0;
      margin: 0;
      min-height: unset;
      height: auto;
      text-transform: none;
      border: 0;
      top: 0;
    }
  }


  .space-notification-action, .support-notification-action {
    text-align: right;
    margin-right: -80px;

    .osbtn {
      margin-top: 16px;
      margin-bottom: 4px;
      line-height: 32px;
      min-height: 32px;
    }

    &.no-ignore-cta {
      .osbtn {
        padding: 0;
        margin: 0;
        min-height: unset;
        height: auto;
        border: 0;
        top: 0;
        right: 0;
        font-size: 12px;
        background: none !important;
        color: $os-link;
        position: absolute;
        line-height: 1;

        &[data-hover='true']:hover {
          text-decoration: underline;
          color: $os-link-hover;
        }
      }
    }

    .helping-text-info {
      text-align: left;
      margin-top: 5px;
    }
  }

  .nf-action-bar {
    margin: 0;
  }

  .form-group {
    margin: .5rem 0 0;
  }

  .post-form-control {
    height: 32px;
    min-height: 32px;
    font-size: 12px;
    line-height: 1.33;
    padding: 8px 40px 0 16px;
  }

  .pmb-conatiner .post-message-button {
    font-size: 14px;
    max-width: 32px;
  }

  .nf-text-info {
    color: $level-9;
    margin-top: 12px;
    overflow: hidden;
    @extend %body-small-1-2;
    font-size: 16px !important;

    .a-link {
      color: $level-9;
    }
  }

  .notification-dropdown-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    margin-top: 10px;
    min-height: 32px;

    &.without-gear {
      padding-right: 0;
      margin-top: 0;

      .os-btn-link {
        margin-right: -5px;
      }
    }
  }

  .notification-btn-group {
    display: flex;
    margin: 0 -16px;
    padding-top: 10px;
    position: relative;

    &::before {
      content: '';
      background-color: $dropdown-bg;
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .osbtn {
      max-width: 50%;
      flex: 1;
      border-radius: 4px 0 0 4px;

      + .osbtn {
          margin: 0;
          border-radius: 0 4px 4px 0;
        }
    }
  }

  .home-loader {
    margin: 0;
  }

  .pmb-conatiner {
    .post-message-button {
      height: 100%;
      line-height: unset;
      min-height: unset;
    }
  }
}

%hover-active-dropdown {
  .dropdown-toggle {
    z-index: 1051;
    position: relative;
  }

  .ifill-os-notification,
  .ifill-os-messages {
    color: $nav-link-icon-hover;

    &::after {
      opacity: 1;
    }

    &::before {
      position: relative;
      z-index: 1;
    }
  }
}

.notification-dropdown.active {
  &::before {
    position: absolute;
    content: '';
    top: 29px;
    left: 50%;
    transform: translateX(-50%);
    border: 12px solid transparent;
    border-bottom-color: $notification-carret;
    z-index: 1051;
  }

  &:after {
    position: absolute;
    content: '';
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    border: 12px solid transparent;
    border-bottom-color: $dropdown-bg;
    z-index: 1051;
  }

  @extend %hover-active-dropdown;
}

.no-touch-support {
  .nav-messages-block,
  .notification-dropdown .dropdown-toggle{
    .os-btn-link {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: 0;
      }

      &:hover {
        @extend %hover-active-dropdown;
        text-decoration: none;
        &:after {
          opacity: 1;
        }
      }
    }

    .ifill-os-notification {
      &::after {
        content: '';
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: 0;
      }

      &:hover {
        @extend %hover-active-dropdown;
        text-decoration: none;
        &:after {
          opacity: 1;
        }
      }
    }


  }

  .notification-dropdown {
    &.active,
    &:hover {
      @extend %hover-active-dropdown;

      .ifill-os-notification:after {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 560px) {
  .notification-dropdown {
    .dropdown-menu__notifications {
      width: 98vw;

      &::befoer {
        right: 54px;
      }
    }
  }
}


@media (max-width: 1200px) {
  .notification-dropdown {
    .dropdown-menu {
      right: -75px
    }
  }
}

.mobile-device {
  .no-notifications {
    margin-top: 30px;
    max-height: calc(100vh - 150px);
  }

  .no-notifications-svg {
    height: 100px;
  }
}

.oval {
  height: 8px;
  width: 8px;
  background-color: $lt-orange !important;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;

  &:hover {
    background-color: $lt-orange !important;
  }

  &.absolute {
    top: 2px;
    z-index: 1;
    right: 14px;
    font-size: 11px;
    position: absolute;
  }

  &.left-oval {
    position: relative;
    left: 6px;
    top: 0px;
  }
}

.mobile-nav-bar {
  .oval {
    &.absolute {
      right: -4px;
      top: 12px;
    }
  }
}
