.emptyStateWrapper {
  max-height: 176px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .Captiondefault {
    height: 16px;
    flex-grow: 0;
    font-family: Inter;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #7e91a5;
    margin-bottom: 24px;
  }

  .openTaskBtn {
    max-width: 148px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #354554;
    border: none;
    cursor: pointer;


    .openTaskBtnText {
      max-width: 116px;
      height: 16px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 11px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.2px;
      text-align: left;
      color: #ffffff;
    }
  }
}