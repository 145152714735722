@media (min-width: 720px) {
  .workspace-invite-modal {
    max-width: 680px !important;
    margin: 4rem auto !important;
  }
}

.workspace-invite-modal {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  .modal-content {
    padding: 40px 24px;
  }
  .invite-header {
    display: flex;
    gap: 8px;
    align-items: center;
    .heading {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4;
      color: #354554;
      margin: 0px;
    }
  }
  .invite-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
    .field-group {
      display: flex;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
      .group-field {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
    .form-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      padding: 0;
      min-width: auto;
      .form-group {
        margin-bottom: 0.5rem;
      }
      .field-title {
        width: 100%;
      }
      label {
        font-size: 16px;
        font-family: Inter;
        line-height: 24px;
      }
      .phone-field {
        .form-group {
          &:nth-child(2){
            width: 100%;
          }
        }
        .form-control {
          border: none;
          box-shadow: none;
          padding: 0;
        }

        display: flex;

        #countryCode {
          .os__control {
            border: none;
            box-shadow: none;
            background-color: #f4f7f9;
            border-radius: 8px 0 0 8px;
          }

          .os__value-container {
            width: max-content;
            margin: 0;
            padding: 0 8px 0 16px;
          }

          .os__single-value {
            color: #354554;
          }

          .os__input-container {
            padding: 0;
            margin: 0;
          }

          .os__menu {
            width: fit-content;
          }
        }
      }

      #phoneNumber {
        border-radius: 0 8px 8px 0;
        background-color: #f4f7f9;
        padding-left: 2px;
      }
    }
  }
  .modal-footer {
    width: 100%;
  }
}
