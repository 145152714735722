.image-picker {
  position: relative;
  cursor: pointer;
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .image-picker-overlay-text {
    width: 100%;
    padding: 0 15px;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 101;
    font-size: 12px;
    font-weight: $font-weight-light;
    letter-spacing: .5px;
    text-align: center;
    transform: translateY(-50%);
  }
  .current-image {
    position: relative;
    z-index: 12;

    img {
      width: 100%;
      height: 142px;
    }

    .image-picker-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 5px;
      align-items: center;
      border-radius: 8px;
      background: linear-gradient(to bottom, rgba(18, 18, 20, 0.5), #121214 100%);
    }

    .image-picker-overlay-arrow {
      width: 100%;
      display: block;
      position: absolute;
      bottom: 24px;
      left: 0;
      text-align: center;
      font-size: 12px;
      line-height: 1.33;
      display: flex;
      align-items: center;
      justify-content: center;

      .ifill-os-chevron-down {
        color: $level-11;
        margin-left: 4px !important;
      }
    }
  }

  .image-selector {
    width: 100%;
    border: solid 1px #6D6D6D;
    border-top: 0;
    padding: 10px 2px 2px 10px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    text-align: center;
    background-color: #000;
    border-radius: 0 0 8px 8px;

    &:before {
      content: '';
      height: 160px;
      border: solid 1px #6D6D6D;
      border-bottom: 0;
      position: absolute;
      top: -160px;
      left: -1px;
      right: -1px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    img {
      width: calc(50% - 8px);
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      &.selected {
        border: 3px solid $lt-green;
      }
    }

    .image-selector-btn {
      padding: 10px 0;
      display: inline-block;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: $font-weight-light;
      text-align: center;
      letter-spacing: .5px;
      cursor: pointer;
    }
  }
}

.detail-board {
  position: relative;
  padding-top: 0;

  .no-content-placeholder {
    width: 100%;
    min-height: 300px;
    position: relative;
    margin-top: 50px;

    svg {
      + .no-content-placeholder-text {
          position: static;
          transform: none;
          top: unset;
          margin-top: 25px;
        }
    }
    .no-content-placeholder-text {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 10px;
    }
  }

  .detail-header {
    margin-bottom: 0px;
  }

  .pull-right{
    .delete-board{
      display: inline-flex;
      color: #5a5a5a;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      margin-right: 42px;
      letter-spacing: 1px;
      cursor: pointer;
    }
    .btn-primary{
      border-radius: 17px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 5px 20px;
      img{
        margin-left: 12px;
      }
    }
  }

  .btn-info {
    padding-left: 40px;
    padding-right: 40px;
    color: $level-11;
  }

  .case-details-content-block {
    margin-top: 10px;
  }

  .space-pending-table {
    .invite-block-group {
      .icon-span {
        display: flex;
        width: 16px;
        height: 16px;
        line-height: 12px;
        min-width: 16px;
        font-size: 10px;
        right: -6px;
      }
    }
  }
}

.modal-footer {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  &.user-board-modal-footer {
    justify-content: space-between;

    .user-board-modal-footer-buttons {
      .text-right {
        margin-left: 30px;
        display: inline-block;

        &:first-child {
          margin-left: 0;
        }
      }

      .board-cancel-btn {
        @media(max-width: 400px) {
          margin-right: 5px !important;
        }
      }

      .edit-board-cancel-btn {
        &:hover {
          color: $level-11;
        }
      }
    }

    .ifill-os-tick-single {
      margin-left: 5px;
    }
  }
}

.modal-edit-board {
  padding-bottom: 100px;
  padding-top: 24px;


  .full-height-col {
    .board-edit-left {
      min-height: 100%;
    }
  }

  .modal-content {
    padding: 15px;
    border-radius: 0;
    padding: 0;
  }

  /*.board-listing-cards-row {
    margin-top: 45px;

    .ball-triangle-path {
      position: absolute;
      left: 50%;
    }
  }

  .tags-container {
    padding-top: 0;

    &.tags-cases {
      margin-bottom: 20px;
      padding-bottom: 10px;
      position: relative;

      &::after {
        content: '';
        width: calc(100% + 120px);
        height: 1px;
        position: absolute;
        bottom: 0;
        left: -60px;
        background: rgba(255, 255, 255, .2);
      }
    }
  }*/

  /*.filter-tab-sticky {
    position: sticky;
    top: 120px;
    z-index: 95;

    &.fixed {
      width: 100vw;
      background: $block;
      .tag-item {
        margin-top: 12px;
      }

      .tags-view-all,
      .tag-buttons {
        top: 11px;
      }
    }
  }*/

  /*.object-search-block {
    display: flex;
  }

  .object-search-holder {
    width: 100%;
    position: relative;
    align-self: flex-end;

    i {
      position: absolute;
      top: 5px;
      left: 10px;
      font-size: 21px;
    }
  }

  .object-search-input {
    width: 100%;
    height: 34px;
    border: none;
    padding: 0 10px 0 30px;
    color: $level-11;
    font-size: 14px;
    font-weight: 500;
    background: #4D4D4D;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .object-view-switcher {
    padding-right: 0;
    display: none;
    position: relative;
    bottom: -4px;
    z-index: 9;

    .sorting-block {
      position: relative;
      text-align: left;
      .sorting-holder {
        display: none;
      }
    }

    .sorting-dropdown {
      top: 30px;
      left: 0;
    }
  }*/

  .modal-footer {
    width: 100%;
    height: 80px;
    border-top: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 998;
    background-color: $block;

    &.footer-absolute {
      position: absolute;
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .mobile-status-row {
      margin-top: 20px;
    }

    .modal-footer {
      height: 80px;
      padding-left: 24px;
      padding-right: 24px;

      &.user-board-modal-footer {
        .user-board-modal-footer-buttons {
          .text-right {
            margin-left: 10px;
          }
        }
      }

      .container {
        padding: 0;
      }

      .btn {
        min-width: 1px;

        &.btn-small-text {
          height: 30px;
          font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .board-profile-description-block {
    font-size: 12px;
    line-height: 1.5;

    .user-profile-bio {
      width: 100%;
      padding: 0;

      &::after {
        display: none;
      }

      .my-3 {
        font-size: 12px;
        line-height: 16px;

        p {
          margin-bottom: 0;
        }
      }

      .user-bio-link {
        font-size: 12px;
      }
    }

    .board-profile-description-text {
      padding-right: 0;
    }
  }

  .see-all-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .board-user-block {
    margin-top: 0;
    position: relative;
    z-index: 2;

    .board-profile-description-text {
      p {
        margin-bottom: 0;
      }
    }

    .user-block-row {
      display: none;
    }
  }
}

.edit-board-type {
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 2;
  font-size: 12px;
  pointer-events: none;

  i {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 11px;
  }
}

.board-form-content {
  .tab-sticky {
    position: sticky;
    top: 40px;
    z-index: 98;
  }

  .os-card-v2 {
    .card-dropdown-menu {
      display: none;
    }
  }
}


.detail-board {
  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    min-height: calc(100vh - 170px);
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) and (orientation: portrait) {

  .detail {
    .detail-header {
      padding-right: 130px;

      .user-case-id {
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation: portrait) {
  .detail-board {
    .related-cards-nowrap {
      white-space: normal;
      flex-wrap: wrap;

      .col-12:first-child {
        padding-left: 15px !important;
      }
    }
  }
}

.detail-board {
  .modal-xl{
    &.add-message-modal {
      .add-message-nav-block {
        .object-header-bar {
          margin-top: 0px;
        }
      }
      .add-message-secondary-nav-block {
        padding-top: 0px;
        .object-header-bar {
          margin-top: 0px;
          margin-bottom: 6px;
        }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
          .object-header-bar {
            margin-top: 0px;
            margin-bottom: 0px;
            .list-inline-item {
              &.object-filter-active {
                padding-bottom: 7px;
                &:after {
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.spaces {
      #root {
        .detail-board {
          min-height: 862px;
        }
      }
      .object-filter {
        li {
          margin-right: 20px !important;
        }
      }
    }
  }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)  and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.spaces {
      #root {
        .detail-board {
          min-height: 780px;
        }
      }
    }
  }
}
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait)   and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.spaces {
      #root {
        .detail-board {
          min-height: 1200px;
        }
      }
    }
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : landscape)   and (-webkit-min-device-pixel-ratio: 1) {
  body{
    &.spaces {
      #root {
        .detail-board {
          min-height: 870px;
        }
      }
    }
  }
}

.ipad {
  &.landscape {
    .board-edit-left {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.mobile-device {
  .space-header-info-group {
    padding-left: 0;
    padding-right: 0;
  }
  .detail-board {
    .detail-header {
      margin-bottom: 24px;
    }

    .head-sticky {
      z-index: 11;
    }


    .detail-comments {
      .mobile-hashtag {
        flex-basis: 100%;
        max-width: 100%;
        width: 100%;
        margin: 0 0;

        .sidebar-container {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }

    .discussion-thread-section {
      .create-slider {
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-right: -16px;
      }
    }
  }

  .boards,
  .spaces {
    .modal-edit-board {
      padding: 20px 12px 0 12px;
    }
  }

  .board-header-mobile {
    .sub-nav-background {
      padding: 24px 0;
    }

    .object-header-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .object-view-switcher {
      width: auto;
      position: relative;
      transform: translateY(0);
      margin-right: 10px;
    }

    .object-view-button-block {
      &:not(.spaces-cta) {
        display: none;
      }
    }
  }

  .privacy-control-box {
    padding-left: 24px;
  }

  &.portrait {
    .detail-board {
      padding-left: 0;
      padding-right: 0;

      .activity-container {
        padding-left: 0;
        padding-right: 0;
      }

      .information-tab {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  .container {
    &.px-m-0 {
      padding-left:0;
      padding-right: 0;
    }
  }

  .space-members-group-container {
    .spc-tab-heading {
      padding-left: 20px;
    }
  }
}

.create-space-head {
  margin: 0px -12px 12px !important;

  .small-link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.spaces {
  .h1-page-heading {
    margin-top: 0;
    margin-bottom: 0;
    @extend %heading-2-semibold;

    @media(min-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    @media(min-width: 1440px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .uploaded-image-block-holder {
    margin-bottom: 24px;
  }
}

textarea.board-edit-textarea {
  padding-bottom: 0;
  height: 40px;
  line-height: normal;
  padding-top: 8px;

  &.is-editable-textarea {
    resize: none;
    height: 80px;
  }
}

.no-touch-support,
.ipad {
  .board-edit-title-row {
    display: flex;
    margin-bottom: 8px;

    .board-edit-title {
      flex: 1;
    }

    .btn-toolbar {
      min-width: 223px;
      white-space: nowrap;
      display: block;
      margin: 24px 0 0 25px;
    }

    .form-group {
      position: relative;
    }

    .form-error {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
}

.new-item-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $block;
  border-radius: 8px;
  position: relative;
  height: 320px;
  box-shadow: $block-shadow;

  &.new-item-160 {
    height: 96px;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.no-touch-support,
.touch-support:not(.mobile-device) {
  .space-links-container, .activity-container {
    min-height: 120px;

    .cards.row {
      margin-left: -12px !important;
      margin-right: -12px !important;
    }
  }
}

.hint-text {
  min-height: 40px;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 8px 16px;
  @include fontstyle(16px, normal, 1.5);
  background: $block;

  &.space-banner {
    background: $block;
  }
}

.mobile-device {
  .create-space-head {
    flex-direction: colum;
    align-items: flex-start !important;
    justify-content: flex-start !important;

    .ml-auto {
      margin-left: 0 !important;
    }
  }

  .space-links-container {
    .cards {
      padding-top: 0 !important;
      padding-left: 0;
      padding-right:0;

      > .col-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .pulse-card-overlay {
      left: 0;
      right: 0;
    }

    &.comment {
      .card--report {
        max-width: 240px;
        height: 120px;
        padding: 0px 24px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .new-item-card {
    height: 160px;
  }

  &.portrait {
    .new-item-card {
      padding:0;
      height: 160px;

      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .space-links-container.comment {
        .card--report {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.no-touch-support, .ipad{
  .board-tab-style {
    .px-tab-0 {
      padding: 0px;
    }
  }
}

.mobile-device {
  .board-header-row  {
    margin: 0px !important;
    width: 100%;
  }
}


.mobile-device {

  &.portrait {
    .delete-mode-active {
      display: flex;
      justify-content: space-between;
      width: 100%;

      + .user-board-modal-footer-buttons {
          display: none;
        }
    }
  }

  &.landscape {
    .detail-board {
      .row.uw-100 {
        margin: 0px !important;
        width: 100% !important;
      }
    }
  }

  &.portrait {
    .delete-mode-active {
      display: flex;
      justify-content: space-between;
      width: 100%;

      + .user-board-modal-footer-buttons {
          display: none;
        }
    }
  }

  .modal-edit-board {
    padding-bottom: 70px;
    .modal-content {
      padding-top: 0;
    }
  }

  .user-board-modal-footer {
    .osbtn {
      line-height: 40px;
      min-height: 40px;
    }
  }

  .board-edit-title {
    .head-1 {
      font-size: 20px;
    }
  }
}

.no-touch-support {
  .spaces {
    .board-user-block {
      padding: 0px !important;
    }
  }
}

.mobile-device {
  .spaces {
    .svg-icon {
      max-height: 128px;
    }
  }

  .detail-board {
    .no-cases-block {
      padding: 40px 0;
    }

    .no-content-placeholder {
      min-height: 1px;
      padding-top: 100px;
    }

    .cards {
      &.row {
        margin: 0;
        padding:0 20px 5px;
        width: 100%;
      }
    }
  }

  .back-text {
    font-size: 12px;
    text-transform: uppercase;
  }

  .user-name-title {
    margin-bottom: 0;
    color: $level-9;
    font-size: 12px;
    font-weight: normal;
  }

  .user-name {
    line-height: 1;
    .a-link {
      font-size: 12px;
      color: $level-9;
    }
  }

  &.portrait {
    .board-user-block.no-description {
      display: none;
    }
  }

  &.landscape {
    .mobile-landscape {
      flex-basis: 100%;
      max-width: 100%;
    }

    .detail-board {
      .cards{
        &.row {
          margin: 0px -12px;
          width: calc(100% + 24px);
          padding: 20px 0;
        }
      }

      .sticky-tab-with-dropdown {
        .object-header-bar {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.mobile-device{
  .board-dis-sec {
    &.no-margin-m {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.landscape {
    .no-margin-m {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.ipad.portrait {
  .no-margin-m:not(.board-dis-sec) {
    margin-left: 0;
    margin-right: 0;
  }
}

.detail-page,
.edit-page {
  .spaces {
    .os-tab-style {
      .px-tab-0 {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

.edit-page {
  .spaces .subspace-container {
    .back-nav-bar {
      margin: 24px 0 16px;

      .osbtn {
        padding-left: 32px;
        left: 8px;

        .btn-icon-helper {
          left: 8px;
        }
      }
    }
  }
}

@media(min-width: 768px) {
  .board-save-btn {
    margin-left: 16px !important;
  }
}

.board-edit-left {
  background-color: $block;
  padding: 24px 32px 24px 40px;
  border-radius: 8px;
  box-shadow: $block-shadow;
  margin-top: 0;

  .h1-page-heading {
    margin-top: 0;
  }
}

.add-cnt-spc-btn {
  margin-top: 16px;
  margin-bottom: 16px;
}

.add-cnt-spc-btn-tm {
  display: none;
  background: $block;
  border-radius: 0px 0px 8px 8px;
  padding-left: 20px;
  padding-bottom: 16px;
  padding-top: 40px;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    background: #5c5c66;
    height: 1px;
    width: calc(100% - 32px);
    position: absolute;
    top: 16px;
    left: 16px;
  }

}

@media screen and (max-width: 1024px) {
  .board-edit-left {
    padding: 24px 40px 0;
    border-radius: 8px 8px 0 0;
  }
}

.ipad {
  &.portrait {
    .board-edit-left {
      padding: 24px 40px 0 ;
    }

    .add-cnt-spc-btn {
      display: none;
    }

    .add-cnt-spc-btn-tm {
      display: block;
      &:before {
        width: calc(100% - 80px);
        top: 16px;
        left: 40px;
      }
    }
  }
}

@media screen and  (max-width: 767px) {
  .board-edit-left {
    padding: 32px 16px 0;
    border-radius: 0 0  8px 8px;
    box-shadow: none;

    .add-cnt-spc-btn {
      display: none;
    }
  }

  .add-cnt-spc-btn-tm {
    display: block;
    padding-left: 0;
  }

  .spaces {
    .h1-page-heading {
      margin-bottom: 12px;
      font-size: 20px;
    }
  }

  .edit-page .spaces .back-nav-bar {
    margin-top: 0;
  }
}

.tab-min-height {
  @media(min-width: 768px) {
    min-height: calc(100vh - 100px)
  }
}

.spaces {
  .course-start-info {
    max-width: 273px;
  }
}
