.modal-dialog.video-recorder-modal {
  max-width: 860px;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;

  .modal-content {
    padding: 32px 24px;
    height: 85%;
    max-height: 648px;
  }

  .modal-header {
    padding: 0 0 1.25rem;
    align-items: center;

    .card-title {
      + .osbtn:not(.close-edit-image-btn) {
        position: absolute;
        top: 32px;
        right: 56px;
      }
    }

    .os-header-btn {
      top: 25px;
    }
  }

  .lecture-loader {
    display: none;
  } 

  .modal-body {
    padding: 0;
  }

  .recording-status {
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    z-index: 1;
    padding-left: 15px;
    display: inline-flex;
    align-items: center;
    top: 15px;
  }

  .timer__Text-avqbub-0 {
    display: flex;
    align-items: center;
    top: -78px;
    
    @media(min-width: 768px) {
      top: -50px;
    }
  }


  .oval {
    height: 8px;
    width: 8px;
    background-color: $lt-green !important;
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px;
    margin-right: 5px;
  }

  .eElzlq {
    margin-bottom: 0;
  }

  [data-qa="start-recording"],
  [data-qa="turn-on-camera"],
  [data-qa="stop-recording"],
  [data-qa="start-replaying"] {
    font-size: 12px;
    font-weight: $font-weight-bold;
    color: $btn-primary-color;
    background-color: $btn-primary;
    line-height: $btn-height;
    padding: 0;
    border: 0;
    text-transform: uppercase;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
    transition: color 500ms ease, background 200ms ease;
    will-change: color, background;
    height: $btn-height;
    width: 160px;
    position: relative;
    -webkit-appearance: none !important;
    vertical-align: middle;
    outline: 0;

    &:focus {
      outline: 0 !important;
    }

    &:hover {
      background-color: $btn-primary-hover;
    }
  }

  [data-qa="start-recording"] {
    &:before {
      content: 'start recording';
    }
  }

  [data-qa="stop-recording"] {
    &:before {
      content: 'stop recording';
    }
  }
  
  [class*="record-button__Instructions"] {
    display: none;
  }
  
  [class*="video-recorder__Wrapper"] {
    overflow: unset;
    height: 100%;
    width: 100%;
    background-color: transparent;
    padding-bottom: 64px;

    [class*="video-recorder__CameraView"] {
      position: relative;
    }

    video {
      top: 0;
      transform: translate(-50%, 0) scale(1);
      object-fit: contain;
      border-radius: 8px;
      /*min-height: calc(100% - 64px);
      height: calc(100% - 64px);*/
    }
  }

  .kBCwrU {
    display: none;
  }

  .stop-button__Border-sc-1h536gx-1,
  .record-button__ButtonBorder-sc-1n5amwk-2 {
    width: unset;
    height: unset;
    min-width: 175px;
    border: 0 !important;
    border-radius:0;
    text-align: center;
    background-color: transparent !important;
  }

  .render-actions__ActionsWrapper-dp6lnv-0 {
    left: 0;
    right: 0;
    top: auto;
    height: 40px;
    bottom: 0px;
  }

  .modal-footer {
    height: 64px;
    justify-content: space-between;
    display: flex;
    align-items: flex-start;

    .video-msg-name {
      display: inline-block;
      padding-right: 16px;
      @include text-truncate();
      max-width: 350px;
      line-height: 40px;
      vertical-align: middle;

      + span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .btn-grp {
      text-align: right;
    }
  }

  @media(max-width: 767px) {
    padding: 0;
    max-width: 100%;
    margin: 0;
    height: 100%;

    .modal-content {
      height: 100%;
      max-height: 100%;
      padding: 10px 0 26px;
    }

    .modal-header {
      padding-top: 10px;
      min-height: 53px;
      justify-content: flex-start;
      padding-left: 10px;

      .card-title {
        font-size: 18px;
        line-height: 1.5;
        padding-right: 100px;

        + .osbtn:not(.close-edit-image-btn) {
          position: absolute;
          top: 20px;
          right: 56px;
        }
      }

      .os-header-btn {
        top: 17px;
        right: 0;
      }
    }

    .video-msg-edit {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: space-between;
      padding: 0 12px !important;
      align-items: center;
      position: relative;

      .video-msg-name {
        max-width: 100%;
        padding-bottom: 8px;
      }

      .osbtn:not(.post-message-button) {
        position: absolute;
        left: 0;
        top: 48px;
      }

      .card-attachment-body {
        &.with-rename {
          width: 100%;
        }
      }

      .with-error {
        text-align: left;
        position: absolute;
        top: 40px;
      }
    }

    .btn-grp {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 12px !important;
      align-items: center;
    }

  }
}

.record-video-uploading {
  min-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
