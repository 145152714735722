.treatment-progress {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;

  .container {
    padding-left: 20px;
  }

  .treatment-header {
    display: flex;
    align-items: center;

    .headerTitle {
      height: 16px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: rgb(126, 145, 165);

      @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 28px;
      }

    }
  }

  .completed-and-left-appointment {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .treatment-completed {
      // height: 1px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: rgb(53, 69, 84);

      input {
        width: 25px;
        border: none;
        padding-right: 0;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }

    .left-appointment {
      height: 12px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: rgb(53, 69, 84);

      input {
        width: 16px;
        border: none;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  .progress-bar-container {
    width: 100%;
    margin-top: 4px;

    .slider {
      width: 100%;
      height: 4px !important;
      cursor: pointer;
    }

    .patient-progress-bar {
      width: 100%;
      height: 4px !important;
      position: relative;
      background-color: rgb(193, 215, 247);
      border-radius: 2.5px;

      .progress {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 4px !important;
        background-color: rgb(76, 137, 229);
        border-radius: 2.5px;
      }
    }
  }

  .end-date-and-status {
    width: 100%;
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .end-date-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .end-date-text {
      // height: 12px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.2px;
      text-align: left;
      color: rgb(129, 141, 153);
    }

    .end-date-dropdown {
      display: flex;
      gap: 4px;
      padding-left: 2px;
    }
  }

  .status-dropdown {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .status {
      // height: 12px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      text-align: left;
      color: rgb(129, 141, 153);
    }
  }
}