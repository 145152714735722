.notifications-preferences {
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  .notifications-preferences-block{
    padding: 20px 30px;
    width: 100%;
    height: auto;
    padding: 15px;
    background: #1e1e1e;
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
    border-radius: 4px;
    margin-bottom: 30px;
  }
  .notify-prefer-title {
    margin: 0;
    padding: 0 0 10px 0;
    color: #aba9a9;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
  }
  .notify-prefer-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .notify-prefer-row {
    width: 100%;
    margin: 10px 0 0;
    padding: 0;
    display: flex;
    align-items: baseline;
    font-size: 14px;
    font-weight: $font-weight-light;
    color: #aba9a9;

    .notify-prefer-label {
      width: 70%;
      font-size: 14px;
    }

    .notify-prefer-value {
      width: 80%;
      padding-left: 10px;
    }
  }

}
@media screen and (max-width: 767px) {
  .notifications-preferences {
    .notify-prefer-row {
      justify-content: space-around;
      .notify-prefer-label {
        width: 80%;
      }
      .notify-prefer-value {
        text-align: right;
      }
    }
  }
}
