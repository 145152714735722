.communication{
  &__container{
    position: relative;
  }

  &__new-message{
    position: absolute;
    bottom: -27px;
    right: 0;
    z-index: 1021;
    width: 158px;

    i{
      font-size: 13px;
      vertical-align: middle;
      height: auto;
      margin-left: 16px;
    }
  }

  &__height-100{
    height: calc(100% - 250px) !important;
    padding-top: 34px;
    display: table !important;
    min-height: 750px;

    .col-4{
      display: table-cell;
      width: 33.33333%;
    }

    .col-8{
      display: table-cell;
      width: 66.66667%;
      height: 100%;
    }
  }

  .sticky-top{
    margin-top: 8px;
    .sub-nav-background {
      padding-top: 6px;
    }
    ul{
      li, li::after{
        margin-left: 0;
      }
      li{margin-right: 45px !important}
      li::after{width: 100%}
    }
  }

  .react-scrollbar-custom-1{
    height: 749px;
    margin-top: 10px;
    height: calc(100% - 47px);
  }

  .users{
    .react-scrollbar-custom-1{
      height: 802px;
      margin-top: 10px;
      height: auto;
      max-height: calc(100vh - 355px);
    }
  }

  .react-scrollbar-default{
    padding-right: 10px;
  }

  .oval {
    border-radius: 10px;
  }

  .notification-clear-all{
    cursor: pointer;
    color: #fff;
    font-size: 11px;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    .times{
      margin-left: 10px;
      border: 1px solid #fff;
      border-radius: 2px;
      line-height: 11px;
      text-align: center;
      font-style: normal;
      font-size: 7px;
      padding: 0px 2px;
    }
  }
}


.cannot-reply {
  pointer-events: none;
  .conversation-input-holder {
    width: 100% !important;
  }

  .media-body {
    textarea {
      width: 100% !important;
      padding-right: 16px !important;
    }
  }
  .ifill-os-send {
    display: none !important;
  }
  .conversation-options-row {
    display: none !important;
  }
}

.ipad {
  &.portrait {
    .cannot-reply {
      textarea::placeholder {
        font-size: 12px;
      }
    }
  }
}

.conversation-card-container,
.conversation-attachment-file-holder,
.add-message-modal {
  .notification-heading  {
    position: absolute !important;
    z-index: 1;
  }

  .edu-video-title {
    @include text-truncate;
    padding: 15px 8px 10px;
    height: 40%;
    line-height: 1;
  }
}


.mobile-device {
  .cannot-reply {
    .chat-attach-mobile-link {
      display: none !important;
    }
    textarea{
      padding-left: 12px !important;
      padding-right: 12px !important;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
}
