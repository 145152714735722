.user-profile-btns {
  .accepted-connection-button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}
.detail-user {
  .detail-header {
    color: $heading;
    @extend %heading-1;
    .user {
      align-items: flex-start;
    }

    .user__info {
      padding-left: 12px !important;
    }

    .user-profile-bio {
      width: 100%;
      padding-top: 24px;
      padding-bottom: 0;
      color: $level-11;

      .bio-text-info {
        @extend %body-small-1-2;
      }

      &:after {
        content: none;
      }
    }

    .detail-btn-row {
      z-index: 991;
    }

    .user-details-tabs-block {
      margin-top: 20px;
    }
  }
}


.user-container  {
  padding-top: 24px;
}


.detail-user-form{
  .custom-file.preview{
    height: 100px;
    .custom-file-input{
      height: 150px;
    }
  }
  .custom-file{
    .custom-file-control{
      z-index: 3;
      height: 100px;
      margin-top: 15px;
      text-align: right;
      vertical-align: middle;
      .btn{
        display: block;
        margin-left: auto;
        margin-right: auto;
        i{
          padding-left: 5px;
        }
        &:before {
          content: '';
          display: inline-block;
          height: 100%;
          vertical-align: middle;
        }
        .btn-centered-text{
          vertical-align: middle;
          display: inline-block;
        }
      }
      .signup-card-logo{
        margin: auto;
        height: 86px;
        width: 86px;
        line-height: 86px;
      }
    }
    .custom-file-input{
      height: 150px;
    }
    &.cropping .custom-file-input,
    &.cropping .custom-file-control{
      height: 70px;
    }
  }

  .contact-action{
    color: $level-4;
    font-size: 14px;
    margin-top: -20px;
    margin-bottom: 20px;
  }
  &.image-edit{
    .custom-file-control{
      text-align: left !important;
    }
  }

  .detail-user-form-bio{
    .form-group{
      height: 100%;
      textarea{
        height: calc(100% - 30px)
      }
    }
  }
}

.user-bio-toggle{
  cursor: pointer;
  color: $level-1;
  font-size: 11px;
  font-weight: $font-weight-bold;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  i{
    margin-left: 10px;
    margin-right: 7px;
    &.times{
      margin-left: 0;
      border: 1px solid $level-1;
      border-radius: 2px;
      line-height: 11px;
      text-align: center;
      font-style: normal;
      font-size: 7px;
      padding: 0px 2px;
      padding-left: 3px;
    }
  }
}

.follow-sidebar{
  .follow-list{
    border-bottom: 1px solid rgba(255,255,255, 0.15);
    height: 350px;
    overflow-y: auto;
    padding-top: 5px;
    .follow-item{
      padding-top: 16px;
    }
  }
}

.user-details-tabs-block {
  .user-details-tabs-header {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .user-tabs-title {
      margin-bottom: 0;
    }

    .user-details-plan-text {
      font-size: 12px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .user-tabs-upgrade-btn {
      margin-left: 10px;
    }
  }

}

.connections-container,
.user-tab-container {
  h3 {
    &.cards-row-title {
      @include fontstyle(20px, normal, 1.5);
    }
  }
}

.modal-dialog {
  &.profile-image-modal {
    width: 488px;

    .modal-header {
      padding: 24px 32px 0 ;
      border-bottom: solid 1px rgba(255, 255, 255, .25);

      .card-title {
        line-height: 40px;
      }

      .ifill-os-edit{
        margin-right: 24px;
        position: relative;
        top: 3px;
      }

      .close-edit-image-btn {
        right: 32px;
        top: 24px;
      }
    }

    .modal-content {
      padding: 0;

      .cropper-bg {
        border: solid 1px $level-1;
        overflow: hidden;
        border-radius: 100px;
      }

      .modal-body {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .user-profile-image {
      height: auto !important;
      /*margin-bottom: 40px;*/

      .user-image-upload-btn {
        width: 180px !important;
        height: 34px;
        color: $level-1;
        background: $level-11;
        cursor: pointer;
      }
    }

    .custom-file-control {
      .os-btn-link {
        cursor: pointer;
      }
    }

    .profile-file-btn {
      position: absolute;
      left: calc(50% - 8px);
      width: 185px;
      height: 22px;
      bottom: 16px;
      top: auto;
      overflow: hidden;
      transform: translateX(-50%);
        .custom-file-input {
          position: absolute;
          right: 0;
          top: 0;
          height: auto;
          opacity: 0;
          width: 100%;
          cursor: pointer;
          max-width: unset;
          height: 100%;
        }
    }

    .signup-card-logo{
      height: 120px;
      width: 120px;
      line-height: 130px;
      margin-bottom: 0;
      margin-top : 32px;
      background: $level-3;
      border-radius: 50%;
      text-align: center;
      color: $level-11;
      font-size: 24px;
    }

    .custom-file-control {
      height: auto !important;
      /*padding: 0;
      top: calc(100% + 10px);*/
      position: relative !important;

      .btn {
        /*margin-top: 0 !important;*/
      }
    }

    .modal-edit-image-buttons-block {
      .form-group {
        margin-bottom: 0;
      }

      .image-edit-cropper-block {
        .cropper-container {
          &.cropper-bg {
            width: 175px !important;

            .cropper-crop-box {
              transform: translateX(0) !important;
            }
          }
        }
      }
      .custom-file input[type="range"] {
        background-color: transparent;
      }
    }

    .modal-footer {
      padding: 80px 32px 40px;
      display: flex ;
      justify-content: space-between;


      .btn {
        width: 180px;
      }
    }

    .modal-edit-image-delete-btn {
      font-weight: $font-weight-light;
      letter-spacing: .5px;
    }

    .apply-btn {
      margin-top: 30px;
      border: solid 1px $border-button-1;
      color: rgba(255, 255, 255, .6);
      font-weight: $font-weight-light;
      letter-spacing: .5px;
      background-color: transparent;

      &:hover {
        background-color: $level-10;
      }
    }

    @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
      width: calc(100% - 30px);
      margin: 15px;

      .modal-header {
        .card-title {
          font-size: 16px;
        }
      }
    }

    .signup-card-logo {
      .avatar {
        margin-top: 0;
      }
    }
  }
}

.singup-profile-pic-card {
  .user-profile-image {
    &.cropping {
      .image-edit-cropper-block {
        position: relative;
        z-index: 2;
      }
    }
  }

  .profile-file-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

      .custom-file-input {
        position: absolute;
        right: 0;
        top: 0;
        height: auto;
        opacity: 0;
        width: 300px;
        cursor: pointer;
        max-width: unset;
        height: 100%;
        width: 300px;
        min-height: 100%;
      }
  }
}


.no-popular {
  font-size: 20px;
  text-align: left;
  color: $level-11;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1.4;
  margin-top: 30px;
}

.no-cases-block {
  width: 100%;
  padding: 54px 0 54px;
  text-align: center;
  color: $lt-orange;
  line-height: 0;

  .icon-no-content {
    font-size: 100px;
    display: block;
  }

  &.course {
    color: $level-11;
  }

  .no-button {
    .no-osbtn{
      margin-bottom: 24px;
    }
  }

  .no-popular {
    font-size: 20px;
    text-align: left;
    color: $level-11;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.4;
    margin-top: 30px;
  }

  &.post {
    padding-top: 54px;

    .no-cases-message-block {
      font-size: 20px;
    }
  }

  &.comparison {
    .no-cases-image-block {
      img {
        max-width: 170px;
      }
    }
  }

  &.comparisons {
    .no-cases-image-block {
      img {
        max-width: 170px;
      }
    }
  }

  .no-cases-image-block {
    img {
      max-width: 100px;
    }
  }

  &.notifications {
    .no-cases-image-block {
      img {
        max-width: 100px;
      }
    }
  }

  .a-link {
    line-height: 16px;
    font-size: 12px;
  }

  .no-cases-message-block {
    @extend %body-small-1-5;
    color: $level-7;
    display: inline-block;
    padding-top: 24px;
    padding-bottom: 24px;

    &.no-lectures {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      max-width: 500px;
    }

    &.empty-new-section {
      display: flex;
      align-items: center;
      color: $level-8;
      @extend %heading-5;
      justify-content: center;

      @media(max-width: 768px){
        flex-direction: column;
      }
    }

    .info-icon {
      color: $level-8;
    }
  }
}

.btn-request {
  width: 160px;
  position: relative;
  opacity: .7;
  cursor: not-allowed;

  &::after {
    content: 'Request Was Sent';
  }

  &:hover {
    opacity: 1;
    cursor: pointer;

    &::after {
      content: 'Cancel';
    }
  }
}

.account-setting-container {
  padding-top: 20px;

  .detail-header {
    margin-bottom: 30px;
  }

  .head-sticky {
    &.fixed {
      background-color: $level-1;
    }
  }

  &.os-acctoun-tab-style {
    .object-filter{
      &:after {
        left: 15px;
        right: 15px;
      }
    }
  }

  .notifications-preferences{
    .notification_subheading {
      font-size: 12px;
      color: $heading;
      margin-left: -15px;
      margin-right: -15px;
    }
    .table-notification {
      background: $block;
      box-shadow: $block-shadow;
      border-radius: 10px;
      padding: 15px 32px 11px;
      margin-bottom: 30px;
      max-width: 624px;

      table {
        margin: 0;

        > :not(:first-child) {
          border-color: $border-button-1;
        }
      }

      th, td{
        border: 0;
      }
      thead {
        border-bottom: 1px solid $border-button-1;
        th {
          font-size: 12px;
          color: $heading;
          padding: 5px 5px 5px 0;
          letter-spacing: 0.39px;

          + th {
            width: 92px;
          }

          &:last-child {
            width: 48px;
            white-space: nowrap;
            padding-right: 0;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $border-button-1;
          &:last-child {
            border-bottom: 0;
          }
        }
        td {
          font-size: 16px;
          padding: 17px 5px 17px 0;
          vertical-align: middle;
          letter-spacing: 0.39px;

          span {
            font-size: 12px;
            display: block;
            line-height: 16px;
          }
          .notify-prefer-row {
            margin-top: 0;
            .notify-prefer-value {
              padding-left: 0;

            }
          }
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .detail-header {
      margin-bottom: 1px;

      .detail-type {
        display: block !important;

        h3 {
          margin-bottom: 0;
          font-size: 21px;
        }
      }
    }

    .object-header-bar {
      .object-filter {
        width: 100% !important;
      }
    }

    .notifications-preferences {
      .notification_subheading {
        margin-left: 0;
        margin-right: 0;
      }
      form.row {
        margin-left: 0;
        margin-right: 0;
        .table-notification {
          padding-left: 15px 15px 4px;

          thead {
            th {
              + th {
                width: 64px;
              }

              &:last-child {
                width: 48px;
              }
            }
          }
          tbody {
            td {
              vertical-align: middle;
              &:first-child {
                white-space: normal;
                padding-right: 32px;
              }
            }
            .notify-prefer-row {
              .notify-prefer-value {
                width: 100%;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

.Select {
  .Select-menu-outer {
    border: 0 !important;
    margin-top: 0;
  }

  .Select-option {
    background-color: inherit;
    color: $level-11;

    &:hover,
    &.is-focused {
      background-color: $dropdown-list-hover !important;
      color: $os-link;
    }

    &.is-focused {
      color: $level-11;
    }
  }

  .Select-placeholder,
  &.Select--single > .Select-control .Select-value {
    line-height: 36px;
    color: $level-11;
    padding-left: 15px;
  }

  .Select-placeholder {
    color: $level-9;
  }

  &.is-open > .Select-control,
  .Select-control {
    border-radius: 4px;
    border-color: $os-link;
  }
}


.no-touch-support {
  .Select .Select-option {
    &:hover {
      background-color: $dropdown-list-hover !important;
      color: $os-link;
    }
  }

  .profile-file-btn:hover {
    ~ .custom-file-control .osbtn {
        background-color: rgba(72, 172, 253, 0.2);
        color: $os-link;
      }
  }
}

.account-setting-container {
  .object-filter {
    li {
      margin-right: 30px !important;
    }
  }

  .os__control .os__value-container {
    padding-left: 14px;
    padding-right: 14px;
  }
}


@media screen and (max-width: 450px) {
  .account-setting-container {
    .user-invitation-header-title {
      .invites-left-count {
        display: block;
        font-size: 11px !important;
      }
    }
  }
}

.mobile-device {
  &.portrait {
    .payment-info-section {
      .payment-info-cards {
        margin-top: 30px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .connections-container, .user-tab-container {
    h3 {
      &.cards-row-title {
        @include fontstyle(16px, normal, 1.5);
      }
    }
  }

  .users {
    .user-tab-container {
      padding: 0 16px;
    }
  }
}


.user-right-section {
  flex: 100% !important;
  max-width: 100% !important;
}

.address-block {
  font-size: 12px;
  color: $level-11;
  font-weight: normal;
}

.avatar{
  &.avatar-168 {
    i {
      padding-top: 0;
    }
  }
}


.ipad {
  &.portrait {
    .user-container {
      padding-right: 0px;
      padding-left: 0px;
    }

    .spce-left {
      min-height: 46px;
    }

    .user-profile-header {
      padding-top: 0px !important;
    }

    .user-clinic-details {
      margin-top: 16px !important;
    }

    .user-profile-pic-block {
      .avatar-168 {
        @include avatar(98px);

        .icon-span {
          @include icon-span(40px);
        }
      }

      .user-image-edit-btn {
        min-width: 32px !important;
        min-height: 32px !important;
        width: 32px !important;
        height: 32px !important;
        line-height: 32px !important;
        /*top: 70px !important;
        right: auto !important;
        left: 88px !important;*/

        @media screen and (max-width: 820px) {
          /*left: 60px !important;*/
        }
      }
    }
  }
}

.no-touch-support, .ipad {
  .users{
    .user-profile-header {
      .user-options-block.engagement-new {
        .osbtn {
          &:first-child {
            margin-left: -8px;
          }
        }
      }
    }
  }
}

.ipad{
  &.portrait {
    .users{
      .user-profile-header {
        .user-profile-pic-block {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
}


.mobile-device {
  .no-cases-block {
    padding-top: 39px;

    svg {
      max-height: 128px;
    }

    .no-cases-message-block {
      font-size: 16px;
      padding-bottom: 16px;
      padding-top: 16px;

      &.no-lectures {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .no-popular {
      padding-left: 0px;
    }
  }
}

.user-tab-container {
  @media screen and (max-width: 767px) {
    &.timeline {
      padding-left: 16px ;
      padding-right: 16px ;
    }
  }

  &.courses {
    .on-demand-content-container {
      margin-top: 0;
    }
    .see-more-btn {
      top: -5px;
    }

    @media screen and (max-width: 767px) {
      .spc-tab-heading {
        padding-left: 0;
      }

      .see-more-btn {
        right: -22px;
      }
    }
  }

  &.instructed_courses {
    .see-more-btn {
      top: 0px;
    }

    @media screen and (max-width: 767px) {
      .see-more-btn {
        margin-right: -12px;
      }
    }
  }

  &.attended_courses {
    .spc-tab-heading {
      margin-bottom: 24px;
    }

    .on-demand-content-container {
      margin-top: 0;

      .see-more-btn {
        top: -58px;

        @media screen and (max-width: 767px) {
          top: -54px;
        }
      }
    }
  }

  &.timeline {
    .activity-listing-data {
      .user-info-row {
        .avatar-listing {
          .act-avatar-item {
            .icon-span {
              display: none;
            }


            &:only-child {
              .icon-span {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .card-row-title {
    + .on-demand-content-container {
      margin-top: 0px;
    }
  }

  @media screen and (max-width: 767px) {
    &.px-0 {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

.courses {
  .sub-nav-background {
    .tab-nav-container {
      padding-left: 12px;
    }
  }
}


.no-touch-support {
  .user-profile-pic-block {
    .icon-span {
      right: 10px;
    }
    .accepted-connection-button {
      margin-top: 16px;
    }
  }
}

.folders {
  .no-cases-block {
    padding: 0;

    .no-cases-message-block {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
