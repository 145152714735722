.assign-overlay {
  transition: all 500ms;
  padding: 0;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1024px;
  max-width: 100%;
  background-color: $level-1;
  border-radius: 4px 4px 0 0;
  z-index: 1031;
  &.assign-overlay--show {
    height: 70%;
    top: 0;
  }


  .assign-overlay__content {
    padding: 0;

    .close-assign-overlay {
      right: -4px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .assign-title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      font-weight: $font-weight-lighter;
    }
    .assign-cancel {
      cursor: pointer;
    }

    .assign-overlay__left-panel {
      margin-bottom: 20px;
      .instructions {
        max-width: 250px;
        margin: 32px 0;
        font-size: 16px;
        line-height: 22px;
      }
    }

  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .assign-overlay__content {
      .assign-overlay__left-panel {
        margin-bottom: 0;
      }

      ul {
        &.assign-list {
          padding: 10px;

          .assign-list-header {
            margin-left: 0;
          }

          .assign-list-item {
            margin-left: 0;
            display: flex;
            align-items: center;
          }

          .list-item__name {
            width: 30%;
            font-size: 12px;
          }

          .list-item__details {
            width: 70%;
            padding-left: 10px;
            align-items: center;

            &> div {
              align-items: center;
            }
          }

          .details__left {
            display: none !important;
          }

          .details__right {
            width: 100%;

            .details__timestamp {
              font-size: 12px;
            }
          }
        }
      }
    }

    .modal-xl {
      &.modal-global {
        .modal-header {
          padding: 15px;

          .add-message-header-row {
            width: auto;

            .add-message-header-action {
              height: 1px;
              position: static;
            }
          }

          .comment-share-input {
            margin: 10px 0 0 -15px;
          }

          .ifill-os-close {
            position: absolute;
            font-size: 12px;
          }
        }

        .modal-body {
          padding: 15px;
        }
      }
    }
  }
}

.assign-background {
  position: fixed;
  z-index: 1052;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(235, 235, 235, 0.4);
}


.transparent {
  opacity: 0;
}

.mobile-device {
  @media screen and (max-width: 400px) {
    .close-assign-overlay {
      right: -14px !important;
    }
  }

  .multi-selection-mode {
    .space-v2-card  {
      .selectable-item {
        margin-top: 12px;
      }
    }
  }

  &.landscape {
    .close-assign-overlay {
      margin-top: 5px;
    }
  }
}
