body {
  &:has([cmdk-dialog]) {
    #root {
      opacity: 0.3;
    }
  }
}

[cmdk-dialog] {
  position: fixed;
  width: 700px;
  top: 100px;
  left: 50%;
  padding: 0;
  border-radius: 8px;
  z-index: 1000;
  transform: translate(-50%, 0%);
  box-shadow: $modal-shadow;
  background: white;
}

[cmdk-group-items] {
  margin-bottom: 16px;
}


[cmdk-input] {
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: none;
  padding: 16px 0 0 16px;
}

[cmdk-root] {
  max-height: 484px;
  overflow: auto;

  .input-option-separator {
    margin: 16px 0;
    border-top: 1px solid #B9C4CF;
  }
}


[cmdk-group-heading] {
  padding: 0 16px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;

  .group-heading {
    height: inherit;
    display: flex;
    color: $level-8;

    span {
      margin-left: 8px;
      color: $level-8;
      font-weight: 600;
      font-style: normal;
      font-size: 12px;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }

  }
}

[cmdk-empty] {
  margin: 0 0 8px 8px;
}

[cmdk-group] {
  //padding: 0 16px 0 16px;
}

[cmdk-item] {
  height: 32px;
  margin: 4px 0;
  padding: 0 4px 4px 0;
  align-items: center;
  font-size: 18px;
  display: flex;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    background: whitesmoke;
    border-radius: 4px;
  }

  .command-option {
    padding-left: 16px;
    display: flex;
    justify-content: center;
    align-content: center;

    i {
      margin: auto 0;
    }

    .command-option-label {
      color: #5D7893;
      font-weight: 400;
      font-size: 16px;
      font-style: normal;
      line-height: 16px;


    }

    &:has(i, img) {
      .command-option-label {
        margin-left: 8px;
      }
    }
  }

  .command-option-action {
    color: $level-8;
    font-size: 8px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    padding-right: 16px;

  }
}

[cmdk-item][data-selected] {
  background: whitesmoke;
}

.command-item {
  margin-left: 8px;
}

.metric-card-holder {
  display: flex;
  padding: 8px 16px;
  gap: 8px;
}

.metric-card-container {
  flex-grow: 1;
  height: 64px;
  border-radius: 8px;
  padding: 8px;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(53, 69, 84, 0.24), 0 1px 3px 0 rgba(53, 69, 84, 0.12);
  display: flex;
  flex-direction: column;
  gap: 8px;

  .card-header {
    background: unset;
    border: none;
    padding: 0;
    .card-heading {
      text-transform: uppercase;
      color: var(--neutrals-level-7, #818D99);
      font-family: Inter, serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      margin: 0;
    }
  }

  .card-metric {
    .metric-value {
      color: var(--neutrals-level-9, #5D7893);
      font-family: Inter, 'sans-serif';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-right: 4px;
    }
    .metric-unit {
      color: var(--neutrals-level-9, #5D7893);
      font-family: Inter, 'sans-serif';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-right: 4px;
    }

    .messages {
      .metric-extra-info {
        color: var(--neutrals-level-9, #5D7893);
        font-family: Inter, 'sans-serif';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .tasks {
      .metric-extra-info {
        color: var(--colors-icons-red, #E05257);
        font-family: Inter, 'sans-serif';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}