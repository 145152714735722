.top-tool-heading,
.sponsored-widget-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  color: $heading;
  margin-bottom: 8px;
}

.see-all-link {
  position: absolute;
  right: 16px;
  top: 22px;
}

.os-btn-link {
  &:hover {
    .top-tool-heading {
      color: $os-link-hover;
    }
  }
}

.sidebar-with-cards {
  .sidebar-container:not(.no-card-effect)  {
    padding-left: 16px;
    padding-right: 16px;
  }

  .home-loader {
    display: none;
  }
}

@media(min-width: 992px) and (max-width: 1199px) {
  .sidebar-with-cards {
    .os-sm-tool {
      padding-left: 43%;

      .tl-image {
        min-width: 43%;
        max-width: 43%;
      }
    }
  }
}

.recently-online-widget {
  .dr-connect-list-item {
    align-items: center;
  }
}

.ipad {
  &.portrait {
    .see-all-link {
      right: 32px;
    }

    .recently-online-widget {
      position: relative;

      .see-all-link {
        right: 0;
        top: 0;
      }

      .col-sm-6 {
        flex:0 0 100%;
        max-width: 100%;
      }
    }

    @media screen and (max-width: 1023px) {
      .top-tool-box {
        max-width: calc(100% + 24px);
        flex-basis: calc(100% + 24px);
        margin-left: -12px;
        margin-right: -12px;

        .sidebar-container {
          padding-bottom: 8px;
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
  }
}
