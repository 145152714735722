.label-option-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;

  .label-option-color {
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}

.subspace-list-row {
  .label-option-color {
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}

.person-option-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;

  .partner-space-listing-avatar {
    height: 16px !important;
    width: 16px !important;
    min-width: 16px !important;
  }
}
