.task-activity-feed-wrapper {
  display: flex;
  flex-direction: column;
  .member {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #edeff3;
    border-radius: 16px;
    padding: 4px 8px;
    justify-content: center;
    height: 32px;
    max-width: max-content;
    .memberName {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #7e91a5;
    }
  }
  .labels-container {
    display: flex;
    width: 100%;
    gap: 8px;
    margin: 4px 0;
    .labelMisc {
      max-width: max-content;
      height: 32px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      padding: 8px 12px;
      border-radius: 16px;
      flex-grow: 0;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      cursor: pointer;
    }
  }

  .task-time-section {
    display: flex;
    justify-content: space-between;

    .title {
      color: #354554;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .new-task-btn {
    display: flex;
    justify-content: flex-end;

    .openTaskBtn {
      width: 96px;
      height: 40px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;
      border-radius: 8px;
      background-color: #354554;
      border: none;
      cursor: pointer;

      .openTaskBtnText {
        width: 64px;
        height: 16px;
        flex-grow: 0;
        font-family: Inter, sans-serif;
        font-size: 11px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.2px;
        text-align: left;
        color: #fff;
      }
    }
  }

  .taskWrapper,
  .task-wrapper {
    margin-bottom: 24px;
  }

  .task-activity-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #7e91a5;
    margin-bottom: 24px;
  }

  .loader-active {
    height: auto;
    margin-top: 32px;

    .loader-inner {
      margin-top: -64px;
      transform: translate(-29.994px, 0);
    }
  }
}

.activity-task-card {
  .card-entity-post {
    .cm-card-body {
      .comment-type {
        font-size: 12px !important;
      }
    }
    .card-text {
      p {
        font-size: 12px !important;
      }
    }
  }
}

.mobile-device {
  .task-activity-feed-wrapper {
    padding: 8px;
  }
}
