.react-kanban-board {
  justify-content: center !important;
  display: block !important;
  padding: 0 !important;
  overflow: auto !important;
  & > div {
    white-space: normal !important;
    display: grid !important;
    overflow: auto !important;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-template-rows: repeat(2, 415px) !important;
    grid-gap: 16px;
    grid-template-areas:
      "monday tuesday wednesday overdue"
      "thursday friday weekend overdue";
  }
}

[data-rbd-draggable-id="column-draggable-monday"] {
  grid-area: monday;
  & > div:last-child {
    & > div {
      & > div {
        display: block !important;
      }
    }
  }
}

[data-rbd-draggable-id="column-draggable-tuesday"] {
  grid-area: tuesday;
  & > div:last-child {
    & > div {
      & > div {
        display: block !important;
      }
    }
  }
}

[data-rbd-draggable-id="column-draggable-wednesday"] {
  grid-area: wednesday;
  & > div:last-child {
    & > div {
      & > div {
        display: block !important;
      }
    }
  }
}

[data-rbd-draggable-id="column-draggable-thursday"] {
  grid-area: thursday;
  & > div:last-child {
    & > div {
      & > div {
        display: block !important;
      }
    }
  }
}

[data-rbd-draggable-id="column-draggable-friday"] {
  grid-area: friday;
  & > div:last-child {
    & > div {
      & > div {
        display: block !important;
      }
    }
  }
}

[data-rbd-draggable-id="column-draggable-weekend"] {
  grid-area: weekend;
  & > div:last-child {
    & > div {
      & > div {
        display: block !important;
      }
    }
  }
}

[data-rbd-draggable-id="column-draggable-overdue"] {
  grid-area: overdue;
  & > div:last-child {
    & > div {
      & > div {
        display: block !important;
      }
    }
  }
  height: calc(100% - 12px) !important;
  .teamMembersDropdown {
    .os__control {
      background-color: #ffffff !important;
      border-color: #ffffff !important;
      .os__value-container {
        padding-left: 0;
        padding-right: 8px;
      }
      &:hover {
        border-color: transparent;
      }
    }
  }
}

.react-kanban-column {
  min-width: 400px;
  border: solid 1px #eff1f4;
  background-color: #ffffff;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 16px 16px;
  margin: 0;

  // Content droppable area
  & > div:last-child {
    min-height: 85% !important;
    height: fit-content !important;
  }

  &:has(.kanban-past-task) {
    background-color: #edeff3;

    .kanban-main-card {
      border-bottom: 1px solid #c9d1da;
    }

    .teamMembersDropdown {
      .os__control {
        background-color: #edeff3 !important;
        border-color: #edeff3 !important;
        .os__value-container {
          padding-left: 0;
          padding-right: 8px;
        }
        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  &:has(.kanban-same-day) {
    background-color: #FFFFFF !important;
    box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);

    .teamMembersDropdown {
      .os__control {
        background-color: #ffffff !important;
        border-color: #ffffff !important;
        .os__value-container {
          padding-left: 0;
          padding-right: 8px;
        }
        &:hover {
          border-color: transparent;
        }
      }
    }

  }

  &:has(.kanban-future-task) {
    .teamMembersDropdown {
      .os__control {
        background-color: #ffffff !important;
        border-color: #ffffff !important;
        .os__value-container {
          padding-left: 0;
          padding-right: 8px;
        }
        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  &:has(.kanban-column-expand) {
    height: 400px !important;
  }

  &:has(.kanban-column-collapse) {
    height: 68px !important;
  }

  .ifill-os-add-task-icon {
    color: #818d99;
  }

  &:hover {
    .kanban-add-icon {
      visibility: visible;
      .ifill-os-add-task-icon {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
    }

    .kanban-column-expand {
      visibility: visible;
      cursor: pointer;
    }

    .kanban-column-collapse {
      visibility: visible;
      cursor: pointer;
    }
  }

  & > div:first-child {
    position: sticky;
    height: 68px;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #FFFFFF;

    &:has(.kanban-past-task) {
      background-color: #edeff3;
    }

    &:has(.kanban-same-day) {
      background-color: #FFFFFF !important;
    }
  }
}

.react-kanban-column-header {
  padding-top: 16px;
}

.kanban-column-heading {
  display: flex;

  .kanban-add-icon {
    visibility: hidden;

    :hover {
      cursor: pointer;
    }
  }

  .kanban-same-day {
    visibility: visible !important;
  }


  .kanban-column-collapse {
    padding-top: 5px;
    visibility: hidden;
  }

  .kanban-column-expand {
    padding-top: 5px;
    visibility: hidden;
  }


}

.kanban-board-heading {
  font-size: 12px;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  font-family: Inter, sans-serif;
  color: #5d7893;
  font-style: normal;
  flex-grow: 1;

  .primary {
    width: 29px;
    font-weight: 600;
    height: 16px;
    text-transform: uppercase;
  }

  .secondary {
    height: 16px;
    width: 15px;
    font-weight: normal;
  }
}

.kanban-column-collapse {
  &:hover {
    cursor: pointer;
  }
}

/**************** KANBAN TASK CARD CSS ******************************/

.kanban-main-card {
  border-bottom: 1px solid #edeff3;
  border-radius: 1px;
  min-width: 300px;
  width: 100%;
}

.kanban-card {
  margin: 16px 4px;

  .list-unstyled {
    box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15) !important;
  }

  .taskLabel {
    width: calc(100% - 24px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    margin-left: 24px;
    margin-top: 10px;

    .task-comment-count {
      font-size: 12px;
      line-height: 16px;
      padding: 4px 8px;
      color: #5d7893;
      mix-blend-mode: normal;
      flex: none;
      display: flex;
      align-items: center;
      gap: 4px;
      padding-left: 0;
      cursor: pointer;
    }

    .taskAssigneeName {
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      gap: 4px;
      background: #EDEFF3;
      border-radius: 12px;

      .taskAssignee {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #354554;
      }
    }

    .taskLabelContainer {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      padding: 0px 8px;
      gap: 4px;
      border-radius: 12px;
      min-height: 24px;


      .singleTaskLabel {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #354554;
      }
    }

    .comment-more {



      .header-dropdown-list {
        z-index: 9;
      }
    }
  }
}

.singleTaskLabel{
  .label-dropdown{
    .dropdown{
      .btn-success{
        padding: 0;
        margin: 0;
        border-color: transparent;
        color: #354545;
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        background-color: transparent;
        height: 24px;
      }

      .dropdown-menu{
        border-radius: 8px;
        min-height: max-content;
        box-shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);
        .dropdown-item{
          height: 32px;

          &:hover{
            background-color: #edeff3;
          }
        }
      }
    }
  }
}

.card-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left-container {
    display: flex;

    .click-popup-wrapper {
      min-width: 16px;
      min-height: 16px;
      max-height: 16px;
      height: 16px;
      width: 16px;
      border: 1px solid #979797;
      border-radius: 50%;
      cursor: pointer;
    }

    .card-completion {
      cursor: pointer;
      margin-top: -6px;
      margin-left: -2px;
    }

    .card-title-info {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1px;

      .taskLabelContainer {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        padding: 0px 8px;
        gap: 4px;
        border-radius: 12px;
        min-height: 24px;


        .task-comment-count {
          font-size: 12px;
          line-height: 16px;
          padding: 4px 8px;
          color: #5d7893;
          mix-blend-mode: normal;
          flex: none;
          display: flex;
          align-items: center;
          gap: 4px;
          padding-left: 0;
          cursor: pointer;
        }
  
        .singleTaskLabel {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #354554;
        }
      }
    }

    .card-info {
      height: 12px;
      flex-grow: 0;
      font-family: Inter, sans-serif;
      font-size: 8px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #7e91a5;
    }

    .created-info {
      margin-bottom: 4px;
    }
  }

  .right-container {
    display: flex;

    .card-action-button {
      margin-top: -12px;

     // CSS FOR THE RENDER TASK BUTTON DROPDOWN POSITIONS
      .assigneeList, .customPredefinedDates {
        visibility: hidden;
        left: -182px;
        transition-delay: 0.10s;
      }

      .calendar {
        visibility: visible;
      }

      .assigneeContainer {
        &:hover {
          .assigneeList {
            visibility: visible;
            left: -182px;
            transition: 0.5s all ease;
            transition-delay: 0s;
          }
        }
      }

      .scheduledTaskContainer {
        &:hover {
          .customPredefinedDates {
            visibility: visible;
            left: -182px;
            transition: 0.5s all ease;
            transition-delay: 0s;
          }
        }

      }
    }

    .card-expand {
      :hover {
        cursor: pointer;
      }
    }
  }
}

.card-title {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.33;

  .task-card-type {
    font-size: 12px;
    color: #5d7893;
    font-weight: 400;
  }
}

.card-description {
  width: calc(100% - 28px);
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 28px;
  align-self: stretch;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  margin-bottom: 0;
  letter-spacing: normal;
  text-align: left;
  color: #354554;

  span {
    &:hover {
      cursor: pointer;
    }
  }
}

// CSS RELATED TO ENTITY ATTACHED IN THE CARD.

.card-entity-post {
  width: fit-content !important;

  .comment-type {
    width: 28px;
    height: 12px;
    flex-grow: 0;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #818d99;
  }

  .card-text {
    p {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #354554;
    }
  }

  .commentDate {
    margin-left: 52px !important;
    height: 12px;
    flex-grow: 0;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #7E91A5;
    padding-right: 8px;
  }

  .mailinfo, .phone-number {
    display: block !important;
  }

  .os-sm-comment, .os-sm-conversation-message {
    &:hover {
      background-color: #c9d1da;
    }
    max-height: none !important;
    height: fit-content !important;
  }

  .comment-time {
    font-size: 8px !important;
    font-weight: normal;
    margin-top: -16px !important;
    padding-right: 0 !important;
    line-height: 1.5;
    text-align: left !important;
    color: #7e91a5 !important;
  }

  .kanban-entity-action {
    display: none !important;
  }
}

.card-entity-post {
  margin-left: 24px;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  font-family: Inter, sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  .os-sm-care {
    &:hover {
      background-color: #c9d1da;
    }
  }

  p {
    margin: 2px 0;
  }

  .entity-heading {
    height: 12px;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    color: #818d99;
    margin-bottom: 2px;
  }

  .entity-description {
    height: 32px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
    text-align: left;
    color: #354554;
  }

  .entity-creation-details {
    font-size: 8px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    color: #7e91a5;
  }
}

.kanban-entity-avatar {
  width: 40px !important;
  height: 40px !important;
  object-fit: contain;
  min-width: auto !important;
}

.card-tags {

  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  .card-chips {
    width: fit-content;
    padding: 4px 8px;
    height: 24px;
    border-radius: 12px;

  }

  .tags-description {
    height: 16px;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: #354554;
  }
}

// CSS RELATED TO THE CARD ACTIONS AND OVERDUE SECTIONS

.kanban-column-heading {
  display: flex !important;
  justify-content: center !important;
  .overdue-section {
    min-width: 115px !important;
    .os-menu-drop {
      .os__control {
        border-color: white !important;
        box-shadow: none !important;
      }
    }
    .os__single-value {
      text-align: center !important;
      text-transform: capitalize !important;
    }
    .os__menu {
      .os__menu-list {
        display: flex !important;
        justify-content: center !important;
        background-color: #edeff3;;
        .os__option {
          padding: 4px 16px;
          flex-grow: 0;
          height: 25px;
          font-family: Inter, serif;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          color: #5d7893;
        }
      }
    }
  }
}

// Media Query for bigger screens
@media  screen and (min-width: 1921px) {
  .react-kanban-board {
    padding: 0 !important;
    overflow: scroll !important;
    & > div {
      grid-template-columns: repeat(7, 1fr) !important;
      grid-template-rows: auto !important;
      overflow: scroll !important;
      grid-gap: 24px;
      grid-template-areas:
      "monday tuesday wednesday thursday friday weekend overdue";
    }
  }

  .react-kanban-column {
    &:has(.kanban-column-expand) {
      height: 88vh !important;
      margin-bottom: 8px !important;
    }
    & > div:last-child {
      height: 80% !important;
    }
  }

  [data-rbd-draggable-id="column-draggable-overdue"] {
    height: 88vh !important;
    margin-bottom: 8px !important;
  }
}
