
.universalBannerWrapper {
  width: 100%;
  position: relative;
  background: $level-4;
  z-index: 1;
  font-weight: 300;

  .container {
    min-height: 48px;
    padding-right: 50px !important;
    display: flex;
    align-items: center;

    .universalBannerMessage {
      color: $level-11;
      padding-right: 10px;

      p {
        margin-bottom: 0px !important;
      }

      b{
        color: $level-11;
        font-weight: 500;
      }
    }
  }

  .header-list-icon {
    vertical-align: middle;
    margin-right: 8px;

    @media screen and (max-width: 767px) {
      &.ifill-os-info {
        display: none;
      }
    }
  }

  .universalBannerClose {
    position: absolute;
    right: 15px;
    padding: 0;
    width: 40px;
  }

  .universalBannerTimer {
    color: $lt-orange;
    text-align: center;
    font-weight: 600;
  }

  @media(max-width: 1180px) {
    .container {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
      font-size: 14px;
      max-width: 100%;
      padding-right: 0 !important;

      .a-link {
        font-size: 14px;
      }
    }
  }

  @media(max-width: 767px) {
    .universalBannerClose {
      right: 0;
      top: 5px;
      margin-right: 0;
    }
    .universalBannerMessage {
      opacity: 0.8;
    }
  }

  .a-link,
  .osbtn,
  .os-btn-link,
  a,
  [data-hover="true"] {
    cursor: pointer;
  }
}

.profile_incomplete {
  .universalBannerWrapper {
    display: none;
  }
}

section[class^="universalBannerWrapper space_mute_banner_"] {
  @media screen and (min-width: 1366px) {
    white-space: nowrap;
    min-height: 36px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 6px;
  }
  
  .universalBannerClose {
    position: relative;
    margin-left: 24px;

    @media screen and (max-width: 767px) {
      top: 0;
      margin-right: 0;
    }
  }

  .container {
    justify-content: center;

    .m-auto {
      margin-left: 0 !important;
      margin-right: 0 !important;

      @media screen and (max-width: 767px) {
        text-align: left;

        .universalBannerMessage {
          padding-right: 0;
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding-right: 12px !important;
      align-items: flex-start;
    }
  }

  .ifill-os-info {
    margin-top: -2px;
    vertical-align: middle;
    display: inline-block;
  }
}

section[class^="universalBannerWrapper space_priority_group_banner_"] {
  .container {
    justify-content: center;
    align-items: flex-start;
    padding-right: 0 !important;

    .m-auto {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    @media screen and (min-width: 1024px) {
      max-width: 908px;
    }

    @media screen and (max-width: 767px) {
      padding-right: 12px !important;
    }
  }

  @media screen and (min-width: 768px) {
    min-height: 72px;
    padding-top: 16px;
  }

  .universalBannerMessage, .os-btn-link  {
    line-height: 16px;
    vertical-align: top;
  }
  .universalBannerClose {
    position: relative;
    margin-left: 12px;
    margin-top: -6px;
    right: 0;

    @media screen and (max-width: 767px) {
      top: 0;
    }
  }

  .ifill-os-info {
    margin-top: -2px;
    vertical-align: middle;
    display: inline-block;
  }
}
