$padd-lt-8: 8px;
$padd-lt-12: 12px;
.case-card-head{
  @extend %os-card-head;
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;

  .i-case {
    position: relative;
    top: -1px;
  }
}

.sm-case-card-head{
  @extend %os-card-head;
  padding-left: $padd-lt-12;
  padding-right: 16px;
  margin-bottom: 16px;

  i{
    &:not(.ifill-os-verified){
      font-size: 14px;
    }
  }
}

.left-case-col,
.right-case-col {
  @extend %col-head;
}

%case-card-footer {
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  padding-left: 16px;
  padding-right: 16px;
}

%case-card-body {
  flex-grow: 1;
  padding-left: $card-gutter;
  padding-right: $card-gutter;
}

.lg-text-9 {
  @include fontstyle(20px, 600, 1.4);
  color: $card-text;
}

.lg-text-16 {
  @include fontstyle(16px, 600, 1.5);
  color: $card-text;
}

.lg-text-12 {
  @include fontstyle(12px, normal, 16px);
  color: $level-11;
}

.sm-text-6 {
  @include fontstyle(12px, 400, 1.33);
  color: $card-sm-text;
}

.cs-title-sm {
  display: flex;
  align-items: center;
}


.case-card-images {
  height: 160px;
  max-height: 160px;
  overflow: hidden;

  &.with-border {
    border-bottom: 1px solid rgba(92, 92, 102, 0.5);
  }

  .img-parent {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    height: 100%;
    border-radius: 8px 8px 0 0;
  }

  .btl-radius {
    border-top-left-radius: 8px;
  }

  .btr-radius {
    border-top-right-radius: 8px;
  }
}

.case-img {
  position: relative;
  overflow: hidden;
  width: calc(50% - 2px);
  height: 100%;

  img {
    filter: saturate(80%);
  }
}

.single-case-img {
  @include flex-center();
  height: 100%;
  width: 100%;

  &.with-background {
    background-color: white;
    text-align: center;

    img {
      width: auto;
      max-height: 100%;
    }
  }
}


.cs-head {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 16px;
}

.cs-user-image {
  min-width:48px;
  width: 48px;
  height: 56px;
  margin-right: 8px;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;

  i{
    display: inline-block;
    margin-top: 18px;
    color: $card-icon;
  }

  + .cs-title-wrap {
    max-width: calc(100% - 48px);
  }
}

.cs-title {
  display: block;
}

/*big card and min-width card */
%os-case-card {
  @extend %os-card-base;
  display: flex;
  flex-direction: column;

  .overlay-span {
    @extend %overlay-span;
  }

  .case-image-tag {
    @extend %img-cover;
  }

  .case-card-menu {
    z-index: 1;
    right: 16px;
  }

  .case-card-body {
    @extend %case-card-body;
  }

  .case-card-footer {
    @extend %case-card-footer;
  }

  .cd_name_degree {
    margin-right: 8px;
    @include text-truncate();
  }

  .cs-with-insight-text {
    flex-wrap: wrap;
  }

  .cd-insight-icon-text {
    margin-left: 8px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;

    i {
      margin-right: 4px;
    }
  }
}

.os-card-v2 {
  .case-active-treatment-block {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
    width: 100%;
    padding: 0px;
    position: relative;
    height: 40px;

    .timeline {
      margin: 0;
      padding: 0;
      height: 100%;
    }

    .appliance-log {
      height: 100%;
      margin-bottom: 0px;
      margin-top: 12px;
    }

    .duration {
      height: 100%;
      position: static;
    }

    .case-treatment-text-block {
      width: 100%;
      @include fontstyle(12px, normal, 1.33);
      position: relative;
      z-index: 1;
    }

    .case-treatment-text-small {
      display: inline-block;
      @include fontstyle(12px, normal, 1.33);
      text-transform: unset;
    }

    .case-treatment-time-block {
      display: inline-block;
      @include fontstyle(12px, normal, 1.33);
      text-transform: unset;
    }

    .case-treatment-progress-block {
      width: 100%;
    }

    .case-treatment-progress-grey-bar {
      width: 100%;
      height: 4px;
      background: transparent;
      border-radius: 2px;
      position: static;

      .base-line {
        height: 4px;
        border-radius: 2px;
        width: 100%;
        transition: transform 0.15s ease-out;
        background: #303036;
        top: auto !important;
        bottom: 8px;
      }
    }

    .case-treatment-progress-blue-bar {
      width: auto;
      top: auto !important;
      bottom: 0 ;
      border-radius: 0;
      height: unset !important;
      .occurance-line {
        display: none;
      }

      &:after {
        content:'';
        position: absolute;
        background-color: #0077c3 !important;
        border-radius: 2px;
        transition: transform 0.15s ease-out;
        height: 4px !important;
        width: 100%;
        bottom: 8px;
        transition: background-color 300ms ease, border-radius 300ms ease;
        will-change: background-color, border-radius;
      }

      &:before {
        content: '';
        position: absolute;
        width: calc(100% + 2px);
        height: 100%;
        border-left: 1px solid #303036;
        border-right: 1px solid #303036;
        left: -1px;
        opacity: 0;
        transition: opacity 500ms ease;
        will-change: opacity;
      }
    }

    .case-treatment-progress-lightblue-bar {
      width: auto;
      height: 100% !important;
      position: absolute;
      background: #76A9D0;
      border-radius: 2px;

      .occurance-line {
        display: none;
      }
    }
  }

  &.small-card {
    .case-active-treatment-block {
      margin: 4px 16px 12px 8px;
      max-width: calc(100% - 24px);

      .case-treatment-progress-blue-bar{
        &:after {
          bottom: 6px;
        }
      }
    }

    .unlocked:not(.in-progress) {
      .case-treatment-text-block {
        display: none;
      }
    }

    .in-progress {
      .timeline {
        .appliance-log {
          margin-top: 5px;
        }
      }

      .case-active-treatment-block {
        margin-top: 24px;
        margin-bottom: 0;
      }
    }

    .case-treatment-text-small {
      font-size: 8px;
      color: $card-sm-text;
    }

    .case-active-treatment-block {
      .case-treatment-progress-grey-bar {
        .base-line {
          bottom: 4px;
          top: 0px !important;
        }
      }
    }
  }

  .my-case {
    .case-active-treatment-block {
      .case-treatment-progress-grey-bar {
        position: unset;
      }
    }
  }
}

.no-touch-support {
  .os-lg-case:not(.in-progress),
  .os-sm-case:not(.in-progress){
    .case-treatment-progress-grey-bar {
      &:hover {
        .base-line{
          height: 6px !important;
          border-radius: 3px;
          bottom: 8px;
        }

        .case-treatment-progress-blue-bar{
          &:after {
            background-color: #303036;
            height: 6px !important;
            border-radius: 3px;
            bottom: 8px;
          }

          &:hover {
            &:before {
              opacity: 1;
            }

            &:after {
              background-color: #0077c3;
              height: 6px !important;
              border-radius: 3px;
              bottom: 8px;
            }
          }

          &.grey-only {
            &:hover {
              &:before {
                border-color: transparent;
              }
              &:after {
                background-color: #303036;
                height: 6px !important;
                border-radius: 3px;
                bottom: 8px;
              }
            }
          }
        }
      }
    }
  }

  .os-sm-case:not(.in-progress){
    .case-treatment-progress-grey-bar {
      &:hover {
        .base-line{
          bottom: 4px;
        }

        .case-treatment-progress-blue-bar {
          &:after {
            bottom: 5px;
          }

          &:hover {
            &:after {
              bottom: 5px;
            }
          }
        }
      }

      .case-treatment-progress-blue-bar {
        bottom: 2px;
      }
    }
  }
}

.mobile-device {
  .os-card-v2 {
    .case-active-treatment-block {
      .case-treatment-progress-grey-bar {
        position: relative;

        .base-line {
          max-width: 100% !important;
          bottom: 0px !important;
        }
      }

      .case-treatment-progress-blue-bar {
        &:after {
          bottom: 0;
        }
      }
    }
  }
}

.cs-title-wrap {
  max-width: 100%;
}

.os-lg-case {
  @extend %os-lg-card;
  @extend %os-case-card;
  padding-bottom: 16px;

  &.select-mode {
    .case-card-footer {
      @extend %case-card-footer;
      justify-content: flex-start;
      padding-left: $card-gutter;
      padding-right: $card-gutter;
      padding-bottom: 0px;
      align-items: center;
    }
  }

  .cs-title {
    .no-patient-info {
      font-size: 13px;
    }
  }

  .cs-head {
    min-height: 72px;
    padding-top: 24px;
  }

  &.case-3d {
    .cs-head {
      .cs-title {
        padding-bottom: 4px !important;
      }
    }

    .case-card-footer {
      justify-content: center
    }


    &.select-mode {
      .case-card-footer {
        padding-bottom: 10px;
      }
    }

    &.locked {
      .img-first-block {
        width: 120px;
      }

      .img-second-block {
        width: calc(100% - 122px);
      }
    }
  }

  &.my-case {
    &.unlocked {
      .cd_name_degree {
        .lg-text-9 {
          @include fontstyle(12px, normal, 1.33);
          color: $level-11;
        }
      }

      .case-img.with-background {
        background-color: white;
        text-align: center;

        img {
          width: auto;
        }
      }
    }
  }

  &.guest-user-case {
    padding-bottom: 8px;

    .case-card-images {
      max-height: 200px;
      height: 200px;
    }

    .cs-head {
      padding-top: 8px;
    }
  }

  &.case-304 {
    @extend %os-304;

    .case-card-head,
    .case-card-user-info,
    .case-card-body {
      padding-left: $card-gutter-16;
      padding-right: $card-gutter-16;
    }

    .card-dropdown-menu {
      right: 8px;
    }

    .case-card-footer {
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  &.in-progress {
    .case-img {
      width: 100%;
    }

    .case-card-footer {
      justify-content: center;
    }
  }

  .indicator-badge {
    margin-left: 8px;
  }
}



.sm-case-card-images {
  position: relative;
  min-width: 40%;
  max-width: 40%;
  border-radius: 8px 0 0 8px;
  overflow: hidden;

  .case-img {
    max-height: 50%;
    display: block;
    width: 100%;

    &:only-child {
      max-height: 80px;
    }
  }

  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    width: 100%;
    filter: saturate(80%);
  }

  .overlay-span {
    border-radius: 8px 0 0 8px;
  }

  .btl-radius {
    border-top-left-radius: 8px;
  }

  .bbl-radius {
    border-bottom-left-radius: 8px;
  }

  .case-img + .case-img {
    img {
      border-radius: 0px 0  0 8px;
    }
  }
}



.os-sm-case {
  @extend %os-sm-card;
  @extend %os-case-card;
  display: flex;
  flex-direction: row;

  .card-dropdown-menu {
    right: 8px;
    top: -4px;
  }

  .left-case-col {
    color: $card-sm-text;
    line-height: 1;
  }

  .sm-case-card-body {
    width: 60%;
    max-width: 60%;
    padding-top: 12px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .cs-head {
    padding-left: $padd-lt-12;
    padding-right: 16px;
    flex-grow: 1;
  }

  .cs-user-image {
    max-width: 32px;
    max-height: 40px;
    min-width: 32px;
    text-align: center;

    i{
      display: inline-block;
      margin-top: 10px;
      color: $card-icon;
    }
  }

  .case-card-footer {
    padding-left: 2px;
    padding-right: 8px;
    justify-content: center;
  }

  &.locked {
    &.case-3d {
      .case-card-footer {
        justify-content: center;
      }
    }
  }

  /*&.in-progress {
    .cs-head {
      flex-grow: 0;
    }
  }*/

  .cs-with-insight-text {
    white-space: normal;
    padding-top: 2px;

    .cd_name_degree {
      @include multiline-truncate(3);
    }
    .cs-title {
      display: inline;
    }
  }

  &.my-case {
    &.unlocked {
      .cd_name_degree {
        @include text-truncate();
        display: block;

        .lg-text-16 {
          @include fontstyle(12px, normal, 1.33);
        }
      }
    }
  }

  &.guest-user-case {
    .single-case-img {
      background: #000;
    }
    .case-image-tag {
      max-height: 80%;
      max-width: 80%;
    }
  }

  &.case-240 {
    @extend %os-240;

    .sm-case-card-images {
      display: none;
    }

    .sm-case-card-head {
      margin-bottom: 8px;
      padding-left: 16px;
    }

    .sm-case-card-body {
      padding: 16px 0 8px 0;
      max-width: 100%;
      width: 100%;
    }

    .cs-head{
      padding-left: 16px;
    }

    .case-card-footer {
      padding-left: 6px;
    }
  }
}



.is-draft {
  .case-img {
    width: 100%;
    max-height: 100%;
  }

  .case-card-footer {
    justify-content: center;
  }
}

.image-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 16px;
  opacity: 0.3;
  transform: translate(-50%, -50%);
}

.comparison-modal-selected-cards-block {
  .os-sm-case {
    .case-card-footer {
      justify-content: center;
    }
  }

  .os-selected-card {
    @extend %os-sm-card;
    @extend %os-case-card;
    border: 2px solid $level-11;
    align-items: center;
    justify-content: center;
  }

  .case-active-treatment-block {
    margin-bottom: 4px !important;
  }

  .header-dropdown-menu {
    display: none !important;
  }
}

/*extra-small-min-card*/
.extra-small-min-card {
  .overlay-span,
  .sm-case-card-images {
    border-radius: 8px;
  }

  .os-sm-case {
    @extend %os-extra-sm-card;
    padding: 8px 8px 8px 12px;

    .sm-case-card-images,
    .case-card-images {
      height: 72px;
      max-height: 72px;
      min-width: 128px;
      max-width: 128px;
      position: absolute;
      top: 12px;
      left: 12px;

      .img-parent {
        width: 100%;
        height: 100%;
        display: flex;
      }
    }

    .header-dropdown-list-item {
      .osbtn {
        width: 100%;
        padding-left: 40px;
        text-align: left;
      }
    }

    .card-dropdown-menu {
      top: 32px;
    }

    .sm-case-card-body {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0 0 0 142px;
      flex-direction: row;
    }

    .sm-case-card-head {
      position: absolute;
      padding: 0;
      top: 12px;
      right: 8px;
      left: 152px;
    }


    .case-card-footer {
      @include extra-small-card-footer;
      right: 0;

      .osbtn {
        width: 40px;
        height: 40px;
        overflow: hidden;
        padding: 0 8px;
        color: #5c5c66;

        .btn-icon-helper {
          font-size: 24px;
        }

        &:hover {
          color: $os-link;
        }
      }
    }

    .cs-head {
      padding: 0;
      width: 100%;
    }

    .cs-title-wrap {
      margin: 12px  0 0 ;
    }

    .lg-text-16 {
      padding-right: 40px;
    }

    .cd_name_degree  {
      margin: 0;
      color: $level-11;
    }
  }

  &.multi-selection-mode {
    .os-sm-case {
      &.unlocked {
        .sm-case-card-body {
          width: calc(100% - 80px);
          max-width: calc(100% - 80px);
        }
      }
    }
  }


  .indicator-badge {
    font-size: 8px;
    height: 12px;
    line-height: 11px;
    padding: 0 4px;

    .icon-indicators {
      font-size: 8px;
    }
  }
}


.indicator-badge {
  padding: 0 8px;
  border-radius: 10px;
  font-weight: normal;
  color: black;
  text-transform: none;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  &.verified {
    background-color: $btn-primary;
    color: white;

    .ifill-os-verified {
      color: white;
    }
  }

  &.draft {
    background-color: $level-11;
    color: $level-1;
  }

  &.live {
    background-color: #57b894;
  }

  &.processing {
    background-color: #ff9c41;
  }

  .icon-indicators {
    color: #fff !important;
    font-size: 12px;
  }
}
