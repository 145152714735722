.reset-password-row {
  align-items: center;

  .reset-password-img {
    width: 520px;
    max-width: 100%;
  }

  .token-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    color: $level-11;

    .login-form-heading {
      max-width: 240px;
      margin: auto;
      padding: 30px 0;
    }
  }

  .reset-password-action {
    margin-top: auto;

    .osbtn {
      margin-top: 36px;
      width: 212px;
    }
  }

  .token-text-helper {
    font-size: 20px;
    line-height: 1.4;
  }

  .form-group {
    margin-bottom: 30px;
    
    .with-error {
      @media(max-width: 900px) {
        position: relative;
      }
    }

    .form-error {
      line-height: 1.33;
    }
  }

  @media(min-width: 768px) {
    .token-text,
    form { 
      height: 664px;

      .login-form-heading {
        padding: 80px 0 64px;
      }
    }

    .reset-password-action {
      margin-bottom: 80px;
    }
  }

  @media(max-width: 768px) {
    .reset-img-col {
      text-align: center;

      img {
        margin: 0auto;
        max-width: 50%;
      }
    }
  }
}

