.modal-attachment {
  .close-attachment-preview {
    right: 20px;
    top: 20px;
  }

  .document-container {
    width: 100% !important;
    max-width: 100%;
  }
}

.mobile-device {
  .modal-attachment {
    .close-attachment-preview {
      right: 0;
      top: 8px;
      z-index: 10;
    }
  }

  &.portrait {
    .modal-xl.modal-attachment .attachments-content-sidebar {
      .attachment-sidebar-row {
        &.btns-row {
          width: 100%;
          margin: 0;
          padding: 10px;
          display: flex;
          position: fixed;
          bottom: 0;
          left: 0;
          background: $level-3;
        }
      }
    }
  }

  .attachments-content-sidebar {
    min-width: 100%;
    padding: 0;
    position: relative;
    top: 0;
    right: 0;

    .attachments-top-content-holder {
      width: 100%;
      padding: 10px 60px 10px 10px;
      position: fixed;
      top: 0;
      left: 0;
      background: $level-3;
    }

    .attachment-sidebar-row {
      &.file-name {
        font-size: 13px;
        line-height: 16px;
      }

       &.file-created-at {
        margin-top: 0;
      }

     .attachment-sidebar-btns {
        margin-top: 0;
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
  }

  .attachments-content-holder {

     .pg-viewer-wrapper {
      display: block;
      overflow-x: hidden;
      .document-container {
        width: 100%;
      }
    }
  }
}

.mobile-device.landscape {
  &.fullscreen-modal-active {
    .modal {
      opacity: 1;
      z-index: 2000;

      .close-attachment-preview {
        display: none !important;
      }

      .modal-content {
        padding-bottom: 0;
      }
    }
  }

  .thumb-slider,
  .file-created-at,
  .attachment-file-size,
  .view-full-size-btn {
    display: none !important;
  }

  .download-attachment-btn {
    min-width: unset !important;
    min-height: unset !important;
    line-height: 70px;
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
    font-size: 0;
    overflow: hidden;
    background: none;
    margin: 0 !important;

    i {
      font-size: 14px !important;
      margin: 0 !important;
    }
  }

  .attachments-top-content-holder {
    height: 60px;
    display: flex;
    align-items: center;
  }

  .modal-body {
    padding-top: 50px !important;
    margin-top: 0 !important;
  }

  .attachment-sidebar-row {
    &.file-name {
      @include text-truncate;
      padding-right: 20px;
    }

    &.btns-row {
      height: 40px;
      width: 40px;
      margin: 0 !important;
      display: inline-block;
      position: fixed;
      right: 4px;
      top: 8px;
    }
  }

  .modal-attachment {
    .create-slider {
      height: 100%;
    }
  }
}
