.guest-layout {
  body.landing-page {
    padding-bottom: 0;
  }

  .case-details-container {
    @media(min-width: 768px) {
      padding-top: 48px !important;
    }
  }

  .detail {
    padding-top: 0;

    &.detail-board {
      padding-top: 0;
    }

    .case-details-content-block {
      margin-top: 0 !important;
    }

    .case-detail-height {
      margin-bottom: 0 !important;
    }
  }

  .detail-text {
    margin-bottom: 12px;
  }

  .form-control {
    text-align: left;
    padding: 0 16px;
    line-height: normal;
  }

  .error-text {
    font-size: 12px;
    color: $lt-red;
  }

  .case-right-btn-row {
    margin-bottom: 12px;
  }

  .navbar + .loader-active {
    position: fixed;
    top: 0;
    width: 100%;

    .loader-inner {
      margin-top: 0;
    }
  }

  .analytics-modal {
    .modal-dialog {
      top: 10%;
    }

    .dr-name-link {
      margin-bottom: 0;
    }
  }

  .modal-open {
    .full-screen {
      display: none;
    }
  }

  .new-cs-card {
    .submit-right-container {
      overflow: hidden;
    }

    .quiz-right-section {
      max-height: calc(100% - 46px) !important;
    }

    .text-center + .accordion-block {
      margin-top: 10px;
    }

    .case-right-btn-row {
      margin-top: 15px;
    }
  }

  &.desktop-small {
    .new-cs-card {
      .case-timeline-right-section {
        height: 100%;
      }
    }
  }

  &.mobile-device {
    .space-sub-header.without-extra-info {
      padding-right: 60px;
    }

    .space-sub-header ~ .r-p-action {
      position: relative;
      display: flex;
      align-items: center;
      
      .card-btn-more:only-child {
        position: absolute;
        right: 8px;
        top: -50px;
      }
    }
  }
}

.guest-user-view {
  .os-main-nav-inner {
    justify-content: space-between;
    width: 100%;
    padding: 0 48px;
  }

  .detail-case {
    min-height: unset !important;
    padding-top: 0 !important;
    padding-bottom: 80px;
  }

  .case-video + .slick-slider {
    display: none;
  }
}

.guest-layout {
  .landing-page {
    &.courses {
      padding-top: 100px;
    }
  }

  .quiz-submit-btn {
    bottom: 0;
    right: 18px;
  }

  .accordion-section {
    margin-bottom: 30px;
  }

  @media(max-width: 767px) {
    .back-nav-bar {
      height: unset;

      .osbtn {
        display: none !important;
      }
    }

    .page-banner {
      .slider__banner {
        &.slide__2 {
          top: 148px;
          padding-left: 16px;
        }

        &.slide__3 {
          top: 76px;
          left: -5px;
          max-width: 82%;
        }

        .slider__banner__heading {
          line-height: 1;
        }

        .slider__banner__text {
          font-size: 20px;
          line-height: 1.6;
        }

        .slider__banner__heading {
          font-size: 24px;
          line-height: 1.33;
          font-weight: 900;
        }
      }

      ul {
        line-height: 2;
        font-size: 12px;
        margin-bottom: 8px;

        li {
          padding-left: 20px;
        }

        .ifill-os-tick-single {
          left: 0;
          top: 7px;
        }
      }
    }
  }
}

.ipad{
  &.portrait {
    .guest-user-view {
      .os-main-nav-inner {
        padding: 0 24px;
      }
    }
  }

  .quiz-section-header {
    margin-top: 16px;
  }
}

.mobile-device {
  .guest-user-view {
    .os-main-nav-inner {
      padding: 0;
    }
  }

  .landing-page {
    &.courses {
      padding-top: 0;
    }
  }

  &.portrait{
    @media screen and (max-width: 320px) {
      .guest-user-view {
        .navbar-unauth {
          padding-left: 15px ;
          padding-right: 15px;

          .osbtn {
            padding: 0px 16px;
          }
        }

        .mr-m-16 {
          margin-right: 8px;
        }
      }
    }
  }

  .page-banner-image {
    height: 100%;
    object-fit: contain;
  }
}

.page-banner,
.page-banner.course-banner {
  .slick-slide {
    position: relative;
    + .slick-slide {
        background: #000;
      }

    > div {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }

  .page-banner-image {
    width: 100%;
  }

  .banner-container {
    z-index: 1;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;

    @media(max-width: 1100px) {
      .slide__2 {
        padding-left: 35px;
      }
    }

    @media(max-width: 1000px) {
      max-width: 100%;
    }

    @media(max-width: 800px) {
      max-width: 744px;
    }


    @media(max-width: 767px) {
      transform: none;
      width: 100%;
      padding: 0 0 0 24px;
      margin: 0;
      top: 12px;
      left: 0;

      .slide__2 {
        padding-left: 0;
      }
    }
  }

  .slider__banner {
    text-align: left;
    white-space: normal;
    color: $level-11;
    position: relative;

    &.slide__3 {
      max-width: 344px;

      .slider__banner__text {
        color: #fff;
      }
    }

    .os-btn-link {
      line-height: 40px;
      display: inline-block;
    }

    &.course_slide__1{
      .slider__banner__heading {
        font-size: 24px;
        line-height: 1.5;
        font-weight: normal;
      }

      .slider__banner__text {
        max-width: 532px;
        font-size: 48px;
        line-height: 1.17;
        font-weight: 900;
      }

      @media(max-width: 767px) {
        left: -9px;
        top: 26px;
        margin: 0;

        .slider__banner__heading {
          font-size: 16px;
          line-height: 1;
        }

        .slider__banner__text {
          max-width: 275px;
          font-size: 24px;
          line-height: 1.33;
        }
      }
    }

    .slider__banner__heading {
      font-size: 28px;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
    }

    .slider__banner__text {
      position: static;
      transform: none;
    }

    @media(max-width: 767px) {

      &.slide__3 {
        max-width: 50%;
        top: 12px;
      }

      .slider__banner__heading {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  .slider__banner__text,
  ul {
    font-size: 20px;
    line-height: 1.4;
    margin: 16px 0;
    font-weight: normal;
  }

  ul {
    list-style: none;
    padding: 0;
    line-height: 1.4;
    color: #e7e7ea;

    li {
      position: relative;
    }

    .ifill-os-tick-single {
      color: #57b894;
      left: 0;
      position: absolute;
      top: 6px;
    }
  }

  @media(max-width: 767px) {
    .slider__banner__text,
    ul {
      font-size: 12px;
      text-align: left;
      margin: 12px 0 0;
      line-height: 1.33;
    }

    ul {
      line-height: 2;
      font-size: 8px;
      margin-top: 5px;
    }

    .os-btn-link {
      margin: auto;
      display: inline-block !important;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.no-touch-support,
.ipad {
  .course-banner {
    .page-banner-image {
        height: 100%;
        object-fit: contain;
      }
  }
}

.image-form-container {
  padding-top: 20px;
  @media(min-width: 768px) {
    padding-top: 60px;
  }
}
