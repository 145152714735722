.information-widget-section {
  @include fontstyle(16px, 400, 24px );
  padding: 24px 32px;

  @media screen and (min-width: 768px) {
    padding: 24px 40px 32px 24px;
  }

  .workspace-blocks {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  .workspace-blocks-info {
    color: $level-11;
  }

  svg {
    max-width: 120px;
    max-height: 120px;
    display: inline-block;
  }

  .img-block {
    flex: 0 0 120px;
    max-width: 120px;
    padding: 0;
    display: inline-block;
    margin-right: 16px;
  }
}

.no-touch-support {
  .information-widget-section {
    .learn-more-link:hover {
      color: $os-link;
    }
  }
}

