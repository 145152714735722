%ghost-hover {
  color: $btn-ghost-hover-color;
  border-color: $btn-ghost-hover-br;
  background-color: transparent;
}

$btn-height: 40px;
$m-btn-height: 32px;


%icon-active-hover {
  color: $btn-icon-hover;
  border-color: $btn-icon-hover;
  box-shadow: $block-shadow-xs;

  .btn-icon-helper {
    color: $btn-icon-hover;
  }
}

%primary-disable {
  color: $btn-primary-disabled-text !important;
  border-color: transparent;
  background-color: $btn-primary-disabled;
}

%secondary-disable {
  color: $btn-secondary-disabled !important;
  border-color: $btn-secondary-disabled !important;
}

.osbtn {
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: $btn-primary-color;
  line-height: $btn-height;
  padding: 0 16px;
  border: 0;
  text-transform: uppercase;
  background-color: transparent;
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: background 200ms ease;
  min-height: $btn-height;
  min-width: $btn-height;
  position: relative;
  -webkit-appearance: none !important;

  &.brt-top {
    border-top: 1px solid #5c5c66;
    line-height: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 0;
    margin-top: 4px;
  }

  &.osbtn-disabled {
    &:hover {
      .button-tooltip {
        display: block;
      }
    }
  }

  &.osbtn-16 {
    padding-left: 16px;
    padding-right: 16px !important;
  }

  .button-tooltip {
    width: 240px;
    margin-left: -120px;
    display: none;
    position: absolute;
    top: -54px;
    left: 50%;
    color: $level-11;
    font-size: 12px;
    text-transform: none;
    text-align: center;
    white-space: normal;
    background-color: $block;
    border-radius: 8px;
    padding: 4px 16px;
    line-height: 1.5;

    .ifill-os-info {
      color: $lt-red !important;
      margin-right: 8px;
      display: inline-block;
    }

    &::before {
      content: none;
      width: 0;
      height: 0;
      margin-left: -3px;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      border-top: solid 6px $block;
      position: absolute;
      bottom: -6px;
      left: 50%;
    }
  }

  &.no-style {
    min-width: unset !important;
    min-height: unset !important;
    background: none !important;
    text-transform: unset !important;
    display: initial;
    line-height: normal;
    padding: 0;
    white-space: normal;
  }

  &.ls-05 {
    letter-spacing: .5px;
  }

  &.w-130 {
    min-width: 130px;
  }

  &.w-120 {
    min-width: 120px;
  }

  &.cursor-default {
    cursor: default;
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
  }

  &:focus,
  &:active,
  &.active {
    color: $btn-primary-hover;
    background-color: rgba(72, 172, 253, 0.2);
    outline: none;
  }

  &.post-message-button {

    &:focus,
    &:active {
      background: transparent;
      color: #fff;
    }
  }

  &.conversation-input-button {

    &:focus,
    &:active {
      /*background: $os-link;
      color: #fff;*/
    }

    &.osbtn-disabled {
      /*pointer-events: none;*/
    }
  }

  &-with-border,
  &.with-border {
    border: 1px solid transparent;
    line-height: $btn-height - 2px;
  }

  &.osbtn-primary {
    color: $btn-primary-color;
    background-color: $btn-primary;

    &:focus,
    &:active,
    &.active {
      background-color: $btn-primary-hover;
      outline: 0;
    }

    &.px-16 {
      padding-left: 16px;
      padding-right: 16px;
    }

    &.osbtn-disabled,
    &.disabled-only,
    &[disabled],
    &.disabled {
      @extend %primary-disable;

      &:focus,
      &:active,
      &.active {
        @extend %primary-disable;
      }
    }
  }

  &.with-icon {
    padding-left: 40px;

    .btn-icon-helper {
      left: 16px;
      transform: translate(0, -50%);
    }
  }

  &.btn-normal {
    text-transform: lowercase;
    font-weight: nowrap;
  }

  &.osbtn-grey {
    color: $level-11;
    border-color: $os-btn-border;
    background-color: #4C4C54;
  }

  &.osbtn-ghost {
    color: $btn-ghost-color;
    border: 1px solid $btn-ghost-border;
    transition: color 200ms ease, border-color 200ms ease;
    will-change: color, border-color;

    &:active,
    &.active,
    &:focus {
      color: $btn-ghost-active;
      border-color: $btn-ghost-active;
    }
  }

  &.font-10 {
    font-size: 10px;
  }

  &.osbtn-outline {
    color: $btn-secondary;
    border-color: $btn-secondary;
  }

  &.osbtn-ghost,
  &.osbtn-outline {

    &.text-blue {
      color: $os-link;
    }

    &.text-red {
      color: $lt-red;
    }

    &:focus,
    &:active,
    &.active {
      color: $os-link-hover;
      border-color: $os-link-hover;
      background-color: transparent;
      outline: 0;
    }
  }

  &.disabled-only {
    cursor: not-allowed;
  }

  &.osbtn-disabled {
    color: $level-6 !important;
    cursor: not-allowed;
    border-color: $os-btn-disabled !important;
    position: relative;

    .btn-link-helper {
      visibility: hidden;
      display: none;
    }

    &:hover {
      color: $level-6;
    }
  }

  &.pointer-allow {
    pointer-events: all;
  }

  &.osbtn-icon-big {
    padding-left: 36px;

    .btn-icon-helper {
      left: 16px;
      transform: translateY(-50%);
    }
  }

  &.text-blue {
    color: $os-link;
  }

  &.blue-text {
    color: $os-link !important;
  }

  &-icon {
    color: $btn-icon-text;

    &.no-icon {
      .btn-icon-helper {
        display: none;
      }
    }

    &:not(.no-text),
    &.osbtn.text-required {
      padding-left: 40px;
      border: 1px solid $btn-icon-border;

      &.righticon {
        padding-right: 40px;
      }

      .btn-icon-helper {
        color: $btn-icon-helper;
        left: 16px;
        transform: translateY(-50%);
      }

      &.onboarding-tip-btn {
        padding-left: 48px;
        text-align: left;
        text-transform: capitalize;

        .btn-icon-helper {
          left: 20px;
        }
      }
    }

    &.list-button {
      border-color: transparent !important;
    }

    &.px-16 {
      padding-left: 16px;
      padding-right: 16px;
    }

    &.text-blue {
      color: $os-link;
    }

    &.text-red {
      color: $lt-red;
    }

    .header-list-icon {
      margin-left: 8px;
    }

    .btn-text-helper {
      display: inline-block;
      /*line-height: 1;*/
      vertical-align: middle;
    }

    &.with-border {
      border: 1px solid $btn-icon-border;

      &:hover {
        border-color: $btn-icon-hover;
        background-color: transparent !important;
        color: $btn-icon-hover;

        .btn-icon-helper {
          color: $btn-icon-hover;
        }
      }
    }

    &.active {
      background: $btn-icon-active;
      color: $btn-icon-active;
      border-color: $btn-icon-active;

      .btn-icon-helper {
        color: $btn-icon-active;
      }

      &:active {
        @extend %icon-active-hover;
      }
    }
  }

  &.px-8 {
    padding-right: 8px;
    padding-left: 8px;

    &:not(.no-text) {
      padding-left: 32px;

      .btn-icon-helper {
        left: 12px;
      }
    }
  }

  .btn-icon-helper {
    font-weight: inherit;
    color: inherit;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: normal;


    &.righticon {
      left: auto !important;
      right: 16px;
    }
  }

  &-secondary {
    color: $btn-secondary;
    border: 1px solid transparent;

    &:focus,
    &:active,
    &.active {
      color: $btn-secondary-bg-active-text;
      background-color: $btn-secondary-bg-active;
    }

    &.osbtn-disabled,
    &.disabled-only {
      @extend %secondary-disable;
      border-color: $level-6 !important;

      &:focus,
      &:active,
      &.active {
        @extend %secondary-disable;
      }
    }

    &.os-text-6 {
      color: $level-9;
    }

    &.with-border {
      border: solid 1px $btn-secondary-border;

      &:focus,
      &:active,
      &.active {
        border-color: $btn-secondary-active;
      }

      &:hover {
        border-color: $btn-secondary-hover;
      }
    }

    &.with-bg {
      background: $level-1;
    }
  }

  &.osbtn-tag {
    min-height: 32px;
    line-height: 30px;
  }

  &.login-a-link {
    line-height: 1.5;

    &:hover {
      text-decoration: underline;
    }
  }

  &.osbtn-link {
    background: none !important;
    font-size: 12px;
    color: $os-link;
    line-height: 32px;
    text-transform: none;
    border-radius: 0;
    border: 0;
    min-width: unset;
    min-height: unset;
    padding: 0;
    font-weight: $link-weight;

    &:active,
    &:focus,
    &:hover {
      background: none !important;
      color: $os-link-hover;
      text-decoration: none;
    }

    &.lh-normal {
      line-height: normal;
    }

    &.lh-15 {
      line-height: 1.5;
    }
  }

  .action-link,
  .btn-link-helper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    border: 0;
    -webkit-appearance: none !important;
    padding: 0;
    margin: 0;
    outline: 0;

    +.btn-link-helper {
      display: none !important;
    }
  }

  +.osbtn {
    margin-left: .5rem;
  }
}


.no-touch-support {
  .osbtn {
    &-primary:not(.disabled-only) {
      &:hover {
        color: $btn-primary-color;
        background-color: $btn-primary-hover;
      }
    }

    &-outline {
      &:hover:not(.disabled-only) {
        color: $btn-secondary;
        border-color: $btn-secondary;
        background-color: transparent;
      }
    }

    &-ghost {
      &:hover:not(.disabled-only) {
        color: $btn-ghost-hover-color;
        border-color: $btn-ghost-hover-br;
        background-color: transparent;
      }
    }

    &-secondary {
      &:hover:not(.disabled-only) {
        color: $btn-secondary-hover;
        background-color: transparent;
        border: 1px solid $btn-secondary-hover;
      }

      &.with-bg:hover {
        background: $level-1;
      }
    }

    &-icon,
    &-icon-big {
      &:not(.no-text) {
        background: $btn-icon;
        border-color: $btn-icon-border;
        color: $btn-icon-text;
        transition: background 200ms ease, border-color 200ms ease;
      }

      &.active:hover,
      &:hover {
        @extend %icon-active-hover;
      }

      &.osbtn-disabled:hover {
        background: none;
      }

      &.no-bg {
        background: none;
      }

      &.list-button {
        border-color: transparent !important;
      }

      &:actve,
      &.active,
      &:focus {
        border-color: $btn-icon-active;
        color: $btn-icon-active;
      }

      &:not(.no-text),
      &:not(.list-button) {
        &:hover {
          border-color: $btn-icon-hover;
          color: $btn-icon-hover;
        }
      }

      &.no-text,
      &.no-border {
        background-color: transparent;
        color: $level-8;

        .btn-icon-helper {
          color: $level-8;
          transition: color 500ms ease;
        }

        &:hover {
          background-color: $btn-i-xs-bg-hover;
          box-shadow: $btn-i-xs-shadow;

          .btn-icon-helper {
            color: $level-8;
          }
        }

        &.active,
        &:focus,
        &:active,
        &.osbtn-active {
          background-color: $btn-i-xs-bg;
          // box-shadow: $btn-i-xs-shadow;

          .btn-icon-helper {
            color: $level-8;
          }
        }
      }
    }

    &.post-message-button {

      &:focus,
      &:active,
      &:hover {
        background: transparent;
        color: #fff;
      }
    }

    &.conversation-input-button {

      &:focus,
      &:active,
      &:hover {
        background: $cl-link;
        color: #fff;
      }
    }
  }

  .osbtn.osbtn-link,
  .osbtn-link,
  .os-btn-link {
    &:hover {
      color: $btn-link-hover;
      text-decoration: underline;
    }
  }
}

.osbtn-tertiary {
  color: $btn-tertiary !important;
  border-color: transparent !important;
  background-color: transparent !important;

  .btn-icon-helper {
    color: $btn-tertiary-icon !important;
    text-decoration: none;
    left: 16px;
    transform: translateY(-50%);
  }

  &:hover {
    color: $btn-tertiary-hover !important;
    text-decoration: underline;
    box-shadow: none !important;

    .btn-icon-helper {
      color: $btn-tertiary-hover !important;
      text-decoration: none;
    }
  }

  &:active,
  &.active,
  &:focus {
    color: $btn-tertiary-active !important;
    text-decoration: underline;
    background-color: transparent;

    .btn-icon-helper {
      color: $btn-tertiary-active !important;
      text-decoration: none;
    }
  }
}

.mt-10 {
  margin-top: -10px;
}

.card-btn-more {
  min-width: 40px;
  min-height: 40px !important;
  line-height: 40px !important;
  padding: 8px;
}

.mobile-device {
  .osbtn:not(.web-view-btn) {
    min-height: $m-btn-height;
    line-height: $m-btn-height;

    &-with-border,
    &.with-border {
      line-height: $m-btn-height - 2px;
    }
  }


  &.landscape {
    .more-icon {
      padding-left: 24px;
      padding-right: 24px;
      min-height: 40px;
      min-width: 40px;

    }

    .notes-modal-attachment-block {
      .osbtn {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}

.osbtn-32 {
  min-width: 32px;
  min-height: 32px;
  line-height: 32px;
  padding: 0px 16px;

  .ifill-os-more {
    font-size: inherit !important;
  }
}

.os-btn-link {
  position: relative;
  font-weight: $font-weight-semibold;
  color: $btn-link;

  .btn-link-helper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &.with-underline {
    text-decoration: underline;
  }

  &.without-underline {
    text-decoration: none !important;
  }
}


.btn-link-32 {
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  line-height: 32px;

  &:hover {
    text-decoration: underline;
  }

  &.btn-hide,
  &.btn-view {
    color: $level-9;
    line-height: 16px;
    text-transform: uppercase;

    &:hover {
      color: $os-link;
    }
  }
}

.touch-support {
  .osbtn {
    &:disabled {
      pointer-events: none;
    }
  }
}

.btn-h-40 {
  height: 40px;
}

.ifill-os-comments-new {
  font-size: 17px;

  &.btn-icon-helper {
    font-size: 17px;
  }
}

.os-btn-link {
  .button-tooltip {
    width: 240px;
    margin-left: -120px;
    display: none;
    position: absolute;
    top: -54px;
    left: 50%;
    color: $level-11;
    font-size: 12px;
    text-transform: none;
    text-align: center;
    white-space: normal;
    background-color: $block;
    border-radius: 4px;
    padding: 4px 8px;
    line-height: 1.5;

    &::before {
      content: '';
      width: 0;
      height: 0;
      margin-left: -3px;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      border-top: solid 6px $block;
      position: absolute;
      bottom: -6px;
      left: 50%;
    }
  }
}


.at-bt-dpdowm {
  padding: 0 !important;
  margin-right: 1rem;

  .page-heading-title {
    padding: 0 16px 0 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    background-color: $level-1;
    border: solid 1px $btn-icon-border;
    color: $btn-icon-text;
    vertical-align: middle;
    border-radius: 8px;
    @include fontstyle(12px, 600, 40px);
    text-transform: uppercase;
    transition: color 500ms ease, border-color 500ms ease, box-shadow 500ms ease;
    will-change: color, border-color, box-shadow;

    .ifill-os-fill-attachment {
      font-weight: inherit;
      color: $btn-icon-helper;
      ;
      font-size: 1rem;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translate(0, -50%);
      font-weight: normal;
    }

    .ifill-os-chevron-down,
    .ifill-os-chevron-up {
      display: none !important;
    }

    &:hover {
      @extend %icon-active-hover;

      .ifill-os-fill-attachment {
        color: $btn-icon-hover;
      }
    }

    &:active,
    &.active,
    &:focus {
      color: $btn-icon-active;
      border-color: $btn-icon-active;

      .ifill-os-fill-attachment {
        color: $btn-icon-hover;
      }
    }
  }

  &.dropdown-open {
    .page-heading-title {
      color: $btn-icon-active;
      border-color: $btn-icon-active;

      .ifill-os-fill-attachment {
        color: $btn-icon-active;
      }

    }
  }

  &.only-icon {
    .page-heading-title {
      padding-left: 12px;
      padding-right: 12px;
      background-color: transparent;
      width: 40px;

      .ifill-os-fill-attachment {
        left: 12px;
      }

      .pointer {
        display: none;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }
}

.reply-buttons,
.chat-action-group {
  .header-dropdown-list-item {
    .osbtn {
      margin-left: 0 !important;
    }
  }

  .at-bt-dpdowm {
    margin-left: 0;
    margin-bottom: 4px;
    margin-right: 0;

    .page-heading-title {
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      border: 0;

      .btn-icon-helper {
        left: 12px;
      }

      .osbtn {
        border-radius: 8px;
        margin-left: 0;
        margin-bottom: 0;
      }

      &:hover {
        box-shadow: none;
      }
    }

    @media screen and (max-width: 1440px) {
      margin-bottom: 0px;
    }
  }

  .attachment-icon{
    margin-top:4px;
  }
}

.chat-action-group {
  .header-dropdown-list {
    bottom: 100%;
  }

  .header-dropdown-list-item {
    .osbtn {
      margin-bottom: 0 !important;
    }
  }

  @media screen and (max-width: 767px) {
    .at-bt-dpdowm {
      margin-bottom: 6px;
    }
  }
}

.floater-message-wrapper {
  .chat-action-group {
    .page-heading-title {
      .osbtn {
        min-height: 32px !important;
        height: 32px !important;
        line-height: 32px !important;

        .btn-icon-helper {
          left: 8px;
        }
      }
    }
  }
}

.extra-small-min-card {
  .with-rename {
    z-index: 1;
  }

  .pmb-conatiner {
    .post-message-button {
      min-height: 38px;
      max-height: 38px;
      border-radius: 0 7px 7px 0;
      box-shadow: none;
      margin-top: 1px;
      margin-right: 1px;
    }
  }
}