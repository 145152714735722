.course-cart-item-row {
  display: flex;
}

.pay-detail-box {
  .with-error {
    position: absolute;
  }

  .subs-term {
    .form-error {
      position: absolute;
    }

    @media(max-width:767px) {
      padding-bottom: 16px;
    }
  }
}

.course-cart-item-image {
  .img-holder {
    width: 80px;
    height: 57px;
    overflow: hidden;
    border-radius: 7px;
    position: relative;
  }

  .img-overlay {
    position: absolute;
    background-image: linear-gradient(to bottom, #121214, rgba(18, 18, 20, 0));
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 7px;
  }

  .img-fluid {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.course-cart-item-info {
  padding: 0 12px;
  flex: 1;
}

.course-cart-extra-info {
  font-size: 12px;
  color: $level-6;
  padding-top: 8px;

  .span {
    display: block;
  }

  i {
    vertical-align: middle;
    font-size: 12px;
    margin-right: 4px;
  }

  .ifill-os-fill-user {
    vertical-align: unset;
  }
}

.course-cart-item-price {
  text-align: right;
  font-size: 20px;
  white-space: nowrap;

  .promo-text {
    font-size: 12px;
    color: $level-9;
    line-height: normal;
    padding-bottom: 12px;
  }

  .promo-price {
    text-decoration: line-through;
  }
}


.promo-code-block {
  padding-top: 32px;

  &.with-promo-apply {
    padding-bottom: 38px;
  }

  .promo-apply-row {
    position: relative;
    padding-left: 30px;
    font-weight: 300;

    .ifill-os-close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: unset;
    }
  }

  .applied-promo {
    font-weight: 600;
    line-height: 1;
    color: $level-11;
  }
}

.promo-field-group {
  margin-bottom: 0;
  margin-top: 8px;
  position: relative;

  .form-group {
    margin: 0;
    flex: 1;
  }

  .osbtn {
    padding-right: 16px;
    padding-left: 16px;
    display: block;
    margin-top: 10px;
    min-width: 162px;
    letter-spacing: 0.5px;
  }

  .form-control {
    &.error {
      border-color: $lt-red;
    }
  }

  .error:not(.form-control) {
    display: block;
    padding-top: 2px;
    color: $lt-red;
    font-size: 12px;
  }
}

@media(min-width: 778px) {
  .course-cart-item-image {
    .img-holder {
      width: 104px;
      height: 72px;
    }
  }

  .course-cart-extra-info {
    font-size: 12px;
    color: $level-6;
    padding-top: 8px;

    .span {
      margin-right: 16px;
      display: inline;
    }
  }

  .promo-code-block {
    .promo-field-group {
      display: flex;
      margin-right: -16px;

      .osbtn {
        margin-top: 0;
        margin-left: 12px;
      }

      .error:not(.form-control) {
        position: absolute;
        left: 0;
        top: 100%;
      }
    }

    .promo-apply-row {
      .ifill-os-close {
        left: -12px;
      }
    }
  }
}

.purchase_course {
  .cmn-form-box {
    &.course-buy-info-box {
      margin-bottom: 32px;
    }
    .promocode-hint {
      color: $level-11;
      font-size: 12px;
      margin-bottom: 8px;
    }

    &.promocode-wrap {
      padding-top: 20px;
      padding-bottom: 20px;

      .form-heading-small {
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        .ifill-os-promo {
          font-size: 24px;
        }
      }

      @media screen and (max-width: 767px) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .promo-code-block {
    padding-top: 24px;
    margin-bottom: 20px;

    &.with-promo-apply {
      padding-bottom: 0;
      padding-top: 10px;
      margin-bottom: 4px;

      + .promocode-hint {
        display: none;
      }
    }

    .promo-field-group {
      margin-right: 0;
      margin-top: 0;
      display: flex;
      align-items: center;

      .osbtn {
        min-width: 72px;
        line-height: 46px;
        max-width: 72px;
        margin-top: 0;
        height: 46px;

        @media screen and (max-width: 768px) and (max-width: 1024px) {
          margin-left: 8px;
        }
      }

      input {
        height: 48px;
        @include placeholder {
          color: #6c6c6f !important;
        }
      }
    }

    .promo-apply-row {
      padding-left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .applied-promo {
      color: $os-link;
    }

    .remove-promo-btn {
      color: #6c6c6f;
      padding-left: 16px;
      padding-right: 40px;
      margin-right: -16px;

      .btn-icon-helper {
        left: auto;
        right: 16px;
      }
    }

    @media screen and (max-width: 778px) and (max-width: 1024px) {
      .promo-field-group {
        .error:not(.form-control) {
          position: absolute;
          left: 0;
          top: 100%;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .promo-field-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .error:not(.form-control) {
          position: absolute;
          left: 0;
          top: 100%;
        }

        .osbtn {
          margin-top: 0;
          margin-left: 8px;
          line-height: 48px;
        }

        .form-control {
          height: 48px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .cmn-form-box {
      .form-control {
        height: 48px;
      }
    }
  }
}

.course-buy-info-box + {
  .promo-code-block {
    &.with-promo-apply {
      padding-top: 32px;
      padding-bottom: 32px;
      margin-bottom: 0;

      .promo-apply-row {
        padding-left: 16px;

        .ifill-os-close {
          left: auto;
          right: 16px;
          color: #fff;
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

.mobile-device {
  .pay-detail-box {
    .form-heading-small {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .osbtn[text*="log in"] {
      padding: 0;
      min-width: 62px;
    }
  }
}

