.pr-32 {
  padding-right: 32px;

  @media screen and (max-device-width: 768px) {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1920px) {
  .row-boxed{
    max-width: 1920px;
    margin: 0 auto;
  }
}

.ipad {
  .d-block {
    .containerClass {
      &>div:last-child {
        padding-left: 120px;
      }
    }
  }
}

.textarea-btn-div {
  height: 40px;
  font-size: 16px;
  line-height: 1.5;
  color:  $level-8;
  background-color: $level-3;
  padding: 8px 24px;
  border-radius: 8px;
  left: 88px;
  position: absolute;
  top: 24px;
  width: calc(100% - 136px);
  transition: opacity 500ms ease;
  will-change: opacity;
  display: none;

  @media screen and (min-width: 1441px) {
    height: 52px;
    font-size: 20px;
    padding: 12px 24px;
    width: calc(100% - 136px);
  }
}

.space-comments {
  .cta-wrap, .feed-author-info, .media-body {
    display: none;
    opacity: 0;
    transition: opacity 5s ease;
    will-change: opacity;
  }

  .textarea-btn-div {
    z-index: 1;
    opacity: 1;
    display: block;
  }

  .comment-collapse, :focus-within {
    .cta-wrap,
    .feed-author-info,
    .media-body  {
      display: block;
      opacity: 1;
    }

    .textarea-btn-div {
      z-index: 0;
      opacity: 0;
    }
  }
}

.user-image-edit-btn  {
  position: absolute;
  .btn-icon-helper {
    color: $level-11 !important;
  }

  &:hover {
    border-radius: 50%;
  }
}

.non-clv-header {
  .user-image-edit-btn  {
    background-color: rgba(255,255,255,0.7) !important;
  }
}
