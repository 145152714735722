.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  border-right-color: rgba(231, 232, 233, 0.95);
}

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before  {
  border-left-color: rgba(231, 232, 233, 0.95);
}

.popover {
  border-radius: 0.5rem;
  border: 0;
  box-shadow: $block-shadow;
  font-size: 0.75rem;
  line-height: 1.33;
  color: $level-9;
  padding-bottom: 4px;

  &.messanger-popover {
    @media(min-width: 768px) {
      margin-top: -16px;
      margin-left: 8px;

      &.bs-popover-bottom {
        margin-top: 24px;
        margin-left: 0;
      }

      &.with-own-message {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    @media(max-width: 767px) {
      margin-top: 20px;

      .popover-arrow {
        display: none;
      }
    }
  }

  .popover-header {
    background-color: transparent;
    text-align: center;
    margin: 0 13px;
    padding: 0;
    border-bottom: solid 1px $level-4;
    line-height: 38px;
    font-size: inherit;
    border-radius: 0;

    &::before {
      display: none;
    }
  }

  .popover-body {
    padding: 0;
    color: inherit;
    max-height: 264px;
    overflow-y: auto;
  }

  .emoji-user-listing {
    width: 200px;
  }

  .emoji-list-name {
    display: flex;
    align-items: center;
    list-style: none;
    line-height: 44px;
    padding: 0 16px;
    cursor: pointer;

    &:hover {
      background-color: $dropdown-list-hover;
      color: $dropdown-hover-text;
    }

    .avatar {
      .icon-span {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        background: $level-1 !important;
        font-size: 12px;
        bottom: -4px;
        right: -9px;
        line-height: 14px;
      }
    }
  }

  .user-name-tag {
    overflow: hidden;
    flex: 1;
    padding-left: 12px;

    @include text-truncate;
  }

  &.feed-emoji-popover {
    .popover-arrow {
      display: none;
    }
  }

  &.with-single-user {
    padding-bottom: 0;
    margin: -8px 0 0;

    .popover-header,
    .avatar,
    .popover-arrow {
      display: none;
    }

    .emoji-user-listing {
      width: auto;
      min-width: 54px;
    }

    .user-name-tag {
      padding: 0;
    }

    .emoji-list-name {
      line-height: 24px;
      padding: 0 6px;
    }
  }
}

em-emoji-picker {
  --background-rgb: 255,255,255;
  --border-radius: 8px;
  --category-icon-size: 16px;
  --color-border-hover: rgba(74, 116, 154, 1);
  --color-border: rgba(201, 209, 218, 0.3);
  --color-b: 126, 145, 165 ;
  --font-family: "Inter", sans-serif;
  --font-size: 12px;
  --rgb-accent: 53, 69, 84;
  --rgb-background: 255, 255, 255;
  --rgb-color: 126, 145, 165;
  --rgb-input: 255,255,255;
  --shadow: 0 2px 4px 0 rgba(53, 69, 84, 0.12), 0 3px 6px 0 rgba(53, 69, 84, 0.15);

  /* height: 50vh; */
  height: 350px;
  min-height: 350px;
  max-height: 350px;

  @media(max-width: 767px) {
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    --shadow: none;

    display: flex;

    --border-radius: 0;

    justify-content: center;

    section {
      width: 100% !important;
    }
  }
}
