.group-conversation {
  // CSS REGARDING NEW MESSAGE EDITOR.
  #new-message-editor {
    position: relative;
    background-color: white;
    padding-top: 16px;

    .conversation-message-box {
      padding: 8px 16px;
      position: relative;
      background-color: #fff;

      .c-close {
        cursor: pointer;
        font-size: 12px;
        position: absolute;
        right: 20px;
        top: 8px;
      }

      .parent-msg-box {
        align-items: flex-start;
        border-left: 2px solid #4a749a;
        color: #7e91a5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 8px;

        .c-author {
          font-size: 12px;
          font-weight: 600;
          line-height: 1.33;
        }

        .c-text {
          font-size: 12px;
          line-height: 1.33;
        }
      }
    }

    span[data-type='mention'] {
      background: #e3e3e3;
      border-radius: 4px;
      padding: 4px;
    }

    .mailinfo,
    .phone-number {
      display: block !important;
    }

    .editor-attachments {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
    }

    .tip-tap-editor-field {
      border: 1px solid grey;
      min-height: 42px !important;
      width: calc(100% - 200px);
      margin-bottom: 65px;
      margin-left: 16px;

      .menu-bar {
        position: absolute;
        bottom: 8px;
        left: 10px;
        zoom: 1.4;

        .emoji-picker-btn-container {
          button {
            border: 1px solid #eff1f4;;
          }
        }
      }
    }

    .drag-overlay {
      position: unset;
    }

    & > button {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }

    .options {
      & > div:first-child {
        margin: 0;
        padding: 0;
        width: fit-content;
        position: absolute;
        bottom: 66px;
        right: 32px;

        .attachment-icon {
          margin-right: 8px;
        }
      }

      .list-unstyled {
        top: -40px;
        left: unset;
        right: 54px;
      }
    }
  }
}

.mobile-device {
  .group-conversation {
    #new-message-editor {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
      width: 100%;

      .options {
        & > div:first-child {
          margin: 0;
          padding: 0;
          width: fit-content;
          right: 32px;
          display: flex;
          position: absolute;
          left: 16px;
          bottom: 16px;
        }

        .list-unstyled {
          top: -40px;
          left: 47px;
          right: 54px;
        }
      }

      .tip-tap-editor-field {
        width: calc(100% - 35px);
      }
    }
  }
}
